import React from 'react';
import PropTypes from 'prop-types';
import RDLModal from '../common/RDLModal';
import { useIntl } from 'react-intl';

function CancellationModal(props) {
    const intl = useIntl();

    return (
        <RDLModal
            isModalOpen={props.isModalOpen}
            setIsModalOpen={props.setIsCancellationModalOpen}
            action={props.cancelBooking}
            title={intl.formatMessage({ id: 'Bookings.BookingCancellationTitle' })}
            body={intl.formatMessage({ id: 'Bookings.BookingCancellationConfirmation' })}
            actionButtonText={intl.formatMessage({ id: 'Bookings.CancelBooking' })}
            actionButtonPendingText={intl.formatMessage({ id: 'Bookings.CancellingBooking' })}
        />
    );
}

CancellationModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsCancellationModalOpen: PropTypes.func.isRequired,
    cancelBooking: PropTypes.func.isRequired,
};

export default CancellationModal;
