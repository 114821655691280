import React, { useState, Fragment, useEffect, useContext, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import DiaryContext from '../../../contexts/DiaryContext';
import AddOnContext from '../../../contexts/AddOnContext';
import infoBarType from '../../../enums/infoBarType';
import addOnType from '../../../enums/addOnType';
import InfoBars from '../../common/InfoBars';
import LoadingSpinner from '../../common/LoadingSpinner';
import ValidationMessage from '../../common/ValidationMessage';
import BreadCrumbDisplay from '../../common/BreadCrumbDisplay';
import RDLModal from '../../common/RDLModal';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import StripeService from '../../../services/StripeService';
import { ReactComponent as StripeConnectIcon } from '../../../content/icons/RD-stripeconnect.svg';
import { ReactComponent as StripeConnectConnectedIcon } from '../../../content/icons/RD-stripeconnect-connected.svg';
import { ReactComponent as GreenTickIcon } from '../../../content/icons/Tick-green.svg';
import { ReactComponent as WarningIcon } from '../../../content/icons/Icon-Alert.svg';
import useQueryParam from '../../../hooks/useQueryParam';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import { useNavigate } from 'react-router-dom';

let _ID = 0;

function StripeConnectPage() {
    const intl = useIntl();
    const [infoBars, setInfoBars] = useState([]);
    const [isConnectedToStripe, setIsConnectedToStripe] = useState(null);
    const [isUpdatingStripeConnectStatus, setIsUpdatingStripeConnectStatus] = useState(false);
    const [stripeConnectUrl, setStripeConnectUrl] = useState(null);
    const [isFetchingStripeConnectUrl, setIsFetchingStripeConnectUrl] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    let code = useQueryParam('code');
    let state = useQueryParam('state');

    const diaryContext = useContext(DiaryContext);
    const addOnContext = useContext(AddOnContext);

    const addSuccessBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    }, []);

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    useEffect(() => {
        if (isConnectedToStripe === null) {
            StripeService.isStripeEnabledForProvider(diaryContext.deploymentId, diaryContext.restaurantId).then(
                (isConnectedToStripe) => {
                    setIsConnectedToStripe(isConnectedToStripe);
                }
            );
        }
    }, [isConnectedToStripe, diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        if (stripeConnectUrl === null && !isFetchingStripeConnectUrl) {
            setIsFetchingStripeConnectUrl(true);
            let redirectUrl = `${window.location.origin}/Settings/StripeConnect`;
            StripeService.getConnectWithStripeUrl(diaryContext.deploymentId, diaryContext.restaurantId, redirectUrl)
                .then((url) => {
                    setStripeConnectUrl(url);
                })
                .finally(() => {
                    setIsFetchingStripeConnectUrl(false);
                });
        }
    }, [stripeConnectUrl, diaryContext.deploymentId, diaryContext.restaurantId, isFetchingStripeConnectUrl]);

    useEffect(() => {
        if (isConnectedToStripe !== null && !isConnectedToStripe && !isUpdatingStripeConnectStatus && code && state) {
            navigate('/Settings/StripeConnect', { replace: true });
            setIsUpdatingStripeConnectStatus(true);
            StripeService.setUpStripeConnectForProvider(diaryContext.deploymentId, diaryContext.restaurantId, code)
                .then(() => {
                    setIsConnectedToStripe(true);
                    addSuccessBar(
                        intl.formatMessage({
                            id: 'Settings.ConnectedToStripe',
                        })
                    );
                    AnalyticsHelper.trackClick('Web VoucherStripe Verified');
                })
                .finally(() => {
                    setIsUpdatingStripeConnectStatus(false);
                });
        }
    }, [
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        code,
        state,
        addSuccessBar,
        isConnectedToStripe,
        intl,
        isUpdatingStripeConnectStatus,
        navigate,
    ]);

    function getBreadcrumbOptions() {
        return [
            new BreadCrumbOption(
                intl.formatMessage({
                    id: 'Settings.Title',
                }),
                '/Settings'
            ),
        ];
    }

    function connectToStripe() {
        window.location = stripeConnectUrl;
    }

    function disconnectFromStripe() {
        setIsUpdatingStripeConnectStatus(true);
        setIsModalOpen(false);
        StripeService.disconnectFromStripeConnect(diaryContext.deploymentId, diaryContext.restaurantId)
            .then(() => {
                setIsConnectedToStripe(false);
                addSuccessBar(
                    intl.formatMessage({
                        id: 'Settings.DisconnectedFromStripe',
                    })
                );
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsUpdatingStripeConnectStatus(false);
            });
    }

    function getConnectToStripeBody() {
        const isVouchersAddOnEnabled = addOnContext.isAddOnEnabled(addOnType.vouchers);
        return (
            <Fragment>
                <div className="mb-3">
                    <StripeConnectIcon className="stripe-connect-svg" />
                </div>
                <div className="stripe-voucher-conditions">
                    <div className="bold-text mb-4">
                        <FormattedMessage id="Vouchers.AcceptStripeForVouchersLine1" />
                    </div>
                    <div>
                        <FormattedMessage id="Vouchers.AcceptStripeForVouchersLine2" />
                    </div>
                </div>
                <div className="stripe-integration-info">
                    <a href="https://stripe.com/connect" target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="Settings.StripeIntegrationInformation" />
                    </a>
                </div>
                {isVouchersAddOnEnabled && (
                    <Fragment>
                        <h6 className="upper-case">
                            <FormattedMessage id="Settings.FeaturesIntegratedWithStripe" />
                        </h6>
                        <div className="add-on-container mb-4">
                            <WarningIcon className="add-on-disconnected-icon" />
                            <span className="add-on-connected-description">
                                <FormattedMessage id="Vouchers.VoucherPayment" />
                            </span>
                            <span className="add-on-warning-text">
                                <FormattedMessage id="Vouchers.VoucherDisconnectWarning" />
                            </span>
                        </div>
                    </Fragment>
                )}
                <Button
                    color="primary"
                    disabled={stripeConnectUrl === null || isUpdatingStripeConnectStatus}
                    onClick={connectToStripe}
                >
                    {isUpdatingStripeConnectStatus ? (
                        <FormattedMessage id="Settings.ConnectingToStripe" />
                    ) : (
                        <FormattedMessage id="Settings.ConnectToStripe" />
                    )}
                </Button>
            </Fragment>
        );
    }

    function getDisconnectFromStripeBody() {
        const isVouchersAddOnEnabled = addOnContext.isAddOnEnabled(addOnType.vouchers);
        return (
            <Fragment>
                <div className="mb-3">
                    <StripeConnectConnectedIcon className="stripe-connect-svg" />
                </div>
                <div className="mb-4">
                    <FormattedMessage id="Settings.DisconnectFromStripeLine1" />
                </div>
                {isVouchersAddOnEnabled && (
                    <Fragment>
                        <h6 className="upper-case">
                            <FormattedMessage id="Settings.FeaturesIntegratedWithStripe" />
                        </h6>
                        <div className="add-on-container mb-4">
                            <GreenTickIcon className="add-on-connected-icon" />
                            <span className="add-on-connected-description">
                                <FormattedMessage id="Vouchers.VoucherPayment" />
                            </span>
                        </div>
                    </Fragment>
                )}
                <Button
                    color="outline-primary"
                    disabled={isUpdatingStripeConnectStatus}
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    {isUpdatingStripeConnectStatus ? (
                        <FormattedMessage id="Settings.DisconnectingFromStripe" />
                    ) : (
                        <FormattedMessage id="Settings.DisconnectFromStripe" />
                    )}
                </Button>
            </Fragment>
        );
    }

    if (isConnectedToStripe === null || isUpdatingStripeConnectStatus) {
        return <LoadingSpinner />;
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <RDLModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                action={disconnectFromStripe}
                title={intl.formatMessage({ id: 'Settings.DisconnectFromStripeModalTitle' })}
                body={
                    <div className="warning-modal-text">
                        <ValidationMessage
                            message={intl.formatMessage({ id: 'Settings.DisconnectFromStripeModalWarningText' })}
                        />
                        {addOnContext.isAddOnEnabled(addOnType.vouchers) && (
                            <ul className="add-on-list">
                                <li>
                                    <FormattedMessage id="Settings.DisconnectFromStripeModalVoucherWarningText" />
                                </li>
                            </ul>
                        )}
                    </div>
                }
                actionButtonText={intl.formatMessage({ id: 'Settings.DisconnectFromStripeButton' })}
                actionButtonPendingText={intl.formatMessage({ id: 'Settings.DisconnectingFromStripe' })}
                dismissActionButtonText={intl.formatMessage({ id: 'Common.Cancel' })}
                isWarningModal
            />
            <BreadCrumbDisplay
                breadcrumbs={getBreadcrumbOptions()}
                activePageTitle={intl.formatMessage({
                    id: 'Settings.StripeSectionTitle',
                })}
            />
            <div className="row">
                <div className="col-lg-12 page-header">
                    <div className="d-flex">
                        <h2 className="flex-fill" data-testid="tables-page-header">
                            <FormattedMessage id="Settings.StripeSectionTitle" />
                        </h2>
                    </div>
                </div>
            </div>
            <div className="flex-container-center left-align-items">
                <div className="white-panel-body">
                    {isConnectedToStripe ? getDisconnectFromStripeBody() : getConnectToStripeBody()}
                </div>
            </div>
        </Fragment>
    );
}

export default StripeConnectPage;
