import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';

class SelectableText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(e) {
        // Prevent further event bubbling
        e.stopPropagation();
        if (!this.props.textModeOnly) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen,
            });
        }
    }

    render() {
        let selectedOption;
        const otherOptions = [];
        const options = this.props.options;
        options.forEach((option, i) => {
            if (option.value === this.props.selectedValue) {
                selectedOption = (
                    <DropdownToggle style={{ backgroundColor: option.backgroundColour }} caret={true}>
                        {option.text}
                    </DropdownToggle>
                );
            } else {
                otherOptions.push(
                    <DropdownItem
                        style={{ backgroundColor: option.backgroundColour }}
                        onClick={() => this.props.onChange(option.value)}
                        key={i}
                    >
                        {option.text}
                    </DropdownItem>
                );
            }
        });

        if (this.props.disabled) {
            selectedOption = (
                <DropdownToggle style={{ backgroundColor: '#b2b2b1' }} caret={false} disabled>
                    {this.props.disabledText}
                </DropdownToggle>
            );
        }

        return (
            <Dropdown
                isOpen={!this.props.textModeOnly && this.state.dropdownOpen}
                onMouseOver={!this.props.textModeOnly ? this.onMouseOver : null}
                onMouseLeave={this.onMouseLeave}
                toggle={this.toggle}
                className={!this.props.textModeOnly ? 'selectable-text' : 'selectable-text text-mode'}
                disabled={this.props.disabled}
            >
                {selectedOption}
                <DropdownMenu>{otherOptions}</DropdownMenu>
            </Dropdown>
        );
    }
}

SelectableText.propTypes = {
    options: PropTypes.array,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
    parentOverriding: PropTypes.bool,
    textModeOnly: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default SelectableText;
