import Customer from './Customer';
import MomentHelper from '../helpers/MomentHelper';

class Booking {
    constructor(booking, isUnallocated) {
        this.id = booking && booking.Id ? booking.Id : 0;
        this.bookingReference = booking && booking.BookingReference ? booking.BookingReference : '';
        this.areaId = booking && booking.AreaId ? booking.AreaId : 0;
        this.serviceId = booking && booking.ServiceId ? booking.ServiceId : 0;
        this.visitDateTime = booking && booking.VisitDateTime ? MomentHelper.newInstance(booking.VisitDateTime) : '';
        this.bookingDateTime =
            booking && booking.BookingDateTime ? MomentHelper.newInstance(booking.BookingDateTime) : '';
        this.duration = booking && booking.Duration ? booking.Duration : 0;
        this.turnTime = booking && booking.TurnTime ? booking.TurnTime : 0;
        this.partySize = booking && booking.PartySize ? booking.PartySize : 0;
        this.bookingComments = booking && booking.Comments ? booking.Comments : '';
        this.customer = booking && booking.Customer ? new Customer(booking.Customer) : null;
        this.arrivalStatus = booking && booking.ArrivalStatus ? booking.ArrivalStatus : '';
        this.status = booking && booking.Status ? booking.Status : '';
        this.bookingType = booking && booking.BookingType ? booking.BookingType : '';
        this.bookingReasonIds = booking && booking.BookingReasonIds ? booking.BookingReasonIds : [];
        this.channelId = booking && booking.ChannelId ? booking.ChannelId : 0;
        this.channelName = booking && booking.ChannelName ? booking.ChannelName : '';
        this.channelColour = booking && booking.ChannelColour ? booking.ChannelColour : '';
        this.isReConfirmed = booking && booking.IsReConfirmed ? booking.IsReConfirmed : false;
        this.tables = booking && booking.Tables ? booking.Tables : [];
        this.customerId = booking && booking.Customer ? booking.Customer.Id : 0;
        this.tableLabelIds = booking && booking.TableLabelIds ? booking.TableLabelIds : [];
        this.orderAtTableStatus = booking && booking.OrderAtTableStatus ? booking.OrderAtTableStatus : '';
        this.isUnallocated = isUnallocated ? isUnallocated : false;
        this.preorderMenuName = booking ? booking.PreorderMenuName : '';
        this.preorderMenuVersionId = booking ? booking.PreorderMenuVersionId : null;
        this.hasUnprocessedPayments =
            booking && booking.Payments && booking.Payments.find((p) => !p.IsProcessed) !== undefined;

        if (booking && booking.Vouchers && booking.Vouchers.length) {
            const voucher = booking.Vouchers[0]; //for the time being, only 1 voucher is redeemable against a booking in RDL
            this.voucherName = voucher.Name;
            this.voucherReference = voucher.Reference;
            this.voucherCost = voucher.Cost;
        } else {
            this.voucherName = '';
            this.voucherReference = '';
            this.voucherCost = 0.0;
        }
        this.dishcultRewardsVoucherCode = booking ? booking.DishcultRewardsVoucherCode : null;
        this.dishcultRewardsVoucherDescription = booking ? booking.DishcultRewardsVoucherDescription : null;
    }

    toJson() {
        return {
            Id: this.id,
            BookingReference: this.bookingReference,
            AreaId: this.areaId,
            ServiceId: this.serviceId,
            VisitDateTime: this.visitDateTime,
            BookingDateTime: this.bookingDateTime,
            Duration: this.duration,
            TurnTime: this.turnTime,
            PartySize: this.partySize,
            Comments: this.bookingComments,
            Customer: this.customer,
            ArrivalStatus: this.arrivalStatus,
            Status: this.status,
            BookingType: this.bookingType,
            BookingReasonIds: this.bookingReasonIds,
            ChannelId: this.channelId,
            ChannelName: this.channelName,
            ChannelColour: this.channelColour,
            IsReConfirmed: this.isReConfirmed,
            Tables: this.tables,
            CustomerId: this.customerId,
            TableLabelIds: this.tableLabelIds,
            PreorderMenuName: this.preorderMenuName,
            PreorderMenuVersionId: this.preorderMenuVersionId,
            VoucherName: this.voucherName,
            VoucherReference: this.voucherReference,
            VoucherCost: this.voucherCost,
            DishcultRewardsVoucherCode: this.dishcultRewardsVoucherCode,
            DishcultRewardsVoucherDescription: this.dishcultRewardsVoucherDescription,
        };
    }
}

export default Booking;
