class Service {
    constructor(service) {
        this.id = service && service.Id ? service.Id : 0;
        this.name = service && service.Name ? service.Name : '';
        this.description = service && service.Description ? service.Description : '';
        this.priority = service && service.Priority ? service.Priority : 0;
        this.timeFrom = service && service.TimeFrom ? service.TimeFrom : '00:00:00';
        this.timeUntil = service && service.TimeUntil ? service.TimeUntil : '00:00:00';
        this.lastBookingTime = service && service.LastBookingTime ? service.LastBookingTime : '00:00:00';
        this.timeSlotInterval = service && service.TimeSlotInterval ? service.TimeSlotInterval : 15;
        this.standardEatDuration = service && service.StandardEatDuration ? service.StandardEatDuration : 0;
        this.minimumBookingDuration = service && service.MinimumBookingDuration ? service.MinimumBookingDuration : 0;
        this.leaveTimeFrom = service && service.LeaveTimeFrom ? service.LeaveTimeFrom : '';
        this.leaveTimeUntil = service && service.LeaveTimeUntil ? service.LeaveTimeUntil : '';
        this.isLeaveTimeEnabled = service && service.IsLeaveTimeEnabled ? service.IsLeaveTimeEnabled : false;
        this.turnTime = service && service.TurnTime ? service.TurnTime : 0;
        this.maxBookings = service && service.MaxBookings ? service.MaxBookings : null;
        this.maxCovers = service && service.MaxCovers ? service.MaxCovers : null;
        this.enabled = service && service.IsEnabled ? service.IsEnabled : false;
    }

    getServiceDTO(name, timeFrom, timeUntil, lastBookingTime, timeSlotInterval, maxCovers, maxBookings) {
        return {
            ServiceName: name,
            TimeFrom: timeFrom,
            TimeUntil: timeUntil,
            LastBookingTime: lastBookingTime,
            TimeSlotInterval: timeSlotInterval,
            MaxCovers: maxCovers,
            MaxBookings: maxBookings,
        };
    }
}

export default Service;
