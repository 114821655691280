import { v4 as uuidv4 } from 'uuid';
import MomentHelper from '../helpers/MomentHelper';

class MenuListing {
    constructor(menu) {
        this.id = menu && menu.Id ? menu.Id : 0;
        this.name = menu && menu.Name ? menu.Name : '';
        this.fileName = menu && menu.FileName ? menu.FileName : '';
        this.menuUrl = menu && menu.MenuUrl ? menu.MenuUrl : '';
        this.type = menu && menu.Type >= 0 ? menu.Type : '';
        this.isEnabled = menu && menu.IsEnabled ? menu.IsEnabled : false;
        this.cultureCode = menu && menu.CultureCode ? menu.CultureCode : 'en';
        this.displayOnlineFrom =
            menu && menu.DisplayOnlineFrom ? MomentHelper.newInstance(menu.DisplayOnlineFrom) : null;
        this.displayOnlineUntil =
            menu && menu.DisplayOnlineUntil ? MomentHelper.newInstance(menu.DisplayOnlineUntil) : null;
        this.uploadedDate = menu && menu.UploadedDate ? MomentHelper.newInstance(menu.UploadedDate) : '';
        this.uniqueIdentifier = menu && menu.UniqueIdentifier ? menu.UniqueIdentifier : uuidv4();
    }
}

export default MenuListing;
