import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class SquareService {
    static getSquareAccount(deploymentId, providerId) {
        return ApiHelper.get(`${SettingsHelper.getApiUrl()}/api/Square/v1/Account/${deploymentId}/${providerId}`).then(
            (response) => response.data
        );
    }

    static connectSquareAccount(deploymentId, providerId, authorisationCode) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Square/v1/Account/${deploymentId}/${providerId}/Connect`,
            JSON.stringify({ authorisationCode })
        ).then((response) => response.data);
    }

    static disconnectSquareAccount(deploymentId, providerId) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Square/v1/Account/${deploymentId}/${providerId}/Disconnect`
        ).then((response) => response.data);
    }

    static getSquareLocations(deploymentId, providerId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Square/v1/Account/${deploymentId}/${providerId}/Location`
        ).then((response) => response.data);
    }

    static setSquareLocation(deploymentId, providerId, locationId) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Square/v1/Account/${deploymentId}/${providerId}/Location`,
            JSON.stringify({ locationId })
        ).then((response) => response.data);
    }
}

export default SquareService;
