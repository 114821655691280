import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import EditMenuItemModifierSection from './EditMenuItemModifierSection';

function AddMenuItemModifierSection(props) {
    const [isOpen, toggleIsOpen] = useReducer(toggle, false);

    function toggle(value) {
        return !value;
    }

    if (!isOpen) {
        return (
            <Button
                block
                className="btn-dashed mt-3"
                color="default"
                onClick={() => {
                    toggleIsOpen();
                }}
                autoFocus
            >
                <FormattedMessage id="Settings.AddModifier" /> +
            </Button>
        );
    }

    return (
        <EditMenuItemModifierSection
            toggleIsOpen={toggleIsOpen}
            menuItemModifierAdded={props.menuItemModifierAdded}
            menuItemModifierEdited={props.menuItemModifierEdited}
            modifiers={props.modifiers}
        />
    );
}

AddMenuItemModifierSection.propTypes = {
    menuItemModifierAdded: PropTypes.func.isRequired,
    menuItemModifierEdited: PropTypes.func.isRequired,
    modifiers: PropTypes.array,
};

export default AddMenuItemModifierSection;
