import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Page from './Page';
import SidePanel from './SidePanel';
import { ReactComponent as MinimiseIcon } from '../.././content/icons/minimise.svg';
import { ReactComponent as MaximiseIcon } from '../.././content/icons/maximise.svg';
import useScreenSize from '../../hooks/useScreenSize';

function SidePanelPage(props) {
    const [showSidePanel, toggleShowSidePanel] = useState(false);
    const toggle = () => toggleShowSidePanel(!showSidePanel);
    const { isMobileView } = useScreenSize();

    let panelToggleIcon;

    if (showSidePanel) {
        panelToggleIcon = <MinimiseIcon data-testid="minimise-icon" />;
    } else {
        panelToggleIcon = <MaximiseIcon data-testid="maximise-icon" />;
    }

    return (
        <div className="expanding-panel-container">
            <Page page={props.page} showSidePanel={showSidePanel} isMobileView={isMobileView} />
            <Button
                data-testid="sliding-panel-toggle"
                className={isMobileView ? 'sliding-panel-toggle mobile' : 'sliding-panel-toggle'}
                onClick={toggle}
            >
                {panelToggleIcon}
            </Button>

            <div data-testid="side-panel">
                <SidePanel sidePanel={props.sidePanel} showSidePanel={showSidePanel} isMobileView={isMobileView} />
            </div>
        </div>
    );
}

SidePanelPage.propTypes = {
    page: PropTypes.object.isRequired,
    sidePanel: PropTypes.object.isRequired,
};

export default SidePanelPage;
