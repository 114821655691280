import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import PropTypes from 'prop-types';
import SettingsHelper from '../../helpers/SettingsHelper';

const MapComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={16}
            center={{ lat: props.latitude, lng: props.longitude }}
            options={{
                styles: [{ elementType: 'labels', featureType: 'poi.business', stylers: [{ visibility: 'off' }] }],
            }}
        >
            {props.isMarkerShown && (
                <Marker
                    onDragEnd={props.onDragEnd}
                    defaultDraggable
                    position={{ lat: props.latitude, lng: props.longitude }}
                />
            )}
        </GoogleMap>
    ))
);

function MapContainer(props) {
    const googleApiKey = SettingsHelper.getGoogleApiKey();
    return (
        <div className="map">
            <div className="map-container">
                <MapComponent
                    onDragEnd={props.onDragEnd}
                    longitude={props.longitude}
                    latitude={props.latitude}
                    isMarkerShown
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing`}
                    loadingElement={<div className="h-100" />}
                    containerElement={<div className="container-element" />}
                    mapElement={<div className="h-100" />}
                />
            </div>
            <div className="latitude-longitude-message">
                <FormattedMessage
                    id="Settings.LatitudeLongitude"
                    values={{ Latitude: props.latitude, Longitude: props.longitude }}
                />
            </div>
        </div>
    );
}

MapContainer.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    onDragEnd: PropTypes.func.isRequired,
};

export default MapContainer;
