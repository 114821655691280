import voucherReportStatus from '../enums/voucherReportStatus';
import voucherType from '../enums/voucherType';

class PurchasedVoucher {
    constructor(voucher) {
        this.reference = voucher && voucher.VoucherReference ? voucher.VoucherReference : '';
        this.voucherCost = voucher && voucher.VoucherCost ? voucher.VoucherCost : '';
        this.deliveryEmail = voucher && voucher.DeliveryEmail ? voucher.DeliveryEmail : '';
        this.deliveryName = voucher && voucher.DeliveryName ? voucher.DeliveryName : '';
        this.voucherOrderDate = voucher && voucher.VoucherOrderDate ? voucher.VoucherOrderDate : '';
        this.customerName = voucher && voucher.CustomerName ? voucher.CustomerName : '';
        this.customerEmail = voucher && voucher.CustomerEmail ? voucher.CustomerEmail : '';
        this.customerPhone = voucher && voucher.CustomerPhone ? voucher.CustomerPhone : '';
        this.voucherName = voucher && voucher.VoucherName ? voucher.VoucherName : '';
        this.voucherValidity = voucher && voucher.VoucherValidity ? voucher.VoucherValidity : '';
        this.voucherRedeemedDate = voucher && voucher.VoucherRedeemedDate ? voucher.VoucherRedeemedDate : '';
        this.voucherRedeemedBookingReference =
            voucher && voucher.VoucherRedeemedBookingReference ? voucher.VoucherRedeemedBookingReference : '';
        this.status = voucher ? voucher.Status : voucherReportStatus.Sold;
        this.type = voucher
            ? voucher.CustomerEmail === voucher.DeliveryEmail
                ? voucherType.Personal
                : voucherType.Gift
            : '';
        this.orderId = voucher && voucher.OrderId ? voucher.OrderId : 0;
    }
}

export default PurchasedVoucher;
