import React from 'react';
import PropTypes from 'prop-types';
import RDLModal from '../common/RDLModal';
import { useIntl } from 'react-intl';

function MenuChangeModal(props) {
    const intl = useIntl();

    return (
        <RDLModal
            isModalOpen={props.isModalOpen}
            setIsModalOpen={props.setIsMenuChangeModalOpen}
            action={props.sendNewMenuNotification}
            title={intl.formatMessage({ id: 'Bookings.MenuChangeModalTitle' }, { menuName: props.menuChangeName })}
            body={intl.formatMessage({ id: 'Bookings.MenuChangeModalWarning' }, { menuName: props.menuChangeName })}
            actionButtonText={intl.formatMessage({ id: 'Common.Confirm' })}
            actionButtonPendingText={intl.formatMessage({ id: 'Bookings.UpdatingBooking' })}
        />
    );
}

MenuChangeModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsMenuChangeModalOpen: PropTypes.func.isRequired,
    sendNewMenuNotification: PropTypes.func.isRequired,
    menuChangeName: PropTypes.string,
};

export default MenuChangeModal;
