import React from 'react';
import PropTypes from 'prop-types';
import BookingHelper from '../../helpers/BookingHelper';
import arrivalStatus from '../../enums/arrivalStatus';
import bookingStatus from '../../enums/bookingStatus';
import MomentHelper from '../../helpers/MomentHelper';
import { ReactComponent as FullySeatedIcon } from '../../content/icons/Icon-FullySeated.svg';
import { ReactComponent as CancelIcon } from '../../content/icons/Icon-Cancel.svg';
import { ReactComponent as ClockIcon } from '../../content/icons/Icon-Clock.svg';
import { ReactComponent as CloseIcon } from '../../content/icons/Icon-CloseOut.svg';
import { useIntl } from 'react-intl';
import useFormattedTime from '../../hooks/useFormattedTime';
import AlertPanel from './AlertPanel';

function BookingAlert(props) {
    const intl = useIntl();
    const formattedTime = useFormattedTime(props.alert.booking.visitDateTime);

    function getIcon(booking) {
        if (booking.status === bookingStatus.cancelled) {
            return (
                <div>
                    <CancelIcon />
                </div>
            );
        } else if (booking.status === bookingStatus.closed) {
            return (
                <div>
                    <CloseIcon />
                </div>
            );
        } else if (booking.arrivalStatus === arrivalStatus.fullySeated) {
            return (
                <div className={'booking-icon-fully-seated'}>
                    <FullySeatedIcon />
                </div>
            );
        } else if (booking.arrivalStatus === arrivalStatus.notArrived) {
            return (
                <div className={getNotArrivedIconStyle(booking)}>
                    <ClockIcon />
                </div>
            );
        }
    }

    function getNotArrivedIconStyle(booking) {
        return MomentHelper.isDateBeforeNow(booking.visitDateTime) ? 'booking-icon-late' : 'booking-icon-default';
    }

    return (
        <AlertPanel
            rowColour={BookingHelper.getRowStatusCssClass(props.alert.booking)}
            title={intl.formatMessage({ id: 'Bookings.NewBooking' })}
            booking={props.alert.booking}
            description={formattedTime}
            icon={getIcon(props.alert.booking)}
            setAlertBookingOverlayId={props.setAlertBookingOverlayId}
        />
    );
}

BookingAlert.propTypes = {
    setAlertBookingOverlayId: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired,
};

export default BookingAlert;
