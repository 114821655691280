import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ProviderProgressFlagsContext from '../../contexts/ProviderProgressFlagsContext';
import ListingStrength from '../common/ListingStrength';
import useTrackPage from '../../hooks/useTrackPage';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import SessionContext from '../../contexts/SessionContext';
import DiaryContext from '../../contexts/DiaryContext';

function ImproveListingTasksCompleteModal(props) {
    const providerProgressFlagsContext = useContext(ProviderProgressFlagsContext);
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);

    useTrackPage('Listing Onboard Done');

    function getAnalyticsProperties() {
        return {
            completedListing: providerProgressFlagsContext.addedAdvancedListing,
            completedMenus: providerProgressFlagsContext.uploadedMenu,
            completedImages: providerProgressFlagsContext.addedAdditionalImage,
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
        };
    }

    function markCompletionModalAsDismissed() {
        AnalyticsHelper.trackClickWithProperties('Web Full Strength', getAnalyticsProperties());
        providerProgressFlagsContext.updatedProviderProgressFlag('dismissedCompletionModal');
        props.setIsModalOpen(false);
    }

    function getCloseButton() {
        return (
            <button className="close" onClick={() => markCompletionModalAsDismissed()}>
                &times;
            </button>
        );
    }

    return (
        <Modal zIndex={1072} size="md" centered isOpen={props.isModalOpen} className="modal">
            <ModalHeader close={getCloseButton()}></ModalHeader>
            <ModalBody className="d-flex flex-column improve-listing-complete-modal-body">
                <ListingStrength percentage={100} />
                <h4 className="mt-3 font-weight-normal">
                    <FormattedMessage id="AccountSubmission.Achieved100PercentModalHeading" />
                </h4>
                <div className="text-center mt-3">
                    <FormattedMessage id="AccountSubmission.Achieved100PercentModalBody" />
                </div>
                <Button
                    className={'btn-outline-primary mt-3'}
                    color="primary"
                    onClick={() => markCompletionModalAsDismissed()}
                >
                    <FormattedMessage id="Common.GotIt" />
                </Button>
            </ModalBody>
        </Modal>
    );
}

ImproveListingTasksCompleteModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
};

export default ImproveListingTasksCompleteModal;
