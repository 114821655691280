import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { ReactComponent as SuccessIcon } from '../../content/icons/Icon-Success.svg';
import { ReactComponent as CreateAndManageIcon } from '../../content/icons/createmanage.svg';
import { ReactComponent as SellPromoteIcon } from '../../content/icons/sellpromote.svg';
import { ReactComponent as AnalyticsIcon } from '../../content/icons/voucher-analytics.svg';
import { ReactComponent as RDLVoucherIcon } from '../../content/icons/RDL-Voucher.svg';
import IconBulletPoint from '../common/IconBulletPoint';
import useScreenSize from '../../hooks/useScreenSize';
import PropTypes from 'prop-types';
import PropTypeExtensions from '../../helpers/PropTypeExtensions';

function VouchersOverviewPage(props) {
    const intl = useIntl();

    const { isTabletView } = useScreenSize();

    function getButton() {
        if (props.hasVouchers) {
            return (
                <Button onClick={() => props.continueOnboardingFlow(true)} color="primary">
                    <FormattedMessage id="Vouchers.ContinueVoucherCreation" />
                </Button>
            );
        } else {
            return (
                <Button onClick={() => props.toggleAddNewVoucher()} color="primary">
                    <FormattedMessage id="Vouchers.CreateFirstVoucher" />
                </Button>
            );
        }
    }

    return (
        <div className={isTabletView ? 'vouchers-overview-container mobile' : 'container vouchers-overview-container'}>
            <div className="header-banner"></div>
            <div className="main-content-wrapper">
                <div className="main-content">
                    <div className="rdl-voucher-icon">
                        <RDLVoucherIcon />
                    </div>
                    <div className="heading text-uppercase bold-text">
                        <FormattedMessage id="Vouchers.GiftVouchers" />
                    </div>
                    <div className="vouchers-description">
                        <FormattedMessage id="Vouchers.VouchersOverDescriptionLineOne" />
                        <br />
                        <FormattedMessage id="Vouchers.VouchersOverDescriptionLineTwo" />
                    </div>
                    <div className="payment-information">
                        <div className="price-commission-info cost-per-month">
                            <div className="sell-vouchers-title">
                                <h4>
                                    <FormattedMessage id="Vouchers.SellGiftVouchersToday" />
                                </h4>
                            </div>
                            {intl.formatMessage({ id: 'Vouchers.CommissionPercentage' }, { commissionPercentage: 3 })}
                            <br />
                            {intl.formatMessage({ id: 'Vouchers.NoHiddenCostsLabel' })}
                        </div>
                    </div>
                    <div className="create-voucher-button-wrapper">{getButton()}</div>
                </div>
                <div className={'vouchers-information'}>
                    <div className={isTabletView ? 'icon-panel d-flex flex-column' : 'icon-panel d-flex'}>
                        <div className="icon-text flex-fill">
                            <CreateAndManageIcon />
                            <div className="icon-header">
                                <FormattedMessage id="Vouchers.CreateAndManageVouchers" />
                            </div>
                            <div className="bullet-points">
                                <IconBulletPoint
                                    className={'progress-icon-container success-icon'}
                                    icon={<SuccessIcon />}
                                    text={intl.formatMessage({ id: 'Vouchers.SimpleSetup' })}
                                />
                                <IconBulletPoint
                                    className={'progress-icon-container success-icon'}
                                    icon={<SuccessIcon />}
                                    text={intl.formatMessage({ id: 'Vouchers.CustomiseYourOwnVoucher' })}
                                />
                                <IconBulletPoint
                                    className={'progress-icon-container success-icon'}
                                    icon={<SuccessIcon />}
                                    text={intl.formatMessage({ id: 'Vouchers.IntroduceRevenueStream' })}
                                />
                            </div>
                        </div>
                        <div className="icon-text flex-fill">
                            <SellPromoteIcon />
                            <div className="icon-header">
                                <FormattedMessage id="Vouchers.SellPromoteAndDistribute" />
                            </div>
                            <div className="bullet-points">
                                <IconBulletPoint
                                    className={'progress-icon-container success-icon'}
                                    icon={<SuccessIcon />}
                                    text={intl.formatMessage({ id: 'Vouchers.SelfHostedUrl' })}
                                />
                                <IconBulletPoint
                                    className={'progress-icon-container success-icon'}
                                    icon={<SuccessIcon />}
                                    text={intl.formatMessage({ id: 'Vouchers.SellThroughOwnWebsite' })}
                                />
                                <IconBulletPoint
                                    className={'progress-icon-container success-icon'}
                                    icon={<SuccessIcon />}
                                    text={intl.formatMessage({ id: 'Vouchers.ComplimentaryPromo' })}
                                />
                            </div>
                        </div>
                        <div className="icon-text flex-fill">
                            <AnalyticsIcon />
                            <div className="icon-header">
                                <FormattedMessage id="Vouchers.TrackYourSalesInRealTime" />
                            </div>
                            <div className="bullet-points">
                                <IconBulletPoint
                                    className={'progress-icon-container success-icon'}
                                    icon={<SuccessIcon />}
                                    text={intl.formatMessage({ id: 'Vouchers.RealTimeAnalytics' })}
                                />
                                <IconBulletPoint
                                    className={'progress-icon-container success-icon'}
                                    icon={<SuccessIcon />}
                                    text={intl.formatMessage({ id: 'Vouchers.MaximiseRevenue' })}
                                />
                                <div className="stripe-fee-message">
                                    *<FormattedMessage id="Vouchers.StandardStripeTransactionFeeApplies" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="terms-and-conditions fake-link bold-text">
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://sales.resdiary.com/terms-conditions/#generaltermsofservice"
                        >
                            <FormattedMessage id="Vouchers.TermsAndConditionsApply" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

VouchersOverviewPage.propTypes = {
    hasVouchers: PropTypes.bool,
    continueOnboardingFlow: PropTypeExtensions.requiredIf(PropTypes.func, (props) => props.hasVouchers),
};

export default VouchersOverviewPage;
