class ListingDetails {
    constructor(dto) {
        this.venueDescription = dto && dto.GeneralDescription ? dto.GeneralDescription : '';
        this.openingInformation = dto && dto.OpeningInformation ? dto.OpeningInformation : '';
        this.currency = dto && dto.IsoCurrencySymbol ? dto.IsoCurrencySymbol : '';
        this.dressCodes = dto && dto.DressCodeIds ? dto.DressCodeIds : [];
        this.pricePoint = dto && dto.PricePoint ? dto.PricePoint : '';
        this.cuisines = dto && dto.CuisineIds ? dto.CuisineIds : [];
        this.facilities = dto && dto.FacilityIds ? dto.FacilityIds : [];
        this.twitterUrl = dto && dto.TwitterUrl ? dto.TwitterUrl : '';
        this.facebookUrl = dto && dto.FacebookUrl ? dto.FacebookUrl : '';
        this.youtubeUrl = dto && dto.YoutubeUrl ? dto.YoutubeUrl : '';
        this.instagramUrl = dto && dto.InstagramUrl ? dto.InstagramUrl : '';
    }

    getDTO(
        venueDescription,
        openingInformation,
        currency,
        dressCodes,
        pricePoint,
        cuisines,
        facilities,
        twitterUrl,
        facebookUrl,
        youtubeUrl,
        instagramUrl,
        isReserveWithGoogleEnabled,
        hideOnPortal
    ) {
        return {
            GeneralDescription: venueDescription,
            OpeningInformation: openingInformation,
            IsoCurrencySymbol: currency,
            DressCodeIds: dressCodes,
            PricePoint: pricePoint,
            CuisineIds: cuisines,
            FacilityIds: facilities,
            TwitterUrl: twitterUrl,
            FacebookUrl: facebookUrl,
            YoutubeUrl: youtubeUrl,
            InstagramUrl: instagramUrl,
            IsReserveWithGoogleEnabled: isReserveWithGoogleEnabled,
            HideOnPortal: hideOnPortal,
        };
    }
}

export default ListingDetails;
