import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

function RDLModal(props) {
    const [isProcessing, setIsProcessing] = useState(false);

    function getCloseButton() {
        if (props.nonDismissible) {
            return null;
        }

        return (
            <button className="close" onClick={() => props.setIsModalOpen(false)}>
                &times;
            </button>
        );
    }

    function process() {
        setIsProcessing(true);
        props.action(actionFailedCallback);
    }

    function actionFailedCallback() {
        setIsProcessing(false);
    }

    function getActionButtonText() {
        if (isProcessing) {
            return props.actionButtonPendingText ? props.actionButtonPendingText : props.actionButtonText;
        }

        return props.actionButtonText;
    }

    return (
        <Modal
            zIndex={1072}
            size="lg"
            centered
            isOpen={props.isModalOpen}
            className={props.modalClass ? 'modal ' + props.modalClass : 'modal'}
            backdrop={props.backdrop}
            wrapClassName={props.wrapClassName}
        >
            <ModalHeader className={props.modalHeaderClass} close={getCloseButton()}>
                {props.title}
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
                <div>{props.body}</div>
            </ModalBody>
            {!props.hideFooter && !props.customFooter && (
                <ModalFooter>
                    <div>
                        {!props.hideCloseButton && !props.nonDismissible && (
                            <Button
                                className="mr-2"
                                outline={!props.isWarningModal}
                                color="primary"
                                onClick={() => props.setIsModalOpen(false)}
                                disabled={isProcessing}
                            >
                                {props.dismissActionButtonText ? (
                                    props.dismissActionButtonText
                                ) : (
                                    <FormattedMessage id="Common.Close" />
                                )}
                            </Button>
                        )}

                        <Button
                            className={props.isWarningModal ? 'warning-modal-confirm-button' : null}
                            outline={props.isWarningModal}
                            color="primary"
                            onClick={process}
                            disabled={isProcessing}
                        >
                            {getActionButtonText()}
                        </Button>
                    </div>
                </ModalFooter>
            )}
            {props.customFooter}
        </Modal>
    );
}

RDLModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    action: PropTypes.func,
    title: PropTypes.string.isRequired,
    body: PropTypes.node.isRequired,
    actionButtonText: PropTypes.string,
    actionButtonPendingText: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    nonDismissible: PropTypes.bool,
    isWarningModal: PropTypes.bool,
    hideFooter: PropTypes.bool,
    modalClass: PropTypes.string,
    modalHeaderClass: PropTypes.string,
    customFooter: PropTypes.element,
    backdrop: PropTypes.bool,
    dismissActionButtonText: PropTypes.string,
    wrapClassName: PropTypes.string,
};

export default RDLModal;
