import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class AvailabilitySearchService {
    static search(deploymentId, restaurantId, date, partySize, excludedBookingIds, duration) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/AvailabilitySearch`,
            { VisitDate: date, PartySize: partySize, ExcludedBookingIds: excludedBookingIds, Duration: duration }
        ).then((response) => response.data);
    }

    static getNextFreeTimeSlot(deploymentId, restaurantId, date, partySize) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/NextFreeTimeSlot?partySize=${partySize}&dateTime=${date}&includeWaitlistBookings=false`
        ).then((response) => response.data);
    }
}

export default AvailabilitySearchService;
