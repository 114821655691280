const userAccountFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    emailAddress: 'emailAddress',
    countryId: 'countryId',
    phoneNumber: 'phoneNumber',
    password: 'password',
    countryAlpha2Code: 'countryAlpha2Code',
};

export default userAccountFields;
