import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import TableHeader from '../common/TableHeader';
import TableHeadingOption from '../common/TableHeadingOption';
import runningOrderColumns from '../../enums/runningOrderColumns';

class TableHeadersExamples extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortOrder: runningOrderColumns.visitTime,
            sortAscending: true,
        };
        this.onSorting = this.onSorting.bind(this);
    }

    onSorting(sortOrder) {
        let sortAscending = true;
        if (sortOrder === this.state.sortOrder) {
            sortAscending = !this.state.sortAscending;
        }
        this.setState({
            sortOrder: sortOrder,
            sortAscending: sortAscending,
        });
    }

    getHeaders() {
        let headerItems = [
            new TableHeadingOption(false, 'visit time', runningOrderColumns.visitTime),
            new TableHeadingOption(false, 'covers', runningOrderColumns.covers),
            new TableHeadingOption(false, 'tables', runningOrderColumns.tables),
            new TableHeadingOption(false, 'customer details', runningOrderColumns.customerDetails),
            new TableHeadingOption(false, 'booking comments', runningOrderColumns.bookingComments),
            new TableHeadingOption(false, 'reference', runningOrderColumns.reference),
            new TableHeadingOption(false, 'reason for booking', runningOrderColumns.bookingComments),
        ];
        return headerItems.map((headingOption, index) => {
            if (headingOption.sortable && headingOption.sortable === true) {
                return (
                    <TableHeader
                        toolTipClasses={this.props.toolTipClasses}
                        index={index}
                        sortable={true}
                        key={headingOption.heading}
                        heading={headingOption.heading}
                        sortOrder={headingOption.sortOrder}
                        onSorting={this.onSorting}
                        currentSortOrder={this.state.sortOrder}
                        sortAscending={this.state.sortAscending}
                        toolTipText={headingOption.toolTip}
                    />
                );
            } else {
                return (
                    <TableHeader
                        toolTipClasses={this.props.toolTipClasses}
                        index={index}
                        sortable={false}
                        key={headingOption.heading}
                        heading={headingOption.heading}
                        toolTipText={headingOption.toolTip}
                    />
                );
            }
        });
    }

    render() {
        return (
            <Fragment>
                <br></br>
                <br></br>
                <h3>Table Headers</h3>
                <Table>
                    <thead>
                        <tr>{this.getHeaders()}</tr>
                    </thead>
                </Table>
            </Fragment>
        );
    }
}

export default TableHeadersExamples;
