import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import TextWithIcon from '../common/TextWithIcon';
import SuccessIconWrapper from '../common/SuccessIconWrapper';
import PropTypes from 'prop-types';
import useScreenSize from '../../hooks/useScreenSize';

function ProgressBar(props) {
    const intl = useIntl();
    const { isTabletView } = useScreenSize();

    function getIcon(isComplete) {
        return <SuccessIconWrapper isCompleted={isComplete} />;
    }

    function getHeaderText() {
        if (!props.createAccountComplete) {
            return intl.formatMessage({ id: 'Common.CreateAccount' });
        } else if (!props.subscriptionComplete) {
            return intl.formatMessage({ id: 'Common.SubscriptionAndPayment' });
        } else {
            return intl.formatMessage({ id: 'Common.AccountSetup' });
        }
    }

    return (
        <Fragment>
            <div className="d-flex rd-progress-bar">
                <div>
                    <TextWithIcon
                        text={isTabletView ? '' : intl.formatMessage({ id: 'Common.CreateAccount' })}
                        icon={getIcon(props.createAccountComplete)}
                    />
                </div>
                <div className="horizontal-splitter"></div>
                <div>
                    <TextWithIcon
                        text={isTabletView ? '' : intl.formatMessage({ id: 'Common.SubscriptionAndPayment' })}
                        icon={getIcon(props.subscriptionComplete)}
                    />
                </div>
                <div className="horizontal-splitter"></div>
                <div>
                    <TextWithIcon
                        text={isTabletView ? '' : intl.formatMessage({ id: 'Common.AccountSetup' })}
                        icon={getIcon(props.accountSetupComplete)}
                    />
                </div>
            </div>
            {isTabletView && <h5 className="m-3 font-weight-normal">{getHeaderText()}</h5>}
        </Fragment>
    );
}

ProgressBar.propTypes = {
    createAccountComplete: PropTypes.bool,
    subscriptionComplete: PropTypes.bool,
    accountSetupComplete: PropTypes.bool,
};

export default ProgressBar;
