import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import { ReactComponent as WarningIcon } from '../../content/icons/Icon-Alert.svg';

function ValidationMessage(props) {
    return (
        <FormFeedback className={props.isBlock ? 'validation-message block' : 'validation-message'}>
            <WarningIcon />
            <div className="validation-message-text" data-testid="validation-message-text">
                {props.message}
            </div>
        </FormFeedback>
    );
}

ValidationMessage.propTypes = {
    message: PropTypes.node.isRequired,
    isBlock: PropTypes.bool,
};

export default ValidationMessage;
