import { useState } from 'react';
import { useForm } from 'react-hook-form';

function useValidation() {
    const { register, errors, trigger, getValues, setValue, control, reset } = useForm({ mode: 'onBlur' });
    const [validationTriggered, setValidationTriggered] = useState(false);
    const submitDisabled = Object.keys(errors).length > 0 && validationTriggered;
    const errorCount = Object.keys(errors).length;

    function submit(doSubmit) {
        const values = Object.keys(getValues());
        const validations = [];
        values.forEach((v) => {
            validations.push(trigger(v));
        });
        Promise.all(validations).then((results) => {
            if (!results.includes(false)) {
                doSubmit();
            }
            setValidationTriggered(true);
        });
    }

    return { register, errors, submitDisabled, submit, errorCount, setValue, control, getValues, reset };
}

export default useValidation;
