class BreadCrumbOption {
    constructor(title, pathname, search, state, isExternal) {
        this.title = title;
        this.pathname = pathname;
        this.state = state;
        this.search = search;
        this.isExternal = isExternal ? isExternal : false;
    }
}

export default BreadCrumbOption;
