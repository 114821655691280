import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class VoucherService {
    static getVouchers(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Vouchers`
        ).then((response) => response.data);
    }

    static createVoucher(deploymentId, restaurantId, voucherDto) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Voucher`,
            JSON.stringify(voucherDto)
        ).then((response) => response.data);
    }

    static updateVoucher(deploymentId, restaurantId, voucherDto) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Voucher`,
            JSON.stringify(voucherDto)
        ).then((response) => response.data);
    }

    static deleteVoucher(deploymentId, restaurantId, voucherId) {
        return ApiHelper.deleteRequest(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Voucher/${voucherId}`
        );
    }

    static validateVoucher(deploymentId, restaurantId, voucherRef) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/ValidateVoucher?voucherReference=${voucherRef}`
        ).then((response) => response.data);
    }

    static redeemVoucherAgainstBooking(deploymentId, restaurantId, bookingId, voucherRef) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/RedeemVoucherAgainstBooking?bookingId=${bookingId}&voucherReference=${voucherRef}`
        ).then((response) => response.data);
    }

    static getVoucherSalesSetting(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Voucher/Sales`
        ).then((response) => response.data);
    }

    static setVoucherSalesSetting(deploymentId, restaurantId, isEnabled) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Voucher/Sales`,
            { IsEnabled: isEnabled }
        );
    }

    static getVoucherStats(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Vouchers/Stats`
        ).then((response) => response.data);
    }

    static getVoucherReport(deploymentId, restaurantId, requestData) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Vouchers/Report`,
            JSON.stringify(requestData)
        ).then((response) => response.data);
    }

    static createInternalVoucherOrder(deploymentId, restaurantId, dto) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Vouchers/InternalVoucherOrder`,
            JSON.stringify(dto)
        ).then((response) => response.data);
    }

    static getMinimumValidityPeriod(providerId, deploymentId) {
        return ApiHelper.get(
            `/api/Voucher/MinimumValidityPeriod?providerId=${providerId}&deploymentId=${deploymentId}`
        ).then((response) => response.data);
    }

    static resendVoucher(deploymentId, restaurantId, voucherOrderId, emailAddress) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Voucher/Resend`,
            JSON.stringify({ VoucherOrderId: voucherOrderId, EmailAddress: emailAddress })
        ).then((response) => response.data);
    }

    static getVoucherByReference(restaurantId, deploymentId, voucherRef) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/SoldVoucher/${voucherRef}`
        ).then((response) => response.data);
    }

    static redeemVoucher(restaurantId, deploymentId, voucherRef) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/RedeemVoucher?voucherReference=${voucherRef}`
        ).then((response) => response.data);
    }

    static addReportExport(deploymentId, restaurantId, requestData) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/ReportExport/v1/Report/${deploymentId}/${restaurantId}/Export`,
            JSON.stringify(requestData)
        ).then((response) => response.data);
    }

    static getExportedReport(deploymentId, restaurantId, exportedRequestId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/ReportExport/v1/Report/${deploymentId}/${restaurantId}/Export/${exportedRequestId}`
        ).then((response) => response.data);
    }

    static getExportedCsv(deploymentId, restaurantId, exportedRequestId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/ReportExport/v1/Report/${deploymentId}/${restaurantId}/Export/${exportedRequestId}/Csv`
        );
    }
}

export default VoucherService;
