import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class ServiceSettingsService {
    static getServices(deploymentId, restaurantId, segmentId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Service/GetAll/${segmentId}`
        ).then((response) => response.data);
    }

    static updateService(deploymentId, restaurantId, service) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Service/Update`,
            JSON.stringify(service)
        ).then((response) => response.data);
    }

    static DeleteService(deploymentId, restaurantId, serviceId) {
        return ApiHelper.deleteRequest(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Service/Delete/${serviceId}`
        );
    }

    static UpdateServiceEnabledStatus(deploymentId, restaurantId, serviceId, isEnabled) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Service/UpdateServiceEnabledStatus`,
            { ServiceId: serviceId, IsEnabled: isEnabled }
        ).then((response) => response.data);
    }
}

export default ServiceSettingsService;
