import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DiaryContext from '../../contexts/DiaryContext';
import { Button } from 'reactstrap';
import { ReactComponent as EditIcon } from '../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../content/icons/Icon-Delete.svg';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import TextWithIcon from '../common/TextWithIcon';
import { ReactComponent as ClockIcon } from '../../content/icons/Icon-Clock.svg';

function VoucherPreviewCard(props) {
    const intl = useIntl();
    const diaryContext = useContext(DiaryContext);

    function GetMonthsValidText() {
        if (props.voucher.monthsValid === 0) {
            return intl.formatMessage({ id: 'Vouchers.VoucherNoExpiry' });
        }
        if (props.voucher.monthsValid === 1) {
            return intl.formatMessage({ id: 'Vouchers.VoucherExpiryMonth' });
        }
        if (props.voucher.monthsValid > 1) {
            return intl.formatMessage({ id: 'Vouchers.VoucherExpiryMonths' }, { months: props.voucher.monthsValid });
        }
    }

    return (
        <div className="voucher-review-entry">
            <div>
                <div className="voucher-header">
                    <div className="voucher-info">
                        <div className="voucher-cost">{`${CurrencyHelper.getCurrencySignWithSymbol(
                            diaryContext.currencySymbol
                        )}${props.voucher.cost}`}</div>
                        <div className="voucher-title">{props.voucher.title}</div>
                    </div>
                    {props.displayActionButtons && (
                        <div className="action-buttons">
                            <Button onClick={() => props.updateVoucher(props.voucher.id)}>{<EditIcon />}</Button>
                            <Button
                                id={'deleteVoucher' + props.voucher.uniqueIdentifier}
                                onClick={() => props.openDeletionModal(props.voucher)}
                            >
                                <DeleteIcon />
                            </Button>
                        </div>
                    )}
                </div>

                <div className="voucher-expiry">
                    <TextWithIcon text={GetMonthsValidText()} icon={<ClockIcon />} iconClass={'clock-icon'} />
                </div>
            </div>
        </div>
    );
}

VoucherPreviewCard.propTypes = {
    voucher: PropTypes.object.isRequired,
    updateVoucher: PropTypes.func,
    deleteVoucher: PropTypes.func,
    displayActionButtons: PropTypes.bool,
};

export default VoucherPreviewCard;
