import React, { useState, useEffect, Fragment, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Label, FormGroup, Form, Button, CustomInput } from 'reactstrap';
import Section from '../../common/Section';
import ValidationInput from '../../common/ValidationInput';
import ValidationMessage from '../../common/ValidationMessage';
import useValidation from '../../../hooks/useValidation';
import Dropdown from '../../common/Dropdown';
import ListingDetails from '../../../domainObjects/RestaurantSettings/ListingDetails';
import ValidationSummary from '../../common/ValidationSummary';
import ValidationDropdown from '../../common/ValidationDropdown';
import Cuisine from '../../../domainObjects/RestaurantSettings/Cuisine';
import Currency from '../../../domainObjects/RestaurantSettings/Currency';
import DressCode from '../../../domainObjects/RestaurantSettings/DressCode';
import Facility from '../../../domainObjects/RestaurantSettings/Facility';
import ProviderImageCard from '../logoAndImages/ProviderImageCard';
import propTypeExtensions from '../../../helpers/PropTypeExtensions';
import ImageUploading from 'react-images-uploading';
import SettingsService from '../../../services/SettingsService';
import ImageService from '../../../services/ImageService';
import DiaryContext from '../../../contexts/DiaryContext';
import ProviderProgressFlagsContext from '../../../contexts/ProviderProgressFlagsContext';
import useScreenSize from '../../../hooks/useScreenSize';
import ProviderImage from '../../../domainObjects/ProviderImage';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import ImproveListingTasksCompleteModal from '../../common/ImproveListingTasksCompleteModal';
import isoCountryCurrency from 'iso-country-currency';
import validator from 'validator';

function MyListingTab(props) {
    const intl = useIntl();
    const [isSaving, setIsSaving] = useState(false);
    const [venueDescription, setVenueDescription] = useState('');
    const [openingInformation, setOpeningInformation] = useState('');
    const [cuisines, setCuisines] = useState([]);
    const [currency, setCurrency] = useState('');
    const [pricePoint, setPricePoint] = useState('');
    const [dressCodes, setDressCodes] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [facebookUrl, setFacebookUrl] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [instagramUrl, setInstagramUrl] = useState('');
    const displayClass = props.isActive ? '' : 'hidden';
    const [initiating, setInitiating] = useState(true);
    const [providerLogo, setProviderLogo] = useState(null);
    const [isReserveWithGoogleEnabled, setIsReserveWithGoogleEnabled] = useState(null);
    const [hideOnPortal, setHideOnPortal] = useState(null);
    const formValidation = useValidation();
    const diaryContext = useContext(DiaryContext);
    const providerProgressFlagsContext = useContext(ProviderProgressFlagsContext);
    const [improveListingTasksCompleteModalOpen, setImproveListingTasksCompleteModalOpen] = useState(false);

    const { isTabletView, isMobileView } = useScreenSize();

    const validateLogo = useCallback(
        (logo) => {
            if (!logo) {
                return intl.formatMessage({ id: 'AccountSubmission.LogoRequiredValidationMessage' });
            }
            return true;
        },
        [intl]
    );

    const validateListingActivation = useCallback(
        (activeListing) => {
            if (
                !activeListing ||
                activeListing.isReserveWithGoogleEnabled === null ||
                activeListing.hideOnPortal === null
            ) {
                return intl.formatMessage({ id: 'AccountSubmission.ListingActivationRequiredValidationMessage' });
            }
            return true;
        },
        [intl]
    );

    useEffect(() => {
        if (!initiating && props.isOnboarding && isReserveWithGoogleEnabled !== null && hideOnPortal !== null) {
            formValidation.setValue(
                'listingActivation',
                { isReserveWithGoogleEnabled: isReserveWithGoogleEnabled, hideOnPortal: hideOnPortal },
                { shouldValidate: true, shouldDirty: true }
            );
        }
    }, [props.isOnboarding, isReserveWithGoogleEnabled, hideOnPortal, initiating, formValidation]);

    useEffect(() => {
        if (initiating && props.isOnboarding) {
            formValidation.register({ name: 'logo' }, { validate: validateLogo });
            formValidation.register({ name: 'listingActivation' }, { validate: validateListingActivation });
            const addErrorBar = props.addErrorBar;
            ImageService.getImagesForProvider(diaryContext.deploymentId, diaryContext.restaurantId)
                .then((response) => {
                    const image = response[0];
                    if (image) {
                        let logo = new ProviderImage(image);
                        setProviderLogo(logo);
                        formValidation.setValue('logo', logo);
                    }
                })
                .catch(() => {
                    addErrorBar();
                });
        }
        setInitiating(false);
    }, [
        formValidation,
        initiating,
        props.isOnboarding,
        validateLogo,
        validateListingActivation,
        props.addErrorBar,
        diaryContext.deploymentId,
        diaryContext.restaurantId,
    ]);

    useEffect(() => {
        if (props.allDetails !== (null || {})) {
            setVenueDescription(props.allDetails.venueDescription);
            setOpeningInformation(props.allDetails.openingInformation);
            setCuisines(props.allDetails.cuisineTypes);
            setCurrency(props.allDetails.isoCurrencySymbol);
            setPricePoint(props.allDetails.pricePoint);
            setDressCodes(props.allDetails.dressCode);
            setFacilities(props.allDetails.facilities);
            setFacebookUrl(props.allDetails.facebookUrl);
            setTwitterUrl(props.allDetails.twitterUrl);
            setYoutubeUrl(props.allDetails.youtubeUrl);
            setInstagramUrl(props.allDetails.instagramUrl);
            // Checking if venueDescription is set so that we can unset all radio buttons to force a choice from the user
            if (props.isOnboarding && !props.allDetails.venueDescription) {
                setIsReserveWithGoogleEnabled(null);
                setHideOnPortal(null);
            } else {
                setIsReserveWithGoogleEnabled(props.allDetails.isReserveWithGoogleEnabled);
                setHideOnPortal(props.allDetails.hideOnPortal);
            }
        }
    }, [props.allDetails, props.isOnboarding]);

    useEffect(() => {
        if (props.isOnboarding) {
            try {
                setCurrency(isoCountryCurrency.getParamByParam('countryName', props.allDetails.country, 'currency'));
            } catch {}
        }
    }, [props.isOnboarding, props.allDetails]);

    function getPricePoints() {
        let currencyList = getCurrencies();
        if (currencyList.length !== 0 && currency !== null && currencyList.find((o) => o.value === currency)) {
            let symbol = currencyList.find((o) => o.value === currency).symbol;
            if (symbol !== '&nbsp;') {
                return [
                    { value: 1, text: decodeCurrencySymbol(symbol) },
                    { value: 2, text: decodeCurrencySymbol(symbol + symbol) },
                    { value: 3, text: decodeCurrencySymbol(symbol + symbol + symbol) },
                    { value: 4, text: decodeCurrencySymbol(symbol + symbol + symbol + symbol) },
                    { value: 5, text: decodeCurrencySymbol(symbol + symbol + symbol + symbol + symbol) },
                ];
            }
        }
        return [
            { value: 1, text: '£' },
            { value: 2, text: '££' },
            { value: 3, text: '£££' },
            { value: 4, text: '££££' },
            { value: 5, text: '£££££' },
        ];
    }

    function onProviderLogoChange(imageList) {
        let imageToUpload = imageList[0];
        if (imageToUpload) {
            if (providerLogo) {
                deleteImage(providerLogo, imageToUpload);
            } else {
                uploadRestaurantImage(imageToUpload);
            }
        }
    }

    function uploadRestaurantImage(imageToUpload) {
        const data = new FormData();
        data.append('file', imageToUpload.file);
        const loadingImage = new ProviderImage({
            ImageUrl: imageToUpload.data_url,
            Name: imageToUpload.file.name,
            IsEnabled: true,
            IsMainImage: true,
        });
        setProviderLogo(loadingImage);

        function updateUploadPercentage(loadingImage, percentage) {
            const updatedLogo = { ...loadingImage };
            updatedLogo.loadingPercentage = percentage;
            setProviderLogo(updatedLogo);
        }

        ImageService.uploadRestaurantImages(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            data,
            (percentage) => {
                updateUploadPercentage(loadingImage, percentage);
            }
        )
            .then((response) => {
                const image = response[0];
                let updatedImage = new ProviderImage(image);
                updatedImage.url = loadingImage.url;
                setAsMainImage(updatedImage.id);
                setProviderLogo(updatedImage);
                formValidation.setValue('logo', updatedImage, { shouldValidate: true, shouldDirty: true });
            })
            .catch(() => {
                props.addErrorBar(intl.formatMessage({ id: 'Settings.ErrorUploadingImages' }));
            });
    }

    function setAsMainImage(imageId) {
        ImageService.setImageAsMainImage(diaryContext.deploymentId, diaryContext.restaurantId, imageId).catch(() => {
            props.addErrorBar(intl.formatMessage({ id: 'Settings.ErrorUpdatingMainImageMessage' }));
        });
    }

    function deleteImage(image, imageToUpload) {
        ImageService.deleteImages(diaryContext.deploymentId, diaryContext.restaurantId, [image.id])
            .then(async () => {
                setProviderLogo(null);
                if (imageToUpload) {
                    await uploadRestaurantImage(imageToUpload);
                } else {
                    formValidation.setValue('logo', null, { shouldValidate: true, shouldDirty: true });
                }
            })
            .catch(() => {
                props.addErrorBar(intl.formatMessage({ id: 'Settings.ErrorDeletingSingleImageMessage' }));
            });
    }

    function decodeCurrencySymbol(text) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }

    function setSelectedCuisines(cuisine) {
        let cuisineCopy = [...cuisines];
        cuisineCopy.push(cuisine);
        setCuisines(cuisineCopy);
    }

    function popCuisines() {
        setCuisines([]);
    }

    function popSelectedCuisines(cuisine) {
        let cuisineCopy = [...cuisines];
        let index = cuisineCopy.indexOf(cuisine);
        cuisineCopy.splice(index, 1);
        setCuisines(cuisineCopy);
    }

    function setSelectedDressCode(dressCode) {
        let dressCodeCopy = [...dressCodes];
        dressCodeCopy.push(dressCode);
        setDressCodes(dressCodeCopy);
    }

    function popSelectedDressCode(dressCode) {
        let dressCodeCopy = [...dressCodes];
        let index = dressCodeCopy.indexOf(dressCode);
        dressCodeCopy.splice(index, 1);
        setDressCodes(dressCodeCopy);
    }

    function popDressCode() {
        setDressCodes([]);
    }

    function setSelectedFacilities(facility) {
        let facilitiesCopy = [...facilities];
        facilitiesCopy.push(facility);
        setFacilities(facilitiesCopy);
    }

    function popFacilities() {
        setFacilities([]);
    }

    function popSelectedFacilities(facility) {
        let facilitiesCopy = [...facilities];
        let index = facilitiesCopy.indexOf(facility);
        facilitiesCopy.splice(index, 1);
        setFacilities(facilitiesCopy);
    }

    function getDTO() {
        const listingDetails = new ListingDetails();
        return listingDetails.getDTO(
            venueDescription,
            openingInformation,
            currency,
            dressCodes,
            pricePoint,
            cuisines,
            facilities,
            twitterUrl,
            facebookUrl,
            youtubeUrl,
            instagramUrl,
            isReserveWithGoogleEnabled,
            hideOnPortal
        );
    }

    function getAnalyticsListingProperties() {
        const socialMediaProperties = [facebookUrl, twitterUrl, youtubeUrl, instagramUrl];
        return {
            completedListing: providerProgressFlagsContext.addedAdvancedListing,
            completedMenus: providerProgressFlagsContext.uploadedMenu,
            completedImages: providerProgressFlagsContext.addedAdditionalImage,
            dishCultActivation: !hideOnPortal,
            rwgActivation: isReserveWithGoogleEnabled,
            dressCode: dressCodes,
            facilities: facilities,
            socialMedia: socialMediaProperties,
        };
    }

    function updateCurrency(currency) {
        setCurrency(currency);
    }

    function updatePricePoint(pricePoint) {
        setPricePoint(pricePoint);
    }

    function getFacilities() {
        let facilityList = props.allFacilities.map((facility) => new Facility(facility));
        return sortDropdownOptions(facilityList);
    }

    function getCuisines() {
        return props.allCuisines.map((cuisine) => new Cuisine(cuisine));
    }

    function getDressCodes() {
        let dressCodes = props.allDressCodes.map((dressCode) => new DressCode(dressCode));
        return sortDropdownOptions(dressCodes);
    }

    function getCurrencies() {
        return props.allCurrencies.map((currency) => new Currency(currency));
    }

    function sortDropdownOptions(list) {
        return list.sort((a, b) => (a.text > b.text ? 1 : -1));
    }

    function hasContentUpdated() {
        if (props.isOnboarding) {
            return (
                props.allDetails.venueDescription !== venueDescription ||
                props.allDetails.openingInformation !== openingInformation ||
                JSON.stringify(props.allDetails.cuisineTypes) !== JSON.stringify(cuisines) ||
                isReserveWithGoogleEnabled !== null ||
                hideOnPortal !== null
            );
        } else
            return (
                props.allDetails.venueDescription !== venueDescription ||
                props.allDetails.openingInformation !== openingInformation ||
                JSON.stringify(props.allDetails.cuisineTypes) !== JSON.stringify(cuisines) ||
                props.allDetails.facebookUrl !== facebookUrl ||
                props.allDetails.twitterUrl !== twitterUrl ||
                props.allDetails.youtubeUrl !== youtubeUrl ||
                props.allDetails.instagramUrl !== instagramUrl ||
                props.allDetails.pricePoint !== pricePoint ||
                JSON.stringify(props.allDetails.facilities) !== JSON.stringify(facilities) ||
                JSON.stringify(props.allDetails.dressCode) !== JSON.stringify(dressCodes) ||
                props.allDetails.isoCurrencySymbol !== currency ||
                props.allDetails.isReserveWithGoogleEnabled !== isReserveWithGoogleEnabled ||
                props.allDetails.hideOnPortal !== hideOnPortal
            );
    }

    function getListingPropertiesForTracking() {
        return {
            venueCurrency: currency,
            venuePricePoint: pricePoint,
            venueCuisineIds: cuisines,
        };
    }

    function trackListingPropertiesAndMoveTab() {
        AnalyticsHelper.trackClickWithProperties('Web SetUp Listing', getDTO());
        AnalyticsHelper.identifyUserIdWithProperties(
            diaryContext.restaurantId,
            getListingPropertiesForTracking(),
            {},
            props.changeToNextTab
        );
    }

    function updateDetails() {
        setIsSaving(true);
        SettingsService.setListingDetails(diaryContext.deploymentId, diaryContext.restaurantId, getDTO())
            .then(() => {
                const allDetails = { ...props.allDetails };
                allDetails.isReserveWithGoogleEnabled = isReserveWithGoogleEnabled;
                allDetails.hideOnPortal = hideOnPortal;
                props.setAllDetails(allDetails);
                props.addSuccessBar(intl.formatMessage({ id: 'Settings.SuccessfullySavedVenueDetailsMessage' }));
                if (props.changeToNextTab) {
                    trackListingPropertiesAndMoveTab();
                }
            })
            .catch(() => {
                props.addErrorBar();
            })
            .finally(() => {
                if (!providerProgressFlagsContext.addedAdvancedListing && !props.isOnboarding) {
                    providerProgressFlagsContext.updatedProviderProgressFlag('addedAdvancedListing');
                    if (
                        providerProgressFlagsContext.uploadedMenu &&
                        providerProgressFlagsContext.addedAdditionalImage &&
                        !providerProgressFlagsContext.dismissedCompletionModal
                    ) {
                        setImproveListingTasksCompleteModalOpen(true);
                    }
                }
                if (!props.changeToNextTab) {
                    AnalyticsHelper.trackClickWithProperties('Web Update Listing', getAnalyticsListingProperties());
                    AnalyticsHelper.identifyUserIdWithProperties(
                        diaryContext.restaurantId,
                        getListingPropertiesForTracking()
                    );

                    if (diaryContext && props.allDetails) {
                        diaryContext.hideOnPortal = !props.allDetails.hideOnPortal;
                    }
                }
                setIsSaving(false);
            });
    }

    function isButtonDisabled() {
        if (props.isOnboarding) {
            return isSaving || formValidation.submitDisabled;
        }

        return isSaving || formValidation.submitDisabled || !hasContentUpdated();
    }

    function checkValidUrl(url) {
        if (url && !validator.isURL(url)) {
            return intl.formatMessage({ id: 'Settings.UrlValidationMessage' });
        }
    }

    function getPageBody() {
        if (isTabletView) {
            return getPageContent();
        }

        return <Section sectionTitle={intl.formatMessage({ id: 'Settings.MyListing' })}>{getPageContent()}</Section>;
    }

    function getPageContent() {
        return (
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    formValidation.submit(updateDetails);
                }}
            >
                <div className="collapsible-panel-body">
                    {props.isOnboarding && (
                        <div className="panel-description">
                            <FormattedMessage id="AccountSubmission.MyListingDescription" />
                        </div>
                    )}

                    <div className="content-container top listing-activation ">
                        <h6>
                            <FormattedMessage id="Settings.ListingActivation" />
                        </h6>
                        <div className="pt-2">
                            <FormattedMessage id="Settings.ListingActivationDescription" />
                            <div className={isMobileView ? 'listing-toggle mobile' : 'listing-toggle'}>
                                <div>
                                    <div className="listing-cell">
                                        <FormattedMessage id="Common.DishCult" />
                                    </div>
                                    <div className="listing-cell">
                                        <FormattedMessage id="Common.ReserveWithGoogle" />
                                    </div>
                                </div>
                                <div>
                                    <CustomInput
                                        id={`active-radio-dish-cult`}
                                        checked={hideOnPortal === false}
                                        onChange={() => {
                                            setHideOnPortal(false);
                                        }}
                                        type="radio"
                                        name={`active-radio-dish-cult`}
                                        label={intl.formatMessage({
                                            id: 'Settings.Active',
                                        })}
                                        className="listing-cell"
                                    />
                                    <CustomInput
                                        id={`active-radio-rwg`}
                                        checked={isReserveWithGoogleEnabled === true}
                                        onChange={() => {
                                            setIsReserveWithGoogleEnabled(true);
                                        }}
                                        type="radio"
                                        name={`active-radio-rwg`}
                                        label={intl.formatMessage({
                                            id: 'Settings.Active',
                                        })}
                                        className="listing-cell"
                                    />
                                </div>
                                <div>
                                    <CustomInput
                                        id={`disabled-radio-dish-cult`}
                                        checked={hideOnPortal === true}
                                        onChange={() => {
                                            setHideOnPortal(true);
                                        }}
                                        type="radio"
                                        name={`disabled-radio-dish-cult`}
                                        label={intl.formatMessage({
                                            id: 'Settings.Inactive',
                                        })}
                                        className="listing-cell"
                                    />
                                    <CustomInput
                                        id={`disabled-radio-rwg`}
                                        checked={isReserveWithGoogleEnabled === false}
                                        onChange={() => {
                                            setIsReserveWithGoogleEnabled(false);
                                        }}
                                        type="radio"
                                        name={`disabled-radio-rwg`}
                                        label={intl.formatMessage({
                                            id: 'Settings.Inactive',
                                        })}
                                        className="listing-cell"
                                    />
                                </div>
                            </div>
                            <div className="validation-summary">
                                {formValidation.errorCount > 0 && formValidation.errors['listingActivation'] && (
                                    <ValidationMessage message={formValidation.errors['listingActivation'].message} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="font-italic">
                        <FormattedMessage id="Settings.RequiredInformation" />
                    </div>
                    <FormGroup>
                        <div className="content-container top">
                            <h6>
                                <FormattedMessage id="Settings.MainDetails" />
                            </h6>
                            <Label for="restaurantDescription" id="restaurantDescription">
                                <FormattedMessage id="Settings.RestaurantDescription" />
                            </Label>
                            <div className="details-container">
                                <ValidationInput
                                    type="textarea"
                                    name="restaurantDescription"
                                    value={venueDescription}
                                    onChange={(e) => setVenueDescription(e)}
                                    innerRef={formValidation.register({
                                        required: intl.formatMessage({
                                            id: 'Settings.RestaurantDescriptionRequired',
                                        }),
                                    })}
                                    errors={formValidation.errors}
                                    maxLength="4000"
                                    className="textarea-lg"
                                    displayCharacterCount
                                    ariaLabelledBy="restaurantDescription"
                                    ariaRequired
                                />
                                <ValidationDropdown
                                    formValidation={formValidation}
                                    name="cuisines"
                                    validationRules={{
                                        validate: (value) => {
                                            if (!value || !value.length)
                                                return intl.formatMessage({ id: 'Settings.CuisineTypeRequired' });
                                        },
                                    }}
                                    isSearchable
                                    multiSelect
                                    onChange={setSelectedCuisines}
                                    defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                                    options={getCuisines()}
                                    selectedValues={cuisines}
                                    title={intl.formatMessage({ id: 'Settings.CuisineTypes' })}
                                    onMultiSelectRemoved={popSelectedCuisines}
                                    onClearSelectedValues={popCuisines}
                                />
                            </div>
                        </div>
                        <div className="content-container">
                            <h6>
                                <FormattedMessage id="Settings.OpeningHours" />
                            </h6>
                            <Label for="OpeningHours" id="OpeningHours">
                                <FormattedMessage id="Settings.OpeningHours" />
                            </Label>
                            <div className="details-container">
                                <ValidationInput
                                    type="textarea"
                                    name="openingHours"
                                    value={openingInformation}
                                    onChange={(e) => setOpeningInformation(e)}
                                    innerRef={formValidation.register({
                                        required: intl.formatMessage({ id: 'Settings.OpeningHoursValidationText' }),
                                    })}
                                    errors={formValidation.errors}
                                    maxLength="4000"
                                    className="textarea-lg"
                                    displayCharacterCount
                                    ariaLabelledBy="OpeningHours"
                                    ariaRequired
                                />
                            </div>
                        </div>
                        <div className="content-container">
                            <h6>
                                <FormattedMessage id="Settings.Pricing" />
                            </h6>

                            <ValidationDropdown
                                formValidation={formValidation}
                                name="currency"
                                validationRules={{
                                    required: intl.formatMessage({ id: 'Settings.CurrencyRequired' }),
                                }}
                                isSearchable
                                hideRemoveItemIcon
                                onChange={updateCurrency}
                                defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                                options={getCurrencies()}
                                selectedValue={currency}
                                title={intl.formatMessage({ id: 'Settings.Currency' })}
                            />

                            <ValidationDropdown
                                formValidation={formValidation}
                                name="pricePoint"
                                validationRules={{
                                    required: intl.formatMessage({ id: 'Settings.PricePointRequired' }),
                                }}
                                hideRemoveItemIcon
                                onChange={updatePricePoint}
                                defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                                options={getPricePoints()}
                                selectedValue={pricePoint}
                                title={intl.formatMessage({ id: 'Settings.PricePoint' })}
                            />
                        </div>
                        {!props.isOnboarding && (
                            <Fragment>
                                <div
                                    className={
                                        !providerProgressFlagsContext.addedAdvancedListing
                                            ? 'content-container add-advanced-listing'
                                            : 'content-container'
                                    }
                                >
                                    <h6>
                                        <FormattedMessage id="Settings.AdditionalInformation" />
                                    </h6>
                                    <Dropdown
                                        multiSelect
                                        onValueChange={setSelectedDressCode}
                                        defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                                        options={getDressCodes()}
                                        selectedValues={dressCodes}
                                        onClearSelectedValues={popDressCode}
                                        onMultiSelectRemoved={popSelectedDressCode}
                                        title={intl.formatMessage({ id: 'Settings.DressCode' })}
                                    />
                                    <Dropdown
                                        multiSelect
                                        isSearchable
                                        onValueChange={setSelectedFacilities}
                                        defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                                        options={getFacilities()}
                                        selectedValues={facilities}
                                        onMultiSelectRemoved={popSelectedFacilities}
                                        onClearSelectedValues={popFacilities}
                                        title={intl.formatMessage({ id: 'Settings.Facilities' })}
                                    />
                                </div>
                                <div
                                    className={
                                        !providerProgressFlagsContext.addedAdvancedListing
                                            ? 'content-container add-advanced-listing'
                                            : 'content-container'
                                    }
                                >
                                    <h6>
                                        <FormattedMessage id="Settings.SocialMedia" />
                                    </h6>
                                    <Label for="facebook" id="facebook">
                                        <FormattedMessage id="Settings.Facebook" />
                                    </Label>
                                    <ValidationInput
                                        type="text"
                                        name="facebook"
                                        value={facebookUrl}
                                        onChange={(e) => setFacebookUrl(e)}
                                        innerRef={formValidation.register({
                                            validate: (value) => checkValidUrl(value),
                                        })}
                                        errors={formValidation.errors}
                                        maxLength="255"
                                        ariaLabelledBy="facebook"
                                    />
                                    <Label for="twitter" id="twitter">
                                        <FormattedMessage id="Settings.Twitter" />
                                    </Label>
                                    <ValidationInput
                                        type="text"
                                        name="twitter"
                                        value={twitterUrl}
                                        onChange={(e) => setTwitterUrl(e)}
                                        innerRef={formValidation.register({
                                            validate: (value) => checkValidUrl(value),
                                        })}
                                        errors={formValidation.errors}
                                        maxLength="255"
                                        ariaLabelledBy="twitter"
                                    />
                                    <Label for="youtube" id="youtube">
                                        <FormattedMessage id="Settings.Youtube" />
                                    </Label>
                                    <ValidationInput
                                        type="text"
                                        name="youtube"
                                        value={youtubeUrl}
                                        onChange={(e) => setYoutubeUrl(e)}
                                        innerRef={formValidation.register({
                                            validate: (value) => checkValidUrl(value),
                                        })}
                                        errors={formValidation.errors}
                                        maxLength="255"
                                        ariaLabelledBy="youtube"
                                    />
                                    <Label for="instagram" id="instagram">
                                        <FormattedMessage id="Settings.Instagram" />
                                    </Label>
                                    <ValidationInput
                                        type="text"
                                        name="instagram"
                                        value={instagramUrl}
                                        onChange={(e) => setInstagramUrl(e)}
                                        innerRef={formValidation.register({
                                            validate: (value) => checkValidUrl(value),
                                        })}
                                        errors={formValidation.errors}
                                        maxLength="255"
                                        ariaLabelledBy="instagram"
                                    />
                                </div>
                            </Fragment>
                        )}
                        {props.isOnboarding && (
                            <div className="content-container">
                                <h6>
                                    <FormattedMessage id="AccountSubmission.Logo" />
                                </h6>
                                <div className="content-description">
                                    <FormattedMessage id="AccountSubmission.LogoDescription" />
                                </div>
                                <div className="settings-images">
                                    <div className="bold-text">
                                        <FormattedMessage id="AccountSubmission.RestaurantLogoRequirementsTitle" />
                                    </div>
                                    <ul className="image-requirement-list">
                                        <li>
                                            <FormattedMessage id="Settings.RestaurantImageMaxFileSizeRequirement" />
                                        </li>
                                        <li>
                                            <FormattedMessage id="Settings.RestaurantImageMinSizeRequirement" />
                                        </li>
                                        <li>
                                            <FormattedMessage id="Settings.RestaurantImageFileTypeRequirement" />
                                        </li>
                                        <li>
                                            <FormattedMessage id="AccountSubmission.LogoSquareRequirement" />
                                        </li>
                                    </ul>
                                    <div className="image-container onboarding">
                                        <ImageUploading
                                            value={providerLogo ? [providerLogo] : []}
                                            onChange={onProviderLogoChange}
                                            dataURLKey="data_url"
                                            maxFileSize={5000000}
                                            acceptType={['jpg', 'jpeg', 'gif', 'png']}
                                        >
                                            {({ imageList, onImageUpload }) => (
                                                <Fragment>
                                                    <div className="provider-images-wrapper">
                                                        {imageList.map((image) => (
                                                            <ProviderImageCard
                                                                key={image.uniqueIdentifier}
                                                                image={image}
                                                                isOnboarding={props.isOnboarding}
                                                                deleteImage={deleteImage}
                                                            />
                                                        ))}
                                                    </div>
                                                    <div className="upload-button">
                                                        <Button
                                                            block
                                                            className="btn-dashed upload"
                                                            color="default"
                                                            onClick={onImageUpload}
                                                        >
                                                            <FormattedMessage id="AccountSubmission.UploadLogo" />
                                                        </Button>
                                                    </div>
                                                    <div className="validation-summary">
                                                        {formValidation.errorCount > 0 &&
                                                            formValidation.errors['logo'] && (
                                                                <ValidationMessage
                                                                    message={formValidation.errors['logo'].message}
                                                                />
                                                            )}
                                                    </div>
                                                </Fragment>
                                            )}
                                        </ImageUploading>
                                    </div>
                                </div>
                            </div>
                        )}
                    </FormGroup>
                </div>
                <div className="panel-footer button-wrapper">
                    {props.isOnboarding && (
                        <Button className="btn-outline-primary mr-2" onClick={() => props.backToPreviousTab()}>
                            {intl.formatMessage({
                                id: 'Common.Back',
                            })}
                        </Button>
                    )}
                    <input
                        disabled={isButtonDisabled()}
                        type="submit"
                        value={
                            props.isOnboarding
                                ? intl.formatMessage({
                                      id: 'Common.SaveAndContinue',
                                  })
                                : props.isSaving
                                ? intl.formatMessage({
                                      id: 'Common.Updating',
                                  })
                                : intl.formatMessage({
                                      id: 'Common.Update',
                                  })
                        }
                        className="btn btn-primary"
                    />
                    <ValidationSummary
                        errorCount={formValidation.errorCount}
                        submitDisabled={formValidation.submitDisabled}
                    />
                </div>
            </Form>
        );
    }

    return (
        <Fragment>
            <div className={displayClass}>{getPageBody()}</div>
            {improveListingTasksCompleteModalOpen && (
                <ImproveListingTasksCompleteModal
                    isModalOpen={improveListingTasksCompleteModalOpen}
                    setIsModalOpen={setImproveListingTasksCompleteModalOpen}
                />
            )}
        </Fragment>
    );
}

MyListingTab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    setAllDetails: PropTypes.func.isRequired,
    allDetails: PropTypes.object.isRequired,
    allCurrencies: PropTypes.array.isRequired,
    allCuisines: PropTypes.array.isRequired,
    allFacilities: propTypeExtensions.requiredIf(PropTypes.array, (props) => !props.isOnboarding),
    allDressCodes: propTypeExtensions.requiredIf(PropTypes.array, (props) => !props.isOnboarding),
    isOnboarding: PropTypes.bool,
    backToPreviousTab: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.isOnboarding),
    changeToNextTab: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.isOnboarding),
};

export default MyListingTab;
