import React from 'react';
import { InputGroup, InputGroupButtonDropdown, Button, DropdownToggle } from 'reactstrap';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import DropdownMenuWrapper from './DropdownMenuWrapper.js';
import { ReactComponent as CloseIcon } from '../../content/icons/close.svg';
import propTypeExtensions from '../../helpers/PropTypeExtensions';
import ReactDOM from 'react-dom';

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovering: false,
            splitButtonOpen: false,
            searchTerm: '',
        };
        this.toggleSplit = this.toggleSplit.bind(this);
        this.filterSearch = this.filterSearch.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.isDropdownDisabled = this.isDropdownDisabled.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.hasLabelDefault = this.hasLabelDefault.bind(this);
        this.onMultiSelectRemoved = this.onMultiSelectRemoved.bind(this);
        this.getDropDownHeader = this.getDropDownHeader.bind(this);
        this.scrollToSelected = this.scrollToSelected.bind(this);
    }

    filterSearch(searchTerm) {
        this.setState({ searchTerm: searchTerm.toLowerCase() });
    }

    getSelectedText() {
        const defaultValue = this.props.defaultValue ? this.props.defaultValue : '';
        let selectedOption;
        if (!this.props.selectedValue && this.props.selectedValue !== 0) {
            return defaultValue;
        } else if (this.props.optionGroups) {
            for (let optionGroup of this.props.optionGroups) {
                for (let option of optionGroup.options) {
                    if (option.value === this.props.selectedValue) {
                        selectedOption = option;
                    }
                }
            }
            return selectedOption ? selectedOption.text : defaultValue;
        } else if (this.props.options) {
            selectedOption = this.props.options.find((option) => option.value === this.props.selectedValue);
            if (this.props.isCountryCodeDropdown) {
                return selectedOption ? selectedOption.text.split(':')[1] : defaultValue;
            }
            return selectedOption ? selectedOption.text : defaultValue;
        } else {
            // Given the unlikely case the dropdown has been passed no options whatsoever
            return defaultValue;
        }
    }

    getSelectedMultiValues() {
        const defaultValue = this.props.defaultValue ? this.props.defaultValue : '';
        return this.props.selectedValues && this.props.selectedValues.length > 0 ? (
            this.props.selectedValues.map((selectedValue) => {
                let selectedOption;
                if (this.props.optionGroups) {
                    for (let optionGroup of this.props.optionGroups) {
                        selectedOption = optionGroup.options.find((option) => option.value === selectedValue);
                        if (selectedOption) {
                            break;
                        }
                    }
                } else if (this.props.options) {
                    selectedOption = this.props.options.find((option) => option.value === selectedValue);
                }
                return selectedOption ? (
                    <div key={selectedOption.text} className="btn-dark flexbox">
                        {selectedOption.text}
                        <button
                            type="button"
                            className="close-button"
                            onClick={(e) => {
                                this.onMultiSelectRemoved(e, selectedOption.value);
                            }}
                            aria-label="Close"
                        >
                            <div>{<CloseIcon />}</div>
                        </button>
                    </div>
                ) : (
                    ''
                );
            })
        ) : (
            <div className="multi-default-value">{defaultValue}</div>
        );
    }

    getUnselectedOptions() {
        return this.props.selectedValues
            ? this.props.options.filter((option) => !this.props.selectedValues.includes(option.value))
            : this.props.options;
    }

    isDropdownDisabled(unselectedOptions) {
        return this.props.isDropdownDisabled || (this.props.options && unselectedOptions.length === 0) ? true : false;
    }

    hasLabelDefault() {
        return (
            this.props.displaySearchableText &&
            !this.state.isHovering &&
            !this.props.parentOverriding &&
            !this.state.splitButtonOpen
        );
    }

    onMultiSelectRemoved(e, value) {
        // Prevent further event bubbling
        e.stopPropagation();
        this.props.onMultiSelectRemoved(value);
    }

    toggleSplit(e) {
        // Prevent further event bubbling
        e.stopPropagation();
        this.setState(
            {
                splitButtonOpen: !this.state.splitButtonOpen,
                searchTerm: '',
            },
            this.scrollToSelected
        );
    }

    onMouseOver() {
        this.setState({
            isHovering: true,
        });
    }

    onMouseLeave() {
        this.setState({
            isHovering: false,
        });
    }

    onValueChange(value) {
        this.setState({
            splitButtonOpen: !this.state.splitButtonOpen,
            searchTerm: '',
            isHovering: false,
        });
        // If the newly selected value matches the value currently selected, deselect the value
        value === this.props.selectedValue ? this.props.onValueChange(null) : this.props.onValueChange(value);
    }

    getDropDownHeader() {
        return (
            (this.props.multiSelect || this.props.title) && (
                <div
                    className={`${this.props.title ? 'd-flex justify-content-between' : 'd-flex justify-content-end'}`}
                >
                    {this.props.title && (
                        <div className="d-flex justify-content-start">
                            <label>{this.props.title} </label>
                        </div>
                    )}
                    {this.props.multiSelect && (
                        <div
                            className={`${
                                this.props.selectedValues && this.props.selectedValues.length > 0
                                    ? 'd-flex justify-content-end mb-1'
                                    : 'hidden'
                            }`}
                        >
                            <Button
                                color="link"
                                aria-label="Clear all"
                                onClick={() => this.props.onClearSelectedValues()}
                            >
                                {'Clear all'}
                            </Button>
                        </div>
                    )}
                </div>
            )
        );
    }

    scrollToSelected() {
        if (this.state.splitButtonOpen && !this.props.multiSelect) {
            const node = ReactDOM.findDOMNode(this);
            let selected = node.querySelector('.dropdown-item-selected');
            if (selected) {
                // Scroll dropdown menu to offset of the selected option
                selected.parentNode.parentNode.scrollTop = selected.parentNode.offsetTop;
            }
        }
    }

    render() {
        const isLoading = this.props.isLoading ? this.props.isLoading : false;
        const unselectedOptions = this.getUnselectedOptions();
        const isDropdownDisabled = this.isDropdownDisabled(unselectedOptions);
        const dropDownClasses = [];
        if (this.props.inputGroupClassName) {
            dropDownClasses.push(this.props.inputGroupClassName);
        }
        const invalidCssClass = this.props.errors && this.props.errors[this.props.name] ? 'is-invalid' : '';

        return (
            <div
                className={this.hasLabelDefault() ? 'rd-dropdown-text' : 'rd-dropdown'}
                onMouseOver={this.onMouseOver}
                onMouseLeave={this.onMouseLeave}
                aria-label={this.props.ariaLabel}
            >
                {this.getDropDownHeader()}
                {(!isLoading && (
                    <InputGroup className={dropDownClasses.join(' ')}>
                        <InputGroupButtonDropdown
                            addonType="prepend"
                            isOpen={this.state.splitButtonOpen}
                            toggle={this.toggleSplit}
                        >
                            {!this.props.multiSelect && (
                                <Button
                                    onClick={(e) => this.toggleSplit(e)}
                                    color="light"
                                    className={`dropdown-btn-left text-left ${invalidCssClass}`}
                                    disabled={isDropdownDisabled}
                                    onBlur={this.props.onBlur}
                                >
                                    {this.getSelectedText()}
                                </Button>
                            )}
                            {this.props.multiSelect && (
                                <div
                                    onClick={!isDropdownDisabled ? (e) => this.toggleSplit(e) : null}
                                    className={`dropdown-btn-left text-left d-flex multi-select-dropdown ${invalidCssClass}`}
                                >
                                    {this.getSelectedMultiValues()}
                                </div>
                            )}
                            {this.props.customDropdownToggle ? (
                                <DropdownToggle
                                    color="light"
                                    className={`dropdown-btn-right dropdown-custom-toggle ${
                                        this.props.multiSelect ? 'multi' : ''
                                    } ${invalidCssClass}`}
                                    disabled={isDropdownDisabled}
                                >
                                    {this.props.customDropdownIcon}
                                </DropdownToggle>
                            ) : (
                                <DropdownToggle
                                    color="light"
                                    className={`dropdown-btn-right ${
                                        this.props.multiSelect ? 'multi' : ''
                                    } ${invalidCssClass}`}
                                    disabled={isDropdownDisabled}
                                    split
                                />
                            )}
                            <DropdownMenuWrapper
                                isSearchable={this.props.isSearchable}
                                hasDefaultValue={this.props.defaultValue}
                                filterSearch={this.filterSearch}
                                searchTerm={this.state.searchTerm}
                                selectedValue={this.props.selectedValue}
                                onValueChange={this.onValueChange}
                                options={!this.props.multiSelect ? this.props.options : unselectedOptions}
                                optionGroups={this.props.optionGroups}
                                flip={this.props.flip}
                                hideRemoveItemIcon={this.props.hideRemoveItemIcon}
                                className={this.props.dropdownMenuClassName}
                            />
                        </InputGroupButtonDropdown>
                    </InputGroup>
                )) || (
                    <div className="input-skeleton-height">
                        {' '}
                        <Skeleton width={this.props.skeletonWidth} />
                    </div>
                )}
            </div>
        );
    }
}

Dropdown.propTypes = {
    // - Allows the default dropdown caret to be replaced with a different icon.
    customDropdownToggle: PropTypes.bool,
    customDropdownIcon: PropTypes.string,
    // - If included, allows the dropdown to have a selectedValue which is
    //   not included in it's array of options.
    // - This also allows the dropdown items themselves to be removed, by adding a
    //   'deselect' cross to the right of the selectedValue's item.
    defaultValue: PropTypes.string,
    // - If true, displays the dropdown as a text label until it is hovered over or toggled.
    displaySearchableText: PropTypes.bool,
    isDropdownDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    // - Includes a search bar at the top of the Dropdown Menu.
    // - Filters the dropdown items as the user types, can be used with either
    //   the options or the optionGroups prop.
    isSearchable: PropTypes.bool,
    onBlur: PropTypes.func,
    onValueChange: PropTypes.func.isRequired,
    // - Group of dropdown options each containing a heading to be listed under.
    // - If passed both this and Options, OptionGroups will be used.
    optionGroups: PropTypes.array,
    // - The basic form of dropdown options (of type array)
    options: PropTypes.array,
    // - Used in conjunction with displaySearchableText to toggle the hover state
    //   from a parent component.
    parentOverriding: PropTypes.bool,
    // - Must be contained in the dropdowns' options
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedValues: PropTypes.array,
    skeletonWidth: PropTypes.number,
    multiSelect: PropTypes.bool,
    flip: PropTypes.bool,
    title: PropTypes.string,
    onMultiSelectRemoved: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.multiSelect),
    onClearSelectedValues: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.multiSelect),
    inputGroupClassName: PropTypes.string,
    dropdownMenuClassName: PropTypes.string,
    // - Works with single select dropdowns to stop users deselecting
    hideRemoveItemIcon: PropTypes.bool,
    errors: PropTypes.object,
    name: PropTypes.string,
    ariaLabel: PropTypes.string,
    ariaRequired: PropTypes.bool,
};

export default Dropdown;
