import React from 'react';
import PropTypes from 'prop-types';
import ValidationMessage from '../common/ValidationMessage';
import { useIntl } from 'react-intl';

function ValidationSummary(props) {
    const intl = useIntl();

    if (props.submitDisabled && props.errorCount > 0) {
        if (props.errorCount > 1) {
            return (
                <ValidationMessage
                    message={intl.formatMessage(
                        { id: 'Common.GenericValidationMessage' },
                        { errorCount: props.errorCount }
                    )}
                />
            );
        } else {
            return <ValidationMessage message={intl.formatMessage({ id: 'Common.GenericValidationMessageSingle' })} />;
        }
    } else {
        return null;
    }
}

ValidationSummary.propTypes = {
    errorCount: PropTypes.number.isRequired,
    submitDisabled: PropTypes.bool.isRequired,
};

export default ValidationSummary;
