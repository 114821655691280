import React, { Fragment, useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText, Input, Button } from 'reactstrap';
import { ReactComponent as SearchIcon } from '../../content/icons/search.svg';
import ValidationInput from './ValidationInput';
import ValidationWrapper from './ValidationWrapper';
import useDebounce from '../../hooks/useDebounce';
import propTypeExtensions from '../../helpers/PropTypeExtensions';

function SearchInput(props) {
    const { clearResults, includeSearchButton, onSearch } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm);
    const [displaySearchButton, setDisplaySearchButton] = useState(includeSearchButton);

    const searchInput = useRef();

    useEffect(() => {
        if (searchTerm.length === 0 && includeSearchButton) {
            clearResults();
            setDisplaySearchButton(true);
        }
    }, [searchTerm, clearResults, includeSearchButton]);

    useEffect(() => {
        if (!includeSearchButton) {
            setDisplaySearchButton(false);
        }
    }, [includeSearchButton]);

    useEffect(() => {
        if (
            (debouncedSearchTerm.length >= props.minimumCharacterLengthForSearch || debouncedSearchTerm.length === 0) &&
            props.searchWithoutButtonPress
        ) {
            onSearch(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm, props.searchWithoutButtonPress, onSearch, props.minimumCharacterLengthForSearch]);

    function onButtonPress() {
        onSearch(searchTerm);
    }

    function handleKeyPress(event) {
        if (event.keyCode === 13) {
            onButtonPress();
            setDisplaySearchButton(false);
        }
    }

    function search() {
        onSearch(searchTerm);
        setDisplaySearchButton(false);
    }

    const isInvalid = props.includeValidation ? props.errors[props.validationName] : false;

    return (
        <Fragment>
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                    <label>{props.labelText}</label>
                </div>
            </div>

            <InputGroup
                className={`input-group-with-addon search-input search-dropdown-addon ${
                    isInvalid ? 'is-invalid' : ''
                } ${props.disabled ? 'disabled' : ''}`}
            >
                <InputGroupAddon addonType="prepend">
                    {!includeSearchButton && (
                        <InputGroupText>
                            <div onClick={() => onButtonPress()}>
                                <SearchIcon />
                            </div>
                        </InputGroupText>
                    )}

                    {includeSearchButton && (
                        <InputGroupText>
                            <SearchIcon />
                        </InputGroupText>
                    )}
                </InputGroupAddon>
                {props.includeValidation && (
                    <ValidationWrapper errors={props.errors} errorNames={[props.validationName]}>
                        <Fragment>
                            <ValidationInput
                                placeholder={props.inputPlaceholderText}
                                type="search"
                                value={searchTerm}
                                onChange={setSearchTerm}
                                innerRef={props.registerValidation({
                                    required: props.validationMessage,
                                })}
                                name={props.validationName}
                                errors={props.errors}
                                hideValidationMessage
                                onKeyUp={handleKeyPress}
                                disabled={props.disabled}
                            />
                            {displaySearchButton && (
                                <InputGroupAddon addonType="append">
                                    <Button color="primary" onClick={() => props.submit(() => search())}>
                                        <FormattedMessage id={'Common.Search'} />
                                    </Button>
                                </InputGroupAddon>
                            )}
                        </Fragment>
                    </ValidationWrapper>
                )}
                {!props.includeValidation && (
                    <Input
                        placeholder={props.inputPlaceholderText}
                        defaultValue={searchTerm}
                        onKeyUp={handleKeyPress}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        type="search"
                        innerRef={searchInput}
                        disabled={props.disabled}
                    />
                )}
            </InputGroup>
        </Fragment>
    );
}

SearchInput.propTypes = {
    inputPlaceholderText: PropTypes.string,
    labelText: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    clearResults: PropTypes.func,
    includeValidation: PropTypes.bool,
    errors: PropTypes.object,
    validationName: PropTypes.string,
    submit: PropTypes.func,
    validationMessage: PropTypes.string,
    includeSearchButton: PropTypes.bool,
    performSearchWithoutButtonPress: PropTypes.bool,
    disabled: PropTypes.bool,
    minimumCharacterLengthForSearch: propTypeExtensions.requiredIf(
        PropTypes.number,
        (props) => props.performSearchWithoutButtonPress
    ),
};

export default SearchInput;
