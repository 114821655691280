import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class StripeService {
    static createPortalSession(deploymentId, providerId) {
        return ApiHelper.post(
            `/api/AccountSubscription/CreateStripePortalSession?deploymentId=${deploymentId}&providerId=${providerId}`
        ).then((response) => response.data);
    }

    static setUpStripeConnectForProvider(deploymentId, providerId, code) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${providerId}/Stripe/Connect`,
            {
                Code: code,
                UseLiveToken: SettingsHelper.getUseLiveStripe(),
            }
        ).then((response) => response.data);
    }

    static disconnectFromStripeConnect(deploymentId, providerId) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${providerId}/Stripe/Disconnect`
        ).then((response) => response.data);
    }

    static isStripeEnabledForProvider(deploymentId, providerId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${providerId}/Stripe/IsStripeEnabledForProvider`
        ).then((response) => response.data);
    }

    static getConnectWithStripeUrl(deploymentId, providerId, redirectUrl) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${providerId}/Stripe/ConnectWithStripeUrl`,
            {
                redirectUrl: redirectUrl,
            }
        ).then((response) => response.data);
    }
}

export default StripeService;
