import React, { Fragment, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Section from '../common/Section';
import { Button } from 'reactstrap';
import SidePanelContentLayout from '../common/SidePanelContentLayout';
import { Link } from 'react-router-dom';
import VouchersListBuilder from './VouchersListBuilder';
import PropTypes from 'prop-types';
import DiaryContext from '../../contexts/DiaryContext';
import SessionContext from '../../contexts/SessionContext';
import SettingsHelper from '../../helpers/SettingsHelper';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import useScreenSize from '../../hooks/useScreenSize';
import voucherReportStatus from '../../enums/voucherReportStatus';
import { useNavigate } from 'react-router-dom';
import VoucherRedemptionTypeahead from './VoucherRedemptionTypeahead';

function VouchersIndexPage(props) {
    const intl = useIntl();
    const navigate = useNavigate();

    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const { isMobileView } = useScreenSize();
    const micrositeUrl = SettingsHelper.getVouchersPortalMicrositeUrl(diaryContext.micrositeName);

    function getAnalyticsProperties() {
        return {
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
            voucher_URL: micrositeUrl,
        };
    }

    function getPageContent() {
        return (
            <Section sectionTitle={intl.formatMessage({ id: 'Vouchers.ManageVouchers' })}>
                <div className="vouchers-list-builder">
                    <VouchersListBuilder
                        vouchers={props.vouchers}
                        saveVoucher={props.saveVoucher}
                        minValidityPeriod={props.minValidityPeriod}
                        isMobileView={isMobileView}
                        displayGenerateModalWithDefaultVoucher={props.displayGenerateModalWithDefaultVoucher}
                    />
                </div>
            </Section>
        );
    }

    function getSidePanelContent() {
        return (
            <Fragment>
                <div className="content">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="mr-2">
                            <div className="vouchers-url-header mb-2">
                                <FormattedMessage id="Vouchers.MyVouchersUrl" />
                            </div>
                            <div>
                                <a className="wrap" target="_blank" href={micrositeUrl} rel="noopener noreferrer">
                                    {micrositeUrl}
                                </a>
                            </div>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                onClick={() => {
                                    AnalyticsHelper.trackClickWithProperties(
                                        'Web VouchersMgmt Promote',
                                        getAnalyticsProperties()
                                    );
                                    props.togglePromoteModal();
                                }}
                            >
                                <FormattedMessage id="Common.Promote" />
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="voucher-sales-heading mb-2">
                        <FormattedMessage id="Vouchers.VoucherSales" />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="voucher-sales-info">
                            <div
                                className="clickable"
                                onClick={() => {
                                    navigate(`/Vouchers/Report?voucherStatus=${voucherReportStatus.Sold}`);
                                }}
                            >
                                <FormattedMessage id="Common.TotalSold" />
                                <div className="numeric-value xl">
                                    {props.voucherTotalSalesStats ? props.voucherTotalSalesStats.totalVouchersSold : ''}
                                </div>
                            </div>
                            <div
                                className="clickable"
                                onClick={() => {
                                    navigate(`/Vouchers/Report?voucherStatus=${voucherReportStatus.Redeemed}`);
                                }}
                            >
                                <FormattedMessage id="Common.TotalRedeemed" />
                                <div className="numeric-value xl">
                                    {props.voucherTotalSalesStats
                                        ? props.voucherTotalSalesStats.totalVouchersRedeemed
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button color="primary" tag={Link} to="/Vouchers/Report">
                                <FormattedMessage id="Common.ViewAll" />
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="content additional-content">
                    <div className="vouchers-url-header mb-2">
                        <FormattedMessage id="Vouchers.VoucherRedemption" />
                    </div>

                    <VoucherRedemptionTypeahead
                        addErrorBar={props.addErrorBar}
                        addSuccessBar={props.addSuccessBar}
                        refreshVoucherData={props.refreshVoucherData}
                    />
                </div>
                <div className="content">
                    <div>
                        <Button
                            color="primary"
                            onClick={() => {
                                AnalyticsHelper.trackClick('Web Vouchers Generate');
                                props.setDisplayGenerateModal(true);
                            }}
                            className="w-100"
                        >
                            <FormattedMessage id="Vouchers.GenerateVouchers" />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }

    return (
        <SidePanelContentLayout
            pageTitle={intl.formatMessage({ id: 'Common.Vouchers' })}
            pageContent={getPageContent()}
            sidePanelContent={getSidePanelContent()}
            isLoading={props.isLoading}
        />
    );
}

VouchersIndexPage.propTypes = {
    vouchers: PropTypes.array.isRequired,
    saveVoucher: PropTypes.func.isRequired,
    setDisplayGenerateModal: PropTypes.func.isRequired,
    displayGenerateModalWithDefaultVoucher: PropTypes.func.isRequired,
    refreshVoucherData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    voucherTotalSalesStats: PropTypes.object,
    minValidityPeriod: PropTypes.number,
    togglePromoteModal: PropTypes.func,
    addErrorBar: PropTypes.func,
    addSuccessBar: PropTypes.func,
};

export default VouchersIndexPage;
