import React from 'react';
import { FormGroup, CustomInput, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import useScreenSize from '../../hooks/useScreenSize';

function CheckBox(props) {
    const { isMobileView } = useScreenSize();

    return (
        <FormGroup check key={props.option.value}>
            <Label check className={!isMobileView ? 'd-flex' : ''}>
                <CustomInput
                    data-testid={'checkbox-input-' + props.option.value}
                    disabled={props.disabled}
                    id={props.id}
                    type="checkbox"
                    label={props.option.text}
                    onChange={(event) => props.onChange(event.target)}
                    checked={props.isChecked}
                    aria-required={props.ariaRequired}
                    aria-label={props.ariaLabel}
                />
                {props.getLink && props.getLink(parseInt(props.id))}
            </Label>
        </FormGroup>
    );
}

CheckBox.propTypes = {
    option: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    getLink: PropTypes.func,
    ariaRequired: PropTypes.bool,
};

export default CheckBox;
