import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { ReactComponent as ConfirmIcon } from '../../content/icons/confirm.svg';
import infoBarType from '../../enums/infoBarType';
import { FormattedMessage } from 'react-intl';

function InfoBar({ id, message, type, onDismiss }) {
    useEffect(() => {
        if (type === infoBarType.success) {
            setTimeout(() => onDismiss(id), 4000);
        } else {
            setTimeout(() => onDismiss(id), 10000);
        }
    }, [id, type, onDismiss]);

    function getAlertColour() {
        return type === infoBarType.success ? 'success' : 'danger';
    }

    return (
        <Alert
            className="info-bar animated slideInDown"
            color={getAlertColour()}
            isOpen={true}
            toggle={() => onDismiss(id)}
        >
            <div className="d-flex">
                <div>
                    <ConfirmIcon />
                </div>
                <div className="mt-1 mb-1">
                    {message ? message : <FormattedMessage id="Common.DefaultInfoBarMessage" />}
                </div>
            </div>
        </Alert>
    );
}

InfoBar.propTypes = {
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    onDismiss: PropTypes.func.isRequired,
    message: PropTypes.string,
};

export default InfoBar;
