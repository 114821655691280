import React from 'react';
import PropTypes from 'prop-types';
import TextWithIcon from '../../common/TextWithIcon';
import { ReactComponent as AlertIcon } from '../../../content/icons/Icon-Alert.svg';
import RDLModel from '../../common/RDLModal';
import { injectIntl, FormattedMessage } from 'react-intl';

function FacebookNoMicrositeModal(props) {
    const { intl } = props;

    function getModalBody() {
        return (
            <div>
                <div className="warning-alert-icon-container">
                    <TextWithIcon text={getMessagewithRestaurantName()} icon={<AlertIcon />} />
                </div>
                <FormattedMessage id="Settings.NoMicrositeEnabledDescription" />
            </div>
        );
    }

    function getMessagewithRestaurantName() {
        return intl.formatMessage(
            {
                id: 'Settings.NoMicrositeEnabledAlert',
            },
            { restaurantName: props.restaurantName }
        );
    }

    return (
        <RDLModel
            isModalOpen={props.isModalOpen}
            setIsModalOpen={props.setIsModalOpen}
            action={props.facebookNoMicrosite}
            title={intl.formatMessage({ id: 'Settings.UnableToConnectToFacebook' })}
            body={getModalBody()}
            actionButtonText={intl.formatMessage({ id: 'Settings.ActivateDishCultButton' })}
            actionButtonPendingText={intl.formatMessage({ id: 'Settings.ActivateDishCultButton' })}
        />
    );
}

FacebookNoMicrositeModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    facebookNoMicrosite: PropTypes.func.isRequired,
    restaurantName: PropTypes.string.isRequired,
};

export default injectIntl(FacebookNoMicrositeModal);
