import React, { useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import VoucherEditPanel from './VoucherEditPanel';
import VoucherEntry from './VoucherEntry';
import SessionContext from '../../contexts/SessionContext';
import DiaryContext from '../../contexts/DiaryContext';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';

function VouchersEntryWrapper(props) {
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const [isEditing, setIsEditing] = useState(false);

    function getAnalyticsProperties() {
        return {
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
        };
    }

    const toggleEditing = () => {
        if (!isEditing) {
            AnalyticsHelper.trackClickWithProperties('Web VouchersMgmt Edit', getAnalyticsProperties());
        }
        setIsEditing(!isEditing);
    };

    return (
        <Fragment>
            {isEditing && (
                <VoucherEditPanel
                    key={props.voucher.uniqueIdentifier}
                    voucher={props.voucher}
                    toggleEditing={toggleEditing}
                    isEditing={isEditing}
                    saveVoucher={props.saveVoucher}
                    minValidityPeriod={props.minValidityPeriod}
                />
            )}

            {!isEditing && (
                <VoucherEntry
                    key={props.voucher.uniqueIdentifier}
                    voucher={props.voucher}
                    toggleEditing={toggleEditing}
                    displayGenerateModalWithDefaultVoucher={props.displayGenerateModalWithDefaultVoucher}
                />
            )}
        </Fragment>
    );
}

VouchersEntryWrapper.propTypes = {
    voucher: PropTypes.object.isRequired,
    saveVoucher: PropTypes.func.isRequired,
    displayGenerateModalWithDefaultVoucher: PropTypes.func.isRequired,
    minValidityPeriod: PropTypes.number,
};

export default VouchersEntryWrapper;
