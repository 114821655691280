class ServiceHelper {
    static toJson(service) {
        return {
            Id: service.id,
            Name: service.name,
            Description: service.description,
            Priority: service.priority,
            TimeFrom: service.timeFrom,
            TimeUntil: service.timeUntil,
            LastBookingTime: service.lastBookingTime,
            TimeSlotInterval: service.timeSlotInterval,
            StandardEatDuration: service.standardEatDuration,
            MinimumBookingDuration: service.minimumBookingDuration,
            LeaveTimeFrom: service.leaveTimeFrom,
            LeaveTimeUntil: service.leaveTimeUntil,
            IsLeaveTimeEnabled: service.isLeaveTimeEnabled,
            TurnTime: service.turnTime,
            MaxBookings: service.maxBookings,
            MaxCovers: service.maxCovers,
        };
    }
}

export default ServiceHelper;
