import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FileIcon } from '../../content/icons/Icon-File.svg';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Label } from 'reactstrap';
import ValidationMessage from './ValidationMessage';

function FileUpload(props) {
    return (
        <FormGroup className="uploader">
            <div className="d-flex align-items-center">
                <Label className="btn btn-secondary-upload btn-lg">
                    <span>{props.label}</span>
                    <input
                        type="file"
                        onChange={(e) => props.onChange(e.target.files[0])}
                        className="upload-btn-file"
                    />
                </Label>
                <div className="ml-2">
                    <FormattedMessage id="Settings.MaxFileSizeText" />
                </div>
            </div>
            {props.fileName && (
                <div className="icon-with-text">
                    <FileIcon />
                    <div className="message-text">{props.fileName}</div>
                </div>
            )}
            <div className="validation-summary">
                {props.errorCount > 0 && props.errors['file'] && (
                    <ValidationMessage message={props.errors['file'].message} />
                )}
            </div>
        </FormGroup>
    );
}

FileUpload.propTypes = {
    label: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object,
    errorCount: PropTypes.number,
};

export default FileUpload;
