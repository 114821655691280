import React, { useState, Fragment, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import MenuListing from '../../../../domainObjects/MenuListing';
import CheckBox from '../../../common/CheckBox';
import Dropdown from '../../../common/Dropdown';
import DatePickerWrapper from '../../../common/DatePickerWrapper';
import FileUpload from '../../../common/FileUpload';
import MomentHelper from '../../../../helpers/MomentHelper';
import CloseablePanel from '../../../common/CloseablePanel';
import SettingsHelper from '../../../../helpers/SettingsHelper';
import DiaryContext from '../../../../contexts/DiaryContext';
import MenuDetailsHelper from '../../../../helpers/MenuDetailsHelper';
import ValidationInput from '../../../common/ValidationInput';
import ValidationMessage from '../../../common/ValidationMessage';
import useValidation from '../../../../hooks/useValidation';
import useScreenSize from '../../../../hooks/useScreenSize';
import MenuType from '../../../../enums/menuListingType';

function MenuListingCreationPanel(props) {
    const intl = useIntl();
    const { register, errors, submitDisabled, submit, errorCount, setValue } = useValidation();
    const [menuBeingUpdated, setMenuBeingUpdated] = useState(new MenuListing(MenuDetailsHelper.toJson(props.menu)));
    const [initiating, setInitiating] = useState(true);
    const [isTimeLimitChecked, setIsTimeLimitChecked] = useState(isTimeLimitSet());
    const [file, setFile] = useState(null);
    const [displayOnlineFromDate, setDisplayOnlineFromDate] = useState(getDisplayOnlineFromDate());
    const [displayOnlineUntilDate, setDisplayOnlineUntilDate] = useState(getDisplayOnlineUntilDate());
    const [fileError, setFileError] = useState(false);

    const { isTabletView, isMobileView } = useScreenSize();
    const diaryContext = useContext(DiaryContext);

    const toggleTimeLimitCheckBox = () => {
        setIsTimeLimitChecked(!isTimeLimitChecked);
    };

    function getDisplayOnlineFromDate() {
        return menuBeingUpdated.displayOnlineFrom ? menuBeingUpdated.displayOnlineFrom : MomentHelper.newInstance();
    }

    function getDisplayOnlineUntilDate() {
        return menuBeingUpdated.displayOnlineUntil ? menuBeingUpdated.displayOnlineUntil : MomentHelper.newInstance();
    }

    useEffect(() => {
        if (isTimeLimitChecked) {
            if (props.isEditing && menuBeingUpdated.displayOnlineFrom !== null) {
                setDisplayOnlineFromDate(menuBeingUpdated.displayOnlineFrom);
                setDisplayOnlineUntilDate(menuBeingUpdated.displayOnlineUntil);
            } else {
                setDisplayOnlineFromDate(MomentHelper.newInstance());
                setDisplayOnlineUntilDate(MomentHelper.newInstance());
            }
        } else {
            setDisplayOnlineFromDate(null);
            setDisplayOnlineUntilDate(null);
        }
    }, [isTimeLimitChecked, props.isEditing, menuBeingUpdated.displayOnlineFrom, menuBeingUpdated.displayOnlineUntil]);

    const validateMenuType = useCallback(
        (value) => {
            const type = value;

            if (isNaN(type)) {
                return intl.formatMessage({ id: 'Settings.RequiredFieldValidationMessage' });
            }
            return true;
        },
        [intl]
    );

    const validateFile = useCallback(
        (file) => {
            if (!file || !file.name) {
                setFileError(true);
                return intl.formatMessage({ id: 'Settings.FileValidationMessage' });
            }

            if (props.existingMenus.find((m) => m.fileName === file.name && m.id !== props.menu.id) !== undefined) {
                setFileError(true);
                return intl.formatMessage({ id: 'Settings.FileDuplicationValidationMessage' });
            }

            if (file.type !== 'application/pdf') {
                setFileError(true);
                return intl.formatMessage({ id: 'Settings.FilePdfExtensionValidationMessage' });
            }

            if (file.size > 2621440) {
                setFileError(true);
                return intl.formatMessage({ id: 'Settings.FileSizeExceededValidationMessage' });
            }
            setFileError(false);
            return true;
        },
        [intl, props.menu.id, props.existingMenus]
    );

    const validateDisplayOnlineFromDate = useCallback(
        (value) => {
            if (displayOnlineUntilDate && displayOnlineUntilDate.isBefore(value)) {
                return intl.formatMessage({ id: 'Settings.DateRangeValidationMessage' });
            }
            return true;
        },
        [displayOnlineUntilDate, intl]
    );

    const validateDisplayOnlineUntilDate = useCallback(
        (value) => {
            if (displayOnlineFromDate && displayOnlineFromDate.isAfter(value)) {
                return intl.formatMessage({ id: 'Settings.DateRangeValidationMessage' });
            }
            return true;
        },
        [displayOnlineFromDate, intl]
    );

    useEffect(() => {
        if (initiating) {
            register({ name: 'type' }, { validate: validateMenuType });
            register({ name: 'file' }, { validate: validateFile });
            register({ name: 'displayOnlineFrom' }, { validate: validateDisplayOnlineFromDate });
            register({ name: 'displayOnlineUntil' }, { validate: validateDisplayOnlineUntilDate });
            setValue('type', menuBeingUpdated.type);
            setValue('displayOnlineFrom', menuBeingUpdated.displayOnlineFrom);
            setValue('displayOnlineUntil', menuBeingUpdated.displayOnlineUntil);
            setInitiating(false);
        }
    }, [
        register,
        validateMenuType,
        validateFile,
        validateDisplayOnlineFromDate,
        validateDisplayOnlineUntilDate,
        setValue,
        initiating,
        menuBeingUpdated.type,
        menuBeingUpdated.displayOnlineFrom,
        menuBeingUpdated.displayOnlineUntil,
    ]);

    function hasMenuUpdated() {
        let hasTimeFromChanged = props.menu.displayOnlineFrom
            ? !props.menu.displayOnlineFrom.isSame(displayOnlineFromDate)
            : props.menu.displayOnlineFrom !== displayOnlineFromDate;

        let hasTimeUntilChanged = props.menu.displayOnlineUntil
            ? !props.menu.displayOnlineUntil.isSame(displayOnlineUntilDate)
            : props.menu.displayOnlineUntil !== displayOnlineUntilDate;

        return (
            props.menu.name !== menuBeingUpdated.name ||
            props.menu.menuUrl !== menuBeingUpdated.menuUrl ||
            props.menu.type !== menuBeingUpdated.type ||
            props.menu.fileName !== menuBeingUpdated.fileName ||
            props.menu.cultureCode !== menuBeingUpdated.cultureCode ||
            hasTimeFromChanged ||
            hasTimeUntilChanged
        );
    }

    function onMenuValueChange(key, value) {
        setMenuBeingUpdated({ ...menuBeingUpdated, [key]: value });
        switch (key) {
            case 'type':
                setValue('type', value, { shouldValidate: true, shouldDirty: true });
                break;
            case 'displayOnlineFrom':
                setValue('displayOnlineFrom', value, { shouldValidate: true, shouldDirty: true });
                break;
            case 'displayOnlineUntil':
                setValue('displayOnlineUntil', value, { shouldValidate: true, shouldDirty: true });
                break;
            default:
                break;
        }
    }

    function saveMenuDetail() {
        menuBeingUpdated.displayOnlineFrom = displayOnlineFromDate;
        menuBeingUpdated.displayOnlineUntil = displayOnlineUntilDate;
        props.saveMenuDetails(menuBeingUpdated, file);
    }

    function isTimeLimitSet() {
        return menuBeingUpdated.displayOnlineFrom != null && menuBeingUpdated.displayOnlineUntil != null;
    }

    function getMenuTypeOptions() {
        return props.menuTypes
            ? props.menuTypes.map((value, index) => ({ value: index, text: getMenuTypeDisplayName(value) }))
            : [];
    }

    function getMenuTypeDisplayName(type) {
        switch (type) {
            case MenuType.aLaCarte:
                return intl.formatMessage({ id: 'Settings.ALaCarte' });
            case MenuType.wineList:
                return intl.formatMessage({ id: 'Settings.WineList' });
            case MenuType.setMenu:
                return intl.formatMessage({ id: 'Settings.SetMenu' });
            case MenuType.function:
                return intl.formatMessage({ id: 'Settings.Function' });
            default:
                return type;
        }
    }

    function getCultureCodeOptions() {
        return props.cultureCodes.map((culture) => ({ value: culture.CultureCode, text: culture.Description }));
    }

    function getDate(date) {
        return date ? date : MomentHelper.newInstance();
    }

    function getRestaurantMenuUrl() {
        return SettingsHelper.getRestaurantMenuUrl(diaryContext.micrositeName);
    }

    function setFileToBeUploaded(file) {
        const fileName = file ? file.name : '';
        menuBeingUpdated.fileName = fileName;
        setFile(file);
        setValue('file', file, { shouldValidate: true, shouldDirty: true });
    }

    function validateUrl(value) {
        if (props.existingMenus.find((m) => m.menuUrl === value && m.id !== props.menu.id) !== undefined) {
            return intl.formatMessage({ id: 'Settings.MenuUrlDuplicationValidationMessage' });
        }
    }

    function getFormBody() {
        return (
            <Fragment>
                <div className="grey-closable-panel-body">
                    <Row form className="list-builder-sub-section">
                        <Col>
                            <FormGroup>
                                <FileUpload
                                    parameterToUpdate={'fileName'}
                                    onChange={setFileToBeUploaded}
                                    label={intl.formatMessage({ id: 'Settings.UploadPdfFile' })}
                                    fileName={menuBeingUpdated.fileName}
                                    errors={errors}
                                    errorCount={errorCount}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form className="list-builder-sub-section">
                        <Col md={4}>
                            <FormGroup>
                                <div className="mb-1">
                                    <FormattedMessage id="Settings.MenuTitle" />
                                </div>
                                <ValidationInput
                                    value={menuBeingUpdated.name}
                                    onChange={(e) => onMenuValueChange('name', e)}
                                    testId="menu-listing-name-input"
                                    innerRef={register({
                                        required: intl.formatMessage({ id: 'Settings.RequiredFieldValidationMessage' }),
                                    })}
                                    name="name"
                                    errors={errors}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <div className="mb-1">
                                    <FormattedMessage id="Settings.MenuType" />{' '}
                                </div>
                                <Dropdown
                                    options={getMenuTypeOptions()}
                                    defaultValue={intl.formatMessage({ id: 'Settings.PleaseSelect' })}
                                    selectedValue={menuBeingUpdated.type}
                                    onValueChange={(value) => onMenuValueChange('type', value)}
                                    hideRemoveItemIcon
                                    errors={errors}
                                    name="type"
                                />
                                <div className="validation-summary">
                                    {errorCount > 0 && errors['type'] && (
                                        <ValidationMessage message={errors['type'].message} />
                                    )}
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <div className="mb-1">
                                    <FormattedMessage id="Settings.Language" />{' '}
                                </div>
                                <Dropdown
                                    options={getCultureCodeOptions()}
                                    defaultValue={intl.formatMessage({ id: 'Settings.PleaseSelect' })}
                                    selectedValue={menuBeingUpdated.cultureCode}
                                    onValueChange={(value) => onMenuValueChange('cultureCode', value)}
                                    hideRemoveItemIcon
                                    isSearchable
                                    errors={errors}
                                    name="cultureCode"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form className="list-builder-sub-section m-0">
                        <FormGroup>
                            <div className="mb-1">
                                <FormattedMessage id="Settings.MenuUrl" />
                            </div>
                            <div className={isTabletView || isMobileView ? 'menu-url-input mob' : 'menu-url-input'}>
                                <div>
                                    <Label>{getRestaurantMenuUrl()}</Label>
                                </div>
                                <div className="input-container">
                                    <ValidationInput
                                        value={menuBeingUpdated.menuUrl}
                                        onChange={(e) => onMenuValueChange('menuUrl', e)}
                                        testId="menu-listing-url-name-input"
                                        innerRef={register({
                                            required: intl.formatMessage({
                                                id: 'Settings.RequiredFieldValidationMessage',
                                            }),
                                            pattern: {
                                                value: /^[A-Za-z0-9]+$/i,
                                                message: intl.formatMessage({
                                                    id: 'Settings.MenuUrlValidationMessage',
                                                }),
                                            },
                                            validate: (value) => validateUrl(value),
                                        })}
                                        name="menuUrl"
                                        errors={errors}
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </Row>

                    <Row form className="align-row m-0">
                        <div className="mr-3">
                            <CheckBox
                                id={'menu-listing-checkbox ' + menuBeingUpdated.uniqueIdentifier}
                                option={new Option(intl.formatMessage({ id: 'Settings.SetTimeLimit' }), 1)}
                                onChange={() => toggleTimeLimitCheckBox()}
                                isChecked={isTimeLimitChecked}
                            />
                        </div>
                        {isTimeLimitChecked && (
                            <Fragment>
                                <div className="menu-validity-date-picker-container">
                                    <DatePickerWrapper
                                        date={getDate(displayOnlineFromDate)}
                                        setDate={setDisplayOnlineFromDate}
                                        allowTodayAndFutureDatesOnly
                                        excludeArrows
                                        hideDayFromDate
                                    />
                                    <div className="time-to-label">
                                        <FormattedMessage id="Settings.TimeToLabel" />
                                    </div>
                                    <DatePickerWrapper
                                        date={getDate(displayOnlineUntilDate)}
                                        setDate={setDisplayOnlineUntilDate}
                                        allowTodayAndFutureDatesOnly
                                        excludeArrows
                                        hideDayFromDate
                                    />
                                </div>
                                <Row>
                                    <div className="validation-summary">
                                        {errorCount > 0 && errors['displayOnlineFrom'] && (
                                            <ValidationMessage message={errors['displayOnlineFrom'].message} />
                                        )}
                                    </div>
                                </Row>
                            </Fragment>
                        )}
                    </Row>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <CloseablePanel
                formBody={getFormBody()}
                isEditing={props.isEditing}
                saveItem={saveMenuDetail}
                toggleEditing={props.toggleEditing}
                toggleAddNewItem={props.toggleAddNewMenu}
                addNewItemText={intl.formatMessage({ id: 'Settings.AddNewMenu' })}
                editItemText={intl.formatMessage({ id: 'Settings.EditMenu' })}
                buttonDisabled={submitDisabled || !hasMenuUpdated() || props.isSaving || fileError}
                submit={submit}
                submitDisabled={submitDisabled}
                errorCount={errorCount}
                isSaving={props.isSaving}
            />
        </Fragment>
    );
}

MenuListingCreationPanel.propTypes = {
    menu: PropTypes.object.isRequired,
    isEditing: PropTypes.bool,
    toggleEditing: PropTypes.func,
    toggleAddNewMenu: PropTypes.func,
    saveMenuDetails: PropTypes.func.isRequired,
    menuTypes: PropTypes.array.isRequired,
    cultureCodes: PropTypes.array.isRequired,
    existingMenus: PropTypes.array,
    isSaving: PropTypes.bool,
};

export default MenuListingCreationPanel;
