import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ReactComponent as StripeConnectIcon } from '../../content/icons/RD-stripeconnect.svg';
import useScreenSize from '../../hooks/useScreenSize';

function VouchersConnectPanel(props) {
    const { isMobileView } = useScreenSize();

    function connectToStripe() {
        window.location = props.stripeConnectUrl;
    }

    return (
        <Fragment>
            <h3 className="font-weight-normal voucher-confirmation-title">
                <FormattedMessage id="Settings.PleaseConnectStripe" />
            </h3>
            <Fragment>
                <div className="stripe-connect-logo-wrapper">
                    <StripeConnectIcon className="stripe-connect-svg" />
                </div>
                <div className={isMobileView ? 'stripe-connect-content mob' : 'stripe-connect-content'}>
                    <div className="stripe-voucher-conditions">
                        <div className="bold-text mb-4">
                            <FormattedMessage id="Vouchers.AcceptStripeForVouchersLine1" />
                        </div>

                        <div>
                            <FormattedMessage id="Vouchers.AcceptStripeForVouchersLine2" />
                        </div>
                    </div>

                    <div className="stripe-integration-info">
                        <a href="https://stripe.com/connect" target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id="Settings.StripeIntegrationInformation" />
                        </a>
                    </div>
                    <div className="stripe-connect-button">
                        <Button
                            block
                            color="primary"
                            disabled={props.stripeConnectUrl === null || props.isUpdatingStripeConnectStatus}
                            onClick={connectToStripe}
                        >
                            {props.isUpdatingStripeConnectStatus ? (
                                <FormattedMessage id="Settings.ConnectingToStripe" />
                            ) : (
                                <FormattedMessage id="Settings.ConnectToStripe" />
                            )}
                        </Button>
                    </div>
                </div>
            </Fragment>
        </Fragment>
    );
}

VouchersConnectPanel.propTypes = {
    isUpdatingStripeConnectStatus: PropTypes.bool.isRequired,
    stripeConnectUrl: PropTypes.string,
};

export default VouchersConnectPanel;
