import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BreadCrumbDisplay from './BreadCrumbDisplay';
import propTypeExtensions from '../../helpers/PropTypeExtensions';
import LoadingSpinner from './LoadingSpinner';
import InfoBars from './InfoBars';
import useScreenSize from '../../hooks/useScreenSize';

function SidePanelContentLayout(props) {
    const { isTabletView } = useScreenSize();

    function getPageContent() {
        if (props.isLoading) {
            return <LoadingSpinner />;
        } else {
            if (isTabletView) {
                return (
                    <div className={'flex-container-inline mobile mt-3 row'}>
                        <div className={'side-panel-content-layout mob'}>{props.sidePanelContent}</div>
                        <div className="">{props.pageContent}</div>
                    </div>
                );
            } else {
                return (
                    <div className={'flex-container-inline mt-3 row'}>
                        <div className={'col-md-3 side-panel-content-layout'}>{props.sidePanelContent}</div>
                        <div className="col-md-9">{props.pageContent}</div>
                    </div>
                );
            }
        }
    }

    return (
        <Fragment>
            {props.includeInfoBar && <InfoBars infoBars={props.infoBars} setInfoBars={props.setInfoBars} />}
            <div className="page-header">
                {props.includeBreadcrumb && (
                    <BreadCrumbDisplay
                        breadcrumbs={props.breadcrumbOptions}
                        activePageTitle={props.breadcrumbActivePageTitle}
                    />
                )}
                <h1>{props.pageTitle}</h1>
            </div>
            {getPageContent()}
        </Fragment>
    );
}

SidePanelContentLayout.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    pageContent: PropTypes.node.isRequired,
    sidePanelContent: PropTypes.element,
    includeBreadcrumb: PropTypes.bool,
    breadcrumbOptions: propTypeExtensions.requiredIf(PropTypes.array, (props) => props.includeBreadcrumb),
    breadcrumbActivePageTitle: propTypeExtensions.requiredIf(PropTypes.string, (props) => props.includeBreadcrumb),
    includeInfoBar: PropTypes.bool,
    setInfoBars: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.includeInfoBar),
    infoBars: propTypeExtensions.requiredIf(
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                type: PropTypes.string.isRequired,
                message: PropTypes.string,
            })
        ),
        (props) => props.includeInfoBar
    ),
    isLoading: PropTypes.bool,
};

export default SidePanelContentLayout;
