import React, { useContext } from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Moment from 'moment';
import DiaryContext from '../../contexts/DiaryContext';
import BookingTypeahead from './BookingTypeahead';
import NewBookingOverlayContent from '../../domainObjects/NewBookingOverlayContent';
import { FormattedMessage } from 'react-intl';
import DatePickerWrapper from '../common/DatePickerWrapper';
import useScreenSize from '../../hooks/useScreenSize';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';

function RunningOrderControls(props) {
    const diaryContext = useContext(DiaryContext);
    const { isMobileView } = useScreenSize();

    if (isMobileView) {
        return (
            <div className="running-order-controls-mobile">
                <Row>
                    <Col sm="12" className="running-order-top-controls">
                        <div className="typeahead-container">
                            <BookingTypeahead
                                deploymentId={diaryContext.deploymentId}
                                restaurantId={diaryContext.restaurantId}
                                date={props.date.format('YYYY-MM-DD')}
                                typeaheadItemSelected={props.typeaheadItemSelected}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <DatePickerWrapper
                            date={props.date}
                            setDate={props.setDate}
                            disabled={props.isLoading}
                            includeTodayButton
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Button
                            color="primary"
                            onClick={() => {
                                AnalyticsHelper.trackPage('Add Booking');
                                props.openOverlay(new NewBookingOverlayContent());
                            }}
                            data-testid="running-order-book-button"
                            id="book-button"
                            className="book-button"
                        >
                            <FormattedMessage id="Bookings.Book" />
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    } else {
        return (
            <Row className="running-order-controls">
                <Col sm="3" className="typeahead-container">
                    <BookingTypeahead
                        deploymentId={diaryContext.deploymentId}
                        restaurantId={diaryContext.restaurantId}
                        date={props.date.format('YYYY-MM-DD')}
                        typeaheadItemSelected={props.typeaheadItemSelected}
                    />
                </Col>
                <Col sm="6" className="date-picker-container">
                    <DatePickerWrapper
                        date={props.date}
                        setDate={props.setDate}
                        disabled={props.isLoading}
                        includeTodayButton
                    />
                </Col>
                <Col lg="3" md="1" sm="2" className="book-print-container">
                    <div className="running-order-buttons">
                        <Button
                            color="primary"
                            onClick={() => {
                                AnalyticsHelper.trackPage('Add Booking');
                                props.openOverlay(new NewBookingOverlayContent());
                            }}
                            data-testid="running-order-book-button"
                            disabled={props.isLoading}
                            id="book-button"
                        >
                            <FormattedMessage id="Bookings.Book" />
                        </Button>
                        <Button
                            color="outline-primary"
                            onClick={() => {
                                window.print();
                            }}
                            disabled={props.isLoading}
                        >
                            <FormattedMessage id="Common.Print" />
                        </Button>
                    </div>
                </Col>
            </Row>
        );
    }
}

RunningOrderControls.propTypes = {
    date: PropTypes.instanceOf(Moment),
    setDate: PropTypes.func.isRequired,
    typeaheadItemSelected: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

export default RunningOrderControls;
