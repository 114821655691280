import loyaltyTransactionType from '../enums/loyaltyTransactionType';

class LoyaltyReportTransaction {
    constructor(loyaltyReportTransaction) {
        this.bookingRef =
            loyaltyReportTransaction && loyaltyReportTransaction.BookingRef ? loyaltyReportTransaction.BookingRef : '';
        this.bookingDate =
            loyaltyReportTransaction && loyaltyReportTransaction.TransactionDate
                ? loyaltyReportTransaction.TransactionDate
                : '';
        this.customerName =
            loyaltyReportTransaction && loyaltyReportTransaction.CustomerName
                ? loyaltyReportTransaction.CustomerName
                : '';
        this.emailAddress =
            loyaltyReportTransaction && loyaltyReportTransaction.CustomerEmail
                ? loyaltyReportTransaction.CustomerEmail
                : '';
        this.phone = this.getPhoneNumber(loyaltyReportTransaction);
        this.customerStatus =
            loyaltyReportTransaction && loyaltyReportTransaction.CustomerStatus
                ? loyaltyReportTransaction.CustomerStatus
                : false;
        this.totalSpend =
            loyaltyReportTransaction && loyaltyReportTransaction.AmountSpent
                ? loyaltyReportTransaction.AmountSpent
                : 0.0;
        this.rewardStatus =
            loyaltyReportTransaction && loyaltyReportTransaction.RewardStatus
                ? loyaltyReportTransaction.RewardStatus
                : 0;
        this.redemptionRef =
            loyaltyReportTransaction && loyaltyReportTransaction.RedemptionRef
                ? loyaltyReportTransaction.RedemptionRef
                : '';
        this.redemptionValue =
            loyaltyReportTransaction && loyaltyReportTransaction.RedemptionValue
                ? loyaltyReportTransaction.RedemptionValue
                : 0;
        this.transactionType =
            loyaltyReportTransaction &&
            loyaltyReportTransaction.TransactionType &&
            loyaltyReportTransaction.TransactionType === loyaltyTransactionType.redeemed
                ? loyaltyTransactionType.redeemed
                : loyaltyTransactionType.earned;
    }

    getPhoneNumber = function (booking) {
        let phoneNumber = '';
        if (booking.CustomerCountryCode) {
            phoneNumber = phoneNumber + '+(' + booking.CustomerCountryCode + ')';
        }
        if (booking.CustomerPhoneNumber) {
            phoneNumber = phoneNumber + booking.CustomerPhoneNumber;
        }
        return phoneNumber;
    };
}
export default LoyaltyReportTransaction;
