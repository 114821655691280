import { v4 as uuidv4 } from 'uuid';
import MomentHelper from '../helpers/MomentHelper';

class Voucher {
    constructor(voucher) {
        this.id = voucher && voucher.VoucherId ? voucher.VoucherId : 0;
        this.title = voucher && voucher.Name ? voucher.Name : '';
        this.description = voucher && voucher.Description ? voucher.Description : '';
        this.cost = voucher && voucher.Cost ? voucher.Cost : 25;
        this.minimumQuantity = voucher && voucher.MinimumQuantity ? voucher.MinimumQuantity : 1;
        this.maximumQuantity = voucher && voucher.MaximumQuantity ? voucher.MaximumQuantity : 1000;
        this.monthsValid = voucher && voucher.MonthsValid ? voucher.MonthsValid : 0;
        this.status = voucher && !voucher.Status ? false : true;
        this.available = voucher && voucher.Available ? voucher.Available : 0;
        this.termsAndConditions = voucher && voucher.TermsAndConditions ? voucher.TermsAndConditions : '';
        this.createdDate = voucher && voucher.CreatedDate ? MomentHelper.newInstance(voucher.CreatedDate) : null;
        this.uniqueIdentifier = uuidv4();
        this.totalSold = voucher && !isNaN(voucher.TotalSold) ? voucher.TotalSold : 0;
        this.totalRedeemed = voucher && !isNaN(voucher.TotalSold) ? voucher.TotalRedeemed : 0;
    }
}

export default Voucher;
