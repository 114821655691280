class TableLabel {
    constructor(label) {
        this.id = label && label.Id ? label.Id : 0;
        this.name = label && label.Name ? label.Name : '';
        this.covers = label && label.Covers ? label.Covers : '';
        this.uniqueIdentifier =
            label && label.UniqueIdentifier ? label.UniqueIdentifier : '_' + Math.random().toString(36).substr(2, 9);
    }
}

export default TableLabel;
