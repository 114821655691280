import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from './CheckBox';

function CheckboxGroup(props) {
    function isChecked(value) {
        return props.checkedValues && props.checkedValues.includes(value) ? true : false;
    }

    return props.options.map((option) => {
        return (
            <CheckBox
                id={option.value}
                key={'checkbox-group-item ' + option.value}
                option={option}
                onChange={props.onChange}
                isChecked={isChecked(option.value)}
                disabled={props.disabled}
                getLink={props.getLink}
                ariaRequired={props.ariaRequired}
                ariaLabel={option.text}
            />
        );
    });
}

CheckboxGroup.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    checkedValues: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    getLink: PropTypes.func,
};

export default CheckboxGroup;
