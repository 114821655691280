import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';
import ValidationInput from './ValidationInput';
import CheckBox from './CheckBox';
import PropTypeExtensions from '../../helpers/PropTypeExtensions';

function ButtonToolbarInput(props) {
    const [isInputOpen, setIsInputOpen] = useState(!props.options.includes(props.selectedValue));
    const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(true);

    function isSelected(value) {
        return props.selectedValue === value ? true : false;
    }

    function toggleInput() {
        setIsInputOpen(!isInputOpen);
    }

    function getCheckboxOptions() {
        return new Option(props.checkboxLabel);
    }

    function setInputValue(e) {
        if (e !== '' && e !== null) {
            props.onChange(e);
        }
        setIsCheckboxEnabled(parseInt(e) !== 0);
    }

    return (
        <div className={props.isMobileView ? 'button-bar mob' : 'button-bar'}>
            {props.hasInput && isInputOpen && (
                <div className={props.isMobileView ? 'mb-3' : 'button-bar-input'}>
                    <ValidationInput
                        type="text"
                        name={props.name}
                        value={props.selectedValue}
                        onChange={(e) => setInputValue(e)}
                        innerRef={props.validation}
                        errors={props.errors}
                        className="button-bar-input"
                    />
                </div>
            )}
            {!isInputOpen && (
                <div className={props.isMobileView ? 'mb-3' : ''}>
                    <ButtonGroup>
                        {props.options.map((option, i) => {
                            return (
                                <Button
                                    key={i}
                                    onClick={() => props.onChange(option)}
                                    color={isSelected(option) ? 'dark' : 'light'}
                                >
                                    {option}
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                </div>
            )}
            {props.hasInput && (
                <CheckBox
                    option={getCheckboxOptions()}
                    onChange={toggleInput}
                    isChecked={isInputOpen}
                    id="button-bar-checkbox"
                    disabled={!isCheckboxEnabled}
                />
            )}
        </div>
    );
}

ButtonToolbarInput.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasInput: PropTypes.bool,
    validation: PropTypeExtensions.requiredIf(PropTypes.func, (props) => props.hasInput),
    errors: PropTypeExtensions.requiredIf(PropTypes.object, (props) => props.hasInput),
    checkboxLabel: PropTypes.string.isRequired,
    name: PropTypes.string,
};

export default ButtonToolbarInput;
