import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class TableLabelService {
    static getAllTableLabels(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/TableLabel/GetAll`
        ).then((response) => response.data);
    }

    static createTableLabel(deploymentId, restaurantId, tableLabel) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/TableLabel/Create`,
            JSON.stringify(tableLabel)
        ).then((response) => response.data);
    }

    static updateTableLabel(deploymentId, restaurantId, tableLabel) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/TableLabel/Update`,
            JSON.stringify(tableLabel)
        ).then((response) => response.data);
    }

    static deleteTableLabel(deploymentId, restaurantId, tableLabelId) {
        return ApiHelper.deleteRequest(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/TableLabel/${tableLabelId}/Delete`
        );
    }
}

export default TableLabelService;
