class CurrencyHelper {
    static toJson(voucher) {
        return {
            VoucherId: voucher.id,
            Name: voucher.title,
            Description: voucher.description,
            Cost: voucher.cost,
            MinimumQuantity: voucher.minimumQuantity,
            MaximumQuantity: voucher.maximumQuantity,
            MonthsValid: voucher.monthsValid,
            Status: voucher.status,
            Available: voucher.available,
        };
    }

    static getCurrencySignWithSymbol(symbol) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = symbol;
        return textArea.value;
    }
}

export default CurrencyHelper;
