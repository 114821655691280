import React, { useReducer, useContext, useEffect, useRef, Fragment } from 'react';
import { Popover } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import SessionContext from '../../contexts/SessionContext';
import DiaryContext from '../../contexts/DiaryContext';
import AlertContext from '../../contexts/AlertContext';
import { FormattedMessage } from 'react-intl';
import useOutsideClick from '../../hooks/useOutsideClick';
import BookingAlert from '../alerts/BookingAlert';
import OrderAndPayAlert from '../alerts/OrderAndPayAlert';
import useScreenSize from '../../hooks/useScreenSize';
import RdNavbar from '../navbar/RdNavbar';
import CollapsedRdNavbar from '../navbar/CollapsedRdNavbar';
import alertType from '../../enums/alertType';
import HelpAndSupportModal from '../support/HelpAndSupportModal';

function HeaderBar(props) {
    const [isAlertsPanelOpen, toggleAlertsPanel] = useReducer(toggle, false);

    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const { alerts, updateAlertsAndSetRefreshBookings, currentDate, setAlertBookingOverlayId } =
        useContext(AlertContext);
    const pathName = useLocation().pathname;
    const dropdownRef = useRef();
    const buttonRef = useRef();
    useOutsideClick([dropdownRef, buttonRef], toggleAlertsPanel);

    const isInSettingsTab = () => {
        return pathName.substring(0, 9) === '/Settings';
    };

    const getNewAlerts = () => {
        return alerts.filter((alert) => alert.isNew);
    };
    const newAlerts = getNewAlerts();
    const newAlertsLength = newAlerts.length;

    const { isTabletView, isMobileView } = useScreenSize();

    useEffect(() => {
        if (isAlertsPanelOpen && newAlertsLength > 0) {
            updateAlertsAndSetRefreshBookings();
        }
    }, [isAlertsPanelOpen, newAlertsLength, updateAlertsAndSetRefreshBookings]);

    function logOut() {
        props.auth.logout();
    }

    function toggle(value) {
        return !value;
    }

    const [isHelpAndSupportModalOpen, toggleModal] = React.useState(false);

    const openHelpAndSupportModal = () => toggleModal(!isHelpAndSupportModalOpen);

    function setAlertBookingOverlayIdAndCloseAlertDropdown(bookingId) {
        setAlertBookingOverlayId(bookingId);
        toggleAlertsPanel();
    }

    function getAlertElements() {
        return alerts.map((alert) => {
            switch (alert.type) {
                case alertType.newBooking:
                    return (
                        <BookingAlert
                            setAlertBookingOverlayId={setAlertBookingOverlayIdAndCloseAlertDropdown}
                            key={alert.id}
                            alert={alert}
                        />
                    );
                case alertType.orderAndPay:
                    return (
                        <OrderAndPayAlert
                            setAlertBookingOverlayId={setAlertBookingOverlayIdAndCloseAlertDropdown}
                            key={alert.id}
                            alert={alert}
                        />
                    );
                default:
                    return alert;
            }
        });
    }

    function getAlertsDropdown() {
        return (
            <div>
                <Popover
                    isOpen={isAlertsPanelOpen}
                    placement={isTabletView || isMobileView ? 'bottom-end' : 'bottom'}
                    target="alert-button"
                    modifiers={{
                        preventOverflow: { escapeWithReference: true },
                    }}
                    popperClassName="alert-popover"
                >
                    <div
                        className="popover-content-wrapper header-bar-popover-content-wrapper"
                        data-testid="popover-content"
                        onClick={(e) => e.stopPropagation()}
                        ref={dropdownRef}
                    >
                        <div className="alert-header">
                            <h6>
                                <FormattedMessage id="Common.Alerts" />
                            </h6>
                            <span className="float-right">{currentDate ? currentDate.format('LL') : ''}</span>
                        </div>
                        <div className="alert-container">{getAlertElements()}</div>
                    </div>
                </Popover>
                {isHelpAndSupportModalOpen && (
                    <HelpAndSupportModal
                        isModalOpen={isHelpAndSupportModalOpen}
                        setIsModalOpen={openHelpAndSupportModal}
                        userName={sessionContext.userName}
                        restaurantId={diaryContext.restaurantId}
                    />
                )}
            </div>
        );
    }

    function getNavBar() {
        if (isMobileView || isTabletView) {
            return (
                <CollapsedRdNavbar
                    restaurantName={diaryContext.restaurantName}
                    restaurantId={diaryContext.restaurantId}
                    pathName={pathName}
                    isInSettingsTab={isInSettingsTab()}
                    alerts={alerts}
                    toggleAlertsPanel={toggleAlertsPanel}
                    newAlertsLength={newAlertsLength}
                    getAlertsDropdown={getAlertsDropdown}
                    openHelpAndSupportModal={openHelpAndSupportModal}
                    userDisplayName={sessionContext.displayName}
                    logOut={logOut}
                    isMobileView={isMobileView}
                    toggle={toggle}
                    buttonRef={buttonRef}
                    isAdmin={sessionContext.isAdminUser}
                    returnToProviderSelection={() => {
                        props.auth.returnToProviderSelection();
                        props.setIsAdminProviderSelectionVisible(true);
                    }}
                />
            );
        } else {
            return (
                <RdNavbar
                    restaurantName={diaryContext.restaurantName}
                    restaurantId={diaryContext.restaurantId}
                    pathName={pathName}
                    isInSettingsTab={isInSettingsTab()}
                    alerts={alerts}
                    toggleAlertsPanel={toggleAlertsPanel}
                    newAlertsLength={newAlertsLength}
                    getAlertsDropdown={getAlertsDropdown}
                    openHelpAndSupportModal={openHelpAndSupportModal}
                    userDisplayName={sessionContext.displayName}
                    logOut={logOut}
                    buttonRef={buttonRef}
                    toggle={toggle}
                    isAdmin={sessionContext.isAdminUser}
                    returnToProviderSelection={() => {
                        props.auth.returnToProviderSelection();
                        props.setIsAdminProviderSelectionVisible(true);
                    }}
                />
            );
        }
    }

    return <Fragment>{getNavBar()}</Fragment>;
}

export default HeaderBar;
