import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Components from './components/examples/Components';
import BookingsPage from './components/booking/BookingsPage';
import SettingsPage from './components/settings/SettingsPage';
import ErrorBoundary from './components/common/ErrorBoundary.js';
import LoginPage from './components/login/LoginPage';
import AdminProviderLoginScreen from './components/login/AdminProviderLoginScreen';
import NotFoundPage from './components/common/404NotFoundPage';
import SessionHelper from './helpers/SessionHelper';
import SessionContext from './contexts/SessionContext';
import DiaryContext from './contexts/DiaryContext';
import AlertContext from './contexts/AlertContext';
import AddOnContext from './contexts/AddOnContext';
import ProviderProgressFlagsContext from './contexts/ProviderProgressFlagsContext';
import UserService from './services/UserService';
import RestaurantService from './services/RestaurantService';
import ProgressFlagService from './services/ProgressFlagService';
import LoadingSpinner from './components/common/LoadingSpinner';
import infoBarType from './enums/infoBarType';
import addOnStatus from './enums/addOnStatus';
import InfoBars from './components/common/InfoBars';
import SettingsService from './services/SettingsService';
import SettingsHelper from './helpers/SettingsHelper';
import TableLabelsConfigPage from './components/settings/tableLabels/TableLabelsConfigPanel';
import CloseOutsPage from './components/settings/closeouts/CloseOutsPanel';
import BookButtonPage from './components/settings/bookButton/BookButtonPage';
import MenuManagementPage from './components/settings/menus/MenuManagementPage';
import ForgotPasswordPage from './components/login/ForgotPasswordPage';
import ResetPasswordPage from './components/login/ResetPasswordPage';
import SquareSettingsPage from './components/settings/square/SquareSettingsPage';
import FacebookSettingsPage from './components/settings/facebook/FacebookSettingsPage';
import ServicesPage from './components/settings/services/ServicesPage';
import AnalyticsHelper from './helpers/AnalyticsHelper';
import BookingWidgetPage from './components/settings/widget/BookingWidgetPage';
import CreateMenuPage from './components/settings/menus/CreateMenuPage';
import EditMenuPage from './components/settings/menus/EditMenuPage';
import VenueSettingsPage from './components/settings/venue/VenueSettingsPage';
import ImagesSettingsPage from './components/settings/logoAndImages/ImagesSettingsPage';
import VouchersManagementPage from './components/vouchers/VouchersManagementPage';
import StripeConnectPage from './components/settings/stripe/StripeConnectPage';
import VoucherReportPage from './components/vouchers/Report/VoucherReportPage';
import CreateAccountPage from './components/accountSubmission/CreateAccountPage';
import SuccessPage from './components/accountSubmission/SuccessPage';
import VenueWizardPage from './components/accountSubmission/VenueWizardPage';
import LoyaltyPromoPage from './components/settings/loyalty/LoyaltyPromoPage';
import LoyaltyForm from './components/settings/loyalty/LoyaltyForm';
import AccountSubmissionService from './services/AccountSubmissionService';
import AddOnService from './services/AddOnService';
import WelcomePage from './components/accountSubmission/WelcomePage';
import ProgressFlags from './domainObjects/ProgressFlags';
import MomentHelper from './helpers/MomentHelper';
import constants from './enums/constants';
import CookiesHelper from './helpers/CookiesHelper';
import TagManager from 'react-gtm-module';
import AddOn from './domainObjects/AddOn';
import loyaltyStatus from './enums/loyaltyStatus';
import LoyaltyDashboard from './components/settings/loyalty/LoyaltyDashboard';
import LoyaltyReportPage from './components/settings/loyalty/Reports/LoyaltyReportPage';
import { useAuth } from './hooks/useAuth';
import RequireAuth from './components/RequireAuth';
import { AxiosInterceptor } from './Interceptor';
import UserAccount from './domainObjects/AccountSubmission/UserAccount';

let _ID = 0;

export default function App() {
    const [loading, setLoading] = useState(true);
    const [sessionInfo, setSessionInfo] = useState(null);
    const [diaryInfo, setDiaryInfo] = useState(null);
    const [providerProgressFlags, setProviderProgressFlags] = useState(null);
    const [infoBars, setInfoBars] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [refreshBookings, setRefreshBookings] = useState(false);
    const [currentDate, setCurrentDate] = useState(null);
    const [alertBookingOverlayId, setAlertBookingOverlayId] = useState(null);
    const [isAdminProviderSelectionVisible, setIsAdminProviderSelectionVisible] = useState(false);
    const [versionNumber, setVersionNumber] = useState(null);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [subscriptionInfo, setSubscriptionInfo] = useState(null);
    const [activeAddOns, setActiveAddOns] = useState(null);
    const [resdiaryUserAuthenticated, setResdiaryAuthenticated] = useState(false);
    const [subscribedToLoyalty, setSubscribedToLoyalty] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [account, setAccount] = useState(new UserAccount());

    const updateAlertsAndSetRefreshBookings = () => {
        const updatedAlerts = [...alerts];
        updatedAlerts.forEach((updatedAlert) => {
            updatedAlert.isNew = false;
        });
        setAlerts(updatedAlerts);
        setRefreshBookings(true);
    };

    const resetAppState = () => {
        setSessionInfo(null);
        setDiaryInfo(null);
        setProviderProgressFlags(null);
        setSubscriptionInfo(null);
        setInProgress(false);
    };

    const auth = useAuth(resetAppState);

    const toggleHasSquareEnabled = () => {
        const updatedDiaryInfo = { ...diaryInfo };
        updatedDiaryInfo.hasSquareEnabled = !diaryInfo.hasSquareEnabled;
        setDiaryInfo(updatedDiaryInfo);
    };

    const enableAddOn = (addOnTypeString) => {
        const updatedAddOns = activeAddOns.slice();
        if (updatedAddOns.some((a) => a.type === addOnTypeString)) {
            updatedAddOns.find((a) => a.type === addOnTypeString).status = addOnStatus.active;
        } else {
            updatedAddOns.push(new AddOn(addOnTypeString, addOnStatus.active));
        }
        setActiveAddOns(updatedAddOns);
    };

    const isAddOnEnabled = (addOnTypeString) => {
        if (activeAddOns) {
            return activeAddOns.some((addOn) => addOn.type === addOnTypeString && addOn.status === addOnStatus.active);
        }
        return false;
    };

    const updateUserName = (userName) => {
        const updatedSessionInfo = { ...sessionInfo };
        updatedSessionInfo.userName = userName;
        setSessionInfo(updatedSessionInfo);
    };

    const updateMicrositeName = (micrositeName) => {
        const updatedDiaryInfo = { ...diaryInfo };
        updatedDiaryInfo.micrositeName = micrositeName;
        setDiaryInfo(updatedDiaryInfo);
    };

    const updateTimeZone = (timeZone) => {
        const updatedDiaryInfo = { ...diaryInfo };
        updatedDiaryInfo.timeZone = timeZone;
        setDiaryInfo(updatedDiaryInfo);
    };

    const updatedProviderProgressFlag = (flagName) => {
        const updatedProviderProgressFlags = { ...providerProgressFlags };
        updatedProviderProgressFlags[flagName] = true;
        ProgressFlagService.updateProviderProgressFlags(
            diaryInfo.deploymentId,
            diaryInfo.restaurantId,
            updatedProviderProgressFlags
        );
        setProviderProgressFlags(updatedProviderProgressFlags);
    };

    const updateLoyaltyStatus = (newStatus) => {
        const updatedDiaryInfo = { ...diaryInfo };
        updatedDiaryInfo.loyaltyStatus = newStatus;
        setDiaryInfo(updatedDiaryInfo);
    };

    const alertValue = {
        alerts,
        setAlerts,
        refreshBookings,
        setRefreshBookings,
        updateAlertsAndSetRefreshBookings,
        currentDate,
        setCurrentDate,
        alertBookingOverlayId,
        setAlertBookingOverlayId,
    };
    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const getTokenInfo = useCallback(() => {
        const tokenInfo = SessionHelper.getTokenInfo();

        if (tokenInfo && tokenInfo.token != null) {
            return tokenInfo;
        }

        const authCookie = getAuthTokenCookie();
        if (authCookie && authCookie.token) {
            const authToken = {
                token: authCookie.token,
                tokenExpiryUtc: MomentHelper.newInstanceUtc(authCookie.tokenExpiryDate),
            };

            CookiesHelper.removeCookieWithPathAndDomain(constants.loginSiteAuthCookie, '/', SettingsHelper.getDomain());
            return authToken;
        }

        return null;
    }, []);

    function getAuthTokenCookie() {
        const authToken = CookiesHelper.getCookie(constants.loginSiteAuthCookie);
        if (authToken) {
            return CookiesHelper.parseCookieToJSON(authToken);
        }

        return null;
    }

    const getRestaurantInformation = useCallback(
        (deploymentId, providerId, isAdmin) => {
            RestaurantService.getRestaurantSetup(deploymentId, providerId)
                .then(async (getRestaurantResponse) => {
                    setDiaryInfo({
                        restaurantId: parseInt(providerId),
                        deploymentId: parseInt(deploymentId),
                        restaurantName: getRestaurantResponse.data.Name,
                        segments: getRestaurantResponse.data.Segments,
                        maxPartySize: getRestaurantResponse.data.MaxPartySize,
                        countryCode: getRestaurantResponse.data.CountryCode,
                        timeFormat: getRestaurantResponse.data.TimeFormat,
                        timeZone: getRestaurantResponse.data.TimeZone,
                        micrositeName: getRestaurantResponse.data.MicrositeName,
                        currencySymbol: getRestaurantResponse.data.PaymentSettings.RestaurantCurrency.Symbol,
                        currencyId: getRestaurantResponse.data.PaymentSettings.RestaurantCurrency.Id,
                        hasStripeSubscription: getRestaurantResponse.data.HasStripeSubscription,
                        widgetId: getRestaurantResponse.data.RDLWidgetId,
                        hasSquareEnabled: getRestaurantResponse.data.PaymentSettings.IsSquareEnabled,
                        isLoyaltyEnabled: getRestaurantResponse.data.IsLoyaltyEnabled,
                        hideOnPortal: getRestaurantResponse.data.HideOnPortal,
                        loyaltyStatus: getRestaurantResponse.data.LoyaltyStatus,
                        isQuickServiceRestaurant: getRestaurantResponse.data.IsQuickServiceRestaurant,
                    });

                    await ProgressFlagService.getProviderProgressFlags(deploymentId, providerId)
                        .then((result) => {
                            setProviderProgressFlags(new ProgressFlags(result));
                        })
                        .catch(() => {
                            addErrorBar();
                        });

                    await AccountSubmissionService.getAccountSubscriptionByProviderId(providerId, deploymentId)
                        .then((result) => {
                            setSubscriptionInfo(result);
                        })
                        .catch(() => {
                            addErrorBar();
                        });

                    await AddOnService.getAddOns(deploymentId, providerId).then((result) => {
                        setActiveAddOns(
                            result.length > 0
                                ? result
                                      .filter((f) => f.Status === addOnStatus.active)
                                      .map((a) => new AddOn(a.Type, a.Status))
                                : result
                        );
                    });

                    if (isAdmin) {
                        setIsAdminProviderSelectionVisible(false);
                    }
                })
                .catch(() => {
                    addErrorBar();
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [addErrorBar]
    );

    useEffect(() => {
        if (!loading || inProgress) return;
        setInProgress(true);
        SettingsService.getSettings()
            .then((settingsResponse) => {
                SettingsHelper.setApiUrl(settingsResponse.data.apiUrl);
                SettingsHelper.setPortalUrl(settingsResponse.data.portalUrl);
                SettingsHelper.setSquareApplicationId(settingsResponse.data.squareApplicationId);
                SettingsHelper.setWidgetUrl(settingsResponse.data.widgetUrl);
                SettingsHelper.setSquareUrl(settingsResponse.data.squareUrl);
                SettingsHelper.setContactUsUrl(settingsResponse.data.contactUsUrl);
                SettingsHelper.setGoogleApiKey(settingsResponse.data.googleApiKey);
                SettingsHelper.setDomain(settingsResponse.data.domain);
                SettingsHelper.setUseLiveStripe(settingsResponse.data.useLiveStripe);
                SettingsHelper.setVouchersPortalBaseUrl(settingsResponse.data.vouchersPortalBaseUrl);
                SettingsHelper.setLoginBaseUrl(settingsResponse.data.loginBaseUrl);
                SettingsHelper.setHasFacebookIntegration(settingsResponse.data.hasFacebookIntegration);
                SettingsHelper.setDishCultSupportEmail(settingsResponse.data.dishCultSupportEmail);
                SettingsHelper.setQSRSubscriptionProductId(settingsResponse.data.qsrSubscriptionProductId);
                setVersionNumber(settingsResponse.data.versionNumber);
                if (settingsResponse.data.segmentKey) {
                    AnalyticsHelper.loadAnalytics(settingsResponse.data.segmentKey);
                }
                if (settingsResponse.data.googleTagManagerKey) {
                    const tagManagerArgs = {
                        gtmId: settingsResponse.data.googleTagManagerKey,
                    };
                    TagManager.initialize(tagManagerArgs);
                }
                const tokenInfo = getTokenInfo();
                if (tokenInfo && tokenInfo.token != null) {
                    auth.login(tokenInfo).then(() => {
                        UserService.getCurrentUser()
                            .then(async (getUserResponse) => {
                                setSessionInfo({
                                    userId: getUserResponse.data.Id,
                                    userName: getUserResponse.data.Username,
                                    displayName: getUserResponse.data.DisplayName,
                                    isAdminUser: getUserResponse.data.IsAdmin,
                                });

                                if (getUserResponse.data.IsAdmin) {
                                    const providerInfo = SessionHelper.getProviderInfo();
                                    if (providerInfo) {
                                        getRestaurantInformation(
                                            providerInfo.deploymentId,
                                            providerInfo.providerId,
                                            true
                                        );
                                        setIsAdminProviderSelectionVisible(false);
                                    } else {
                                        setIsAdminProviderSelectionVisible(true);
                                        setLoading(!loading);
                                        setInProgress(false);
                                    }
                                } else {
                                    if (getUserResponse.data.DefaultRestaurantId) {
                                        if (getUserResponse.data.CanAccessRDL) {
                                            const defaultDiaryInfo = getUserResponse.data.Restaurants.find(
                                                (r) => r.Id === getUserResponse.data.DefaultRestaurantId
                                            );
                                            getRestaurantInformation(
                                                defaultDiaryInfo.DeploymentId,
                                                defaultDiaryInfo.Id,
                                                false
                                            );
                                        } else {
                                            setResdiaryAuthenticated(true);
                                            setSessionInfo(null);
                                            setLoading(false);
                                            setInProgress(false);
                                        }
                                    } else {
                                        await AccountSubmissionService.getAccountSubscriptionByApiToken(tokenInfo.token)
                                            .then((result) => {
                                                setSubscriptionInfo(result);
                                                setHasSubscription(result.stripeSubscriptionId != null);
                                            })
                                            .catch(() => {
                                                addErrorBar();
                                            })
                                            .finally(() => {
                                                setLoading(false);
                                                setInProgress(false);
                                            });
                                    }
                                }
                            })
                            .catch((error) => {
                                setLoading(false);
                                setInProgress(false);
                                addErrorBar();
                            });
                    });
                }
                //user is not logged in
                else {
                    setLoading(!loading);
                    setInProgress(false);
                }
            })
            .catch(() => {
                addErrorBar();
                setLoading(!loading);
            });
    }, [addErrorBar, auth, getRestaurantInformation, getTokenInfo, inProgress, loading]);

    useEffect(() => {
        if (!loading && diaryInfo && sessionInfo) {
            const userProperties = { email: sessionInfo.userName };
            AnalyticsHelper.identifyUserIdWithProperties(diaryInfo.restaurantId, userProperties);
        }
    }, [diaryInfo, sessionInfo, loading]);

    if (loading) {
        return <LoadingSpinner />;
    } else {
        if (isAdminProviderSelectionVisible) {
            return (
                <AxiosInterceptor auth={auth}>
                    <AdminProviderLoginScreen onLoginToProvider={getRestaurantInformation} />
                </AxiosInterceptor>
            );
        }
        if (sessionInfo) {
            if (diaryInfo) {
                if (providerProgressFlags && providerProgressFlags.wizardComplete) {
                    return (
                        <AxiosInterceptor auth={auth}>
                            <ErrorBoundary>
                                <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
                                <SessionContext.Provider value={sessionInfo}>
                                    <AddOnContext.Provider value={{ activeAddOns, enableAddOn, isAddOnEnabled }}>
                                        <DiaryContext.Provider
                                            value={{
                                                ...diaryInfo,
                                                ...subscriptionInfo,
                                                toggleHasSquareEnabled,
                                                updateMicrositeName,
                                                updateTimeZone,
                                            }}
                                        >
                                            <AlertContext.Provider value={alertValue}>
                                                <ProviderProgressFlagsContext.Provider
                                                    value={{
                                                        ...providerProgressFlags,
                                                        updatedProviderProgressFlag,
                                                    }}
                                                >
                                                    <Layout
                                                        auth={auth}
                                                        setIsAdminProviderSelectionVisible={
                                                            setIsAdminProviderSelectionVisible
                                                        }
                                                        hideHeaderBar={!auth.authed}
                                                    >
                                                        <Routes>
                                                            <Route
                                                                path="/Welcome"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <WelcomePage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Bookings"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <BookingsPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <BookingsPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Components"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <Components />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <SettingsPage />
                                                                    </RequireAuth>
                                                                }
                                                            />

                                                            <Route
                                                                path="/Vouchers"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <VouchersManagementPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/Tables"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <TableLabelsConfigPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/CloseOuts"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <CloseOutsPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/BookButton"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <BookButtonPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/Square"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <SquareSettingsPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                exact
                                                                path="/Settings/Facebook"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <FacebookSettingsPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/Services"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <ServicesPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/BookingWidget"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <BookingWidgetPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/MenuManagement"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <MenuManagementPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/Venue"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <VenueSettingsPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/Images"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <ImagesSettingsPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/MenuManagement/Create"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <CreateMenuPage />
                                                                    </RequireAuth>
                                                                }
                                                            />

                                                            <Route
                                                                path="/Vouchers/Report"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <VoucherReportPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/StripeConnect"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <StripeConnectPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/MenuManagement/Edit/:id"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <EditMenuPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/Loyalty/Form"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <LoyaltyForm
                                                                            updateLoyaltyStatus={updateLoyaltyStatus}
                                                                            setSubscribedToLoyalty={
                                                                                setSubscribedToLoyalty
                                                                            }
                                                                        />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="/Settings/Loyalty/Report"
                                                                element={
                                                                    <RequireAuth
                                                                        auth={auth}
                                                                        versionNumber={versionNumber}
                                                                        setLoading={setLoading}
                                                                    >
                                                                        <LoyaltyReportPage />
                                                                    </RequireAuth>
                                                                }
                                                            />
                                                            <Route
                                                                path="Settings/Loyalty"
                                                                element={
                                                                    diaryInfo.loyaltyStatus ===
                                                                    loyaltyStatus.inactive ? (
                                                                        <LoyaltyPromoPage />
                                                                    ) : (
                                                                        <RequireAuth
                                                                            auth={auth}
                                                                            versionNumber={versionNumber}
                                                                            setLoading={setLoading}
                                                                        >
                                                                            <LoyaltyDashboard
                                                                                subscribedToLoyalty={
                                                                                    subscribedToLoyalty
                                                                                }
                                                                                setSubscribedToLoyalty={
                                                                                    setSubscribedToLoyalty
                                                                                }
                                                                            />
                                                                        </RequireAuth>
                                                                    )
                                                                }
                                                            />
                                                            <Route path="*" element={<NotFoundPage />} />
                                                            <Route
                                                                path="/DishCultRewards"
                                                                element={
                                                                    diaryInfo.loyaltyStatus ===
                                                                    loyaltyStatus.inactive ? (
                                                                        <RequireAuth
                                                                            auth={auth}
                                                                            versionNumber={versionNumber}
                                                                            setLoading={setLoading}
                                                                        >
                                                                            <LoyaltyPromoPage />
                                                                        </RequireAuth>
                                                                    ) : (
                                                                        <RequireAuth
                                                                            auth={auth}
                                                                            versionNumber={versionNumber}
                                                                            setLoading={setLoading}
                                                                        >
                                                                            <LoyaltyDashboard
                                                                                subscribedToLoyalty={
                                                                                    subscribedToLoyalty
                                                                                }
                                                                                setSubscribedToLoyalty={
                                                                                    setSubscribedToLoyalty
                                                                                }
                                                                            />
                                                                        </RequireAuth>
                                                                    )
                                                                }
                                                            />
                                                        </Routes>
                                                    </Layout>
                                                </ProviderProgressFlagsContext.Provider>
                                            </AlertContext.Provider>
                                        </DiaryContext.Provider>
                                    </AddOnContext.Provider>
                                </SessionContext.Provider>
                            </ErrorBoundary>
                        </AxiosInterceptor>
                    );
                } else {
                    return (
                        <ErrorBoundary>
                            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
                            <SessionContext.Provider value={sessionInfo}>
                                <DiaryContext.Provider
                                    value={{
                                        ...diaryInfo,
                                        ...subscriptionInfo,
                                        toggleHasSquareEnabled,
                                        updateMicrositeName,
                                    }}
                                >
                                    <Layout hideHeaderBar auth={auth}>
                                        <Routes>
                                            <Route path="*" element={<VenueWizardPage />} />
                                            <Route
                                                path="/DishCultRewards"
                                                element={
                                                    diaryInfo.loyaltyStatus === loyaltyStatus.inactive ? (
                                                        <RequireAuth
                                                            auth={auth}
                                                            versionNumber={versionNumber}
                                                            setLoading={setLoading}
                                                        >
                                                            <LoyaltyPromoPage />
                                                        </RequireAuth>
                                                    ) : (
                                                        <RequireAuth
                                                            auth={auth}
                                                            versionNumber={versionNumber}
                                                            setLoading={setLoading}
                                                        >
                                                            <LoyaltyDashboard
                                                                subscribedToLoyalty={subscribedToLoyalty}
                                                                setSubscribedToLoyalty={setSubscribedToLoyalty}
                                                            />
                                                        </RequireAuth>
                                                    )
                                                }
                                            />{' '}
                                        </Routes>
                                    </Layout>
                                </DiaryContext.Provider>
                            </SessionContext.Provider>
                        </ErrorBoundary>
                    );
                }
            } else {
                if (hasSubscription) {
                    return (
                        <ErrorBoundary>
                            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
                            <SessionContext.Provider value={{ ...sessionInfo, updateUserName }}>
                                <Layout hideHeaderBar auth={auth}>
                                    <Routes>
                                        <Route path="/" element={<SuccessPage />} />
                                        <Route path="/AccountSubmission/Success" element={<SuccessPage />} />
                                        <Route
                                            path="/DishCultRewards"
                                            element={
                                                diaryInfo.loyaltyStatus === loyaltyStatus.inactive ? (
                                                    <RequireAuth
                                                        auth={auth}
                                                        versionNumber={versionNumber}
                                                        setLoading={setLoading}
                                                    >
                                                        <LoyaltyPromoPage />
                                                    </RequireAuth>
                                                ) : (
                                                    <RequireAuth
                                                        auth={auth}
                                                        versionNumber={versionNumber}
                                                        setLoading={setLoading}
                                                    >
                                                        <LoyaltyDashboard
                                                            subscribedToLoyalty={subscribedToLoyalty}
                                                            setSubscribedToLoyalty={setSubscribedToLoyalty}
                                                        />
                                                    </RequireAuth>
                                                )
                                            }
                                        />{' '}
                                    </Routes>
                                </Layout>
                            </SessionContext.Provider>
                        </ErrorBoundary>
                    );
                } else {
                    return (
                        <ErrorBoundary>
                            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
                            <SessionContext.Provider value={{ ...sessionInfo, updateUserName }}>
                                <DiaryContext.Provider value={{ ...diaryInfo, toggleHasSquareEnabled }}>
                                    <Layout hideHeaderBar auth={auth}>
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={
                                                    <CreateAccountPage
                                                        accountCreated
                                                        auth={auth}
                                                        account={account}
                                                        setAccount={setAccount}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/CreateAccount"
                                                element={
                                                    <CreateAccountPage
                                                        accountCreated
                                                        auth={auth}
                                                        account={account}
                                                        setAccount={setAccount}
                                                    />
                                                }
                                            />
                                            <Route path="/AccountSubmission/Success" element={<SuccessPage />} />
                                            <Route
                                                path="/QuickServiceRestaurant"
                                                element={
                                                    <CreateAccountPage
                                                        accountCreated
                                                        auth={auth}
                                                        isQuickServiceRestaurant={true}
                                                        account={account}
                                                        setAccount={setAccount}
                                                    />
                                                }
                                            />
                                        </Routes>
                                    </Layout>
                                </DiaryContext.Provider>
                            </SessionContext.Provider>
                        </ErrorBoundary>
                    );
                }
            }
        } else {
            return (
                <ErrorBoundary>
                    <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
                    <SessionContext.Provider value={{ ...sessionInfo, updateUserName, resdiaryUserAuthenticated }}>
                        <Routes>
                            <Route
                                path="/CreateAccount"
                                element={<CreateAccountPage auth={auth} account={account} setAccount={setAccount} />}
                            />
                            <Route
                                path="/QuickServiceRestaurant"
                                element={
                                    <CreateAccountPage
                                        auth={auth}
                                        isQuickServiceRestaurant={true}
                                        account={account}
                                        setAccount={setAccount}
                                    />
                                }
                            />
                            <Route
                                path="Login"
                                element={
                                    <LoginPage
                                        versionNumber={versionNumber}
                                        login={auth.login}
                                        setLoading={setLoading}
                                    />
                                }
                            />
                            <Route
                                path="/"
                                element={
                                    <LoginPage
                                        versionNumber={versionNumber}
                                        login={auth.login}
                                        setLoading={setLoading}
                                    />
                                }
                            />
                            <Route path="/ForgotPassword" element={<ForgotPasswordPage />} />
                            <Route path="/SetPassword" element={<ResetPasswordPage />} />
                            <Route path="/ResetPassword" element={<ResetPasswordPage />} />
                            <Route
                                path="*"
                                element={
                                    <LoginPage
                                        versionNumber={versionNumber}
                                        login={auth.login}
                                        setLoading={setLoading}
                                    />
                                }
                            />
                            <Route
                                path="/DishCultRewards"
                                element={
                                    !diaryInfo || diaryInfo.loyaltyStatus !== loyaltyStatus.active ? (
                                        <RequireAuth auth={auth} versionNumber={versionNumber} setLoading={setLoading}>
                                            <LoyaltyPromoPage />
                                        </RequireAuth>
                                    ) : (
                                        <RequireAuth auth={auth} versionNumber={versionNumber} setLoading={setLoading}>
                                            <LoyaltyDashboard
                                                subscribedToLoyalty={subscribedToLoyalty}
                                                setSubscribedToLoyalty={setSubscribedToLoyalty}
                                            />
                                        </RequireAuth>
                                    )
                                }
                            />
                        </Routes>
                    </SessionContext.Provider>
                </ErrorBoundary>
            );
        }
    }
}
