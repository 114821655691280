import React from 'react';
import PropTypes from 'prop-types';

function ModalPanel({ icon, text, iconClass, textClass, className, infoText, infoTextClass }) {
    return (
        <div>
            <div className={className ? 'd-flex modal-panel ' + className : 'd-flex modal-panel'}>
                <div className={iconClass ? 'icon ' + iconClass : 'icon'}>{icon}</div>
                <div className={textClass ? 'text ' + textClass : 'text'}>{text}</div>
            </div>
            <div className={className ? 'd-flex modal-panel ' + className : 'd-flex modal-panel'}>
                <div className={infoTextClass ? 'infoText ' + infoTextClass : 'infoText'}>{infoText}</div>
            </div>
        </div>
    );
}

ModalPanel.propTypes = {
    icon: PropTypes.object.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    iconClass: PropTypes.string,
    textClass: PropTypes.string,
    infoText: PropTypes.string,
    infoTextClass: PropTypes.string,
};

export default ModalPanel;
