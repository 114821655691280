import React, { useState, Fragment, useEffect, useCallback, useContext } from 'react';
import RunningOrderControls from '../booking/RunningOrderControls';
import SidePanelPage from '../common/SidePanelPage';
import FilterPanel from '../common/FilterPanel';
import { Button } from 'reactstrap';
import { ReactComponent as EditIcon } from '../../content/icons/edit.svg';
import { ReactComponent as CancelIcon } from '../../content/icons/Icon-Cancel.svg';
import { ReactComponent as CloseIcon } from '../../content/icons/Icon-Close.svg';
import Overlay from '../common/Overlay';
import { useIntl } from 'react-intl';
import RestaurantService from '../../services/RestaurantService';
import BookingService from '../../services/BookingService';
import CustomerService from '../../services/CustomerService';
import VoucherService from '../../services/VoucherService';
import CountryService from '../../services/CountryService';
import BookingSummaryOverlayContent from '../../domainObjects/BookingSummaryOverlayContent';
import BookingFilterOverlayContent from '../../domainObjects/BookingFilterOverlayContent';
import Service from '../../domainObjects/Service';
import Booking from '../../domainObjects/Booking';
import MomentHelper from '../../helpers/MomentHelper';
import infoBarType from '../../enums/infoBarType';
import InfoBars from '../common/InfoBars';
import bookingFilterType from '../../enums/bookingFilterType';
import bookingStatus from '../../enums/bookingStatus';
import BookingReason from '../../domainObjects/BookingReason';
import OverlayContent from '../overlay/OverlayContent';
import Customer from '../../domainObjects/Customer';
import useDebounce from '../../hooks/useDebounce';
import overlayType from '../../enums/overlayType';
import DiaryContext from '../../contexts/DiaryContext';
import AlertContext from '../../contexts/AlertContext';
import ProviderProgressFlagsContext from '../../contexts/ProviderProgressFlagsContext';
import CancellationModal from './CancellationModal';
import MenuChangeModal from '../overlay/MenuChangeModal';
import useBookingsPollRequest from '../../hooks/useBookingsPollRequest';
import TableLabelService from '../../services/TableLabelService';
import TableLabel from '../../domainObjects/TableLabel';
import useScreenSize from '../../hooks/useScreenSize';
import useQueryParam from '../../hooks/useQueryParam';
import NewBookingOverlayContent from '../../domainObjects/NewBookingOverlayContent';
import { ReactComponent as MaximiseIcon } from '../.././content/icons/maximise.svg';
import orderAtTableStatus from '../../enums/orderAtTableStatus';
import alertType from '../../enums/alertType';
import RunningOrderTable from './RunningOrderTable';
import MenuManagementMenu from '../../domainObjects/MenuManagementMenu';
import ImproveListingsFooter from '../accountSubmission/ImproveListingsFooter';
import { useNavigate } from 'react-router-dom';

let _ID = 0;

function BookingsPage() {
    const intl = useIntl();
    const [selectedFilter, onSelectedBookingFilterChange] = useState(bookingFilterType.allBookings);
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [overlayAnimating, setOverlayAnimating] = useState(false);
    const [services, setServiceData] = useState([]);
    const [overlayContent, setOverlayContent] = useState(null);
    const [bookings, setBookingData] = useState([]);
    const [unAllocatedBookings, setUnallocatedBookings] = useState([]);
    const [allBookings, setAllBookings] = useState([]);
    const [date, setDate] = useState(MomentHelper.newInstance().startOf('day'));
    const [infoBars, setInfoBars] = useState([]);
    const [bookingReasons, setBookingReasons] = useState([]);
    const [isSegmentSetupLoading, setIsSegmentSetupLoading] = useState(true);
    const [bookingStatusControlOpen, setBookingStatusControlOpen] = useState(false);
    const [isBookingsForDateLoading, setIsBookingsForDateLoading] = useState(true);
    const [isBookingReasonsLoading, setIsBookingReasonsLoading] = useState(true);
    const [isCountryCodesLoading, setIsCountryCodesLoading] = useState(true);
    const debouncedDateString = useDebounce(date.format('YYYY-MM-DD'));
    const diaryContext = useContext(DiaryContext);
    const [isMakingRequest, setIsMakingRequest] = useState(false);
    const [showCustomerOverlay, setShowCustomerOverlay] = useState(false);
    const [isEditingCustomer, setIsEditingCustomer] = useState(false);
    const [isEditingBooking, setIsEditingBooking] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);
    const [bookingToBeCancelled, setBookingToBeCancelled] = useState(null);
    const [isMenuChangeModalOpen, setIsMenuChangeModalOpen] = useState(false);
    const [menuToBeSent, setMenuToBeSent] = useState(null);
    const [bookingToBeSentMenu, setBookingToBeSentMenu] = useState(null);
    const [sentMenuVersionId, setSentMenuVersionId] = useState(null);
    const [menuChangeName, setMenuChangeName] = useState('');
    const [tableLabels, setTableLabels] = useState([]);
    const [isTableLabelsLoading, setIsTableLabelsLoading] = useState(true);
    const { isMobileView } = useScreenSize();
    const [customerSearchTerm, setCustomerSearchTerm] = useState('');
    const [isMenusLoading, setIsMenusLoading] = useState(false);
    const [menus, setMenus] = useState([]);
    const providerProgressFlagsContext = useContext(ProviderProgressFlagsContext);
    const navigate = useNavigate();
    const addBooking = useQueryParam('addBooking');

    const { newBookings, isFetching, isPollingRequest, newUnAllocatedBookings } =
        useBookingsPollRequest(debouncedDateString);
    const {
        alerts,
        setAlerts,
        refreshBookings,
        setRefreshBookings,
        setCurrentDate,
        alertBookingOverlayId,
        setAlertBookingOverlayId,
    } = useContext(AlertContext);

    const bookingSummaryIsAlreadyOpen = useCallback(
        (newOverlayContent) => {
            return (
                overlayContent &&
                newOverlayContent.overlayType === overlayType.booking &&
                overlayContent.overlayType === overlayType.booking &&
                newOverlayContent.booking.id === overlayContent.booking.id
            );
        },
        [overlayContent]
    );

    const closeOverlay = useCallback(() => {
        if (overlayOpen) {
            setBookingStatusControlOpen(false);
            setOverlayContent(null);
            setOverlayAnimating(true);
            setShowCustomerOverlay(false);
            setIsEditingCustomer(false);
            setIsEditingBooking(false);
            setTimeout(() => {
                setOverlayAnimating(false);
            }, 300);
            setOverlayOpen(false);
        }
    }, [overlayOpen]);

    const isDataLoading = useCallback(() => {
        return (
            isSegmentSetupLoading ||
            isBookingsForDateLoading ||
            isBookingReasonsLoading ||
            isCountryCodesLoading ||
            isTableLabelsLoading ||
            isMenusLoading
        );
    }, [
        isBookingReasonsLoading,
        isBookingsForDateLoading,
        isCountryCodesLoading,
        isSegmentSetupLoading,
        isTableLabelsLoading,
        isMenusLoading,
    ]);

    const retrieveNewSegmentId = useCallback(() => {
        let segmentId;
        diaryContext.segments.forEach((segment) => {
            segment.ValidityPeriods.forEach((p) => {
                if (
                    MomentHelper.isDateWithinRange(
                        MomentHelper.newInstance(debouncedDateString),
                        p.StartDate,
                        p.EndDate
                    )
                ) {
                    segmentId = segment.Id;
                    return;
                }
            });
        });

        return segmentId;
    }, [debouncedDateString, diaryContext.segments]);

    const openOverlay = useCallback(
        (newOverlayContent) => {
            if (bookingSummaryIsAlreadyOpen(newOverlayContent)) {
                closeOverlay();
            } else {
                setTimeout(
                    () => {
                        setOverlayContent(newOverlayContent);
                        setOverlayOpen(true);
                    },
                    overlayAnimating ? 300 : 0
                );
            }
        },
        [bookingSummaryIsAlreadyOpen, closeOverlay, overlayAnimating]
    );

    const [segmentId, setSegmentId] = useState(retrieveNewSegmentId());

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const bookingIds = allBookings.map((b) => b.id);
    const newAlertBookings = newBookings.filter((newBooking) => !bookingIds.includes(newBooking.id));

    const newOrderAtTableStatusBookings = useCallback(() => {
        return newBookings.filter((nb) => {
            let booking = allBookings.find((s) => s.id === nb.id);
            return booking
                ? booking.orderAtTableStatus &&
                      booking.orderAtTableStatus === orderAtTableStatus.MenuSent &&
                      nb.orderAtTableStatus &&
                      nb.orderAtTableStatus !== orderAtTableStatus.MenuSent &&
                      booking.orderAtTableStatus !== nb.orderAtTableStatus
                : false;
        });
    }, [allBookings, newBookings]);

    const getTableLabelData = useCallback(
        (tableLabelIds) => {
            let tableLabelsForBooking = [];
            tableLabelIds.forEach((id) => {
                tableLabelsForBooking.push(tableLabels.find((tableLabel) => tableLabel.id === id));
            });

            return tableLabelsForBooking;
        },
        [tableLabels]
    );

    useEffect(() => {
        if (alertBookingOverlayId) {
            const booking = newBookings.find((booking) => booking.id === alertBookingOverlayId);
            openOverlay(new BookingSummaryOverlayContent(booking));
            setAlertBookingOverlayId(null);
        }
    }, [alertBookingOverlayId, newBookings, openOverlay, setAlertBookingOverlayId]);

    useEffect(() => {
        if (isDataLoading()) return;
        const newAlerts = [...alerts];
        const newAlertIds = newAlerts.map((a) => a.id);
        newAlertBookings.forEach((newBooking) => {
            if (!newAlertIds.includes(newBooking.id)) {
                newAlerts.unshift({
                    id: newBooking.id,
                    isNew: true,
                    type: alertType.newBooking,
                    booking: newBooking,
                    tableLabels: getTableLabelData(newBooking.tableLabelIds),
                });
            }
        });
        newOrderAtTableStatusBookings().forEach((booking) => {
            if (!newAlertIds.includes(booking.id)) {
                newAlerts.unshift({
                    id: booking.id,
                    isNew: true,
                    type: alertType.orderAndPay,
                    booking: booking,
                    tableLabels: getTableLabelData(booking.tableLabelIds),
                });
            }
        });
        if (alerts.length !== newAlerts.length) {
            setAlerts(newAlerts);
        }
    }, [isDataLoading, alerts, newAlertBookings, setAlerts, newOrderAtTableStatusBookings, getTableLabelData]);

    useEffect(() => {
        setAlerts([]);
        setCurrentDate(MomentHelper.newInstance(debouncedDateString));
    }, [debouncedDateString, setAlerts, setCurrentDate]);

    useEffect(() => {
        if (refreshBookings) {
            setAllBookings(newBookings);
            setUnallocatedBookings(newUnAllocatedBookings);
            setRefreshBookings(false);
        }
    }, [refreshBookings, newBookings, setRefreshBookings, newUnAllocatedBookings]);

    useEffect(() => {
        const hasSegmentChanged = retrieveNewSegmentId() !== segmentId;
        const currentSegmentId = hasSegmentChanged ? retrieveNewSegmentId() : segmentId;
        if (!currentSegmentId && !segmentId) {
            addErrorBar(intl.formatMessage({ id: 'Bookings.ProviderNotSetupForDateWarningMessage' }));
        } else if ((hasSegmentChanged || services.length === 0) && currentSegmentId) {
            setIsSegmentSetupLoading(true);
            RestaurantService.getSegmentSetup(diaryContext.deploymentId, diaryContext.restaurantId, currentSegmentId)
                .then((segmentResponse) => {
                    const servicesData = segmentResponse.Services.map((service) => new Service(service));
                    setServiceData(servicesData);
                })
                .catch(() => {
                    addErrorBar();
                })
                .finally(() => {
                    setIsSegmentSetupLoading(false);
                });
        }
    }, [
        addErrorBar,
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        debouncedDateString,
        retrieveNewSegmentId,
        segmentId,
        services.length,
        intl,
    ]);

    useEffect(() => {
        setSegmentId(retrieveNewSegmentId(debouncedDateString));
    }, [debouncedDateString, retrieveNewSegmentId]);

    useEffect(() => {
        setIsBookingsForDateLoading(true);
    }, [debouncedDateString]);

    useEffect(() => {
        if (!isFetching && isBookingsForDateLoading) {
            setAllBookings(newBookings);
            setUnallocatedBookings(newUnAllocatedBookings);
            setIsBookingsForDateLoading(false);
        }
    }, [isFetching, isBookingsForDateLoading, newBookings, newUnAllocatedBookings]);

    useEffect(() => {
        setIsCountryCodesLoading(true);
        CountryService.getCountryCodes()
            .then((countryCodes) => {
                setCountryCodes(countryCodes);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsCountryCodesLoading(false);
            });
    }, [addErrorBar]);

    useEffect(() => {
        const bookingsCopy = [...allBookings];

        switch (selectedFilter) {
            case bookingFilterType.allBookings:
                setBookingData(bookingsCopy);
                break;
            case bookingFilterType.activeBookings:
                setBookingData(bookingsCopy.filter((b) => b.status !== bookingStatus.cancelled));
                break;
            case bookingFilterType.cancelledBookings:
                setBookingData(bookingsCopy.filter((b) => b.status === bookingStatus.cancelled));
                break;
            default:
                setBookingData(bookingsCopy);
        }
    }, [selectedFilter, allBookings]);

    useEffect(() => {
        if (overlayContent) {
            setOverlayOpen(true);
        }
    }, [overlayContent]);

    useEffect(() => {
        setIsMenusLoading(true);
        BookingService.getAllMenus(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((response) => {
                if (response.length > 0) {
                    let data = response.map((menu) => new MenuManagementMenu(menu));
                    setMenus(data);
                }
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsMenusLoading(false);
            });
    }, [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        setIsBookingReasonsLoading(true);
        BookingService.getBookingReasons(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((response) => {
                const data = response.map((bookingReason) => new BookingReason(bookingReason));
                setBookingReasons(data);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsBookingReasonsLoading(false);
            });
    }, [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        setIsTableLabelsLoading(true);
        TableLabelService.getAllTableLabels(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((response) => {
                const data = response.TableLabels.map((label) => new TableLabel(label));
                setTableLabels(data);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsTableLabelsLoading(false);
            });
    }, [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        if (addBooking && addBooking === 'true') {
            openOverlay(new NewBookingOverlayContent());
            navigate('/Bookings');
        }
    }, [navigate, openOverlay, addBooking]);

    function getBookingReasonNames(bookingReasonIds) {
        const bookingReasonsForBooking = [];
        bookingReasonIds.forEach((id) => {
            bookingReasonsForBooking.push({ id: id, name: bookingReasons.find((br) => br.id === id).name });
        });
        return bookingReasonsForBooking;
    }

    function typeaheadItemSelected(bookingId) {
        let booking = allBookings.find((booking) => booking.id === bookingId);
        if (!booking) {
            BookingService.getBooking(diaryContext.deploymentId, diaryContext.restaurantId, bookingId)
                .then((retrievedBooking) => {
                    booking = new Booking(retrievedBooking);
                    setDate(booking.visitDateTime);
                    CustomerService.getCustomer(
                        diaryContext.deploymentId,
                        diaryContext.restaurantId,
                        retrievedBooking.CustomerId
                    ).then((customer) => {
                        booking.customer = new Customer(customer);
                        openOverlay(new BookingSummaryOverlayContent(booking));
                    });
                })
                .catch((error) => {
                    addErrorBar();
                });
        } else {
            openOverlay(new BookingSummaryOverlayContent(booking));
        }
    }

    function getHeaderChildren() {
        if (overlayContent) {
            if (showCustomerOverlay) {
                return (
                    <Button className="ml-auto overlay-header-child" onClick={() => toggleShowCustomerOverlay(false)}>
                        {' '}
                        {<CloseIcon />}{' '}
                    </Button>
                );
            }
            switch (overlayContent.overlayType) {
                case overlayType.booking: {
                    let isCancelled = overlayContent.booking.status === bookingStatus.cancelled;
                    if (!isCancelled) {
                        return (
                            <Fragment>
                                <Button
                                    className="ml-auto overlay-header-child"
                                    onClick={() => openEditBookingOverlay(overlayContent.booking)}
                                >
                                    {' '}
                                    {<EditIcon />}{' '}
                                </Button>
                                <Button
                                    className={'overlay-header-child'}
                                    onClick={() => openCancellationModalWithBookingId(overlayContent.booking)}
                                >
                                    {<CancelIcon />}
                                </Button>
                            </Fragment>
                        );
                    }
                    break;
                }
                default:
                    return null;
            }
        }
        return null;
    }

    function getSidePanel() {
        return (
            <FilterPanel
                selectedFilter={selectedFilter}
                onSelectedBookingFilterChange={onSelectedBookingFilterChange}
            />
        );
    }

    function toggleBookingStatusTooltip() {
        setBookingStatusControlOpen(!bookingStatusControlOpen);
    }

    function toggleShowCustomerOverlay(isEditing, customerSearchTerm) {
        if (!isEditing && !showCustomerOverlay && customerSearchTerm) {
            setCustomerSearchTerm(customerSearchTerm);
        } else {
            setCustomerSearchTerm('');
        }
        setShowCustomerOverlay(!showCustomerOverlay);
        setIsEditingCustomer(isEditing);
    }

    function openEditBookingOverlay(booking, callback) {
        setIsEditingBooking(true);
        setOverlayContent(new NewBookingOverlayContent(booking));
        if (callback) {
            callback();
        }
    }

    function addNewCustomer(customer, bookingId, callback) {
        setIsMakingRequest(true);
        CustomerService.createCustomer(diaryContext.deploymentId, diaryContext.restaurantId, customer)
            .then((result) => {
                const booking = bookings.find((b) => b.id === bookingId);
                if (booking) {
                    booking.customerId = result.Id;
                    toggleShowCustomerOverlay(false);
                } else {
                    setIsMakingRequest(false);
                    toggleShowCustomerOverlay(false);
                }
                if (callback) {
                    callback(result);
                }
            })
            .catch(() => {
                addErrorBar();
            });
    }

    function updateCustomer(customer, bookingId, callback) {
        setIsMakingRequest(true);
        CustomerService.updateCustomer(diaryContext.deploymentId, diaryContext.restaurantId, customer)
            .then((result) => {
                if (bookingId) {
                    let bookingsCopy = [...allBookings];
                    let bookingIndex = bookingsCopy.findIndex((b) => b.id === bookingId);
                    bookingsCopy[bookingIndex].customer = new Customer(result);
                    const bookingsWithSameCustomer = bookingsCopy.filter(
                        (b) => b.customer && b.customer.id === result.Id && b.id !== bookingId
                    );
                    if (bookingsWithSameCustomer.length > 0) {
                        bookingsWithSameCustomer.forEach((b) => {
                            b.customer = new Customer(result);
                            let index = bookingsCopy.findIndex((booking) => booking.id === b.id);
                            bookingsCopy[index] = b;
                        });
                    }
                    setAllBookings(bookingsCopy);
                    if (overlayOpen) {
                        setOverlayContent(new NewBookingOverlayContent(bookingsCopy[bookingIndex]));
                    }
                }

                addSuccessBar(intl.formatMessage({ id: 'Customer.CustomerUpdatedSuccessfullyMessage' }));

                if (callback) {
                    callback(result);
                }
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                toggleShowCustomerOverlay(false);
                setIsMakingRequest(false);
            });
    }

    function replaceCustomer(customerId, bookingId, callback) {
        setIsMakingRequest(true);
        if (bookingId) {
            const booking = bookings.find((b) => b.id === bookingId);
            booking.customerId = customerId;
            updateBooking(booking, callback);
        } else {
            CustomerService.getCustomer(diaryContext.deploymentId, diaryContext.restaurantId, customerId)
                .then((customer) => {
                    if (callback) {
                        callback(customer);
                    }
                })
                .catch(() => {
                    addErrorBar();
                })
                .finally(() => {
                    setIsMakingRequest(false);
                });
        }
    }

    function postBookingCreatedCallback(bookingData, voucher) {
        if (!MomentHelper.newInstance(bookingData.VisitDateTime).isSame(date, 'day')) {
            setDate(MomentHelper.newInstance(bookingData.VisitDateTime));
        } else {
            const bookingsCopy = [...allBookings];
            const newBooking = new Booking(bookingData);
            if (voucher) {
                VoucherService.redeemVoucherAgainstBooking(
                    diaryContext.deploymentId,
                    diaryContext.restaurantId,
                    newBooking.id,
                    voucher.reference
                )
                    .then(() => {
                        newBooking.voucherName = voucher.name;
                        newBooking.voucherReference = voucher.reference;
                        newBooking.voucherCost = voucher.cost;
                    })
                    .catch(() => {
                        addErrorBar(intl.formatMessage({ id: 'Vouchers.ProblemRedeemingVoucherErrorMessage' }));
                    });
            }
            if (bookingData.CustomerId) {
                CustomerService.getCustomer(
                    diaryContext.deploymentId,
                    diaryContext.restaurantId,
                    bookingData.CustomerId
                ).then((customer) => {
                    newBooking.customer = new Customer(customer);
                    bookingsCopy.push(newBooking);
                    setAllBookings(bookingsCopy);
                });
            } else {
                bookingsCopy.push(newBooking);
                setAllBookings(bookingsCopy);
            }
        }
    }

    function sendMenuNotification(bookingId, menuId, menuVersionId) {
        BookingService.sendMenuNotification(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            bookingToBeSentMenu ? bookingToBeSentMenu : bookingId,
            menuToBeSent ? menuToBeSent : menuId
        )
            .then(() => {
                let sentBookingId = bookingToBeSentMenu ? bookingToBeSentMenu : bookingId;
                let sentMenuId = sentMenuVersionId ? sentMenuVersionId : menuVersionId;
                let bookingsCopy = [...bookings];
                let index = bookingsCopy.findIndex((b) => b.id === sentBookingId);
                if (index >= 0) {
                    bookingsCopy[index].orderAtTableStatus = orderAtTableStatus.MenuSent;
                    bookingsCopy[index].preorderMenuVersionId = sentMenuId;
                    setBookingData(bookingsCopy);
                } else {
                    bookingsCopy = [...unAllocatedBookings];
                    index = bookingsCopy.findIndex((b) => b.id === sentBookingId);
                    if (index >= 0) {
                        bookingsCopy[index].orderAtTableStatus = orderAtTableStatus.MenuSent;
                        bookingsCopy[index].preorderMenuVersionId = sentMenuId;
                        setUnallocatedBookings(bookingsCopy);
                    }
                }

                if (menuToBeSent) {
                    setIsMenuChangeModalOpen(false);
                }
                closeOverlay();
                addSuccessBar(intl.formatMessage({ id: 'Bookings.SentMenuSuccessMessage' }));
            })
            .catch(() => {
                setIsMenuChangeModalOpen(false);
                addErrorBar();
            });
    }

    function toggleMenuChangeModalOpened(isOpen) {
        if (!isOpen) {
            setMenuToBeSent(null);
            setBookingToBeSentMenu(null);
        }
        setIsMenuChangeModalOpen(isOpen);
    }

    function getOverlayContent() {
        return (
            <OverlayContent
                overlayContent={overlayContent}
                getBookingReasonNames={getBookingReasonNames}
                getTableLabelData={getTableLabelData}
                updateArrivalStatus={updateArrivalStatus}
                openCancellationModalWithBookingId={openCancellationModalWithBookingId}
                closeBooking={closeBooking}
                rebookBooking={openEditBookingOverlay}
                toggleReconfirmedStatus={toggleReconfirmedStatus}
                bookingStatusControlOpen={bookingStatusControlOpen}
                toggleBookingStatusTooltip={toggleBookingStatusTooltip}
                closeOverlay={closeOverlay}
                postBookingCreatedCallback={postBookingCreatedCallback}
                bookingReasons={bookingReasons}
                toggleShowCustomerOverlay={toggleShowCustomerOverlay}
                showCustomerOverlay={showCustomerOverlay}
                isEditingCustomer={isEditingCustomer}
                countryCodes={countryCodes}
                countryCode={diaryContext.countryCode ? diaryContext.countryCode : 44}
                addNewCustomer={addNewCustomer}
                updateCustomer={updateCustomer}
                isMakingRequest={isMakingRequest}
                replaceCustomer={replaceCustomer}
                closeBookingStatusControl={closeBookingStatusControl}
                isEditingBooking={isEditingBooking}
                updateBooking={updateBooking}
                addSuccessBar={addSuccessBar}
                tableLabels={tableLabels}
                diaryDate={date}
                customerSearchTerm={customerSearchTerm}
                selectedFilter={selectedFilter}
                onSelectedBookingFilterChange={onSelectedBookingFilterChange}
                sendMenuNotification={sendMenuNotification}
                bookings={bookings}
                menus={menus.filter((d) => !d.isDisabled)}
                openMenuChangeModal={openMenuChangeModal}
                showListingsFooter={showListingsFooter()}
            />
        );
    }

    function getOverlayTitle() {
        if (overlayContent) {
            if (showCustomerOverlay) {
                return isEditingCustomer
                    ? intl.formatMessage({
                          id: 'Customer.EditCustomer',
                      })
                    : intl.formatMessage({
                          id: 'Customer.AddCustomer',
                      });
            }
            switch (overlayContent.overlayType) {
                case overlayType.booking:
                    return intl.formatMessage({
                        id: 'Bookings.BookingSummary',
                    });
                case overlayType.newBooking:
                    return isEditingBooking
                        ? intl.formatMessage({
                              id: 'Bookings.EditBooking',
                          })
                        : intl.formatMessage({
                              id: 'Bookings.AddBooking',
                          });
                default:
                    return '';
            }
        }
        return '';
    }

    function getPageBody() {
        return (
            <Fragment>
                <div className="sticky-top" data-testid="bookings-page">
                    <RunningOrderControls
                        date={date}
                        setDate={setDate}
                        typeaheadItemSelected={typeaheadItemSelected}
                        openOverlay={openOverlay}
                        isLoading={isFetching && !isPollingRequest} //only when manually changing date, not when polling
                    />
                </div>
                <RunningOrderTable
                    services={services}
                    bookings={bookings}
                    isLoading={isDataLoading}
                    isMobileView={isMobileView}
                    getBookingReasonNames={getBookingReasonNames}
                    getTableLabelData={getTableLabelData}
                    openOverlay={openOverlay}
                    updateArrivalStatus={updateArrivalStatus}
                    closeBooking={closeBooking}
                    openCancellationModalWithBookingId={openCancellationModalWithBookingId}
                    rebookBooking={openEditBookingOverlay}
                    toggleReconfirmedStatus={toggleReconfirmedStatus}
                    unAllocatedBookings={unAllocatedBookings}
                    debouncedDateString={debouncedDateString}
                />
                <Overlay
                    overlayOpen={overlayOpen}
                    overlayTitle={getOverlayTitle()}
                    overlayBackButtonAction={overlayBackButtonAction}
                    headerChildren={getHeaderChildren()}
                    overlayContent={getOverlayContent()}
                    showCloseButton={!showCustomerOverlay}
                    isMobileView={isMobileView}
                    isFilterOverlay={overlayContent && overlayContent.overlayType === overlayType.filter}
                />

                {isCancellationModalOpen && (
                    <CancellationModal
                        isModalOpen={isCancellationModalOpen}
                        setIsCancellationModalOpen={setIsCancellationModalOpen}
                        cancelBooking={cancelBooking}
                    />
                )}

                {isMenuChangeModalOpen && (
                    <MenuChangeModal
                        isModalOpen={isMenuChangeModalOpen}
                        setIsMenuChangeModalOpen={toggleMenuChangeModalOpened}
                        sendNewMenuNotification={sendMenuNotification}
                        menuChangeName={menuChangeName}
                    />
                )}

                {showListingsFooter() && <ImproveListingsFooter />}
            </Fragment>
        );
    }

    function updateArrivalStatus(booking, newArrivalStatus, callback) {
        BookingService.setArrivalStatus(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            booking.id,
            newArrivalStatus
        ).then((updatedArrivalStatus) => {
            let bookingsCopy = [...allBookings];
            let index = bookingsCopy.findIndex((b) => b.id === booking.id);
            if (index >= 0) {
                bookingsCopy[index].arrivalStatus = updatedArrivalStatus;
                bookingsCopy[index].status = bookingStatus.confirmed;
                setAllBookings(bookingsCopy);
                if (overlayOpen) {
                    setOverlayContent(new BookingSummaryOverlayContent(bookingsCopy[index]));
                }
            } else {
                booking.arrivalStatus = updatedArrivalStatus;
                booking.status = bookingStatus.confirmed;
                if (overlayOpen) {
                    setOverlayContent(new BookingSummaryOverlayContent(booking));
                }
            }
            if (callback) {
                callback();
            }
        });
    }

    function closeBooking(booking, callback) {
        booking.status = bookingStatus.closed;
        BookingService.closeBooking(diaryContext.deploymentId, diaryContext.restaurantId, booking.id).then(
            (bookingStatus) => {
                let bookingsCopy = [...allBookings];
                let index = bookingsCopy.findIndex((b) => b.id === booking.id);
                if (index >= 0) {
                    bookingsCopy[index].status = bookingStatus;
                    setAllBookings(bookingsCopy);
                    if (overlayOpen) {
                        setOverlayContent(new BookingSummaryOverlayContent(bookingsCopy[index]));
                    }
                } else {
                    booking.status = bookingStatus;
                    if (overlayOpen) {
                        setOverlayContent(new BookingSummaryOverlayContent(booking));
                    }
                }
                if (callback) {
                    callback();
                }
            }
        );
    }

    function cancelBooking(failureCallback) {
        BookingService.cancelBooking(diaryContext.deploymentId, diaryContext.restaurantId, bookingToBeCancelled.id)
            .then(() => {
                let bookingsCopy = [...allBookings];

                if (bookingToBeCancelled.isUnallocated) {
                    let unallocatedBookings = unAllocatedBookings.filter((b) => b.id !== bookingToBeCancelled.id);
                    setUnallocatedBookings(unallocatedBookings);
                    bookingsCopy.push(bookingToBeCancelled);
                }

                let index = bookingsCopy.findIndex((b) => b.id === bookingToBeCancelled.id);

                if (index >= 0) {
                    bookingsCopy[index].status = bookingStatus.cancelled;
                    setAllBookings(bookingsCopy);
                    if (overlayOpen) {
                        setOverlayContent(new BookingSummaryOverlayContent(bookingsCopy[index]));
                    }
                } else {
                    bookingToBeCancelled.status = bookingStatus.cancelled;
                    if (overlayOpen) {
                        setOverlayContent(new BookingSummaryOverlayContent(bookingToBeCancelled));
                    }
                }
                addSuccessBar(intl.formatMessage({ id: 'Bookings.BookingCancelledSuccessMessage' }));
                setIsCancellationModalOpen(false);
            })
            .catch(() => {
                addErrorBar();
                if (failureCallback) {
                    failureCallback();
                }
            });
    }

    function updateBooking(bookingToBeUpdated, callback, isBookingOnDifferentDateToRunningOrderDate, voucher) {
        BookingService.updateBooking(diaryContext.deploymentId, diaryContext.restaurantId, bookingToBeUpdated.toJson())
            .then((booking) => {
                let allBookingsCopy = [...allBookings];
                let index = allBookingsCopy.findIndex((b) => b.id === booking.Id);

                if (bookingToBeUpdated.isUnallocated) {
                    let unallocatedBookings = unAllocatedBookings.filter((b) => b.id !== booking.Id);
                    setUnallocatedBookings(unallocatedBookings);
                }

                if (booking.CustomerId) {
                    CustomerService.getCustomer(
                        diaryContext.deploymentId,
                        diaryContext.restaurantId,
                        booking.CustomerId
                    )
                        .then((customer) => {
                            const updatedBooking = new Booking(booking);
                            updatedBooking.orderAtTableStatus = bookingToBeUpdated.orderAtTableStatus;
                            updatedBooking.preorderMenuName = bookingToBeUpdated.preorderMenuName;
                            updatedBooking.customer = new Customer(customer);

                            if (voucher) {
                                VoucherService.redeemVoucherAgainstBooking(
                                    diaryContext.deploymentId,
                                    diaryContext.restaurantId,
                                    booking.Id,
                                    voucher.reference
                                ).then(() => {
                                    updatedBooking.voucherName = voucher.name;
                                    updatedBooking.voucherReference = voucher.reference;
                                    updatedBooking.voucherCost = voucher.cost;
                                });
                            }

                            if (!isBookingOnDifferentDateToRunningOrderDate) {
                                if (index >= 0) {
                                    allBookingsCopy[index] = updatedBooking;
                                } else {
                                    allBookingsCopy.push(updatedBooking);
                                }
                                setAllBookings(allBookingsCopy);
                            } else {
                                // only removing the updated booking from the booking list (list of bookings that are being displayed) as the poll request would bring back
                                // the original booking causing a misinformed notification to be shown.
                                let bookingsCopy = [...bookings];
                                let currentBookings = bookingsCopy.filter((b) => b.id !== updatedBooking.id);
                                setBookingData(currentBookings);
                            }

                            if (overlayOpen && isEditingBooking) {
                                setOverlayContent(new BookingSummaryOverlayContent(updatedBooking));
                            }
                            if (callback) {
                                callback();
                            }
                        })
                        .catch(() => {
                            addErrorBar();
                        });
                } else {
                    const updatedBooking = new Booking(booking);
                    if (voucher) {
                        VoucherService.redeemVoucherAgainstBooking(
                            diaryContext.deploymentId,
                            diaryContext.restaurantId,
                            booking.Id,
                            voucher.reference
                        )
                            .then(() => {
                                updatedBooking.voucherName = voucher.name;
                                updatedBooking.voucherReference = voucher.reference;
                                updatedBooking.voucherCost = voucher.cost;
                            })
                            .catch(() => {
                                addErrorBar(intl.formatMessage({ id: 'Vouchers.ProblemRedeemingVoucherErrorMessage' }));
                            });
                    }
                    if (index >= 0) {
                        allBookingsCopy[index] = updatedBooking;
                    } else {
                        allBookingsCopy.push(updatedBooking);
                    }
                    setAllBookings(allBookingsCopy);
                    if (overlayOpen && isEditingBooking) {
                        setOverlayContent(new BookingSummaryOverlayContent(updatedBooking));
                    }
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsMakingRequest(false);
                addSuccessBar(intl.formatMessage({ id: 'Bookings.BookingUpdatedSuccessMessage' }));
            });
    }

    function toggleReconfirmedStatus(booking, callback) {
        BookingService.reconfirmBooking(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            booking.id,
            !booking.isReConfirmed
        ).then((result) => {
            let bookingsCopy = [...allBookings];
            let index = bookingsCopy.findIndex((b) => b.id === booking.id);
            if (index >= 0) {
                bookingsCopy[index].isReConfirmed = !booking.isReConfirmed;
                setAllBookings(bookingsCopy);
                if (overlayOpen) {
                    setOverlayContent(new BookingSummaryOverlayContent(bookingsCopy[index]));
                }
            } else {
                booking.isReConfirmed = !booking.isReConfirmed;
                if (overlayOpen) {
                    setOverlayContent(new BookingSummaryOverlayContent(booking));
                }
            }

            if (callback) {
                callback();
            }
        });
    }

    function openCancellationModalWithBookingId(booking, callback) {
        setBookingToBeCancelled(booking);
        setIsCancellationModalOpen(true);
        if (callback) {
            callback();
        }
    }

    function openMenuChangeModal(bookingId, menuId, menuName, menuVersionId, callback) {
        setMenuToBeSent(menuId);
        setBookingToBeSentMenu(bookingId);
        setMenuChangeName(menuName);
        setSentMenuVersionId(menuVersionId);
        setIsMenuChangeModalOpen(true);
        if (callback) {
            callback();
        }
    }

    function closeBookingStatusControl() {
        setBookingStatusControlOpen(false);
    }

    function overlayBackButtonAction() {
        if (overlayContent.overlayType === overlayType.newBooking && overlayContent.booking) {
            setOverlayContent(new BookingSummaryOverlayContent(overlayContent.booking));
        } else {
            closeOverlay();
        }
    }

    function showListingsFooter() {
        return (
            !providerProgressFlagsContext.uploadedMenu ||
            !providerProgressFlagsContext.addedAdditionalImage ||
            !providerProgressFlagsContext.addedAdvancedListing
        );
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            {!isMobileView && <SidePanelPage page={getPageBody()} sidePanel={getSidePanel()} />}
            {isMobileView && (
                <Fragment>
                    <div className="scrollable-panel mobile">
                        <Button
                            data-testid="sliding-panel-toggle"
                            className={'sliding-panel-toggle mobile'}
                            onClick={() => openOverlay(new BookingFilterOverlayContent())}
                        >
                            <MaximiseIcon data-testid="maximise-icon" />
                        </Button>
                        {getPageBody()}
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
}

export default BookingsPage;
