import MomentHelper from '../helpers/MomentHelper';
import { v4 as uuidv4 } from 'uuid';

class ProviderImage {
    constructor(image) {
        this.url = image.ImageUrl ? image.ImageUrl : null;
        this.id = image.Id ? image.Id : null;
        this.name = image.Name ? image.Name : '';
        this.isActive = image.IsEnabled !== undefined && image.IsEnabled !== null ? image.IsEnabled : true;
        this.isMainImage = image.IsMainImage ? image.IsMainImage : false;
        this.uploadedDate = image.UploadedDate
            ? MomentHelper.newInstance(image.UploadedDate).format('LL')
            : MomentHelper.newInstance().format('LL');
        this.isSelected = false;
        this.uniqueIdentifier = uuidv4();
        this.loadingPercentage = null;
    }
}

export default ProviderImage;
