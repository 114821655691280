import React from 'react';
import PropTypes from 'prop-types';

function ColouredDot(props) {
    return <div className="coloured-dot" style={{ backgroundColor: props.colour }}></div>;
}

ColouredDot.propTypes = {
    colour: PropTypes.string.isRequired,
};

export default ColouredDot;
