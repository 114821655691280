import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import EditCategorySection from './EditCategorySection';
import MenuSectionCategory from '../../../domainObjects/MenuSectionCategory';

function AddCategorySection(props) {
    const [isOpen, toggleIsOpen] = useReducer(toggle, false);
    const [title, setTitle] = useState('');

    function toggle(value) {
        return !value;
    }

    function addCategory() {
        const newCategory = new MenuSectionCategory();
        newCategory.name = title;
        newCategory.displayOrder = props.newCategoryIndex;
        props.addCategory(newCategory);
        setTitle('');
        toggleIsOpen();
    }

    if (!isOpen) {
        return (
            <div className="collapsible-panel-body transparent">
                <Button
                    block
                    className="btn-dashed"
                    color="default"
                    onClick={() => {
                        toggleIsOpen();
                    }}
                >
                    <FormattedMessage id="Settings.AddCategory" /> +
                </Button>
            </div>
        );
    }

    return (
        <div className="collapsible-panel-body transparent">
            <EditCategorySection
                toggleIsOpen={toggleIsOpen}
                saveCategory={addCategory}
                title={title}
                setTitle={setTitle}
            />
        </div>
    );
}

AddCategorySection.propTypes = {
    addCategory: PropTypes.func.isRequired,
    newCategoryIndex: PropTypes.number.isRequired,
};

export default AddCategorySection;
