import React from 'react';
import PropTypes from 'prop-types';

function PaginatorPage(props) {
    let styleClasses = 'page-item';
    if (props.selectedPage === props.pageNumber) {
        styleClasses = styleClasses + ' active';
    }
    if (props.isEllipsis) {
        styleClasses = styleClasses + ' disabled';
    }

    return (
        <li className={styleClasses} onClick={() => props.onSelectPage(props.pageNumber, props.isEllipsis)}>
            <button className="page-link">{props.pageNumber}</button>
        </li>
    );
}

PaginatorPage.propTypes = {
    onSelectPage: PropTypes.func.isRequired,
    selectedPage: PropTypes.number.isRequired,
    pageNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isEllipsis: PropTypes.bool,
};

export default PaginatorPage;
