import React, { useState, useEffect, Fragment, useReducer, useCallback, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import getSymbolFromCurrency from 'currency-symbol-map';
import InfoBars from '../common/InfoBars';
import ProgressPage from '../common/ProgressPage';
import ProgressBar from './ProgressBar';
import StripeCheckoutFormElementsWrapper from './StripeCheckoutFormElementsWrapper';
import Country from '../../domainObjects/AccountSubmission/Country';
import AccountSubmissionService from '../../services/AccountSubmissionService';
import AccountService from '../../services/AccountService';
import ValidationInput from '../common/ValidationInput';
import ValidationDropdown from '../common/ValidationDropdown';
import PhoneNumberInput from '../common/PhoneNumberInput';
import useValidation from '../../hooks/useValidation';
import userAccountFields from '../../enums/userAccountFields';
import SessionHelper from '../../helpers/SessionHelper';
import { ReactComponent as ShowPasswordIcon } from '../../content/icons/Icon-Preview.svg';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';
import { ReactComponent as SuccessIcon } from '../../content/icons/Icon-Success.svg';
import infoBarType from '../../enums/infoBarType';
import { Label, FormGroup, Form } from 'reactstrap';
import useScreenSize from '../../hooks/useScreenSize';
import useTrackPage from '../../hooks/useTrackPage';
import useQueryParam from '../../hooks/useQueryParam';
import SessionContext from '../../contexts/SessionContext';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import CheckboxGroup from '../common/CheckboxGroup';
import termsAndPrivacyCheckboxValue from '../../enums/termsAndPrivacyCheckboxValue';
import SettingsHelper from '../../helpers/SettingsHelper';
import { Link } from 'react-router-dom';
import ChargeBeeService from './../../services/ChargeBeeService';
import billingCycle from '../../enums/billingCycle';

function CreateAccountPage(props) {
    const intl = useIntl();
    const [infoBars, setInfoBars] = useState([]);
    const [countries, setCountries] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('61');
    const [createdAccount, setCreatedAccount] = useState(props.accountCreated);
    const [showPassword, toggleShowPassword] = useReducer(toggle, false);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [selectedCheckBoxValues, setSelectedCheckBoxValues] = useState([]);
    const [campaignCode, setCampaignCode] = useState(null);
    const [analyticsReady, setAnalyticsReady] = useState(false);
    const [stripeInfo, setStripeInfo] = useState(null);
    const [qsrSubscriptionDetails, setQsrSubscriptionDetails] = useState(null);

    const sessionContext = useContext(SessionContext);
    const { isMobileView } = useScreenSize();
    const anonId = useQueryParam('aId');
    const utmSource = useQueryParam('utm_source');
    const utmMedium = useQueryParam('utm_medium');
    const utmCampaign = useQueryParam('utm_campaign');

    let _ID = 0;

    const formValidation = useValidation();

    const addErrorBar = useCallback(
        (message) => {
            setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
        },
        [_ID]
    );

    window.analytics.ready(function () {
        setAnalyticsReady(true);
    });

    useEffect(() => {
        setCampaignCode(utmCampaign ? utmCampaign : '');
    }, [utmCampaign]);

    useEffect(() => {
        function getAnalyticsUtmProperties() {
            let properties = {};

            if (utmSource) {
                const source = { source: utmSource };
                properties = { ...properties, ...source };
            }
            if (utmMedium) {
                const medium = { medium: utmMedium };
                properties = { ...properties, ...medium };
            }
            if (campaignCode) {
                const campaign = { campaign: campaignCode };
                properties = { ...properties, ...campaign };
            }
            return properties;
        }
        const storedAnonId = SettingsHelper.getSegmentAnonymousId();

        if (storedAnonId != null && storedAnonId.length > 0) {
            AnalyticsHelper.setAnonymousId(SettingsHelper.getSegmentAnonymousId());
        } else if (anonId != null && anonId.length > 0) {
            AnalyticsHelper.setAnonymousId(anonId);
            SettingsHelper.setSegmentAnonymousId(anonId);
        }
        if (analyticsReady) {
            SettingsHelper.setSegmentAnonymousId(window.analytics.user().anonymousId());
            AnalyticsHelper.identifyUserWithProperties(getAnalyticsUtmProperties());
        }
    }, [anonId, analyticsReady, utmSource, utmMedium, campaignCode]);

    useTrackPage('Create Account');

    function toggle(value) {
        return !value;
    }

    function validatePassword(password) {
        if (password.length < 6 || !hasUpperCase(password)) {
            return intl.formatMessage({ id: 'AccountSubmission.PasswordValidationMessage' });
        }
        if (password.length > 128) {
            return intl.formatMessage({ id: 'AccountSubmission.PasswordLengthLimitMessage' });
        }
        return true;
    }

    function hasUpperCase(string) {
        return string.toLowerCase() !== string;
    }

    useEffect(() => {
        let tokenInfo = SessionHelper.getTokenInfo();
        if (tokenInfo && tokenInfo.token && campaignCode !== null) {
            AccountSubmissionService.getSubscriptionDetails(tokenInfo.token, campaignCode).then((result) => {
                setSubscriptionDetails(result);
            });
        }
        AccountSubmissionService.getCountries().then((result) => {
            setCountries(result.map((c) => new Country(c)));

            //The alpha2code might need to change once DCR and QSRs
            //become available outside of the UK
            ChargeBeeService.getPlanInformation({
                chargeBeePlanId: SettingsHelper.getQSRSubscriptionProductId(),
                periodUnit: billingCycle.month,
                alpha2Code: 'GB',
            }).then((response) => {
                setQsrSubscriptionDetails(response);
            });
        });
    }, [campaignCode]);

    function getCountryOptions() {
        return countries.map((c) => ({ value: c.id, text: c.name }));
    }

    // populate phone code dropdown
    function getCountryCodeOptions() {
        return countries.map((c) => new Option(`${c.name}: +${c.phoneCode.toString()}`, c.id));
    }

    // update countryCode state by relating item id to country id
    function onCountryCodeChange(value) {
        value = countries.find((c) => c.id === parseInt(value)).phoneCode;
        setCountryCode(value);
    }

    // relate chosen phone code to corresponding country id to update selected item
    function getCountryCodeId() {
        let id = countryCode && countries ? countries.find((c) => c.phoneCode === parseInt(countryCode)).id : 0;
        return id.toString();
    }

    function trackAccountCreation(account) {
        const userProperties = { email: account.email };
        AnalyticsHelper.identifyUserWithProperties(userProperties);

        let country = countries.find((x) => x.id === account.countryId);
        const accountCreatedProperties = {
            first_name: account.firstName,
            last_name: account.lastName,
            email_address: account.emailAddress,
            country: country.name,
            phone: phoneNumber,
            product_type: 'RDL',
        };

        AnalyticsHelper.trackClickWithProperties('Account Created', accountCreatedProperties);
        AnalyticsHelper.trackPage('Account Created');
    }

    function submitCreateAccount() {
        AccountSubmissionService.createUserAccount(props.account).then((result) => {
            if (result && result.token && result.tokenExpiryUtc) {
                props.auth.login({
                    token: result.token,
                    tokenExpiryUtc: result.tokenExpiryUtc,
                });
                sessionContext.updateUserName(props.account.emailAddress);
                setCreatedAccount(true);
                trackAccountCreation(props.account);
            }
        });
    }

    function onUserAccountChange(field, value) {
        const accountCopy = { ...props.account };
        accountCopy[field] = value;
        props.setAccount(accountCopy);
    }

    function onCountryChange(value) {
        const accountCopy = { ...props.account };
        const country = countries.find((c) => c.id === parseInt(value));
        accountCopy[userAccountFields.countryId] = value;
        accountCopy[userAccountFields.countryAlpha2Code] = country.alpha2Code;
        props.setAccount(accountCopy);
    }

    function isUsernameUnique(value) {
        return AccountService.usernameIsValid(value).then((result) => {
            if (!result) {
                return intl.formatMessage({ id: 'AccountSubmission.EmailUniqueValidationMessage' });
            } else {
                return true;
            }
        });
    }

    function getStripeCheckoutSetupInformation() {
        AccountSubmissionService.getStripeCheckoutSetupInformation(
            subscriptionDetails ? subscriptionDetails.alpha2Code : null
        )
            .then((response) => {
                const productInformation = response.productInformation;
                const checkoutProperties = {
                    value: productInformation.productPriceTaxInclusive,
                    currency: productInformation.currency,
                    products: productInformation.productName,
                };

                AnalyticsHelper.trackClickWithProperties('Checkout Started', checkoutProperties);

                setStripeInfo(response);
            })
            .catch(() => {
                addErrorBar();
            });
    }

    function getCheckoutInformation() {
        if (props.isQuickServiceRestaurant) {
            //this is where we go to the Chargebee hosted payment page
            const paymentRequest = {
                UserAccount: props.account,
                PackageInformation: qsrSubscriptionDetails,
                AddOns: [],
            };
            ChargeBeeService.checkout(paymentRequest).then((hostedPage) => {
                const chargebeeUrl = `${hostedPage.url}?id=${hostedPage.id}&state=${hostedPage.state}`;
                window.open(chargebeeUrl, '_self');
            });
        } else {
            getStripeCheckoutSetupInformation();
        }
    }

    function getTrialPeriodMessage() {
        if (subscriptionDetails) {
            return subscriptionDetails.trialPeriod === 1
                ? intl.formatMessage(
                      { id: 'AccountSubmission.PaymentInfoLine1SingleDay' },
                      { trialPeriod: subscriptionDetails.trialPeriod }
                  )
                : intl.formatMessage(
                      { id: 'AccountSubmission.PaymentInfoLine1' },
                      { trialPeriod: subscriptionDetails.trialPeriod }
                  );
        }
    }

    function getPriceAfterTrialMessage() {
        if (subscriptionDetails) {
            return subscriptionDetails.taxDisplayName
                ? intl.formatMessage(
                      { id: 'AccountSubmission.PriceAfterTrialWithTaxName' },
                      {
                          currency: getSymbolFromCurrency(subscriptionDetails.currency),
                          price: intl.formatNumber(subscriptionDetails.price, { minimumFractionDigits: 2 }),
                          taxName: subscriptionDetails.taxDisplayName,
                      }
                  )
                : intl.formatMessage(
                      { id: 'AccountSubmission.PriceAfterTrial' },
                      {
                          currency: getSymbolFromCurrency(subscriptionDetails.currency),
                          price: intl.formatNumber(subscriptionDetails.price, { minimumFractionDigits: 2 }),
                      }
                  );
        }
    }

    function getTermsAndPrivacyCheckboxOptions() {
        return [
            new Option(
                intl.formatMessage({ id: 'AccountSubmission.TermsAndConditionsCheckboxLabel' }),
                termsAndPrivacyCheckboxValue.termsAndConditions
            ),
            new Option(
                intl.formatMessage({ id: 'AccountSubmission.PrivacyPolicyCheckboxLabel' }),
                termsAndPrivacyCheckboxValue.privacyPolicy
            ),
        ];
    }

    function termsAndPrivacyCheckboxOnChange(id) {
        let selectedItems = [...selectedCheckBoxValues];

        if (selectedItems.includes(id.toString())) {
            selectedItems = selectedItems.filter((value) => value !== id.toString());
        } else {
            selectedItems.push(id.toString());
        }

        setSelectedCheckBoxValues(selectedItems);
    }

    function areCheckBoxEnabled() {
        return selectedCheckBoxValues.length === 2;
    }

    function getTermsAndPrivacyLink(id) {
        if (id === 1) {
            return (
                <div className={isMobileView ? 'terms-and-privacy-checkbox mobile' : 'terms-and-privacy-checkbox'}>
                    <a href="https://sales.resdiary.com/terms-conditions/" target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="AccountSubmission.TermsAndConditions" />
                    </a>
                </div>
            );
        }

        return (
            <div className={isMobileView ? 'terms-and-privacy-checkbox mobile' : 'terms-and-privacy-checkbox'}>
                <a href="https://sales.resdiary.com/privacy-statement/" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="AccountSubmission.PrivacyPolicy" />
                </a>
            </div>
        );
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <ProgressPage
                pageTitle={intl.formatMessage({
                    id: props.isQuickServiceRestaurant
                        ? 'AccountSubmission.GetStartedWithDCR'
                        : 'Common.GetStartedMessage',
                })}
                progressBar={
                    <ProgressBar
                        createAccountComplete={createdAccount}
                        subscriptionComplete={false}
                        accountSetupComplete={false}
                    />
                }
            >
                <div className="container">
                    <div className="mt-3 row">
                        <div className="col-md-12">
                            {!createdAccount && !stripeInfo && (
                                <Form
                                    className="create-account-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        formValidation.submit(submitCreateAccount);
                                    }}
                                >
                                    <FormGroup className="account-details-form-group">
                                        <Label for="firstName" id="firstName">
                                            <FormattedMessage id="AccountSubmission.FirstName" />
                                        </Label>
                                        <ValidationInput
                                            type="text"
                                            name="firstName"
                                            value={props.account.firstName}
                                            onChange={(e) => onUserAccountChange(userAccountFields.firstName, e)}
                                            innerRef={formValidation.register({
                                                required: intl.formatMessage({
                                                    id: 'AccountSubmission.FirstNameRequiredMessage',
                                                }),
                                            })}
                                            errors={formValidation.errors}
                                            ariaLabelledBy={'firstName'}
                                            ariaRequired
                                        />
                                    </FormGroup>
                                    <FormGroup className="account-details-form-group">
                                        <Label for="lastName" id="lastName">
                                            <FormattedMessage id="AccountSubmission.LastName" />
                                        </Label>
                                        <ValidationInput
                                            type="text"
                                            name="lastName"
                                            value={props.account.lastName}
                                            onChange={(e) => onUserAccountChange(userAccountFields.lastName, e)}
                                            innerRef={formValidation.register({
                                                required: intl.formatMessage({
                                                    id: 'AccountSubmission.LastNameRequiredMessage',
                                                }),
                                            })}
                                            errors={formValidation.errors}
                                            ariaLabelledBy={'lastName'}
                                            ariaRequired
                                        />
                                    </FormGroup>
                                    <FormGroup className="account-details-form-group">
                                        <ValidationDropdown
                                            formValidation={formValidation}
                                            name="countryId"
                                            validationRules={{
                                                required: intl.formatMessage({
                                                    id: 'AccountSubmission.CountryRequiredMessage',
                                                }),
                                            }}
                                            hideRemoveItemIcon
                                            onChange={(e) => onCountryChange(e)}
                                            defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                                            options={getCountryOptions()}
                                            selectedValue={props.account.countryId}
                                            title={intl.formatMessage({ id: 'AccountSubmission.Country' })}
                                            isSearchable
                                            ariaRequired
                                        />
                                    </FormGroup>
                                    {props.account.countryId === 14 && (
                                        <FormGroup className="account-details-form-group">
                                            <PhoneNumberInput
                                                label={intl.formatMessage({ id: 'AccountSubmission.PhoneNumber' })}
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                inputValue={phoneNumber}
                                                optionGroups={getCountryCodeOptions()}
                                                onChange={(e) => setPhoneNumber(`+${countryCode}${e}`)}
                                                selectedValue={getCountryCodeId(countryCode)}
                                                onCountryCodeChange={(e) => onCountryCodeChange(e)}
                                                errors={formValidation.errors}
                                                innerRef={formValidation.register({
                                                    required: intl.formatMessage({
                                                        id: 'AccountSubmission.PhoneNumberRequiredMessage',
                                                    }),
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: intl.formatMessage({
                                                            id: 'AccountSubmission.PhoneNumberValidationMessage',
                                                        }),
                                                    },
                                                })}
                                                includeValidation
                                            />
                                        </FormGroup>
                                    )}
                                    <FormGroup className="account-details-form-group">
                                        <Label for="emailAddress" id="emailAddress">
                                            <FormattedMessage id="AccountSubmission.EmailAddress" />
                                        </Label>
                                        <ValidationInput
                                            type="text"
                                            name="emailAddress"
                                            value={props.account.emailAddress}
                                            onChange={(e) => onUserAccountChange(userAccountFields.emailAddress, e)}
                                            innerRef={formValidation.register({
                                                required: intl.formatMessage({
                                                    id: 'AccountSubmission.EmailValidationMessage',
                                                }),
                                                validate: (value) => isUsernameUnique(value),
                                                pattern: {
                                                    value: /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/gi, // eslint-disable-line no-control-regex
                                                    message: intl.formatMessage({
                                                        id: 'AccountSubmission.ProvideAValidEmailAddress',
                                                    }),
                                                },
                                            })}
                                            errors={formValidation.errors}
                                            ariaLabelledBy={'emailAddress'}
                                            ariaRequired
                                        />
                                    </FormGroup>
                                    <FormGroup className="account-details-form-group password">
                                        <Label for="password" id="password">
                                            <FormattedMessage id="AccountSubmission.Password" />
                                        </Label>
                                        <ValidationInput
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            value={props.account.password}
                                            onChange={(e) => onUserAccountChange(userAccountFields.password, e)}
                                            innerRef={formValidation.register({
                                                validate: (value) => validatePassword(value),
                                            })}
                                            errors={formValidation.errors}
                                            ariaLabelledBy={'password'}
                                            ariaRequired
                                        />
                                        {!formValidation.errors['password'] && (
                                            <div className="password-restrictions-info">
                                                <InfoIcon />
                                                <div className="ml-1">
                                                    <FormattedMessage id="AccountSubmission.PasswordValidationMessage" />
                                                </div>
                                            </div>
                                        )}
                                        <div className="toggle-password-visible" onClick={() => toggleShowPassword()}>
                                            <ShowPasswordIcon />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="account-details-form-group">
                                        <CheckboxGroup
                                            id="terms-and-privacy-checkbox-group"
                                            options={getTermsAndPrivacyCheckboxOptions()}
                                            onChange={(option) => termsAndPrivacyCheckboxOnChange(parseInt(option.id))}
                                            checkedValues={selectedCheckBoxValues}
                                            getLink={getTermsAndPrivacyLink}
                                            ariaRequired
                                        />
                                    </FormGroup>
                                    <FormGroup className="account-details-form-group">
                                        <input
                                            disabled={formValidation.submitDisabled || !areCheckBoxEnabled()}
                                            type="submit"
                                            value={intl.formatMessage({ id: 'AccountSubmission.CreateAnAccount' })}
                                            className="create-account-button btn btn-primary"
                                        />
                                    </FormGroup>

                                    <FormGroup className="account-details-form-group back-to-login-link">
                                        <FormattedMessage id="Login.AlreadyHaveAccount" />{' '}
                                        <Link to="/">
                                            <FormattedMessage id="Login.LogIn" />
                                        </Link>
                                    </FormGroup>
                                </Form>
                            )}
                            {createdAccount && subscriptionDetails && !stripeInfo && (
                                <div className="account-create-success-page">
                                    <div className="progress-icon-container success-icon">
                                        <SuccessIcon />
                                    </div>
                                    <div className="heading-text">
                                        <FormattedMessage id="AccountSubmission.AccountHasBeenCreated" />
                                    </div>
                                    <div className="payment-information-text">
                                        <div>
                                            {props.isQuickServiceRestaurant ? (
                                                <FormattedMessage id="AccountSubmission.DCRInfoLine1" />
                                            ) : (
                                                getTrialPeriodMessage()
                                            )}
                                        </div>
                                        <div>
                                            {props.isQuickServiceRestaurant ? (
                                                <FormattedMessage id="AccountSubmission.DCRInfoLine2" />
                                            ) : (
                                                <FormattedMessage id="AccountSubmission.PaymentInfoLine2" />
                                            )}
                                        </div>
                                    </div>
                                    <input
                                        type="button"
                                        value={intl.formatMessage({ id: 'Common.Continue' })}
                                        className={
                                            isMobileView
                                                ? 'continue-button mob btn btn-primary'
                                                : 'continue-button btn btn-primary'
                                        }
                                        onClick={() => getCheckoutInformation()}
                                    />
                                    <div>
                                        {props.isQuickServiceRestaurant ? (
                                            <FormattedMessage id="AccountSubmission.DCRInfoLine3" />
                                        ) : (
                                            getPriceAfterTrialMessage()
                                        )}
                                        <span className="bold-text pl-1">
                                            <FormattedMessage id="AccountSubmission.CancelAnytime" />
                                        </span>
                                    </div>
                                </div>
                            )}

                            {stripeInfo && (
                                <StripeCheckoutFormElementsWrapper
                                    stripePublishableKey={stripeInfo.stripePublishableKey}
                                    clientSecret={stripeInfo.clientSecret}
                                    productInformation={stripeInfo.productInformation}
                                    campaignCode={campaignCode}
                                    alpha2Code={subscriptionDetails.alpha2Code}
                                    countries={countries}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ProgressPage>
        </Fragment>
    );
}

export default CreateAccountPage;
