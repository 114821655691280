import React, { Fragment, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import ListBuilder from '../common/ListBuilder';
import PropTypes from 'prop-types';
import VouchersEntryWrapper from './VouchersEntryWrapper';
import Voucher from '../../domainObjects/Voucher';
import VoucherEditPanel from './VoucherEditPanel';
import SessionContext from '../../contexts/SessionContext';
import DiaryContext from '../../contexts/DiaryContext';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import FooterPreviewModal from '../common/FooterPreviewModal';
import VoucherPreviewCard from './VoucherPreviewCard';

function VouchersListBuilder(props) {
    const intl = useIntl();
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const [isAddingNewVoucher, setIsAddingNewVoucher] = useState(false);
    const [displayMobilePreview, setDisplayMobilePreview] = useState(false);
    const [voucherPreview, setVoucherPreview] = useState(null);

    function getAnalyticsProperties() {
        return {
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
        };
    }

    const toggleAddNewVoucher = () => {
        if (!isAddingNewVoucher) {
            AnalyticsHelper.trackClickWithProperties('Web VouchersCreation CreateNew', getAnalyticsProperties());
        }
        setIsAddingNewVoucher(!isAddingNewVoucher);
    };

    function saveVoucher(voucher, callback) {
        setVoucherPreview(voucher);
        if (props.isMobileView) {
            setDisplayMobilePreview(true);
        }
        props.saveVoucher(voucher, callback);
    }

    function getPageContent() {
        return (
            <Fragment>
                {props.vouchers.map((voucher) => {
                    return (
                        <VouchersEntryWrapper
                            key={voucher.uniqueIdentifier}
                            voucher={voucher}
                            saveVoucher={saveVoucher}
                            minValidityPeriod={props.minValidityPeriod}
                            toggleAddNewVoucher={toggleAddNewVoucher}
                            displayGenerateModalWithDefaultVoucher={props.displayGenerateModalWithDefaultVoucher}
                        />
                    );
                })}

                {isAddingNewVoucher && (
                    <VoucherEditPanel
                        voucher={new Voucher()}
                        toggleAddNewVoucher={toggleAddNewVoucher}
                        saveVoucher={saveVoucher}
                        minValidityPeriod={props.minValidityPeriod}
                    />
                )}
            </Fragment>
        );
    }

    return (
        <Fragment>
            <ListBuilder
                isAddingNewItem={isAddingNewVoucher}
                toggleAddNewItem={toggleAddNewVoucher}
                addNewItemButtonText={intl.formatMessage({ id: 'Vouchers.CreateVoucher' })}
                content={getPageContent()}
            />

            {displayMobilePreview && (
                <FooterPreviewModal
                    title={intl.formatMessage({ id: 'Vouchers.VoucherPreview' })}
                    body={
                        <div>
                            <VoucherPreviewCard key={voucherPreview.uniqueIdentifier} voucher={voucherPreview} />
                        </div>
                    }
                    isModalOpen={displayMobilePreview}
                    setIsModalOpen={setDisplayMobilePreview}
                />
            )}
        </Fragment>
    );
}

VouchersListBuilder.propTypes = {
    vouchers: PropTypes.array.isRequired,
    saveVoucher: PropTypes.func.isRequired,
    displayGenerateModalWithDefaultVoucher: PropTypes.func.isRequired,
    minValidityPeriod: PropTypes.number,
};

export default VouchersListBuilder;
