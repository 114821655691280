const tagColour = {
    Navy: 'Navy',
    Green: 'Green',
    Red: 'Red',
    Orange: 'Orange',
    None: 'None',
    Grey: 'Grey',
    Disabled: 'Disabled',
};

export default tagColour;
