import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';

export default function NotFound() {
    return (
        <div className="not-found-background">
            <Container>
                <Row>
                    <Col>
                        <div className="not-found-content">
                            <h1 className="status-code">404</h1>
                            <FormattedMessage id="Common.404Line1" tagName="h1" />
                            <FormattedMessage id="Common.404Line2" tagName="h3" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
