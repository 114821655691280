import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ValidationMessage from '../common/ValidationMessage';

function ValidationWrapper(props) {
    function getErrorMessage() {
        let errorMessage = '';
        for (let errName of props.errorNames) {
            const err = props.errors[errName];
            if (err) {
                errorMessage = err.message;
                break;
            }
        }
        return errorMessage;
    }
    const errMessage = getErrorMessage();

    return (
        <Fragment>
            {props.children}
            <div className="validation-input-feedback">{errMessage && <ValidationMessage message={errMessage} />}</div>
        </Fragment>
    );
}

ValidationWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    errorNames: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
};

export default ValidationWrapper;
