import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';
import ProgressFlagHelper from '../helpers/ProgressFlagHelper';

class ProgressFlagService {
    static getProviderProgressFlags(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/GetRDLProviderProgressFlags`
        ).then((response) => response.data);
    }

    static updateProviderProgressFlags(deploymentId, restaurantId, providerProgressFlags) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/UpdateRDLProviderProgressFlags`,
            ProgressFlagHelper.toJson(providerProgressFlags)
        ).then((response) => response.data);
    }
}

export default ProgressFlagService;
