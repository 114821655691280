class Address {
    constructor(address) {
        this.addressLine1 = address && address.AddressLine1 ? address.AddressLine1 : '';
        this.city = address && address.City ? address.City : '';
        this.postcode = address && address.Postcode ? address.Postcode : '';
        this.country = address && address.Country ? address.Country : '';
    }
}

export default Address;
