const voucherStatus = {
    valid: 'Valid',
    expired: 'Expired',
    redeemed: 'Redeemed',
    pendingRedemption: 'PendingRedemption',
    invalidVoucher: 'InvalidVoucher',
    expiresBeforeBooking: 'ExpiresBeforeBooking',
};

export default voucherStatus;
