import React from 'react';
import { Button } from 'reactstrap';
//import SearchInput from '../../../common/SearchInput';
import PropTypes from 'prop-types';
import { ReactComponent as MaximiseIcon } from '../../../../content/icons/maximise.svg';
import { FormattedMessage } from 'react-intl';
import useScreenSize from '../../../../hooks/useScreenSize';

function LoyaltyReportTableHeader(props) {
    //const intl = useIntl();
    const { isMobileView } = useScreenSize();

    /* const isButtonDisabled = () => {
        return props.isLoading || props.tableData.length === 0 || (props.searchTerm !== '' && props.searchResults.length === 0);
    }; */

    return (
        <div className="loyalty-report-table-header">
            {/* <div className="loyalty-report-search-bar">
                <SearchInput
                    disabled={props.tableData.length === 0}
                    labelText={intl.formatMessage({ id: 'Common.Search' })}
                    onSearch={(searchTerm) => {
                        props.setSearchTerm(searchTerm);
                    }}
                    inputPlaceholderText={intl.formatMessage({ id: 'Settings.EnterBookingRef' })}
                /> 
            </div> */}
            {/* <Button color="primary" outline onClick={() => {}} disabled={isButtonDisabled()}>
                <FormattedMessage id="Settings.ExportAsCSV" />
            </Button> */}
            <Button
                className={'filter-button' + (isMobileView ? ' mobile' : '')}
                color="primary"
                outline
                onClick={() => {
                    props.setIsFilterOpen(true);
                }}
                disabled={props.isLoading}
            >
                <MaximiseIcon />
                <FormattedMessage id="Common.Filter" />
            </Button>
        </div>
    );
}

LoyaltyReportTableHeader.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    tableData: PropTypes.array.isRequired,
    searchResults: PropTypes.array.isRequired,
    setIsFilterOpen: PropTypes.func.isRequired,
};

export default LoyaltyReportTableHeader;
