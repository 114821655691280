import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../../common/Dropdown';
import DatePickerWrapper from '../../../common/DatePickerWrapper';
import Moment from 'moment';
import { ReactComponent as MaximiseIcon } from '../../../../content/icons/maximise.svg';
import { Button, Label } from 'reactstrap';
import loyaltyReportDateRange from '../../../../enums/loyaltyReportDateRange';
//import loyaltyReportRewardStatus from '../../../../enums/loyaltyReportRewardStatus';
import loyaltyReportPageLength from '../../../../enums/loyaltyReportPageLength';
import { FormattedMessage, useIntl } from 'react-intl';
import useScreenSize from '../../../../hooks/useScreenSize';

function LoyaltyReportFilterSidebar(props) {
    const intl = useIntl();
    const { isMobileView } = useScreenSize();

    const getDateRangeOptions = () => {
        return [
            { value: loyaltyReportDateRange.allTime, text: intl.formatMessage({ id: 'Settings.AllTime' }) },
            { value: loyaltyReportDateRange.thisWeek, text: intl.formatMessage({ id: 'Settings.ThisWeek' }) },
            { value: loyaltyReportDateRange.thisMonth, text: intl.formatMessage({ id: 'Settings.ThisMonth' }) },
            { value: loyaltyReportDateRange.customRange, text: intl.formatMessage({ id: 'Settings.CustomRange' }) },
        ];
    };

    /*
    const getRewardStatusOptions = () => {
        return [
            { value: loyaltyReportRewardStatus.all, text: intl.formatMessage({ id: 'Settings.All' }) },
            { value: loyaltyReportRewardStatus.earned, text: intl.formatMessage({ id: 'Settings.Earned' }) },
            { value: loyaltyReportRewardStatus.redeemed, text: intl.formatMessage({ id: 'Settings.Redeemed' }) },
        ];
    };
    */

    const getPageLengthOptions = () => {
        return [
            {
                value: loyaltyReportPageLength.twenty,
                text: intl.formatMessage({ id: 'Settings.ResultsPerPage' }, { results: '20' }),
            },
            {
                value: loyaltyReportPageLength.fifty,
                text: intl.formatMessage({ id: 'Settings.ResultsPerPage' }, { results: '50' }),
            },
            {
                value: loyaltyReportPageLength.twoHundred,
                text: intl.formatMessage({ id: 'Settings.ResultsPerPage' }, { results: '200' }),
            },
            {
                value: loyaltyReportPageLength.fiveHundred,
                text: intl.formatMessage({ id: 'Settings.ResultsPerPage' }, { results: '500' }),
            },
            {
                value: loyaltyReportPageLength.oneThousand,
                text: intl.formatMessage({ id: 'Settings.ResultsPerPage' }, { results: '1000' }),
            },
            {
                value: loyaltyReportPageLength.twoThousand,
                text: intl.formatMessage({ id: 'Settings.ResultsPerPage' }, { results: '2000' }),
            },
        ];
    };

    return (
        <div
            className={`loyalty-report-filter-sidebar ${props.isFilterOpen ? 'active' : ''} ${
                isMobileView ? ' mobile' : ''
            }`}
        >
            <div className="loyalty-report-filter-sidebar-container sidebar-header">
                <Button className="filter-button footnote bold upper-case" onClick={() => props.setIsFilterOpen(false)}>
                    <MaximiseIcon />
                    <FormattedMessage id="Common.Filter" />
                </Button>
            </div>
            <div className="loyalty-report-filter-sidebar-container date-range-selector">
                <Dropdown
                    title={intl.formatMessage({ id: 'Settings.ByDateRange' })}
                    options={getDateRangeOptions()}
                    onValueChange={props.setDateRangeOption}
                    selectedValue={props.dateRangeOption}
                />
                {props.dateRangeOption === loyaltyReportDateRange.customRange && (
                    <div className="custom-date-range-container">
                        <Label className="date-range-from-label">
                            <FormattedMessage id="Common.From" />
                        </Label>
                        <DatePickerWrapper
                            className="date-range-from-input"
                            date={props.dateRangeFrom}
                            setDate={(date) => {
                                props.setDateRangeFrom(date.startOf('day'));
                            }}
                            excludeArrows
                            hideDayFromDate
                            showCalendarIcon
                        />
                        <Label className="date-range-to-label">
                            <FormattedMessage id="Common.To" />
                        </Label>
                        <DatePickerWrapper
                            className="date-range-to-input"
                            date={props.dateRangeTo}
                            setDate={(date) => {
                                props.setDateRangeTo(date.endOf('day'));
                            }}
                            excludeArrows
                            hideDayFromDate
                            showCalendarIcon
                        />
                    </div>
                )}
            </div>
            {/* <div className="loyalty-report-filter-sidebar-container reward-status-selector">
                <Label>
                    <FormattedMessage id="Settings.ByRewardStatus" />
                </Label>
                <ButtonGroup>
                    {getRewardStatusOptions().map((rewardStatusOption, i) => {
                        return (
                            <Button
                                key={i}
                                className="small-body medium"
                                onClick={() => {
                                    props.setRewardStatusOption(rewardStatusOption.value);
                                }}
                                active={props.rewardStatusOption === rewardStatusOption.value}
                            >
                                {rewardStatusOption.text}
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </div> */}
            <div className="loyalty-report-filter-sidebar-container page-length-selector">
                <Dropdown
                    title={intl.formatMessage({ id: 'Common.Show' })}
                    options={getPageLengthOptions()}
                    onValueChange={props.setPageLengthOption}
                    selectedValue={props.pageLengthOption}
                />
            </div>
            <div className="loyalty-report-filter-sidebar-container page-controls">
                <Button className="reset-button">
                    <FormattedMessage id="Common.Reset" />
                </Button>
                <Button color="primary" onClick={props.applyFilters}>
                    <FormattedMessage id="Common.Apply" />
                </Button>
            </div>
        </div>
    );
}

LoyaltyReportFilterSidebar.propTypes = {
    isFilterOpen: PropTypes.bool.isRequired,
    setIsFilterOpen: PropTypes.func.isRequired,
    dateRangeOption: PropTypes.number.isRequired,
    setDateRangeOption: PropTypes.func.isRequired,
    dateRangeFrom: PropTypes.instanceOf(Moment),
    setDateRangeFrom: PropTypes.func.isRequired,
    dateRangeTo: PropTypes.instanceOf(Moment),
    setDateRangeTo: PropTypes.func.isRequired,
    rewardStatusOption: PropTypes.number.isRequired,
    setRewardStatusOption: PropTypes.func.isRequired,
    pageLengthOption: PropTypes.number.isRequired,
    setPageLengthOption: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

export default LoyaltyReportFilterSidebar;
