import React, { Fragment } from 'react';
import { Table, Badge } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ReactComponent as EmptyResult } from '../../../../content/icons/empty-result.svg';
import useScreenSize from '../../../../hooks/useScreenSize';
import { FormattedMessage, useIntl } from 'react-intl';
import Paginator from '../../../common/Paginator';
import paginatorMode from '../../../../enums/paginatorMode';
import loyaltyTransactionType from '../../../../enums/loyaltyTransactionType';

function LoyaltyReportTableBody(props) {
    const intl = useIntl();
    const { isMobileView } = useScreenSize();

    const getBadge = (badgeText, badgeClassName) => {
        return <Badge className={'small-body medium badge ' + badgeClassName}>{badgeText}</Badge>;
    };

    const getCustomerStatusBadge = (customerStatus) => {
        return getBadge(
            customerStatus === true
                ? intl.formatMessage({ id: 'Common.Active' })
                : intl.formatMessage({ id: 'Common.Disabled' }),
            customerStatus === true ? 'active' : 'disabled'
        );
    };

    const getTransactionType = (rewardStatus) => {
        return getBadge(
            rewardStatus === loyaltyTransactionType.redeemed ? (
                <FormattedMessage id="Settings.Redeemed" />
            ) : (
                <FormattedMessage id="Settings.Earned" />
            ),
            rewardStatus === loyaltyTransactionType.redeemed ? 'redeemed' : 'earned'
        );
    };

    const getEmptyResultText = () => {
        if (props.searchTerm !== '') {
            return (
                <Fragment>
                    <p className="small-body bold">
                        <FormattedMessage id="Settings.LoyaltyReportNoResultsFoundTitle" />
                    </p>
                    <p className="small-body regular">
                        <FormattedMessage id="Settings.LoyaltyReportNoResultsFoundBody" />
                    </p>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <p className="small-body bold">
                        <FormattedMessage id="Settings.LoyaltyReportNoReportAvailableTitle" />
                    </p>
                    <p className="small-body regular">
                        <FormattedMessage id="Settings.LoyaltyReportNoReportAvailableBody" />
                    </p>
                </Fragment>
            );
        }
    };

    const renderTableBody = () => {
        if (isMobileView) {
            if (props.tableData.length === 0 || (props.searchResults.length === 0 && props.searchTerm !== '')) {
                return (
                    <div className="loyalty-report-table-body-mobile">
                        <div className="no-data-cell">
                            <EmptyResult />
                            {getEmptyResultText()}
                        </div>
                    </div>
                );
            } else {
                return (
                    <Fragment>
                        <div className="loyalty-report-table-body-mobile">
                            {props.tableData.map((td, i) => {
                                return (
                                    <div key={i} className="loyalty-report-mobile-cell-container">
                                        <div className="cell booking-date">
                                            <p className="small-body bold">
                                                <FormattedMessage id="Settings.BookingDate" />
                                            </p>
                                            <p className="small-body">{moment(td.bookingDate).format('DD MMM YYYY')}</p>
                                        </div>
                                        <div className="cell customer-details">
                                            <p className="small-body bold name">{td.customerName}</p>
                                            <p className="small-body email">{td.emailAddress}</p>
                                            <p className="small-body phone">{td.phone}</p>
                                            {getCustomerStatusBadge(td.customerStatus)}
                                        </div>
                                        <div className="cell total-spend">
                                            <p className="small-body bold">
                                                <FormattedMessage id="Settings.Amount" />
                                            </p>
                                            <p className="small-body">£{td.totalSpend.toFixed(2)}</p>
                                            {getTransactionType(td.transactionType)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {props.pageCount > 0 && (
                            <div className="paginator-container-mobile">
                                <Paginator
                                    onSelectPage={props.setCurrentPage}
                                    selectedPage={props.currentPage}
                                    numberOfPages={props.pageCount}
                                    pageSize={props.pageSize}
                                    totalItemCount={props.pageCount * props.pageSize}
                                    paginatorMode={paginatorMode.previousNext}
                                />
                            </div>
                        )}
                    </Fragment>
                );
            }
        } else {
            return (
                <Fragment>
                    <Table bordered className="loyalty-report-table">
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="Settings.BookingDate" />
                                </th>
                                <th>
                                    <FormattedMessage id="Settings.CustomersName" />
                                </th>
                                <th>
                                    <FormattedMessage id="Settings.EmailAddress" />
                                </th>
                                <th>
                                    <FormattedMessage id="Settings.Phone" />
                                </th>
                                <th>
                                    <FormattedMessage id="Settings.CustomersStatus" />
                                </th>
                                <th>
                                    <FormattedMessage id="Settings.Amount" />
                                </th>
                                <th>
                                    <FormattedMessage id="Settings.Type" />
                                </th>
                            </tr>
                        </thead>
                        {!(
                            props.tableData.length === 0 ||
                            (props.searchResults.length === 0 && props.searchTerm !== '')
                        ) && (
                            <tbody>
                                {props.tableData.map((td, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{moment(td.bookingDate).format('DD MMM YYYY')}</td>
                                            <td>{td.customerName}</td>
                                            <td>{td.emailAddress}</td>
                                            <td>{td.phone}</td>
                                            <td>{getCustomerStatusBadge(td.customerStatus)}</td>
                                            <td>£{td.totalSpend.toFixed(2)}</td>
                                            <td>{getTransactionType(td.transactionType)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </Table>
                    {(props.tableData.length === 0 ||
                        (props.searchResults.length === 0 && props.searchTerm !== '')) && (
                        <div align="middle" className="no-data-cell">
                            <EmptyResult />
                            {getEmptyResultText()}
                        </div>
                    )}
                    {props.pageCount > 0 && (
                        <Paginator
                            onSelectPage={props.setCurrentPage}
                            selectedPage={props.currentPage}
                            numberOfPages={props.pageCount}
                            pageSize={props.pageSize}
                            totalItemCount={props.pageCount * props.pageSize}
                            paginatorMode={paginatorMode.previousNext}
                        />
                    )}
                </Fragment>
            );
        }
    };

    return <div className="loyalty-report-table-body">{renderTableBody()}</div>;
}

LoyaltyReportTableBody.propTypes = {
    tableData: PropTypes.array.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTerm: PropTypes.string.isRequired,
};

export default LoyaltyReportTableBody;
