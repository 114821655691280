import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as OrderIcon } from '../../content/icons/Icon-OrderForm.svg';
import { useIntl } from 'react-intl';
import AlertPanel from './AlertPanel';
import orderAtTableStatus from '../../enums/orderAtTableStatus';

function OrderAndPayAlert(props) {
    const intl = useIntl();
    const isOrderComplete = props.alert.booking.orderAtTableStatus === orderAtTableStatus.Completed;

    function getIcon() {
        return (
            <div className={getIconStyle()}>
                <OrderIcon />
            </div>
        );
    }

    function getIconStyle() {
        return isOrderComplete ? 'order-icon-completed' : 'order-icon-failed';
    }

    function getRowCss() {
        return isOrderComplete ? 'order-completed' : 'order-failed';
    }

    function getTitleText() {
        return isOrderComplete
            ? intl.formatMessage({ id: 'Common.OrderCompletedAndPaidLabel' })
            : intl.formatMessage({ id: 'Common.OrderFailedLabel' });
    }

    function getTables() {
        if (props.alert.tableLabels && props.alert.tableLabels.length > 0) {
            const tablesLabel = intl.formatMessage({ id: 'Common.Tables' });
            const tableNames = props.alert.tableLabels.map((label) => label.name);
            let tables = tableNames.join(', ');
            return tablesLabel + ' ' + tables;
        }

        return '';
    }

    return (
        <AlertPanel
            rowColour={getRowCss()}
            title={getTitleText()}
            booking={props.alert.booking}
            description={getTables()}
            icon={getIcon()}
            setAlertBookingOverlayId={props.setAlertBookingOverlayId}
        />
    );
}

OrderAndPayAlert.propTypes = {
    setAlertBookingOverlayId: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired,
};

export default OrderAndPayAlert;
