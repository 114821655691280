import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledAlert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ErrorService from '../../services/ErrorService';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true }, () => {
            ErrorService.reportErrorFromReact(
                error.message,
                error.stack,
                info && info.componentStack ? info.componentStack : ''
            );
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <UncontrolledAlert className="error-bar" color="danger">
                    <FormattedMessage id="Common.ErrorHasOccured" description="Standard Error text" />
                </UncontrolledAlert>
            );
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
