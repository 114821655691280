class Customer {
    constructor(customer) {
        this.id = customer && customer.Id ? customer.Id : 0;
        this.firstname = customer && customer.FirstName ? customer.FirstName : '';
        this.surname = customer && customer.Surname ? customer.Surname : '';
        this.fullname = customer && customer.FullName ? customer.FullName : '';
        this.phoneCode = customer && customer.PhoneCode ? customer.PhoneCode : '';
        this.phoneNumber = customer && customer.PhoneNumber ? customer.PhoneNumber : '';
        this.mobilePhoneCode = customer && customer.MobilePhoneCode ? customer.MobilePhoneCode : '';
        this.mobileNumber = customer && customer.MobileNumber ? customer.MobileNumber : '';
        this.company = customer && customer.Company ? customer.Company : '';
        this.email = customer && customer.Email ? customer.Email : '';
        this.isEnabled = customer && customer.IsEnabled ? customer.IsEnabled : false;
        this.isVip = customer && customer.IsVip ? customer.IsVip : false;
        this.customerComments = customer && customer.Comments ? customer.Comments : '';
        this.loyaltyId = customer && customer.LoyaltyId ? customer.LoyaltyId : '';
    }

    displayFullName() {
        let fullname = this.fullname ? this.fullname : `${this.firstname} ${this.surname}`;
        return fullname;
    }
}

export default Customer;
