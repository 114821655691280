import React from 'react';
import PropTypes from 'prop-types';
import InfoBar from './InfoBar';
import useScreenSize from '../../hooks/useScreenSize';

function InfoBars({ infoBars, setInfoBars }) {
    const onDismiss = (id) => {
        setInfoBars((infoBars) => infoBars.filter((m) => m.id !== id));
    };

    const { isMobileView, isTabletView } = useScreenSize();

    return (
        <div
            className={
                isMobileView ? 'info-bar-wrapper mobile' : isTabletView ? 'info-bar-wrapper tablet' : 'info-bar-wrapper'
            }
        >
            {infoBars.slice(0, 5).map((infoBar) => (
                <InfoBar
                    key={infoBar.id}
                    id={infoBar.id}
                    message={infoBar.message}
                    type={infoBar.type}
                    onDismiss={onDismiss}
                />
            ))}
        </div>
    );
}

InfoBars.propTypes = {
    infoBars: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
            message: PropTypes.string,
        })
    ).isRequired,
    setInfoBars: PropTypes.func.isRequired,
};

export default InfoBars;
