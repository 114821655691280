import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class MenuService {
    static saveMenu(deploymentId, restaurantId, menuDto) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Menu/Save`,
            menuDto
        ).then((response) => response.data);
    }

    static getMenuCategories(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Menu/Categories/GetAll`
        ).then((response) => response.data);
    }

    static getMenus(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Menu/GetAll`
        ).then((response) => response.data);
    }

    static getMenu(deploymentId, restaurantId, menuId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Menu/Get/${menuId}`
        ).then((response) => response.data);
    }

    static deleteMenu(deploymentId, restaurantId, menuId) {
        return ApiHelper.deleteRequest(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Menu/Delete/${menuId}`
        ).then((response) => response.data);
    }

    static updateMenuIsDisabled(deploymentId, restaurantId, menuId, isDisabled) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Menu/UpdateIsDisabled/${menuId}`,
            { isDisabled: isDisabled }
        ).then((response) => response.data);
    }

    static copyMenu(deploymentId, restaurantId, copyMenuDto) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Menu/Copy`,
            copyMenuDto
        ).then((response) => response.data);
    }

    static saveMenuLogo(deploymentId, restaurantId, menuId, data) {
        return ApiHelper.postFormData(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Menu/${menuId}/SubmitImage`,
            data
        ).then((response) => response.data);
    }
}

export default MenuService;
