import React from 'react';
import { ReactComponent as SuccessIcon } from '../.././content/icons/Icon-Success.svg';
import PropTypes from 'prop-types';

function SuccessIconWrapper(props) {
    function getIconClass() {
        let className = 'progress-icon-container';

        if (props.notStarted && !props.isCompleted) {
            className += ' not-started';
        } else if (!props.notStarted && !props.isCompleted) {
            className += ' incomplete';
        }

        return className;
    }

    return (
        <div className={getIconClass()}>
            <SuccessIcon />
        </div>
    );
}

SuccessIconWrapper.propTypes = {
    isCompleted: PropTypes.bool.isRequired,
    notStarted: PropTypes.bool,
};

export default SuccessIconWrapper;
