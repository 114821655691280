import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function BreadCrumbDisplay(props) {
    function getBreadcrumbs() {
        return props.breadcrumbs.map((breadcrumb) => {
            if (!breadcrumb.isExternal) {
                return (
                    <Link
                        key={breadcrumb.title}
                        className="breadcrumb-item clickable-text"
                        to={{ pathname: breadcrumb.pathname, state: breadcrumb.state, search: breadcrumb.search }}
                    >
                        {breadcrumb.title}
                    </Link>
                );
            } else {
                return (
                    <a key={breadcrumb.title} href={breadcrumb.pathname} className="breadcrumb-item clickable-text">
                        {breadcrumb.title}
                    </a>
                );
            }
        });
    }

    return (
        (props.breadcrumbs && (
            <Breadcrumb tag="nav" listClassName="breadcrumb-nav">
                {getBreadcrumbs()}
                <BreadcrumbItem active tag="span">
                    {props.activePageTitle}
                </BreadcrumbItem>
            </Breadcrumb>
        )) ||
        null
    );
}

BreadCrumbDisplay.propTypes = {
    breadcrumbs: PropTypes.array,
    activePageTitle: PropTypes.string.isRequired,
};

export default BreadCrumbDisplay;
