import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ReactComponent as RDLVoucherIcon } from '../../content/icons/RDL-Voucher.svg';
import { ReactComponent as TickGreenIcon } from '../../content/icons/Tick-green.svg';
import TextWithIcon from '../common/TextWithIcon';
import LoadingSpinner from '../common/LoadingSpinner';
import AddOnService from '../../services/AddOnService';
import VoucherService from '../../services/VoucherService';
import addOnType from '../../enums/addOnType';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import DiaryContext from '../../contexts/DiaryContext';
import useScreenSize from '../../hooks/useScreenSize';

function VouchersConfirmationPanel(props) {
    const intl = useIntl();
    const { isMobileView } = useScreenSize();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubscribing, setIsSubscribing] = useState(false);

    const diaryContext = useContext(DiaryContext);

    useEffect(() => {
        AddOnService.getAddOnDisplayInformation(
            diaryContext.restaurantId,
            diaryContext.deploymentId,
            addOnType.vouchers
        ).then(() => {
            setIsLoading(false);
        });
    }, [diaryContext.restaurantId, diaryContext.deploymentId]);

    function getAnalyticsProperties() {
        let properties = {};
        props.vouchers.forEach((voucher, index) => {
            properties['VoucherName' + (index + 1)] = voucher.title;
        });
        return properties;
    }

    function subscribeToVouchers() {
        setIsSubscribing(true);
        AddOnService.addAddOn(diaryContext.restaurantId, diaryContext.deploymentId, addOnType.vouchers)
            .then(() => {
                VoucherService.setVoucherSalesSetting(diaryContext.deploymentId, diaryContext.restaurantId, true).then(
                    () => {
                        AnalyticsHelper.trackClickWithProperties(
                            'Web VouchersReview Confirm',
                            getAnalyticsProperties()
                        );
                        props.toggleAddon();
                    }
                );
            })
            .catch(() => {
                props.addErrorBar();
            })
            .finally(() => {
                setIsSubscribing(false);
            });
    }

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Fragment>
            <div className="voucher-confirmation-title">
                <FormattedMessage id="Settings.SubscribeNow" />
            </div>
            <div className="voucher-confirmation-title">
                <FormattedMessage id="Settings.GoLiveAndStartSelling" />
            </div>
            <Fragment>
                <div className="mb-3 mt-4">
                    <RDLVoucherIcon className="stripe-connect-svg voucher-confirmation-svg" />
                </div>
                <div className="voucher-confirmation-box">
                    <h6 className="upper-case">
                        <FormattedMessage id="Vouchers.GiftVouchers" />
                    </h6>
                    <div className="price-title">
                        <FormattedMessage id="Common.Free" />*
                    </div>
                    <div>
                        <div className="text-left bold-text voucher-confirmation-summary">
                            <FormattedMessage id="Vouchers.VoucherQuickSummary" />
                        </div>
                        <div className={isMobileView ? 'mb-1' : null}>
                            <TextWithIcon
                                text={intl.formatMessage({ id: 'Vouchers.IntroduceRevenueStream' })}
                                icon={<TickGreenIcon />}
                                iconClass="mr-1"
                            />
                        </div>
                        <div className={isMobileView ? 'mb-1' : null}>
                            <TextWithIcon
                                text={intl.formatMessage({ id: 'Vouchers.SellThroughOwnWebsite' })}
                                icon={<TickGreenIcon />}
                                iconClass="mr-1"
                            />
                        </div>
                        <div className={isMobileView ? 'mb-1' : null}>
                            <TextWithIcon
                                text={intl.formatMessage({ id: 'Vouchers.ReachNewDiners' })}
                                icon={<TickGreenIcon />}
                                iconClass="mr-1"
                            />
                        </div>
                    </div>

                    <hr />

                    <div className="stripe-details">
                        <div className="mb-4 p-1">
                            <div className="text-left mb-1">
                                *<FormattedMessage id="Settings.CommissionFee" values={{ commissionFee: '3.00%' }} />
                            </div>
                            <div className="text-left terms-conditions">
                                <FormattedMessage
                                    id="Settings.CommissionFeeDescription"
                                    values={{ commissionFee: '3%' }}
                                />
                            </div>
                        </div>
                        <Button
                            color="primary"
                            disabled={props.stripeConnectUrl === null || isSubscribing}
                            onClick={subscribeToVouchers}
                        >
                            {isSubscribing ? (
                                <FormattedMessage id="Settings.Subscribing" />
                            ) : (
                                <FormattedMessage id="Settings.SubscribeNow" />
                            )}
                        </Button>
                    </div>
                </div>
            </Fragment>
        </Fragment>
    );
}

VouchersConfirmationPanel.propTypes = {
    addErrorBar: PropTypes.func.isRequired,
    stripeConnectUrl: PropTypes.string,
    vouchers: PropTypes.array,
};

export default VouchersConfirmationPanel;
