import React, { useReducer, Fragment, useContext } from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Tooltip,
} from 'reactstrap';
import { ReactComponent as ResIcon } from '../.././content/images/rd-lite-logo.svg';
import { ReactComponent as HelpIcon } from '../.././content/icons/Icon-Help.svg';
import { ReactComponent as AlertBellIcon } from '../.././content/icons/Icon-BellAlert.svg';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ColouredDot from '../booking/ColouredDot';
import ProgressFlagsContext from '../../contexts/ProviderProgressFlagsContext';
import SessionContext from '../../contexts/SessionContext';
import DiaryContext from '../../contexts/DiaryContext';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';

function RdNavbar(props) {
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const [isToolTipOpen, toggleTooltip] = useReducer(props.toggle, false);
    const progressFlagsContext = useContext(ProgressFlagsContext);

    function getAnalyticsProperties() {
        return {
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
        };
    }

    function displayListingsIndicator() {
        return (
            !progressFlagsContext.uploadedMenu ||
            !progressFlagsContext.addedAdditionalImage ||
            !progressFlagsContext.addedAdvancedListing
        );
    }

    function getNavItems() {
        if (props.pathName.toLowerCase() === '/welcome') {
            return null;
        }

        return (
            <Fragment>
                <NavItem>
                    <NavLink
                        tag={Link}
                        className={
                            props.pathName === '/Bookings' || props.pathName === '/' ? 'current-nav-location' : ''
                        }
                        to="/Bookings"
                    >
                        <FormattedMessage id="Common.Bookings" />
                    </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                    <NavLink
                        tag={Link}
                        className={props.pathName === '/Vouchers' ? 'current-nav-location' : ''}
                        to="/Vouchers"
                        onClick={() => {
                            AnalyticsHelper.trackClickWithProperties('Web Voucher Click', getAnalyticsProperties());
                        }}
                    >
                        <FormattedMessage id="Common.Vouchers" />{' '}
                    </NavLink>
                </NavItem>{' '}
                <NavItem className="nav-item improve-listings">
                    <NavLink tag={Link} className={props.isInSettingsTab ? 'current-nav-location' : ''} to="/Settings">
                        <FormattedMessage id="Common.Settings" />{' '}
                        {displayListingsIndicator() && <ColouredDot colour={'#f29151'} />}
                    </NavLink>
                </NavItem>{' '}
            </Fragment>
        );
    }

    return (
        <Navbar id="header-nav-bar" expand="md" className={'fixed-top'}>
            <Nav className="ml-auto align-center" navbar>
                <div className="navbar-brand-container">
                    <NavbarBrand href={'/'}>
                        <ResIcon />
                    </NavbarBrand>
                    <NavItem className="provider-section">
                        <div>
                            <span data-testid="nav-bar-restaurant-name">{props.restaurantName}</span>
                            <span data-testid="nav-bar-restaurant-id">
                                {'ID'} {props.restaurantId}
                            </span>
                        </div>
                    </NavItem>
                </div>
                <div className="page-nav-container">{getNavItems()}</div>
                <div className="user-section-container">
                    <div id="alert-button" ref={props.buttonRef}>
                        <NavItem className="icon">
                            <NavLink
                                tag="button"
                                className="btn btn-link my-tooltip"
                                onClick={() => {
                                    if (props.alerts.length > 0) {
                                        props.toggleAlertsPanel();
                                    }
                                }}
                            >
                                <div className={props.newAlertsLength > 0 ? 'alert-bell' : null}>
                                    <div className={props.newAlertsLength > 0 ? 'alert-bell-icon' : null}>
                                        <AlertBellIcon />
                                    </div>
                                    {props.newAlertsLength > 0 && (
                                        <div className="alert-dot-container">
                                            <div className="alert-dot">
                                                {props.newAlertsLength > 9 ? '9+' : props.newAlertsLength}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </NavLink>
                        </NavItem>
                    </div>
                    {props.getAlertsDropdown()}
                    <div id="HelpIconToolTip">
                        <NavItem className="icon">
                            <NavLink
                                tag="button"
                                className="btn btn-link my-tooltip"
                                onClick={() => props.openHelpAndSupportModal()}
                            >
                                <HelpIcon />
                            </NavLink>
                            <Tooltip
                                innerClassName="tooltip-style"
                                placement="bottom"
                                isOpen={isToolTipOpen}
                                target="HelpIconToolTip"
                                toggle={toggleTooltip}
                            >
                                <FormattedMessage id="Common.Help" />
                            </Tooltip>
                        </NavItem>
                    </div>
                    <UncontrolledDropdown nav inNavbar className="user-section">
                        <DropdownToggle nav caret>
                            <div className="user-control">{props.userDisplayName}</div>
                        </DropdownToggle>
                        <DropdownMenu className="user-dropdown-menu">
                            {props.isAdmin && (
                                <DropdownItem tag="a" onClick={props.returnToProviderSelection}>
                                    <FormattedMessage id="Common.ReturnToProviderSelection" />
                                </DropdownItem>
                            )}
                            <DropdownItem tag="a" onClick={props.logOut}>
                                <FormattedMessage id="Common.Logout" />
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </Nav>
        </Navbar>
    );
}

RdNavbar.propTypes = {
    restaurantName: PropTypes.string.isRequired,
    restaurantId: PropTypes.number.isRequired,
    toggle: PropTypes.func.isRequired,
    pathName: PropTypes.string.isRequired,
    isInsettingsTab: PropTypes.bool,
    alerts: PropTypes.array,
    toggleAlertsPanel: PropTypes.func,
    getAlertsDropdown: PropTypes.func,
    openHelpAndSupportModal: PropTypes.func,
    logOut: PropTypes.func,
    buttonRef: PropTypes.object,
    newAlertsLength: PropTypes.number,
    userDisplayName: PropTypes.string,
};

export default RdNavbar;
