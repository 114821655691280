import React, { Fragment, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import { ReactComponent as PlusIcon } from '../../../content/icons/Icon-Plus.svg';
import ValidationDropdown from '../../common/ValidationDropdown';
import ValidationInput from '../../common/ValidationInput';
import { Label, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import RDLModal from '../../common/RDLModal';

function MerchantIdSection(props) {
    const intl = useIntl();
    const [displayWhatIsMerchantIdModal, setDisplayWhatIsMerchantIdModal] = useState(false);

    return (
        <div className="loyalty-form-section merchant-id">
            <div className="merchant-group-header-container">
                <p className="form-group-title">
                    {intl.formatMessage({ id: 'Settings.MerchantID' })}&nbsp;
                    <span>({props.merchantIds.length}/8)</span>
                </p>
                <Button
                    className="what-is-merchant-id-button"
                    onClick={() => {
                        setDisplayWhatIsMerchantIdModal(true);
                    }}
                >
                    {intl.formatMessage({ id: 'Settings.LoyaltyFormMerchantIdHelpTag' })}
                </Button>
            </div>
            <p>
                <i>{intl.formatMessage({ id: 'Settings.LoyaltyFormMerchantIdNote' })}</i>
            </p>
            <div className="merchant-id-container">
                {props.merchantIds.map((m, index) => {
                    return (
                        <div className="merchant-id-input-container" key={m.uniqueIdentifier}>
                            <Label
                                className="merchant-type-label"
                                id={`merchantId-label-${index}`}
                                for={`merchantId-${index}`}
                            >
                                {intl.formatMessage({ id: 'Settings.MerchantType' })}
                            </Label>
                            <ValidationDropdown
                                className="merchant-type-dropdown"
                                formValidation={props.formValidation}
                                name={`merchantType-${index}`}
                                validationRules={{
                                    required: intl.formatMessage(
                                        { id: 'Settings.LoyaltyFormIsRequired' },
                                        { fieldName: intl.formatMessage({ id: 'Settings.MerchantType' }) }
                                    ),
                                }}
                                hideRemoveItemIcon
                                defaultValue={intl.formatMessage({ id: 'Settings.SelectOne' })}
                                onChange={(merchantType) => {
                                    props.updateMerchantIdType(m, merchantType);
                                }}
                                selectedValue={m.merchantType}
                                options={props.merchantTypeOptions}
                                ariaRequired
                            />

                            <Label
                                className="merchant-id-label"
                                id={`merchantId-label-${index}`}
                                for={`merchantId-${index}`}
                            >
                                {intl.formatMessage({ id: 'Settings.MerchantID' })}
                            </Label>
                            <ValidationInput
                                id={`merchantId-${index}`}
                                type="text"
                                value={m.value ? m.value : ''}
                                name={`merchantId-${index}`}
                                onChange={(e) => {
                                    props.updateMerchantIdValue(m, e);
                                }}
                                innerRef={props.formValidation.register({
                                    required: intl.formatMessage(
                                        { id: 'Settings.LoyaltyFormIsRequired' },
                                        { fieldName: intl.formatMessage({ id: 'Settings.MerchantID' }) }
                                    ),
                                    validate: (value) => {
                                        if (value && value.length > 20) {
                                            return intl.formatMessage(
                                                { id: 'Common.ValueTooLongError' },
                                                { maxLength: '20' }
                                            );
                                        }
                                    },
                                })}
                                placeholder={intl.formatMessage({ id: 'Settings.MerchantID' })}
                                className="merchant-id-input"
                                errors={props.formValidation.errors}
                                ariaLabelledBy={`merchantId-label-${index}`}
                                ariaRequired
                            />

                            <Button
                                className="delete-button"
                                disabled={props.merchantIds.length === 1}
                                onClick={() => props.deleteMerchantId(m)}
                            >
                                <DeleteIcon />
                            </Button>
                        </div>
                    );
                })}
                {props.merchantIds.length < 8 && (
                    <Button className="btn add-merchant-button" onClick={() => props.addNewMerchantId()}>
                        <PlusIcon /> {intl.formatMessage({ id: 'Common.Add' })}{' '}
                        {intl.formatMessage({ id: 'Settings.MerchantID' })}
                    </Button>
                )}
            </div>
            <RDLModal
                isModalOpen={displayWhatIsMerchantIdModal}
                setIsModalOpen={setDisplayWhatIsMerchantIdModal}
                title={intl.formatMessage({ id: 'Settings.LoyaltyWhatIsMerchantIdModalTitle' })}
                actionButtonText={intl.formatMessage({ id: 'Common.Close' })}
                body={
                    <Fragment>
                        <FormattedMessage id="Settings.LoyaltyWhatIsMerchantIdParagraph1" />
                        <p className="modal-header-text">
                            {intl.formatMessage({ id: 'Settings.LoyaltyWhatIsMerchantIdParagraph2Header' })}
                        </p>
                        <FormattedMessage id="Settings.LoyaltyWhatIsMerchantIdParagraph2" />
                        <p></p>
                        <FormattedMessage id="Settings.LoyaltyWhatIsMerchantIdParagraph3" />
                        <p className="modal-header-text">
                            {intl.formatMessage({ id: 'Settings.LoyaltyWhatIsMerchantIdParagraph4Header' })}
                        </p>
                        <FormattedMessage id="Settings.LoyaltyWhatIsMerchantIdParagraph4" />
                        <p></p>
                        <FormattedMessage id="Settings.LoyaltyWhatIsMerchantIdParagraph5Bullet1" />
                        <p></p>
                        <FormattedMessage id="Settings.LoyaltyWhatIsMerchantIdParagraph5Bullet2" />
                        <p></p>
                        <FormattedMessage id="Settings.LoyaltyWhatIsMerchantIdParagraph5Bullet3" />
                        <p></p>
                        <p className="modal-header-text">
                            {intl.formatMessage({ id: 'Settings.LoyaltyWhatIsMerchantIdParagraph6Header' })}
                        </p>
                        <FormattedMessage id="Settings.LoyaltyWhatIsMerchantIdParagraph6" />
                    </Fragment>
                }
                action={(func) => {
                    setDisplayWhatIsMerchantIdModal(false);
                    func();
                }}
                backdrop
                hideCloseButton
                wrapClassName={'what-is-merchant-id-modal-wrapper'}
            />
        </div>
    );
}

MerchantIdSection.propTypes = {
    merchantIds: PropTypes.array.isRequired,
    updateMerchantIdType: PropTypes.func.isRequired,
    updateMerchantIdValue: PropTypes.func.isRequired,
    addNewMerchantId: PropTypes.func.isRequired,
    deleteMerchantId: PropTypes.func.isRequired,
    formValidation: PropTypes.object.isRequired,
    merchantTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MerchantIdSection;
