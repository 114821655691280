import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class AddOnService {
    static getAddOns(deploymentId, providerId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${providerId}/AddOns`
        ).then((response) => response.data);
    }

    static getAddOnDisplayInformation(providerId, deploymentId, addOnType) {
        return ApiHelper.get(
            `/api/AddOn/AddOnDisplayInformation?providerId=${providerId}&deploymentId=${deploymentId}&addOnType=${addOnType}`
        ).then((response) => response.data);
    }

    static addAddOn(providerId, deploymentId, addOnType) {
        return ApiHelper.post(
            `/api/AddOn/Add?providerId=${providerId}&deploymentId=${deploymentId}&addOnType=${addOnType}`
        ).then((response) => response.data);
    }

    static removeAddOn(providerId, deploymentId, addOnType) {
        return ApiHelper.post(
            `/api/AddOn/Remove?providerId=${providerId}&deploymentId=${deploymentId}&addOnType=${addOnType}`
        ).then((response) => response.data);
    }
}

export default AddOnService;
