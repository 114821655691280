import React, { useState, useEffect, useContext, useCallback, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import venueSettingsTab from '../../../enums/Tabs/venueSettingsTab';
import MyDetailsTab from './MyDetailsTab';
import MyListingTab from './MyListingTab';
import MyListingMenusTab from './MyListingMenusTab';
import TermsPrivacyTab from './TermsPrivacyTab';
import SettingsService from '../../../services/SettingsService';
import DiaryContext from '../../../contexts/DiaryContext';
import RestaurantDetails from '../../../domainObjects/RestaurantSettings/RestaurantDetails';
import GeneralDetails from '../../../domainObjects/RestaurantSettings/GeneralDetails';
import SearchDetails from '../../../domainObjects/RestaurantSettings/SearchDetails';
import SocialNetworkDetails from '../../../domainObjects/RestaurantSettings/SocialNetworkDetails';
import TermsPrivacy from '../../../domainObjects/RestaurantSettings/TermsPrivacy';
import infoBarType from '../../../enums/infoBarType';
import SettingsHelper from '../../../helpers/SettingsHelper';
import CountryService from '../../../services/CountryService';
import ProgressFlagsContext from '../../../contexts/ProviderProgressFlagsContext';
import Tag from '../../common/Tag';
import tagColour from '../../../enums/tagColour';
import SettingsTabbedContentPage from '../SettingsTabbedContentPage';
import CustomerDataRetentionTab from './CustomerDataRetentionTab';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import SessionContext from '../../../contexts/SessionContext';
import useQueryParam from '../../../hooks/useQueryParam';

let _ID = 0;
function VenueSettingsPage(props) {
    const intl = useIntl();

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const diaryContext = useContext(DiaryContext);
    const [restaurantDetails, setRestaurantDetails] = useState(null);
    const [termsPrivacyDetails, setTermsPrivacyDetails] = useState(null);
    const [menuTypes, setMenuTypes] = useState([]);
    const [providerMenus, setProviderMenus] = useState([]);
    const [cultureCodes, setCultureCodes] = useState([]);
    const [isLoadingRestaurantDetails, setIsLoadingRestaurantDetails] = useState(true);
    const [isLoadingPrivacyPolicy, setIsLoadingPrivacyPolicy] = useState(true);
    const [allFacilities, setAllFacilities] = useState([]);
    const [allDressCodes, setAllDressCodes] = useState([]);
    const [allCuisines, setAllCuisines] = useState([]);
    const [allCurrencies, setAllCurrencies] = useState([]);
    const [infoBars, setInfoBars] = useState([]);
    const [isCountryCodesLoading, setIsCountryCodesLoading] = useState(true);
    const [countryCodes, setCountryCodes] = useState([]);
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [allDetails, setAllDetails] = useState(null);
    const progressFlagsContext = useContext(ProgressFlagsContext);
    const sessionContext = useContext(SessionContext);
    const [analyticsProperties] = useState(getAnalyticsProperties);
    const listing = useQueryParam('listing');

    useEffect(() => {
        SettingsService.getRestaurantSettings(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((data) => {
                setRestaurantDetails(new RestaurantDetails(data.NameAddressDetails));
                setMenuTypes(data.MenuTypes);
                setProviderMenus(data.ProviderMenus);
                setCultureCodes(data.Cultures);
                setWebsiteUrl(data.RestaurantInformation.WebsiteUrl);
                setAllFacilities(data.Facilities);
                setAllDressCodes(data.DressCodes);
                setAllCuisines(data.Cuisines);
                setAllCurrencies(data.Currencies);
                setAllDetails({
                    ...new RestaurantDetails(data.NameAddressDetails),
                    ...new GeneralDetails(data.RestaurantInformation),
                    ...new SearchDetails(data.SearchDetails),
                    ...new SocialNetworkDetails(data.SocialNetworkDetails),
                    isReserveWithGoogleEnabled: data.IsReserveWithGoogleEnabled,
                    hideOnPortal: data.HideOnPortal,
                    isCustomerRetentionPolicyEnabled: data.IsCustomerRetentionPolicyEnabled,
                });
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsLoadingRestaurantDetails(false);
            });
    }, [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        SettingsService.getTermsAndPrivacy(diaryContext.deploymentId, diaryContext.restaurantId).then((data) => {
            setTermsPrivacyDetails(new TermsPrivacy(data));
            setIsLoadingPrivacyPolicy(false);
        });
    }, [diaryContext.deploymentId, diaryContext.restaurantId]);

    const [currentTabKey, setCurrentTabKey] = useState(venueSettingsTab.myDetails);

    useEffect(() => {
        setIsCountryCodesLoading(true);
        CountryService.getCountryCodes()
            .then((countryCodes) => {
                setCountryCodes(countryCodes);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsCountryCodesLoading(false);
            });
    }, [addErrorBar]);

    useEffect(() => {
        if (listing) {
            if (listing === venueSettingsTab.myListing) {
                setCurrentTabKey(venueSettingsTab.myListing);
            } else if (listing === venueSettingsTab.myListingMenus) {
                setCurrentTabKey(venueSettingsTab.myListingMenus);
            }
        } else {
            setCurrentTabKey(venueSettingsTab.myDetails);
        }
    }, [listing]);

    useEffect(() => {
        if (currentTabKey) {
            switch (currentTabKey) {
                case venueSettingsTab.myListing:
                    AnalyticsHelper.trackPage('Restaurant Listing');
                    AnalyticsHelper.trackClickWithProperties('Web Activation Listing', analyticsProperties);
                    break;
                case venueSettingsTab.myListingMenus:
                    AnalyticsHelper.trackPage('Restaurant Menu');
                    AnalyticsHelper.trackClickWithProperties('Web Activation Menu', analyticsProperties);
                    break;

                default:
                    break;
            }
        }
    }, [currentTabKey, analyticsProperties]);

    function getAnalyticsProperties() {
        return {
            completedListing: progressFlagsContext.addedAdvancedListing,
            completedMenus: progressFlagsContext.uploadedMenu,
            completedImages: progressFlagsContext.addedAdditionalImage,
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
        };
    }

    function getTabLabels() {
        const tabLabels = [
            {
                key: venueSettingsTab.myDetails,
                name: intl.formatMessage({ id: 'Settings.MyDetails' }),
            },
            {
                key: venueSettingsTab.myListing,
                name: intl.formatMessage({ id: 'Settings.MyListing' }),
                showDot: !progressFlagsContext.addedAdvancedListing,
            },
            {
                key: venueSettingsTab.myListingMenus,
                name: intl.formatMessage({ id: 'Settings.MyListingMenus' }),
                showDot: !progressFlagsContext.uploadedMenu,
            },
            {
                key: venueSettingsTab.termsPrivacy,
                name: intl.formatMessage({ id: 'Settings.TermsAndPrivacy' }),
            },
        ];

        if (allDetails && allDetails.isCustomerRetentionPolicyEnabled === true) {
            tabLabels.push({
                key: venueSettingsTab.customerDataRetention,
                name: intl.formatMessage({ id: 'Settings.CustomerDataRetention' }),
            });
        }

        return tabLabels;
    }

    function getTabs() {
        const tabs = [
            <MyDetailsTab
                key={venueSettingsTab.myDetails}
                isActive={currentTabKey === venueSettingsTab.myDetails}
                addSuccessBar={addSuccessBar}
                addErrorBar={addErrorBar}
                {...restaurantDetails}
                countryCodes={countryCodes}
                websiteUrl={websiteUrl}
            />,
            <MyListingTab
                key={venueSettingsTab.myListing}
                isActive={currentTabKey === venueSettingsTab.myListing}
                addSuccessBar={addSuccessBar}
                addErrorBar={addErrorBar}
                allDetails={allDetails}
                allCuisines={allCuisines}
                allCurrencies={allCurrencies}
                allDressCodes={allDressCodes}
                allFacilities={allFacilities}
                setAllDetails={setAllDetails}
            />,
            <MyListingMenusTab
                key={venueSettingsTab.myListingMenus}
                isActive={currentTabKey === venueSettingsTab.myListingMenus}
                menuTypes={menuTypes}
                providerMenus={providerMenus}
                cultureCodes={cultureCodes}
                addSuccessBar={addSuccessBar}
                addErrorBar={addErrorBar}
            />,
            <TermsPrivacyTab
                key={venueSettingsTab.termsPrivacy}
                isActive={currentTabKey === venueSettingsTab.termsPrivacy}
                addSuccessBar={addSuccessBar}
                addErrorBar={addErrorBar}
                {...termsPrivacyDetails}
            />,
        ];

        if (allDetails && allDetails.isCustomerRetentionPolicyEnabled === true) {
            tabs.push(
                <CustomerDataRetentionTab
                    key={venueSettingsTab.customerDataRetention}
                    isActive={currentTabKey === venueSettingsTab.customerDataRetention}
                    addSuccessBar={addSuccessBar}
                    addErrorBar={addErrorBar}
                />
            );
        }

        return tabs;
    }

    function getSidePanelContent() {
        if (restaurantDetails) {
            return (
                <Fragment>
                    <div className="online-status-tab" onClick={() => onTabChanged(venueSettingsTab.myListing)}>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="bold-text mr-2">
                                <div>
                                    <FormattedMessage id="Common.DishCult" />
                                </div>
                                {allDetails && allDetails.hideOnPortal === false && (
                                    <div>
                                        <a
                                            className="wrap"
                                            target="_blank"
                                            href={SettingsHelper.getRestaurantMicrosite(diaryContext.micrositeName)}
                                            rel="noopener noreferrer"
                                        >
                                            {SettingsHelper.getShortenedRestaurantMicrosite(diaryContext.micrositeName)}
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div>{getListingStatusTag()}</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <div className="bold-text mr-2">
                                <FormattedMessage id="Common.ReserveWithGoogle" />
                            </div>
                            <div>{getRwGStatusTag()}</div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }

    function getListingStatusTag() {
        if (allDetails && allDetails.hideOnPortal === false)
            return <Tag value={intl.formatMessage({ id: 'Common.Active' })} color={tagColour.Green} />;
        else return <Tag value={intl.formatMessage({ id: 'Common.InActive' })} color={tagColour.Disabled} />;
    }

    function getRwGStatusTag() {
        if (allDetails && allDetails.isReserveWithGoogleEnabled === true)
            return <Tag value={intl.formatMessage({ id: 'Common.Active' })} color={tagColour.Green} />;
        else return <Tag value={intl.formatMessage({ id: 'Common.InActive' })} color={tagColour.Disabled} />;
    }

    function onTabChanged(tabKey) {
        if (tabKey !== currentTabKey) {
            setCurrentTabKey(tabKey);
        }
    }

    function getBreadcrumbOptions() {
        return [new BreadCrumbOption(intl.formatMessage({ id: 'Settings.Title' }), '/Settings')];
    }

    function isLoading() {
        return isLoadingRestaurantDetails || isLoadingPrivacyPolicy || isCountryCodesLoading;
    }

    return (
        <SettingsTabbedContentPage
            pageTitle={intl.formatMessage({ id: 'Settings.RestaurantDetails' })}
            tabLabels={getTabLabels()}
            currentTabKey={currentTabKey}
            tabs={getTabs()}
            sidePanelAdditionalContent={getSidePanelContent()}
            onTabChanged={onTabChanged}
            isLoading={isLoading()}
            infoBars={infoBars}
            setInfoBars={setInfoBars}
            breadcrumbActivePageTitle={intl.formatMessage({ id: 'Settings.RestaurantDetails' })}
            breadcrumbOptions={getBreadcrumbOptions()}
            includeBreadcrumb
        />
    );
}

export default VenueSettingsPage;
