import PropTypes from 'prop-types';
import React, { useState, useRef, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import MenuOverlayList from './MenuOverlayList';
import { ReactComponent as ChevronUp } from '../../content/icons/chevron-left.svg';

function MenuOverlayExpander(props) {
    const [openExpander, setOpenExpander] = useState(false);
    const [maxHeight, setMaxHeight] = useState('205px');
    const [rotateIcon, setRotateIcon] = useState('');
    const content = useRef(null);

    function toggleExpander() {
        setOpenExpander(!openExpander);
        setMaxHeight(openExpander ? '205px' : `${content.current.scrollHeight}px`);
        setRotateIcon(openExpander ? '' : 'rotate');
    }

    function getMenuRows() {
        return (
            <div>
                {props.menus.map((menu) => {
                    return (
                        <MenuOverlayList
                            key={menu.id}
                            overlayContent={props.overlayContent}
                            menu={menu}
                            sendMenuNotification={props.sendMenuNotification}
                            openMenuChangeModal={props.openMenuChangeModal}
                        />
                    );
                })}
            </div>
        );
    }

    function getExpander() {
        return (
            <Fragment>
                <div>
                    <div ref={content} className="expander-content" style={{ maxHeight: `${maxHeight}` }}>
                        {getMenuRows()}
                    </div>
                    {props.menus.length > 4 && (
                        <div className="send-menu-show-row">
                            <button onClick={toggleExpander}>
                                <FormattedMessage id={openExpander ? 'Bookings.ShowLess' : 'Bookings.ShowMore'} />
                                <ChevronUp className={`${rotateIcon}`} />
                            </button>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }

    return <Fragment>{getExpander()}</Fragment>;
}

MenuOverlayExpander.propTypes = {
    overlayContent: PropTypes.object,
    menus: PropTypes.array,
    openMenuChangeModal: PropTypes.func,
    sendMenuNotification: PropTypes.func,
};

export default MenuOverlayExpander;
