import MomentHelper from '../helpers/MomentHelper';
import Voucher from './Voucher';

class SoldVoucher {
    constructor(soldVoucher) {
        this.id = soldVoucher && soldVoucher.Id ? soldVoucher.Id : 0;
        this.orderId = soldVoucher && soldVoucher.OrderId ? soldVoucher.OrderId : 0;
        this.reference = soldVoucher && soldVoucher.Reference ? soldVoucher.Reference : '';
        this.voucher = soldVoucher && soldVoucher.Voucher ? new Voucher(soldVoucher.Voucher) : null;
        this.expiresOn = soldVoucher && soldVoucher.ExpiresOn ? MomentHelper.newInstance(soldVoucher.ExpiresOn) : null;
    }
}

export default SoldVoucher;
