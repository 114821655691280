import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class RestaurantService {
    static getRestaurantSetup(deploymentId, restaurantId) {
        return ApiHelper.get(`${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}`);
    }

    static getSegmentSetup(deploymentId, restaurantId, segmentId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Segment/${segmentId}`
        ).then((response) => response.data);
    }

    static GetAllProvidersForDeployment(deploymentId, searchTerm) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/GetAllProvidersForDeployment?deploymentId=${deploymentId}&searchTerm=${searchTerm}`
        ).then((response) => response.data);
    }

    static getSystemTimeZones(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/GetTimeZones`
        ).then((response) => response.data);
    }
}

export default RestaurantService;
