import React from 'react';
import PropTypes from 'prop-types';
import RDLModel from '../../common/RDLModal';
import { useIntl } from 'react-intl';

function DisconnectSquareModal(props) {
    const intl = useIntl();

    return (
        <RDLModel
            isModalOpen={props.isModalOpen}
            setIsModalOpen={props.setIsModalOpen}
            action={props.disconnectSquare}
            title={intl.formatMessage({ id: 'Settings.DisconnectSquare' })}
            bodyText={intl.formatMessage({ id: 'Settings.DisconnectSquareConfirmationText' })}
            actionButtonText={intl.formatMessage({ id: 'Common.Disconnect' })}
            actionButtonPendingText={intl.formatMessage({ id: 'Common.Disconnecting' })}
        />
    );
}

DisconnectSquareModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    disconnectSquare: PropTypes.func.isRequired,
};

export default DisconnectSquareModal;
