import CloseOutType from '../enums/closeOutType';

class CloseOut {
    constructor(closeOut) {
        this.id = closeOut && closeOut.Id ? closeOut.Id : 0;
        this.name = closeOut && closeOut.Name ? closeOut.Name : '';
        this.description = closeOut && closeOut.Description ? closeOut.Description : '';
        this.serviceId = closeOut && closeOut.ServiceId ? closeOut.ServiceId : 0;
        this.date = closeOut && closeOut.CloseOutDate ? closeOut.CloseOutDate : '';
        this.type = closeOut && closeOut.CloseOutType ? closeOut.CloseOutType : CloseOutType.trafficLight;
    }

    toJson() {
        return {
            Id: this.id,
            Name: this.name,
            Description: this.description,
            ServiceId: this.serviceId,
            CloseOutDate: this.date,
            CloseOutType: this.type,
        };
    }
}

export default CloseOut;
