import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import EditMenuItemSection from './EditMenuItemSection';

function AddMenuItemSection(props) {
    const [isOpen, toggleIsOpen] = useReducer(toggle, false);

    function toggle(value) {
        return !value;
    }

    if (!isOpen) {
        return (
            <Button
                block
                className="btn-dashed"
                color="default"
                onClick={() => {
                    toggleIsOpen();
                }}
            >
                <FormattedMessage id="Settings.AddItem" /> +
            </Button>
        );
    }

    return (
        <EditMenuItemSection
            toggleIsOpen={toggleIsOpen}
            menuItemAdded={props.menuItemAdded}
            menuItemEdited={props.menuItemEdited}
            addErrorBar={props.addErrorBar}
        />
    );
}

AddMenuItemSection.propTypes = {
    menuItemAdded: PropTypes.func.isRequired,
    menuItemEdited: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
};

export default AddMenuItemSection;
