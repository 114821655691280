import React, { useState, Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Section from '../../common/Section';
import ImproveListingTasksCompleteModal from '../../common/ImproveListingTasksCompleteModal';
import ImageService from '../../../services/ImageService';
import SettingsHelper from '../../../helpers/SettingsHelper';
import { Button, CustomInput } from 'reactstrap';
import ImageUploading from 'react-images-uploading';
import DiaryContext from '../../../contexts/DiaryContext';
import ProviderImage from '../../../domainObjects/ProviderImage';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import ProviderImageCard from './ProviderImageCard';
import useScreenSize from '../../../hooks/useScreenSize';
import ProviderProgressFlagsContext from '../../../contexts/ProviderProgressFlagsContext';
import providerProgressFlags from '../../../enums/providerProgressFlags';
import imagesEnabledStatus from '../../../enums/imagesEnabledStatus';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';

function RestaurantImagesTab({ isActive, addErrorBar, addSuccessBar }) {
    const intl = useIntl();
    const displayClass = isActive ? '' : 'hidden';
    const [images, setImages] = useState([]);
    const [improveListingTasksCompleteModalOpen, setImproveListingTasksCompleteModalOpen] = useState(false);
    const diaryContext = useContext(DiaryContext);
    const { isMobileView } = useScreenSize();
    const providerProgressFlagsContext = useContext(ProviderProgressFlagsContext);
    const [selectedImageEnabledRadioToggle, setSelectedImageEnabledRadioToggle] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        ImageService.getImagesForProvider(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((response) => {
                setImages(response.map((image) => new ProviderImage(image)));
            })
            .catch(() => {
                addErrorBar();
            });
    }, [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        if (images && images.length > 0) {
            const activeImages = images.filter((i) => i.isActive && !i.isMainImage);
            const disabledImages = images.filter((i) => !i.isActive && !i.isMainImage);

            if (activeImages.length > 0 && disabledImages.length === 0) {
                setSelectedImageEnabledRadioToggle(imagesEnabledStatus.allActive);
            } else if (disabledImages.length > 0 && activeImages.length === 0) {
                setSelectedImageEnabledRadioToggle(imagesEnabledStatus.allDisabled);
            } else {
                setSelectedImageEnabledRadioToggle('');
            }
        }
    }, [images]);

    function getAnalyticsImagesProperties(newImageArray, isIdentify) {
        let imageNames = [];
        let imagesCopy = [...newImageArray];
        imagesCopy.forEach((image) => {
            imageNames.push(image.name);
        });
        if (isIdentify) {
            return {
                noOfImagesUploaded: newImageArray.length,
                imageNames: imageNames,
            };
        }
        return {
            completedListing: providerProgressFlagsContext.addedAdvancedListing,
            completedMenus: providerProgressFlagsContext.uploadedMenu,
            completedImages: providerProgressFlagsContext.addedAdditionalImage,
            noOfImagesUploaded: newImageArray.length,
            imageNames: imageNames,
        };
    }

    function onImagesChange(imageList) {
        function updateUploadPercentage(newImageArray, index, percentage) {
            const imageToUpdateIndex = newImageArray.findIndex(
                (i) => i.uniqueIdentifier === loadingImages[index].uniqueIdentifier
            );
            newImageArray[imageToUpdateIndex].loadingPercentage = percentage;
            setImages([...newImageArray]);
        }

        let imagesToUpload = imageList.filter((image) => !images.includes(image));
        const loadingImages = imagesToUpload.map(
            (i) => new ProviderImage({ ImageUrl: i.data_url, Name: i.file.name, IsEnabled: true, IsMainImage: false })
        );
        const newImageArray = [...images, ...loadingImages];
        setImages(newImageArray);
        imagesToUpload.forEach((image, index) => {
            const data = new FormData();
            data.append('file', image.file);
            ImageService.uploadRestaurantImages(
                diaryContext.deploymentId,
                diaryContext.restaurantId,
                data,
                (percentage) => {
                    updateUploadPercentage(newImageArray, index, percentage);
                }
            )
                .then((response) => {
                    const imageToUpdateIndex = newImageArray.findIndex(
                        (i) => i.uniqueIdentifier === loadingImages[index].uniqueIdentifier
                    );
                    if (imageToUpdateIndex >= 0) {
                        const newImageItem = new ProviderImage(response[0]);
                        // Setting the URL to be the same as the uploaded URL to stop having to fetch the image from the stored cloud url to improve user experience.
                        newImageItem.url = newImageArray[imageToUpdateIndex].url;
                        newImageArray[imageToUpdateIndex] = newImageItem;
                        setImages([...newImageArray]);
                    }
                    if (!providerProgressFlagsContext.addedAdditionalImage) {
                        providerProgressFlagsContext.updatedProviderProgressFlag(
                            providerProgressFlags.addedAdditionalImage
                        );
                    }
                    if (
                        providerProgressFlagsContext.uploadedMenu &&
                        providerProgressFlagsContext.addedAdvancedListing &&
                        !providerProgressFlagsContext.dismissedCompletionModal
                    ) {
                        setImproveListingTasksCompleteModalOpen(true);
                    }
                })
                .catch(() => {
                    addErrorBar(intl.formatMessage({ id: 'Settings.ErrorUploadingImages' }));
                })
                .finally(() => {
                    AnalyticsHelper.trackClickWithProperties(
                        'Web Improve RestImages',
                        getAnalyticsImagesProperties(newImageArray)
                    );
                    AnalyticsHelper.identifyUserIdWithProperties(
                        diaryContext.restaurantId,
                        getAnalyticsImagesProperties(newImageArray, true)
                    );
                });
        });
    }

    function toggleImageSelected(image) {
        let imagesCopy = [...images];
        let imageIndex = imagesCopy.findIndex((i) => i.id === image.id);
        imagesCopy[imageIndex].isSelected = !imagesCopy[imageIndex].isSelected;
        setImages(imagesCopy);
    }

    function toggleSelectAllImages() {
        let value = !images.every((i) => i.isSelected);
        let imagesCopy = [...images];
        setImages(imagesCopy.map((e) => ({ ...e, isSelected: value })));
    }

    function toggleImageActiveStatus(image, status) {
        if (status === true) {
            ImageService.setImagesEnabled(diaryContext.deploymentId, diaryContext.restaurantId, [image.id])
                .then(() => {
                    let imagesCopy = [...images];
                    let imageIndex = imagesCopy.findIndex((i) => i.id === image.id);
                    imagesCopy[imageIndex].isActive = status;
                    setImages(imagesCopy);
                    addSuccessBar(intl.formatMessage({ id: 'Settings.SuccessfullyEnabledImageMessage' }));
                })
                .catch(() => {
                    addErrorBar(intl.formatMessage({ id: 'Settings.ErrorEnablingSingleImageMessage' }));
                });
        } else {
            if (!image.isMainImage) {
                ImageService.setImagesDisabled(diaryContext.deploymentId, diaryContext.restaurantId, [image.id])
                    .then(() => {
                        let imagesCopy = [...images];
                        let imageIndex = imagesCopy.findIndex((i) => i.id === image.id);
                        imagesCopy[imageIndex].isActive = status;
                        setImages(imagesCopy);
                        addSuccessBar(intl.formatMessage({ id: 'Settings.SuccessfullyDisabledImageMessage' }));
                    })
                    .catch(() => {
                        addErrorBar(intl.formatMessage({ id: 'Settings.ErrorDisablingSingleImageMessage' }));
                    });
            }
        }
    }

    function enableSelectedImages() {
        setSelectedImageEnabledRadioToggle(imagesEnabledStatus.allActive);
        let selectedImageIds = images.filter((i) => i.isSelected && !i.isMainImage && !i.isActive).map((si) => si.id);
        if (selectedImageIds.length) {
            ImageService.setImagesEnabled(diaryContext.deploymentId, diaryContext.restaurantId, selectedImageIds)
                .then(() => {
                    let imagesCopy = [...images];
                    selectedImageIds.forEach((id) => {
                        const image = imagesCopy.find((i) => i.id === id);
                        image.isActive = true;
                    });
                    setImages(imagesCopy);
                    addSuccessBar(
                        intl.formatMessage(
                            { id: 'Settings.SuccessfullyEnabledMultipleImagesMessage' },
                            { imagesCount: selectedImageIds.length }
                        )
                    );
                })
                .catch(() => {
                    if (selectedImageIds.length === 1) {
                        addErrorBar(intl.formatMessage({ id: 'Settings.ErrorEnablingSingleImageMessage' }));
                    }
                    addErrorBar(intl.formatMessage({ id: 'Settings.ErrorEnablingMultipleImagesMessage' }));
                });
        }
    }

    function disableSelectedImages() {
        setSelectedImageEnabledRadioToggle(imagesEnabledStatus.allDisabled);
        let selectedImageIds = images.filter((i) => i.isSelected && !i.isMainImage && i.isActive).map((si) => si.id);
        const mainImageSelected = images.find((i) => i.isSelected && i.isMainImage);
        if (selectedImageIds.length) {
            ImageService.setImagesDisabled(diaryContext.deploymentId, diaryContext.restaurantId, selectedImageIds)
                .then(() => {
                    let imagesCopy = [...images];
                    selectedImageIds.forEach((id) => {
                        const image = imagesCopy.find((i) => i.id === id);
                        image.isActive = false;
                    });
                    setImages(imagesCopy);
                    if (mainImageSelected) {
                        addErrorBar(
                            intl.formatMessage(
                                { id: 'Settings.SuccessfullyDisabledMultipleImagesWithWarningMessage' },
                                { imagesCount: selectedImageIds.length }
                            )
                        );
                    } else {
                        addSuccessBar(
                            intl.formatMessage(
                                { id: 'Settings.SuccessfullyDisabledMultipleImagesMessage' },
                                { imagesCount: selectedImageIds.length }
                            )
                        );
                    }
                })
                .catch(() => {
                    if (selectedImageIds.length === 1) {
                        addErrorBar(intl.formatMessage({ id: 'Settings.ErrorDisablingSingleImageMessage' }));
                    }
                    addErrorBar(intl.formatMessage({ id: 'Settings.ErrorDisablingMultipleImagesMessage' }));
                });
        }
    }

    function deleteImage(image) {
        if (!image.isMainImage) {
            setIsDeleting(true);
            ImageService.deleteImages(diaryContext.deploymentId, diaryContext.restaurantId, [image.id])
                .then(() => {
                    let imagesCopy = [...images];
                    let imageIndex = imagesCopy.findIndex((i) => i.id === image.id);
                    imagesCopy.splice(imageIndex, 1);
                    setImages(imagesCopy);
                    addSuccessBar(intl.formatMessage({ id: 'Settings.SuccessfullyDeletedImageMessage' }));
                })
                .catch(() => {
                    addErrorBar(intl.formatMessage({ id: 'Settings.ErrorDeletingSingleImageMessage' }));
                })
                .finally(() => {
                    setIsDeleting(false);
                });
        }
    }

    function deleteSelectedImages() {
        let selectedImageIds = images.filter((i) => i.isSelected && !i.isMainImage).map((si) => si.id);
        const mainImageSelected = images.find((i) => i.isSelected && i.isMainImage);
        if (selectedImageIds.length) {
            ImageService.deleteImages(diaryContext.deploymentId, diaryContext.restaurantId, selectedImageIds)
                .then(() => {
                    let imagesCopy = images.filter((image) => !selectedImageIds.includes(image.id));
                    setImages(imagesCopy);
                    if (mainImageSelected) {
                        addErrorBar(
                            intl.formatMessage(
                                { id: 'Settings.SuccessfullyDeletedMultipleImagesWithWarningMessage' },
                                { imagesCount: selectedImageIds.length }
                            )
                        );
                    } else {
                        addSuccessBar(
                            intl.formatMessage(
                                { id: 'Settings.SuccessfullyDeletedMultipleImagesMessage' },
                                { imagesCount: selectedImageIds.length }
                            )
                        );
                    }
                })
                .catch(() => {
                    if (selectedImageIds.length === 1) {
                        addErrorBar(intl.formatMessage({ id: 'Settings.ErrorDeletingSingleImageMessage' }));
                    }
                    addErrorBar(intl.formatMessage({ id: 'Settings.ErrorDeletingMultipleImagesMessage' }));
                });
        }
    }

    function setAsMainImage(image) {
        if (!image.isMainImage) {
            if (image.isActive) {
                ImageService.setImageAsMainImage(diaryContext.deploymentId, diaryContext.restaurantId, image.id)
                    .then(() => {
                        let imagesCopy = [...images];
                        imagesCopy.forEach((img) => {
                            img.isMainImage = false;
                        });
                        let imageIndex = imagesCopy.findIndex((i) => i.id === image.id);
                        imagesCopy[imageIndex].isMainImage = true;
                        setImages(imagesCopy);
                        addSuccessBar(intl.formatMessage({ id: 'Settings.SuccessfullyUpdatedMainImageMessage' }));
                    })
                    .catch(() => {
                        addErrorBar(intl.formatMessage({ id: 'Settings.ErrorUpdatingMainImageMessage' }));
                    });
            } else {
                addErrorBar(intl.formatMessage({ id: 'ErrorUpdatingMainImageMessage' }));
            }
        }
    }

    function getSelectAllImagesCheckedStatus(id) {
        if (images.length !== 0 && images.every((i) => i.isSelected)) {
            return selectedImageEnabledRadioToggle === id;
        }

        return false;
    }

    return (
        <div className={`${displayClass} settings-images`}>
            <Section sectionTitle={intl.formatMessage({ id: 'Settings.RestaurantImagesTabTitle' })}>
                <div className="collapsible-panel-body">
                    <div className="image-instructions-box">
                        <div className="bold-text">
                            <FormattedMessage id="Settings.RestaurantImagesListingPageInfo" />{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={SettingsHelper.getRestaurantMicrosite(diaryContext.micrositeName)}
                            >
                                {SettingsHelper.getRestaurantMicrosite(diaryContext.micrositeName)}
                            </a>
                        </div>
                        <div>
                            <FormattedMessage id="Settings.RestaurantImagesMainImageInfo" />
                        </div>
                        <div>
                            <FormattedMessage id="Settings.RestaurantImagesUploadingLimitInstruction" />
                        </div>
                    </div>
                    {!isMobileView && (
                        <div className="filter-bar">
                            <div className="row-section">
                                <CustomInput
                                    id={'select-all'}
                                    type="checkbox"
                                    label={`Select all (${images.filter((i) => i.isSelected).length}/${
                                        images.length
                                    } selected)`}
                                    onChange={() => toggleSelectAllImages()}
                                    checked={images.every((i) => i.isSelected) && images.length !== 0}
                                    className="select-images-filter"
                                />
                                <CustomInput
                                    checked={getSelectAllImagesCheckedStatus(imagesEnabledStatus.allActive)}
                                    onChange={() => enableSelectedImages()}
                                    inline
                                    type="radio"
                                    id={2}
                                    name={'customRadio'}
                                    label={intl.formatMessage({
                                        id: 'Settings.Active',
                                    })}
                                    disabled={!images.find((i) => i.isSelected && !i.isMainImage)}
                                />
                                <CustomInput
                                    checked={getSelectAllImagesCheckedStatus(imagesEnabledStatus.allDisabled)}
                                    onChange={() => disableSelectedImages()}
                                    inline
                                    type="radio"
                                    id={3}
                                    name={'customRadio'}
                                    label={intl.formatMessage({
                                        id: 'Settings.Inactive',
                                    })}
                                    disabled={!images.find((i) => i.isSelected && !i.isMainImage)}
                                />
                                <Button
                                    className="delete-button"
                                    onClick={() => deleteSelectedImages()}
                                    disabled={!images.find((i) => i.isSelected && !i.isMainImage)}
                                >
                                    <DeleteIcon />
                                    <FormattedMessage id="Common.Delete" />
                                </Button>
                                <div className="total-images-count bold-text">
                                    <FormattedMessage
                                        id="Settings.TotalImagesUploaded"
                                        values={{ imagesCount: `${images.length}/30` }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {isMobileView && (
                        <div className="filter-bar-mob">
                            <div className="py-3">
                                <CustomInput
                                    id={'select-all'}
                                    type="checkbox"
                                    label={`Select all (${images.filter((i) => i.isSelected).length}/${
                                        images.length
                                    } selected)`}
                                    onChange={() => toggleSelectAllImages()}
                                    checked={images.every((i) => i.isSelected) && images.length !== 0}
                                    className="select-images-filter"
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <CustomInput
                                        checked={false}
                                        onChange={enableSelectedImages}
                                        inline
                                        type="radio"
                                        id={2}
                                        name={'customRadio'}
                                        label={intl.formatMessage({
                                            id: 'Settings.Active',
                                        })}
                                        disabled={!images.find((i) => i.isSelected && !i.isMainImage)}
                                    />
                                    <CustomInput
                                        checked={false}
                                        onChange={disableSelectedImages}
                                        inline
                                        type="radio"
                                        id={3}
                                        name={'customRadio'}
                                        label={intl.formatMessage({
                                            id: 'Settings.Inactive',
                                        })}
                                        disabled={!images.find((i) => i.isSelected && !i.isMainImage)}
                                    />
                                </div>
                                <div>
                                    <Button
                                        className="delete-button py-3 px-2 bg-white"
                                        onClick={() => deleteSelectedImages()}
                                        disabled={!images.find((i) => i.isSelected && !i.isMainImage)}
                                    >
                                        <DeleteIcon />
                                        <FormattedMessage id="Common.Delete" />
                                    </Button>
                                </div>
                            </div>
                            <div className="py-3">
                                <FormattedMessage
                                    id="Settings.TotalImagesUploaded"
                                    values={{ imagesCount: `${images.length}/30` }}
                                />
                            </div>
                        </div>
                    )}

                    <div className="image-container">
                        <ImageUploading
                            multiple
                            value={images}
                            onChange={onImagesChange}
                            dataURLKey="data_url"
                            maxFileSize={5000000}
                            maxNumber={30}
                            acceptType={['jpg', 'jpeg', 'gif', 'png']}
                        >
                            {({ imageList, onImageUpload }) => (
                                <Fragment>
                                    <div className="provider-images-wrapper">
                                        {imageList.map((image) => (
                                            <ProviderImageCard
                                                key={image.uniqueIdentifier}
                                                image={image}
                                                toggleImageActiveStatus={toggleImageActiveStatus}
                                                toggleImageSelected={toggleImageSelected}
                                                deleteImage={deleteImage}
                                                setAsMainImage={setAsMainImage}
                                                isDeleting={isDeleting}
                                            />
                                        ))}
                                    </div>
                                    <Button block className="btn-dashed" color="default" onClick={onImageUpload}>
                                        <FormattedMessage id="Settings.UploadImages" /> +
                                    </Button>
                                </Fragment>
                            )}
                        </ImageUploading>
                    </div>
                </div>
            </Section>

            {improveListingTasksCompleteModalOpen && (
                <ImproveListingTasksCompleteModal
                    isModalOpen={improveListingTasksCompleteModalOpen}
                    setIsModalOpen={setImproveListingTasksCompleteModalOpen}
                />
            )}
        </div>
    );
}

RestaurantImagesTab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
};

export default RestaurantImagesTab;
