import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class DeploymentService {
    static getAllDeployments() {
        return ApiHelper.get(`${SettingsHelper.getApiUrl()}/api/Tablet/v1/GetAllDeployments`).then(
            (response) => response.data
        );
    }
}

export default DeploymentService;
