import React, { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typeahead from './Typeahead';
import useDebounce from '../../hooks/useDebounce';
import infoBarType from '../../enums/infoBarType';
import InfoBars from '../common/InfoBars';
import propTypeExtensions from '../../helpers/PropTypeExtensions';

let _ID = 0;

function TypeaheadWrapper(props) {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [infoBars, setInfoBars] = useState([]);
    const debouncedSearchTerm = useDebounce(searchTerm);

    const startSearching = () => {
        setIsSearching(true);
        setResults([]);
    };

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const requestFunctionAsyncProp = props.requestFunctionAsync;
    const processResultsFunctionProp = props.processResultsFunction;
    const requestFunction = useCallback(
        (searchTerm) => {
            return requestFunctionAsyncProp(searchTerm);
        },
        [requestFunctionAsyncProp]
    );

    const processResultsFunction = useCallback(
        (response) => {
            return processResultsFunctionProp(response);
        },
        [processResultsFunctionProp]
    );

    const addErrorBarProp = props.addErrorBar;

    useEffect(() => {
        if (debouncedSearchTerm.length >= 3) {
            requestFunction(debouncedSearchTerm)
                .then((response) => {
                    const results = processResultsFunction(response);
                    setResults(results);
                })
                .catch(() => {
                    if (addErrorBarProp) {
                        addErrorBarProp();
                    } else {
                        addErrorBar();
                    }
                })
                .finally(() => {
                    setIsSearching(false);
                });
        }
    }, [debouncedSearchTerm, addErrorBar, requestFunction, processResultsFunction, addErrorBarProp]);

    useEffect(() => {
        if (searchTerm.length >= 3) {
            startSearching();
        }
    }, [searchTerm]);

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <Typeahead
                results={results}
                setSelectedResult={props.typeaheadItemSelected}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                isSearching={isSearching}
                addNew={props.allowAddNew}
                addNewFunction={props.addNewFunction}
                addNewTitle={props.addNewTitle}
                registerValidation={props.registerValidation}
                errors={props.errors}
                includeValidation={props.includeValidation}
                placeholder={props.placeholder}
                validationMessage={props.validationMessage}
                validationName={props.validationName}
                disabled={props.disabled}
                useDangerouslySetHTML={props.useDangerouslySetHTML}
                autoFocus={props.autoFocus}
            />
        </Fragment>
    );
}

TypeaheadWrapper.propTypes = {
    typeaheadItemSelected: PropTypes.func.isRequired,
    //a function to make the request for results. Should return a promise and accept a string argument (the search term)
    requestFunctionAsync: PropTypes.func.isRequired,
    //a function for processing the results of the request
    processResultsFunction: PropTypes.func.isRequired,
    allowAddNew: PropTypes.bool,
    addNewFunction: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.allowAddNew),
    addNewTitle: propTypeExtensions.requiredIf(PropTypes.string, (props) => props.allowAddNew),
    disabled: PropTypes.bool,
    useDangerouslySetHTML: PropTypes.bool,
    autoFocus: PropTypes.bool,
    addErrorBar: PropTypes.func,
};

export default TypeaheadWrapper;
