import ApiHelper from '../helpers/ApiHelper';

class AccountSubmissionService {
    static createStripeSubscription(
        emailAddress,
        alpha2code,
        campaignCode,
        paymentMethodId,
        promotionCodeId,
        apiToken
    ) {
        return ApiHelper.postJSON(
            `/api/AccountSubscription/CreateStripeSubscription`,
            JSON.stringify({ emailAddress, alpha2code, campaignCode, paymentMethodId, promotionCodeId, apiToken })
        ).then((response) => response.data);
    }

    static getStripeCheckoutSetupInformation(alpha2Code) {
        return ApiHelper.post(
            `/api/AccountSubscription/GetStripeCheckoutSetupInformation?alpha2Code=${alpha2Code}`
        ).then((response) => response.data);
    }

    static getCountries() {
        return ApiHelper.get(`/api/AccountSubscription/GetCountries`).then((response) => response.data);
    }

    static createUserAccount(account) {
        return ApiHelper.postJSON(`/api/AccountSubscription/CreateUserAccount`, JSON.stringify(account)).then(
            (response) => response.data
        );
    }

    static getAccountSubscriptionByApiToken(apiToken) {
        return ApiHelper.get(`/api/AccountSubscription/GetAccountSubscriptionByApiToken?apiToken=${apiToken}`).then(
            (response) => response.data
        );
    }

    static getAccountSubscriptionByProviderId(providerId, deploymentId) {
        return ApiHelper.get(
            `/api/AccountSubscription/GetAccountSubscriptionByProviderId?providerId=${providerId}&deploymentId=${deploymentId}`
        ).then((response) => response.data);
    }

    static getSubscriptionDetails(apiToken, campaignCode) {
        return ApiHelper.get(
            `/api/AccountSubscription/GetSubscriptionDetails?apiToken=${apiToken}&campaignCode=${campaignCode}   `
        ).then((response) => response.data);
    }

    static createSubscriptionCancellationRequest(cancellationRequest) {
        return ApiHelper.postJSON(
            `/api/AccountSubscription/SubscriptionCancellationRequest`,
            JSON.stringify(cancellationRequest)
        ).then((response) => response.data);
    }

    static validatePromoCode(promoCode, alpha2Code, currentDateTime) {
        return ApiHelper.post(
            `/api/AccountSubmission/ValidatePromoCode?promoCode=${promoCode}&alpha2Code=${alpha2Code}&currentDateTime=${currentDateTime}`
        ).then((response) => response.data);
    }
}

export default AccountSubmissionService;
