import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, CustomInput } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import MomentHelper from '../../../helpers/MomentHelper';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import useScreenSize from '../../../hooks/useScreenSize';
import serviceUpdateModalType from '../../../enums/serviceUpdateModalType';

function ServiceEntry(props) {
    const intl = useIntl();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { isMobileView } = useScreenSize();

    function toggleTooltip() {
        setTooltipOpen(!tooltipOpen);
    }

    function getFormattedTime(time) {
        let dateTime = MomentHelper.createDateTimeWithTime(time);
        return MomentHelper.formatted24HourTimeFormat(dateTime);
    }

    function getValidityPeriodText() {
        return getFormattedTime(props.service.timeFrom) + ' - ' + getFormattedTime(props.service.timeUntil);
    }

    function getMaxPerInterval() {
        if (props.service.maxCovers) {
            let labelId;
            if (props.service.maxCovers > 1) {
                labelId = 'Settings.MaxCoversPerInterval';
            } else {
                labelId = 'Settings.MaxCoverPerInterval';
            }
            return <FormattedMessage id={labelId} values={{ maxCovers: props.service.maxCovers }} />;
        } else if (props.service.maxBookings) {
            let labelId;
            if (props.service.maxBookings > 1) {
                labelId = 'Settings.MaxBookingsPerInterval';
            } else {
                labelId = 'Settings.MaxBookingPerInterval';
            }
            return <FormattedMessage id={labelId} values={{ maxBookings: props.service.maxBookings }} />;
        }
        return '';
    }

    function openServiceUpdateModal(updateType, disable) {
        if (updateType === serviceUpdateModalType.delete) {
            if (!props.isInitialSetup) {
                toggleTooltip();
                props.setUpdateType(updateType);
                props.openModalWithUpdatedService(props.service);
            } else {
                props.deleteService(props.service.id);
            }
        } else {
            if (disable) {
                props.setUpdateType(updateType);
                props.openModalWithUpdatedService(props.service);
            } else {
                props.updateServiceEnabledService(props.service.id);
            }
        }
    }

    function getButtons() {
        return (
            <Fragment>
                {isMobileView && (
                    <div className="row-element mob service-interval">
                        <div className="mb-1">{getTimeInterval()}</div>
                        <div>{getMaxPerInterval()}</div>
                    </div>
                )}
                <div
                    className={isMobileView ? 'row-element clickable mob' : 'row-element clickable'}
                    onClick={() => props.toggleEditing()}
                >
                    <EditIcon />
                </div>
                <div className={isMobileView ? 'row-element clickable delete mob' : 'row-element clickable delete'}>
                    <Button
                        id={'deleteRowButton' + props.service.id.toString()}
                        onClick={() => openServiceUpdateModal(serviceUpdateModalType.delete)}
                        data-testid="delete-icon-table-label"
                    >
                        <DeleteIcon />
                    </Button>
                </div>
            </Fragment>
        );
    }

    function getTimeInterval() {
        return (
            <FormattedMessage id="Settings.ServiceTimeInterval" values={{ interval: props.service.timeSlotInterval }} />
        );
    }

    function getList() {
        return (
            <Fragment>
                {!isMobileView && (
                    <div className="row-element service-interval">
                        <div className="mb-1">{getTimeInterval()}</div>
                        <div>{getMaxPerInterval()}</div>
                    </div>
                )}
                {!props.isInitialSetup && (
                    <div className={isMobileView ? 'row-element mob custom-controls-mobile services' : 'row-element'}>
                        <CustomInput
                            checked={props.service.enabled}
                            onChange={() => openServiceUpdateModal(serviceUpdateModalType.disable, false)}
                            inline
                            type="radio"
                            id={'active ' + props.service.id}
                            name={'customRadio' + props.service.id}
                            label={intl.formatMessage({
                                id: 'Settings.Active',
                            })}
                            disabled={props.awaitingIsEnabledRequest}
                        />
                        <CustomInput
                            checked={!props.service.enabled}
                            onChange={() => openServiceUpdateModal(serviceUpdateModalType.disable, true)}
                            inline
                            type="radio"
                            id={'disabled ' + props.service.id}
                            name={'customRadio' + props.service.id}
                            label={intl.formatMessage({
                                id: 'Settings.Inactive',
                            })}
                            disabled={props.awaitingIsEnabledRequest}
                        />
                    </div>
                )}

                {isMobileView && <div className="mobile-services-row">{getButtons()}</div>}
                {!isMobileView && <Fragment>{getButtons()}</Fragment>}
            </Fragment>
        );
    }

    return (
        <div key={props.service.id} className="service-item">
            <div className={isMobileView ? 'service-entry mob' : 'service-entry'}>
                <div className={isMobileView ? 'mr-auto service-description mob' : 'mr-auto service-description'}>
                    <div className={isMobileView ? 'bold-text pb-1' : 'bold-text mr-2'}>{props.service.name}</div>
                    <div>{getValidityPeriodText()}</div>
                </div>
                {getList()}
            </div>
        </div>
    );
}

ServiceEntry.propTypes = {
    service: PropTypes.object.isRequired,
    toggleEditing: PropTypes.func.isRequired,
    deleteService: PropTypes.func.isRequired,
    openModalWithUpdatedService: PropTypes.func.isRequired,
    updateServiceEnabledStatus: PropTypes.func.isRequired,
    setUpdateType: PropTypes.func.isRequired,
    isInitialSetup: PropTypes.bool,
    updateServiceEnabledService: PropTypes.func.isRequired,
    awaitingIsEnabledRequest: PropTypes.bool,
};

export default ServiceEntry;
