import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class AccountService {
    static authenticateUser(username, password) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Jwt/v2/Authenticate`,
            JSON.stringify({ username: username, password: password })
        );
    }

    static forgotPassword(username) {
        return ApiHelper.postJSON(`/api/Account/ForgotPassword`, JSON.stringify({ username: username }));
    }

    static resetPassword(token, password) {
        return ApiHelper.postJSON(`/api/Account/ResetPassword`, JSON.stringify({ token: token, password: password }));
    }

    static checkPasswordResetToken(token) {
        return ApiHelper.postJSON(`/api/Account/CheckPasswordResetToken`, JSON.stringify({ token: token }));
    }

    static usernameIsValid(username) {
        return ApiHelper.get(`/api/Account/UsernameIsValid?username=${encodeURIComponent(username)}`).then(
            (response) => response.data
        );
    }

    static deleteAPIToken(apiToken) {
        return ApiHelper.post(`/api/Account/DeleteApiToken?apiToken=${apiToken}`).then((response) => response.data);
    }
}

export default AccountService;
