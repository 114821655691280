import React, { useState, Fragment } from 'react';
import Typeahead from '../common/Typeahead';

function TypeaheadExamples() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedResult, setSelectedResult] = useState(null);
    const mockedResults = [
        { id: 1, display: 'Ross Anderson' },
        { id: 2, display: 'Iain Briskham' },
        {
            id: 3,
            display: 'Iain Johnson',
            alternateDisplay: 'Development Manager',
        },
        { id: 4, display: 'Abdul Ashraf' },
        { id: 5, display: 'David Haughton' },
        { id: 6, display: 'Sid (Jonathan) Saje' },
    ];
    let result = mockedResults.find((obj) => {
        return obj.id === selectedResult;
    });

    let filteredResults =
        searchTerm && searchTerm !== ''
            ? mockedResults.filter((obj) => {
                  return obj.display.toLowerCase().includes(searchTerm.toLowerCase());
              })
            : mockedResults;

    return (
        <Fragment>
            <br></br>
            <br></br>
            <h3>Typeahead</h3>
            <Typeahead
                results={filteredResults}
                setSelectedResult={setSelectedResult}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                isSearching={false}
            />
            <div>Selected Result: {result ? result.display : 'N/A'}</div>
        </Fragment>
    );
}

export default TypeaheadExamples;
