import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Label } from 'reactstrap';
import LoginForm from './LoginForm';

function LoginPage(props) {
    return (
        <Fragment>
            <Container className="login-page">
                <LoginForm login={props.login} setLoading={props.setLoading} />
            </Container>
            <div className="version-number">
                <Label>{props.versionNumber}</Label>
            </div>
        </Fragment>
    );
}

LoginPage.propTypes = {
    versionNumber: PropTypes.string.isRequired,
};

export default LoginPage;
