import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageUploading from 'react-images-uploading';
import { ReactComponent as UploadIcon } from '../../content/icons/Upload.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import ValidationMessage from './ValidationMessage';
import { Button } from 'reactstrap';

function ImageUpload(props) {
    const intl = useIntl();
    function getImageErrorMessage(errors) {
        if (!errors) {
            return null;
        }

        if (errors.maxFileSize) {
            return <ValidationMessage message={intl.formatMessage({ id: 'Settings.FileValidationSize' })} />;
        }

        if (errors.acceptType) {
            return <ValidationMessage message={intl.formatMessage({ id: 'Settings.FileValidationType' })} />;
        }
    }

    function getImageFileSize(file) {
        if (file && file.size) {
            return `(${(file.size / 1024).toFixed(2)}KB)`;
        }
        return '';
    }

    return (
        <ImageUploading
            value={props.images}
            onChange={props.onChange}
            dataURLKey="data_url"
            maxFileSize={5000000}
            acceptType={['jpg', 'jpeg', 'gif', 'png']}
        >
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps, errors }) => (
                <Fragment>
                    <div className="upload-image-wrapper">
                        <div
                            className={`image-item clickable ${imageList.length === 0 ? 'empty' : ''}`}
                            style={isDragging ? { borderColor: 'green' } : null}
                            onClick={() => (imageList.length > 0 ? onImageUpdate(0) : onImageUpload())}
                            {...dragProps}
                        >
                            {imageList.length === 0 && (
                                <div className="upload-icon-container">
                                    <UploadIcon />
                                    <div>
                                        <FormattedMessage id="Settings.EmptyFileText" />
                                    </div>
                                </div>
                            )}
                            {imageList.map((image, index) => (
                                <img key={index} src={image.data_url} alt="" width="128" height="128" />
                            ))}
                        </div>
                        <div className="image-text">
                            <div className="bold-text">{props.title}</div>
                            <div className="image-instruction">{props.instruction}</div>
                            <div>
                                {imageList.length > 0 && (
                                    <div>
                                        <div className="image-text-size d-inline">{`${
                                            imageList[0].file.name
                                        } ${getImageFileSize(imageList[0].file)}`}</div>
                                        <div className="fake-link d-inline" onClick={() => onImageRemove(0)}>
                                            <FormattedMessage id="Common.Remove" />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="image-text-footer">
                                <Button
                                    color="outline-primary"
                                    onClick={() => (imageList.length > 0 ? onImageUpdate(0) : onImageUpload())}
                                    id="create-menu"
                                >
                                    <FormattedMessage id="Common.SelectAFile" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    {getImageErrorMessage(errors)}
                </Fragment>
            )}
        </ImageUploading>
    );
}

ImageUpload.propTypes = {
    images: PropTypes.array,
    onChange: PropTypes.func,
    title: PropTypes.string,
    instruction: PropTypes.string,
};

export default ImageUpload;
