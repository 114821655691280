class Facility {
    constructor(dto) {
        this.value = dto && dto.Id ? dto.Id : '';
        this.text = dto && dto.Name ? dto.Name : '';
        this.nameResource = dto && dto.NameResource ? dto.NameResource : '';
    }

    getDTO(value, text, nameResource) {
        return {
            Id: value,
            Name: text,
            NameResource: nameResource,
        };
    }
}

export default Facility;
