import React, { Fragment } from 'react';
import DatePicker from '../common/DatePicker';
import MomentHelper from '../../helpers/MomentHelper';

class DatePickerExamples extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: MomentHelper.newInstance(),
        };
        this.onDateChange = this.onDateChange.bind(this);
        this.incrementDate = this.incrementDate.bind(this);
        this.decrementDate = this.decrementDate.bind(this);
        this.goToToday = this.goToToday.bind(this);
    }

    onDateChange(date) {
        this.setState({
            date: date,
        });
    }

    incrementDate() {
        this.setState((prevState) => {
            prevState.date.add(1, 'days');
            return { date: prevState.date };
        });
    }

    decrementDate() {
        this.setState((prevState) => {
            prevState.date.subtract(1, 'days');
            return { date: prevState.date };
        });
    }

    goToToday() {
        this.setState({
            date: MomentHelper.newInstance(),
        });
    }

    render() {
        return (
            <Fragment>
                <br></br>
                <br></br>
                <h3>DatePicker</h3>
                <div className="date-picker-wrapper">
                    <DatePicker
                        onDateChange={(date) => this.onDateChange(date)}
                        incrementDate={this.incrementDate}
                        decrementDate={this.decrementDate}
                        goToToday={this.goToToday}
                        date={this.state.date}
                        skeletonWidth={175}
                        allowTodayAndFutureDatesOnly={true}
                    />
                </div>
            </Fragment>
        );
    }
}

export default DatePickerExamples;
