class SettingsHelper {
    static setApiUrl(url) {
        sessionStorage.setItem('apiUrl', url);
    }

    static getApiUrl() {
        return sessionStorage.getItem('apiUrl');
    }

    static setPortalUrl(url) {
        sessionStorage.setItem('portalUrl', url);
    }

    static getPortalUrl() {
        return sessionStorage.getItem('portalUrl');
    }

    static setSquareApplicationId(applicationId) {
        sessionStorage.setItem('squareApplicationId', applicationId);
    }

    static getSquareApplicationId() {
        return sessionStorage.getItem('squareApplicationId');
    }

    static setSquareUrl(url) {
        sessionStorage.setItem('squareUrl', url);
    }

    static getSquareUrl() {
        return sessionStorage.getItem('squareUrl');
    }

    static setWidgetUrl(url) {
        sessionStorage.removeItem('widgetUrl');
        sessionStorage.setItem('widgetUrl', url);
    }

    static getWidgetUrl() {
        return sessionStorage.getItem('widgetUrl');
    }

    static getRestaurantMicrosite(micrositeName) {
        return `${SettingsHelper.getPortalUrl()}${
            SettingsHelper.getPortalUrl().endsWith('/') ? '' : '/'
        }restaurant/${micrositeName}`;
    }

    //full url without the scheme/protocol
    static getShortenedRestaurantMicrosite(micrositeName) {
        const fullUrl = SettingsHelper.getRestaurantMicrosite(micrositeName);
        if (fullUrl.startsWith('https://')) return fullUrl.slice(8);
        else if (fullUrl.startsWith('http://')) return fullUrl.slice(7);
        else return fullUrl;
    }

    static getRestaurantMenuUrl(micrositeName) {
        return `${SettingsHelper.getPortalUrl()}restaurant/${micrositeName}/menu/`;
    }

    static setContactUsUrl(url) {
        sessionStorage.setItem('contactUsUrl', url);
    }

    static getContactUsUrl() {
        return sessionStorage.getItem('contactUsUrl');
    }

    static setGoogleApiKey(key) {
        sessionStorage.setItem('googleApiKey', key);
    }

    static getGoogleApiKey() {
        return sessionStorage.getItem('googleApiKey');
    }

    static setSegmentAnonymousId(id) {
        sessionStorage.setItem('segmentAnonId', id);
    }

    static getSegmentAnonymousId() {
        return sessionStorage.getItem('segmentAnonId');
    }

    static setDomain(domain) {
        sessionStorage.setItem('domain', domain);
    }

    static getDomain() {
        return sessionStorage.getItem('domain');
    }

    static setUseLiveStripe(useLiveStripe) {
        sessionStorage.setItem('useLiveStripe', useLiveStripe);
    }

    static getUseLiveStripe() {
        return sessionStorage.getItem('useLiveStripe');
    }

    static getVouchersPortalBaseUrl() {
        return sessionStorage.getItem('vouchersPortalBaseUrl');
    }

    static setVouchersPortalBaseUrl(url) {
        sessionStorage.setItem('vouchersPortalBaseUrl', url);
    }

    static getVouchersPortalMicrositeUrl(micrositeName) {
        return `${SettingsHelper.getVouchersPortalBaseUrl()}/${micrositeName}`;
    }

    static getVouchersPortalPreviewUrl(micrositeName) {
        return `${SettingsHelper.getVouchersPortalBaseUrl()}/${micrositeName}/preview`;
    }

    static getLoginBaseUrl() {
        return sessionStorage.getItem('loginBaseUrl');
    }

    static setLoginBaseUrl(url) {
        sessionStorage.setItem('loginBaseUrl', url);
    }

    static getHasFacebookIntegration() {
        return sessionStorage.getItem('hasFacebookIntegration') === 'true';
    }

    static setHasFacebookIntegration(value) {
        sessionStorage.setItem('hasFacebookIntegration', value);
    }

    static setDishCultSupportEmail(value) {
        sessionStorage.setItem('dishCultSupportEmail', value);
    }

    static getDishCultSupportEmail() {
        return sessionStorage.getItem('dishCultSupportEmail');
    }

    static setQSRSubscriptionProductId(value) {
        sessionStorage.setItem('qsrSubscriptionProductId', value);
    }

    static getQSRSubscriptionProductId() {
        return sessionStorage.getItem('qsrSubscriptionProductId');
    }
}
export default SettingsHelper;
