import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import TableHeadingOption from '../common/TableHeadingOption';
import TableHeader from '../common/TableHeader';
import CurrencyDiv from '../common/CurrencyDiv';
import voucherReviewTableColumns from '../../enums/voucherReviewTableColumns';
import { useIntl, FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import DiaryContext from '../../contexts/DiaryContext';
import useScreenSize from '../../hooks/useScreenSize';

function VoucherGenerateModalReviewTable(props) {
    const diaryContext = useContext(DiaryContext);
    const { isMobileView } = useScreenSize();
    const intl = useIntl();
    function getColumnHeadings() {
        return getHeadingOptions().map((headingOption, index) => {
            return (
                <TableHeader
                    index={index}
                    sortable={false}
                    key={headingOption.heading}
                    heading={headingOption.heading}
                />
            );
        });
    }

    function getHeadingOptions() {
        if (isMobileView) {
            return [];
        }
        return [
            new TableHeadingOption(
                false,
                intl.formatMessage({ id: 'Common.Voucher' }),
                voucherReviewTableColumns.VoucherName
            ),
            new TableHeadingOption(
                false,
                intl.formatMessage({ id: 'Vouchers.RecipientName' }),
                voucherReviewTableColumns.RecipientName
            ),
            new TableHeadingOption(
                false,
                intl.formatMessage({ id: 'Vouchers.SendTo' }),
                voucherReviewTableColumns.RecipientEmail
            ),
            new TableHeadingOption(
                false,
                intl.formatMessage({ id: 'Vouchers.Subtotal' }),
                voucherReviewTableColumns.SubTotal
            ),
        ];
    }

    function getVoucher(voucherId) {
        return props.vouchers.find((v) => v.id === voucherId);
    }

    function getVoucherEntries() {
        return props.vouchersToGenerate.map((voucher, i) => {
            const voucherObject = getVoucher(voucher.id);

            if (isMobileView) {
                return (
                    <tr key={i}>
                        <td>
                            <div>
                                <div className="d-flex bold-text text-larger">
                                    <span>
                                        {`${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)}${
                                            voucherObject.cost
                                        } ${voucherObject.title}`}{' '}
                                        x {voucher.quantity}
                                    </span>
                                    <span className="ml-auto">
                                        <CurrencyDiv price={voucherObject.cost * voucher.quantity} />
                                    </span>
                                </div>
                                <div className="bold-text mt-2 upper-case">
                                    <FormattedMessage id="Vouchers.Recipient" />:
                                </div>
                                <div className="mt-1">
                                    {voucher.receipientEmail
                                        ? `${voucher.receipientName} (${voucher.receipientEmail})`
                                        : voucher.receipientName}
                                </div>
                            </div>
                        </td>
                    </tr>
                );
            }

            return (
                <tr key={i}>
                    <td>
                        {`${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)}${
                            voucherObject.cost
                        } ${voucherObject.title}`}{' '}
                        x {voucher.quantity}
                    </td>
                    <td>{voucher.receipientName}</td>
                    <td>
                        {voucher.receipientEmail ? voucher.receipientEmail : intl.formatMessage({ id: 'Common.None' })}
                    </td>
                    <td>
                        <CurrencyDiv price={voucherObject.cost * voucher.quantity} />
                    </td>
                </tr>
            );
        });
    }

    return (
        <Fragment>
            <div>
                <Table striped>
                    <thead>
                        <tr>{getColumnHeadings()}</tr>
                    </thead>
                    <tbody>{getVoucherEntries()}</tbody>
                </Table>
            </div>
        </Fragment>
    );
}

VoucherGenerateModalReviewTable.propTypes = {
    vouchersToGenerate: PropTypes.array.isRequired,
    vouchers: PropTypes.array.isRequired,
};

export default VoucherGenerateModalReviewTable;
