import React, { Fragment, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import ListBuilder from '../../../common/ListBuilder';
import ImproveListingTasksCompleteModal from '../../../common/ImproveListingTasksCompleteModal';
import MenuListingCreationPanel from './MenuListingCreationPanel';
import MenuListingEntryWrapper from './MenuListingEntryWrapper';
import MenuListing from '../../../../domainObjects/MenuListing';
import SettingsService from '../../../../services/SettingsService';
import DiaryContext from '../../../../contexts/DiaryContext';
import ProviderProgressFlagsContext from '../../../../contexts/ProviderProgressFlagsContext';
import PropTypes from 'prop-types';
import providerProgressFlags from '../../../../enums/providerProgressFlags';
import AnalyticsHelper from '../../../../helpers/AnalyticsHelper';

function MenuListingsListBuilder(props) {
    const intl = useIntl();
    const [menuListings, updateMenuListings] = useState(getMenuListings());
    const [isAddingNewMenu, setIsAddingNewMenu] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const diaryContext = useContext(DiaryContext);
    const [improveListingTasksCompleteModalOpen, setImproveListingTasksCompleteModalOpen] = useState(false);
    const providerProgressFlagsContext = useContext(ProviderProgressFlagsContext);

    const toggleAddNewMenu = () => {
        setIsAddingNewMenu(!isAddingNewMenu);
    };

    function getMenuListings() {
        return props.menus.map((menu) => new MenuListing(menu));
    }

    function updateMenuEnabledStatus(menuId, isEnabled) {
        const menus = [...menuListings];
        const index = menus.findIndex((menu) => menu.id === menuId);
        if (index >= 0) {
            menus[index].isEnabled = isEnabled;
        }
        updateMenuListings(menus);
    }

    function configureMenuToBeSaved(menu, file) {
        menu.isEnabled = true;

        let data = new FormData();
        data.append('menuDetails', JSON.stringify(menu));
        data.append(file, file ? file : null);
        return data;
    }

    function getAnalyticsMenuProperties(newMenu) {
        const menuProperties = [];
        const menuListingsCopy = [...menuListings, newMenu];
        menuListingsCopy.forEach((menu) => {
            menuProperties.push({
                menuName: menu.name,
                menuType: menu.type,
                menuUrl: menu.menuUrl,
                menuLanguage: menu.cultureCode,
            });
        });
        return { restaurantMenus: menuProperties };
    }

    function saveMenuDetails(menu, file, callback) {
        const data = configureMenuToBeSaved(menu, file);
        setIsSaving(true);
        let newMenuListing = {};
        SettingsService.SaveMenuDetails(diaryContext.deploymentId, diaryContext.restaurantId, data)
            .then((response) => {
                newMenuListing = new MenuListing(response);
                if (menu.id > 0) {
                    let menuListingsCopy = [...menuListings];
                    let index = menuListingsCopy.findIndex((m) => m.id === response.Id);
                    menuListingsCopy[index] = newMenuListing;
                    updateMenuListings(menuListingsCopy);

                    props.addSuccessBar(
                        intl.formatMessage({
                            id: 'Common.ItemUpdatedInfoBarSuccessMessage',
                        })
                    );
                } else {
                    updateMenuListings([...menuListings, newMenuListing]);
                    toggleAddNewMenu();
                    if (!providerProgressFlagsContext.uploadedMenu) {
                        providerProgressFlagsContext.updatedProviderProgressFlag(providerProgressFlags.uploadedMenu);
                    }
                    if (
                        providerProgressFlagsContext.addedAdvancedListing &&
                        providerProgressFlagsContext.addedAdditionalImage &&
                        !providerProgressFlagsContext.dismissedCompletionModal
                    ) {
                        setImproveListingTasksCompleteModalOpen(true);
                    }

                    props.addSuccessBar(
                        intl.formatMessage({
                            id: 'Common.ItemAddedInfoBarSuccessMessage',
                        })
                    );
                }
                if (callback) {
                    callback();
                }
            })
            .catch(() => {
                props.addErrorBar();
            })
            .finally(() => {
                AnalyticsHelper.trackClickWithProperties('Web Update Menu', getAnalyticsMenuProperties(newMenuListing));
                AnalyticsHelper.identifyUserIdWithProperties(
                    diaryContext.restaurantId,
                    getAnalyticsMenuProperties(newMenuListing)
                );
                setIsSaving(false);
            });
    }

    function deleteMenuDetail(menuId) {
        SettingsService.DeleteMenuDetail(diaryContext.deploymentId, diaryContext.restaurantId, menuId)
            .then(() => {
                updateMenuListings(menuListings.filter((menu) => menu.id !== menuId));
                props.addSuccessBar(
                    intl.formatMessage({
                        id: 'Common.ItemRemovedInfoBarSuccessMessage',
                    })
                );
            })
            .catch(() => {
                props.addErrorBar();
            });
    }

    function getPageContent() {
        return (
            <Fragment>
                {menuListings.map((menu) => {
                    return (
                        <MenuListingEntryWrapper
                            key={menu.id}
                            menu={menu}
                            saveMenuDetails={saveMenuDetails}
                            deleteMenuDetail={deleteMenuDetail}
                            menuTypes={props.menuTypes}
                            cultureCodes={props.cultureCodes}
                            addSuccessBar={props.addSuccessBar}
                            addErrorBar={props.addErrorBar}
                            updateMenuEnabledStatus={updateMenuEnabledStatus}
                            existingMenus={menuListings}
                            isSaving={isSaving}
                        />
                    );
                })}

                {isAddingNewMenu && (
                    <MenuListingCreationPanel
                        toggleAddNewMenu={toggleAddNewMenu}
                        menu={new MenuListing()}
                        saveMenuDetails={saveMenuDetails}
                        existingMenus={menuListings}
                        menuTypes={props.menuTypes}
                        cultureCodes={props.cultureCodes}
                        isSaving={isSaving}
                    />
                )}

                {improveListingTasksCompleteModalOpen && (
                    <ImproveListingTasksCompleteModal
                        isModalOpen={improveListingTasksCompleteModalOpen}
                        setIsModalOpen={setImproveListingTasksCompleteModalOpen}
                    />
                )}
            </Fragment>
        );
    }

    return (
        <ListBuilder
            isAddingNewItem={isAddingNewMenu}
            toggleAddNewItem={toggleAddNewMenu}
            addNewItemButtonText={intl.formatMessage({ id: 'Settings.AddNewMenuButtonText' })}
            content={getPageContent()}
        />
    );
}

MenuListingsListBuilder.propTypes = {
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    menus: PropTypes.array.isRequired,
    menuTypes: PropTypes.array.isRequired,
    cultureCodes: PropTypes.array.isRequired,
};

export default MenuListingsListBuilder;
