import overlayType from '../enums/overlayType';

class BookingSummaryOverlayContent {
    constructor(booking) {
        this.booking = booking ? booking : null;
        this.overlayType = overlayType.booking;
    }
}

export default BookingSummaryOverlayContent;
