class VoucherHelper {
    static toJson(voucher) {
        return {
            VoucherId: voucher.id,
            Name: voucher.title,
            Description: voucher.description,
            Cost: voucher.cost,
            MinimumQuantity: voucher.minimumQuantity,
            MaximumQuantity: voucher.maximumQuantity,
            MonthsValid: voucher.monthsValid,
            Status: voucher.status,
            Available: voucher.available,
            TermsAndConditions: voucher.termsAndConditions,
            CreatedDate: voucher.createdDate,
        };
    }
}

export default VoucherHelper;
