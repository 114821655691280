import React, { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import HeaderBar from './HeaderBar';
import Body from './Body';
import MomentHelper from '../../helpers/MomentHelper';
import DiaryContext from '../../contexts/DiaryContext';
import UpdatePaymentModal from '../accountSubmission/UpdatePaymentModal';
import stripeSubscriptionStatus from '../../enums/stripeSubscriptionStatus';
import CookiesHelper from '../../helpers/CookiesHelper';

function Layout(props) {
    const diaryContext = useContext(DiaryContext);
    const [isUpdatePaymentsModalOpen, setIsUpdatePaymentsModalOpen] = useState(
        diaryContext.subscriptionStatus !== stripeSubscriptionStatus.active
    );

    const subscriptionStatusCookieName = 'SUB_STATUS';

    if (diaryContext.timeZone) {
        MomentHelper.setUp(null, null, diaryContext.timeZone);
    }

    function getSubscriptionStatusFromCookie() {
        return CookiesHelper.getCookie(subscriptionStatusCookieName);
    }

    function setSubscriptionStatusCookie() {
        CookiesHelper.setCookie(subscriptionStatusCookieName, true, {
            expires: MomentHelper.newInstance().add(1, 'days').toDate(),
            sameSite: true,
            secure: true,
        });
    }

    function closeModal() {
        setSubscriptionStatusCookie();
        setIsUpdatePaymentsModalOpen(!isUpdatePaymentsModalOpen);
    }

    function getUpdatePaymentsModal() {
        const hasCookie = getSubscriptionStatusFromCookie();

        if (diaryContext && diaryContext.stripeSubscriptionId && !hasCookie && isUpdatePaymentsModalOpen) {
            return <UpdatePaymentModal isModalOpen={isUpdatePaymentsModalOpen} dismissModal={closeModal} />;
        }

        return null;
    }

    return (
        <Fragment>
            {!props.hideHeaderBar && (
                <HeaderBar
                    auth={props.auth}
                    setIsAdminProviderSelectionVisible={props.setIsAdminProviderSelectionVisible}
                />
            )}
            <Body hideHeaderBar={props.hideHeaderBar}>{props.children}</Body>

            {getUpdatePaymentsModal()}
        </Fragment>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    hideHeaderBar: PropTypes.bool,
};
export default Layout;
