import React, { useState, useContext, Fragment, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, CustomInput } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import useValidation from '../../hooks/useValidation';
import DiaryContext from '../../contexts/DiaryContext';
import ValidationInput from '../common/ValidationInput';
import cancellationReasonRadioButtonValue from '../../enums/cancellationReasonRadioButtonValue';
import AccountSubmissionService from '../../services/AccountSubmissionService';
import ValidationMessage from '../common/ValidationMessage';

function CancelSubscriptionModal(props) {
    const intl = useIntl();
    const [otherReasonText, setOtherReasonText] = useState('');
    const [cancellationReason, setCancellationReason] = useState(null);
    const [cancellationEmailSent, setCancellationEmailSent] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const diaryContext = useContext(DiaryContext);

    function getCloseButton() {
        return (
            <button className="close" onClick={() => props.setIsModalOpen(false)}>
                &times;
            </button>
        );
    }

    const formValidation = useValidation();

    const validateCancellationReason = useCallback(
        (cancellationReason) => {
            if (!cancellationReason) {
                return intl.formatMessage({ id: 'Settings.CancellationReasonValidationMessage' });
            }
            return true;
        },
        [intl]
    );

    useEffect(() => {
        formValidation.register({ name: 'cancellationReason' }, { validate: validateCancellationReason });
    }, [formValidation, validateCancellationReason]);

    useEffect(() => {
        formValidation.setValue('cancellationReason', cancellationReason);
    }, [cancellationReason, formValidation]);

    function submitCancelRequest() {
        setIsCancelling(true);
        AccountSubmissionService.createSubscriptionCancellationRequest({
            ProviderId: diaryContext.restaurantId,
            ProviderName: diaryContext.restaurantName,
            CancellationReason: cancellationReason,
            OtherReason: otherReasonText,
        })
            .then(() => {
                setCancellationEmailSent(true);
            })
            .catch((e) => {
                props.addErrorBar(intl.formatMessage({ id: 'Settings.CancellationRequestErrorMessage' }));
            })
            .finally(() => {
                setIsCancelling(false);
            });
    }

    return (
        <Modal zIndex={1072} size="md" centered isOpen={props.isModalOpen} className="modal cancel-subscription-modal">
            <ModalHeader close={getCloseButton()}>
                {cancellationEmailSent ? (
                    <FormattedMessage id="Settings.SuccessfullyCancelledSubscriptionModalTitle" />
                ) : (
                    <FormattedMessage id="Settings.CancelationModalTitle" />
                )}
            </ModalHeader>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    formValidation.submit(submitCancelRequest);
                }}
            >
                <ModalBody>
                    {cancellationEmailSent && (
                        <FormattedMessage id="Settings.SuccessfullyCancelledSubscriptionModalBody" />
                    )}
                    {!cancellationEmailSent && (
                        <Fragment>
                            <div className="help-us-improve-text">
                                <FormattedMessage id="Settings.CancelationModalHelpUsImprove" />
                                <div className="validation-summary">
                                    {formValidation.errorCount > 0 && formValidation.errors['cancellationReason'] && (
                                        <ValidationMessage
                                            message={formValidation.errors['cancellationReason'].message}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column cancelation-reasons-container">
                                <CustomInput
                                    checked={
                                        cancellationReason === cancellationReasonRadioButtonValue.lackingFunctionality
                                    }
                                    onChange={() =>
                                        setCancellationReason(cancellationReasonRadioButtonValue.lackingFunctionality)
                                    }
                                    type="radio"
                                    id={'radio ' + cancellationReasonRadioButtonValue.lackingFunctionality}
                                    name={'customRadio' + cancellationReasonRadioButtonValue.lackingFunctionality}
                                    label={intl.formatMessage({
                                        id: 'Settings.CancelReasonLackingFunctionality',
                                    })}
                                />

                                <CustomInput
                                    checked={cancellationReason === cancellationReasonRadioButtonValue.tooExpensive}
                                    onChange={() =>
                                        setCancellationReason(cancellationReasonRadioButtonValue.tooExpensive)
                                    }
                                    type="radio"
                                    id={'radio ' + cancellationReasonRadioButtonValue.tooExpensive}
                                    name={'customRadio' + cancellationReasonRadioButtonValue.tooExpensive}
                                    label={intl.formatMessage({
                                        id: 'Settings.CancelReasonTooExpensive',
                                    })}
                                />

                                <CustomInput
                                    checked={cancellationReason === cancellationReasonRadioButtonValue.upgradeToRDF}
                                    onChange={() =>
                                        setCancellationReason(cancellationReasonRadioButtonValue.upgradeToRDF)
                                    }
                                    type="radio"
                                    id={'radio ' + cancellationReasonRadioButtonValue.upgradeToRDF}
                                    name={'customRadio' + cancellationReasonRadioButtonValue.upgradeToRDF}
                                    label={intl.formatMessage({
                                        id: 'Settings.CancelReasonUpgradeToRDF',
                                    })}
                                />

                                <CustomInput
                                    checked={cancellationReason === cancellationReasonRadioButtonValue.lackOfSupport}
                                    onChange={() =>
                                        setCancellationReason(cancellationReasonRadioButtonValue.lackOfSupport)
                                    }
                                    type="radio"
                                    id={'radio ' + cancellationReasonRadioButtonValue.lackOfSupport}
                                    name={'customRadio' + cancellationReasonRadioButtonValue.lackOfSupport}
                                    label={intl.formatMessage({
                                        id: 'Settings.CancelReasonLackOfSupport',
                                    })}
                                />

                                <CustomInput
                                    checked={cancellationReason === cancellationReasonRadioButtonValue.otherReason}
                                    onChange={() =>
                                        setCancellationReason(cancellationReasonRadioButtonValue.otherReason)
                                    }
                                    type="radio"
                                    id={'radio ' + cancellationReasonRadioButtonValue.otherReason}
                                    name={'customRadio' + cancellationReasonRadioButtonValue.otherReason}
                                    label={intl.formatMessage({
                                        id: 'Settings.CancelReasonOther',
                                    })}
                                />
                                <div className="other-reasons-text-area">
                                    <ValidationInput
                                        testId="otherReasonsText"
                                        type="textarea"
                                        name="otherReasonsText"
                                        value={otherReasonText}
                                        onChange={(e) => setOtherReasonText(e)}
                                        innerRef={formValidation.register({
                                            validate: (value) => {
                                                if (
                                                    cancellationReason ===
                                                        cancellationReasonRadioButtonValue.otherReason &&
                                                    (!value || !value.length)
                                                )
                                                    return intl.formatMessage({
                                                        id: 'Settings.CancelReasonOtherValidationMessage',
                                                    });
                                            },
                                        })}
                                        errors={formValidation.errors}
                                        rows="6"
                                        disabled={
                                            !(cancellationReason === cancellationReasonRadioButtonValue.otherReason)
                                        }
                                    />
                                </div>
                            </div>
                        </Fragment>
                    )}
                </ModalBody>
                <ModalFooter>
                    {cancellationEmailSent && (
                        <Fragment>
                            <Button color="primary" onClick={() => props.setIsModalOpen(false)}>
                                <FormattedMessage id="Common.Close" />
                            </Button>
                        </Fragment>
                    )}
                    {!cancellationEmailSent && (
                        <Fragment>
                            <Button
                                className="mr-2"
                                outline
                                color="primary"
                                onClick={() => props.setIsModalOpen(false)}
                            >
                                <FormattedMessage id="Settings.KeepMySubscription" />
                            </Button>
                            <input
                                type="submit"
                                value={
                                    isCancelling
                                        ? intl.formatMessage({ id: 'Common.Cancelling' })
                                        : intl.formatMessage({ id: 'Settings.CancelMySubscription' })
                                }
                                disabled={isCancelling}
                                className="btn btn-primary"
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Form>
        </Modal>
    );
}

CancelSubscriptionModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
};

export default CancelSubscriptionModal;
