import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class LoyaltyService {
    static getProviderTransactions(deploymentId, restaurantId, request) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Loyalty/ProviderTransactions`,
            request
        );
    }

    static getProviderRewardsDetails(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Loyalty/LoyaltyStatus`
        );
    }

    static addMIDToLocation(deploymentId, restaurantId, merchantId) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Loyalty/AddMIDToLocation`,
            merchantId
        );
    }

    static getWeeklyFigures(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Loyalty/WeeklyFigures`
        );
    }
}

export default LoyaltyService;
