import MomentHelper from '../helpers/MomentHelper';

class BookingStatus {
    constructor(booking) {
        this.id = booking && booking.id ? booking.id : 0;
        this.arrivalStatus = booking && booking.arrivalStatus ? booking.arrivalStatus : '';
        this.status = booking && booking.status ? booking.status : '';
        this.isReConfirmed = booking && booking.isReConfirmed ? booking.isReConfirmed : false;
        this.visitDateTime = booking && booking.visitDateTime ? booking.visitDateTime : '';
        this.isFutureBooking =
            booking && booking.visitDateTime
                ? booking.visitDateTime.isSameOrAfter(MomentHelper.newInstance().add(1, 'days'), 'day')
                : false;
        this.isUnallocated = booking && booking.isUnallocated ? booking.isUnallocated : false;
    }
}

export default BookingStatus;
