class Country {
    constructor(country) {
        this.id = country && country.id ? country.id : 0;
        this.name = country && country.name ? country.name : '';
        this.phoneCode = country && country.phoneCode ? country.phoneCode : '';
        this.alpha2Code = country && country.alpha2Code ? country.alpha2Code : '';
    }
}

export default Country;
