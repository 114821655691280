import React from 'react';

function useDebounce(value, interval) {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const handler = setTimeout(
            () => {
                setDebouncedValue(value);
            },
            interval ? interval : 500
        );

        return () => {
            clearTimeout(handler);
        };
    }, [interval, value]);

    return debouncedValue;
}

export default useDebounce;
