import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Button, Label, FormGroup, Input } from 'reactstrap';
import BreadcrumbPage from '../../common/BreadcrumbPage';
import infoBarType from '../../../enums/infoBarType';
import Section from '../../common/Section';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import { FormattedMessage, useIntl } from 'react-intl';
import AddCategorySection from './AddCategorySection';
import CategorySection from './CategorySection';
import DiaryContext from '../../../contexts/DiaryContext';
import MenuService from '../../../services/MenuService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/LoadingSpinner';
import ImageUpload from '../../common/ImageUpload';

let _ID = 0;

function BaseMenuPage(props) {
    const intl = useIntl();
    const navigate = useNavigate();
    const diaryContext = useContext(DiaryContext);
    const [menuTitle, setMenuTitle] = useState('');
    const [menuDescription, setMenuDescription] = useState('');
    const [categories, setCategories] = useState(props.categories ? [...props.categories] : []);
    const [isSaving, setIsSaving] = useState(false);
    const [infoBars, setInfoBars] = useState([]);
    const [images, setImages] = useState([]);
    const [wasImageChanged, setWasImageChanged] = useState(false);

    const onChange = (imageList) => {
        setWasImageChanged(true);
        setImages(imageList);
    };
    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    useEffect(() => {
        if (props.categories) {
            setCategories([...props.categories]);
        }
    }, [props.categories]);

    useEffect(() => {
        if (props.menuTitle) {
            setMenuTitle(props.menuTitle);
        }
    }, [props.menuTitle]);

    useEffect(() => {
        if (props.logoUrl && props.logoFilename) {
            setImages([
                {
                    data_url: props.logoUrl,
                    file: {
                        name: props.logoFilename,
                        size: null,
                    },
                },
            ]);
        }
    }, [props.logoUrl, props.logoFilename]);

    useEffect(() => {
        if (props.menuDescription) {
            setMenuDescription(props.menuDescription);
        }
    }, [props.menuDescription]);

    async function getMenuCategory() {
        if (props.menuCategoryId) {
            return props.menuCategoryId;
        }

        const menuCategories = await MenuService.getMenuCategories(
            diaryContext.deploymentId,
            diaryContext.restaurantId
        );

        return menuCategories[0].Id;
    }

    async function saveMenu() {
        setIsSaving(true);
        const menuCategoryId = await getMenuCategory();
        const dto = {
            Id: props.menuId,
            ProviderId: diaryContext.restaurantId,
            MenuCategoryId: menuCategoryId,
            Title: menuTitle,
            Description: menuDescription,
            MenuSections: getMenuSections(),
        };

        MenuService.saveMenu(diaryContext.deploymentId, diaryContext.restaurantId, dto)
            .then(async (response) => {
                if (wasImageChanged) {
                    const data = new FormData();
                    data.append('file', images[0] ? images[0].file : null);
                    await MenuService.saveMenuLogo(
                        diaryContext.deploymentId,
                        diaryContext.restaurantId,
                        response.Id,
                        data
                    );
                }
                navigate({
                    pathname: '/Settings/MenuManagement',
                    successMessage: intl.formatMessage(
                        { id: props.menuId ? 'Settings.MenuUpdated' : 'Settings.MenuCreated' },
                        { menuName: `'${menuTitle}'` }
                    ),
                });
            })
            .catch(() => {
                setIsSaving(false);
                addErrorBar(
                    intl.formatMessage(
                        { id: props.menuId ? 'Settings.ErrorMenuUpdating' : 'Settings.ErrorMenuCreating' },
                        { menuName: `'${menuTitle}'` }
                    )
                );
            });
    }

    function getMenuSections() {
        return categories.map((category) => {
            return {
                Id: category.id,
                Name: category.name,
                MenuItems: getMenuItems(category),
            };
        });
    }

    function getMenuItems(category) {
        return category.menuItems.map((item) => {
            return {
                Id: item.id,
                EposId: item.eposId,
                Name: item.name,
                Price: item.price,
                Description: item.description,
                DisplayOrder: item.displayOrder,
                OptionCategories: getMenuItemModifiers(item),
            };
        });
    }

    function getMenuItemModifiers(menuItem) {
        return menuItem.optionCategories.map((modifier) => {
            return {
                Id: modifier.id,
                MenuSectionItemId: menuItem.id,
                EposId: modifier.eposId,
                Name: modifier.name,
                DinerQuestion: modifier.dinerQuestion,
                AllowMultipleChoices: modifier.allowMultipleChoices,
                IsRequired: modifier.isRequired,
                Options: getMenuItemModifierOptions(modifier),
            };
        });
    }

    function getMenuItemModifierOptions(modifier) {
        return modifier.options.map((option) => {
            return {
                Id: option.id,
                MenuItemOptionCategoryId: modifier.id,
                EposId: option.eposId,
                Name: option.name,
                Price: option.price,
            };
        });
    }

    function getBreadcrumbOptions() {
        return [
            new BreadCrumbOption(intl.formatMessage({ id: 'Settings.Title' }), '/Settings'),
            new BreadCrumbOption(intl.formatMessage({ id: 'Settings.MenuManagement' }), '/Settings/MenuManagement'),
        ];
    }

    function addCategory(newCategory) {
        const newCategories = [...categories];
        newCategories.push(newCategory);
        setCategories(newCategories);
    }

    function editCategory(editedCategory) {
        const newCategories = [...categories];
        const index = newCategories.findIndex((c) => c.uniqueIdentifier === editedCategory.uniqueIdentifier);
        newCategories[index] = editedCategory;
        setCategories(newCategories);
    }

    function deleteCategory(deletedCategoryUniqueId) {
        const newCategories = [...categories];
        const index = newCategories.findIndex((c) => c.uniqueIdentifier === deletedCategoryUniqueId);
        if (index >= 0) {
            newCategories.splice(index, 1);
            setCategories(newCategories);
        }
    }

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(categories, result.source.index, result.destination.index);

        items.forEach((c, index) => {
            c.displayOrder = index;
        });

        setCategories(items);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function getCategorySections() {
        const sortedCategories = categories.sort((a, b) => a.displayOrder - b.displayOrder);
        return sortedCategories.map((c, index) => {
            return (
                <Draggable key={c.uniqueIdentifier} draggableId={c.uniqueIdentifier} index={index}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <CategorySection
                                key={c.uniqueIdentifier}
                                editCategory={editCategory}
                                category={c}
                                dragHandleProps={provided.dragHandleProps}
                                addErrorBar={addErrorBar}
                                deleteCategory={deleteCategory}
                            />
                        </div>
                    )}
                </Draggable>
            );
        });
    }

    if (props.isLoadingData) {
        return <LoadingSpinner />;
    }

    return (
        <BreadcrumbPage
            pageTitle={intl.formatMessage({
                id: props.menuId ? 'Settings.EditMenu' : 'Settings.CreateNewMenu',
            })}
            breadcrumbOptions={getBreadcrumbOptions()}
            infoBars={infoBars}
            setInfoBars={setInfoBars}
        >
            <div className="page-title-with-button">
                <FormattedMessage id={props.menuId ? 'Settings.EditMenu' : 'Settings.CreateNewMenu'} tagName="h2" />
                <Button
                    color="outline-primary"
                    onClick={() => {
                        navigate('/Settings/MenuManagement');
                    }}
                    id="create-menu"
                >
                    <FormattedMessage id="Common.Cancel" />
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        saveMenu();
                    }}
                    id="create-menu"
                    disabled={!menuTitle}
                >
                    {isSaving ? <FormattedMessage id="Common.Saving" /> : <FormattedMessage id="Common.Save" />}
                </Button>
            </div>
            <Section
                sectionTitle={intl.formatMessage({
                    id: 'Settings.MenuDetails',
                })}
                isCollapsible
            >
                <div className="collapsible-panel-body menu-section">
                    <FormGroup>
                        <ImageUpload
                            onChange={onChange}
                            images={images}
                            title={intl.formatMessage({
                                id: 'Settings.UploadRestaurantLogo',
                            })}
                            instruction={intl.formatMessage({
                                id: 'Settings.UploadRestaurantLogoInstruction',
                            })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="menuTitle">
                            <FormattedMessage id="Settings.MenuTitle" />
                        </Label>
                        <Input
                            name="menuTitle"
                            type="text"
                            value={menuTitle}
                            onChange={(event) => {
                                setMenuTitle(event.target.value);
                            }}
                            maxLength="100"
                            autoFocus
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="menuDescription">
                            <FormattedMessage id="Settings.MenuDescription" />
                        </Label>
                        <Input
                            name="menuDescription"
                            type="textarea"
                            value={menuDescription}
                            onChange={(event) => {
                                setMenuDescription(event.target.value);
                            }}
                        />
                    </FormGroup>
                </div>
            </Section>
            <Section
                sectionTitle={intl.formatMessage({
                    id: 'Settings.Categories',
                })}
                isCollapsible
                isTransparent
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {getCategorySections()}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <AddCategorySection addCategory={addCategory} newCategoryIndex={categories.length} />
            </Section>
        </BreadcrumbPage>
    );
}

BaseMenuPage.propTypes = {
    categories: PropTypes.array,
    menuTitle: PropTypes.string,
    menuDescription: PropTypes.string,
    menuId: PropTypes.number,
    logoFilename: PropTypes.string,
    logoUrl: PropTypes.string,
    isLoadingData: PropTypes.bool,
};

export default BaseMenuPage;
