import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

function PaginatorSizer(props) {
    function showPageSize() {
        if (props.showPageSizeSelector) {
            return (
                <div className="d-flex">
                    <div className="mr-2 align-self-center">
                        <FormattedMessage id="Common.Show" />
                    </div>
                    <ul className="pagination">
                        {props.pageSizes.map((size) => {
                            if (size === props.pageSize) {
                                return (
                                    <li
                                        className="page-item active"
                                        key={size}
                                        onClick={() => props.onPageSizeChange(size)}
                                    >
                                        <button className="page-link">{size}</button>
                                    </li>
                                );
                            } else {
                                return (
                                    <li className="page-item" key={size} onClick={() => props.onPageSizeChange(size)}>
                                        <button className="page-link">{size}</button>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                    <div className="ml-2 align-self-center">
                        <FormattedMessage id="Common.PerPage" />
                    </div>
                </div>
            );
        }
    }
    return <Fragment>{showPageSize()}</Fragment>;
}

PaginatorSizer.propTypes = {
    pageSize: PropTypes.number.isRequired,
    onPageSizeChange: PropTypes.func,
    pageSizes: PropTypes.array.isRequired,
    showPageSizeSelector: PropTypes.bool,
};

export default PaginatorSizer;
