import React from 'react';
import PropTypes from 'prop-types';
import tagColour from '../../enums/tagColour';

function Tag(props) {
    function getClassName() {
        return `${props.className} ${props.color ? props.color.toLowerCase() : ''}`;
    }

    return <div className={'rd-tag ' + getClassName()}>{props.value}</div>;
}

Tag.propTypes = {
    value: PropTypes.string.isRequired,
    color: PropTypes.oneOf(Object.keys(tagColour)).isRequired,
    className: PropTypes.string,
};

export default Tag;
