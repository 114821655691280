import Service from '../domainObjects/Service';

class ServiceDetailsHelper {
    static getServiceDto(services) {
        let servicesObject = { services: {} };
        let servicesList = [];
        services.forEach((service) => {
            let serviceDto = new Service();
            servicesList.push(
                serviceDto.getServiceDTO(
                    service.name,
                    service.timeFrom,
                    service.timeUntil,
                    service.lastBookingTime,
                    service.timeSlotInterval,
                    service.maxCovers,
                    service.maxBookings
                )
            );
        });
        servicesObject.services = servicesList;
        return servicesObject;
    }

    static getServicesDtoForTracking(services) {
        let servicesObject = {};
        let serviceIncrement = 1;
        services.forEach((service) => {
            let serviceName = 'venueServiceName' + serviceIncrement;
            servicesObject[serviceName] = service.name;
            serviceIncrement += 1;
        });
        return servicesObject;
    }
}
export default ServiceDetailsHelper;
