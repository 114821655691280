import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class SettingsService {
    static getSettings() {
        return ApiHelper.get('/api/configuration');
    }

    static getRestaurantSettings(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/DetailsSettings`
        ).then((response) => response.data);
    }

    static UpdateAddressAndContactDetails(deploymentId, restaurantId, dto, websiteUrl) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/DetailsSettings/AddressAndContactDetails`,
            dto
        ).then((response) => response.data);
    }

    static UploadMenuFile(deploymentId, restaurantId, data) {
        return ApiHelper.postFormData(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/DetailsSettings/UploadMenu`,
            data
        ).then((response) => response.data);
    }

    static SaveMenuDetails(deploymentId, restaurantId, data) {
        return ApiHelper.postFormData(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/DetailsSettings/SaveMenuDetails`,
            data
        ).then((response) => response.data);
    }

    static DeleteMenuDetail(deploymentId, restaurantId, menuId) {
        return ApiHelper.deleteRequest(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/DetailsSettings/${menuId}/DeleteMenuDetail`
        );
    }

    static UpdateMenuEnabledStatus(deploymentId, restaurantId, menuId, isEnabled) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/DetailsSettings/UpdateMenuEnabledStatus`,
            { MenuId: menuId, IsEnabled: isEnabled }
        ).then((response) => response.data);
    }

    static getGeoLocationData(deploymentId, restaurantId, address, town, country, postcode) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/LocationData?address=${address}&town=${town}&country=${country}&postcode=${postcode}`
        ).then((response) => response.data);
    }

    static getTermsAndPrivacy(deploymentId, providerId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/tablet/v1/Restaurant/${deploymentId}/${providerId}/TermsPrivacy`
        ).then((response) => response.data);
    }

    static setTermsAndPrivacy(deploymentId, providerId, termsPrivacy) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${providerId}/TermsPrivacy`,
            termsPrivacy
        ).then((response) => response.data);
    }

    static setListingDetails(deploymentId, providerId, listingDetails) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${providerId}/DetailsSettings/ListingSettings`,
            listingDetails
        ).then((response) => response.data);
    }

    static completeDiarySetup(deploymentId, restaurantId) {
        return ApiHelper.put(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/AccountSetup/Complete`
        );
    }

    static getCustomerDataRetentionPeriod(deploymentId, providerId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/tablet/v1/Restaurant/${deploymentId}/${providerId}/CustomerDataRetentionPeriod`
        ).then((response) => response.data);
    }

    static setCustomerDataRetentionPeriod(deploymentId, providerId, retentionPeriod) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/tablet/v1/Restaurant/${deploymentId}/${providerId}/CustomerDataRetentionPeriod`,
            { CustomerRetentionPeriod: retentionPeriod }
        ).then((response) => response.data);
    }

    static subscribeToLoyalty(deploymentId, providerId, subscriptionDto) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${providerId}/Loyalty/SubscribeProvider`,
            subscriptionDto
        ).then((response) => response.data);
    }
}

export default SettingsService;
