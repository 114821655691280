import React, { Fragment, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as EditIcon } from '../../content/icons/edit.svg';
import { ReactComponent as GenerateVoucherIcon } from '../../content/icons/voucher-generate.svg';
import Tag from '../common/Tag';
import tagColour from '../../enums/tagColour';
import voucherReportStatus from '../../enums/voucherReportStatus';
import useScreenSize from '../../hooks/useScreenSize';
import DiaryContext from '../../contexts/DiaryContext';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'reactstrap';

function VoucherEntry(props) {
    const intl = useIntl();
    const { isMobileView } = useScreenSize();
    const [isToolTipOpen, toggleTooltip] = useReducer(toggle, false);
    const diaryContext = useContext(DiaryContext);
    const navigate = useNavigate();

    function toggle(value) {
        return !value;
    }

    function getStatusTag() {
        if (props.voucher.status) {
            return <Tag value={intl.formatMessage({ id: 'Common.Active' })} color={tagColour.Green} />;
        }
        return <Tag value={intl.formatMessage({ id: 'Common.InActive' })} color={tagColour.Disabled} />;
    }

    function getFormattedCreatedDate() {
        const formattedDate = props.voucher.createdDate.format('D MMM YYYY');
        return <FormattedMessage id="Common.CreatedOn" values={{ date: formattedDate }} />;
    }

    function getEditButton() {
        return (
            <Fragment>
                {isMobileView && (
                    <div className={isMobileView ? 'row-element mobile' : 'row-element'}>{getVoucherSalesInfo()}</div>
                )}

                <div
                    className={isMobileView ? 'row-element clickable button mob' : 'row-element clickable button'}
                    onClick={() => props.toggleEditing()}
                >
                    <div>
                        <EditIcon />
                    </div>
                </div>
            </Fragment>
        );
    }

    function getGenerateVoucherButton() {
        const voucherTarget = 'generate-voucher' + props.voucher.id;
        return (
            <Fragment>
                <div
                    id={voucherTarget}
                    className={isMobileView ? 'row-element clickable button mob' : 'row-element clickable button'}
                    onClick={() => {
                        AnalyticsHelper.trackClickWithProperties('Web Vouchers GenerateIcon', {
                            voucherName: `${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)}${
                                props.voucher.cost
                            } ${props.voucher.title}`,
                            activeStatus: props.voucher.status ? 'Active' : 'Inactive',
                            quantitySold: props.voucher.totalSold,
                            quantityRedeemed: props.voucher.totalRedeemed,
                        });
                        props.displayGenerateModalWithDefaultVoucher(true, props.voucher.id);
                    }}
                >
                    <Tooltip
                        innerClassName="tooltip-style"
                        placement="top"
                        isOpen={isToolTipOpen}
                        target={voucherTarget}
                        toggle={toggleTooltip}
                    >
                        <FormattedMessage id="Vouchers.GenerateAndSendVouchers" />
                    </Tooltip>
                    <div>
                        <GenerateVoucherIcon />
                    </div>
                </div>
            </Fragment>
        );
    }

    function getList() {
        return (
            <Fragment>
                {!isMobileView && <div className={'row-element d-flex'}>{getVoucherSalesInfo()}</div>}

                {isMobileView && (
                    <div className="mobile-vouchers-row">
                        {getEditButton()}
                        {getGenerateVoucherButton()}
                    </div>
                )}
                {!isMobileView && <Fragment>{getEditButton()}</Fragment>}
                {!isMobileView && <Fragment>{getGenerateVoucherButton()}</Fragment>}
            </Fragment>
        );
    }

    function getVoucherSalesInfo() {
        return (
            <div className="voucher-sales-info">
                <div
                    className="vouchers-sold clickable"
                    onClick={() => {
                        navigate(
                            `/Vouchers/Report?voucherId=${props.voucher.id}&voucherStatus=${voucherReportStatus.Sold}`
                        );
                    }}
                >
                    <div className="numeric-value">{props.voucher.totalSold}</div>
                    <FormattedMessage id="Common.Sold" />
                </div>
                <div
                    className="clickable"
                    onClick={() => {
                        navigate(
                            `/Vouchers/Report?voucherId=${props.voucher.id}&voucherStatus=${voucherReportStatus.Redeemed}`
                        );
                    }}
                >
                    <div className="numeric-value">{props.voucher.totalRedeemed}</div>
                    <FormattedMessage id="Common.Redeemed" />
                </div>
            </div>
        );
    }

    return (
        <div key={props.voucher.uniqueIdentifier} className="voucher-item">
            <div className={isMobileView ? 'voucher-entry mob' : 'voucher-entry'}>
                <div className={isMobileView ? 'voucher-description mob' : 'mr-auto voucher-description'}>
                    <div
                        className="bold-text clickable"
                        onClick={() => {
                            navigate(`/Vouchers/Report?voucherId=${props.voucher.id}`);
                        }}
                    >
                        <div className="d-flex pb-1">
                            {`${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)}${
                                props.voucher.cost
                            } ${props.voucher.title}`}
                        </div>
                        <div className="creation-date">{getFormattedCreatedDate()}</div>
                    </div>

                    <div className="status-tag">{getStatusTag()} </div>
                </div>
                {getList()}
            </div>
        </div>
    );
}

VoucherEntry.propTypes = {
    voucher: PropTypes.object.isRequired,
    toggleEditing: PropTypes.func.isRequired,
    displayGenerateModalWithDefaultVoucher: PropTypes.func.isRequired,
};

export default VoucherEntry;
