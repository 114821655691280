import React from 'react';
import PropTypes from 'prop-types';
import PaginatorPage from './PaginatorPage';

function PaginatorPages(props) {
    let pages = [];
    for (let i = 1; i <= props.numberOfPages; i++) {
        if (shouldAddPageNumber(i, props.numberOfPages, props.selectedPage)) {
            pages.push(
                <PaginatorPage
                    pageNumber={i}
                    key={i}
                    onSelectPage={() => props.onSelectPage(i)}
                    selectedPage={props.selectedPage}
                />
            );
        } else if (shouldAddEllipsis(i, props.numberOfPages, props.selectedPage)) {
            pages.push(
                <PaginatorPage
                    pageNumber={'...'}
                    key={i}
                    onSelectPage={() => props.onSelectPage(i)}
                    selectedPage={props.selectedPage}
                    isEllipsis={true}
                />
            );
        }
    }
    return pages;
}

function shouldAddPageNumber(i, lastIndex, selectedPage) {
    return i === lastIndex || i === 1 || i === selectedPage || shouldAddSurroundingPageNumbers(i, selectedPage);
}

function shouldAddSurroundingPageNumbers(i, selectedPage) {
    // Page number should only be added to Paginator if is within 2 on either side of the selected page.
    return i === selectedPage + 1 || i === selectedPage + 2 || i === selectedPage - 1 || i === selectedPage - 2;
}

function shouldAddEllipsis(i, lastIndex, selectedPage) {
    // Ellipsis should only be added ONCE when the selected page is outside of the range for adding surrounding numbers to paginator.
    return (selectedPage >= 5 && i === selectedPage - 3) || (selectedPage <= lastIndex - 4 && i === selectedPage + 3);
}

PaginatorPages.propTypes = {
    onSelectPage: PropTypes.func.isRequired,
    selectedPage: PropTypes.number.isRequired,
    numberOfPages: PropTypes.number.isRequired,
};

export default PaginatorPages;
