import React, { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, CustomInput, Collapse } from 'reactstrap';
import menuManagementStatusType from '../../../enums/menuManagementStatusType';
import { useIntl } from 'react-intl';
import MenuService from '../../../services/MenuService';
import DiaryContext from '../../../contexts/DiaryContext';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DuplicateIcon } from '../../../content/icons/Duplicate.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import { ReactComponent as PreviewIcon } from '../../../content/icons/Icon-Preview.svg';
import { useNavigate } from 'react-router-dom';
import SettingsHelper from '../../../helpers/SettingsHelper';
import useScreenSize from '../../../hooks/useScreenSize';
import { ReactComponent as ChevronUp } from '../../../content/icons/chevron-left.svg';

function MenuManagementRow(props) {
    const intl = useIntl();
    const diaryContext = useContext(DiaryContext);
    const [awaitingIsDisabledRequest, setAwaitingIsDisabledRequest] = useState(false);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const { isMobileView } = useScreenSize();
    const [rotateIcon, setRotateIcon] = useState('');

    function mobileToggle() {
        setIsOpen(!isOpen);
        setRotateIcon(isOpen ? '' : 'rotate');
    }

    function updateIsMenuDisabled(menuId, checkedId) {
        setAwaitingIsDisabledRequest(true);
        const isDisabled = menuManagementStatusType.active + menuId !== checkedId;
        MenuService.updateMenuIsDisabled(diaryContext.deploymentId, diaryContext.restaurantId, menuId, isDisabled)
            .then(() => {
                props.updateMenuIsDisabled(menuId, isDisabled);
                props.addSuccessBar(
                    intl.formatMessage(
                        {
                            id: isDisabled ? 'Settings.SuccessMenuDisabling' : 'Settings.SuccessMenuEnabling',
                        },
                        { menuName: `'${props.menu.name}'` }
                    )
                );
            })
            .catch(() => {
                props.addErrorBar(
                    intl.formatMessage(
                        {
                            id: isDisabled ? 'Settings.ErrorMenuDisabling' : 'Settings.ErrorMenuEnabling',
                        },
                        { menuName: `'${props.menu.name}'` }
                    )
                );
            })
            .finally(() => {
                setAwaitingIsDisabledRequest(false);
            });
    }

    function getPreviewMenu() {
        return `${SettingsHelper.getPortalUrl()}Preorder/Menu?restaurantName=${diaryContext.micrositeName}&versionId=${
            props.menu.latestMenuVersionId
        }`;
    }

    function getButtons() {
        return (
            <Fragment>
                <div
                    className={isMobileView ? 'row-section clickable mob' : 'row-section clickable'}
                    onClick={() => window.open(getPreviewMenu(), '_blank')}
                >
                    <PreviewIcon />
                </div>
                <div
                    className={isMobileView ? 'row-section clickable copy mob' : 'row-section clickable copy'}
                    onClick={() => props.setMenuToCopyId(props.menu.id)}
                >
                    <DuplicateIcon />
                </div>
                <div
                    className={isMobileView ? 'row-section clickable mob' : 'row-section clickable'}
                    onClick={() => navigate(`/Settings/MenuManagement/Edit/${props.menu.id}`)}
                >
                    <EditIcon />
                </div>
                <div className={isMobileView ? 'row-section clickable delete mob' : 'row-section clickable delete'}>
                    <Button
                        id={'deleteRowButton' + props.menu.id.toString()}
                        onClick={() => props.openDeletionModal(props.menu)}
                    >
                        <DeleteIcon />
                    </Button>
                </div>
            </Fragment>
        );
    }

    function getList() {
        return (
            <Fragment>
                <div className={isMobileView ? 'row-section mob' : 'row-section'}>
                    <CustomInput
                        disabled={awaitingIsDisabledRequest}
                        checked={!props.menu.isDisabled}
                        onChange={(event) => updateIsMenuDisabled(props.menu.id, event.target.id)}
                        inline
                        type="radio"
                        id={menuManagementStatusType.active + props.menu.id}
                        name={'customRadio' + props.menu.id}
                        label={intl.formatMessage({
                            id: 'Settings.Active',
                        })}
                    />
                    <CustomInput
                        disabled={awaitingIsDisabledRequest}
                        checked={props.menu.isDisabled}
                        onChange={(event) => updateIsMenuDisabled(props.menu.id, event.target.id)}
                        inline
                        type="radio"
                        id={menuManagementStatusType.disabled + props.menu.id}
                        name={'customRadio' + props.menu.id}
                        label={intl.formatMessage({
                            id: 'Settings.Inactive',
                        })}
                    />
                </div>
                {isMobileView && <div className={isMobileView ? 'mobile-menu-management-row' : ''}>{getButtons()}</div>}
                {!isMobileView && <Fragment>{getButtons()}</Fragment>}
            </Fragment>
        );
    }

    return (
        <div key={props.menu.id} className="menu-item">
            <div className={isMobileView ? 'menu-row mob' : 'menu-row'}>
                <div
                    className={isMobileView ? 'menu-description clickable mob' : 'mr-auto menu-description'}
                    onClick={isMobileView ? mobileToggle : null}
                >
                    <div className={isMobileView ? 'menu-description-with-icon' : ''}>
                        <div className="bold-text">{props.menu.name}</div>
                    </div>
                    {isMobileView && <ChevronUp className={`${rotateIcon}`} />}
                </div>
                {isMobileView && <Collapse isOpen={isOpen}>{getList()}</Collapse>}
                {!isMobileView && <Fragment>{getList()}</Fragment>}
            </div>
        </div>
    );
}

MenuManagementRow.propTypes = {
    menu: PropTypes.object.isRequired,
    updateMenuIsDisabled: PropTypes.func.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    setMenus: PropTypes.func.isRequired,
    setMenuToCopyId: PropTypes.func.isRequired,
};

export default MenuManagementRow;
