import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import TableLabelEntry from './TableLabelEntry';
import TableLabelCreationPanel from './TableLabelCreationPanel';

function TableLabelEntryWrapper(props) {
    const [isEditing, setIsEditing] = useState(false);

    const toggleEditing = () => {
        setIsEditing(!isEditing);
    };

    function updateTableLabelEntry(tableLabel) {
        return props.updateTableLabelEntry(tableLabel, toggleEditing);
    }

    return (
        <Fragment>
            <div data-testid="table-label-entry-wrapper">
                {isEditing && (
                    <TableLabelCreationPanel
                        key={props.tableLabel.uniqueIdentifier}
                        tableLabel={props.tableLabel}
                        isEditing={isEditing}
                        toggleEditing={toggleEditing}
                        updateTableLabelEntry={updateTableLabelEntry}
                        existingTableLabels={props.existingTableLabels}
                    />
                )}

                {!isEditing && (
                    <TableLabelEntry
                        key={props.tableLabel.uniqueIdentifier}
                        tableLabel={props.tableLabel}
                        deleteTableLabelEntry={props.deleteTableLabelEntry}
                        toggleEditing={toggleEditing}
                        openDeletionModal={props.openDeletionModal}
                    />
                )}
            </div>
        </Fragment>
    );
}

TableLabelEntryWrapper.propTypes = {
    tableLabel: PropTypes.object.isRequired,
    deleteTableLabelEntry: PropTypes.func.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    existingTableLabels: PropTypes.array.isRequired,
};

export default TableLabelEntryWrapper;
