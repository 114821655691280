import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button, FormGroup } from 'reactstrap';
import CurrencyDiv from '../../common/CurrencyDiv';
import ConfirmActionButton from '../../common/ConfirmActionButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import { ReactComponent as UpArrowIcon } from '../../../content/icons/up-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../../content/icons/down-arrow.svg';
import EditMenuItemModifierSection from './EditMenuItemModifierSection';
import useScreenSize from '../../../hooks/useScreenSize';

function MenuItemModifier(props) {
    const intl = useIntl();
    const [isOpen, toggleIsOpen] = useReducer(toggle, false);
    const [isDeleting, toggleIsDeleting] = useReducer(toggle, false);
    const [isEditing, toggleIsEditing] = useReducer(toggle, false);
    const [tooltipOpen, toggleTooltip] = useReducer(toggle, false);
    const { isMobileView } = useScreenSize();

    // At some point we will have to support unmanaged menus (not linked to an EPOS), at that time we can
    // work this out based on whether it is managed externally or not, and set this accordingly.
    const canDelete = false;

    function toggle(value) {
        return !value;
    }

    function getArrowIcon() {
        return isOpen ? <UpArrowIcon /> : <DownArrowIcon />;
    }

    function getSummary() {
        return (
            <div className="d-flex item-summary">
                <div className="menu-items-summary-header">
                    <div className="bold-text text-uppercase">
                        <FormattedMessage id="Settings.Item" />
                    </div>
                    {props.modifier.options.map((si) => {
                        return (
                            <div key={si.uniqueIdentifier} className="panel-display-item">
                                {si.name}
                            </div>
                        );
                    })}
                </div>
                <div className="menu-items-summary-header">
                    <div className="bold-text text-uppercase">
                        <FormattedMessage id="Settings.Price" />
                    </div>
                    {props.modifier.options.map((si) => {
                        return (
                            <CurrencyDiv key={si.uniqueIdentifier} className="panel-display-item" price={si.price} />
                        );
                    })}
                </div>
            </div>
        );
    }

    if (isEditing) {
        return (
            <EditMenuItemModifierSection
                editingSection
                modifier={props.modifier}
                modifiers={props.modifiers}
                menuItemModifierEdited={props.menuItemModifierEdited}
                toggleIsOpen={() => {
                    toggleIsEditing();
                }}
            />
        );
    }

    return (
        <div className="menu-item">
            <div className={isOpen ? 'menu-item-row section-open' : 'menu-item-row'}>
                <div className="mr-auto">
                    <div className="bold-text">{props.modifier.name}</div>
                    <div>{props.modifier.dinerQuestion}</div>
                </div>
                {!isMobileView && (
                    <div className="row-section modifier-row-section-column">
                        <div>
                            <FormattedMessage
                                id={
                                    props.modifier.options.length === 1
                                        ? 'Settings.ModifierTotalItem'
                                        : 'Settings.ModifierTotalItems'
                                }
                                values={{ totalItems: props.modifier.options.length }}
                            />
                        </div>
                    </div>
                )}
                <div
                    className="row-section clickable"
                    onClick={() => {
                        toggleIsEditing();
                    }}
                >
                    <EditIcon />
                </div>
                {canDelete && (
                    <div className="row-section clickable delete">
                        <ConfirmActionButton
                            id={'deleteRowButton' + props.modifier.uniqueIdentifier}
                            className="booking-status-icon-button"
                            icon={<DeleteIcon />}
                            tooltipOpen={tooltipOpen}
                            includeCloseIcon
                            toggle={toggleTooltip}
                            tooltipPlacement="top"
                        >
                            <FormattedMessage id="Common.DeletionConfirmation" />
                            <Button
                                className="btn-outline-primary cancel-btn"
                                onClick={() => {
                                    toggleIsDeleting();
                                    props.modifierDeleted(props.modifier);
                                }}
                            >
                                {isDeleting
                                    ? intl.formatMessage({ id: 'Common.Deleting' })
                                    : intl.formatMessage({ id: 'Common.Delete' })}
                            </Button>
                        </ConfirmActionButton>
                    </div>
                )}
                <div className="row-section chevron">
                    <Button className="section-chevron" onClick={toggleIsOpen}>
                        {getArrowIcon()}
                    </Button>
                </div>
            </div>
            <Collapse className="inline-collapse-section" isOpen={isOpen}>
                <FormGroup className="panel-form-group">{getSummary()}</FormGroup>
            </Collapse>
        </div>
    );
}

MenuItemModifier.propTypes = {
    modifier: PropTypes.object.isRequired,
    modifierDeleted: PropTypes.func.isRequired,
    menuItemModifierEdited: PropTypes.func.isRequired,
    modifiers: PropTypes.array,
};

export default MenuItemModifier;
