import MerchantId from './MerchantId';
import Address from './Address';
import ContactDetails from './ContactDetails';

class VenueSignUpInfo {
    constructor(signUpInfo) {
        this.venueName = signUpInfo && signUpInfo.VenueName ? signUpInfo.VenueName : '';
        this.tradingName = signUpInfo && signUpInfo.TradingName ? signUpInfo.TradingName : '';
        this.merchantIds = signUpInfo && signUpInfo.MerchantIds ? signUpInfo.MerchantIds : [new MerchantId()];
        this.address = signUpInfo && signUpInfo.Address ? signUpInfo.Address : new Address();
        this.contactDetails =
            signUpInfo && signUpInfo.ContactDetails ? signUpInfo.ContactDetails : new ContactDetails();
    }
}

export default VenueSignUpInfo;
