import React, { useState, Fragment, useContext } from 'react';
import runningOrderColumns from '../../enums/runningOrderColumns';
import { ReactComponent as NoBookingsIcon } from '../../content/images/rd-lite-no-bookings.svg';
import { ReactComponent as UpArrowIcon } from '../../content/icons/up-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../content/icons/down-arrow.svg';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';
import TableHeader from '.././common/TableHeader';
import TableHeadingOption from '../common/TableHeadingOption';
import { useIntl, FormattedMessage } from 'react-intl';
import LoadingSpinner from '../common/LoadingSpinner';
import MobileBookingCard from './MobileBookingCard';
import { Table, Button, Tooltip } from 'reactstrap';
import RunningOrderTableBody from './RunningOrderTableBody';
import BookingEntry from './BookingEntry';
import MomentHelper from '../../helpers/MomentHelper';
import overlayType from '../../enums/overlayType';
import bookingStatus from '../../enums/bookingStatus';
import PropTypes from 'prop-types';
import DiaryContext from '../../contexts/DiaryContext';
import AddOnContext from '../../contexts/AddOnContext';
import addOnType from '../../enums/addOnType';
import loyaltyStatus from '../../enums/loyaltyStatus';

function RunningOrderTable(props) {
    const intl = useIntl();
    const [showBodyElements, toggleShowBodyElements] = useState(true);
    const toggle = () => toggleShowBodyElements(!showBodyElements);
    const diaryContext = useContext(DiaryContext);
    const addOnContext = useContext(AddOnContext);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleToolTip = () => setTooltipOpen(!tooltipOpen);

    function getArrowIcon() {
        return showBodyElements ? (
            <UpArrowIcon className="unallocated-booking-arrow" data-testid="arrow-up-icon" />
        ) : (
            <DownArrowIcon className="unallocated-booking-arrow" data-testid="arrow-down-icon" />
        );
    }

    function getTotalCovers(bookings) {
        let covers = 0;
        bookings.forEach((entry) => {
            covers += entry.partySize;
        });

        return covers;
    }

    function getTotalBookings(bookings) {
        return bookings.length;
    }

    function getBookingsAndCoversLabel(bookings) {
        const totalBookings = getTotalBookings(bookings);
        const totalCovers = getTotalCovers(bookings);
        let labelId;
        if (totalBookings !== 1) {
            labelId = 'Bookings.BookingsAndCoversLabel';
        } else {
            if (totalCovers !== 1) {
                labelId = 'Bookings.BookingAndCoversLabel';
            } else {
                labelId = 'Bookings.BookingAndCoverLabel';
            }
        }
        return <FormattedMessage id={labelId} values={{ totalCovers: totalCovers, totalBookings: totalBookings }} />;
    }

    function getHeaders() {
        let headerItems = [
            new TableHeadingOption(
                false,
                intl.formatMessage({
                    id: 'Bookings.VisitTimeLabel',
                }),
                runningOrderColumns.visitTime
            ),
            new TableHeadingOption(
                false,
                intl.formatMessage({
                    id: 'Bookings.CoversLabel',
                }),
                runningOrderColumns.covers
            ),
            new TableHeadingOption(
                false,
                intl.formatMessage({
                    id: 'Bookings.TableLabel',
                }),
                runningOrderColumns.tables
            ),
            new TableHeadingOption(
                false,
                intl.formatMessage({
                    id: 'Bookings.CustomerDetailsLabel',
                }),
                runningOrderColumns.customerDetails
            ),
        ];

        if (diaryContext.loyaltyStatus === loyaltyStatus.active) {
            headerItems = headerItems.concat([
                new TableHeadingOption(
                    false,
                    intl.formatMessage({
                        id: 'Bookings.Member',
                    }),
                    runningOrderColumns.customerIsLoyaltyMember
                ),
            ]);
        }

        headerItems = headerItems.concat([
            new TableHeadingOption(
                false,
                intl.formatMessage({
                    id: 'Bookings.BookingCommentsLabel',
                }),
                runningOrderColumns.bookingComments
            ),
        ]);

        if (diaryContext.hasSquareEnabled) {
            headerItems = headerItems.concat([
                new TableHeadingOption(
                    false,
                    intl.formatMessage({
                        id: 'Bookings.OrderStatus',
                    }),
                    runningOrderColumns.orderStatus
                ),
            ]);
        }

        headerItems = headerItems.concat([
            new TableHeadingOption(
                false,
                intl.formatMessage({
                    id: 'Bookings.BookingReferenceLabel',
                }),
                runningOrderColumns.reference
            ),
        ]);

        if (addOnContext.isAddOnEnabled(addOnType.vouchers)) {
            headerItems = headerItems.concat([
                new TableHeadingOption(
                    false,
                    intl.formatMessage({
                        id: 'Bookings.Voucher',
                    }),
                    runningOrderColumns.voucherReference
                ),
            ]);
        }

        headerItems = headerItems.concat([
            new TableHeadingOption(
                false,
                intl.formatMessage({
                    id: 'Bookings.BookingReasonLabel',
                }),
                runningOrderColumns.bookingReason
            ),
        ]);

        if (diaryContext.loyaltyStatus === loyaltyStatus.active) {
            headerItems = headerItems.concat([
                new TableHeadingOption(
                    false,
                    intl.formatMessage({
                        id: 'Bookings.DishcultRewardsRedemption',
                    }),
                    runningOrderColumns.dishcultRewardsRedemption
                ),
            ]);
        }

        return headerItems.map((headingOption, index) => {
            return (
                <TableHeader
                    index={index}
                    sortable={false}
                    key={headingOption.heading}
                    heading={headingOption.heading}
                    toolTipText={headingOption.toolTip}
                />
            );
        });
    }

    function getTableItems(service) {
        const serviceStartTime = service.timeFrom.split(':');
        const serviceStartHour = serviceStartTime[0];
        const serviceStartMinute = serviceStartTime[1];
        let serviceStartDateTime = MomentHelper.newInstance(props.debouncedDateString)
            .set('hour', serviceStartHour)
            .set('minute', serviceStartMinute)
            .set('second', 0)
            .set('millisecond', 0);

        const bookingsForService = props.bookings.filter(
            (booking) => booking.serviceId === service.id && booking.visitDateTime.isSameOrAfter(serviceStartDateTime)
        );

        const cancelledBookingsOutsideServiceTime = props.bookings.filter(
            (booking) =>
                booking.serviceId === service.id &&
                booking.visitDateTime.isBefore(serviceStartDateTime) &&
                booking.status === bookingStatus.cancelled
        );

        const afterMidnightBookings = props.bookings
            .filter(
                (booking) =>
                    booking.serviceId === service.id &&
                    booking.visitDateTime.isBefore(serviceStartDateTime) &&
                    booking.status !== bookingStatus.cancelled
            )
            .sort((a, b) => a.visitDateTime - b.visitDateTime);

        const allBookingsForService = bookingsForService
            .concat(cancelledBookingsOutsideServiceTime)
            .sort((a, b) => a.visitDateTime - b.visitDateTime)
            .concat(afterMidnightBookings);

        return allBookingsForService.map((booking, index) => {
            return getBookingEntry(booking, index);
        });
    }

    function getUnAllocatedBookingEntries() {
        const sortedBookings = props.unAllocatedBookings.sort((a, b) => a.visitDateTime - b.visitDateTime);

        return sortedBookings.map((booking, index) => {
            return getBookingEntry(booking, index);
        });
    }

    function getBookingEntry(booking, index) {
        return (
            <BookingEntry
                openOverlay={props.openOverlay}
                key={index}
                booking={booking}
                bookingReasons={props.getBookingReasonNames(booking.bookingReasonIds)}
                tableLabels={props.getTableLabelData(booking.tableLabelIds)}
                updateArrivalStatus={props.updateArrivalStatus}
                closeBooking={props.closeBooking}
                openCancellationModalWithBookingId={props.openCancellationModalWithBookingId}
                rebookBooking={props.rebookBooking}
                toggleReconfirmedStatus={props.toggleReconfirmedStatus}
                isSelectedBooking={
                    props.overlayContent &&
                    props.overlayContent.overlayType === overlayType.booking &&
                    props.overlayContent.booking.id === booking.id
                }
            />
        );
    }

    function getBookingsTable() {
        if (props.isLoading()) {
            return <LoadingSpinner />;
        }
        if (props.bookings.length > 0 || props.unAllocatedBookings.length > 0) {
            if (props.isMobileView) {
                return (
                    <Fragment>
                        {props.unAllocatedBookings
                            .sort((a, b) => a.visitDateTime - b.visitDateTime)
                            .concat(props.bookings.sort((a, b) => a.visitDateTime - b.visitDateTime))
                            .map((booking) => {
                                return (
                                    <MobileBookingCard
                                        key={booking.id}
                                        booking={booking}
                                        bookingReasons={props.getBookingReasonNames(booking.bookingReasonIds)}
                                        tableLabels={props.getTableLabelData(booking.tableLabelIds)}
                                        openOverlay={props.openOverlay}
                                    />
                                );
                            })}
                    </Fragment>
                );
            } else {
                return (
                    <Table className="running-order-table">
                        <thead className="running-order-table-header">
                            <tr>{getHeaders()}</tr>
                        </thead>
                        {props.unAllocatedBookings && props.unAllocatedBookings.length > 0 && (
                            <Fragment>
                                <tbody className="unallocated-bookings-section-body">
                                    <tr className="table-section-header-row row-disabled" onClick={toggle}>
                                        {/*setting colSpan to something silly so it always takes up the full width*/}
                                        <td className="table-section-header" colSpan="100">
                                            <div className="unallocated-bookings-section">
                                                <div className="header-name text-uppercase">
                                                    <FormattedMessage id="Bookings.Unallocated" />
                                                </div>
                                                <InfoIcon id="unallocated-booking-tooltip" />
                                                <Tooltip
                                                    innerClassName="tooltip-style"
                                                    placement="top"
                                                    isOpen={tooltipOpen}
                                                    target="unallocated-booking-tooltip"
                                                    toggle={toggleToolTip}
                                                >
                                                    <FormattedMessage id="Bookings.UnallocatedBookingToolTipText" />
                                                </Tooltip>
                                                <div></div>

                                                <div>{getBookingsAndCoversLabel(props.unAllocatedBookings)}</div>
                                            </div>
                                            <Button className="section-chevron" data-testid="show-hide-body-arrow-icon">
                                                {getArrowIcon()}
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    data-testid="unallocated-table-items-body"
                                    className={showBodyElements ? '' : 'hidden'}
                                >
                                    {getUnAllocatedBookingEntries()}
                                </tbody>
                            </Fragment>
                        )}

                        {props.services.map((service) => {
                            return (
                                <RunningOrderTableBody
                                    key={service.id}
                                    service={service}
                                    tableItems={getTableItems(service)}
                                />
                            );
                        })}
                    </Table>
                );
            }
        } else {
            return (
                <div className="booking-empty-state">
                    <NoBookingsIcon />
                    <div>
                        <FormattedMessage id="Bookings.NoBookingsLabel" />
                    </div>
                </div>
            );
        }
    }

    return <Fragment>{getBookingsTable()}</Fragment>;
}

RunningOrderTable.propTypes = {
    services: PropTypes.array.isRequired,
    bookings: PropTypes.array,
    isLoading: PropTypes.func,
    isMobileView: PropTypes.bool,
    getBookingReasonNames: PropTypes.func.isRequired,
    getTableLabelData: PropTypes.func.isRequired,
    openOverlay: PropTypes.func.isRequired,
    updateArrivalStatus: PropTypes.func.isRequired,
    closeBooking: PropTypes.func.isRequired,
    openCancellationModalWithBookingId: PropTypes.func.isRequired,
    rebookBooking: PropTypes.func.isRequired,
    toggleReconfirmedStatus: PropTypes.func.isRequired,
    unAllocatedBookings: PropTypes.array,
    debouncedDateString: PropTypes.string.isRequired,
};

export default RunningOrderTable;
