const customerFields = {
    mobilePhoneCode: 'mobilePhoneCode',
    phoneCode: 'phoneCode',
    phoneNumber: 'phoneNumber',
    mobileNumber: 'mobileNumber',
    surname: 'surname',
    email: 'email',
    customerComments: 'customerComments',
};

export default customerFields;
