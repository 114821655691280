import React, { useReducer, Fragment } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarToggler } from 'reactstrap';

import { ReactComponent as ResIcon } from '../.././content/images/rd-lite-logo.svg';
import { ReactComponent as RdlBowTie } from '../.././content/images/rdl-bowtie.svg';
import { ReactComponent as AlertBellIcon } from '../.././content/icons/Icon-BellAlert.svg';
import { ReactComponent as UpArrow } from '../.././content/icons/up-arrow.svg';
import { ReactComponent as DownArrow } from '../.././content/icons/down-arrow.svg';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import useMediaQuery from '../../hooks/useMediaQuery';

function CollapsedNavbar(props) {
    const [isBurgerOpen, toggleIsBurgerOpen] = useReducer(props.toggle, false);
    const [isUsernameSectionExpanded, toggleIsUsernameSectionExpanded] = useReducer(props.toggle, false);
    const smallMobile = useMediaQuery('(max-width:350px)');

    function getArrow() {
        return isUsernameSectionExpanded ? <UpArrow /> : <DownArrow />;
    }

    function getExpandedNavStyles() {
        const styles = ['expanded-nav-container'];
        if (!isBurgerOpen) {
            styles.push('hide');
        }
        if (props.isMobileView) {
            styles.push('mobile');
        }
        return styles.join(' ');
    }

    return (
        <Fragment>
            <Navbar id="header-nav-bar" className={'fixed-top'} light>
                <Nav className="ml-auto align-center" navbar>
                    <div className="navbar-brand-container colapsed">
                        <NavbarBrand href={'/'}>{smallMobile ? <RdlBowTie /> : <ResIcon />}</NavbarBrand>
                        {!props.isMobileView && (
                            <NavItem className="provider-section">
                                <div>{props.restaurantName}</div>
                                <div>
                                    {'ID'} {props.restaurantId}
                                </div>
                            </NavItem>
                        )}
                    </div>
                    <div id="alert-button" ref={props.buttonRef} className="alert-button">
                        <NavItem className="icon">
                            <NavLink
                                tag="button"
                                className="btn btn-link my-tooltip"
                                onClick={() => {
                                    if (props.alerts.length > 0) {
                                        props.toggleAlertsPanel();
                                    }
                                }}
                            >
                                <div className={props.newAlertsLength > 0 ? 'alert-bell' : null}>
                                    <div className={props.newAlertsLength > 0 ? 'alert-bell-icon' : null}>
                                        <AlertBellIcon />
                                    </div>
                                    {props.newAlertsLength > 0 && (
                                        <div className="alert-dot-container">
                                            <div className="alert-dot">
                                                {props.newAlertsLength > 9 ? '9+' : props.newAlertsLength}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </NavLink>
                        </NavItem>
                    </div>
                    {props.getAlertsDropdown()}
                    <NavbarToggler onClick={() => toggleIsBurgerOpen()} className="ml-auto navbar-toggler" />
                </Nav>
            </Navbar>
            <div className={getExpandedNavStyles()}>
                <Nav navbar>
                    {props.isMobileView && (
                        <NavItem className="provider-section">
                            <div>{props.restaurantName}</div>
                            <div>
                                {'ID'} {props.restaurantId}
                            </div>
                        </NavItem>
                    )}
                    <NavItem>
                        <NavLink
                            tag={Link}
                            className={
                                props.pathName === '/Bookings' || props.pathName === '/' ? 'current-nav-location' : ''
                            }
                            onClick={() => toggleIsBurgerOpen()}
                            to="/Bookings"
                        >
                            <FormattedMessage id="Common.Bookings" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            tag={Link}
                            className={props.pathName === '/Vouchers' ? 'current-nav-location' : ''}
                            to="/Vouchers"
                            onClick={() => toggleIsBurgerOpen()}
                        >
                            <FormattedMessage id="Common.Vouchers" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            tag={Link}
                            className={props.isInSettingsTab ? 'current-nav-location' : ''}
                            to="/Settings"
                            onClick={() => toggleIsBurgerOpen()}
                        >
                            <FormattedMessage id="Common.Settings" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => props.openHelpAndSupportModal()}>
                            <FormattedMessage id="Common.Help" />
                        </NavLink>
                    </NavItem>
                    <div className="profile-section">
                        <div onClick={() => toggleIsUsernameSectionExpanded()} className="username">
                            {props.userDisplayName}
                            {getArrow()}
                        </div>
                        {isUsernameSectionExpanded && props.isAdmin && (
                            <div onClick={props.returnToProviderSelection}>
                                <FormattedMessage id="Common.ReturnToProviderSelection" />
                            </div>
                        )}
                        {isUsernameSectionExpanded && (
                            <div className="logout" onClick={() => props.logOut()}>
                                <FormattedMessage id="Common.Logout" />
                            </div>
                        )}
                    </div>
                </Nav>
            </div>
        </Fragment>
    );
}

CollapsedNavbar.propTypes = {
    restaurantName: PropTypes.string.isRequired,
    restaurantId: PropTypes.number.isRequired,
    toggle: PropTypes.func.isRequired,
    pathName: PropTypes.string.isRequired,
    isInsettingsTab: PropTypes.bool,
    alerts: PropTypes.array,
    toggleAlertsPanel: PropTypes.func,
    getAlertsDropdown: PropTypes.func,
    openHelpAndSupportModal: PropTypes.func,
    logOut: PropTypes.func,
    buttonRef: PropTypes.object,
    newAlertsLength: PropTypes.number,
    userDisplayName: PropTypes.string,
};

export default CollapsedNavbar;
