import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import BookingSummaryOverlay from './BookingSummaryOverlay';
import CustomerOverlayContent from './CustomerOverlayContent';
import CustomerCard from './CustomerCard';
import BookingSummaryAdditionalInfo from './BookingSummaryAdditionalInfo';
import MenuOverlayContent from './MenuOverlayContent';
import DiaryContext from '../../contexts/DiaryContext';
import orderAtTableStatus from '../../enums/orderAtTableStatus';

function BookingOverlayContent(props) {
    const diaryContext = useContext(DiaryContext);

    function replaceCustomer(customerId, callback) {
        return props.replaceCustomer(customerId, props.overlayContent.booking.id, callback);
    }

    return (
        <Fragment>
            {!props.showCustomerOverlay && (
                <Fragment>
                    <BookingSummaryOverlay
                        overlayContent={props.overlayContent}
                        updateArrivalStatus={props.updateArrivalStatus}
                        closeBooking={props.closeBooking}
                        openCancellationModalWithBookingId={props.openCancellationModalWithBookingId}
                        toggleReconfirmedStatus={props.toggleReconfirmedStatus}
                        bookingStatusControlOpen={props.bookingStatusControlOpen}
                        toggleBookingStatusTooltip={props.toggleBookingStatusTooltip}
                        rebookBooking={props.rebookBooking}
                        closeBookingStatusControl={props.closeBookingStatusControl}
                        tableLabels={props.tableLabels}
                    />
                    <CustomerCard
                        customer={props.overlayContent.booking.customer}
                        toggleShowCustomerOverlay={props.toggleShowCustomerOverlay}
                        replaceCustomer={replaceCustomer}
                        isReadOnly
                    />
                    <div className="additional-info-wrapper">
                        {diaryContext.hasSquareEnabled &&
                            props.overlayContent.booking.orderAtTableStatus !== orderAtTableStatus.Completed &&
                            !props.overlayContent.booking.hasUnprocessedPayments && (
                                <MenuOverlayContent
                                    overlayContent={props.overlayContent}
                                    menus={props.menus}
                                    sendMenuNotification={props.sendMenuNotification}
                                    openMenuChangeModal={props.openMenuChangeModal}
                                />
                            )}
                        <BookingSummaryAdditionalInfo
                            overlayContent={props.overlayContent}
                            bookingReasons={props.bookingReasons}
                        />
                    </div>
                </Fragment>
            )}
            {props.showCustomerOverlay && (
                <CustomerOverlayContent
                    toggleShowCustomerOverlay={props.toggleShowCustomerOverlay}
                    countryCodes={props.countryCodes}
                    countryCode={props.countryCode}
                    addNewCustomer={props.addNewCustomer}
                    updateCustomer={props.updateCustomer}
                    bookingId={props.overlayContent.booking.id}
                    customer={props.overlayContent.booking.customer}
                    isEditingCustomer={props.isEditingCustomer}
                    isMakingRequest={props.isMakingRequest}
                    customerSearchTerm={props.customerSearchTerm}
                />
            )}
        </Fragment>
    );
}

BookingOverlayContent.propTypes = {
    overlayContent: PropTypes.object,
    bookingReasons: PropTypes.array,
    updateArrivalStatus: PropTypes.func,
    closeBooking: PropTypes.func,
    openCancellationModalWithBookingId: PropTypes.func,
    openMenuChangeModal: PropTypes.func,
    toggleReconfirmedStatus: PropTypes.func,
    bookingStatusControlOpen: PropTypes.bool,
    toggleBookingStatusTooltip: PropTypes.func,
    toggleShowCustomerOverlay: PropTypes.func,
    showCustomerOverlay: PropTypes.bool,
    rebookBooking: PropTypes.func,
    closeBookingStatusControl: PropTypes.func,
    tableLabels: PropTypes.array,
    customerSearchTerm: PropTypes.string,
    sendMenuNotification: PropTypes.func,
    menus: PropTypes.array,
};

export default BookingOverlayContent;
