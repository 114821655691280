import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PaginatorPages from './PaginatorPages';
import PaginatorSizer from './PaginatorSizer';
import paginatorMode from '../../enums/paginatorMode';
import { ReactComponent as ChevronLeft } from '../../content/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../content/icons/chevron-right.svg';

const pageSizes = [20, 50, 200];

function Paginator(props) {
    return (
        <Fragment>
            <div className="row">
                <Fragment>
                    <div className={props.showPageSizeSelector ? 'col-md-6' : ''}>
                        <PaginatorSizer
                            pageSize={props.pageSize}
                            pageSizes={pageSizes}
                            onPageSizeChange={props.onPageSizeChange}
                            showPageSizeSelector={props.showPageSizeSelector}
                        />
                    </div>
                    {props.numberOfPages !== 1 && (
                        <div className={props.showPageSizeSelector ? 'col-md-6' : 'col-md-12'}>
                            <nav className="float-right">
                                <ul className="pagination">
                                    <li
                                        onClick={() => {
                                            props.paginatorMode === paginatorMode.previousNext
                                                ? props.onSelectPage(props.selectedPage - 1)
                                                : props.onSelectPage(1);
                                        }}
                                        className={props.selectedPage > 1 ? 'page-item' : 'page-item disabled'}
                                    >
                                        <button
                                            className={
                                                'page-link page-shifter' +
                                                (props.paginatorMode === paginatorMode.previousNext
                                                    ? ' chevron-button'
                                                    : '')
                                            }
                                        >
                                            <span>
                                                {props.paginatorMode === paginatorMode.previousNext ? (
                                                    <ChevronLeft />
                                                ) : (
                                                    <FormattedMessage id="Common.First" />
                                                )}
                                            </span>
                                        </button>
                                    </li>
                                    <PaginatorPages
                                        numberOfPages={props.numberOfPages}
                                        onSelectPage={props.onSelectPage}
                                        selectedPage={props.selectedPage}
                                    />
                                    <li
                                        onClick={() => {
                                            props.paginatorMode === paginatorMode.previousNext
                                                ? props.onSelectPage(props.selectedPage + 1)
                                                : props.onSelectPage(props.numberOfPages);
                                        }}
                                        className={
                                            props.selectedPage < props.numberOfPages
                                                ? 'page-item'
                                                : 'page-item disabled'
                                        }
                                    >
                                        <button
                                            className={
                                                'page-link page-shifter' +
                                                (props.paginatorMode === paginatorMode.previousNext
                                                    ? ' chevron-button'
                                                    : '')
                                            }
                                        >
                                            <span>
                                                {props.paginatorMode === paginatorMode.previousNext ? (
                                                    <ChevronRight className="chevron-button" />
                                                ) : (
                                                    <FormattedMessage id="Common.Last" />
                                                )}
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    )}
                </Fragment>
            </div>
        </Fragment>
    );
}

Paginator.propTypes = {
    onSelectPage: PropTypes.func.isRequired,
    selectedPage: PropTypes.number.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalItemCount: PropTypes.number.isRequired,
    onPageSizeChange: PropTypes.func,
    showPageSizeSelector: PropTypes.bool,
    paginatorMode: PropTypes.string,
};

export default Paginator;
