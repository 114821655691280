import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import Dropdown from '../common/Dropdown';
import ValidationMessage from '../common/ValidationMessage';
import VoucherStatus from '../../enums/voucherStatus';
import CurrencyDiv from '../common/CurrencyDiv';
import AddOnContext from '../../contexts/AddOnContext';
import addOnType from '../../enums/addOnType';

function NewBookingAdditionalInfo(props) {
    const intl = useIntl();
    const addOnContext = useContext(AddOnContext);

    function mapTableLabels() {
        if (props.tableLabels) {
            return props.tableLabels.map((label) => ({
                text: `${label.name} (${label.covers})`,
                value: label.id,
            }));
        } else {
            return [];
        }
    }

    function setSelectedBookingReason(reason) {
        props.setBookingReason([...props.bookingReason, reason]);
    }

    function popSelectedBookingReason(reason) {
        let reasonCopy = [...props.bookingReason];
        let index = reasonCopy.indexOf(reason);
        reasonCopy.splice(index, 1);
        props.setBookingReason(reasonCopy);
    }

    function popAllReasons() {
        props.setBookingReason([]);
    }

    function setSelectedTables(table) {
        props.setBookingTables([...props.bookingTables, table]);
    }

    function popSelectedTable(table) {
        let tableCopy = [...props.bookingTables];
        let index = tableCopy.indexOf(table);
        tableCopy.splice(index, 1);
        props.setBookingTables(tableCopy);
    }

    function popAllTables() {
        props.setBookingTables([]);
    }

    function getVoucherErrorMessage(voucherStatus) {
        switch (voucherStatus) {
            case VoucherStatus.invalidVoucher:
                return intl.formatMessage({ id: 'Vouchers.VoucherIsInvalidMessage' });
            case VoucherStatus.expired:
                return intl.formatMessage({ id: 'Vouchers.VoucherIsExpiredMessage' });
            case VoucherStatus.redeemed:
                return intl.formatMessage({ id: 'Vouchers.VoucherIsRedeemedMessage' });
            default:
                return null;
        }
    }

    return (
        <div className="new-booking-additional-info-wrapper">
            <div className="new-booking-additional-info-component">
                <h6>
                    <FormattedMessage id="Bookings.BookingCommentsOptional" />
                </h6>
                <Input
                    type="textarea"
                    value={props.bookingComments}
                    onChange={(e) => props.setBookingComments(e.target.value)}
                ></Input>
            </div>
            <div className="new-booking-additional-info-component">
                <Dropdown
                    multiSelect
                    onValueChange={setSelectedBookingReason}
                    defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                    options={props.bookingReasons}
                    selectedValues={props.bookingReason}
                    onMultiSelectRemoved={popSelectedBookingReason}
                    onClearSelectedValues={popAllReasons}
                    title={intl.formatMessage({ id: 'Bookings.BookingReasonOptional' })}
                />
            </div>
            <div className="new-booking-additional-info-component">
                <Dropdown
                    multiSelect
                    onValueChange={setSelectedTables}
                    defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                    options={mapTableLabels(props.tableLabels)}
                    selectedValues={props.bookingTables}
                    onMultiSelectRemoved={popSelectedTable}
                    onClearSelectedValues={popAllTables}
                    title={intl.formatMessage({ id: 'Bookings.BookingTableOptional' })}
                />
            </div>
            {addOnContext.isAddOnEnabled(addOnType.vouchers) && (
                <div className="new-booking-additional-info-component voucher-input-wrapper">
                    <h6>
                        <FormattedMessage id="Bookings.VoucherRef" />
                    </h6>
                    {props.appliedVoucher && props.appliedVoucher.reference && (
                        <Fragment>
                            <div className="blue-pill">{props.appliedVoucher.reference}</div>
                            <div className="voucher-name">
                                <CurrencyDiv price={props.appliedVoucher.cost} /> {props.appliedVoucher.name}
                            </div>
                        </Fragment>
                    )}
                    {(!props.appliedVoucher || !props.appliedVoucher.reference) && (
                        <div className="d-flex">
                            <Input
                                type="text"
                                name="redeemVoucherCode"
                                value={props.voucherReference}
                                onChange={(e) => props.setVoucherReference(e.target.value)}
                                placeholder={intl.formatMessage({ id: 'Vouchers.EnterVoucherPlaceholder' })}
                            />
                            <Button
                                color="primary"
                                onClick={() => props.redeemVoucherCode()}
                                disabled={!props.voucherReference || props.voucherReference.length < 7}
                            >
                                {intl.formatMessage({
                                    id: 'Common.Redeem',
                                })}
                            </Button>
                        </div>
                    )}

                    {props.voucherReferenceStatus && props.voucherReferenceStatus !== VoucherStatus.valid && (
                        <ValidationMessage message={getVoucherErrorMessage(props.voucherReferenceStatus)}>
                            {' '}
                        </ValidationMessage>
                    )}
                </div>
            )}
        </div>
    );
}

NewBookingAdditionalInfo.propTypes = {
    bookingReasons: PropTypes.array.isRequired,
    bookingReason: PropTypes.array.isRequired,
    setBookingReason: PropTypes.func.isRequired,
    bookingComments: PropTypes.string.isRequired,
    setBookingComments: PropTypes.func.isRequired,
    bookingTables: PropTypes.array.isRequired,
    tableLabels: PropTypes.array,
    setBookingTables: PropTypes.func.isRequired,
};

export default NewBookingAdditionalInfo;
