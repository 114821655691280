class TermsPrivacy {
    constructor(terms) {
        this.termsConditions = terms && terms.TermsConditions ? terms.TermsConditions : '';
        this.privacyPolicy = terms && terms.PrivacyPolicy ? terms.PrivacyPolicy : '';
    }

    getDto(termsConditions, privacyPolicy) {
        return {
            TermsConditions: termsConditions,
            PrivacyPolicy: privacyPolicy,
        };
    }
}

export default TermsPrivacy;
