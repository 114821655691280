import { v4 as uuidv4 } from 'uuid';

class ModifierOption {
    constructor(modifier) {
        this.id = null;
        this.eposId = modifier && modifier.Id ? modifier.Id : null;
        this.name = modifier && modifier.Name ? modifier.Name : '';
        this.price = modifier && modifier.Price ? modifier.Price : 0;
        this.uniqueIdentifier = uuidv4();
    }

    populateFromDiaryDto(diaryModifierOptionDto) {
        this.id = diaryModifierOptionDto.Id;
        this.eposId = diaryModifierOptionDto.EposId;
        this.name = diaryModifierOptionDto.Name;
        this.price = diaryModifierOptionDto.Price;
    }
}

export default ModifierOption;
