import React from 'react';
import PropTypes from 'prop-types';
import RDLModal from '../../common/RDLModal';
import DeletionModal from '../../common/DeletionModal';
import { useIntl } from 'react-intl';
import ServiceUpdateModalType from '../../../enums/serviceUpdateModalType';
import propTypeExtensions from '../../../helpers/PropTypeExtensions';

function ServiceUpdateModal(props) {
    const intl = useIntl();

    function getModal() {
        switch (props.updateType) {
            case ServiceUpdateModalType.update:
                return (
                    <RDLModal
                        isModalOpen={props.isModalOpen}
                        setIsModalOpen={props.setIsModalOpen}
                        action={props.updateService}
                        title={intl.formatMessage({ id: 'Settings.ServiceModalTitle' })}
                        body={intl.formatMessage({ id: 'Settings.ServiceModalWarningText' })}
                        actionButtonText={intl.formatMessage({ id: 'Common.Update' })}
                        actionButtonPendingText={intl.formatMessage({ id: 'Common.Updating' })}
                    />
                );

            case ServiceUpdateModalType.delete:
                return (
                    <DeletionModal
                        isModalOpen={props.isModalOpen}
                        setIsModalOpen={props.setIsModalOpen}
                        deleteItem={props.updateService}
                        title={props.deletionModalTitle}
                        bodyText={intl.formatMessage({ id: 'Settings.ServiceModalDeleteWarningText' })}
                    />
                );

            case ServiceUpdateModalType.disable:
                return (
                    <RDLModal
                        isModalOpen={props.isModalOpen}
                        setIsModalOpen={props.setIsModalOpen}
                        action={props.updateService}
                        title={intl.formatMessage({ id: 'Settings.ServiceModalDisableTitle' })}
                        body={intl.formatMessage({ id: 'Settings.ServiceModalDisableWarningText' })}
                        actionButtonText={intl.formatMessage({ id: 'Common.Disable' })}
                        actionButtonPendingText={intl.formatMessage({ id: 'Common.Disabling' })}
                    />
                );

            default:
                break;
        }
    }

    return getModal();
}

ServiceUpdateModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    updateService: PropTypes.func.isRequired,
    updateType: PropTypes.string.isRequired,
    deletionModalTitle: propTypeExtensions.requiredIf(
        PropTypes.string,
        (props) => props.updateType === ServiceUpdateModalType.delete
    ),
};

export default ServiceUpdateModal;
