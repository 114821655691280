import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, FormGroup } from 'reactstrap';
import Dropdown from '../../common/Dropdown';
import CurrencyDiv from '../../common/CurrencyDiv';
import CheckboxGroup from '../../common/CheckboxGroup';
import menuItemCheckboxValue from '../../../enums/menuItemCheckboxValue';

function EditMenuItemModifierSection(props) {
    const intl = useIntl();
    const [selectedItem, setSelectedItem] = useState(props.modifier ? props.modifier : null);

    function getOptions() {
        if (props.modifiers) {
            const results = props.modifiers.map((item) => ({
                value: item.uniqueIdentifier,
                text: `${item.name} ${
                    item.options.length === 1
                        ? intl.formatMessage({ id: 'Settings.ModifierTotalItem' }, { totalItems: item.options.length })
                        : intl.formatMessage({ id: 'Settings.ModifierTotalItems' }, { totalItems: item.options.length })
                }`,
            }));
            return results;
        } else {
            return [];
        }
    }

    function getButtonText() {
        if (props.editingSection) {
            return <FormattedMessage id="Common.Update" />;
        }
        return <FormattedMessage id="Common.Add" />;
    }

    function handleItemSelected(uniqueIdentifier) {
        const item = props.modifiers.find((item) => item.uniqueIdentifier === uniqueIdentifier);
        setSelectedItem(item);
    }

    function getSelectedSectionsOptions() {
        return [
            new Option(intl.formatMessage({ id: 'Settings.MultipleChoice' }), menuItemCheckboxValue.multipleChoice),
            new Option(intl.formatMessage({ id: 'Settings.ChooseOneItem' }), menuItemCheckboxValue.chooseOneItem),
        ];
    }

    function menuItemModifierCheckboxToggled(id) {
        const newModifier = { ...selectedItem };
        if (id === menuItemCheckboxValue.multipleChoice) {
            newModifier.allowMultipleChoices = !newModifier.allowMultipleChoices;
        } else if (id === menuItemCheckboxValue.chooseOneItem) {
            newModifier.isRequired = !newModifier.isRequired;
        }
        setSelectedItem(newModifier);
    }

    function getCheckedValues() {
        const checkedValue = [];
        if (selectedItem.allowMultipleChoices) {
            checkedValue.push(menuItemCheckboxValue.multipleChoice.toString());
        }
        if (selectedItem.isRequired) {
            checkedValue.push(menuItemCheckboxValue.chooseOneItem.toString());
        }
        return checkedValue;
    }

    function handleSubmit() {
        if (props.editingSection) {
            props.menuItemModifierEdited(selectedItem);
        } else {
            props.menuItemModifierAdded(selectedItem);
        }
        props.toggleIsOpen();
    }

    return (
        <div
            className={
                props.editingSection
                    ? 'grey-closable-panel menu-section editing mt-3'
                    : 'grey-closable-panel menu-section mt-3'
            }
        >
            <Button
                className="close"
                onClick={() => {
                    props.toggleIsOpen();
                }}
            >
                <span aria-hidden="true">&times;</span>
            </Button>
            <h6 className="heading text-uppercase bold">
                {props.editingSection ? (
                    <FormattedMessage id="Settings.EditModifier" />
                ) : (
                    <FormattedMessage id="Settings.AddModifier" />
                )}
            </h6>
            <div>
                <FormGroup className="panel-form-group">
                    {selectedItem && (
                        <div className="d-flex menu-items-summary">
                            <div className="menu-items-summary-header">
                                <div className="bold-text text-uppercase">
                                    <FormattedMessage id="Common.Title" />
                                </div>
                                <div className="panel-display-item">{selectedItem.name}</div>
                            </div>
                            <div className="menu-items-summary-header">
                                <div className="bold-text text-uppercase">
                                    <FormattedMessage id="Common.Description" />
                                </div>
                                <div className="panel-display-item">{selectedItem.dinerQuestion}</div>
                            </div>
                            <div className="menu-items-summary-header">
                                <div className="bold-text text-uppercase">
                                    <FormattedMessage id="Settings.Item" />
                                </div>
                                {selectedItem.options.map((si) => {
                                    return (
                                        <div key={si.uniqueIdentifier} className="panel-display-item">
                                            {si.name}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="menu-items-summary-header">
                                <div className="bold-text text-uppercase">
                                    <FormattedMessage id="Settings.Price" />
                                </div>
                                {selectedItem.options.map((si) => {
                                    return (
                                        <CurrencyDiv
                                            key={si.uniqueIdentifier}
                                            className="panel-display-item"
                                            price={si.price}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {!selectedItem && (
                        <Dropdown
                            title={intl.formatMessage({ id: 'Settings.Modifier' })}
                            onValueChange={handleItemSelected}
                            defaultValue={intl.formatMessage({ id: 'Settings.PleaseSelectAModifier' })}
                            options={getOptions()}
                            hideRemoveItemIcon={true}
                            isSearchable
                        />
                    )}
                </FormGroup>

                {selectedItem && (
                    <FormGroup className="grey-closable-panel-section">
                        <div className="checkbox-group-container">
                            <div>
                                <CheckboxGroup
                                    id="services-checkbox-group"
                                    options={getSelectedSectionsOptions()}
                                    onChange={(option) => {
                                        menuItemModifierCheckboxToggled(parseInt(option.id));
                                    }}
                                    checkedValues={getCheckedValues()}
                                    disabled
                                />
                            </div>
                        </div>
                    </FormGroup>
                )}

                <FormGroup className="grey-closable-panel-footer">
                    <div className="button-wrapper">
                        <Button
                            color="primary"
                            disabled={!selectedItem}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {getButtonText()}
                        </Button>
                    </div>
                </FormGroup>
            </div>
        </div>
    );
}

EditMenuItemModifierSection.propTypes = {
    toggleIsOpen: PropTypes.func.isRequired,
    menuItemModifierAdded: PropTypes.func,
    menuItemModifierEdited: PropTypes.func,
    modifiers: PropTypes.array,
    editingSection: PropTypes.bool,
    modifier: PropTypes.object,
};

export default EditMenuItemModifierSection;
