const voucherTableColumns = {
    VoucherReference: 0,
    Price: 1,
    Purchased: 2,
    CustomerName: 3,
    EmailAddress: 4,
    Phone: 5,
    VoucherName: 6,
    Validity: 7,
    Redemption: 8,
    BookingReference: 9,
    DeliveryDetails: 10,
    PaymentMethod: 11,
    Status: 12,
    Type: 13,
};

export default voucherTableColumns;
