import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import VoucherTableEntry from './VoucherTableEntry';
import PagedTable from '../../common/PagedTable';
import useScreenSize from '../../../hooks/useScreenSize';

function VoucherReportTable(props) {
    const { isMobileView } = useScreenSize();

    function getVoucherEntries() {
        return props.vouchers.map((voucher, i) => {
            return (
                <VoucherTableEntry
                    key={i}
                    voucher={voucher}
                    columnTypes={props.columnTypes}
                    setIsResendVoucherModalOpen={props.setIsResendVoucherModalOpen}
                />
            );
        });
    }

    return (
        <Fragment>
            <div className={isMobileView ? 'voucher-report mob' : 'voucher-report'}>
                <PagedTable
                    tableItems={getVoucherEntries()}
                    headings={props.allColumns}
                    onSorting={props.onSorting}
                    sortOrder={props.sortOrder}
                    sortAscending={props.sortAscending}
                    onSelectPage={props.onSelectPage}
                    selectedPage={props.selectedPage}
                    onPageSizeChange={props.onPageSizeChange}
                    pageSize={props.pageSize}
                    totalItemCount={props.totalItemCount}
                    showSpinner={props.isLoading}
                    showPageSizeSelector={true}
                    emptyTableText={'Vouchers.VoucherReportEmptyLabel'}
                />
            </div>
        </Fragment>
    );
}

VoucherReportTable.propTypes = {
    onSorting: PropTypes.func.isRequired,
    onSelectPage: PropTypes.func.isRequired,
    sortOrder: PropTypes.number.isRequired,
    sortAscending: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    selectedPage: PropTypes.number.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    vouchers: PropTypes.array.isRequired,
    totalItemCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    columnTypes: PropTypes.array,
    allColumns: PropTypes.array.isRequired,
    setIsResendVoucherModalOpen: PropTypes.func.isRequired,
};

export default VoucherReportTable;
