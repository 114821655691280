import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Button, Popover } from 'reactstrap';
import PropTypes from 'prop-types';
import TextWithIcon from './TextWithIcon';
import { ReactComponent as CloseIcon } from '../../content/icons/close.svg';

function ConfirmActionButton(props) {
    const [isToolTipOpen, setToolTipOpen] = useState(props.tooltipOpen ? props.tooltipOpen : false);
    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            setToolTipOpen(props.tooltipOpen);
        }
    }, [props.tooltipOpen]);

    function toggle(e) {
        e.stopPropagation();
        setToolTipOpen(!isToolTipOpen);
        if (props.toggle) {
            props.toggle();
        }
    }

    function getButtonContent() {
        if (props.label && props.icon) {
            return <TextWithIcon icon={props.icon} text={props.label} />;
        } else if (props.label && !props.icon) {
            return <div>{props.label}</div>;
        }
        return <div data-testid="icon-container">{props.icon}</div>;
    }

    return (
        <Fragment>
            <Button
                data-testid="toggle-button"
                outline={props.outline}
                disabled={props.disabled}
                size={props.buttonSize}
                className={props.buttonClassName}
                color={props.buttonColor}
                id={props.id}
                onClick={(e) => toggle(e)}
            >
                {getButtonContent()}
            </Button>
            <Popover
                placement={props.tooltipPlacement}
                isOpen={isToolTipOpen}
                target={props.id}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: props.isMultiSelectorStatusControl,
                    },
                    flip: {
                        enabled: false,
                    },
                }}
            >
                <div
                    className="popover-content-wrapper"
                    data-testid="popover-content"
                    onClick={(e) => e.stopPropagation()}
                >
                    {props.includeCloseIcon && (
                        <button
                            type="button"
                            className="float-right close-button"
                            onClick={(e) => toggle(e)}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </button>
                    )}

                    {props.children}
                </div>
            </Popover>
        </Fragment>
    );
}

ConfirmActionButton.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    buttonColor: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    buttonClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    tooltipOpen: PropTypes.bool,
    outline: PropTypes.bool,
    toggle: PropTypes.func,
    icon: PropTypes.node,
    buttonSize: PropTypes.string,
    includeCloseIcon: PropTypes.bool,
    isMultiSelectorStatusControl: PropTypes.bool,
};

export default ConfirmActionButton;
