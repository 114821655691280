class TableLabelHelper {
    static toJson(tableLabel) {
        return {
            Id: tableLabel.id,
            Name: tableLabel.name,
            Covers: tableLabel.covers,
            UniqueIdentifier: tableLabel.uniqueIdentifier,
        };
    }
}

export default TableLabelHelper;
