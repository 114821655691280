import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import ValidationMessage from '../common/ValidationMessage';

function ValidationInput(props) {
    function getClassNamesString() {
        if (props.errors[props.name] && !props.disabled) {
            if (props.className) return `is-invalid ${props.className}`;
            else return 'is-invalid';
        } else {
            if (props.className) return props.className;
            else return null;
        }
    }

    function getInputLengthClass() {
        return props.value.length.toString() === props.maxLength
            ? 'validation-input-count warning'
            : 'validation-input-count';
    }

    function onChange(event) {
        props.onChange(event.target.value);
    }

    return (
        <Fragment>
            <Input
                className={getClassNamesString()}
                defaultValue={props.value}
                onInput={onChange}
                data-testid={props.testId}
                innerRef={!props.disabled ? props.innerRef : null}
                name={props.name}
                type={props.type || 'text'}
                placeholder={props.placeholder}
                rows={props.rows}
                maxLength={props.maxLength}
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                min={props.min}
                max={props.max}
                onKeyUp={props.onKeyUp}
                aria-labelledby={props.ariaLabelledBy}
                aria-required={props.ariaRequired}
                aria-invalid={props.errors[props.name] && !props.disabled}
            />
            {!props.hideValidationMessage && (
                <div className="validation-input-feedback">
                    {props.errors[props.name] && <ValidationMessage message={props.errors[props.name].message} />}
                    {props.displayCharacterCount && (
                        <div className={getInputLengthClass()}>
                            {props.value.length}/{props.maxLength}
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
}

ValidationInput.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    testId: PropTypes.string,
    innerRef: PropTypes.func,
    name: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    rows: PropTypes.string,
    maxLength: PropTypes.string,
    autoFocus: PropTypes.bool,
    displayCharacterCount: PropTypes.bool,
    hideValidationMessage: PropTypes.bool,
    min: PropTypes.string,
    max: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    onKeyUp: PropTypes.func,
    ariaRequired: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default ValidationInput;
