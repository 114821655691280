const loyaltyReportPageLength = {
    twenty: 0,
    fifty: 1,
    twoHundred: 2,
    fiveHundred: 3,
    oneThousand: 4,
    twoThousand: 5,
};

export default loyaltyReportPageLength;
