import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';

function OverlayDialogue(props) {
    return (
        <div className="overlay-dialogue-wrapper">
            <div className="overlay-dialogue">
                <div className="overlay-dialogue-content-wrapper">
                    {!props.showLoading && (
                        <button className="close-button close" onClick={() => props.setDialogueOpen(false)}>
                            &times;
                        </button>
                    )}

                    <div>
                        {props.showLoading && <LoadingSpinner />}
                        {!props.showLoading && (
                            <div className={props.iconClassName ? 'icon ' + props.iconClassName : 'icon'}>
                                {props.icon}
                            </div>
                        )}
                        {props.message && <div className="message">{props.message}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

OverlayDialogue.propTypes = {
    setDialogueOpen: PropTypes.func,
    showLoading: PropTypes.bool,
    icon: PropTypes.node,
    iconClassName: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default OverlayDialogue;
