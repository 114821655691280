import React, { Fragment, useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import DiaryContext from '../../contexts/DiaryContext';
import SessionContext from '../../contexts/SessionContext';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import DatePickerWrapper from '../common/DatePickerWrapper';
import MomentHelper from '../../helpers/MomentHelper';
import Dropdown from '../common/Dropdown';
import OptionGroup from '../common/OptionGroup';
import LoadingSpinner from '../common/LoadingSpinner';
import InfoBars from '../common/InfoBars';
import infoBarType from '../../enums/infoBarType';
import bookingStatus from '../../enums/bookingStatus';
import OptionPill from './OptionPill';
import NewBookingAdditionalInfo from './NewBookingAdditionalInfo';
import AvailabilitySearchService from '../../services/AvailabilitySearchService';
import TimeSlot from '../../domainObjects/TimeSlot';
import Customer from '../../domainObjects/Customer';
import RedeemedVoucher from '../../domainObjects/RedeemedVoucher';
import moment from 'moment-timezone';
import useDebounce from '../../hooks/useDebounce';
import RestaurantService from '../../services/RestaurantService';
import BookingService from '../../services/BookingService';
import VoucherService from '../../services/VoucherService';
import Service from '../../domainObjects/Service';
import { ReactComponent as ChevronLeft } from '../../content/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../content/icons/chevron-right.svg';
import CustomerCard from './CustomerCard';
import CustomerOverlayContent from './CustomerOverlayContent';
import useValidation from '../../hooks/useValidation';
import usePrevious from '../../hooks/usePrevious';
import ValidationSummary from '../common/ValidationSummary';
import Booking from '../../domainObjects/Booking';
import { useIntl } from 'react-intl';
import CloseOutsService from '../../services/CloseOutsService';
import CloseOut from '../../domainObjects/CloseOut';
import { ReactComponent as NoAvailabilityIcon } from '../../content/icons/Img-Report-Warning.svg';
import ValidationSummaryContainer from '../common/ValidationSummaryContainer';
import useTrackPage from '../../hooks/useTrackPage';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import VoucherStatus from '../../enums/voucherStatus';

let _ID = 0;

function NewBookingOverlayContent(props) {
    const intl = useIntl();
    const [date, setDate] = useState(getDate());
    const [covers, setCovers] = useState(props.isEditingBooking ? props.booking.partySize : 2);
    const [selectedTimeIndex, setSelectedTimeIndex] = useState(0);
    const [nextFreeTimeSlot, setNextFreeTimeSlot] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isSegmentSetupLoading, setIsSegmentSetupLoading] = useState(true);
    const [isAvailableSearchLoading, setIsAvailableSearchLoading] = useState(false);
    const [isCreatingOrUpdatingBooking, setIsCreatingOrUpdatingBooking] = useState(false);
    const [services, setServiceData] = useState([]);
    const [infoBars, setInfoBars] = useState([]);
    const [bookingComments, setBookingComments] = useState(props.isEditingBooking ? props.booking.bookingComments : '');
    const [bookingReason, setBookingReason] = useState(props.isEditingBooking ? props.booking.bookingReasonIds : []);
    const [bookingTables, setBookingTables] = useState(props.isEditingBooking ? props.booking.tableLabelIds : []);
    const [customer, setCustomer] = useState(props.isEditingBooking ? props.booking.customer : null);
    const debouncedDate = useDebounce(date, 300);
    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);
    const retrieveNewSegmentId = useCallback(() => {
        let segmentId;
        diaryContext.segments.forEach((segment) => {
            segment.ValidityPeriods.forEach((p) => {
                if (MomentHelper.isDateWithinRange(debouncedDate, p.StartDate, p.EndDate)) {
                    segmentId = segment.Id;
                    return;
                }
            });
        });

        return segmentId;
    }, [debouncedDate, diaryContext.segments]);

    const [segmentId, setSegmentId] = useState(retrieveNewSegmentId());
    const [isCustomerRequired, setIsCustomerRequired] = useState(false);
    const [closeOuts, setCloseOuts] = useState([]);
    const [activeCloseOut, setActiveCloseOut] = useState(null);
    const { register, errors, submitDisabled, submit, errorCount } = useValidation();
    const prevTimeSlots = usePrevious(timeSlots);
    const [isSelectedTimeOverBooked, setIsSelectedTimeSlotOverBooked] = useState(false);
    const [voucherReference, setVoucherReference] = useState(
        props.isEditingBooking ? props.booking.voucherReference : null
    );
    const [appliedVoucher, setAppliedVoucher] = useState(
        props.isEditingBooking
            ? new RedeemedVoucher({
                  VoucherName: props.booking.voucherName,
                  VoucherReference: props.booking.voucherReference,
                  VoucherCost: props.booking.voucherCost,
              })
            : null
    );
    const [voucherReferenceStatus, setVoucherReferenceStatus] = useState(null);

    useTrackPage('New Booking Side Panel');

    const isOverBooked = useCallback(
        (service, amount) => {
            return service.maxCovers ? amount + covers > service.maxCovers : amount + 1 > service.maxBookings;
        },
        [covers]
    );

    const getBookingsForTimeSlot = useCallback(
        (timeSlot) => {
            return props.bookings.filter((b) => {
                return b.visitDateTime.format('HH:mm') === timeSlot;
            });
        },
        [props.bookings]
    );

    const willTimeSlotBeOverBooked = useCallback(
        (timeSlot) => {
            if (timeSlots.length > 0) {
                const time = moment(timeSlot).format('HH:mm');
                const timeSlotWithSameTime = timeSlots.find((ts) => ts.timeSlot.format('HH:mm') === time);

                if (!timeSlotWithSameTime) {
                    return false;
                }

                const bookingsForTimeSlot = getBookingsForTimeSlot(time);
                const service = services.find((s) => s.id === timeSlotWithSameTime.serviceId);
                if (service) {
                    const amount = service.maxCovers
                        ? getMaxPerIntervalCoversAmount(bookingsForTimeSlot)
                        : bookingsForTimeSlot.length;
                    let overBooked = isOverBooked(service, amount);
                    return overBooked;
                }
            }
        },
        [getBookingsForTimeSlot, isOverBooked, services, timeSlots]
    );

    function getDate() {
        if (props.isEditingBooking && !props.booking.isUnallocated) {
            return props.booking.visitDateTime.clone();
        } else if (props.isEditingBooking && props.booking.isUnallocated) {
            const date = MomentHelper.newInstance();
            date.set('date', props.booking.visitDateTime.date());
            date.set('month', props.booking.visitDateTime.month());
            date.set('year', props.booking.visitDateTime.year());

            return date;
        }

        return MomentHelper.newInstance();
    }

    useEffect(() => {
        setSegmentId(retrieveNewSegmentId(debouncedDate));
        CloseOutsService.getCloseOuts(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            debouncedDate.format('YYYY-MM-DD')
        ).then((response) => {
            setCloseOuts(response.filter((c) => c.ServiceId !== null).map((c) => new CloseOut(c)));
        });
    }, [debouncedDate, diaryContext.deploymentId, diaryContext.restaurantId, retrieveNewSegmentId]);

    useEffect(() => {
        const hasSegmentChanged = retrieveNewSegmentId() !== segmentId;
        const currentSegmentId = hasSegmentChanged ? retrieveNewSegmentId() : segmentId;

        if (hasSegmentChanged || services.length === 0) {
            setIsSegmentSetupLoading(true);
            RestaurantService.getSegmentSetup(diaryContext.deploymentId, diaryContext.restaurantId, currentSegmentId)
                .then((segmentResponse) => {
                    const servicesData = segmentResponse.Services.map((service) => new Service(service));
                    setServiceData(servicesData);
                })
                .catch(() => {
                    addErrorBar();
                })
                .finally(() => {
                    setIsSegmentSetupLoading(false);
                });
        }
    }, [
        addErrorBar,
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        debouncedDate,
        retrieveNewSegmentId,
        segmentId,
        services.length,
    ]);

    useEffect(() => {
        if (prevTimeSlots && prevTimeSlots.length !== timeSlots.length) {
            const currentTimeSlotIndex = timeSlots.findIndex((ts) => moment(ts.timeSlot).isSame(selectedTimeSlot));
            if (currentTimeSlotIndex > -1) {
                setSelectedTimeIndex(currentTimeSlotIndex);
            }
        }

        if (isInitialLoading && timeSlots.length > 0) {
            setIsInitialLoading(false);
            if (props.isEditingBooking && props.booking && !props.booking.isUnallocated) {
                const currentTimeSlotIndex = timeSlots.findIndex((ts) => moment(ts.timeSlot).isSame(debouncedDate));
                if (currentTimeSlotIndex > -1) {
                    setSelectedTimeIndex(currentTimeSlotIndex);
                    setSelectedTimeSlot(MomentHelper.toServerDateTimeFormat(timeSlots[currentTimeSlotIndex].timeSlot));
                }
            } else {
                AvailabilitySearchService.getNextFreeTimeSlot(
                    diaryContext.deploymentId,
                    diaryContext.restaurantId,
                    MomentHelper.toServerDateTimeFormat(debouncedDate),
                    covers
                )
                    .then((response) => {
                        const defaultTimeSlotIndex = timeSlots.findIndex((ts) =>
                            moment(ts.timeSlot).isSame(MomentHelper.toServerDateTimeFormat(moment(response.TimeSlot)))
                        );
                        if (defaultTimeSlotIndex > -1) {
                            setNextFreeTimeSlot(response.TimeSlot);
                            setSelectedTimeIndex(defaultTimeSlotIndex);
                            setSelectedTimeSlot(
                                MomentHelper.toServerDateTimeFormat(timeSlots[defaultTimeSlotIndex].timeSlot)
                            );
                        }
                    })
                    .catch(() => {
                        addErrorBar();
                    });
            }
        }
    }, [
        timeSlots,
        selectedTimeSlot,
        isInitialLoading,
        debouncedDate,
        props.isEditingBooking,
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        covers,
        addErrorBar,
        prevTimeSlots,
        props.booking,
    ]);

    useEffect(() => {
        setIsAvailableSearchLoading(true);
        const bookingIdToExclude = props.isEditingBooking ? [props.booking.id] : null;
        const duration = props.isEditingBooking ? props.booking.duration : null;
        AvailabilitySearchService.search(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            MomentHelper.toServerDateFormat(debouncedDate),
            covers,
            bookingIdToExclude,
            duration
        )
            .then((response) => {
                const newTimeSlots = response.TimeSlots.map((ts) => new TimeSlot(ts));
                setTimeSlots(newTimeSlots);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsAvailableSearchLoading(false);
            });
    }, [
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        debouncedDate,
        covers,
        props.isEditingBooking,
        props.booking,
        addErrorBar,
    ]);

    useEffect(() => {
        if (selectedTimeSlot && !timeSlots.find((ts) => ts.timeSlot.isSame(moment(selectedTimeSlot)))) {
            const selectedTime = moment(selectedTimeSlot).format('HH:mm');
            const timeSlotWithSameTime = timeSlots.find((ts) => ts.timeSlot.format('HH:mm') === selectedTime);
            if (timeSlotWithSameTime) {
                setSelectedTimeSlot(MomentHelper.toServerDateTimeFormat(timeSlotWithSameTime.timeSlot));
            } else {
                setSelectedTimeSlot(null);
            }
        }
        const timeslot = timeSlots.find((ts) => ts.timeSlot.isSame(moment(selectedTimeSlot)));
        if (timeslot) {
            setActiveCloseOut(closeOuts.find((c) => c.serviceId === timeslot.serviceId));
        }
    }, [closeOuts, selectedTimeSlot, timeSlots]);

    useEffect(() => {
        if (selectedTimeSlot) {
            const overBooked = willTimeSlotBeOverBooked(selectedTimeSlot);
            setIsSelectedTimeSlotOverBooked(overBooked);
        }
    }, [selectedTimeSlot, willTimeSlotBeOverBooked]);

    useEffect(() => {
        if (nextFreeTimeSlot && selectedTimeSlot && timeSlots && timeSlots.length > 0) {
            const nextFreeTimeSlotIndex = timeSlots.findIndex((ts) => moment(ts.timeSlot).isSame(nextFreeTimeSlot));
            const oneTimeSlotAheadIndex =
                nextFreeTimeSlotIndex < timeSlots.length - 1 ? nextFreeTimeSlotIndex + 1 : nextFreeTimeSlotIndex;
            const oneTimeSlotBehindIndex = nextFreeTimeSlotIndex > 0 ? nextFreeTimeSlotIndex - 1 : 0;
            const selectedTS = MomentHelper.newInstance(selectedTimeSlot);

            const currentTime = MomentHelper.newInstance();
            const currentTimeInWindow = MomentHelper.isDateWithinRange(
                selectedTS,
                currentTime.clone().subtract(15, 'minutes'),
                currentTime.clone().add(15, 'minutes')
            );

            const isSelectedTimeAWalkin =
                (selectedTS.isSame(timeSlots[oneTimeSlotAheadIndex].timeSlot) && currentTimeInWindow) ||
                (selectedTS.isSame(timeSlots[oneTimeSlotBehindIndex].timeSlot) && currentTimeInWindow) ||
                (selectedTS.isSame(nextFreeTimeSlot) && currentTimeInWindow);

            setIsCustomerRequired(!isSelectedTimeAWalkin);
        }
    }, [nextFreeTimeSlot, selectedTimeSlot, timeSlots]);

    const maxPartySize = diaryContext.maxPartySize ? diaryContext.maxPartySize : 6;

    function getDropdownCoverOptions() {
        const options = [];
        for (let i = 1; i <= maxPartySize; i++) {
            options.push({
                text: i.toString(),
                value: i,
            });
        }
        return options;
    }

    function getCoverOptionButtons() {
        const options = [];
        for (let i = 1; i <= 6; i++) {
            options.push(
                <OptionPill
                    key={i}
                    value={i}
                    onClick={setCovers}
                    selectedValue={covers}
                    disabled={i > maxPartySize}
                    className={getOptionPillClasses(false, i, covers)}
                />
            );
        }
        return options;
    }

    function getMaxPerIntervalCoversAmount(bookings) {
        let totalCovers = 0;
        bookings.forEach((booking) => {
            if (booking.status !== bookingStatus.cancelled) {
                totalCovers += booking.partySize;
            }
        });

        return totalCovers;
    }

    function getMaxPerIntervalBookingsAmount(bookings) {
        return bookings.filter((b) => b.status !== bookingStatus.cancelled).length;
    }

    function getMaxPerIntervalLabel(timeSlot, service) {
        const bookingsForTimeSlot = getBookingsForTimeSlot(timeSlot);

        let labelId;
        let amount;
        let maxPerInterval;

        if (service.maxCovers) {
            amount = getMaxPerIntervalCoversAmount(bookingsForTimeSlot);
            labelId = 'Bookings.MaxPerIntervalCoversLabel';
            maxPerInterval = service.maxCovers;
        } else {
            amount = getMaxPerIntervalBookingsAmount(bookingsForTimeSlot);
            labelId = 'Bookings.MaxPerIntervalBookingsLabel';
            maxPerInterval = service.maxBookings;
        }

        return (
            <span
                className={
                    isOverBooked(service, amount) ? 'max-per-interval-value overbooked' : 'max-per-interval-value'
                }
            >
                <FormattedMessage id={labelId} values={{ amount: amount, maxPerInterval: maxPerInterval }} />
            </span>
        );
    }

    function getDropdownTimeSlotOptions() {
        const timeSlotOptionGroups = [];
        services.forEach((service) => {
            const timeSlotOptions = [];
            const serviceStartTime = service.timeFrom.split(':');
            const serviceStartHour = serviceStartTime[0];
            const serviceStartMinute = serviceStartTime[1];
            const lastBookingTime = service.lastBookingTime.split(':');
            const lastBookingHour = lastBookingTime[0];
            const lastBookingMinute = lastBookingTime[1];
            let serviceStartDateTime = debouncedDate
                .clone()
                .set('hour', serviceStartHour)
                .set('minute', serviceStartMinute)
                .set('second', 0)
                .set('millisecond', 0);
            const lastBookingDateTime = debouncedDate
                .clone()
                .set('hour', lastBookingHour)
                .set('minute', lastBookingMinute)
                .set('second', 0)
                .set('millisecond', 0);

            if (serviceStartDateTime.isAfter(lastBookingDateTime)) {
                lastBookingDateTime.add(1, 'd');
            }
            timeSlots.forEach((ts) => {
                const timeSlotLabel = (
                    <div>
                        <span>{ts.timeSlot.format('HH:mm')}</span>
                        {getMaxPerIntervalLabel(ts.timeSlot.format('HH:mm'), service)}
                    </div>
                );
                if (
                    ts.timeSlot.isSameOrAfter(serviceStartDateTime) &&
                    ts.timeSlot.isSameOrBefore(lastBookingDateTime)
                ) {
                    timeSlotOptions.push({
                        text: timeSlotLabel,
                        value: MomentHelper.toServerDateTimeFormat(ts.timeSlot),
                    });
                } else {
                    // Handles timeslots past midnight
                    const timeSlotAddedDay = ts.timeSlot.clone().add(1, 'd');
                    if (
                        timeSlotAddedDay.isSameOrAfter(serviceStartDateTime) &&
                        timeSlotAddedDay.isSameOrBefore(lastBookingDateTime)
                    ) {
                        timeSlotOptions.push({
                            text: timeSlotLabel,
                            value: MomentHelper.toServerDateTimeFormat(ts.timeSlot),
                        });
                    }
                }
            });
            timeSlotOptionGroups.push(new OptionGroup(service.name, timeSlotOptions));
        });
        return timeSlotOptionGroups;
    }

    function getTimeOptionButtons() {
        if (timeSlots && timeSlots.length > 0) {
            const options = [];
            let maxLength = selectedTimeIndex + 7;
            let startingIndex = selectedTimeIndex;
            if (maxLength >= timeSlots.length) {
                maxLength = timeSlots.length - 1;
                startingIndex = maxLength - 7;
                if (startingIndex < 0) {
                    startingIndex = 0;
                }
            }
            for (startingIndex; startingIndex <= maxLength; startingIndex++) {
                const currentTimeSlot = timeSlots[startingIndex];
                const value = MomentHelper.toServerDateTimeFormat(currentTimeSlot.timeSlot);
                let isCloseoutActive = closeOuts.some((ac) => ac.serviceId === currentTimeSlot.serviceId);
                const overBooked = willTimeSlotBeOverBooked(currentTimeSlot.timeSlot);
                options.push(
                    <OptionPill
                        key={value}
                        value={value}
                        displayValue={timeSlots[startingIndex].timeSlot.format('HH:mm')}
                        onClick={setSelectedTimeSlot}
                        selectedValue={selectedTimeSlot}
                        className={getOptionPillClasses(isCloseoutActive, value, selectedTimeSlot, overBooked)}
                    />
                );
            }
            return options;
        }
    }

    function getOptionPillClasses(isCloseoutActive, value, selectedValue, isOverBooked) {
        const classes = ['option-pill', 'btn'];
        if (isCloseoutActive || isOverBooked) {
            classes.push('option-pill-orange');
            if (selectedValue === value) {
                classes.push('btn-dark-orange');
            }
        } else {
            if (selectedValue === value) {
                classes.push('btn-dark');
            }
        }
        return classes.join(' ');
    }

    if (isSegmentSetupLoading || isAvailableSearchLoading) {
        return <LoadingSpinner loadingText={<FormattedMessage id="Bookings.SearchingForAvailability" />} />;
    }

    function decrementSelectedTimeIndex() {
        if (selectedTimeIndex - 8 < 0) {
            setSelectedTimeIndex(0);
        } else {
            setSelectedTimeIndex(selectedTimeIndex - 8);
        }
    }

    function incrementSelectedTimeIndex() {
        if (selectedTimeIndex + 8 >= timeSlots.length) {
            setSelectedTimeIndex(timeSlots.length - 8);
        } else {
            setSelectedTimeIndex(selectedTimeIndex + 8);
        }
    }

    function getCreateBookingTrackProperties() {
        return {
            bookingDate: moment(selectedTimeSlot).format('YYYY-MM-DD'),
            bookingCovers: covers,
            bookingTime: moment(selectedTimeSlot).format('HH:mm'),
        };
    }

    function trackCreatedBooking() {
        AnalyticsHelper.trackClickWithProperties('Booking Created', getCreateBookingTrackProperties());
    }

    function createBooking() {
        const chosenTimeSlot = timeSlots.find((ts) => ts.timeSlot.isSame(moment(selectedTimeSlot)));
        setIsCreatingOrUpdatingBooking(true);
        BookingService.createBooking(diaryContext.deploymentId, diaryContext.restaurantId, {
            Booking: {
                VisitDateTime: selectedTimeSlot,
                PartySize: covers,
                Tables: chosenTimeSlot.tableIds,
                Comments: bookingComments,
                BookingReasonIds: bookingReason,
                CustomerId: customer ? customer.id : 0,
                TableLabelIds: bookingTables,
            },
        })
            .then((bookingData) => {
                props.postBookingCreatedCallback(bookingData, appliedVoucher);
                props.addSuccessBar(intl.formatMessage({ id: 'Bookings.BookingCreatedSuccessMessage' }));
                trackCreatedBooking();
                props.closeOverlay();
            })
            .catch(() => {
                setIsCreatingOrUpdatingBooking(false);
            });
    }

    function updateBooking() {
        const chosenTimeSlot = timeSlots.find((ts) => ts.timeSlot.isSame(moment(selectedTimeSlot)));
        setIsCreatingOrUpdatingBooking(true);
        const updatedBooking = new Booking(props.booking.toJson());
        if (updatedBooking.status === bookingStatus.cancelled) {
            updatedBooking.status = bookingStatus.confirmed;
        }
        updatedBooking.bookingDateTime = props.booking.bookingDateTime;
        updatedBooking.visitDateTime = selectedTimeSlot;
        updatedBooking.partySize = covers;
        updatedBooking.tables = chosenTimeSlot.tableIds;
        updatedBooking.tableLabelIds = bookingTables;
        updatedBooking.bookingComments = bookingComments;
        updatedBooking.bookingReasonIds = bookingReason;
        updatedBooking.customerId = customer ? customer.id : 0;
        updatedBooking.serviceId = chosenTimeSlot.serviceId;
        updatedBooking.isUnallocated = props.booking.isUnallocated;
        updatedBooking.orderAtTableStatus = props.booking.orderAtTableStatus;
        updatedBooking.preorderMenuName = props.booking.preorderMenuName;
        props.updateBooking(
            updatedBooking,
            null,
            !MomentHelper.newInstance(updatedBooking.visitDateTime).isSame(props.diaryDate, 'day'),
            appliedVoucher && props.booking.voucherReference !== appliedVoucher.reference ? appliedVoucher : null
        );
    }

    function getTimePillSelector() {
        return (
            <div className="time-selection-pill-selector">
                {timeSlots.length > 0 && (
                    <Fragment>
                        <Button
                            className={`toggle-button ${selectedTimeIndex > 0 ? '' : 'hide'}`}
                            onClick={decrementSelectedTimeIndex}
                        >
                            <ChevronLeft />
                        </Button>
                        <div className="time-selection-pills">{getTimeOptionButtons()}</div>
                        <Button
                            className={`toggle-button ${selectedTimeIndex + 8 < timeSlots.length ? '' : 'hide'}`}
                            onClick={incrementSelectedTimeIndex}
                        >
                            <ChevronRight />
                        </Button>
                    </Fragment>
                )}
                {timeSlots.length === 0 && (
                    <div className="no-availability">
                        <NoAvailabilityIcon />
                        <div>
                            <FormattedMessage id="Bookings.NoAvailability" />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    function setSelectedTimeSlotFromDropdown(selectedTimeSlotFromDropdown) {
        const timeSlotIndex = timeSlots.findIndex((ts) =>
            moment(ts.timeSlot).isSame(moment(selectedTimeSlotFromDropdown))
        );
        if (timeSlotIndex >= 0) {
            setSelectedTimeIndex(timeSlotIndex);
            setSelectedTimeSlot(MomentHelper.toServerDateTimeFormat(timeSlots[timeSlotIndex].timeSlot));
        }
    }

    function updateCustomerCallback(customer) {
        setCustomer(new Customer(customer));
    }

    function replaceCustomer(customerId, callback) {
        props.replaceCustomer(customerId, null, updateCustomerCallback);
        if (callback) {
            callback();
        }
    }

    function getSubmitButtonText() {
        if (props.isEditingBooking) {
            return isCreatingOrUpdatingBooking ? 'Bookings.UpdatingBooking' : 'Bookings.UpdateBooking';
        } else {
            return isCreatingOrUpdatingBooking ? 'Bookings.CreatingBooking' : 'Bookings.Book';
        }
    }

    function getIsButtonDisabled() {
        if (props.isEditingBooking) {
            let customerChanged = false;
            if (props.booking.customer && customer) {
                customerChanged = props.booking.customer.id !== customer.id;
            } else {
                customerChanged = customer && !props.booking.customer;
            }
            return (
                (props.booking.bookingReasonIds === bookingReason &&
                    props.booking.partySize === covers &&
                    props.booking.tableLabelIds &&
                    props.booking.voucherReference === appliedVoucher.reference &&
                    JSON.stringify(props.booking.tableLabelIds.sort()) === JSON.stringify(bookingTables.sort()) &&
                    props.booking.bookingComments === bookingComments &&
                    !customerChanged &&
                    props.booking.visitDateTime.isSame(MomentHelper.newInstance(selectedTimeSlot)) &&
                    props.booking.status !== bookingStatus.cancelled) ||
                isCreatingOrUpdatingBooking ||
                !selectedTimeSlot
            );
        } else {
            return isCreatingOrUpdatingBooking || !selectedTimeSlot;
        }
    }

    function getValidationSummaryContainer() {
        let validationSummaries = [];

        if (activeCloseOut && isSelectedTimeOverBooked) {
            validationSummaries.push(
                {
                    title: intl.formatMessage(
                        {
                            id: 'Bookings.OverBookedTimeSlot',
                        },
                        { timeslot: moment(selectedTimeSlot).format('HH:mm') }
                    ),
                    description: intl.formatMessage(
                        {
                            id: 'Bookings.OverBookedTimeSlotDescription',
                        },
                        { timeslot: moment(selectedTimeSlot).format('HH:mm') }
                    ),
                },
                {
                    title: intl.formatMessage(
                        {
                            id: 'Bookings.CloseoutActive',
                        },
                        { service: services.find((s) => s.id === activeCloseOut.serviceId).name }
                    ),
                    description: activeCloseOut.description,
                }
            );
        } else if (activeCloseOut) {
            validationSummaries.push({
                title: intl.formatMessage(
                    {
                        id: 'Bookings.CloseoutActive',
                    },
                    { service: services.find((s) => s.id === activeCloseOut.serviceId).name }
                ),
                description: activeCloseOut.description,
            });
        } else if (isSelectedTimeOverBooked) {
            validationSummaries.push({
                title: intl.formatMessage(
                    {
                        id: 'Bookings.OverBookedTimeSlot',
                    },
                    { timeslot: moment(selectedTimeSlot).format('HH:mm') }
                ),
                description: intl.formatMessage(
                    {
                        id: 'Bookings.OverBookedTimeSlotDescription',
                    },
                    { timeslot: moment(selectedTimeSlot).format('HH:mm') }
                ),
            });
        }

        return <ValidationSummaryContainer validationErrors={validationSummaries} />;
    }

    function getNewBookingOverlayClassNames() {
        const classes = ['scrollable-panel'];
        if (submitDisabled) {
            classes.push('error-message');
        }
        if (props.showListingsFooter) {
            classes.push('listings-footer');
        }
        return classes.join(' ');
    }

    function getAnalyticsProperties() {
        return {
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
        };
    }

    function getRedeemAnalyticsProperties() {
        return {
            ...getAnalyticsProperties(),
            booking_Date: moment(selectedTimeSlot).format('YYYY-MM-DD'),
            booking_Covers: covers,
            booking_Time: moment(selectedTimeSlot).format('HH:mm'),
            booking_Comments: bookingComments,
            cust_name: customer ? customer.displayFullName() : null,
            voucher_Code: voucherReference,
            reason_for_booking: bookingReason
                ? bookingReason.map((br) => {
                      return props.bookingReasonOptions.find((x) => x.value === br).text;
                  })
                : [],
        };
    }

    function redeemVoucherCode() {
        VoucherService.validateVoucher(diaryContext.deploymentId, diaryContext.restaurantId, voucherReference).then(
            (result) => {
                if (result.VoucherStatus === VoucherStatus.valid) {
                    AnalyticsHelper.trackClickWithProperties('Web Voucher Redemn', getRedeemAnalyticsProperties());
                    setAppliedVoucher(new RedeemedVoucher(result));
                    setVoucherReferenceStatus(result.VoucherStatus);
                } else {
                    AnalyticsHelper.trackClickWithProperties('Web Voucher Error', {
                        ...getRedeemAnalyticsProperties(),
                        error_reason: result.VoucherStatus,
                    });
                    setVoucherReferenceStatus(result.VoucherStatus);
                }
            }
        );
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            {!props.showCustomerOverlay && (
                <Fragment>
                    <div className={getNewBookingOverlayClassNames()}>
                        <DatePickerWrapper date={date} setDate={setDate} />
                        <div className="new-booking-overlay-content">
                            <div className="covers-label">
                                <FormattedMessage id="Bookings.Covers" />
                            </div>
                            <div className="d-flex covers">
                                {getCoverOptionButtons()}
                                <Dropdown
                                    onValueChange={setCovers}
                                    options={getDropdownCoverOptions()}
                                    selectedValue={covers}
                                    hideRemoveItemIcon
                                />
                            </div>
                            <div className="time-selection">
                                <div className="time-selection-dropdown">
                                    <div className="pb-1">
                                        <FormattedMessage id="Bookings.Time" />
                                    </div>
                                    <Dropdown
                                        onValueChange={setSelectedTimeSlotFromDropdown}
                                        selectedValue={selectedTimeSlot}
                                        optionGroups={getDropdownTimeSlotOptions()}
                                        hideRemoveItemIcon
                                        isDropdownDisabled={timeSlots.length === 0}
                                    />
                                </div>

                                {getValidationSummaryContainer()}
                                {getTimePillSelector()}
                            </div>
                        </div>
                        <div className="customer-section">
                            <CustomerCard
                                customer={customer}
                                toggleShowCustomerOverlay={props.toggleShowCustomerOverlay}
                                replaceCustomer={replaceCustomer}
                                isCustomerRequired={isCustomerRequired}
                                registerValidation={register}
                                errors={errors}
                                includeValidation={isCustomerRequired}
                            />
                        </div>
                        <NewBookingAdditionalInfo
                            bookingReasons={props.bookingReasonOptions}
                            bookingReason={bookingReason}
                            setBookingReason={setBookingReason}
                            bookingComments={bookingComments}
                            setBookingComments={setBookingComments}
                            bookingTables={bookingTables}
                            setBookingTables={setBookingTables}
                            tableLabels={props.tableLabels}
                            voucherReference={voucherReference}
                            setVoucherReference={setVoucherReference}
                            redeemVoucherCode={redeemVoucherCode}
                            voucherReferenceStatus={voucherReferenceStatus}
                            appliedVoucher={appliedVoucher}
                        />
                    </div>
                    <div className="overlay-footer">
                        <div className="overlay-footer-content">
                            <ValidationSummary errorCount={errorCount} submitDisabled={submitDisabled} />
                            <Button
                                className="float-right"
                                color="primary"
                                onClick={() =>
                                    submit(() => (props.isEditingBooking ? updateBooking() : createBooking()))
                                }
                                disabled={getIsButtonDisabled()}
                                data-testid="new-booking-content-book-button"
                            >
                                <FormattedMessage id={getSubmitButtonText()} />
                            </Button>
                        </div>
                    </div>
                </Fragment>
            )}
            {props.showCustomerOverlay && (
                <CustomerOverlayContent
                    toggleShowCustomerOverlay={props.toggleShowCustomerOverlay}
                    countryCodes={props.countryCodes}
                    countryCode={props.countryCode}
                    addNewCustomer={props.addNewCustomer}
                    updateCustomer={props.updateCustomer}
                    isEditingCustomer={props.isEditingCustomer}
                    isMakingRequest={props.isMakingRequest}
                    callback={updateCustomerCallback}
                    customer={customer}
                    bookingId={props.isEditingBooking ? props.booking.id : null}
                    customerSearchTerm={props.customerSearchTerm}
                />
            )}
        </Fragment>
    );
}

NewBookingOverlayContent.propTypes = {
    postBookingCreatedCallback: PropTypes.func.isRequired,
    closeOverlay: PropTypes.func.isRequired,
    bookingReasonOptions: PropTypes.array,
    toggleShowCustomerOverlay: PropTypes.func,
    showCustomerOverlay: PropTypes.bool,
    isEditingCustomer: PropTypes.bool,
    countryCodes: PropTypes.array,
    countryCode: PropTypes.number,
    isMakingRequest: PropTypes.bool,
    addNewCustomer: PropTypes.func,
    updateCustomer: PropTypes.func,
    isEditingBooking: PropTypes.bool,
    tableLabels: PropTypes.array,
    diaryDate: PropTypes.object,
    customerSearchTerm: PropTypes.string,
    bookings: PropTypes.array,
    booking: PropTypes.object,
    showListingsFooter: PropTypes.bool,
};

export default NewBookingOverlayContent;
