import React, { useState, useContext, useEffect, Fragment } from 'react';
import { useIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Label, FormGroup, Form, Input, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../../contexts/SessionContext';
import DiaryContext from '../../contexts/DiaryContext';
import stripeElementFields from '../../enums/stripeElementFields';
import AccountSubmissionService from '../../services/AccountSubmissionService';
import useValidation from '../../hooks/useValidation';
import useScreenSize from '../../hooks/useScreenSize';
import MomentHelper from '../../helpers/MomentHelper';
import stripeBillingCycleType from '../../enums/stripeBillingCycleType';
import ValidationDropdown from '../common/ValidationDropdown';
import ValidationInput from '../common/ValidationInput';
import ValidationMessage from '../common/ValidationMessage';
import getSymbolFromCurrency from 'currency-symbol-map';
import useTrackPage from '../../hooks/useTrackPage';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { ReactComponent as CloseIcon } from '../../content/icons/close.svg';
import stripePromoCodeErrorType from '../../enums/stripePromoCodeErrorType';
import SessionHelper from '../../helpers/SessionHelper';

function StripeCheckoutForm(props) {
    const intl = useIntl();
    const elements = useElements();
    const stripe = useStripe();
    const [cardHolderName, setCardHolderName] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [countryId, setCountryId] = useState(null);
    const [cardNumberError, setCardNumberError] = useState(null);
    const [cardExpiryError, setCardExpiryError] = useState(null);
    const [cardCVCError, setCardCVCError] = useState(null);
    const [trialEndDate, setTrialEndDate] = useState(null);
    const [creatingSubscription, setCreatingSubscription] = useState(false);
    const [addPromoTextOpen, setAddPromoTextOpen] = useState(false);
    const [promoCodeCoupon, setPromoCodeCoupon] = useState(null);
    const [promoCode, setPromoCode] = useState(null);
    const [promoCodeError, setPromoCodeError] = useState(null);
    const [cardDeclinedErrorMessage, setCardDeclinedErrorMessage] = useState('');
    const navigate = useNavigate();
    const sessionContext = useContext(SessionContext);
    const diaryContext = useContext(DiaryContext);
    const formValidation = useValidation();
    const { isTabletView } = useScreenSize();

    useTrackPage('CheckOut Page');

    useEffect(() => {
        if (props.alpha2Code) {
            setCountryId(props.countries.find((c) => c.alpha2Code === props.alpha2Code).id);
        }
    }, [props.alpha2Code, props.countries]);

    useEffect(() => {
        const dateNow = MomentHelper.newInstance();
        setTrialEndDate(dateNow.add(props.productInformation.freeTrialPeriod, 'days'));
    }, [props.productInformation.freeTrialPeriod]);

    function getCountryOptions() {
        return props.countries.map((c) => ({ value: c.id, text: c.name }));
    }
    function getPromoCodeError() {
        switch (promoCodeError) {
            case stripePromoCodeErrorType.invalid:
                return intl.formatMessage({
                    id: 'AccountSubmission.PromoCodeInvalidMessage',
                });
            case stripePromoCodeErrorType.expired:
                return intl.formatMessage({
                    id: 'AccountSubmission.PromoCodeExpiredMessage',
                });
            default:
                return null;
        }
    }

    async function submitStripeCheckout() {
        const cardNumberElement = elements.getElement(CardNumberElement);
        setCardDeclinedErrorMessage('');
        setCreatingSubscription(true);
        await stripe
            .confirmCardSetup(props.clientSecret, {
                payment_method: {
                    card: cardNumberElement,
                    billing_details: {
                        address: {
                            postal_code: postalCode,
                            country: props.countries.find((c) => c.id === countryId).alpha2Code,
                        },
                        email: sessionContext.userName,
                        name: cardHolderName,
                    },
                },
            })
            .then((result) => {
                if (result.error) {
                    setCardDeclinedErrorMessage(result.error.message);
                }
                if (result.setupIntent && result.setupIntent.status === 'succeeded') {
                    let tokenInfo = SessionHelper.getTokenInfo();
                    AccountSubmissionService.createStripeSubscription(
                        sessionContext.userName,
                        props.alpha2Code,
                        props.campaignCode,
                        result.setupIntent.payment_method,
                        promoCode ? promoCode.promotionCodeId : null,
                        tokenInfo.token
                    )
                        .then(() => {
                            const productInformation = props.productInformation;
                            let currentUTC = new Date();
                            const startDate = currentUTC.toISOString();
                            let freeTrialEndDate = new Date();
                            let checkoutValue = productInformation.productPriceTaxInclusive;

                            if (productInformation.freeTrialPeriod && productInformation.freeTrialPeriod > 0) {
                                freeTrialEndDate.setDate(
                                    freeTrialEndDate.getDate() + productInformation.freeTrialPeriod
                                );
                                checkoutValue = 0.0;
                            }

                            const analyticsDto = {
                                value: checkoutValue,
                                currency: productInformation.currency,
                                products: productInformation.productName,
                                start_date: startDate,
                                free_trial_end_date: freeTrialEndDate.toISOString(),
                                email_address: sessionContext.userName,
                            };
                            AnalyticsHelper.trackClickWithProperties('Order Completed', analyticsDto);
                            AnalyticsHelper.identifyUserIdWithProperties(diaryContext.restaurantId, analyticsDto);
                            navigate('/AccountSubmission/Success');
                        })
                        .finally(() => {
                            setCreatingSubscription(false);
                        });
                } else {
                    setCreatingSubscription(false);
                }
            });
    }

    function validatePromoCode() {
        AccountSubmissionService.validatePromoCode(
            promoCodeCoupon,
            props.alpha2Code,
            MomentHelper.toServerDateTimeFormat(MomentHelper.newInstance())
        ).then((result) => {
            if (result.isValid) {
                setPromoCode(result);
                setPromoCodeCoupon(null);
                setAddPromoTextOpen(false);
                setPromoCodeError(null);
            } else {
                setPromoCodeError(result.errorType);
            }
        });
    }

    function onCardFieldChange(event, fieldName) {
        if (event.error) {
            if (fieldName === stripeElementFields.cardCVC) {
                setCardCVCError(event.error.message);
            } else if (fieldName === stripeElementFields.cardNumber) {
                setCardNumberError(event.error.message);
            } else if (fieldName === stripeElementFields.cardExpiry) {
                setCardExpiryError(event.error.message);
            }
        } else {
            if (fieldName === stripeElementFields.cardCVC) {
                setCardCVCError(null);
            } else if (fieldName === stripeElementFields.cardNumber) {
                setCardNumberError(null);
            } else if (fieldName === stripeElementFields.cardExpiry) {
                setCardExpiryError(null);
            }
        }
    }

    function getSubscriptionTypeText() {
        switch (props.productInformation.billingCycle) {
            case stripeBillingCycleType.day:
                return intl.formatMessage(
                    { id: 'AccountSubmission.SubscriptionType' },
                    { type: intl.formatMessage({ id: 'Common.Daily' }) }
                );
            case stripeBillingCycleType.week:
                return intl.formatMessage(
                    { id: 'AccountSubmission.SubscriptionType' },
                    { type: intl.formatMessage({ id: 'Common.Weekly' }) }
                );
            case stripeBillingCycleType.month:
                return intl.formatMessage(
                    { id: 'AccountSubmission.SubscriptionType' },
                    { type: intl.formatMessage({ id: 'Common.Monthly' }) }
                );
            case stripeBillingCycleType.year:
                return intl.formatMessage(
                    { id: 'AccountSubmission.SubscriptionType' },
                    { type: intl.formatMessage({ id: 'Common.Yearly' }) }
                );
            default:
                return intl.formatMessage(
                    { id: 'AccountSubmission.SubscriptionType' },
                    { type: intl.formatMessage({ id: 'Common.Monthly' }) }
                );
        }
    }

    return (
        <div
            className={
                isTabletView
                    ? 'd-flex flex-column stripe-checkout-form-wrapper mobile'
                    : 'd-flex stripe-checkout-form-wrapper'
            }
        >
            <div className={isTabletView ? 'description flex-fill mobile' : 'description flex-fill'}>
                <div className="description-heading-text-box">
                    <h6 className="bold-text">
                        <FormattedMessage id="AccountSubmission.TryResDiaryLite" />
                    </h6>
                    <h5 className="bold-text">
                        <FormattedMessage
                            id="AccountSubmission.DaysFree"
                            values={{ freeTrialPeriod: props.productInformation.freeTrialPeriod }}
                        />
                    </h5>
                    <div>
                        <FormattedMessage
                            id="AccountSubmission.PricePerBillingPeriod"
                            values={{
                                currency: getSymbolFromCurrency(props.productInformation.currency),
                                productPrice: intl.formatNumber(
                                    promoCode
                                        ? promoCode.promotionAppliedTotalPrice
                                        : props.productInformation.productPriceTaxInclusive,
                                    {
                                        minimumFractionDigits: 2,
                                    }
                                ),
                                billingPeriod: props.productInformation.billingCycle,
                            }}
                        />
                    </div>
                </div>
                <div className="product-details">
                    <div className="d-flex">
                        {props.productInformation.productImageUrl && (
                            <div>
                                <div className="product-image">
                                    <img src={props.productInformation.productImageUrl} alt="product logo"></img>
                                </div>
                            </div>
                        )}
                        <div
                            className={
                                props.productInformation.productImageUrl
                                    ? 'product-information'
                                    : 'product-information no-image'
                            }
                        >
                            <div className="d-flex">
                                <div className="d-flex flex-column">
                                    <div>{props.productInformation.productName}</div>
                                    <div className="subscription-type">{getSubscriptionTypeText()}</div>
                                </div>
                                <div className="free-trial-period">
                                    <FormattedMessage
                                        id="AccountSubmission.DaysFree"
                                        values={{ freeTrialPeriod: props.productInformation.freeTrialPeriod }}
                                    />
                                </div>
                            </div>
                            <div className="price-after-trial">
                                <FormattedMessage
                                    id="AccountSubmission.PriceAfter"
                                    values={{
                                        currency: getSymbolFromCurrency(props.productInformation.currency),
                                        price: intl.formatNumber(
                                            promoCode
                                                ? promoCode.promotionAppliedTotalPrice
                                                : props.productInformation.productPriceTaxInclusive,
                                            {
                                                minimumFractionDigits: 2,
                                            }
                                        ),
                                    }}
                                />
                            </div>
                            <div className="sub-total">
                                <FormattedMessage id="AccountSubmission.SubTotal" />
                                <span className="sub-total-price">
                                    {getSymbolFromCurrency(props.productInformation.currency)}
                                    <FormattedNumber
                                        value={props.productInformation.productPriceTaxExclusive}
                                        minimumFractionDigits={2}
                                    />
                                </span>
                            </div>

                            {props.productInformation.taxRateDisplayName &&
                                props.productInformation.taxRatePercentage && (
                                    <div className="promotion-code">
                                        {!addPromoTextOpen && !promoCode && (
                                            <div
                                                className="fake-link bold-text add-promotion-link"
                                                onClick={() => setAddPromoTextOpen(true)}
                                            >
                                                {intl.formatMessage({
                                                    id: 'AccountSubmission.AddPromotionCode',
                                                })}
                                            </div>
                                        )}
                                        {addPromoTextOpen && !promoCode && (
                                            <Fragment>
                                                <div
                                                    className={
                                                        promoCodeError !== null
                                                            ? 'd-flex add-promotion-input has-error'
                                                            : 'd-flex add-promotion-input'
                                                    }
                                                >
                                                    <Input
                                                        type="text"
                                                        name="promoCode"
                                                        value={promoCode}
                                                        onChange={(e) => setPromoCodeCoupon(e.target.value)}
                                                        aria-label={'Promotion Code'}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        onClick={() => validatePromoCode()}
                                                        disabled={!promoCodeCoupon}
                                                    >
                                                        {intl.formatMessage({
                                                            id: 'Common.Apply',
                                                        })}
                                                    </Button>
                                                </div>
                                                {promoCodeError !== null && (
                                                    <div className="promo-code-error-message">
                                                        <ValidationMessage message={getPromoCodeError()}>
                                                            {' '}
                                                        </ValidationMessage>
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                        {promoCode && (
                                            <Fragment>
                                                <div className="active-promo-code btn-dark d-flex">
                                                    {promoCode.couponCode}
                                                    <div className="unapply-promo" onClick={() => setPromoCode(null)}>
                                                        {<CloseIcon />}
                                                    </div>
                                                </div>
                                                <div className="d-flex applied-promo-code">
                                                    <div>{promoCode.promoCodeDescription}</div>
                                                    <div className="ml-auto">
                                                        -{getSymbolFromCurrency(props.productInformation.currency)}
                                                        <FormattedNumber
                                                            value={promoCode.promotionDeduction}
                                                            minimumFractionDigits={2}
                                                        />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                        <div className="d-flex tax-entry">
                                            <div className="tax-rate">
                                                {props.productInformation.taxRateDisplayName} (
                                                {props.productInformation.taxRatePercentage}%){' '}
                                            </div>
                                            <div className="ml-auto">
                                                {getSymbolFromCurrency(props.productInformation.currency)}
                                                <FormattedNumber
                                                    value={
                                                        promoCode
                                                            ? promoCode.promotionAppliedTaxedTotal
                                                            : props.productInformation.taxedTotal
                                                    }
                                                    minimumFractionDigits={2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            <div className="total-summary">
                                <div className="d-flex">
                                    <FormattedMessage id="AccountSubmission.TotalDueAfterTrial" />
                                    <div className="ml-auto">
                                        {getSymbolFromCurrency(props.productInformation.currency)}
                                        <FormattedNumber
                                            value={
                                                promoCode
                                                    ? promoCode.promotionAppliedTotalPrice
                                                    : props.productInformation.productPriceTaxInclusive
                                            }
                                            minimumFractionDigits={2}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex total-today">
                                    <FormattedMessage id="AccountSubmission.TotalDueToday" />
                                    <div className="ml-auto">
                                        {getSymbolFromCurrency(props.productInformation.currency)}
                                        0.00
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={isTabletView ? 'elements flex-fill mobile' : 'elements flex-fill'}>
                <Form
                    className="stripe-checkout-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        formValidation.submit(submitStripeCheckout);
                    }}
                >
                    <h6 className="bold-text payment-details-heading">
                        <FormattedMessage id="AccountSubmission.PaymentDetails" />
                    </h6>
                    <FormGroup className="stripe-checkout-form-group">
                        <Label for="emailAddress">
                            <FormattedMessage id="AccountSubmission.EmailAddress" />
                        </Label>
                        <Input type="text" name="emailAddress" value={sessionContext.userName} disabled />
                    </FormGroup>
                    <FormGroup className="stripe-checkout-form-group">
                        <Label for="cardNumber">
                            <FormattedMessage id="AccountSubmission.CardNumber" />
                        </Label>
                        <CardNumberElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '13px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                                showIcon: true,
                            }}
                            className={'stripe-element-input-wrapper'}
                            onChange={(e) => onCardFieldChange(e, stripeElementFields.cardNumber)}
                        />
                        {cardNumberError && <ValidationMessage message={cardNumberError}></ValidationMessage>}
                    </FormGroup>
                    <FormGroup className="stripe-checkout-form-group">
                        <div className="d-flex">
                            <div
                                className={
                                    isTabletView
                                        ? 'inline-element-input-field mr-5 mobile'
                                        : 'inline-element-input-field mr-5'
                                }
                            >
                                <Label for="cardExpiry">
                                    <FormattedMessage id="AccountSubmission.ExpiryDate" />
                                </Label>
                                <CardExpiryElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '13px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                    className={'stripe-element-input-wrapper'}
                                    onChange={(e) => onCardFieldChange(e, stripeElementFields.cardExpiry)}
                                />
                                {cardExpiryError && <ValidationMessage message={cardExpiryError}></ValidationMessage>}
                            </div>
                            <div
                                className={
                                    isTabletView ? 'inline-element-input-field mobile' : 'inline-element-input-field'
                                }
                            >
                                <Label for="cardCVC">
                                    <FormattedMessage id="AccountSubmission.CVC" />
                                </Label>
                                <CardCvcElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '13px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                                borderColor: '#9e2146',
                                            },
                                        },
                                    }}
                                    className={'stripe-element-input-wrapper'}
                                    onChange={(e) => onCardFieldChange(e, stripeElementFields.cardCVC)}
                                />
                                {cardCVCError && <ValidationMessage message={cardCVCError}></ValidationMessage>}
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup className="stripe-checkout-form-group">
                        <Label for="cardName" id="cardName">
                            <FormattedMessage id="AccountSubmission.NameOnCard" />
                        </Label>
                        <ValidationInput
                            type="text"
                            name="cardName"
                            value={cardHolderName}
                            onChange={setCardHolderName}
                            innerRef={formValidation.register({
                                required: intl.formatMessage(
                                    { id: 'Common.GenericRequiredValidationMessage' },
                                    { fieldName: intl.formatMessage({ id: 'AccountSubmission.NameOnCard' }) }
                                ),
                            })}
                            errors={formValidation.errors}
                            ariaRequired
                            ariaLabelledBy={'cardName'}
                        />
                    </FormGroup>
                    <FormGroup className="stripe-checkout-form-group">
                        <ValidationDropdown
                            formValidation={formValidation}
                            name="countryId"
                            validationRules={{
                                required: intl.formatMessage({
                                    id: 'AccountSubmission.CountryRequiredMessage',
                                }),
                            }}
                            hideRemoveItemIcon
                            onChange={(e) => setCountryId(e)}
                            defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                            options={getCountryOptions()}
                            title={'Country or Region'}
                            isSearchable
                            selectedValue={countryId}
                        />
                    </FormGroup>
                    <FormGroup className="stripe-checkout-form-group postal-code">
                        <Label for="postCode" id="postCode">
                            <FormattedMessage id="AccountSubmission.PostalCode" />
                        </Label>
                        <ValidationInput
                            type="text"
                            name="postalCode"
                            value={postalCode}
                            onChange={setPostalCode}
                            innerRef={formValidation.register({
                                required: intl.formatMessage(
                                    { id: 'Common.GenericRequiredValidationMessage' },
                                    { fieldName: intl.formatMessage({ id: 'AccountSubmission.PostalCode' }) }
                                ),
                            })}
                            errors={formValidation.errors}
                            ariaRequired
                            ariaLabelledBy={'postCode'}
                        />
                    </FormGroup>
                    <FormGroup className="stripe-checkout-form-group">
                        <Input
                            type={'submit'}
                            value={
                                creatingSubscription
                                    ? intl.formatMessage({ id: 'AccountSubmission.StartingTrial' })
                                    : intl.formatMessage({ id: 'AccountSubmission.StartTrial' })
                            }
                            className="create-account-button btn btn-primary"
                            disabled={
                                formValidation.submitDisabled ||
                                cardCVCError ||
                                cardExpiryError ||
                                cardNumberError ||
                                creatingSubscription
                            }
                        />
                        {cardDeclinedErrorMessage && <ValidationMessage message={cardDeclinedErrorMessage} />}
                    </FormGroup>
                    {trialEndDate && (
                        <div className="trial-end-message">
                            <FormattedMessage
                                id="AccountSubmission.TrialEndMessage"
                                values={{
                                    currency: getSymbolFromCurrency(props.productInformation.currency),
                                    price: intl.formatNumber(
                                        promoCode
                                            ? promoCode.promotionAppliedTotalPrice
                                            : props.productInformation.productPriceTaxInclusive,
                                        {
                                            minimumFractionDigits: 2,
                                        }
                                    ),
                                    date: trialEndDate.date(),
                                    month: trialEndDate.format('MMMM'),
                                    year: trialEndDate.year(),
                                }}
                            />
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
}

export default StripeCheckoutForm;
