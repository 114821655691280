import React, { Fragment } from 'react';
import Dropdown from '../common/Dropdown';

function DropdownExamples(props) {
    return (
        <Fragment>
            <h3>Multiselect</h3>
            <Dropdown
                onValueChange={() => {
                    alert('changed');
                }}
                defaultValue={'Please select'}
                options={[
                    { text: 'One', value: '1' },
                    { text: 'Two', value: '2' },
                    { text: 'Three', value: '3' },
                ]}
                selectedValues={['1', '3']}
                multiSelect
                onMultiSelectRemoved={() => {
                    alert('removed');
                }}
                onClearSelectedValues={() => {
                    alert('cleared');
                }}
            />
            <h3>Single select with remove</h3>
            <Dropdown
                onValueChange={() => {
                    alert('changed');
                }}
                defaultValue={'Please select'}
                options={[
                    { text: 'One', value: '1' },
                    { text: 'Two', value: '2' },
                    { text: 'Three', value: '3' },
                ]}
                selectedValue={'1'}
                onMultiSelectRemoved={() => {
                    alert('removed');
                }}
                onClearSelectedValues={() => {
                    alert('cleared');
                }}
            />
            <h3>Single select without remove</h3>
            <Dropdown
                onValueChange={() => {
                    alert('changed');
                }}
                defaultValue={'Please select'}
                options={[
                    { text: 'One', value: '1' },
                    { text: 'Two', value: '2' },
                    { text: 'Three', value: '3' },
                ]}
                selectedValue={'1'}
                onMultiSelectRemoved={() => {
                    alert('removed');
                }}
                onClearSelectedValues={() => {
                    alert('cleared');
                }}
                hideRemoveItemIcon={true}
            />
            <h3>With search</h3>
            <Dropdown
                onValueChange={() => {
                    alert('changed');
                }}
                defaultValue={'Please select'}
                options={[
                    { text: 'One', value: '1' },
                    { text: 'Two', value: '2' },
                    { text: 'Three', value: '3' },
                ]}
                onMultiSelectRemoved={() => {
                    alert('removed');
                }}
                onClearSelectedValues={() => {
                    alert('cleared');
                }}
                isSearchable
            />
        </Fragment>
    );
}

export default DropdownExamples;
