import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { Button, Row, Col, Input } from 'reactstrap';
import DiaryContext from '../../../contexts/DiaryContext';
import RestaurantService from '../../../services/RestaurantService';
import CloseOutsService from '../../../services/CloseOutsService';
import useDebounce from '../../../hooks/useDebounce';
import MomentHelper from '../../../helpers/MomentHelper';
import Service from '../../../domainObjects/Service';
import CloseOut from '../../../domainObjects/CloseOut';
import CloseOutRequest from '../../../domainObjects/CloseOutRequest';
import CheckboxGroup from '../../common/CheckboxGroup';
import DatePickerWrapper from '../../common/DatePickerWrapper';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import infoBarType from '../../../enums/infoBarType';
import InfoBars from '../../common/InfoBars';
import CloseOutType from '../../../enums/closeOutType';
import { FormattedMessage, useIntl } from 'react-intl';
import LoadingSpinner from '../../common/LoadingSpinner';
import useScreenSize from '../../../hooks/useScreenSize';
import BreadcrumbPage from '../../common/BreadcrumbPage';

let _ID = 0;

function CloseOutsPanel() {
    const intl = useIntl();
    const [services, setServiceData] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [closeOutsToBeCreated, setCloseOutsToBeCreated] = useState([]);
    const [closeOuts, setCloseOuts] = useState([]);
    const diaryContext = useContext(DiaryContext);
    const [date, setDate] = useState(MomentHelper.newInstance());
    const debouncedDate = useDebounce(date);
    const [description, setDescription] = useState('');
    const [infoBars, setInfoBars] = useState([]);
    const [isFetchingServices, setFetchingServices] = useState(true);
    const [isFetchingCloseOuts, setFetchingCloseOuts] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [originalDescription, setOriginalDescription] = useState('');
    const [isDateInAValidSegment, setIsDateInAValidSegment] = useState(false);
    const { isMobileView } = useScreenSize();

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const retrieveNewSegmentId = useCallback(() => {
        let segmentId;
        diaryContext.segments.forEach((segment) => {
            segment.ValidityPeriods.forEach((p) => {
                if (MomentHelper.isDateWithinRange(debouncedDate, p.StartDate, p.EndDate)) {
                    segmentId = segment.Id;
                    return;
                }
            });
        });

        return segmentId;
    }, [debouncedDate, diaryContext.segments]);

    const [segmentId, setSegmentId] = useState(retrieveNewSegmentId());

    useEffect(() => {
        setSegmentId(retrieveNewSegmentId(debouncedDate));
    }, [debouncedDate, retrieveNewSegmentId]);

    useEffect(() => {
        const hasSegmentChanged = retrieveNewSegmentId() !== segmentId;
        const currentSegmentId = hasSegmentChanged ? retrieveNewSegmentId() : segmentId;
        if (!currentSegmentId && !segmentId) {
            setIsDateInAValidSegment(false);
            addErrorBar(intl.formatMessage({ id: 'Bookings.ProviderNotSetupForDateWarningMessage' }));
        }
        if ((hasSegmentChanged || services.length === 0) && currentSegmentId) {
            setFetchingServices(true);
            RestaurantService.getSegmentSetup(diaryContext.deploymentId, diaryContext.restaurantId, currentSegmentId)
                .then((segmentResponse) => {
                    const servicesData = segmentResponse.Services.map((service) => new Service(service));
                    setServiceData(servicesData);
                })
                .catch(() => {
                    addErrorBar();
                })
                .finally(() => {
                    setFetchingServices(false);
                    setIsDateInAValidSegment(true);
                });
        }
    }, [
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        debouncedDate,
        retrieveNewSegmentId,
        segmentId,
        services.length,
        addErrorBar,
        intl,
    ]);

    useEffect(() => {
        setFetchingCloseOuts(true);
        CloseOutsService.getCloseOuts(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            debouncedDate.format('YYYY-MM-DD')
        )
            .then((response) => {
                const closeOuts = response.map((c) => new CloseOut(c));
                const serviceCloseOuts = closeOuts.filter((c) => c.serviceId !== 0);
                const description = serviceCloseOuts.length > 0 ? serviceCloseOuts[0].description : '';
                setCloseOuts(serviceCloseOuts);
                setSelectedServices(
                    serviceCloseOuts.map((closeout) => {
                        return closeout.serviceId.toString();
                    })
                );
                setDescription(description);
                setOriginalDescription(description);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setFetchingCloseOuts(false);
            });
    }, [addErrorBar, debouncedDate, diaryContext.deploymentId, diaryContext.restaurantId]);

    function getSelectedSectionsOptions() {
        return services.map((service) => new Option(service.name, service.id));
    }

    function onSelectedSectionChange(service) {
        if (service.checked) {
            setSelectedServices([...selectedServices, service.id]);
            setCloseOutsToBeCreated([...closeOutsToBeCreated, service.id]);
        } else {
            setSelectedServices(selectedServices.filter((id) => id !== service.id));
            setCloseOutsToBeCreated(closeOutsToBeCreated.filter((id) => id !== service.id));
        }
    }

    function getDescriptionText() {
        if (!description) {
            return intl.formatMessage({ id: 'Settings.DefaultCloseOutDescription' });
        }

        return description;
    }

    function createCloseOuts() {
        setIsUpdating(true);

        if (originalDescription !== description && closeOuts.length > 0) {
            updateCloseOutDescriptions(closeOuts, false);
        }

        const closeOutObjects = closeOutsToBeCreated.map((c) => {
            return {
                Name: 'TrafficLight',
                Description: getDescriptionText(),
                ServiceId: parseInt(c),
                CloseOutDate: MomentHelper.toServerDateFormat(debouncedDate),
                CloseOutType: CloseOutType.trafficLight,
            };
        });

        const createRequest = new CloseOutRequest(closeOutObjects);

        CloseOutsService.createCloseOuts(diaryContext.deploymentId, diaryContext.restaurantId, createRequest.toJson())
            .then((response) => {
                let closeOutData = response.map((c) => {
                    return new CloseOut(c);
                });

                setCloseOuts([...closeOuts, ...closeOutData]);
                const description = closeOutData.length > 0 ? closeOutData[0].description : '';
                setDescription(description);
                setCloseOutsToBeCreated([]);
                addSuccessBar(intl.formatMessage({ id: 'Settings.CloseOutCreatedSuccessMessage' }));
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsUpdating(false);
            });
    }

    function deleteCloseOuts(closeOutsToBeDeleted) {
        setIsUpdating(true);

        const deleteRequest = new CloseOutRequest(
            closeOutsToBeDeleted.map((c) => {
                return {
                    Id: c.id,
                    CloseOutDate: MomentHelper.toServerDateFormat(debouncedDate),
                    CloseOutType: CloseOutType.trafficLight,
                };
            })
        );
        CloseOutsService.deleteCloseOuts(diaryContext.deploymentId, diaryContext.restaurantId, deleteRequest.toJson())
            .then(() => {
                let closeOutsCopy = [...closeOuts];
                closeOutsToBeDeleted.forEach((closeout) => {
                    closeOutsCopy = closeOutsCopy.filter((c) => c.id !== closeout.id);
                });
                if (closeOutsCopy.length === 0) {
                    setDescription('');
                }
                if (originalDescription !== description && closeOutsCopy.length > 0) {
                    updateCloseOutDescriptions(closeOutsCopy, false);
                }
                setCloseOuts(closeOutsCopy);
                addSuccessBar(intl.formatMessage({ id: 'Settings.CloseOutRemovedSuccessMessage' }));
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsUpdating(false);
            });
    }

    function updateCloseOutDescriptions(closeOutsToUpdate, displaySuccess) {
        setIsUpdating(true);
        const closeOutObjects = closeOutsToUpdate.map((c) => {
            return {
                Id: c.id,
                Description: getDescriptionText(),
            };
        });

        const createRequest = new CloseOutRequest(closeOutObjects);
        CloseOutsService.updateCloseOutsDescription(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            createRequest.toJson()
        )
            .then((result) => {
                setOriginalDescription(result[0].Description);
                if (displaySuccess) {
                    addSuccessBar(intl.formatMessage({ id: 'Settings.CloseOutUpdatedSuccessMessage' }));
                }
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsUpdating(false);
            });
    }

    function updateCloseOuts() {
        const closeOutsToBeDeleted = getCloseOutsToBeDeleted();

        if (closeOutsToBeDeleted.length === 0 && closeOutsToBeCreated.length === 0) {
            updateCloseOutDescriptions(closeOuts, true);
        }

        if (closeOutsToBeCreated.length > 0) {
            createCloseOuts();
        }

        if (closeOutsToBeDeleted.length > 0) {
            deleteCloseOuts(closeOutsToBeDeleted);
        }
    }

    function getCloseOutsToBeDeleted() {
        const closeOutsToBeDeleted = [];

        closeOuts.forEach((closeout) => {
            if (!selectedServices.includes(closeout.serviceId.toString())) {
                closeOutsToBeDeleted.push(closeout);
            }
        });

        return closeOutsToBeDeleted;
    }

    function getBreadcrumbOptions() {
        return [new BreadCrumbOption(intl.formatMessage({ id: 'Settings.Title' }), '/Settings')];
    }

    function getUpdateButtonText() {
        if (isUpdating) {
            return <FormattedMessage id="Common.Updating" />;
        } else {
            return <FormattedMessage id="Common.Update" />;
        }
    }

    function getUpdateButton() {
        return (
            <Button
                disabled={
                    isUpdating ||
                    (closeOutsToBeCreated.length === 0 &&
                        getCloseOutsToBeDeleted().length === 0 &&
                        originalDescription === description) ||
                    !isDateInAValidSegment
                }
                color="primary"
                onClick={() => updateCloseOuts()}
            >
                {getUpdateButtonText()}
            </Button>
        );
    }

    const isFetchingData = () => {
        return isFetchingServices || isFetchingCloseOuts;
    };

    function getPageContent() {
        if (isFetchingData()) {
            return <LoadingSpinner />;
        } else {
            return (
                <Fragment>
                    <Row className="closeout-row-container">
                        <Col lg="4" md="3" sm="3" className="page-header">
                            <h2 className="flex-fill">
                                <FormattedMessage id="Settings.CloseOutsTitleText" />
                            </h2>
                        </Col>
                        <Col lg="4" md="6" sm="6" className="date-picker-container">
                            <DatePickerWrapper date={date} setDate={setDate} />
                        </Col>
                        {!isMobileView && (
                            <Col lg="4" md="3" sm="3">
                                <div className="update-button">{getUpdateButton()}</div>
                            </Col>
                        )}
                    </Row>

                    <Fragment>
                        <div className="closeout-content">
                            <div className="section-wrapper">
                                <div className="checkbox-group-container">
                                    <div className="title-text">
                                        <FormattedMessage id="Settings.ServiceCloseOutHeaderText" />
                                    </div>
                                    <div>
                                        <CheckboxGroup
                                            id="services-checkbox-group"
                                            options={getSelectedSectionsOptions()}
                                            onChange={(serviceId) => onSelectedSectionChange(serviceId)}
                                            checkedValues={selectedServices}
                                            disabled={!isDateInAValidSegment}
                                        />
                                    </div>
                                </div>

                                <div className="description-container">
                                    <div>
                                        <FormattedMessage id="Settings.OptionalDescription" />
                                    </div>
                                    <Input
                                        className="description-box"
                                        id={'closeout-description-input'}
                                        type={'textarea'}
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                        maxLength={'500'}
                                        rows={'5'}
                                        disabled={
                                            (closeOuts.length === 0 && closeOutsToBeCreated.length === 0) ||
                                            !isDateInAValidSegment
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>

                    <div className="footer">
                        <div className="update-button">{getUpdateButton()}</div>
                    </div>
                </Fragment>
            );
        }
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <BreadcrumbPage
                pageTitle={intl.formatMessage({ id: 'Settings.CloseOutsTitleText' })}
                breadcrumbOptions={getBreadcrumbOptions()}
                dataTestId="closeouts-panel"
            >
                {getPageContent()}
            </BreadcrumbPage>
        </Fragment>
    );
}

export default CloseOutsPanel;
