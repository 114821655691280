import Cookies from 'universal-cookie';

class CookiesHelper {
    static getCookie(name) {
        const cookies = new Cookies();
        return cookies.get(name);
    }

    static setCookie(name, value, options) {
        const cookies = new Cookies();
        cookies.set(name, value, options);
    }

    static removeCookie(name) {
        const cookies = new Cookies();
        cookies.remove(name);
    }

    static removeCookieWithPathAndDomain(name, path, domain) {
        const cookies = new Cookies();
        cookies.remove(name, { path: path, domain: domain });
    }

    static parseCookieToJSON(cookie) {
        let output = {};
        cookie.split(/\s*&\s*/).forEach(function (pair) {
            pair = pair.split(/\s*=\s*/);
            output[pair[0]] = pair.splice(1).join('=');
        });

        return output;
    }
}

export default CookiesHelper;
