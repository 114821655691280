import MomentHelper from './MomentHelper';
import bookingStatus from '../enums/bookingStatus';
import arrivalStatus from '../enums/arrivalStatus';

class BookingHelper {
    static getRowStatusCssClass(booking, isSelectedBooking) {
        let className = '';
        switch (booking.status) {
            case bookingStatus.cancelled:
                className = className + ' cancelled';
                break;
            case bookingStatus.closed:
                className = className + ' closed';
                break;
            default:
                if (booking.arrivalStatus === arrivalStatus.fullySeated) {
                    className = className + ' fully-seated';
                } else if (booking.arrivalStatus === arrivalStatus.notArrived) {
                    const notArrivedStatus = MomentHelper.isDateBeforeNow(booking.visitDateTime)
                        ? ' not-arrived-late'
                        : ' not-arrived';
                    className = className + notArrivedStatus;
                }
                break;
        }

        if (isSelectedBooking) {
            className = className + ' selected-booking';
        }

        if (booking.isUnallocated) {
            className = className + ' unallocated-booking';
        }
        return className;
    }
}

export default BookingHelper;
