class ContactDetails {
    constructor(contactDetails) {
        this.timezone = contactDetails && contactDetails.Timezone ? contactDetails.Timezone : '';
        this.countryCode = contactDetails && contactDetails.CountryCode ? contactDetails.CountryCode : '';
        this.phoneNumber = contactDetails && contactDetails.PhoneNumber ? contactDetails.PhoneNumber : '';
        this.emailAddress = contactDetails && contactDetails.EmailAddress ? contactDetails.EmailAddress : '';
    }
}

export default ContactDetails;
