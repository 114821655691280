import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import ListingStrength from '../common/ListingStrength';
import SuccessIconWrapper from '../common/SuccessIconWrapper';
import TextWithIcon from '../common/TextWithIcon';
import { ReactComponent as PlusIcon } from '../../content/icons/Icon-Plus.svg';
import { ReactComponent as SettingsIcon } from '../../content/icons/Icon-Settings.svg';
import useScreenSize from '../../hooks/useScreenSize';
import useOnboardingProgress from '../../hooks/useOnboardingProgress';
import useTrackPage from '../../hooks/useTrackPage';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { useNavigate } from 'react-router-dom';

function WelcomePage(props) {
    const intl = useIntl();
    const { isMobileView } = useScreenSize();
    const onboardingProgress = useOnboardingProgress();
    const navigate = useNavigate();

    useTrackPage('PreBooking');

    function getSuccessIcon() {
        return <SuccessIconWrapper isCompleted />;
    }

    function goToBookingsPage() {
        AnalyticsHelper.trackPage('Add Booking');
        window.location = '/Bookings?addBooking=true';
    }

    function goToSettingPage() {
        AnalyticsHelper.trackPage('Settings');
        window.location = '/Settings';
    }

    function linkToListingsPage(url) {
        navigate(url);
    }

    function getRDLWelcomeTitleAndDescription() {
        if (isMobileView) {
            return (
                <Fragment>
                    <h2>
                        <FormattedMessage id="AccountSubmission.WelcomeToRDLTitle" />
                    </h2>
                    <h2>
                        <FormattedMessage id="AccountSubmission.WelcomeToRDLDescription" />
                    </h2>{' '}
                </Fragment>
            );
        }

        return (
            <Fragment>
                <h1>
                    <FormattedMessage id="AccountSubmission.WelcomeToRDLTitle" />
                </h1>
                <h1>
                    <FormattedMessage id="AccountSubmission.WelcomeToRDLDescription" />
                </h1>
            </Fragment>
        );
    }

    return (
        <div className={isMobileView ? 'welcome-page mob' : 'welcome-page'}>
            <div className={isMobileView ? '' : 'centered'}>
                {getRDLWelcomeTitleAndDescription()}

                <div className={isMobileView ? 'flex-container mob' : 'flex-container'}>
                    <div className={isMobileView ? 'flex-child listing-strength-mobile' : 'flex-child'}>
                        <div className="welcome-page-progress-chart">
                            <ListingStrength percentage={onboardingProgress} includeHeading />
                        </div>
                        <div className={isMobileView ? 'ml-4 mt-3' : 'mt-3'}>
                            <TextWithIcon
                                className="mb-1"
                                text={intl.formatMessage({ id: 'Settings.MyDetails' })}
                                icon={getSuccessIcon()}
                                textClass="text-uppercase font-weight-bold"
                            />
                            <TextWithIcon
                                className="mb-1"
                                text={intl.formatMessage({ id: 'Settings.MyListing' })}
                                icon={getSuccessIcon()}
                                textClass="text-uppercase font-weight-bold"
                            />
                            <TextWithIcon
                                className="mb-1"
                                text={intl.formatMessage({ id: 'Common.Services' })}
                                icon={getSuccessIcon()}
                                textClass="text-uppercase font-weight-bold"
                            />
                        </div>
                    </div>
                    <div className="flex-child">
                        <div className="mb-3">
                            <FormattedMessage id="AccountSubmission.ImproveListingDescription" />
                        </div>

                        <div>
                            <div
                                className={isMobileView ? 'listing-container mob' : 'listing-container'}
                                onClick={() => linkToListingsPage('/Settings/Images')}
                            >
                                <div className="d-flex">
                                    <SuccessIconWrapper isCompleted={false} notStarted />
                                    <div>
                                        <h6 className="listing-title">
                                            <FormattedMessage id="AccountSubmission.UploadRestaurantImagesTitle" />{' '}
                                        </h6>
                                        <FormattedMessage id="AccountSubmission.UploadRestaurantImagesDescription" />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={isMobileView ? 'listing-container mob' : 'listing-container'}
                                onClick={() => linkToListingsPage('/Settings/Venue?listing=MyListingMenus')}
                            >
                                <div className="d-flex">
                                    <SuccessIconWrapper isCompleted={false} notStarted />
                                    <div>
                                        <h6 className="listing-title">
                                            <FormattedMessage id="AccountSubmission.UploadMenuTitle" />{' '}
                                        </h6>
                                        <FormattedMessage id="AccountSubmission.UploadMenuDescription" />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={isMobileView ? 'listing-container mob' : 'listing-container'}
                                onClick={() => linkToListingsPage('/Settings/Venue?listing=MyListing')}
                            >
                                <div className="d-flex">
                                    <SuccessIconWrapper isCompleted={false} notStarted />
                                    <div>
                                        <h6 className="listing-title">
                                            <FormattedMessage id="AccountSubmission.AdvanceListingTitle" />{' '}
                                        </h6>
                                        <FormattedMessage id="AccountSubmission.AdvanceListingDescription" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={isMobileView ? 'listings-button-wrapper mob' : 'listings-button-wrapper'}>
                    <Button color="outline-primary" onClick={() => goToBookingsPage()}>
                        <TextWithIcon
                            text={intl.formatMessage({ id: 'Bookings.AddBooking' })}
                            icon={<PlusIcon />}
                            textClass="pl-0"
                        />
                    </Button>
                    <Button color="outline-primary" onClick={() => goToSettingPage()}>
                        <TextWithIcon
                            text={intl.formatMessage({ id: 'Common.Settings' })}
                            icon={<SettingsIcon />}
                            textClass="pl-1"
                        />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default WelcomePage;
