import React, { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, FormGroup, Input, Row, Col } from 'reactstrap';
import Dropdown from '../../common/Dropdown';
import ValidationInput from '../../common/ValidationInput';
import MomentHelper from '../../../helpers/MomentHelper';
import useValidation from '../../../hooks/useValidation';
import moment from 'moment-timezone';
import { useIntl, FormattedMessage } from 'react-intl';
import Service from '../../../domainObjects/Service';
import ServiceHelper from '../../../helpers/ServiceHelper';
import yieldManagmentTypes from '../../../enums/yieldManagmentTypes';
import ValidationMessage from '../../common/ValidationMessage';
import CloseablePanel from '../../common/CloseablePanel';
import useScreenSize from '../../../hooks/useScreenSize';
import serviceUpdateModalType from '../../../enums/serviceUpdateModalType';

function ServiceEditPanel(props) {
    const intl = useIntl();
    const formValidation = useValidation();
    const [initiating, setInitiating] = useState(true);
    const [serviceBeingUpdated, setServiceBeingUpdated] = useState(getServiceCopy());
    const [selectedMaxPerIntervalDropdownValue, setSelectedMaxPerIntervalDropdownValue] = useState(
        getMaxPerIntervalDropdownValue()
    );
    const [maxPerIntervalValue, setMaxPerIntervalValue] = useState(getMaxPerIntervalValue());
    const { isMobileView, isTabletView } = useScreenSize();

    function onValueChange(key, value) {
        setServiceBeingUpdated({ ...serviceBeingUpdated, [key]: value });
    }

    const validateServiceStartTime = useCallback(
        (value) => {
            const startTime = MomentHelper.dateWithFormat(value, 'HH:mm:ss');
            const endTime = MomentHelper.dateWithFormat(formValidation.getValues('timeUntil'), 'HH:mm:ss');

            if (props.doesServiceTimeOverLap(startTime, endTime, serviceBeingUpdated.id)) {
                return intl.formatMessage({ id: 'Settings.ServiceOverlapValidationMessage' });
            }
            if (MomentHelper.areDatesEqual(startTime, endTime)) {
                return intl.formatMessage({ id: 'Settings.ServiceFromAndUntilEqualValidationMessage' });
            }
            return true;
        },
        [formValidation, intl, props, serviceBeingUpdated.id]
    );

    const validateServiceEndTime = useCallback(
        (value) => {
            const startTime = MomentHelper.dateWithFormat(formValidation.getValues('timeFrom'), 'HH:mm:ss');
            const endTime = MomentHelper.dateWithFormat(value, 'HH:mm:ss');
            if (props.doesServiceTimeOverLap(startTime, endTime, serviceBeingUpdated.id, true)) {
                return intl.formatMessage({ id: 'Settings.ServiceOverlapValidationMessage' });
            }
            if (MomentHelper.areDatesEqual(startTime, endTime)) {
                return intl.formatMessage({ id: 'Settings.ServiceFromAndUntilEqualValidationMessage' });
            }
            return true;
        },
        [formValidation, intl, props, serviceBeingUpdated.id]
    );

    const validateServiceLastBookingTime = useCallback(
        (value) => {
            const startTime = MomentHelper.dateWithFormat(formValidation.getValues('timeFrom'), 'HH:mm:ss')
                .date(1)
                .month(0)
                .year(1970);
            const endTime = MomentHelper.dateWithFormat(formValidation.getValues('timeUntil'), 'HH:mm:ss')
                .date(1)
                .month(0)
                .year(1970);
            const lastBookingTime = MomentHelper.dateWithFormat(value, 'HH:mm:ss').date(1).month(0).year(1970);

            // Two dates representing midnight to help validate service times.
            const midnightBefore = MomentHelper.dateWithFormat('01-01-1970 00:00', 'DD-MM-YYYY HH:mm');
            const midnightAfter = MomentHelper.dateWithFormat('02-01-1970 00:00', 'DD-MM-YYYY HH:mm');

            const isEndTimeAfterMidnight = endTime.isBefore(startTime);

            if (isEndTimeAfterMidnight) {
                if (
                    !lastBookingTime.isSame(midnightBefore) &&
                    !isBetween(lastBookingTime, startTime, midnightAfter) &&
                    !isBetween(lastBookingTime, midnightBefore, endTime)
                ) {
                    return intl.formatMessage({ id: 'Settings.ServiceLastOrdersOutOfBoundsValidationMessage' });
                }
            } else {
                if (!MomentHelper.isDateWithinRange(lastBookingTime, startTime, endTime)) {
                    return intl.formatMessage({ id: 'Settings.ServiceLastOrdersOutOfBoundsValidationMessage' });
                }
            }

            return true;
        },
        [formValidation, intl]
    );

    function isBetween(timeToCheck, startTime, endTime) {
        return timeToCheck.isAfter(startTime) && timeToCheck.isBefore(endTime);
    }

    const validateServiceMaxPerInterval = useCallback(
        (value) => {
            if (parseInt(value) < 1) {
                return intl.formatMessage({ id: 'Settings.MinValueValidationMessage' }, { value: 0 });
            }
            if (!value) {
                return intl.formatMessage({ id: 'Settings.MaxPerIntervalValidationMessage' });
            }
            return true;
        },
        [intl]
    );

    useEffect(() => {
        if (initiating) {
            formValidation.register({ name: 'timeFrom' }, { validate: validateServiceStartTime });
            formValidation.register({ name: 'timeUntil' }, { validate: validateServiceEndTime });
            formValidation.register({ name: 'lastBookingTime' }, { validate: validateServiceLastBookingTime });
            formValidation.register({ name: 'maxPerInterval' }, { validate: validateServiceMaxPerInterval });
            formValidation.setValue('timeFrom', serviceBeingUpdated.timeFrom);
            formValidation.setValue('timeUntil', serviceBeingUpdated.timeUntil);
            formValidation.setValue('lastBookingTime', serviceBeingUpdated.lastBookingTime);
            formValidation.setValue('maxPerInterval', maxPerIntervalValue);
            setInitiating(false);
        }
    }, [
        formValidation.register,
        validateServiceStartTime,
        validateServiceEndTime,
        validateServiceMaxPerInterval,
        formValidation.setValue,
        initiating,
        serviceBeingUpdated.timeFrom,
        serviceBeingUpdated.timeUntil,
        maxPerIntervalValue,
        serviceBeingUpdated.lastBookingTime,
        formValidation,
        validateServiceLastBookingTime,
    ]);

    function getServiceCopy() {
        return new Service(ServiceHelper.toJson(props.service));
    }

    function openServiceUpdateModal() {
        if (selectedMaxPerIntervalDropdownValue === yieldManagmentTypes.covers) {
            serviceBeingUpdated.maxBookings = null;
        } else {
            serviceBeingUpdated.maxCovers = null;
        }

        if (props.isEditing && !props.isInitialSetup) {
            props.setUpdateType(serviceUpdateModalType.update);
            props.openModalWithUpdatedService(serviceBeingUpdated);
        } else {
            props.updateService(serviceBeingUpdated);
        }
    }

    function setSelectedTimeFromInDropDown(time) {
        onValueChange('timeFrom', time);
        formValidation.setValue('timeFrom', time, { shouldValidate: true, shouldDirty: true });
        formValidation.setValue('timeUntil', serviceBeingUpdated.timeUntil, {
            shouldValidate: true,
            shouldDirty: true,
        });
    }

    function setSelectedTimeUntilInDropDown(time) {
        onValueChange('timeUntil', time);
        formValidation.setValue('timeUntil', time, { shouldValidate: true, shouldDirty: true });
        formValidation.setValue('timeFrom', serviceBeingUpdated.timeFrom, { shouldValidate: true, shouldDirty: true });
    }

    function setSelectedLastBookingInDropDown(time) {
        onValueChange('lastBookingTime', time);
        formValidation.setValue('lastBookingTime', time, { shouldValidate: true, shouldDirty: true });
    }

    function getServiceTimes() {
        let startTime = MomentHelper.newInstance('2020-01-01 00:00');
        const endTime = MomentHelper.newInstance('2020-01-01 23:45');
        const options = [];
        while (startTime.isSameOrBefore(endTime)) {
            options.push({
                text: startTime.format('HH:mm'),
                value: MomentHelper.toServerTimeFormat(startTime),
            });
            startTime = moment(startTime).add(15, 'minutes');
        }

        return options;
    }

    function getIntervalButtons() {
        const intervalOptions = [
            { value: 15, text: intl.formatMessage({ id: 'Common.15MinutesTimeInterval' }) },
            { value: 30, text: intl.formatMessage({ id: 'Common.30MinutesTimeInterval' }) },
            { value: 60, text: intl.formatMessage({ id: 'Common.60MinutesTimeInterval' }) },
        ];

        return intervalOptions.map((option) => {
            return (
                <Button
                    key={option.value}
                    onClick={() => onTimeSlotIntervalChange(option.value)}
                    className="flex-fill"
                    color={serviceBeingUpdated.timeSlotInterval === option.value ? 'dark' : 'light'}
                >
                    {option.text}
                </Button>
            );
        });
    }

    function getMaxCoversOrBookingsDropdownOptions() {
        const options = [
            {
                text: intl.formatMessage({ id: 'Common.Covers' }).toLowerCase(),
                value: yieldManagmentTypes.covers,
            },
            {
                text: intl.formatMessage({ id: 'Common.Bookings' }).toLowerCase(),
                value: yieldManagmentTypes.bookings,
            },
        ];

        return options;
    }

    function onTimeSlotIntervalChange(interval) {
        onValueChange('timeSlotInterval', interval);
    }

    function getMaxPerIntervalValue() {
        return serviceBeingUpdated.maxCovers ? serviceBeingUpdated.maxCovers : serviceBeingUpdated.maxBookings;
    }

    function getMaxPerIntervalDropdownValue() {
        if (serviceBeingUpdated.maxCovers) {
            return yieldManagmentTypes.covers;
        }
        return yieldManagmentTypes.bookings;
    }

    function setMaxPerIntervalDropdownValue(value) {
        setSelectedMaxPerIntervalDropdownValue(value);

        if (value === yieldManagmentTypes.covers) {
            onValueChange('maxCovers', maxPerIntervalValue);
        } else {
            onValueChange('maxBookings', maxPerIntervalValue);
        }
    }

    function setMaxPerInterval(value) {
        setMaxPerIntervalValue(parseInt(value));
        formValidation.setValue('maxPerInterval', value, { shouldValidate: true, shouldDirty: true });

        if (selectedMaxPerIntervalDropdownValue === yieldManagmentTypes.covers) {
            onValueChange('maxCovers', parseInt(value));
        } else {
            onValueChange('maxBookings', parseInt(value));
        }
    }

    function hasServiceUpdated() {
        return JSON.stringify(serviceBeingUpdated) !== JSON.stringify(props.service);
    }

    function getServiceTimeValidationMessage() {
        if (formValidation.errorCount > 0) {
            let error = '';
            if (formValidation.errors['timeFrom']) {
                error = 'timeFrom';
            }
            if (formValidation.errors['timeUntil']) {
                error = 'timeUntil';
            }

            if (error) {
                return <ValidationMessage message={formValidation.errors[error].message} />;
            }
        }

        return null;
    }

    function getFormBody() {
        return (
            <Fragment>
                <div className="grey-closable-panel-body">
                    <div className="service-edit-entry">
                        <Row form className="list-builder-sub-section">
                            <Col md={4}>
                                <FormGroup>
                                    <div className="mb-1" id="serviceName">
                                        <FormattedMessage id="Settings.ServiceName" />
                                    </div>
                                    <ValidationInput
                                        value={serviceBeingUpdated.name}
                                        onChange={(e) => onValueChange('name', e)}
                                        testId="service-name-input"
                                        innerRef={formValidation.register({
                                            required: intl.formatMessage({
                                                id: 'Settings.ServiceNameValidationMessage',
                                            }),
                                        })}
                                        name="name"
                                        autoFocus
                                        errors={formValidation.errors}
                                        ariaRequired
                                        ariaLabelledBy="serviceName"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form className="list-builder-sub-section">
                            <div className="d-flex time-selection-section mr-4">
                                <Col className="pl-1 pr-0 col">
                                    <FormGroup className="mb-0">
                                        <div className="mb-1">
                                            <FormattedMessage id="Settings.TimeFromLabel" />
                                        </div>
                                        <div className="d-flex">
                                            <Dropdown
                                                onValueChange={setSelectedTimeFromInDropDown}
                                                selectedValue={serviceBeingUpdated.timeFrom}
                                                options={getServiceTimes()}
                                                hideRemoveItemIcon
                                                name="timeFrom"
                                                errors={formValidation.errors}
                                            />
                                            <div className="time-to-label">
                                                <FormattedMessage id="Settings.TimeToLabel" />
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col className="col pl-0">
                                    <FormGroup className="mb-0">
                                        <div className="mb-1">
                                            <FormattedMessage id="Settings.TimeUntilLabel" />
                                        </div>
                                        <Dropdown
                                            onValueChange={setSelectedTimeUntilInDropDown}
                                            selectedValue={serviceBeingUpdated.timeUntil}
                                            options={getServiceTimes()}
                                            hideRemoveItemIcon
                                            name="timeUntil"
                                            errors={formValidation.errors}
                                        />
                                    </FormGroup>
                                </Col>
                            </div>

                            <Col sm={3}>
                                <FormGroup>
                                    <div className="mb-1">
                                        <FormattedMessage id="Settings.LastOrderTimeLabel" />
                                    </div>
                                    <div className={!isMobileView ? 'last-orders-dropdown' : ''}>
                                        <Dropdown
                                            onValueChange={setSelectedLastBookingInDropDown}
                                            selectedValue={serviceBeingUpdated.lastBookingTime}
                                            options={getServiceTimes()}
                                            hideRemoveItemIcon
                                            errors={formValidation.errors}
                                            name="lastBookingTime"
                                        />
                                    </div>
                                    {formValidation.errorCount > 0 && formValidation.errors['lastBookingTime'] && (
                                        <ValidationMessage message={formValidation.errors['lastBookingTime'].message} />
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <div>{getServiceTimeValidationMessage()}</div>
                        <Row
                            form
                            className={isMobileView ? 'list-builder-sub-section d-block' : 'list-builder-sub-section'}
                        >
                            <Col className={isTabletView ? 'time-interval mob col-md-4' : 'time-interval col-md-3'}>
                                <FormGroup>
                                    <div className="mb-1">
                                        <FormattedMessage id="Settings.TimeIntervalLabel" />
                                    </div>
                                    <ButtonGroup
                                        className="subfilter-date-button-group d-flex"
                                        role="group"
                                        aria-label="First group"
                                    >
                                        {getIntervalButtons()}
                                    </ButtonGroup>
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                    <div className="mb-1">
                                        <FormattedMessage id="Settings.MaxPerIntervalLabel" />
                                    </div>
                                    <div className="d-flex max-per-interval-wrapper">
                                        <div className="max-per-interval-input">
                                            <Input
                                                value={maxPerIntervalValue}
                                                onChange={(e) => setMaxPerInterval(e.target.value)}
                                                type="number"
                                                name="maxPerInterval"
                                            />
                                        </div>
                                        <Dropdown
                                            onValueChange={setMaxPerIntervalDropdownValue}
                                            selectedValue={selectedMaxPerIntervalDropdownValue}
                                            options={getMaxCoversOrBookingsDropdownOptions()}
                                            hideRemoveItemIcon
                                            isDropdownDisabled={false}
                                        />
                                    </div>
                                </FormGroup>
                                {formValidation.errorCount > 0 && formValidation.errors['maxPerInterval'] && (
                                    <ValidationMessage message={formValidation.errors['maxPerInterval'].message} />
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <CloseablePanel
                formBody={getFormBody()}
                isEditing={props.isEditing}
                saveItem={openServiceUpdateModal}
                toggleEditing={props.toggleEditing}
                toggleAddNewItem={props.toggleAddNewService}
                addNewItemText={intl.formatMessage({ id: 'Settings.AddService' })}
                editItemText={intl.formatMessage({ id: 'Settings.EditService' })}
                buttonDisabled={formValidation.submitDisabled || !hasServiceUpdated()}
                submit={formValidation.submit}
                submitDisabled={formValidation.submitDisabled}
                errorCount={formValidation.errorCount}
                isSaving={false}
            />
        </Fragment>
    );
}

ServiceEditPanel.propTypes = {
    service: PropTypes.object.isRequired,
    toggleEditing: PropTypes.func,
    doesServiceTimeOverLap: PropTypes.func.isRequired,
    openModalWithUpdatedService: PropTypes.func,
    updateService: PropTypes.func,
    isEditing: PropTypes.bool,
    isInitialSetup: PropTypes.bool,
    setUpdateType: PropTypes.func,
};

export default ServiceEditPanel;
