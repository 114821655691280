import MomentHelper from '../helpers/MomentHelper';

class TimeSlot {
    constructor(availableTimeSlot) {
        this.timeSlot = availableTimeSlot.TimeSlot ? MomentHelper.newInstance(availableTimeSlot.TimeSlot) : null;
        this.duration = availableTimeSlot.Duration ? availableTimeSlot.Duration : 0;
        this.tableIds =
            availableTimeSlot.Tables && availableTimeSlot.Tables.length > 0
                ? availableTimeSlot.Tables[0].TableIds
                : null;
        this.serviceId = availableTimeSlot.ServiceId ? availableTimeSlot.ServiceId : 0;
    }
}

export default TimeSlot;
