import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

function MenuOverlayList(props) {
    function getRow() {
        return (
            <div className="send-menu-row">
                <div className="send-menu-title">
                    <h6>{props.menu.name}</h6>
                </div>
                <div className="send-menu-button">
                    <Button
                        outline={
                            props.overlayContent.booking.preorderMenuVersionId !== props.menu.latestMenuVersionId
                                ? false
                                : true
                        }
                        color="primary"
                        disabled={
                            !props.overlayContent.booking.customer ||
                            (props.overlayContent.booking.customer && !props.overlayContent.booking.customer.email)
                        }
                        onClick={() => {
                            props.overlayContent.booking.preorderMenuVersionId !== props.menu.latestMenuVersionId &&
                            props.overlayContent.booking.preorderMenuVersionId !== 0
                                ? props.openMenuChangeModal(
                                      props.overlayContent.booking.id,
                                      props.menu.id,
                                      props.menu.name,
                                      props.menu.latestMenuVersionId
                                  )
                                : props.sendMenuNotification(
                                      props.overlayContent.booking.id,
                                      props.menu.id,
                                      props.menu.latestMenuVersionId
                                  );
                        }}
                    >
                        <FormattedMessage
                            id={
                                props.overlayContent.booking.preorderMenuVersionId !== props.menu.latestMenuVersionId
                                    ? 'Bookings.SendMenuButtonLabel'
                                    : 'Bookings.ResendMenuButtonLabel'
                            }
                        />
                    </Button>
                </div>
            </div>
        );
    }

    return <Fragment>{getRow()}</Fragment>;
}

MenuOverlayList.propTypes = {
    overlayContent: PropTypes.object,
    menu: PropTypes.object,
    openMenuChangeModal: PropTypes.func,
    sendMenuNotification: PropTypes.func,
};

export default MenuOverlayList;
