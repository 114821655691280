import React, { useState, useContext, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import Section from '../common/Section';
import DiaryContext from '../../contexts/DiaryContext';
import ServicesListBuilder from '../settings/services/ServicesListBuilder';
import ServiceSettingsService from '../../services/ServiceSettingsService';
import Service from '../../domainObjects/Service';
import MomentHelper from '../../helpers/MomentHelper';
import useScreenSize from '../../hooks/useScreenSize';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import ServiceDetailsHelper from '../../helpers/ServiceDetailsHelper';
import SettingsService from '../../services/SettingsService';

function ServicesTab(props) {
    const intl = useIntl();
    const [services, setServices] = useState([]);
    const displayClass = props.isActive ? '' : 'hidden';
    const diaryContext = useContext(DiaryContext);
    const [completing, setCompleting] = useState(false);

    const { isTabletView } = useScreenSize();

    const addErrorBar = props.addErrorBar;

    const retrieveNewSegmentId = useCallback(() => {
        let segmentId;
        diaryContext.segments.forEach((segment) => {
            segment.ValidityPeriods.forEach((p) => {
                if (MomentHelper.isDateWithinRange(MomentHelper.newInstance(), p.StartDate, p.EndDate)) {
                    segmentId = segment.Id;
                    return;
                }
            });
        });

        return segmentId;
    }, [diaryContext.segments]);

    const [segmentId] = useState(retrieveNewSegmentId());

    useEffect(() => {
        ServiceSettingsService.getServices(diaryContext.deploymentId, diaryContext.restaurantId, segmentId)
            .then((services) => {
                const servicesData = services.map((service) => new Service(service));
                const sortedServices = servicesData.sort(
                    (a, b) =>
                        MomentHelper.createDateTimeWithTime(a.timeFrom) -
                        MomentHelper.createDateTimeWithTime(b.timeFrom)
                );
                setServices(sortedServices);
            })
            .catch(() => {
                addErrorBar();
            });
    }, [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId, segmentId]);

    function getPageBody() {
        if (isTabletView) {
            return getPageContent();
        }

        return <Section sectionTitle={intl.formatMessage({ id: 'Common.Services' })}>{getPageContent()}</Section>;
    }

    function getServiceDTO() {
        return ServiceDetailsHelper.getServiceDto(services);
    }

    function getServicePropertiesForTracking() {
        return ServiceDetailsHelper.getServicesDtoForTracking(services);
    }

    function completeAccountSetup() {
        if (!completing) {
            setCompleting(true);
            AnalyticsHelper.trackClickWithProperties('Web SetUp Services', getServiceDTO());
            AnalyticsHelper.identifyUserIdWithProperties(
                diaryContext.restaurantId,
                getServicePropertiesForTracking(),
                {},
                markDiarySetUpComplete
            );
        }
    }

    function markDiarySetUpComplete() {
        SettingsService.completeDiarySetup(diaryContext.deploymentId, diaryContext.restaurantId)
            .then(() => {
                window.location = '/Welcome';
            })
            .catch(() => {
                addErrorBar();
                setCompleting(false);
            });
    }

    function getPageContent() {
        return (
            <Fragment>
                <div className="collapsible-panel-body">
                    <FormattedMessage id="Settings.ServicesOnboardingTitleText" />

                    <div className="services-container tabbed-page">
                        <ServicesListBuilder
                            addSuccessBar={props.addSuccessBar}
                            addErrorBar={props.addErrorBar}
                            services={services}
                            segmentId={segmentId}
                            setServices={setServices}
                            isInitialSetup
                        />
                    </div>
                </div>
                <div className="panel-footer button-wrapper">
                    <Button className="btn-outline-primary" onClick={() => props.backToPreviousTab()}>
                        {intl.formatMessage({
                            id: 'Common.Back',
                        })}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => completeAccountSetup()}
                        disabled={services.length < 1 || completing}
                    >
                        {!completing
                            ? intl.formatMessage({
                                  id: 'Common.SaveAndContinue',
                              })
                            : intl.formatMessage({
                                  id: 'Common.Saving',
                              })}
                    </Button>
                </div>
            </Fragment>
        );
    }

    return <div className={displayClass}>{getPageBody()}</div>;
}

ServicesTab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    backToPreviousTab: PropTypes.func.isRequired,
};

export default ServicesTab;
