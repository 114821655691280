import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';
import CustomerHelper from '../helpers/CustomerHelper';

class CustomerService {
    static getCustomer(deploymentId, restaurantId, customerId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Customer/${customerId}`
        ).then((response) => response.data);
    }

    static searchCustomers(deploymentId, restaurantId, searchText) {
        if (searchText.includes(' ')) {
            const names = searchText.split(' ');
            return ApiHelper.get(
                `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Customers?firstName=${
                    names[0]
                }&surname=${names[1]}`
            ).then((response) => response.data);
        }
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Customers?firstName=${searchText}&surname=${searchText}&email=${searchText}&performOrSearch=true`
        ).then((response) => response.data);
    }

    static createCustomer(deploymentId, restaurantId, customer) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Customer`,
            { Customer: CustomerHelper.toJson(customer) }
        ).then((response) => response.data);
    }

    static updateCustomer(deploymentId, restaurantId, customer) {
        return ApiHelper.put(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Customer`,
            { Customer: CustomerHelper.toJson(customer) }
        ).then((response) => response.data);
    }
}

export default CustomerService;
