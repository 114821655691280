import React from 'react';
import loyaltyStatus from '../enums/loyaltyStatus';

export default React.createContext({
    restaurantId: null,
    deploymentId: null,
    providerGroupId: null,
    restaurantName: '',
    segments: [],
    maxPartySize: null,
    countryCode: '',
    timeFormat: '',
    timeZone: '',
    micrositeName: '',
    currencySymbol: '',
    widgetId: null,
    hasStripeSubscription: false,
    hasSquareEnabled: false,
    toggleHasSquareEnabled: () => {},
    stripeSubscriptionStatus: 0,
    isLoyaltyEnabled: false,
    hideOnPortal: false,
    loyaltyStatus: loyaltyStatus.inactive,
    isQuickServiceRestaurant: false,
});
