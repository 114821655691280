import React, { useContext, useCallback, Fragment, useState, useEffect, useReducer } from 'react';
import SettingsSectionPanel from '.././settings/SettingsSectionPanel';
import { ReactComponent as CalenderIcon } from '../.././content/icons/icon_settings_diary_configuration.svg';
import { ReactComponent as PersonIcon } from '../.././content/icons/person.svg';
import { ReactComponent as IntegrationsIcon } from '../.././content/icons/Integration.svg';
import { ReactComponent as CreditCardIcon } from '../.././content/icons/credit-card.svg';
import { ReactComponent as RestaurantDetailsIcon } from '../.././content/icons/Icon-Main.svg';
import { ReactComponent as PromoteIcon } from '../.././content/icons/promote-filled.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import StripeService from '../../services/StripeService';
import SquareService from '../../services/SquareService';
import DiaryContext from '../../contexts/DiaryContext';
import SessionContext from '../../contexts/SessionContext';
import ProviderProgressFlagsContext from '../../contexts/ProviderProgressFlagsContext';
import InfoBars from '../common/InfoBars';
import LoadingSpinner from '../common/LoadingSpinner';
import infoBarType from '../../enums/infoBarType';
import ImproveListingsFooter from '../accountSubmission/ImproveListingsFooter';
import settingsSectionPanelActionType from '../../enums/settingsSectionPanelActionType';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import useTrackPage from '../../hooks/useTrackPage';
import SettingsHelper from '../../helpers/SettingsHelper';
import AnalyticsHelper from './../../helpers/AnalyticsHelper';
import loyaltyStatus from '../../enums/loyaltyStatus';
import tagColour from '../../enums/tagColour';

let _ID = 0;

function SettingsPage() {
    const intl = useIntl();
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const providerProgressFlagsContext = useContext(ProviderProgressFlagsContext);
    const [infoBars, setInfoBars] = useState([]);
    const [showMenuPage, setShowMenuPage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCancelationModalOpen, toggleIsCancelationModalOpen] = useReducer(toggle, false);

    useTrackPage('Settings');

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    useEffect(() => {
        if (!showMenuPage) {
            SquareService.getSquareAccount(diaryContext.deploymentId, diaryContext.restaurantId)
                .then((data) => {
                    if (data) {
                        setShowMenuPage(true);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
                });
        }
    }, [showMenuPage, diaryContext.deploymentId, diaryContext.restaurantId, addErrorBar, intl]);

    function toggle(value) {
        return !value;
    }

    function getListingTagsForRestaurantDetails() {
        const tags = [];
        if (!providerProgressFlagsContext.uploadedMenu) {
            tags.push({
                value: intl.formatMessage({
                    id: 'AccountSubmission.ImproveListing',
                }),
            });
        }

        if (!providerProgressFlagsContext.addedAdvancedListing) {
            tags.push({
                value: intl.formatMessage({
                    id: 'AccountSubmission.ActivateListing',
                }),
            });
        }

        return tags;
    }

    function getListingTagsForImages() {
        const tags = [];
        if (!providerProgressFlagsContext.addedAdditionalImage) {
            tags.push({
                value: intl.formatMessage({
                    id: 'AccountSubmission.ImproveListing',
                }),
            });
        }

        return tags;
    }

    function getListingTagsForLoyalty() {
        //TODO: Add logic to hide new tag when required
        const tags = [
            {
                value: intl.formatMessage({ id: 'Common.New' }),
                color: tagColour.Green,
                className: 'new-tag',
            },
        ];

        if (diaryContext.loyaltyStatus === loyaltyStatus.syncing) {
            tags.push({
                value: intl.formatMessage({
                    id: 'Settings.PendingActivation',
                }),
            });
        }

        return tags;
    }

    function getDiaryConfigSection() {
        return {
            header: intl.formatMessage({
                id: 'Settings.DiaryConfigLabel',
            }),
            data: [
                {
                    title: intl.formatMessage({
                        id: 'Settings.TablesTitleText',
                    }),
                    description: intl.formatMessage({
                        id: 'Settings.TablesDescriptionText',
                    }),
                    url: 'Tables',
                    action: settingsSectionPanelActionType.link,
                },
                {
                    title: intl.formatMessage({
                        id: 'Settings.CloseOutsTitleText',
                    }),
                    description: intl.formatMessage({
                        id: 'Settings.CloseOutsDescriptionText',
                    }),
                    url: 'CloseOuts',
                    action: settingsSectionPanelActionType.link,
                },
                {
                    title: intl.formatMessage({
                        id: 'Common.Services',
                    }),
                    description: intl.formatMessage({
                        id: 'Settings.ServiceDescriptionText',
                    }),
                    url: 'Services',
                    action: settingsSectionPanelActionType.link,
                },
            ],
        };
    }

    function getOnlineBookingsSection() {
        const data = [
            {
                title: intl.formatMessage({
                    id: 'Settings.BookButtonCreatorTitleText',
                }),
                description: intl.formatMessage({
                    id: 'Settings.BookButtonCreatorDescriptionText',
                }),
                url: 'BookButton',
                action: settingsSectionPanelActionType.link,
            },
        ];
        if (diaryContext.widgetId) {
            data.push({
                title: intl.formatMessage({
                    id: 'Settings.BookingWidgetTitleText',
                }),
                description: intl.formatMessage({
                    id: 'Settings.BookingWidgetDescriptionText',
                }),
                url: 'BookingWidget',
                action: settingsSectionPanelActionType.link,
            });
        }
        return {
            header: intl.formatMessage({
                id: 'Settings.OnlineBookingLabel',
            }),
            data: data,
        };
    }

    function getManageSubscriptionSection() {
        const section = {
            header: intl.formatMessage({
                id: 'Settings.SubscriptionAndBilling',
            }),
            data: [],
        };

        section.data.push({
            title: intl.formatMessage({
                id: 'Settings.BillingLabel',
            }),
            description: intl.formatMessage({
                id: 'Settings.BillingDescriptionText',
            }),
            action: settingsSectionPanelActionType.callback,
            callback: createPortalSession,
        });

        section.data.push({
            title: intl.formatMessage({
                id: 'Settings.CancelSubscriptionLabel',
            }),
            description: intl.formatMessage({
                id: 'Settings.CancelSubscriptionDescription',
            }),
            action: settingsSectionPanelActionType.callback,
            callback: toggleIsCancelationModalOpen,
        });

        return section;
    }

    function getIntegrationsSection() {
        const data = [
            {
                title: intl.formatMessage({
                    id: 'Settings.ManageSquareTitleText',
                }),
                description: intl.formatMessage({
                    id: 'Settings.ManageSquareDescriptionText',
                }),
                url: 'Square',
                action: settingsSectionPanelActionType.link,
            },
            {
                title: intl.formatMessage({
                    id: 'Settings.StripeSectionTitle',
                }),
                description: intl.formatMessage({
                    id: 'Settings.StripeSectionDescription',
                }),
                url: 'StripeConnect',
                action: settingsSectionPanelActionType.link,
            },
        ];
        if (SettingsHelper.getHasFacebookIntegration()) {
            data.push({
                title: intl.formatMessage({
                    id: 'Settings.ManageFacebookTitleText',
                }),
                description: intl.formatMessage({
                    id: 'Settings.ManageFacebookDescriptionText',
                }),
                url: 'Facebook',
                onClick: sendFacebookAnalyticsClick,
                action: settingsSectionPanelActionType.linkWithOnClick,
            });
        }
        return {
            header: intl.formatMessage({ id: 'Settings.IntegrationsLabel' }),
            data: data,
        };
    }

    function getRestaurantDetailsSection() {
        const data = [
            {
                title: intl.formatMessage({
                    id: 'Settings.RestaurantDetails',
                }),
                description: intl.formatMessage({
                    id: 'Settings.RestaurantDetailsDescription',
                }),
                url: 'Venue',
                action: settingsSectionPanelActionType.link,
                tags: getListingTagsForRestaurantDetails(),
            },
        ];
        if (showMenuPage) {
            data.push({
                title: intl.formatMessage({
                    id: 'Settings.MenuManagement',
                }),
                description: intl.formatMessage({
                    id: 'Settings.CreateOrChangeMenu',
                }),
                action: settingsSectionPanelActionType.link,
                url: 'MenuManagement',
            });
        }
        data.push({
            title: intl.formatMessage({
                id: 'Settings.Images',
            }),
            description: intl.formatMessage({
                id: 'Settings.ImagesDescription',
            }),
            url: 'Images',
            action: settingsSectionPanelActionType.link,
            tags: getListingTagsForImages(),
        });
        return {
            header: intl.formatMessage({
                id: 'Settings.MyRestaurant',
            }),
            data: data,
        };
    }

    function getPromotionSection() {
        const data = [
            {
                title: intl.formatMessage({
                    id: 'Settings.DishCultRewards',
                }),
                description: intl.formatMessage({
                    id: 'Settings.EngageCustomersText',
                }),
                url: 'Loyalty',
                action: settingsSectionPanelActionType.linkWithOnClick,
                onClick: sendDishCultRewardsClick,
                tags: getListingTagsForLoyalty(),
            },
        ];

        return {
            header: intl.formatMessage({
                id: 'Settings.Promote',
            }),
            data: data,
        };
    }

    function sendFacebookAnalyticsClick() {
        AnalyticsHelper.trackClickWithProperties('Web Integrations Facebook/Instagram', {
            providerId: diaryContext.restaurantId,
        });
    }

    function sendDishCultRewardsClick() {
        AnalyticsHelper.trackClickWithProperties('Web Promote DCRewards', {
            providerId: diaryContext.restaurantId,
            userName: sessionContext.userName,
            userId: sessionContext.userId,
        });
    }

    function createPortalSession() {
        StripeService.createPortalSession(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((response) => {
                window.location = response;
            })
            .catch((error) => {
                addErrorBar();
            });
    }

    function showListingsFooter() {
        return (
            !providerProgressFlagsContext.uploadedMenu ||
            !providerProgressFlagsContext.addedAdditionalImage ||
            !providerProgressFlagsContext.addedAdvancedListing
        );
    }

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <div className="row">
                <div className="col-lg-12 page-header">
                    <div className="d-flex">
                        <h1 className="flex-fill" data-testid="settings-page-header">
                            <FormattedMessage id="Settings.Title" />
                        </h1>
                    </div>
                </div>
            </div>
            <div className="settings-section">
                <SettingsSectionPanel
                    icon={<CalenderIcon />}
                    iconClass="calender-icon"
                    panelObject={getDiaryConfigSection()}
                />
                <SettingsSectionPanel
                    icon={<RestaurantDetailsIcon />}
                    iconClass="management-icon"
                    panelObject={getRestaurantDetailsSection()}
                />
                <SettingsSectionPanel
                    icon={<PersonIcon />}
                    iconClass="person-icon"
                    panelObject={getOnlineBookingsSection()}
                />
                <SettingsSectionPanel
                    icon={<IntegrationsIcon />}
                    iconClass="integrations-icon"
                    panelObject={getIntegrationsSection()}
                />
                {diaryContext.isLoyaltyEnabled && (
                    <SettingsSectionPanel
                        icon={<PromoteIcon />}
                        iconClass="promote-icon"
                        panelObject={getPromotionSection()}
                    />
                )}
                {diaryContext.hasStripeSubscription && (
                    <SettingsSectionPanel
                        icon={<CreditCardIcon />}
                        iconClass="credit-card-icon"
                        panelObject={getManageSubscriptionSection()}
                    />
                )}
            </div>
            <CancelSubscriptionModal
                isModalOpen={isCancelationModalOpen}
                setIsModalOpen={toggleIsCancelationModalOpen}
                addErrorBar={addErrorBar}
            />
            {showListingsFooter() && <ImproveListingsFooter />}
        </Fragment>
    );
}

export default SettingsPage;
