import axios from 'axios';
import ErrorService from './services/ErrorService';
import SessionHelper from './helpers/SessionHelper';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const instance = axios.create();
const AxiosInterceptor = ({ children, auth }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const resInterceptor = (response) => {
            return response;
        };

        const errInterceptor = (error) => {
            if (error.response && error.response.status) {
                if (error.response.status >= 400 && error.response.status <= 599) {
                    if (error.response.status !== 429) {
                        ErrorService.reportErrorFromRequest(JSON.stringify(error.response));
                        if (error.response.status === 401) {
                            auth.logout();
                            navigate('/', { state: location.pathname });
                        }
                    }
                }
            } else {
                ErrorService.reportErrorFromRequest(error);
            }
            return Promise.reject(error);
        };

        const reqInterceptor = (config) => {
            const tokenInfo = SessionHelper.getTokenInfo();
            if (tokenInfo !== null) {
                config.headers['Authorization'] = 'Bearer ' + tokenInfo.token;
            }
            return config;
        };

        const requestErrInterceptor = (error) => {
            Promise.reject(error);
        };

        const requestInterceptor = instance.interceptors.request.use(reqInterceptor, requestErrInterceptor);
        const responseInterceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);

        return () => {
            instance.interceptors.request.eject(requestInterceptor);
            instance.interceptors.response.eject(responseInterceptor);
        };
    }, [auth, location.pathname, navigate]);

    return children;
};

export default instance;
export { AxiosInterceptor };
