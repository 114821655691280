import React, { useState, Fragment } from 'react';
import { Container, Form } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import infoBarType from '../../enums/infoBarType';
import InfoBars from '../../components/common/InfoBars';
import { FormGroup, Label } from 'reactstrap';
import { ReactComponent as ResIcon } from '../../content/images/rd-lite-logo.svg';
import { ReactComponent as SuccessIcon } from '../../content/icons/Icon-Success.svg';
import ValidationInput from '../common/ValidationInput';
import useValidation from '../../hooks/useValidation';
import AccountService from '../../services/AccountService';
import { Link } from 'react-router-dom';
import useScreenSize from '../../hooks/useScreenSize';

let _ID = 0;

function ForgotPasswordPage() {
    const intl = useIntl();
    const [username, setUsername] = useState('');
    const [infoBars, setInfoBars] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const addErrorBar = (message) => {
        setIsSubmitting(false);
        setInfoBars([...infoBars, { id: _ID++, type: infoBarType.error, message: message }]);
    };

    const { isMobileView } = useScreenSize();
    const { register, errors, submitDisabled, submit } = useValidation();

    function submitForgotPassword() {
        setIsSubmitting(true);
        AccountService.forgotPassword(username)
            .then((result) => {
                if (result.data.isSuccess === true) {
                    setSubmitted(true);
                } else {
                    addErrorBar(result.data.errorMessage);
                }
            })
            .catch(() => {
                addErrorBar();
            });
    }

    return (
        <Container className="login-page">
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <div className="flex-container-center">
                <div className="login-image">
                    <ResIcon />
                </div>
                <div className={isMobileView ? 'login-form-container mobile' : 'login-form-container'}>
                    {!submitted && (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                submit(submitForgotPassword);
                            }}
                        >
                            <Fragment>
                                <div className="text-left mb-4">
                                    <FormattedMessage id="Login.ForgotPasswordMessageText" />
                                </div>

                                <FormGroup>
                                    <div className="d-flex">
                                        <Label for="username">
                                            <FormattedMessage id="Login.Email" />
                                        </Label>
                                    </div>
                                    <ValidationInput
                                        testId="forgot-password-form-username"
                                        type="text"
                                        name="username"
                                        value={username}
                                        onChange={(e) => setUsername(e)}
                                        innerRef={register({
                                            required: intl.formatMessage({ id: 'Login.EmailRequired' }),
                                        })}
                                        errors={errors}
                                        autoFocus
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <input
                                        disabled={isSubmitting || submitDisabled}
                                        type="submit"
                                        value={
                                            isSubmitting
                                                ? intl.formatMessage({
                                                      id: 'Common.Submitting',
                                                  })
                                                : intl.formatMessage({
                                                      id: 'Common.Submit',
                                                  })
                                        }
                                        className="login-button btn btn-primary"
                                        data-testid="forgot-password-form-submit-button"
                                    />
                                </FormGroup>
                            </Fragment>
                        </Form>
                    )}
                    {submitted && (
                        <Fragment>
                            <div className="progress-icon-container success-icon mb-4">
                                <SuccessIcon />
                            </div>
                            <FormGroup>
                                <span data-testid="success-message">
                                    <FormattedMessage
                                        id="Login.ForgotPasswordSuccessMessage"
                                        values={{ email: username }}
                                    />
                                </span>
                            </FormGroup>
                        </Fragment>
                    )}
                    <FormGroup>
                        <Link to="/">
                            <FormattedMessage id="Login.BackToLogin" />
                        </Link>
                    </FormGroup>
                </div>
            </div>
        </Container>
    );
}

export default ForgotPasswordPage;
