import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import { findIana } from 'windows-iana';

class MomentHelper {
    static setUp(currentUiCultureName, currentUiCultureTwoLetterIsoLanguageName, providerTimeZone) {
        this.applyOverrides();
        moment.tz.setDefault(findIana(providerTimeZone)[0]);
        //moment.locale([currentUiCultureName, currentUiCultureTwoLetterIsoLanguageName, 'en']);
    }

    static applyOverrides() {
        this.enOverrides();
        this.enGbOverrides();
    }

    static enOverrides() {
        moment.updateLocale('en', {
            longDateFormat: {
                LLL: 'ddd[,] MMM D YYYY [-] HH:mm',
                LL: 'ddd[,] D MMM YYYY',
            },
        });
    }

    static enGbOverrides() {
        moment.updateLocale('en-gb', {
            longDateFormat: {
                LLL: 'ddd[,] D MMM YYYY [-] HH:mm',
                LL: 'ddd[,] D MMM YYYY',
            },
        });
    }

    static newInstance(dateTimeString) {
        if (dateTimeString && dateTimeString.length > 0) {
            return moment(dateTimeString);
        } else {
            return moment();
        }
    }

    static newInstanceUtc(dateTimeString) {
        if (dateTimeString && dateTimeString.length > 0) {
            return moment.utc(dateTimeString);
        } else {
            return moment().utc();
        }
    }

    static dateWithFormat(date, format, isStrict) {
        return moment(date, format, isStrict);
    }

    static formattedDate(dateTime) {
        if (moment(dateTime).isValid()) {
            return moment(dateTime).format('LL');
        }
        return dateTime;
    }

    static formatted12HourTimeFormat(dateTime) {
        if (moment(dateTime).isValid()) {
            return moment(dateTime).format('hh:mm a');
        }
        return dateTime;
    }

    static formatted24HourTimeFormat(dateTime) {
        if (moment(dateTime).isValid()) {
            return moment(dateTime).format('HH:mm');
        }
        return dateTime;
    }

    static getFirstDayOfWeekForLocale() {
        return moment.localeData(moment.locale()).firstDayOfWeek();
    }

    static getDateFormatWithoutDay() {
        switch (moment.locale()) {
            case 'en':
                return 'MMM D YYYY';
            case 'en-gb':
                return 'D MMM YYYY';
            default:
                return 'D MMM YYYY';
        }
    }

    static getDateFormat() {
        switch (moment.locale()) {
            case 'en':
                return 'ddd, MMM D YYYY';
            case 'en-gb':
                return 'ddd, D MMM YYYY';
            default:
                return 'ddd, D MMM YYYY';
        }
    }

    static isDateWithinRange(currentDate, startDate, endDate) {
        return currentDate.isBetween(startDate, endDate, null, '[)');
    }

    static isDateWithinRangeExclusive(currentDate, startDate, endDate) {
        return currentDate.isBetween(startDate, endDate, null, '(]');
    }

    static isDateBeforeNow(date) {
        return moment().isAfter(moment(date));
    }

    static toServerDateFormat(momentObject) {
        return momentObject.format('YYYY-MM-DDT00:00:00.000');
    }

    static toServerDateTimeFormat(momentObject) {
        return momentObject.format('YYYY-MM-DDTHH:mm:ss');
    }

    static toServerTimeFormat(momentObject) {
        return momentObject.format('HH:mm:ss');
    }

    static createDateTimeWithTime(timeString) {
        if (timeString && timeString.length > 0) {
            let date = moment();
            const timeArray = timeString.split(':');
            const hours = timeArray[0];
            const minutes = timeArray[1];

            let dateTime = date
                .clone()
                .set('hour', hours)
                .set('minute', minutes)
                .set('second', 0)
                .set('millisecond', 0);

            return dateTime;
        }
    }

    static areDatesEqual(date1, date2) {
        return date1.isSame(date2);
    }
}
export default MomentHelper;
