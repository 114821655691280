import React, { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import VoucherService from '../../services/VoucherService';
import DiaryContext from '../../contexts/DiaryContext';
import SoldVoucher from '../../domainObjects/SoldVoucher';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import { Button } from 'reactstrap';
import TextWithIcon from '../common/TextWithIcon';
import { ReactComponent as SuccessIcon } from '../../content/icons/Icon-Success.svg';
import { ReactComponent as WarningIcon } from '../../content/icons/Icon-Alert.svg';
import voucherStatus from '../../enums/voucherStatus';
import SearchInput from '../common/SearchInput';
import useValidation from '../../hooks/useValidation';
import useScreenSize from '../../hooks/useScreenSize';
import FooterPreviewModal from '../common/FooterPreviewModal';
import SessionContext from '../../contexts/SessionContext';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';

function VoucherRedemptionTypeahead(props) {
    const intl = useIntl();
    const [soldVoucher, setSoldVoucher] = useState(null);
    const [voucherResultCode, setVoucherResultCode] = useState(null);
    const [voucherReference, setVoucherReference] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);

    const { isMobileView } = useScreenSize();
    const { register, errors, submit } = useValidation();

    function getAnalyticsProperties() {
        return {
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
        };
    }

    function searchReference(reference) {
        let analyticsProperties = getAnalyticsProperties();
        analyticsProperties.searchTerm = reference;

        AnalyticsHelper.trackClickWithProperties('Web VoucherRedemption Search', analyticsProperties);

        VoucherService.getVoucherByReference(diaryContext.restaurantId, diaryContext.deploymentId, reference)
            .then((response) => {
                if (response.SoldVoucher) {
                    const soldVoucher = new SoldVoucher(response.SoldVoucher);
                    setSoldVoucher(soldVoucher);
                    setVoucherReference(reference);

                    if (isMobileView) {
                        setIsModalOpen(true);
                    }
                } else {
                    setVoucherResultCode(voucherStatus.invalidVoucher);
                }
            })
            .catch(() => {
                props.addErrorBar();
            });
    }

    function redeemVoucher() {
        let analyticsProperties = getAnalyticsProperties();
        analyticsProperties.redeemedVoucher = soldVoucher;

        AnalyticsHelper.trackClickWithProperties('Web VoucherRedemption Redeem', analyticsProperties);

        VoucherService.redeemVoucher(diaryContext.restaurantId, diaryContext.deploymentId, voucherReference)
            .then((response) => {
                if (isMobileView) {
                    if (response === voucherStatus.valid) {
                        props.addSuccessBar(getVoucherRedemptionResultText(response));
                    } else {
                        props.addErrorBar(getVoucherRedemptionResultText(response));
                    }
                    setIsModalOpen(false);
                } else {
                    setVoucherResultCode(response);
                }
                props.refreshVoucherData();
            })
            .catch(() => {
                props.addErrorBar();
            });
    }

    function clearResults() {
        setSoldVoucher(null);
        setVoucherResultCode(null);
        setVoucherReference(null);
    }

    function getFormattedExpiryDate() {
        if (!soldVoucher.expiresOn) {
            return <FormattedMessage id="Vouchers.VoucherNoExpiry" />;
        }

        const formattedDate = soldVoucher.expiresOn.format('D MMM YYYY');
        return <FormattedMessage id="Common.ExpiresOn" values={{ expiry: formattedDate }} />;
    }

    function getVoucherRedemptionResultText(resultCode) {
        let analyticsProperties = getAnalyticsProperties();
        analyticsProperties.redeemedVoucher = voucherReference;

        switch (resultCode) {
            case voucherStatus.valid:
                AnalyticsHelper.trackClickWithProperties('Web VoucherRedemption Redeemed', analyticsProperties);
                return intl.formatMessage(
                    { id: 'Vouchers.VoucherSuccessfullyRedeemed' },
                    { voucherRef: soldVoucher.reference }
                );
            case voucherStatus.expired:
                AnalyticsHelper.trackClickWithProperties('Web VoucherRedemption ExpiredCode', analyticsProperties);
                return intl.formatMessage({ id: 'Vouchers.VoucherReferenceExpired' });
            case voucherStatus.redeemed:
                AnalyticsHelper.trackClickWithProperties('Web VoucherRedemption AlreadyRedeemed', analyticsProperties);
                return intl.formatMessage({ id: 'Vouchers.VoucherAlreadyRedeemed' });
            default:
                AnalyticsHelper.trackClickWithProperties('Web VoucherRedemption InvalidCode', analyticsProperties);
                return intl.formatMessage({ id: 'Vouchers.InvalidVoucherReference' });
        }
    }

    function getVoucherRedemptionResultMessage() {
        const voucherStatusMessage = getVoucherRedemptionResultText(voucherResultCode);

        if (voucherResultCode === voucherStatus.valid) {
            return (
                <TextWithIcon
                    text={voucherStatusMessage}
                    icon={<SuccessIcon />}
                    iconClass="progress-icon-container success-icon"
                />
            );
        }

        return <TextWithIcon text={voucherStatusMessage} icon={<WarningIcon />} iconClass="validation-message" />;
    }

    function getVoucherRedemptionEntry() {
        return (
            <div className="voucher-redemption-result-entry">
                <div>
                    <div className="voucher-ref">{voucherReference}</div>
                    <div className="voucher-title">
                        {`${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)}${
                            soldVoucher.voucher.cost
                        } ${soldVoucher.voucher.title}`}
                    </div>
                    <div className="voucher-expiry">{getFormattedExpiryDate()}</div>
                </div>
                <div className="ml-auto">
                    <Button color="primary" onClick={() => redeemVoucher(voucherReference)}>
                        <FormattedMessage id="Common.Redeem" />
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="voucher-redemption-typeahead">
            <div className={voucherResultCode ? '' : 'mb-2'}>
                <SearchInput
                    onSearch={searchReference}
                    inputPlaceholderText={intl.formatMessage({ id: 'Vouchers.EnterVoucherReference' })}
                    includeSearchButton={!isMobileView}
                    clearResults={clearResults}
                    registerValidation={register}
                    includeValidation
                    validationMessage={intl.formatMessage({ id: 'Vouchers.VoucherReferenceRequired' })}
                    validationName={'voucherReference'}
                    errors={errors}
                    submit={submit}
                />
            </div>

            {voucherResultCode && (
                <div
                    className={
                        voucherResultCode !== voucherStatus.valid
                            ? 'voucher-result-message invalid'
                            : 'voucher-result-message'
                    }
                >
                    {getVoucherRedemptionResultMessage()}
                </div>
            )}

            {soldVoucher && !voucherResultCode && !isMobileView && <Fragment>{getVoucherRedemptionEntry()}</Fragment>}

            {isModalOpen && (
                <FooterPreviewModal
                    title={intl.formatMessage({ id: 'Vouchers.RedeemVoucher' })}
                    body={getVoucherRedemptionEntry()}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            )}
        </div>
    );
}

VoucherRedemptionTypeahead.propTypes = {
    refreshVoucherData: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func,
    addSuccessBar: PropTypes.func,
};

export default VoucherRedemptionTypeahead;
