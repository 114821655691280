import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import BreadcrumbPage from '../../common/BreadcrumbPage';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as ConfirmIcon } from '../../../content/icons/confirm.svg';
import SettingsHelper from '../../../helpers/SettingsHelper';
import DiaryContext from '../../../contexts/DiaryContext';
import useScreenSize from '../../../hooks/useScreenSize';

function BookingWidgetPage() {
    const intl = useIntl();
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const diaryContext = useContext(DiaryContext);
    const { isMobileView } = useScreenSize();

    const widgetUrl = `${SettingsHelper.getWidgetUrl()}/widget/Standard/${diaryContext.micrositeName}/${
        diaryContext.widgetId
    }?includeJquery=false`;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `${SettingsHelper.getWidgetUrl()}/bundles/WidgetV2Loader.js`;
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => window.onload();
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    function getBreadcrumbOptions() {
        return [new BreadCrumbOption(intl.formatMessage({ id: 'Settings.Title' }), '/Settings')];
    }

    function getWidgetCode() {
        return `<div id="rd-widget-frame" style="max-width: 600px; margin: auto;"></div>\n\n<input id="rdwidgeturl" name="rdwidgeturl" value=${widgetUrl} type="hidden">\n<script type="text/javascript" src="${SettingsHelper.getWidgetUrl()}/bundles/WidgetV2Loader.js"></script>`;
    }

    function copyToClipboard() {
        let range = document.createRange();
        range.selectNode(document.getElementById('widget-code'));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        setCopiedToClipboard(true);
    }

    function getContainerClasses() {
        const contentClass = 'booking-widget-content';
        return isMobileView ? `${contentClass} mobile` : contentClass;
    }

    return (
        <BreadcrumbPage
            pageTitle={intl.formatMessage({ id: 'Settings.BookingWidgetTitleText' })}
            breadcrumbOptions={getBreadcrumbOptions()}
        >
            <FormattedMessage id="Settings.BookingWidgetTitleText" tagName="h2" />
            <div className={getContainerClasses()}>
                <Row>
                    <Col md="6">
                        <div className="widget-description">
                            <div>
                                <FormattedMessage id="Settings.BookingWidgetDescriptionLine1" />
                            </div>
                            <div>
                                <FormattedMessage id="Settings.BookingWidgetDescriptionLine2" />
                            </div>
                        </div>
                        <div className="widget-code">
                            <div className="code-box" id="widget-code" onClick={() => copyToClipboard()}>
                                <div className="code">{getWidgetCode()}</div>
                                {copiedToClipboard && (
                                    <div className="d-flex success-banner">
                                        <ConfirmIcon />
                                        <div>
                                            <FormattedMessage id="Settings.CopiedToClipboard" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="widget-preview-container">
                            <div id="rd-widget-frame" style={{ maxWidth: '600px', margin: 'auto' }}></div>
                            <input id="rdwidgeturl" name="rdwidgeturl" value={widgetUrl} type="hidden"></input>
                        </div>
                    </Col>
                </Row>
            </div>
        </BreadcrumbPage>
    );
}

export default BookingWidgetPage;
