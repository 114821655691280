import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class FacebookService {
    static getFacebookSettings(deploymentId, providerId, redirectUrl) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/FacebookIntegration/v1/FacebookIntegrationSettings?deploymentId=${deploymentId}&providerId=${providerId}&redirectUrl=${redirectUrl}`
        ).then((response) => response.data);
    }

    static logFacebookIntegrationError(deploymentId, providerId, error) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/FacebookIntegration/v1/LogFacebookIntegrationError/${deploymentId}/${providerId}?error=${error}`
        ).then((response) => response.data);
    }

    static disconnectFromFacebook(deploymentId, providerId) {
        return ApiHelper.deleteRequest(
            `${SettingsHelper.getApiUrl()}/api/FacebookIntegration/v1/DisableFacebookIntegration/${deploymentId}/${providerId}`
        ).then((response) => response.data);
    }
}

export default FacebookService;
