import React from 'react';
import PropTypes from 'prop-types';
import BreadCrumbDisplay from './BreadCrumbDisplay';
import InfoBars from './InfoBars';

function BreadcrumbPage(props) {
    return (
        <div className="main-body" data-testid={props.dataTestId}>
            {props.setInfoBars && props.infoBars && (
                <InfoBars infoBars={props.infoBars} setInfoBars={props.setInfoBars} />
            )}
            <BreadCrumbDisplay breadcrumbs={props.breadcrumbOptions} activePageTitle={props.pageTitle} />
            {props.children}
        </div>
    );
}
BreadcrumbPage.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    breadcrumbOptions: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    setInfoBars: PropTypes.func,
    infoBars: PropTypes.array,
    dataTestId: PropTypes.string,
};

export default BreadcrumbPage;
