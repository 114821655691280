import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import voucherTableColumns from '../../../enums/voucherTableColumns';
import PurchasedVoucher from '../../../domainObjects/PurchasedVoucher';
import voucherReportStatus from '../../../enums/voucherReportStatus';
import MomentHelper from '../../../helpers/MomentHelper';
import voucherType from '../../../enums/voucherType';
import CurrencyDiv from '../../common/CurrencyDiv';
import Button from 'reactstrap/lib/Button';

function VoucherTableEntry(props) {
    function getStatus(status) {
        switch (status) {
            case voucherReportStatus.Redeemed:
                return (
                    <p className="voucher-status redeemed" key={status}>
                        <FormattedMessage id="Vouchers.Redeemed" />
                    </p>
                );
            case voucherReportStatus.NotRedeemed:
                return (
                    <p className="voucher-status not-redeemed" key={status}>
                        <FormattedMessage id="Vouchers.NotRedeemed" />
                    </p>
                );
            case voucherReportStatus.Expired:
                return (
                    <p className="voucher-status expired" key={status}>
                        <FormattedMessage id="Vouchers.Expired" />
                    </p>
                );
            default:
                break;
        }
    }

    function getType(type) {
        switch (type) {
            case voucherType.Gift:
                return (
                    <p>
                        <FormattedMessage id="Vouchers.Gift" />
                    </p>
                );
            case voucherType.Personal:
                return (
                    <p>
                        <FormattedMessage id="Vouchers.Personal" />
                    </p>
                );
            default:
                break;
        }
    }

    function convertDateTime(dateTime) {
        return MomentHelper.newInstance(dateTime).format('DD MMM YYYY');
    }

    return (
        <tr>
            {props.columnTypes.map((c) => {
                switch (c.sortOrder) {
                    case voucherTableColumns.VoucherReference:
                        return <td key={c.sortOrder}>{props.voucher.reference}</td>;
                    case voucherTableColumns.Price:
                        return (
                            <td key={c.sortOrder}>
                                <CurrencyDiv price={props.voucher.voucherCost} />
                            </td>
                        );
                    case voucherTableColumns.VoucherName:
                        return <td key={c.sortOrder}>{props.voucher.voucherName}</td>;
                    case voucherTableColumns.CustomerName:
                        if (props.voucher.type === voucherType.Gift) {
                            return (
                                <td key={c.sortOrder}>
                                    {props.voucher.customerName}
                                    <br />
                                    <strong>
                                        <FormattedMessage id="Vouchers.Recipient" />:
                                    </strong>
                                    <br />
                                    {props.voucher.deliveryName}
                                    <br />
                                    {props.voucher.deliveryEmail}
                                    <br />
                                </td>
                            );
                        } else {
                            return <td key={c}>{props.voucher.customerName}</td>;
                        }
                    case voucherTableColumns.EmailAddress:
                        const resendButton =
                            props.voucher.status === voucherReportStatus.NotRedeemed ? (
                                <Button
                                    color="link"
                                    className="p-0"
                                    onClick={() => props.setIsResendVoucherModalOpen(true, props.voucher.orderId)}
                                >
                                    <FormattedMessage id="Vouchers.Resend" />
                                </Button>
                            ) : null;
                        const resendEmailText = props.voucher.customerEmail ? (
                            <Fragment>{props.voucher.customerEmail}</Fragment>
                        ) : (
                            <Fragment>
                                <FormattedMessage id="Common.Internal" />
                            </Fragment>
                        );
                        return (
                            <td key={c.sortOrder}>
                                {resendEmailText}
                                <br />
                                {resendButton}
                            </td>
                        );
                    case voucherTableColumns.Phone:
                        return <td key={c.sortOrder}>{props.voucher.customerPhone}</td>;
                    case voucherTableColumns.Type:
                        return <td key={c.sortOrder}>{getType(props.voucher.type)}</td>;
                    case voucherTableColumns.Purchased:
                        return <td key={c.sortOrder}>{convertDateTime(props.voucher.voucherOrderDate)}</td>;
                    case voucherTableColumns.Validity:
                        if (props.voucher.voucherValidity) {
                            return (
                                <td key={c.sortOrder}>
                                    <FormattedMessage
                                        id="Common.MonthsDuration"
                                        values={{ months: props.voucher.voucherValidity }}
                                    />
                                </td>
                            );
                        } else {
                            return (
                                <td key={c.sortOrder}>
                                    <FormattedMessage id="Vouchers.VoucherReportNoExpiry" />
                                </td>
                            );
                        }
                    case voucherTableColumns.Redemption:
                        if (props.voucher.voucherRedeemedDate) {
                            return <td key={c.sortOrder}>{convertDateTime(props.voucher.voucherRedeemedDate)}</td>;
                        } else {
                            return <td key={c.sortOrder}></td>;
                        }

                    case voucherTableColumns.BookingReference:
                        return <td key={c.sortOrder}>{props.voucher.voucherRedeemedBookingReference}</td>;
                    case voucherTableColumns.Status:
                        return <td key={c.sortOrder}>{getStatus(props.voucher.status)}</td>;
                    default:
                        return <td key={c.sortOrder}></td>;
                }
            })}
        </tr>
    );
}

VoucherTableEntry.propTypes = {
    voucher: PropTypes.instanceOf(PurchasedVoucher),
    columnTypes: PropTypes.array,
    setIsResendVoucherModalOpen: PropTypes.func.isRequired,
};

export default VoucherTableEntry;
