class Cuisine {
    constructor(dto) {
        this.value = dto && dto.Id ? dto.Id : 0;
        this.text = dto && dto.Name ? dto.Name : '';
    }

    getDTO(value, text) {
        return {
            Id: value,
            Name: text,
        };
    }
}

export default Cuisine;
