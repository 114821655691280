import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ModalPanel from '../common/ModalPanel';
import { ReactComponent as KnowledgeBaseIcon } from '../.././content/icons/speech-bubble.svg';
import { ReactComponent as NewTicketIcon } from '../.././content/icons/building.svg';
import { ReactComponent as FeatureSuggestionsIcon } from '../.././content/icons/light-bulb.svg';
import { ReactComponent as ChevronRight } from '../.././content/icons/chevron-right.svg';
import constants from '../.././enums/constants';
import { useIntl } from 'react-intl';
import FreshdeskTicketRaiser from './FreshdeskTicketRaiser';

function HelpAndSupportModal(props) {
    const intl = useIntl();

    const [isFreshDeskTicketRaiserOpen, setIsFreshDeskTicketRaiserOpen] = useState(false);

    function getCloseButton() {
        return (
            <button className="close" onClick={() => closeModal()}>
                &times;
            </button>
        );
    }

    function closeModal() {
        props.setIsModalOpen(false);
        setIsFreshDeskTicketRaiserOpen(false);
    }

    return (
        <Modal
            zIndex={1072}
            size="md"
            centered
            isOpen={props.isModalOpen}
            style={{ maxWidth: '700px', width: '100%' }}
            className="modal help-support-modal"
        >
            <ModalHeader close={getCloseButton()}></ModalHeader>
            <ModalBody>
                {isFreshDeskTicketRaiserOpen ? (
                    <FreshdeskTicketRaiser userName={props.userName} restaurantId={props.restaurantId} />
                ) : (
                    <div>
                        <hr style={{ color: '#333' }} />
                        <a
                            className="support-option-container"
                            href={constants.resdiarySupport}
                            target="_blank"
                            onClick={() => props.setIsModalOpen(false)}
                            rel="noreferrer"
                        >
                            <ModalPanel
                                icon={<div>{<KnowledgeBaseIcon />}</div>}
                                text={intl.formatMessage({ id: 'HelpAndSupport.KnowledgeBase' })}
                                textClass="header-text"
                                iconClass="support-icon"
                                infoText={intl.formatMessage({ id: 'HelpAndSupport.KnowledgeBaseInfo' })}
                                infoTextClass="info-text"
                            />
                            <ChevronRight />
                        </a>
                        <hr style={{ color: '#333' }} />
                        <button className="ticket-raiser-button" onClick={() => setIsFreshDeskTicketRaiserOpen(true)}>
                            <ModalPanel
                                icon={<div>{<NewTicketIcon />}</div>}
                                text={intl.formatMessage({ id: 'HelpAndSupport.NewTicket' })}
                                textClass="header-text"
                                iconClass="support-icon"
                                infoText={intl.formatMessage({ id: 'HelpAndSupport.NewTicketInfo' })}
                                infoTextClass="info-text"
                            />
                            <ChevronRight />
                        </button>
                        <hr style={{ color: '#333' }} />
                        <a
                            className="support-option-container"
                            href={constants.resdiarySupportForums}
                            target="_blank"
                            onClick={() => props.setIsModalOpen(false)}
                            rel="noreferrer"
                        >
                            <ModalPanel
                                icon={<div>{<FeatureSuggestionsIcon />}</div>}
                                text={intl.formatMessage({ id: 'HelpAndSupport.ProductFeatureSuggestions' })}
                                textClass="header-text"
                                iconClass="support-icon"
                                infoText={intl.formatMessage({ id: 'HelpAndSupport.ProductFeatureSuggestionsInfo' })}
                                infoTextClass="info-text"
                            />
                            <ChevronRight />
                        </a>
                        <hr style={{ color: '#333' }} />
                    </div>
                )}
            </ModalBody>
        </Modal>
    );
}

HelpAndSupportModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
    restaurantId: PropTypes.number.isRequired,
};

export default HelpAndSupportModal;
