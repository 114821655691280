import { useEffect } from 'react';

function useOutsideClick(refs, callback) {
    function handleClickOutside(event) {
        let clickedOutside = true;
        for (let ref of refs) {
            if (!ref.current || (ref.current && ref.current.contains(event.target))) {
                clickedOutside = false;
                break;
            }
        }
        if (clickedOutside) {
            callback(event);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
}

export default useOutsideClick;
