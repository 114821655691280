import React from 'react';
import PropTypes from 'prop-types';
import TextWithIcon from '../common/TextWithIcon';
import { Link } from 'react-router-dom';
import Tag from '../common/Tag';
import TagColour from '../../enums/tagColour';
import settingsSectionPanelActionType from '../../enums/settingsSectionPanelActionType';

function SettingsSectionPanel(props) {
    function getRedirectActionContent(data) {
        return (
            <div className="d-flex flex-wrap">
                <a
                    href={data.url}
                    key="title-header"
                    data-testid="settings-link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <h4>{data.title}</h4>
                </a>
            </div>
        );
    }

    function getLinkActionPanelContent(data) {
        return (
            <div className="d-flex flex-wrap">
                <Link to={data.url} key="title-header" data-testid="settings-link" className="settings-action-title">
                    <h4 className="flex-shrink-0">{data.title}</h4>
                </Link>
                {data.tags &&
                    data.tags.map((t) => {
                        return (
                            <Tag
                                key={data.title + ' ' + t.value}
                                value={t.value}
                                color={t.color ? t.color : TagColour.Orange}
                                className={t.className ? t.className : ''}
                            />
                        );
                    })}
            </div>
        );
    }

    function getLinkWithOnClickActionPanelContent(data) {
        return (
            <div className="d-flex flex-wrap">
                <Link to={data.url} key="title-header" data-testid="settings-link" onClick={() => data.onClick()}>
                    <h4 className="flex-shrink-0">{data.title}</h4>
                </Link>
                {data.tags &&
                    data.tags.map((t) => {
                        return (
                            <Tag
                                key={data.title + ' ' + t.value}
                                value={t.value}
                                color={t.color ? t.color : TagColour.Orange}
                                className={t.className ? t.className : ''}
                            />
                        );
                    })}
            </div>
        );
    }

    function getCallbackActionPanelContent(data) {
        return (
            <h4 className="redirect-link" onClick={() => data.callback()}>
                {data.title}
            </h4>
        );
    }

    function getSectionPanelContent(data) {
        switch (data.action) {
            case settingsSectionPanelActionType.redirect:
                return getRedirectActionContent(data);
            case settingsSectionPanelActionType.link:
                return getLinkActionPanelContent(data);
            case settingsSectionPanelActionType.linkWithOnClick:
                return getLinkWithOnClickActionPanelContent(data);
            case settingsSectionPanelActionType.callback:
                return getCallbackActionPanelContent(data);
            default:
                return null;
        }
    }

    return (
        <div className="settings-panel" data-testid="settings-panel">
            <div className="settings-header-panel">
                <TextWithIcon
                    icon={<div>{props.icon}</div>}
                    text={props.panelObject.header}
                    iconClass={props.iconClass}
                    textClass="header-text"
                />
            </div>
            <div className="settings-body-panel">
                {props.panelObject.data.map((d) => {
                    return (
                        <div key={'id ' + d.title} className="settings-data">
                            {getSectionPanelContent(d)}
                            <p key="description">{d.description}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

SettingsSectionPanel.propTypes = {
    panelObject: PropTypes.object.isRequired,
    icon: PropTypes.object.isRequired,
    iconClass: PropTypes.string,
    settingsCallback: PropTypes.func,
};

export default SettingsSectionPanel;
