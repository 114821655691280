import React, { useState, useContext, useEffect } from 'react';
import MenuSectionCategory from '../../../domainObjects/MenuSectionCategory';
import DiaryContext from '../../../contexts/DiaryContext';
import MenuService from '../../../services/MenuService';
import BaseMenuPage from './BaseMenuPage';
import { useParams } from 'react-router-dom';

function EditMenuPage() {
    const diaryContext = useContext(DiaryContext);
    const [menuTitle, setMenuTitle] = useState('');
    const [menuDescription, setMenuDescription] = useState('');
    const [categories, setCategories] = useState([]);
    const [isLoadingMenu, setIsLoadingMenu] = useState(true);
    const [menuCategoryId, setMenuCategoryId] = useState(null);
    const [logoFilename, setLogoFilename] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const { id } = useParams();
    const menuId = parseInt(id);

    useEffect(() => {
        MenuService.getMenu(diaryContext.deploymentId, diaryContext.restaurantId, menuId).then((response) => {
            setMenuTitle(response.Title);
            setMenuDescription(response.Description);
            setMenuCategoryId(response.MenuCategoryId);
            setLogoFilename(response.LogoFilename);
            setLogoUrl(response.LogoUrl);
            const cats = response.MenuSections.map((section) => {
                const cat = new MenuSectionCategory();
                cat.populateFromDiaryDto(section);
                return cat;
            });
            setCategories(cats);
            setIsLoadingMenu(false);
        });
    }, [diaryContext.deploymentId, diaryContext.restaurantId, menuId]);

    return (
        <BaseMenuPage
            menuTitle={menuTitle}
            menuDescription={menuDescription}
            categories={categories}
            menuId={menuId}
            menuCategoryId={menuCategoryId}
            logoFilename={logoFilename}
            logoUrl={logoUrl}
            isLoadingData={isLoadingMenu}
        />
    );
}

export default EditMenuPage;
