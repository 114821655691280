class Currency {
    constructor(dto) {
        this.value = dto && dto.Id ? dto.Id : '';
        this.text = dto && dto.Name ? dto.Name : '';
        this.symbol = dto && dto.Symbol ? dto.Symbol : '';
    }

    getDTO(value, text, symbol) {
        return {
            Id: value,
            Name: text,
            Symbol: symbol,
        };
    }
}

export default Currency;
