import React, { useState, Fragment, useEffect, useContext, useCallback } from 'react';
import AddonWizardBasePage from '../common/AddonWizardBasePage';
import Voucher from '../../domainObjects/Voucher';
import { useIntl, FormattedMessage } from 'react-intl';
import VoucherEditPanel from './VoucherEditPanel';
import VouchersReviewPage from './VouchersReviewPage';
import VouchersIndexPage from './VouchersIndexPage';
import VoucherService from '../../services/VoucherService';
import StripeService from '../../services/StripeService';
import DiaryContext from '../../contexts/DiaryContext';
import infoBarType from '../../enums/infoBarType';
import InfoBars from '../common/InfoBars';
import LoadingSpinner from '../common/LoadingSpinner';
import addonWizardStatus from '../../enums/addonWizardStatus';
import VoucherHelper from '../../helpers/VoucherHelper';
import VouchersOverviewPage from './VouchersOverviewPage';
import MomentHelper from '../../helpers/MomentHelper';
import VouchersPromoteModal from './VouchersPromoteModal';
import VouchersGenerateModal from './VouchersGenerateModal';
import AddOnContext from '../../contexts/AddOnContext';
import addOnType from '../../enums/addOnType';
import useScreenSize from '../../hooks/useScreenSize';
import useQueryParam from '../../hooks/useQueryParam';

let _ID = 0;

function VouchersManagementPage() {
    const intl = useIntl();
    const [vouchers, setVouchers] = useState([]);
    const [infoBars, setInfoBars] = useState([]);
    const [isCreatingVoucher, setIsCreatingVoucher] = useState(false);
    const [voucherToUpdate, setVoucherToUpdate] = useState(new Voucher());
    const [isLoading, setIsLoading] = useState(true);
    const [displayPromoteModal, setDisplayPromoteModal] = useState(false);
    const [displayGenerateModal, setDisplayGenerateModal] = useState(false);
    const [voucherToGenerateId, setVoucherToGenerateId] = useState(null);
    const [vouchersEnabled, setVouchersEnabled] = useState(false);
    const [isConnectedToStripe, setIsConnectedToStripe] = useState(null);
    const [initialSetupComplete, setInitialSetupComplete] = useState(false);
    const [voucherTotalSalesStats, setVoucherTotalSalesStats] = useState(null);
    const [minValidityPeriod, setMinValidityPeriod] = useState(null);
    const [displayReviewPage, setDisplayReviewPage] = useState(false);
    const [lastFetchVoucherDateTime, setLastFetchVoucherDateTime] = useState(MomentHelper.newInstance().toString());

    const code = useQueryParam('code');
    const state = useQueryParam('state');

    const diaryContext = useContext(DiaryContext);
    const addOnContext = useContext(AddOnContext);
    const { isMobileView } = useScreenSize();

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const toggleAddNewVoucher = () => {
        setVoucherToUpdate(new Voucher());
        setIsCreatingVoucher(!isCreatingVoucher);
    };

    const togglePromoteModal = () => {
        setDisplayPromoteModal(!displayPromoteModal);
    };

    const toggleAddon = () => {
        addOnContext.enableAddOn(addOnType.vouchers);
        setInitialSetupComplete(true);
        setDisplayPromoteModal(true);
    };

    function setVoucherBeingUpdated(voucherId) {
        const voucher = vouchers.find((v) => v.id === voucherId);
        setVoucherToUpdate(voucher);
        setIsCreatingVoucher(true);
    }

    function dismissModalAfterWizardSetup() {
        setInitialSetupComplete(false);
        togglePromoteModal();
    }

    function displayGenerateModalWithDefaultVoucher(setIsVisible, voucherId) {
        setDisplayGenerateModal(setIsVisible);
        setVoucherToGenerateId(voucherId);
    }

    useEffect(() => {
        VoucherService.getVoucherStats(diaryContext.deploymentId, diaryContext.restaurantId).then((response) => {
            setVoucherTotalSalesStats({
                totalVouchersSold: response.TotalVouchersSold,
                totalVouchersRedeemed: response.TotalVouchersRedeemed,
            });
        });
    }, [diaryContext.restaurantId, diaryContext.deploymentId, lastFetchVoucherDateTime]);

    useEffect(() => {
        VoucherService.getMinimumValidityPeriod(diaryContext.restaurantId, diaryContext.deploymentId).then((vp) => {
            if (vp) {
                setMinValidityPeriod(vp);
            } else {
                setMinValidityPeriod(0);
            }
        });
    }, [diaryContext.restaurantId, diaryContext.deploymentId]);

    useEffect(() => {
        setVouchersEnabled(addOnContext && addOnContext.isAddOnEnabled(addOnType.vouchers));
    }, [addOnContext]);

    useEffect(() => {
        if (code && code !== '') {
            setDisplayReviewPage(true);
        }
    }, [code]);

    useEffect(() => {
        if (isConnectedToStripe === null) {
            StripeService.isStripeEnabledForProvider(diaryContext.deploymentId, diaryContext.restaurantId).then(
                (isConnectedToStripe) => {
                    setIsConnectedToStripe(isConnectedToStripe);
                }
            );
        }
    }, [isConnectedToStripe, diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        VoucherService.getVouchers(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((response) => {
                const data = response.Vouchers.map((voucher) => new Voucher(voucher));
                setVouchers(data);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [diaryContext.deploymentId, diaryContext.restaurantId, addErrorBar, lastFetchVoucherDateTime]);

    function saveAndProceed(voucher, callback) {
        if (voucher.id > 0) {
            updateVoucher(voucher, callback);
        } else {
            voucher.createdDate = MomentHelper.toServerDateFormat(MomentHelper.newInstance());
            createVoucher(voucher, callback);
        }
    }

    function createVoucher(voucher, callback) {
        VoucherService.createVoucher(diaryContext.deploymentId, diaryContext.restaurantId, getVoucherDto(voucher))
            .then((response) => {
                setVouchers([...vouchers, new Voucher(response.Voucher)]);

                if (callback) {
                    callback();
                }

                if (vouchers.length === 0) {
                    addSuccessBar(
                        intl.formatMessage({
                            id: 'Vouchers.InitialVoucherCreationSuccessMessage',
                        })
                    );
                } else {
                    addSuccessBar(
                        intl.formatMessage({
                            id: 'Vouchers.VoucherCreatedSuccessMessage',
                        })
                    );
                }
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsCreatingVoucher(false);
                setDisplayReviewPage(true);
            });
    }

    function updateVoucher(voucher, callback) {
        VoucherService.updateVoucher(diaryContext.deploymentId, diaryContext.restaurantId, getVoucherDto(voucher))
            .then((response) => {
                let vouchersCopy = [...vouchers];
                let index = vouchers.findIndex((v) => v.id === response.Voucher.VoucherId);
                vouchersCopy[index] = new Voucher(response.Voucher);
                setVouchers(vouchersCopy);

                addSuccessBar(
                    intl.formatMessage({
                        id: 'Vouchers.VoucherUpdatedSuccessMessage',
                    })
                );

                if (callback) {
                    callback();
                }
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsCreatingVoucher(false);
            });
    }

    function deleteVoucher(voucherId, callback) {
        VoucherService.deleteVoucher(diaryContext.deploymentId, diaryContext.restaurantId, voucherId)
            .then(() => {
                setVouchers(vouchers.filter((v) => v.id !== voucherId));

                addSuccessBar(
                    intl.formatMessage({
                        id: 'Common.ItemRemovedInfoBarSuccessMessage',
                    })
                );

                if (callback) {
                    callback();
                }

                if (vouchers.length === 1) {
                    setDisplayReviewPage(false);
                }
            })
            .catch(() => {
                addErrorBar();
            });
    }

    function getVoucherDto(voucher) {
        return VoucherHelper.toJson(voucher);
    }

    function refreshVoucherData() {
        setLastFetchVoucherDateTime(MomentHelper.newInstance().toString());
    }

    function getPageContent() {
        if (isCreatingVoucher) {
            return (
                <div className={isMobileView ? 'vouchers-flex-container mob' : 'vouchers-flex-container '}>
                    <div className="vouchers-wizard-heading">
                        <h2>
                            <FormattedMessage id="Vouchers.VouchersHeadingTitle" />
                        </h2>
                        <p>
                            <FormattedMessage id="Vouchers.VouchersHeadingDescription" />
                        </p>
                    </div>
                    <AddonWizardBasePage
                        status={addonWizardStatus.Create}
                        title={
                            isMobileView
                                ? intl.formatMessage({ id: 'Common.Create' })
                                : intl.formatMessage({ id: 'Vouchers.VouchersSectionTitle' })
                        }
                        isMobileView={isMobileView}
                    >
                        <VoucherEditPanel
                            voucher={voucherToUpdate}
                            saveVoucher={saveAndProceed}
                            isEditing={voucherToUpdate.id > 0}
                            isInitialSetup
                            toggleAddNewVoucher={toggleAddNewVoucher}
                            minValidityPeriod={minValidityPeriod}
                        />
                    </AddonWizardBasePage>
                </div>
            );
        } else if (displayReviewPage) {
            return (
                <div className={isMobileView ? 'vouchers-flex-container mob' : 'vouchers-flex-container '}>
                    <div className="vouchers-wizard-heading">
                        <h2>
                            <FormattedMessage id="Vouchers.VouchersHeadingTitle" />
                        </h2>
                        <p>
                            <FormattedMessage id="Vouchers.VouchersHeadingDescription" />
                        </p>
                    </div>
                    <AddonWizardBasePage
                        status={addonWizardStatus.Review}
                        title={
                            isMobileView
                                ? intl.formatMessage({ id: 'Common.Review' })
                                : intl.formatMessage({ id: 'Vouchers.VouchersSectionTitle' })
                        }
                        isMobileView={isMobileView}
                    >
                        <VouchersReviewPage
                            vouchers={vouchers}
                            toggleAddNewVoucher={toggleAddNewVoucher}
                            setVoucherBeingUpdated={setVoucherBeingUpdated}
                            deleteVoucher={deleteVoucher}
                            code={code}
                            state={state}
                            addSuccessBar={addSuccessBar}
                            isConnectedToStripe={isConnectedToStripe}
                            setIsConnectedToStripe={setIsConnectedToStripe}
                            addErrorBar={addErrorBar}
                            toggleAddon={toggleAddon}
                            isMobileView={isMobileView}
                        />
                    </AddonWizardBasePage>
                </div>
            );
        }

        return (
            <VouchersOverviewPage
                toggleAddNewVoucher={toggleAddNewVoucher}
                continueOnboardingFlow={setDisplayReviewPage}
                hasVouchers={vouchers.length > 0}
            />
        );
    }

    if (isLoading || isConnectedToStripe === null) {
        return <LoadingSpinner />;
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />

            {displayPromoteModal && (
                <VouchersPromoteModal
                    isModalOpen={displayPromoteModal}
                    setIsModalOpen={setDisplayPromoteModal}
                    addSuccessBar={addSuccessBar}
                    addErrorBar={addErrorBar}
                    initialSetupComplete={initialSetupComplete}
                    dismissModalAfterWizardSetup={dismissModalAfterWizardSetup}
                />
            )}

            {displayGenerateModal && (
                <VouchersGenerateModal
                    isModalOpen={displayGenerateModal}
                    setIsModalOpen={() => displayGenerateModalWithDefaultVoucher(false, null)}
                    addSuccessBar={addSuccessBar}
                    addErrorBar={addErrorBar}
                    vouchers={vouchers}
                    defaultVoucherId={voucherToGenerateId}
                    refreshVoucherData={refreshVoucherData}
                />
            )}

            {vouchersEnabled && (
                <VouchersIndexPage
                    vouchers={vouchers}
                    saveVoucher={saveAndProceed}
                    togglePromoteModal={togglePromoteModal}
                    voucherTotalSalesStats={voucherTotalSalesStats}
                    minValidityPeriod={minValidityPeriod}
                    addErrorBar={addErrorBar}
                    addSuccessBar={addSuccessBar}
                    isLoading={isLoading}
                    setDisplayGenerateModal={setDisplayGenerateModal}
                    displayGenerateModalWithDefaultVoucher={displayGenerateModalWithDefaultVoucher}
                    refreshVoucherData={refreshVoucherData}
                />
            )}

            {!vouchersEnabled && <div className="vouchers-page">{getPageContent()}</div>}
        </Fragment>
    );
}

export default VouchersManagementPage;
