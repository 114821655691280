const reportType = {
    BookingSummary: 0,
    BookingDetails: 1,
    CancellationSummary: 2,
    CancellationDetails: 3,
    EventSummary: 4,
    Dashboard: 5,
    IncompleteBookings: 6,
    FeedbackDetails: 8,
    FeedbackSummary: 9,
    PreOrdersList: 10,
    PreOrdersDishes: 11,
    VoucherSalesDetails: 12,
};

export default reportType;
