class ProgressFlags {
    constructor(progressFlags) {
        this.uploadedMenu = progressFlags ? progressFlags.UploadedMenu : true;
        this.addedAdditionalImage = progressFlags ? progressFlags.AddedAdditionalImage : true;
        this.addedAdvancedListing = progressFlags ? progressFlags.AddedAdvancedListing : true;
        this.dismissedCompletionModal = progressFlags ? progressFlags.DismissedCompletionModal : true;
        this.wizardComplete = progressFlags ? progressFlags.WizardComplete : true;
    }
}
export default ProgressFlags;
