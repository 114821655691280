import React, { Fragment, useCallback, useEffect, useState, useContext } from 'react';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import BreadCrumbDisplay from '../../common/BreadCrumbDisplay';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import FacebookService from '../../../services/FacebookService';
import LoadingSpinner from '../../common/LoadingSpinner';
import DiaryContext from '../../../contexts/DiaryContext';
import SettingsHelper from '../../../helpers/SettingsHelper';
import infoBarType from '../../../enums/infoBarType';
import InfoBars from '../../common/InfoBars';
import FacebookNoMicrositeModal from './FacebookNoMicrositeModal';
import useQueryParam from '../../../hooks/useQueryParam';
import SettingsService from '../../../services/SettingsService';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import useTrackPage from '../../../hooks/useTrackPage';

let _ID = 0;
function FacebookSettingsPage(props) {
    let fbRedirectSuccess = useQueryParam('success');
    let fbRedirectError = useQueryParam('error');
    const { intl } = props;
    const diaryContext = useContext(DiaryContext);
    const [loading, setLoading] = useState(true);
    const [facebookLoginUrl, setFacebookLoginUrl] = useState(null);
    const [isEnabled, setIsEnabled] = useState(false);
    const [infoBars, setInfoBars] = useState([]);
    const [facebookModalOpen, setFacebookModalOpen] = useState(false);
    const [hasMicrosite, setHasMicrosite] = useState(false);
    const [isHideOnPortal, setIsHideOnPortal] = useState(false);

    useTrackPage('Facebook Integration Page');

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };
    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const getFacebookIntegrationSettings = useCallback(
        (redirectEnabled) => {
            FacebookService.getFacebookSettings(
                diaryContext.deploymentId,
                diaryContext.restaurantId,
                window.location.href + '?success=true'
            )
                .then((data) => {
                    let fbIsEnabled = redirectEnabled ? true : data.IsEnabled;
                    setFacebookLoginUrl(data.LoginUrl);
                    setIsEnabled(fbIsEnabled);
                    setHasMicrosite(!isHideOnPortal);
                    if (fbIsEnabled && redirectEnabled) {
                        addSuccessBar(intl.formatMessage({ id: 'Settings.FacebookIntegrationEnabled' }));
                    }
                    if (fbRedirectError) {
                        addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
                    }

                    fbIsEnabled
                        ? AnalyticsHelper.trackPage('Web FBIntegration (Currently Enabled)')
                        : AnalyticsHelper.trackPage('Web FBIntegration (Currently Disabled)');
                })
                .catch(() => {
                    addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId, fbRedirectError, intl, isHideOnPortal]
    );

    useEffect(() => {
        SettingsService.getRestaurantSettings(diaryContext.deploymentId, diaryContext.restaurantId).then(
            (restaurantSettngs) => {
                setIsHideOnPortal(restaurantSettngs.HideOnPortal);
            }
        );
    }, [diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        let isRedirectSuccess = fbRedirectSuccess === 'true' && fbRedirectError === null;

        if (fbRedirectSuccess === 'true' && fbRedirectError) {
            FacebookService.logFacebookIntegrationError(
                diaryContext.deploymentId,
                diaryContext.restaurantId,
                fbRedirectError
            ).then(() => {
                getFacebookIntegrationSettings(false);
            });
        } else {
            getFacebookIntegrationSettings(isRedirectSuccess);
        }
    }, [
        getFacebookIntegrationSettings,
        fbRedirectError,
        fbRedirectSuccess,
        diaryContext.deploymentId,
        diaryContext.restaurantId,
    ]);

    function getBreadcrumbOptions() {
        return [new BreadCrumbOption(intl.formatMessage({ id: 'Settings.Title' }), '/Settings')];
    }

    function connectToFacebook(showSpinner) {
        AnalyticsHelper.trackClick('Web Connect Facebook Link');
        // Add a tiny delay to give the analytics request a chance to
        // complete. It is aborted if the browser navigates away before
        // it completes.
        setTimeout(() => {
            if (!hasMicrosite) {
                setFacebookModalOpen(true);
            } else {
                setLoading(true);
                facebookIntegration();
            }
        }, 1);
    }

    function disconnectFromFacebook() {
        setLoading(true);

        AnalyticsHelper.trackClick('Web Disconnect Facebook Link');

        FacebookService.disconnectFromFacebook(diaryContext.deploymentId, diaryContext.restaurantId)
            .then(() => {
                getFacebookIntegrationSettings(false);
            })
            .then(() => {
                addSuccessBar(intl.formatMessage({ id: 'Settings.FacebookIntegrationDisabled' }));
            })
            .catch(() => {
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
            });
    }

    function facebookIntegration() {
        window.location = facebookLoginUrl;
    }

    function facebookNoMicrosite() {
        window.location = 'settings/Venue?listing=MyListing';
    }

    function dishcultUrlMessage() {
        return (
            <FormattedMessage
                id="Settings.FacebookConnectMessage4"
                values={{
                    micrositeLink: (
                        <a href={SettingsHelper.getPortalUrl()} target="_blank" rel="noopener noreferrer">
                            {SettingsHelper.getPortalUrl()}
                        </a>
                    ),
                }}
            />
        );
    }

    function disconnectMessagewithRestaurantName() {
        return intl.formatMessage(
            {
                id: 'Settings.FacebookDisconnectMessage1',
            },
            { restaurantName: diaryContext.restaurantName }
        );
    }

    function getBody() {
        if (!isEnabled) {
            return (
                <Fragment>
                    <div className="content">
                        <div className="body">
                            <div className="header">
                                <h6>
                                    <FormattedMessage id="Settings.FacebookConnectMessage1" />
                                </h6>
                            </div>
                            <div className="body">
                                <p>
                                    <FormattedMessage id="Settings.FacebookConnectMessage2" />
                                </p>
                                <p>
                                    <FormattedMessage id="Settings.FacebookConnectMessage3" />
                                </p>
                                <p>{dishcultUrlMessage()}</p>
                                <p>
                                    <FormattedMessage id="Settings.FacebookConnectMessage5" />
                                </p>
                                <Button className="settings-button" color="primary" onClick={connectToFacebook}>
                                    <FormattedMessage id="Settings.FacebookConnectButtonLabel" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    {setFacebookModalOpen && (
                        <FacebookNoMicrositeModal
                            isModalOpen={facebookModalOpen}
                            setIsModalOpen={setFacebookModalOpen}
                            facebookNoMicrosite={facebookNoMicrosite}
                            restaurantName={diaryContext.restaurantName}
                        />
                    )}
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <div className="content">
                        <div className="body">
                            <div className="header">
                                <h6>{disconnectMessagewithRestaurantName()}</h6>
                            </div>
                            <div className="body">
                                <p>
                                    <FormattedMessage id="Settings.FacebookDisconnectMessage2" />
                                </p>
                                <form>
                                    <Button
                                        className="btn-outline-primary"
                                        color="primary"
                                        onClick={disconnectFromFacebook}
                                    >
                                        <FormattedMessage id="Settings.FacebookDisconnectButton" />
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <div>
                <BreadCrumbDisplay
                    breadcrumbs={getBreadcrumbOptions()}
                    activePageTitle={intl.formatMessage({
                        id: 'Settings.ManageFacebookTitleText',
                    })}
                />
                <h2>
                    <FormattedMessage id="Settings.FacebookConnectTitle" />
                </h2>
                {loading && <LoadingSpinner></LoadingSpinner>}
                {!loading && <div className="bg-white integration-container">{getBody()}</div>}
            </div>
        </Fragment>
    );
}

export default injectIntl(FacebookSettingsPage);
