import CloseOut from './CloseOut';

class CloseOutRequest {
    constructor(closeouts) {
        this.closeOuts = closeouts ? this.getCloseOut(closeouts) : [];
    }

    getCloseOut(closeouts) {
        return closeouts.map((c) => {
            return new CloseOut(c);
        });
    }

    convertItemsToJson() {
        return this.closeOuts.map((c) => {
            return c.toJson();
        });
    }

    toJson() {
        return {
            closeOuts: this.convertItemsToJson(),
        };
    }
}

export default CloseOutRequest;
