import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MenuService from '../../../services/MenuService';
import DiaryContext from '../../../contexts/DiaryContext';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup } from 'reactstrap';

function CopyMenuModal(props) {
    const intl = useIntl();
    const [isProcessing, setIsProcessing] = useState(false);
    const [newMenuTitle, setNewMenuTitle] = useState('');
    const diaryContext = useContext(DiaryContext);

    function getCloseButton() {
        return (
            <button className="close" onClick={() => props.setMenuToCopyId(null)}>
                &times;
            </button>
        );
    }

    function getDto() {
        return {
            ProviderId: diaryContext.restaurantId,
            MenuToCopyId: props.menuToCopyId,
            MenuTitle: newMenuTitle,
        };
    }

    function copyMenu() {
        setIsProcessing(true);
        MenuService.copyMenu(diaryContext.deploymentId, diaryContext.restaurantId, getDto())
            .then((response) => {
                props.setMenuToCopyId(null);
                props.addSuccessBar(
                    intl.formatMessage(
                        {
                            id: 'Settings.MenuCreated',
                        },
                        { menuName: `'${newMenuTitle}'` }
                    )
                );
                props.addCopiedMenu(response);
            })
            .catch(() => {
                props.addErrorBar(
                    intl.formatMessage(
                        {
                            id: 'Settings.ErrorMenuCreating',
                        },
                        { menuName: `'${newMenuTitle}'` }
                    )
                );
            })
            .finally(() => {
                setIsProcessing(false);
                setNewMenuTitle('');
            });
    }

    return (
        <Modal zIndex={1072} size="lg" centered isOpen={props.isModalOpen} className="modal">
            <ModalHeader close={getCloseButton()}>
                <FormattedMessage id="Settings.CopyMenu" />
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
                <FormGroup>
                    <Label for="menuTitle">
                        <FormattedMessage id="Settings.NewMenuTitle" />
                    </Label>
                    <Input
                        name="menuTitle"
                        type="text"
                        value={newMenuTitle}
                        onChange={(event) => {
                            setNewMenuTitle(event.target.value);
                        }}
                        maxLength="100"
                        autoFocus
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button
                        className="mr-2"
                        outline
                        color="primary"
                        onClick={() => props.setMenuToCopyId(null)}
                        disabled={isProcessing}
                    >
                        <FormattedMessage id="Common.Close" />
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            copyMenu();
                        }}
                        disabled={!newMenuTitle || isProcessing}
                    >
                        <FormattedMessage id={isProcessing ? 'Settings.Copying' : 'Settings.CopyMenu'} />
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

CopyMenuModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setMenuToCopyId: PropTypes.func.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    addCopiedMenu: PropTypes.func.isRequired,
    menuToCopyId: PropTypes.number,
};

export default CopyMenuModal;
