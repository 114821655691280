import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BookingOverlayContent from './BookingOverlayContent';
import NewBookingOverlayContent from './NewBookingOverlayContent';
import BookingFilterOverlayContent from './BookingFilterOverlayContent';
import overlayType from '../../enums/overlayType';

function OverlayContent(props) {
    function getOverlayContent() {
        if (props.overlayContent) {
            switch (props.overlayContent.overlayType) {
                case overlayType.booking:
                    const bookingReasons = props.getBookingReasonNames(props.overlayContent.booking.bookingReasonIds);
                    const tableLabels = props.getTableLabelData(props.overlayContent.booking.tableLabelIds);
                    return (
                        <BookingOverlayContent
                            overlayContent={props.overlayContent}
                            bookingReasons={bookingReasons}
                            tableLabels={tableLabels}
                            updateArrivalStatus={props.updateArrivalStatus}
                            closeBooking={props.closeBooking}
                            openCancellationModalWithBookingId={props.openCancellationModalWithBookingId}
                            toggleReconfirmedStatus={props.toggleReconfirmedStatus}
                            bookingStatusControlOpen={props.bookingStatusControlOpen}
                            toggleBookingStatusTooltip={props.toggleBookingStatusTooltip}
                            toggleShowCustomerOverlay={props.toggleShowCustomerOverlay}
                            showCustomerOverlay={props.showCustomerOverlay}
                            isEditingCustomer={props.isEditingCustomer}
                            countryCodes={props.countryCodes}
                            countryCode={props.countryCode}
                            addNewCustomer={props.addNewCustomer}
                            updateCustomer={props.updateCustomer}
                            customer={props.customer}
                            isMakingRequest={props.isMakingRequest}
                            rebookBooking={props.rebookBooking}
                            replaceCustomer={props.replaceCustomer}
                            closeBookingStatusControl={props.closeBookingStatusControl}
                            customerSearchTerm={props.customerSearchTerm}
                            sendMenuNotification={props.sendMenuNotification}
                            menus={props.menus}
                            openMenuChangeModal={props.openMenuChangeModal}
                        />
                    );
                case overlayType.newBooking:
                    const bookingReasonOptions = props.bookingReasons.map((reason) => ({
                        text: reason.name,
                        value: reason.id,
                    }));
                    return (
                        <NewBookingOverlayContent
                            closeOverlay={props.closeOverlay}
                            postBookingCreatedCallback={props.postBookingCreatedCallback}
                            bookingReasonOptions={bookingReasonOptions}
                            toggleShowCustomerOverlay={props.toggleShowCustomerOverlay}
                            showCustomerOverlay={props.showCustomerOverlay}
                            isEditingCustomer={props.isEditingCustomer}
                            countryCodes={props.countryCodes}
                            countryCode={props.countryCode}
                            isMakingRequest={props.isMakingRequest}
                            addNewCustomer={props.addNewCustomer}
                            updateCustomer={props.updateCustomer}
                            replaceCustomer={props.replaceCustomer}
                            isEditingBooking={props.isEditingBooking}
                            booking={props.overlayContent.booking}
                            updateBooking={props.updateBooking}
                            addSuccessBar={props.addSuccessBar}
                            tableLabels={props.tableLabels}
                            diaryDate={props.diaryDate}
                            customerSearchTerm={props.customerSearchTerm}
                            bookings={props.bookings}
                            showListingsFooter={props.showListingsFooter}
                        />
                    );
                case overlayType.filter:
                    return (
                        <BookingFilterOverlayContent
                            selectedFilter={props.selectedFilter}
                            onSelectedBookingFilterChange={props.onSelectedBookingFilterChange}
                            closeOverlay={props.closeOverlay}
                        />
                    );
                default:
                    return null;
            }
        }
    }
    return <Fragment>{getOverlayContent()}</Fragment>;
}

OverlayContent.propTypes = {
    getBookingReasonNames: PropTypes.func,
    overlayContent: PropTypes.object,
    updateArrivalStatus: PropTypes.func,
    openCancellationModalWithBookingId: PropTypes.func,
    openMenuChangeModal: PropTypes.func,
    closeBooking: PropTypes.func,
    toggleReconfirmedStatus: PropTypes.func,
    bookingStatusControlOpen: PropTypes.bool,
    toggleBookingStatusTooltip: PropTypes.func,
    closeOverlay: PropTypes.func,
    postBookingCreatedCallback: PropTypes.func,
    rebookBooking: PropTypes.func,
    bookingReasons: PropTypes.array,
    closeBookingStatusControl: PropTypes.func,
    getTableLabelData: PropTypes.func,
    tableLabels: PropTypes.array,
    diaryDate: PropTypes.object,
    customerSearchTerm: PropTypes.string,
    sendMenuNotification: PropTypes.func,
    bookings: PropTypes.array,
    menus: PropTypes.array,
    showListingsFooter: PropTypes.bool,
};

export default OverlayContent;
