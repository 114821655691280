import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';
import useScreenSize from '../../hooks/useScreenSize';
import SuccessIconWrapper from './SuccessIconWrapper';
import TextWithIcon from './TextWithIcon';
import ProgressPage from './ProgressPage';
import InfoBars from './InfoBars';

function WizardPage(props) {
    const { isTabletView } = useScreenSize();

    function getIcon(index) {
        if (props.progressIndex > index) {
            return <SuccessIconWrapper isCompleted />;
        }
        if (props.progressIndex === index) {
            return <SuccessIconWrapper isCompleted={false} />;
        }
        if (props.progressIndex < index) {
            return <SuccessIconWrapper isCompleted={false} notStarted />;
        }
    }

    function getTabLabels() {
        const labels = [];
        props.tabLabels.forEach((label, index) => {
            let tabStyles = 'tab text-uppercase bold-text';
            if (props.progressIndex < index) {
                tabStyles = `grayed-out ${tabStyles}`;
            }
            const labelElement = (
                <div onClick={() => props.onTabChanged(label.key)} key={label.key} className={tabStyles}>
                    <TextWithIcon text={label.name} icon={getIcon(index)} />
                </div>
            );
            labels.push(labelElement);
        });
        return labels;
    }

    function getTabbedContent() {
        if (props.isLoading || !props.currentTabKey) {
            return <LoadingSpinner />;
        }
        return props.tabs;
    }

    function getPageContent() {
        if (isTabletView) {
            return (
                <div className="flex-container-inline mobile mt-3">
                    <div>
                        <div className="horizontal-tabs">{getTabLabels()}</div>
                    </div>
                    <div className="tabbed-content">{getTabbedContent()}</div>
                </div>
            );
        } else {
            return (
                <div className="flex-container-inline mt-3 row">
                    <div className="col-md-3 verticle-tabs">{getTabLabels()}</div>
                    <div className="col-md-9 tabbed-content">{getTabbedContent()}</div>
                </div>
            );
        }
    }

    return (
        <Fragment>
            <InfoBars infoBars={props.infoBars} setInfoBars={props.setInfoBars} />

            <ProgressPage pageTitle={props.pageTitle} progressBar={props.progressBar}>
                {getPageContent()}
            </ProgressPage>
        </Fragment>
    );
}

WizardPage.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    tabLabels: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
        })
    ).isRequired,
    currentTabKey: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.element).isRequired,
    onTabChanged: PropTypes.func.isRequired,
    setInfoBars: PropTypes.func,
    infoBars: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
            message: PropTypes.string,
        })
    ),
    isLoading: PropTypes.bool,
    progressIndex: PropTypes.number.isRequired,
    progressBar: PropTypes.element,
};

export default WizardPage;
