import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PhoneIcon } from '../.././content/icons/Icon-Phone.svg';
import { ReactComponent as EmailIcon } from '../.././content/icons/Icon-Email.svg';
import { ReactComponent as KebabIcon } from '../.././content/icons/kebab.svg';
import { ReactComponent as ChevronLeft } from '../../content/icons/chevron-left.svg';
import { ReactComponent as DcRewardsBadge } from '../../content/images/DC-rewards.svg';
import { Fragment } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import CustomerTypeahead from './CustomerTypeahead';
import { FormattedMessage } from 'react-intl';
import DiaryContext from '../../contexts/DiaryContext';
import loyaltyStatus from '../../enums/loyaltyStatus';

function CustomerCard(props) {
    const [dropdownOpen, setOpen] = useState(false);
    const [isReplacingCustomer, setIsReplacingCustomer] = useState(false);
    const toggleKebab = () => setOpen(!dropdownOpen);
    const toggleReplaceCustomer = () => setIsReplacingCustomer(!isReplacingCustomer);
    const diaryContext = useContext(DiaryContext);

    function hasNoCustomer() {
        return !props.customer || props.customer.id === 0;
    }

    function getPhoneInformation() {
        const phoneInfo = [];
        if (props.customer.mobileNumber) {
            phoneInfo.push(props.customer.mobileNumber);
        }
        if (props.customer.phoneNumber) {
            phoneInfo.push(props.customer.phoneNumber);
        }
        return phoneInfo.join(' / ');
    }

    function replaceCustomer(customerId) {
        const callback = isReplacingCustomer ? toggleReplaceCustomer : null;
        return props.replaceCustomer(customerId, callback);
    }

    function searchOrDetails() {
        if (props.isReadOnly && hasNoCustomer()) {
            //it must be a walk in
            return (
                <div className="customer-card-wrapper">
                    <div className="d-flex">
                        <h5>{<FormattedMessage id="Bookings.WalkIn" />}</h5>
                    </div>
                </div>
            );
        }
        if (isReplacingCustomer && !hasNoCustomer()) {
            return (
                <div className="customer-card-wrapper search" data-testid="customer-replace-search">
                    <div className="back-button">
                        <Button onClick={toggleReplaceCustomer}>
                            <ChevronLeft /> <FormattedMessage id="Common.Back" />
                        </Button>
                    </div>
                    <CustomerTypeahead
                        deploymentId={diaryContext.deploymentId}
                        restaurantId={diaryContext.restaurantId}
                        typeaheadItemSelected={replaceCustomer}
                        addNewFunction={props.toggleShowCustomerOverlay}
                        registerValidation={props.registerValidation}
                        errors={props.errors}
                        includeValidation={props.includeValidation}
                    />
                </div>
            );
        }
        if (hasNoCustomer()) {
            return (
                <div className="customer-card-wrapper search">
                    <div className="pb-1" data-testid={'customer-label'}>
                        {props.isCustomerRequired ? (
                            <FormattedMessage id="Customer.CustomerRequired" />
                        ) : (
                            <FormattedMessage id="Customer.CustomerOptional" />
                        )}
                    </div>
                    <CustomerTypeahead
                        deploymentId={diaryContext.deploymentId}
                        restaurantId={diaryContext.restaurantId}
                        typeaheadItemSelected={replaceCustomer}
                        addNewFunction={props.toggleShowCustomerOverlay}
                        registerValidation={props.registerValidation}
                        errors={props.errors}
                        includeValidation={props.includeValidation}
                    />
                </div>
            );
        }

        return (
            <div className="customer-card-wrapper">
                <div className="d-flex">
                    <h5>{props.customer.displayFullName()}</h5>
                    {!props.isReadOnly && (
                        <ButtonDropdown
                            isOpen={dropdownOpen}
                            toggle={toggleKebab}
                            data-testid="customer-kebab-button"
                            className="ml-auto"
                        >
                            <DropdownToggle className="kebab">
                                <KebabIcon />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => props.toggleShowCustomerOverlay(true)}>
                                    <FormattedMessage id="Customer.EditCustomer" />
                                </DropdownItem>
                                <DropdownItem divider></DropdownItem>
                                <DropdownItem onClick={toggleReplaceCustomer} data-testid="customer-replace-button">
                                    <FormattedMessage id="Common.ReplaceCustomer" />
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    )}
                </div>
                {(props.customer.phoneNumber || props.customer.mobileNumber) && (
                    <div className="customer-row">
                        <PhoneIcon />
                        <span>{getPhoneInformation()}</span>
                    </div>
                )}
                {props.customer.email && (
                    <div className="customer-row">
                        <EmailIcon />
                        <span>{props.customer.email}</span>
                    </div>
                )}
                {diaryContext.loyaltyStatus === loyaltyStatus.active && props.customer.loyaltyId && (
                    <DcRewardsBadge className="dish-cult-rewards-badge" />
                )}
            </div>
        );
    }

    return <Fragment>{searchOrDetails()}</Fragment>;
}

CustomerCard.propTypes = {
    customer: PropTypes.object,
    isReadOnly: PropTypes.bool,
    toggleShowCustomerOverlay: PropTypes.func,
    replaceCustomer: PropTypes.func,
    registerValidation: PropTypes.func,
    includeValidation: PropTypes.bool,
    errors: PropTypes.object,
};

export default CustomerCard;
