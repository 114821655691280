import React from 'react';
import PropTypes from 'prop-types';
import Section from './Section';
import TextWithIcon from './TextWithIcon';
import SuccessIconWrapper from './SuccessIconWrapper';
import { useIntl } from 'react-intl';
import addonWizardStatus from '../../enums/addonWizardStatus';

function AddonWizardBasePage(props) {
    const intl = useIntl();

    function getIcon(isComplete) {
        return <SuccessIconWrapper isCompleted={isComplete} />;
    }

    function isComplete(key) {
        switch (props.status) {
            case addonWizardStatus.Create:
                return false;
            case addonWizardStatus.Review:
                if (key === addonWizardStatus.Create) return true;
                return false;
            case addonWizardStatus.Live:
                return true;
            default:
                return false;
        }
    }

    function getTextClass(key) {
        if (isComplete(key) || props.status === key) {
            return 'font-weight-bold';
        } else {
            return 'inactive';
        }
    }

    const headerChildren = (
        <div className="d-flex rd-progress-bar m-0 mr-3">
            <div>
                <TextWithIcon
                    text={props.isMobileView ? '' : intl.formatMessage({ id: 'Common.Create' })}
                    icon={getIcon(isComplete(addonWizardStatus.Create))}
                    textClass={'font-weight-bold'}
                />
            </div>
            <div className={props.isMobileView ? 'horizontal-splitter mob' : 'horizontal-splitter'}></div>
            <div>
                <TextWithIcon
                    text={props.isMobileView ? '' : intl.formatMessage({ id: 'Common.Review' })}
                    icon={getIcon(isComplete(addonWizardStatus.Review))}
                    textClass={getTextClass(addonWizardStatus.Review)}
                />
            </div>
            <div className={props.isMobileView ? 'horizontal-splitter mob' : 'horizontal-splitter'}></div>
            <div>
                <TextWithIcon
                    text={props.isMobileView ? '' : intl.formatMessage({ id: 'Common.GoLive' })}
                    icon={getIcon(isComplete(addonWizardStatus.Live))}
                    textClass={getTextClass(addonWizardStatus.Live)}
                />
            </div>
        </div>
    );

    return (
        <Section sectionTitle={props.title} headerChildren={headerChildren}>
            <div className="addon-content">{props.children}</div>
        </Section>
    );
}

AddonWizardBasePage.propTypes = {
    title: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.keys(addonWizardStatus)).isRequired,
};

export default AddonWizardBasePage;
