import React from 'react';
import PropTypes from 'prop-types';
import constants from '../.././enums/constants';
import { useIntl } from 'react-intl';

function FreshdeskTicketRaiser(props) {
    const intl = useIntl();

    const freshdeskUrl =
        constants.freshdeskUrl +
        `${props.userName}&helpdesk_ticket[custom_field]` +
        `[${constants.freshDeskAccountNumber}]=${props.restaurantId}` +
        `&disable[custom_field][${constants.freshDeskAccountNumber}]=true`;
    return (
        <div>
            <iframe
                title={intl.formatMessage({ id: 'HelpAndSupport.FeedbackFormTitle' })}
                className="freshwidget-embedded-form"
                id="freshwidget-embedded-form"
                src={freshdeskUrl}
                scrolling="no"
                height="500px"
                width="100%"
                frameBorder="0"
            ></iframe>
        </div>
    );
}

FreshdeskTicketRaiser.propTypes = {
    userName: PropTypes.string.isRequired,
    restaurantId: PropTypes.number.isRequired,
};

export default FreshdeskTicketRaiser;
