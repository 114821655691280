class TableHeadingOption {
    constructor(sortable, heading, sortOrder, toolTip) {
        this.sortable = sortable;
        this.sortOrder = sortOrder;
        this.heading = heading;
        this.toolTip = toolTip ? toolTip : null;
    }
}

export default TableHeadingOption;
