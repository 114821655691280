import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormGroup, Label, Input } from 'reactstrap';
import Dropdown from './Dropdown';
import ValidationInput from './ValidationInput';

function PhoneNumberInput(props) {
    function onCountryCodeChange(countryCode) {
        props.onCountryCodeChange(countryCode);
    }

    function onInputChange(number) {
        props.onChange(number.target.value);
    }

    return (
        <Fragment>
            <Label>{props.label}</Label>
            <FormGroup>
                <InputGroup className="phone-number">
                    <Dropdown
                        options={props.optionGroups}
                        onValueChange={onCountryCodeChange}
                        selectedValue={props.selectedValue}
                        isSearchable
                        isCountryCodeDropdown
                        dropdownMenuClassName="child-full-width"
                    />
                    {props.includeValidation && (
                        <ValidationInput
                            name={props.name}
                            type="tel"
                            value={props.inputValue}
                            onChange={(e) => props.onChange(e)}
                            innerRef={props.innerRef}
                            errors={props.errors}
                            className={props.inputClassName}
                            placeholder={props.placeholder}
                        />
                    )}

                    {!props.includeValidation && (
                        <Input
                            type="tel"
                            maxLength={'30'}
                            placeholder={props.placeholder}
                            onChange={onInputChange}
                            value={props.inputValue}
                        />
                    )}
                </InputGroup>
            </FormGroup>
        </Fragment>
    );
}

PhoneNumberInput.propTypes = {
    optionGroups: PropTypes.array,
    onCountryCodeChange: PropTypes.func,
    onChange: PropTypes.func,
    countryCodeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    includeValidation: PropTypes.bool,
    innerRef: PropTypes.func,
    errors: PropTypes.object,
    name: PropTypes.string,
    inputClassName: PropTypes.string,
    placeholder: PropTypes.string,
};

export default PhoneNumberInput;
