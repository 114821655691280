import React, { useState, useContext } from 'react';
import TableLabel from '../../../domainObjects/TableLabel';
import TableLabelCreationPanel from './TableLabelCreationPanel';
import TableLabelEntryWrapper from './TableLabelEntryWrapper';
import TableLabelsService from '../../../services/TableLabelService';
import DiaryContext from '../../../contexts/DiaryContext';
import { useIntl } from 'react-intl';
import ListBuilder from '../../common/ListBuilder';
import DeletionModal from '../../common/DeletionModal';
import PropTypes from 'prop-types';

function TabelLabelsListBuilder(props) {
    const intl = useIntl();
    const [isAddingNewTable, setIsAddingNewTable] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tableLabelToDelete, setTableLabelToDelete] = useState(null);
    const diaryContext = useContext(DiaryContext);

    const toggleAddNewTable = (addAnother) => {
        addAnother ? setIsAddingNewTable(addAnother) : setIsAddingNewTable(!isAddingNewTable);
    };

    function deleteTableLabelEntry() {
        TableLabelsService.deleteTableLabel(diaryContext.deploymentId, diaryContext.restaurantId, tableLabelToDelete.id)
            .then(() => {
                props.updateTableLabels(
                    props.tableLabels.filter((label) => label.uniqueIdentifier !== tableLabelToDelete.uniqueIdentifier)
                );
                props.addSuccessBar(
                    intl.formatMessage({
                        id: 'Common.ItemRemovedInfoBarSuccessMessage',
                    })
                );
            })
            .catch(() => {
                props.addErrorBar();
            })
            .finally(() => {
                setIsModalOpen(false);
            });
    }

    function saveTableLabel(addAnother, tableLabel) {
        TableLabelsService.createTableLabel(diaryContext.deploymentId, diaryContext.restaurantId, tableLabel)
            .then((response) => {
                props.updateTableLabels([...props.tableLabels, new TableLabel(response)]);
                toggleAddNewTable(addAnother);
                props.addSuccessBar(
                    intl.formatMessage({
                        id: 'Common.ItemAddedInfoBarSuccessMessage',
                    })
                );
            })
            .catch(() => {
                props.addErrorBar();
            });
    }

    function updateTableLabelEntry(tableLabel, callback) {
        TableLabelsService.updateTableLabel(diaryContext.deploymentId, diaryContext.restaurantId, tableLabel)
            .then((result) => {
                let tableLabelsCopy = [...props.tableLabels];
                let index = tableLabelsCopy.findIndex((t) => t.id === result.Id);
                tableLabelsCopy[index] = new TableLabel(result);
                props.updateTableLabels(tableLabelsCopy);
                props.addSuccessBar(intl.formatMessage({ id: 'Common.ItemUpdatedInfoBarSuccessMessage' }));
                if (callback) {
                    callback();
                }
            })
            .catch(() => {
                props.addErrorBar();
            });
    }

    function openDeletionModal(tableLabel) {
        setTableLabelToDelete(tableLabel);
        setIsModalOpen(true);
    }

    function getDeletionModalTitle() {
        return intl.formatMessage(
            {
                id: 'Settings.DeleteTableModalTitle',
            },
            { tableName: `${tableLabelToDelete ? tableLabelToDelete.name : ''}` }
        );
    }

    function getPageContent() {
        return (
            <div data-testid="table-config-panel">
                {props.tableLabels.map((label) => {
                    return (
                        <TableLabelEntryWrapper
                            key={label.uniqueIdentifier}
                            tableLabel={label}
                            deleteTableLabelEntry={deleteTableLabelEntry}
                            addSuccessBar={props.addSuccessBar}
                            addErrorBar={props.addErrorBar}
                            existingTableLabels={props.tableLabels}
                            updateTableLabelEntry={updateTableLabelEntry}
                            openDeletionModal={openDeletionModal}
                        />
                    );
                })}

                {isAddingNewTable && (
                    <TableLabelCreationPanel
                        tableLabel={new TableLabel()}
                        toggleAddNewTable={toggleAddNewTable}
                        saveTableLabel={saveTableLabel}
                        existingTableLabels={props.tableLabels}
                    />
                )}
                <DeletionModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    deleteItem={deleteTableLabelEntry}
                    title={getDeletionModalTitle()}
                    bodyText={intl.formatMessage({ id: 'Settings.DeleteTableWarningMessage' })}
                />
            </div>
        );
    }

    return (
        <div className="table-label-container">
            <ListBuilder
                isAddingNewItem={isAddingNewTable}
                toggleAddNewItem={toggleAddNewTable}
                addNewItemButtonText={intl.formatMessage({ id: 'Settings.AddNewTable' })}
                content={getPageContent()}
                buttonTestId="add-table-label"
            />
        </div>
    );
}

TabelLabelsListBuilder.propTypes = {
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    tableLabels: PropTypes.array.isRequired,
    updateTableLabels: PropTypes.func.isRequired,
};

export default TabelLabelsListBuilder;
