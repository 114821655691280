import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { ReactComponent as WarningIcon } from '../../content/icons/Icon-Alert.svg';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';

function WarningLabel(props) {
    function getIcon() {
        if (props.infoIcon) {
            return <InfoIcon />;
        }
        return <WarningIcon />;
    }

    return (
        <Label className="warning-label orange">
            <div className="warning-icon orange">{getIcon()}</div>
            {props.label}
        </Label>
    );
}

WarningLabel.propTypes = {
    label: PropTypes.string.isRequired,
};

export default WarningLabel;
