import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CloseablePanel from '../common/CloseablePanel';
import VoucherCreationPanel from './VoucherCreationPanel';
import useValidation from '../../hooks/useValidation';
import Voucher from '../../domainObjects/Voucher';
import VoucherHelper from '../../helpers/VoucherHelper';
import { CustomInput } from 'reactstrap';
import TextWithIcon from '../common/TextWithIcon';
import { ReactComponent as SuccessIcon } from '../../content/icons/Icon-Success.svg';
import { ReactComponent as WarningIcon } from '../../content/icons/Icon-Alert.svg';
import useScreenSize from '../../hooks/useScreenSize';

function VoucherEditPanel(props) {
    const intl = useIntl();
    const [voucherBeingUpdated, setVoucherBeingUpdated] = useState(getVoucherCopy());
    const [isSaving, setIsSaving] = useState(false);
    const formValidation = useValidation();

    const { isMobileView } = useScreenSize();

    const voucherSalesStatusClass = () => {
        let className = voucherBeingUpdated.status
            ? 'vouchers-status-sales-site-message'
            : 'vouchers-status-sales-site-message inactive';
        if (props.isMobileView) className += ' mob';

        return className;
    };

    function getVoucherCopy() {
        return new Voucher(VoucherHelper.toJson(props.voucher));
    }

    function saveVoucher() {
        setIsSaving(true);

        if (props.isEditing) {
            props.saveVoucher(voucherBeingUpdated, props.toggleEditing);
        } else {
            props.saveVoucher(voucherBeingUpdated, props.toggleAddNewVoucher);
        }
    }

    function onValueChange(key, value) {
        setVoucherBeingUpdated({ ...voucherBeingUpdated, [key]: value });
    }

    function hasVoucherUpdated() {
        return (
            JSON.stringify({
                title: props.voucher.title,
                cost: props.voucher.cost,
                monthsValid: props.voucher.monthsValid,
                maxPerInterval: props.voucher.maximumQuantity,
                description: props.voucher.description,
                terms: props.voucher.termsAndConditions,
                status: props.voucher.status,
            }) !==
            JSON.stringify({
                title: voucherBeingUpdated.title,
                cost: voucherBeingUpdated.cost,
                monthsValid: voucherBeingUpdated.monthsValid,
                maxPerInterval: voucherBeingUpdated.maximumQuantity,
                description: voucherBeingUpdated.description,
                terms: voucherBeingUpdated.termsAndConditions,
                status: voucherBeingUpdated.status,
            })
        );
    }

    function getFormBody() {
        return (
            <Fragment>
                <div className="grey-closable-panel-body">
                    <VoucherCreationPanel
                        minValidityPeriod={props.minValidityPeriod}
                        voucher={voucherBeingUpdated}
                        formValidation={formValidation}
                        onValueChange={onValueChange}
                        isEditing={props.isEditing}
                        setVoucherBeingUpdated={setVoucherBeingUpdated}
                    />
                    {isMobileView && <Fragment>{footerAdditionalContent()}</Fragment>}
                </div>
            </Fragment>
        );
    }

    function footerAdditionalContent() {
        return (
            <div className={isMobileView ? 'vouchers-radio-buttons-container mob' : 'vouchers-radio-buttons-container'}>
                <div className={isMobileView ? 'voucher-radio-buttons mob' : 'voucher-radio-buttons'}>
                    <CustomInput
                        checked={voucherBeingUpdated.status}
                        onChange={() => onValueChange('status', true)}
                        inline
                        type="radio"
                        id={'active ' + props.voucher.uniqueIdentifier}
                        name={'customRadio' + props.voucher.uniqueIdentifier}
                        label={intl.formatMessage({
                            id: 'Common.Active',
                        })}
                        disabled={false}
                    />
                    <CustomInput
                        checked={!voucherBeingUpdated.status}
                        onChange={() => onValueChange('status', false)}
                        inline
                        type="radio"
                        id={'inactive ' + props.voucher.uniqueIdentifier}
                        name={'customRadio' + props.voucher.uniqueIdentifier}
                        label={intl.formatMessage({
                            id: 'Common.InActive',
                        })}
                        disabled={false}
                    />
                </div>

                <div className={voucherSalesStatusClass()}>
                    {voucherBeingUpdated.status && (
                        <TextWithIcon
                            text={intl.formatMessage({ id: 'Vouchers.VoucherActiveStatusSalesSiteMessage' })}
                            icon={<SuccessIcon />}
                            iconClass="progress-icon-container success-icon"
                        />
                    )}

                    {!voucherBeingUpdated.status && (
                        <TextWithIcon
                            text={intl.formatMessage({ id: 'Vouchers.VoucherInActiveStatusSalesSiteMessage' })}
                            icon={<WarningIcon />}
                            iconClass={'validation-message'}
                        />
                    )}
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {props.isInitialSetup && (
                <div className={isMobileView ? 'vouchers-content mob' : 'vouchers-content'}>
                    <VoucherCreationPanel
                        minValidityPeriod={props.minValidityPeriod}
                        voucher={voucherBeingUpdated}
                        formValidation={formValidation}
                        onValueChange={onValueChange}
                        isInitialSetup={props.isInitialSetup}
                        saveVoucher={saveVoucher}
                        isSaving={isSaving}
                        setVoucherBeingUpdated={setVoucherBeingUpdated}
                        isEditing={props.isEditing}
                        toggleAddNewVoucher={props.toggleAddNewVoucher}
                        hasVoucherUpdated={hasVoucherUpdated}
                    />
                </div>
            )}

            {!props.isInitialSetup && (
                <CloseablePanel
                    formBody={getFormBody()}
                    toggleEditing={props.toggleEditing}
                    toggleAddNewItem={props.toggleAddNewVoucher}
                    isEditing={props.isEditing}
                    buttonDisabled={formValidation.submitDisabled || !hasVoucherUpdated()}
                    submit={formValidation.submit}
                    submitDisabled={formValidation.submitDisabled}
                    errorCount={formValidation.errorCount}
                    saveItem={saveVoucher}
                    addNewItemText={intl.formatMessage({ id: 'Vouchers.CreateNewVoucher' })}
                    editItemText={intl.formatMessage({ id: 'Vouchers.EditVoucher' })}
                    isSaving={isSaving}
                    footerAdditionalContent={isMobileView ? null : footerAdditionalContent()}
                    saveButtonText={
                        voucherBeingUpdated.status ? intl.formatMessage({ id: 'Common.SaveAndPublish' }) : null
                    }
                />
            )}
        </Fragment>
    );
}

VoucherEditPanel.propTypes = {
    voucher: PropTypes.object.isRequired,
    saveVoucher: PropTypes.func.isRequired,
    toggleEditing: PropTypes.func,
    toggleAddNewVoucher: PropTypes.func,
    isInitialSetup: PropTypes.bool,
    isEditing: PropTypes.bool,
    minValidityPeriod: PropTypes.number,
};

export default VoucherEditPanel;
