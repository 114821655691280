class DressCode {
    constructor(dto) {
        this.value = dto && dto.Id ? dto.Id : '';
        this.text = dto && dto.DisplayName ? dto.DisplayName : '';
    }

    getDTO(value, text) {
        return {
            Id: value,
            DisplayName: text,
        };
    }
}

export default DressCode;
