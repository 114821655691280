import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DishCultLogo } from '../../../content/images/dishcult-logo.svg';
import { ReactComponent as ConnectToDiaryImage } from '../../../content/images/loyalty-connect-to-diary.svg';
import { ReactComponent as TiedToDinerAccountsImage } from '../../../content/images/loyalty-tied-to-diner-account.svg';
import { ReactComponent as BoostRevenueImage } from '../../../content/images/loyalty-boost-revenue.svg';
import { ReactComponent as GreenTick } from '../../../content/icons/Tick-green.svg';
import { useIntl } from 'react-intl';
import useScreenSize from '../../../hooks/useScreenSize';
import DiaryContext from '../../../contexts/DiaryContext';
import SessionContext from '../../../contexts/SessionContext';
import RDLModal from '../../common/RDLModal';
import venueSettingsTab from '../../../enums/Tabs/venueSettingsTab';
import useTrackPage from '../../../hooks/useTrackPage';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';

function LoyaltyPromoPage() {
    const intl = useIntl();
    const { isMobileView, isTabletView } = useScreenSize();
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const navigate = useNavigate();
    const [displayListingInactiveModal, setDisplayListingInactiveModal] = useState(false);

    const columnList = [
        {
            title: intl.formatMessage({ id: 'Settings.LoyaltyPageColumn1Title' }),
            icon: ConnectToDiaryImage,
            bulletpoints: [{ text: intl.formatMessage({ id: 'Settings.LoyaltyPageC1Bullet1' }) }],
        },
        {
            title: intl.formatMessage({ id: 'Settings.LoyaltyPageColumn2Title' }),
            icon: TiedToDinerAccountsImage,
            bulletpoints: [
                { text: intl.formatMessage({ id: 'Settings.LoyaltyPageC2Bullet1' }) },
                { text: intl.formatMessage({ id: 'Settings.LoyaltyPageC2Bullet2' }) },
            ],
        },
        {
            title: intl.formatMessage({ id: 'Settings.LoyaltyPageColumn3Title' }),
            icon: BoostRevenueImage,
            bulletpoints: [
                { text: intl.formatMessage({ id: 'Settings.LoyaltyPageC3Bullet1' }) },
                { text: intl.formatMessage({ id: 'Settings.LoyaltyPageC3Bullet2' }) },
            ],
        },
    ];

    const commonTrackingProperties = {
        providerId: diaryContext.providerId,
        userName: sessionContext.userName,
        userId: sessionContext.userId,
    };

    function startFreeClick() {
        AnalyticsHelper.trackClickWithProperties('Web dcRewards startFree', commonTrackingProperties);
        diaryContext.hideOnPortal ? setDisplayListingInactiveModal(true) : navigate('/Settings/Loyalty/Form');
    }

    function clickActivateDCListing() {
        AnalyticsHelper.trackClickWithProperties('Web dcListingActivate', commonTrackingProperties);
        navigate('/Settings/Venue?listing=' + venueSettingsTab.myListing);
    }

    function closeListingInactiveModal() {
        AnalyticsHelper.trackClickWithProperties('Web dcListing Cancel', commonTrackingProperties);
        setDisplayListingInactiveModal(false);
    }

    if (!diaryContext.isLoyaltyEnabled) {
        navigate('/');
    }

    useTrackPage('DCRewards Activation Page');

    return (
        <div className="loyalty-page">
            <div className="loyalty-page-container container">
                <div className={'header-banner' + (isMobileView ? ' mobile' : '')}></div>
                <div className="dishcult-logo">
                    <DishCultLogo />
                    <p className="dishcult-tagline footnote bold">
                        {intl.formatMessage({ id: 'Settings.LoyaltyPagePoweredByResDiary' })}
                    </p>
                </div>

                <div className="loyalty-page-header">
                    <div className={'loyalty-page-title-container' + (isMobileView || isTabletView ? ' mobile' : '')}>
                        <h3 className={'h3 bold' + (isMobileView ? ' mobile' : '')}>
                            {intl.formatMessage({ id: 'Settings.LoyaltyPageTitle' })}
                        </h3>
                        <p className="body">{intl.formatMessage({ id: 'Settings.LoyaltyPage2ndTitle' })}</p>
                    </div>
                    <button className="loyalty-page-button btn btn-primary" onClick={startFreeClick}>
                        {intl.formatMessage({ id: 'Settings.LoyaltyPageButtonText' })}
                    </button>
                    <p className="footnote">{intl.formatMessage({ id: 'Settings.LoyaltyPageAsterix' })}</p>
                </div>
                <div className={'loyalty-information' + (isMobileView || isTabletView ? ' mobile' : '')}>
                    {columnList.map((column, i) => {
                        return (
                            <div className={'information-column' + (isMobileView ? ' mobile' : '')} key={i}>
                                <column.icon />
                                <p className="column-header footnote bold">{column.title}</p>
                                <div className={'information-bullet-point-container' + (isTabletView ? ' tablet' : '')}>
                                    {column.bulletpoints.map((bulletpoint, j) => {
                                        return (
                                            <div className="information-bullet-point" key={j}>
                                                <GreenTick />
                                                <p className="small-body">{bulletpoint.text}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={'loyalty-terms-and-conditions-notice' + (isMobileView ? ' mobile' : '')}>
                    <p className="footnote bold">{intl.formatMessage({ id: 'Settings.LoyaltyPageTermsTitle' })}</p>
                    <p className="footnote">{intl.formatMessage({ id: 'Settings.LoyaltyPageFootNote' })}</p>
                </div>
                <RDLModal
                    isModalOpen={displayListingInactiveModal}
                    setIsModalOpen={closeListingInactiveModal}
                    title={intl.formatMessage({ id: 'Settings.DcListingInactiveModalHeader' })}
                    body={intl.formatMessage({ id: 'Settings.DcListingInactiveModalBody' })}
                    actionButtonText={intl.formatMessage({ id: 'Settings.ActivateYourDishCultListingLabel' })}
                    action={clickActivateDCListing}
                    backdrop
                    dismissActionButtonText={intl.formatMessage({ id: 'Common.Cancel' })}
                    wrapClassName={'listing-inactive-modal-wrapper'}
                />
            </div>
        </div>
    );
}

export default LoyaltyPromoPage;
