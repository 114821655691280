import React from 'react';
import usePollRequest from './usePollRequest';
import Booking from '../domainObjects/Booking';
import DiaryContext from '../contexts/DiaryContext';
import SettingsHelper from '../helpers/SettingsHelper';
import requestType from '../enums/requestType';

function useBookingsPollRequest(debouncedDateString) {
    const diaryContext = React.useContext(DiaryContext);
    const [state, update] = usePollRequest(
        `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${diaryContext.deploymentId}/${
            diaryContext.restaurantId
        }/BookingsV2/${debouncedDateString}`,
        30000
    );

    const newBookings = state.responseData ? state.responseData.Bookings.map((booking) => new Booking(booking)) : [];
    const newUnAllocatedBookings = state.responseData
        ? state.responseData.UnallocatedBookings.map((booking) => new Booking(booking, true))
        : [];
    const isFetching = state.isFetching;
    const isPollingRequest = state.requestType === requestType.poll;
    return { newBookings, isFetching, update, isPollingRequest, newUnAllocatedBookings };
}
export default useBookingsPollRequest;
