import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Dropdown from '../../common/Dropdown';
import React, { useEffect, useState } from 'react';
import voucherReportStatus from '../../../enums/voucherReportStatus';
import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import SearchInput from '../../common/SearchInput';
import useScreenSize from '../../../hooks/useScreenSize';

function VoucherReportHeader(props) {
    const intl = useIntl();
    const [voucherDropdownOptions, setVoucherDropdownOptions] = useState([]);
    const { isTabletView } = useScreenSize();

    useEffect(() => {
        let getVoucherDropdownOptions = [];
        getVoucherDropdownOptions.push(
            new Option(
                intl.formatMessage({
                    id: 'Vouchers.AllVouchers',
                }),
                '0'
            )
        );
        props.availableVouchers.forEach((voucher) => {
            getVoucherDropdownOptions.push(new Option(voucher.title, parseInt(voucher.id)));
        });
        setVoucherDropdownOptions(getVoucherDropdownOptions);
    }, [props.availableVouchers, intl]);

    function getVoucherStatusButtons() {
        const statusOptions = [
            {
                value: voucherReportStatus.Sold,
                text: intl.formatMessage(
                    { id: 'Vouchers.VoucherReportSoldCount' },
                    { soldCount: props.vouchersTotalSold }
                ),
            },
            {
                value: voucherReportStatus.NotRedeemed,
                text: intl.formatMessage(
                    { id: 'Vouchers.VoucherReportNotRedeemedCount' },
                    { notRedeemedCount: props.vouchersTotalNotRedeemed }
                ),
            },
            {
                value: voucherReportStatus.Redeemed,
                text: intl.formatMessage(
                    { id: 'Vouchers.VoucherReportRedeemedCount' },
                    { redeemedCount: props.vouchersTotalRedeemed }
                ),
            },
            {
                value: voucherReportStatus.Expired,
                text: intl.formatMessage(
                    { id: 'Vouchers.VoucherReportExpiredCount' },
                    { expiredCount: props.vouchersTotalExpired }
                ),
            },
        ];

        return statusOptions.map((option) => {
            return (
                <Button
                    key={option.value}
                    onClick={() => props.onVoucherStatusChange(option.value)}
                    className="flex-fill"
                    color={props.voucherStatusFilter === option.value ? 'dark' : 'light'}
                >
                    {option.text}
                </Button>
            );
        });
    }

    return (
        <div className={isTabletView ? 'voucher-report-header mob' : 'voucher-report-header'}>
            <div className="voucher-report-search-input">
                <SearchInput
                    onSearch={props.onSearchReference}
                    inputPlaceholderText={intl.formatMessage({
                        id: 'Vouchers.VoucherSearchPlaceholder',
                    })}
                    labelText={intl.formatMessage({
                        id: 'Vouchers.VoucherSearchLabel',
                    })}
                    searchWithoutButtonPress
                    minimumCharacterLengthForSearch={7}
                />
            </div>
            <div className="voucher-report-dropdown">
                <Dropdown
                    title={intl.formatMessage({
                        id: 'Vouchers.VoucherReportColumnVoucherName',
                    })}
                    onValueChange={props.onVoucherTitleChange}
                    options={voucherDropdownOptions}
                    selectedValue={props.selectedVoucherFilterId}
                    hideRemoveItemIcon={true}
                />
            </div>
            <div className="voucher-report-buttons">
                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                        <label>
                            {intl.formatMessage({
                                id: 'Common.Show',
                            })}
                        </label>
                    </div>
                </div>
                <ButtonGroup>{getVoucherStatusButtons()}</ButtonGroup>
            </div>
            <div className={!isTabletView ? 'ml-2 ml-auto align-self-end' : 'mt-2'}>
                <Button onClick={() => props.exportCsv()} disabled={props.isExporting} color="outline-primary">
                    <FormattedMessage id="Common.ExportCsv" />
                </Button>
            </div>
        </div>
    );
}

VoucherReportHeader.propTypes = {
    onVoucherStatusChange: PropTypes.func,
    voucherStatusFilter: PropTypes.number,
    availableVouchers: PropTypes.array,
    onVoucherTitleChange: PropTypes.func,
    vouchersTotalSold: PropTypes.number,
    vouchersTotalRedeemed: PropTypes.number,
    vouchersTotalNotRedeemed: PropTypes.number,
    vouchersTotalExpired: PropTypes.number,
    selectedVoucherFilterId: PropTypes.string,
    onSearchReference: PropTypes.func,
    exportCsv: PropTypes.func,
};

export default VoucherReportHeader;
