import React, { useState, Fragment, useContext } from 'react';
import { FormGroup, Form, Label } from 'reactstrap';
import AccountService from '../../services/AccountService';
import { ReactComponent as ResIcon } from '../.././content/images/rd-lite-logo.svg';
import infoBarType from '../../enums/infoBarType';
import InfoBars from '../../components/common/InfoBars';
import { useIntl, FormattedMessage } from 'react-intl';
import ValidationInput from '../common/ValidationInput';
import useValidation from '../../hooks/useValidation';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import useTrackPage from '../../hooks/useTrackPage';
import useScreenSize from '../../hooks/useScreenSize';
import SessionContext from '../../contexts/SessionContext';
import ValidationMessage from '../common/ValidationMessage';
import SettingsHelper from '../../helpers/SettingsHelper';

let _ID = 0;

function LoginForm(props) {
    const intl = useIntl();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [infoBars, setInfoBars] = useState([]);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const addErrorBar = (message) => {
        setIsLoggingIn(false);
        setInfoBars([...infoBars, { id: _ID++, type: infoBarType.error, message: message }]);
    };
    useTrackPage('Login');

    const { isMobileView } = useScreenSize();

    const sessionContext = useContext(SessionContext);

    const { register, errors, submitDisabled, submit } = useValidation();

    const { state } = useLocation();

    const navigate = useNavigate();

    function trackUserProperties(email) {
        const userProperties = { email: email };
        AnalyticsHelper.identifyUserWithProperties(userProperties);
    }

    const authenticateUser = async () => {
        setIsLoggingIn(true);
        const result = await AccountService.authenticateUser(username, password);
        if (result.data ? result.data.Status === 'Success' : result.status < 400) {
            trackUserProperties(username);
            await props.login({
                token: result.data.Token,
                tokenExpiryUtc: result.data.TokenExpiryUtc,
            });
            props.setLoading(true);
            navigate(state?.path || '/');
        } else if (result.data?.Status === 'PasswordResetRequired') {
            addErrorBar(
                intl.formatMessage({
                    id: 'Login.PasswordResetRequired',
                })
            );
        } else {
            addErrorBar(
                intl.formatMessage({
                    id: 'Login.EmailOrPasswordIncorrect',
                })
            );
        }
        setIsLoggingIn(false);
    };

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <div className="flex-container-center">
                <div className="login-image">
                    <ResIcon />
                </div>
                <div className={isMobileView ? 'login-form-container mobile' : 'login-form-container'}>
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            submit(async () => await authenticateUser(event));
                        }}
                    >
                        <FormGroup>
                            <div className="d-flex">
                                <Label for="username">
                                    <FormattedMessage id="Login.Email" />
                                </Label>
                            </div>
                            <ValidationInput
                                testId="login-form-username"
                                type="text"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e)}
                                innerRef={register({
                                    required: intl.formatMessage({ id: 'Login.EmailRequired' }),
                                })}
                                errors={errors}
                                autoFocus
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="d-flex">
                                <Label for="password">
                                    <FormattedMessage id="Login.Password" />
                                </Label>
                                <Link className="ml-auto" to="/ForgotPassword">
                                    <FormattedMessage id="Login.ForgotPasswordPrompt" />
                                </Link>
                            </div>
                            <ValidationInput
                                testId="login-form-password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e)}
                                innerRef={register({
                                    required: intl.formatMessage({ id: 'Login.PasswordRequired' }),
                                })}
                                errors={errors}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormattedMessage id="Login.CreateAccountMessage" />{' '}
                            <Link to="/CreateAccount">
                                <FormattedMessage id="Login.CreateAccountMessageLink" />
                            </Link>
                        </FormGroup>
                        <FormGroup>
                            <input
                                disabled={isLoggingIn || submitDisabled}
                                type="submit"
                                value={
                                    isLoggingIn
                                        ? intl.formatMessage({
                                              id: 'Login.LoggingIn',
                                          })
                                        : intl.formatMessage({
                                              id: 'Login.LogIn',
                                          })
                                }
                                className="login-button btn btn-primary"
                                data-testid="login-form-button"
                            />
                        </FormGroup>

                        {sessionContext.resdiaryUserAuthenticated && (
                            <div className="rdfull-user-validation-message">
                                <ValidationMessage
                                    message={
                                        <FormattedMessage
                                            id="Login.RDFullUserAuthenticatedValidationMessage"
                                            values={{
                                                loginSite: (
                                                    <a href={SettingsHelper.getLoginBaseUrl()}>
                                                        {SettingsHelper.getLoginBaseUrl()}
                                                    </a>
                                                ),
                                            }}
                                        />
                                    }
                                />
                            </div>
                        )}
                    </Form>
                </div>
            </div>
        </Fragment>
    );
}

export default LoginForm;
