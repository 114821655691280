import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button } from 'reactstrap';
import LoadingSpinner from '../../common/LoadingSpinner';
import infoBarType from '../../../enums/infoBarType';
import BreadcrumbPage from '../../common/BreadcrumbPage';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import MenuManagementMenu from '../../../domainObjects/MenuManagementMenu';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuService from '../../../services/MenuService';
import EposService from '../../../services/EposService';
import DiaryContext from '../../../contexts/DiaryContext';
import SyncEposDataModal from './SyncEposDataModal';
import MenuManagementRow from './MenuManagementRow';
import CopyMenuModal from './CopyMenuModal';
import DeletionModal from '../../common/DeletionModal';
import useScreenSize from '../../../hooks/useScreenSize';

let _ID = 0;

function MenuManagementPage() {
    const intl = useIntl();
    const location = useLocation();
    const successMessage = location.state ? location.state.successMessage : null;
    const navigate = useNavigate();
    const diaryContext = useContext(DiaryContext);
    const [infoBars, setInfoBars] = useState([]);
    const [menus, setMenus] = useState([]);
    const [isMenusLoading, setIsMenusLoading] = useState(true);
    const [isSyncModalOpen, setSyncModalOpen] = useState(false);
    const [menuToCopyId, setMenuToCopyId] = useState(null);
    const { isMobileView } = useScreenSize();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [menuToDelete, setMenuToDelete] = useState(null);

    const addSuccessBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    }, []);

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    useEffect(() => {
        if (successMessage) {
            addSuccessBar(successMessage);
        }
    }, [addSuccessBar, successMessage]);

    useEffect(() => {
        MenuService.getMenus(diaryContext.deploymentId, diaryContext.restaurantId).then((response) => {
            const mappedMenus = response.map((cat) => {
                return new MenuManagementMenu(cat);
            });

            setMenus(mappedMenus);
            setIsMenusLoading(false);
        });
    }, [diaryContext.deploymentId, diaryContext.restaurantId]);

    function getBreadcrumbOptions() {
        return [new BreadCrumbOption(intl.formatMessage({ id: 'Settings.Title' }), '/Settings')];
    }

    function syncMenuItems() {
        EposService.syncMenuItems(diaryContext.deploymentId, diaryContext.restaurantId)
            .then(() => {
                setSyncModalOpen(false);
                addSuccessBar(intl.formatMessage({ id: 'Settings.SyncTriggered' }));
            })
            .catch(() => {
                addErrorBar(intl.formatMessage({ id: 'Settings.ErrorSyncing' }));
            });
    }

    function isLoading() {
        return isMenusLoading;
    }

    function updateMenuIsDisabled(menuId, isDisabled) {
        const newMenus = [...menus];
        const index = newMenus.findIndex((menu) => menu.id === menuId);
        if (index >= 0) {
            newMenus[index].isDisabled = isDisabled;
        }
        setMenus(newMenus);
    }

    function addCopiedMenu(copiedMenu) {
        const newMenus = [...menus];
        newMenus.push(new MenuManagementMenu(copiedMenu));
        setMenus(newMenus);
    }

    function getCopyMenuModal() {
        return (
            <CopyMenuModal
                menuToCopyId={menuToCopyId}
                isModalOpen={menuToCopyId !== null}
                setMenuToCopyId={setMenuToCopyId}
                addSuccessBar={addSuccessBar}
                addErrorBar={addErrorBar}
                addCopiedMenu={addCopiedMenu}
            />
        );
    }

    function openDeletionModal(menu) {
        setMenuToDelete(menu);
        setIsModalOpen(true);
    }

    function deleteMenu() {
        MenuService.deleteMenu(diaryContext.deploymentId, diaryContext.restaurantId, menuToDelete.id)
            .then(() => {
                setMenus((prevMenus) => [...prevMenus.filter((menu) => menu.id !== menuToDelete.id)]);
                addSuccessBar(
                    intl.formatMessage(
                        {
                            id: 'Settings.SuccessMenuDeleting',
                        },
                        { menuName: `'${menuToDelete.name}'` }
                    )
                );
            })
            .catch(() => {
                addErrorBar(
                    intl.formatMessage(
                        {
                            id: 'Settings.ErrorMenuDeleting',
                        },
                        { menuName: `'${menuToDelete.name}'` }
                    )
                );
            })
            .finally(() => {
                setIsModalOpen(false);
            });
    }

    function getDeletionModalTitle() {
        return intl.formatMessage(
            {
                id: 'Settings.DeleteModalGenericTitle',
            },
            { itemTitle: `${menuToDelete ? menuToDelete.name : ''}` }
        );
    }

    function getCategoryRows() {
        return (
            <div className="menus-container">
                {menus.map((menu) => {
                    return (
                        <MenuManagementRow
                            key={menu.id}
                            menu={menu}
                            updateMenuIsDisabled={updateMenuIsDisabled}
                            setMenus={setMenus}
                            addSuccessBar={addSuccessBar}
                            addErrorBar={addErrorBar}
                            setMenuToCopyId={setMenuToCopyId}
                            openDeletionModal={openDeletionModal}
                        />
                    );
                })}
            </div>
        );
    }

    if (isLoading()) {
        return <LoadingSpinner />;
    }

    return (
        <BreadcrumbPage
            pageTitle={intl.formatMessage({ id: 'Settings.MenuManagement' })}
            breadcrumbOptions={getBreadcrumbOptions()}
            infoBars={infoBars}
            setInfoBars={setInfoBars}
        >
            {getCopyMenuModal()}
            <div className={isMobileView ? 'page-title-with-button mob' : 'page-title-with-button'}>
                <FormattedMessage id="Settings.MenuManagement" tagName="h2" />
                <Button color="primary" onClick={() => navigate('/Settings/MenuManagement/Create')} id="create-menu">
                    <FormattedMessage id="Settings.CreateNewMenu" />
                </Button>

                <Button color="primary" onClick={() => setSyncModalOpen(true)}>
                    <FormattedMessage id="Settings.SyncMenuItemsLabel" />
                </Button>

                {isSyncModalOpen && (
                    <SyncEposDataModal
                        isModalOpen={isSyncModalOpen}
                        setIsModalOpen={setSyncModalOpen}
                        syncMenuItems={syncMenuItems}
                    />
                )}
            </div>
            {getCategoryRows()}

            <DeletionModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                deleteItem={deleteMenu}
                title={getDeletionModalTitle()}
                bodyText={intl.formatMessage({ id: 'Settings.DeleteMenuWarningMessage' })}
            />
        </BreadcrumbPage>
    );
}

export default MenuManagementPage;
