import React, { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import runningOrderColumns from '../../enums/runningOrderColumns';
import BookingStatusControl from './BookingStatusControl';
import { ReactComponent as CustomerCommentsIcon } from '../../content/icons/Icon-Customer-Comment.svg';
import { ReactComponent as DcRewardsBadge } from '../../content/images/DC-rewards.svg';
import BookingStatus from '../../domainObjects/BookingStatus';
import BookingSummaryOverlayContent from '../../domainObjects/BookingSummaryOverlayContent';
import { FormattedMessage, useIntl } from 'react-intl';
import ColouredDot from './ColouredDot';
import useFormattedTime from '../../hooks/useFormattedTime';
import BookingHelper from '../../helpers/BookingHelper';
import Tag from '../common/Tag';
import CurrencyDiv from '../common/CurrencyDiv';
import tagColour from '../../enums/tagColour';
import orderAtTableStatus from '../../enums/orderAtTableStatus';
import DiaryContext from '../../contexts/DiaryContext';
import AddOnContext from '../../contexts/AddOnContext';
import loyaltyStatus from '../../enums/loyaltyStatus';
import addOnType from '../../enums/addOnType';

function BookingEntry(props) {
    const diaryContext = useContext(DiaryContext);
    const intl = useIntl();
    const addOnContext = useContext(AddOnContext);

    let columns = [
        runningOrderColumns.visitTime,
        runningOrderColumns.covers,
        runningOrderColumns.tables,
        runningOrderColumns.customerDetails,
    ];

    if (diaryContext.loyaltyStatus === loyaltyStatus.active) {
        columns.push(runningOrderColumns.customerIsLoyaltyMember);
    }

    columns.push(runningOrderColumns.bookingComments);

    if (diaryContext.hasSquareEnabled) {
        columns.push(runningOrderColumns.orderStatus);
    }

    columns.push(runningOrderColumns.reference);

    if (addOnContext.isAddOnEnabled(addOnType.vouchers)) {
        columns.push(runningOrderColumns.voucherReference);
    }
    columns.push(runningOrderColumns.bookingReason);

    if (diaryContext.loyaltyStatus === loyaltyStatus.active) {
        columns.push(runningOrderColumns.dishcultRewardsRedemption);
    }

    const [columnTypes] = useState(columns);

    const [bookingStatusControlOpen, setBookingStatusControlOpen] = useState(false);

    const formattedTime = useFormattedTime(props.booking.visitDateTime);

    function getCustomerDetails() {
        if (props.booking.customer) {
            const tableCellDataArray = [<p key={'CDName'}>{props.booking.customer.displayFullName()}</p>];
            if (props.booking.customer.mobileNumber || props.booking.customer.phoneNumber) {
                let phoneLine = '';

                if (props.booking.customer.mobileNumber && props.booking.customer.phoneNumber) {
                    phoneLine = `${props.booking.customer.mobileNumber} / ${props.booking.customer.phoneNumber}`;
                } else {
                    phoneLine = props.booking.customer.mobileNumber + props.booking.customer.phoneNumber;
                }

                tableCellDataArray.push(<p key={'CDMobile'}>{phoneLine}</p>);
            }

            return tableCellDataArray;
        } else {
            return (
                <p>
                    <FormattedMessage id="Bookings.WalkIn" />
                </p>
            );
        }
    }

    function getBookingReasons() {
        const reasons = [];
        if (props.bookingReasons) {
            props.bookingReasons.forEach((bookingReason) => {
                reasons.push(
                    <p className="booking-reason-item" key={bookingReason.id}>
                        {bookingReason.name}
                    </p>
                );
            });
        }
        return reasons;
    }

    function getTableLabels() {
        let tables = '';
        if (props.tableLabels && props.tableLabels.length > 0) {
            const tableNames = props.tableLabels.map((label) => label.name);
            tables = tableNames.join(', ');
        } else {
            return '-';
        }
        return tables;
    }

    function getVoucherInfo() {
        if (props.booking.voucherName && props.booking.voucherReference && props.booking.voucherCost) {
            return (
                <Fragment>
                    <div>{props.booking.voucherReference}</div>
                    <CurrencyDiv price={props.booking.voucherCost} /> {props.booking.voucherName}
                </Fragment>
            );
        }
        return null;
    }

    function getCustomerLoyaltyStatus() {
        return (
            props.booking.customer &&
            props.booking.customer.loyaltyId && <DcRewardsBadge className="dish-cult-rewards-badge" />
        );
    }

    function getDishcultRewardRedemption() {
        return props.booking.dishcultRewardsVoucherCode && <CurrencyDiv price={'10.00'} />;
    }

    function toggleBookingStatusControlOpen() {
        setBookingStatusControlOpen(!bookingStatusControlOpen);
    }

    function closeBookingStatusControl() {
        setBookingStatusControlOpen(false);
    }

    function updateArrivalStatus(newArrivalStatus) {
        return props.updateArrivalStatus(props.booking, newArrivalStatus, toggleBookingStatusControlOpen);
    }

    function closeBooking() {
        return props.closeBooking(props.booking, toggleBookingStatusControlOpen);
    }

    function rebookBooking() {
        return props.rebookBooking(props.booking, toggleBookingStatusControlOpen);
    }

    function toggleReconfirmedStatus() {
        return props.toggleReconfirmedStatus(props.booking, toggleBookingStatusControlOpen);
    }

    function openCancellationModalWithBookingId() {
        return props.openCancellationModalWithBookingId(props.booking, toggleBookingStatusControlOpen);
    }

    function getOrderAtTableStatusText() {
        switch (props.booking.orderAtTableStatus) {
            case orderAtTableStatus.MenuSent:
                return intl.formatMessage({
                    id: 'Bookings.MenuSent',
                });
            case orderAtTableStatus.Completed:
                return intl.formatMessage({
                    id: 'Bookings.Completed',
                });
            case orderAtTableStatus.Failed:
                return intl.formatMessage({
                    id: 'Bookings.Failed',
                });
            default:
                return '';
        }
    }

    function getOrderAtTableStatusColour() {
        switch (props.booking.orderAtTableStatus) {
            case orderAtTableStatus.MenuSent:
                return tagColour.Navy;
            case orderAtTableStatus.Completed:
                return tagColour.Green;
            case orderAtTableStatus.Failed:
                return tagColour.Red;
            default:
                return tagColour.None;
        }
    }

    return (
        <tr
            onClick={() => props.openOverlay(new BookingSummaryOverlayContent(props.booking))}
            data-testid="bookingEntry-row"
            className={`booking-entry-row${BookingHelper.getRowStatusCssClass(props.booking, props.isSelectedBooking)}`}
        >
            {columnTypes.map((c) => {
                switch (c) {
                    case runningOrderColumns.visitTime:
                        return (
                            <td key={c}>
                                <div className="running-order-time-section">
                                    <BookingStatusControl
                                        booking={new BookingStatus(props.booking)}
                                        updateArrivalStatus={updateArrivalStatus}
                                        closeBooking={closeBooking}
                                        openCancellationModalWithBookingId={openCancellationModalWithBookingId}
                                        rebookBooking={rebookBooking}
                                        toggleReconfirmedStatus={toggleReconfirmedStatus}
                                        bookingStatusButtonId={'bookingStatusRunningOrderButton_' + props.booking.id}
                                        tooltipPlacement="right"
                                        tooltipOpen={bookingStatusControlOpen}
                                        toggleBookingStatusTooltip={toggleBookingStatusControlOpen}
                                        closeBookingStatusControl={closeBookingStatusControl}
                                        buttonClassName="booking-status-icon-button"
                                    />
                                    <div className="visitTime-label">{formattedTime}</div>
                                </div>
                            </td>
                        );
                    case runningOrderColumns.covers:
                        return <td key={c}>{props.booking.partySize}</td>;
                    case runningOrderColumns.tables:
                        return <td key={c}>{getTableLabels()}</td>;
                    case runningOrderColumns.customerDetails:
                        return (
                            <td key={c}>
                                <div className="customer-details-container">
                                    <ColouredDot colour={props.booking.channelColour} />
                                    <div>{getCustomerDetails()}</div>
                                    {props.booking.customer && props.booking.customer.customerComments && (
                                        <div className="customer-details-comments-icon">
                                            <CustomerCommentsIcon />
                                        </div>
                                    )}
                                </div>
                            </td>
                        );
                    case runningOrderColumns.bookingComments:
                        return <td key={c}>{props.booking.bookingComments}</td>;
                    case runningOrderColumns.orderStatus:
                        return (
                            <td key={c}>
                                <div>
                                    <Tag value={getOrderAtTableStatusText()} color={getOrderAtTableStatusColour()} />
                                </div>
                            </td>
                        );
                    case runningOrderColumns.reference:
                        return <td key={c}>{props.booking.bookingReference}</td>;
                    case runningOrderColumns.bookingReason:
                        return <td key={c}>{<div className="booking-reason-container">{getBookingReasons()}</div>}</td>;
                    case runningOrderColumns.voucherReference:
                        return <td key={c}>{getVoucherInfo()}</td>;
                    case runningOrderColumns.customerIsLoyaltyMember:
                        return (
                            <td key={c} className="customer-loyalty-status-container">
                                {getCustomerLoyaltyStatus()}
                            </td>
                        );
                    case runningOrderColumns.dishcultRewardsRedemption:
                        return (
                            <td key={c} className="dishcult-reward-redemption-container">
                                {getDishcultRewardRedemption()}
                            </td>
                        );
                    default:
                        return <td key={c}></td>;
                }
            })}
        </tr>
    );
}

BookingEntry.propTypes = {
    booking: PropTypes.object.isRequired,
    openOverlay: PropTypes.func.isRequired,
    bookingReasons: PropTypes.array,
    updateArrivalStatus: PropTypes.func,
    closeBooking: PropTypes.func,
    openCancellationModalWithBookingId: PropTypes.func,
    toggleReconfirmedStatus: PropTypes.func,
    isSelectedBooking: PropTypes.bool,
};

export default BookingEntry;
