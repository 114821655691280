import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ValidationDropdown from '../../common/ValidationDropdown';
import ValidationInput from '../../common/ValidationInput';
import { FormGroup, Label } from 'reactstrap';
import Option from '../../common/Option';
import PhoneNumberInput from '../../common/PhoneNumberInput';
import CheckBox from '../../common/CheckBox';
import { useIntl } from 'react-intl';
import validator from 'validator';

function ContactDetailsSection(props) {
    const intl = useIntl();

    function getCountryOptions() {
        return props.countryCodes ? props.countryCodes.map((c) => new Option(c.Name, c.Id)) : [];
    }

    function getCountryCodeOptions() {
        return props.countryCodes.map(
            (countryCode) => new Option(`${countryCode.Name}: +${countryCode.PhoneCode.toString()}`, countryCode.Id)
        );
    }

    function checkValidEmail(email) {
        if (email && !validator.isEmail(email)) {
            return intl.formatMessage({ id: 'Settings.EmailValidation' });
        }
    }

    function checkValidPhoneNumber(phoneNumber) {
        if (phoneNumber && !validator.isMobilePhone(phoneNumber)) {
            return intl.formatMessage({ id: 'Settings.PhoneValidation' });
        }
    }

    return (
        <Fragment>
            <p className="form-group-title">{intl.formatMessage({ id: 'Settings.ContactDetails' })}</p>

            <FormGroup className="loyalty-form-group contact-details">
                <FormGroup>
                    <ValidationDropdown
                        title={intl.formatMessage({ id: 'Settings.TimeZone' })}
                        onChange={(country) => {
                            props.setContactDetailsSection(
                                'timezone',
                                props.countryCodes.find((c) => c.Id === country).Name
                            );
                        }}
                        selectedValue={
                            props.countryCodes.find((c) => c.Name === props.contactDetails.timezone) != null
                                ? props.countryCodes.find((c) => c.Name === props.contactDetails.timezone).Id
                                : null
                        }
                        options={getCountryOptions()}
                        formValidation={props.formValidation}
                        name="timezone"
                        validationRules={{
                            required: intl.formatMessage(
                                { id: 'Settings.LoyaltyFormIsRequired' },
                                { fieldName: intl.formatMessage({ id: 'Settings.TimeZone' }) }
                            ),
                        }}
                        hideRemoveItemIcon
                        defaultValue={intl.formatMessage({ id: 'Settings.SelectOne' })}
                        isSearchable
                        ariaRequired
                    />
                </FormGroup>
                <FormGroup>
                    <PhoneNumberInput
                        id="phoneNumber"
                        name="phoneNumber"
                        optionGroups={getCountryCodeOptions()}
                        onCountryCodeChange={(e) => props.setContactDetailsSection('countryCode', e)}
                        onChange={(e) => props.setContactDetailsSection('phoneNumber', e)}
                        inputParameterToUpdate="phoneNumber"
                        selectedValue={props.contactDetails.countryCode}
                        inputValue={props.contactDetails.phoneNumber}
                        label={
                            intl.formatMessage({ id: 'Settings.VenuePhoneNumber' }) +
                            ' - ' +
                            intl.formatMessage({ id: 'Settings.Optional' })
                        }
                        innerRef={props.formValidation.register({
                            validate: (value) => checkValidPhoneNumber(value),
                        })}
                        includeValidation
                        errors={props.formValidation.errors}
                        placeholder={intl.formatMessage({ id: 'Settings.PhoneNumber' })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="venuesEmailAddress">
                        {intl.formatMessage({ id: 'Settings.VenueEmailAddress' }) +
                            ' - ' +
                            intl.formatMessage({ id: 'Settings.Optional' })}
                    </Label>
                    <ValidationInput
                        name="emailAddress"
                        type="text"
                        value={props.contactDetails.emailAddress}
                        onChange={(value) => props.setContactDetailsSection('emailAddress', value)}
                        innerRef={props.formValidation.register({
                            validate: (value) => checkValidEmail(value),
                        })}
                        errors={props.formValidation.errors}
                        placeholder={intl.formatMessage({ id: 'Settings.EmailAddress' })}
                    />
                </FormGroup>
                <FormGroup>
                    <CheckBox
                        option={
                            new Option(
                                intl.formatMessage(
                                    { id: 'Settings.LoyaltyFormTermsAndConditions' },
                                    {
                                        termsAndConditions: (
                                            <a href="https://resdiary.com/dish-cult-rewards-terms-of-use">
                                                {intl.formatMessage({ id: 'Settings.TermsAndConditionsRequired' })}
                                            </a>
                                        ),
                                        privacyPolicy: (
                                            <a href="https://resdiary.com/privacy-policy-b2b">
                                                {intl.formatMessage({ id: 'Settings.PrivacyPolicyRequired' })}
                                            </a>
                                        ),
                                    }
                                )
                            )
                        }
                        onChange={() =>
                            props.setTermsAndConditionsCheckboxChecked(!props.termsAndConditionsCheckboxChecked)
                        }
                        isChecked={props.termsAndConditionsCheckboxChecked}
                        id="tandc-checkbox"
                    />
                </FormGroup>
                <FormGroup>
                    <CheckBox
                        option={new Option(intl.formatMessage({ id: 'Settings.LoyaltyFormUserPermission' }))}
                        onChange={() => props.setPermissionCheckboxChecked(!props.permissionCheckboxChecked)}
                        isChecked={props.permissionCheckboxChecked}
                        id="permission-checkbox"
                    />
                </FormGroup>
            </FormGroup>
        </Fragment>
    );
}

ContactDetailsSection.propTypes = {
    contactDetails: PropTypes.object.isRequired,
    setContactDetailsSection: PropTypes.func.isRequired,
    countryCodes: PropTypes.array,
    formValidation: PropTypes.object.isRequired,
    termsAndConditionsCheckboxChecked: PropTypes.bool.isRequired,
    setTermsAndConditionsCheckboxChecked: PropTypes.func.isRequired,
    permissionCheckboxChecked: PropTypes.bool.isRequired,
    setPermissionCheckboxChecked: PropTypes.func.isRequired,
};

export default ContactDetailsSection;
