import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TypeaheadWrapper from '../common/TypeaheadWrapper';
import MagicSearchService from '../../services/MagicSearchService';
import magicSearchType from '../../enums/magicSearchType';
import { useIntl } from 'react-intl';

function BookingTypeahead(props) {
    const intl = useIntl();
    const { deploymentId, restaurantId, date } = props;
    const processResponse = useCallback((response) => {
        const categories = response.data.Categories.find(
            (cat) => cat.ResultType === magicSearchType.expectedArrival || cat.ResultType === magicSearchType.booking
        );
        if (categories) {
            const results = categories.Items.map((item) => ({
                id: item.EntityId,
                display: item.Display,
                alternateDisplay: item.AlternateDisplay,
            }));
            return results;
        } else {
            return [];
        }
    }, []);

    const makeRequest = useCallback(
        (searchTerm) => {
            return MagicSearchService.search(deploymentId, restaurantId, date, searchTerm);
        },
        [deploymentId, restaurantId, date]
    );

    return (
        <TypeaheadWrapper
            typeaheadItemSelected={props.typeaheadItemSelected}
            requestFunctionAsync={makeRequest}
            processResultsFunction={processResponse}
            placeholder={intl.formatMessage({ id: 'Common.BookingTypeAheadPlaceholder' })}
        />
    );
}

BookingTypeahead.propTypes = {
    date: PropTypes.string.isRequired,
    deploymentId: PropTypes.number.isRequired,
    restaurantId: PropTypes.number.isRequired,
    typeaheadItemSelected: PropTypes.func.isRequired,
};

export default BookingTypeahead;
