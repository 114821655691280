class SocialNetworkDetails {
    constructor(dto) {
        this.twitterUrl = dto && dto.TwitterUrl ? dto.TwitterUrl : '';
        this.facebookUrl = dto && dto.FacebookUrl ? dto.FacebookUrl : '';
        this.youtubeUrl = dto && dto.YoutubeUrl ? dto.YoutubeUrl : '';
        this.instagramUrl = dto && dto.InstagramUrl ? dto.InstagramUrl : '';
    }

    getDTO(twitterUrl, facebookUrl, youtubeUrl, instagramUrl) {
        return {
            TwitterUrl: twitterUrl,
            FacebookUrl: facebookUrl,
            YoutubeUrl: youtubeUrl,
            InstagramUrl: instagramUrl,
        };
    }
}

export default SocialNetworkDetails;
