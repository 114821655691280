import React from 'react';
import PropTypes from 'prop-types';

function Body(props) {
    return (
        <div className={`container-fluid body-container ${props.hideHeaderBar ? 'hide-header-bar' : ''}`}>
            <div className="row">
                <div className="col-md-12">{props.children}</div>
            </div>
        </div>
    );
}

Body.propTypes = {
    children: PropTypes.element.isRequired,
    hideHeaderBar: PropTypes.bool,
};

export default Body;
