import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

function FooterPreviewModal(props) {
    function getCloseButton() {
        if (!props.hideCloseButton) {
            return (
                <button className="close close-button" onClick={() => props.setIsModalOpen(false)}>
                    &times;
                </button>
            );
        }
    }

    return (
        <Modal
            zIndex={1072}
            size="lg"
            isOpen={props.isModalOpen}
            modalClassName={props.modalClassName ? 'footer-modal ' + props.modalClassName : 'footer-modal'}
            backdropClassName="footer-modal-backdrop"
        >
            <ModalHeader close={getCloseButton()} className="text-uppercase">
                {props.title}
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
                <div>{props.body}</div>
            </ModalBody>
            {props.customFooter}
        </Modal>
    );
}

FooterPreviewModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    title: PropTypes.node.isRequired,
    body: PropTypes.node.isRequired,
    modalClassName: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    customFooter: PropTypes.element,
};

export default FooterPreviewModal;
