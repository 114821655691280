import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import TabbedContentPage from '../common/TabbedContentPage';
import ListingStrength from '../common/ListingStrength';
import { FormattedMessage } from 'react-intl';
import useOnboardingProgress from '../../hooks/useOnboardingProgress';
import useScreenSize from '../../hooks/useScreenSize';
import ProgressFlagsContext from '../../contexts/ProviderProgressFlagsContext';
import { Button } from 'reactstrap';
import propTypeExtensions from '../../helpers/PropTypeExtensions';
import logoAndImagesSettingsTab from '../../enums/Tabs/logoAndImagesSettingsTab';
import venueSettingsTab from '../../enums/Tabs/venueSettingsTab';
import { useNavigate } from 'react-router-dom';

function SettingsTabbedContentPage(props) {
    const onboardingProgress = useOnboardingProgress();
    const progressFlagsContext = useContext(ProgressFlagsContext);
    const { isTabletView } = useScreenSize();
    const navigate = useNavigate();

    function getIncreaseBookingRateButtonText() {
        if (props.currentTabKey === logoAndImagesSettingsTab.restaurantImages) {
            return !progressFlagsContext.uploadedMenu ? (
                <FormattedMessage id="Settings.UploadMenu" />
            ) : (
                <FormattedMessage id="Settings.UpdateListing" />
            );
        } else if (props.currentTabKey === venueSettingsTab.myListing) {
            return !progressFlagsContext.addedAdditionalImage ? (
                <FormattedMessage id="Settings.UploadRestaurantImages" />
            ) : (
                <FormattedMessage id="Settings.UploadMenu" />
            );
        } else if (props.currentTabKey === venueSettingsTab.myListingMenus) {
            return !progressFlagsContext.addedAdditionalImage ? (
                <FormattedMessage id="Settings.UploadRestaurantImages" />
            ) : (
                <FormattedMessage id="Settings.UpdateListing" />
            );
        } else {
            if (!progressFlagsContext.addedAdditionalImage) {
                return <FormattedMessage id="Settings.UploadRestaurantImages" />;
            } else if (!progressFlagsContext.uploadedMenu) {
                return <FormattedMessage id="Settings.UploadMenu" />;
            } else {
                return <FormattedMessage id="Settings.UpdateListing" />;
            }
        }
    }

    function increaseBookingRateButtonRedirect() {
        if (props.currentTabKey === logoAndImagesSettingsTab.restaurantImages) {
            return !progressFlagsContext.uploadedMenu
                ? navigate('/Settings/Venue?listing=MyListingMenus')
                : navigate('/Settings/Venue?listing=MyListing');
        } else if (props.currentTabKey === venueSettingsTab.myListing) {
            return !progressFlagsContext.addedAdditionalImage
                ? navigate('/Settings/Images')
                : props.onTabChanged(venueSettingsTab.myListingMenus);
        } else if (props.currentTabKey === venueSettingsTab.myListingMenus) {
            return !progressFlagsContext.addedAdditionalImage
                ? navigate('/Settings/Images')
                : props.onTabChanged(venueSettingsTab.myListing);
        } else {
            if (!progressFlagsContext.addedAdditionalImage) {
                navigate('/Settings/Images');
            } else if (!progressFlagsContext.uploadedMenu) {
                props.onTabChanged(venueSettingsTab.myListingMenus);
            } else {
                props.onTabChanged(venueSettingsTab.myListing);
            }
        }
    }

    function displayIncreaseBookingButton() {
        if (props.currentTabKey === logoAndImagesSettingsTab.restaurantImages) {
            return !progressFlagsContext.uploadedMenu || !progressFlagsContext.addedAdvancedListing;
        } else if (props.currentTabKey === venueSettingsTab.myListing) {
            return !progressFlagsContext.addedAdditionalImage || !progressFlagsContext.uploadedMenu;
        } else if (props.currentTabKey === venueSettingsTab.myListingMenus) {
            return !progressFlagsContext.addedAdditionalImage || !progressFlagsContext.addedAdvancedListing;
        }
        return true;
    }

    function getSidePanelContent() {
        return (
            <Fragment>
                {props.sidePanelAdditionalContent}
                {!progressFlagsContext.dismissedCompletionModal && (
                    <Fragment>
                        <div className="listing-strength-wrapper">
                            <ListingStrength percentage={onboardingProgress} includeHeading centralProgressBar />
                        </div>
                        <FormattedMessage id="Common.ListingStrengthExplanation" />
                    </Fragment>
                )}
                {!progressFlagsContext.dismissedCompletionModal && displayIncreaseBookingButton() && (
                    <div className={isTabletView ? 'increase-booking-rate-box fixed' : 'increase-booking-rate-box'}>
                        <div className="bold-text">
                            <FormattedMessage id="AccountSubmission.IncreaseBookingRateBy200Percent" />
                        </div>
                        <Button
                            className={'btn btn-primary'}
                            color="primary"
                            onClick={() => increaseBookingRateButtonRedirect()}
                        >
                            {getIncreaseBookingRateButtonText()}
                        </Button>
                    </div>
                )}
            </Fragment>
        );
    }

    return (
        <TabbedContentPage
            pageTitle={props.pageTitle}
            tabLabels={props.tabLabels}
            currentTabKey={props.currentTabKey}
            tabs={props.tabs}
            sidePanelAdditionalContent={getSidePanelContent()}
            onTabChanged={props.onTabChanged}
            includeBreadcrumb={props.includeBreadcrumb}
            breadcrumbOptions={props.breadcrumbOptions}
            breadcrumbActivePageTitle={props.breadcrumbActivePageTitle}
            infoBars={props.infoBars}
            setInfoBars={props.setInfoBars}
            isLoading={props.isLoading}
            tabbedContentClass={
                isTabletView && !progressFlagsContext.dismissedCompletionModal && displayIncreaseBookingButton()
                    ? 'fixed-bottom-bar'
                    : ''
            }
        />
    );
}

SettingsTabbedContentPage.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    tabLabels: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
        })
    ).isRequired,
    currentTabKey: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.element).isRequired,
    sidePanelAdditionalContent: PropTypes.element,
    onTabChanged: PropTypes.func.isRequired,
    includeBreadcrumb: PropTypes.bool,
    breadcrumbOptions: propTypeExtensions.requiredIf(PropTypes.array, (props) => props.includeBreadcrumb),
    breadcrumbActivePageTitle: propTypeExtensions.requiredIf(PropTypes.string, (props) => props.includeBreadcrumb),

    setInfoBars: PropTypes.func,
    infoBars: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
            message: PropTypes.string,
        })
    ),
    isLoading: PropTypes.bool,
};

export default SettingsTabbedContentPage;
