import React from 'react';
import PropTypes from 'prop-types';
import TextWithIcon from '../common/TextWithIcon';
import { ReactComponent as ClockIcon } from '../../content/icons/Icon-Clock.svg';
import { ReactComponent as FullySeatedIcon } from '../../content/icons/Icon-FullySeated.svg';
import { ReactComponent as PersonIcon } from '../../content/icons/person.svg';
import useFormattedTime from '../../hooks/useFormattedTime';
import { FormattedMessage, useIntl } from 'react-intl';
import BookingStatus from '../../domainObjects/BookingStatus';
import BookingStatusControl from './BookingStatusControl';
import { ReactComponent as CustomerCommentsIcon } from '../../content/icons/Icon-Customer-Comment.svg';
import BookingSummaryOverlayContent from '../../domainObjects/BookingSummaryOverlayContent';
import Tag from '../common/Tag';
import tagColour from '../../enums/tagColour';
import orderAtTableStatus from '../../enums/orderAtTableStatus';

function MobileBookingCard(props) {
    const intl = useIntl();
    const formattedTime = useFormattedTime(props.booking.visitDateTime);

    function getBookingReasons() {
        const reasons = [];
        props.bookingReasons.forEach((bookingReason) => {
            reasons.push(
                <div className="booking-reason-item" key={bookingReason.id}>
                    {bookingReason.name}
                </div>
            );
        });
        return reasons;
    }

    function getTableLabels() {
        let tables = '';
        if (props.tableLabels && props.tableLabels.length > 0) {
            const tableNames = props.tableLabels.map((label) => label.name);
            tables = tableNames.join(', ');
        } else {
            return '-';
        }
        return tables;
    }

    function getOrderAtTableStatusText() {
        switch (props.booking.orderAtTableStatus) {
            case orderAtTableStatus.MenuSent:
                return intl.formatMessage({
                    id: 'Bookings.MenuSent',
                });
            case orderAtTableStatus.Completed:
                return intl.formatMessage({
                    id: 'Bookings.Completed',
                });
            case orderAtTableStatus.Failed:
                return intl.formatMessage({
                    id: 'Bookings.Failed',
                });
            default:
                return '';
        }
    }

    function getOrderAtTableStatusColour() {
        switch (props.booking.orderAtTableStatus) {
            case orderAtTableStatus.MenuSent:
                return tagColour.Navy;
            case orderAtTableStatus.Completed:
                return tagColour.Green;
            case orderAtTableStatus.Failed:
                return tagColour.Red;
            default:
                return tagColour.None;
        }
    }

    return (
        <div
            className="mobile-booking-card"
            onClick={() => props.openOverlay(new BookingSummaryOverlayContent(props.booking))}
        >
            <div className="mobile-row">
                <div className="d-flex booking-details">
                    <div className="mobile-booking-status-container">
                        <BookingStatusControl
                            booking={new BookingStatus(props.booking)}
                            bookingStatusButtonId={'bookingStatusRunningOrderButton_' + props.booking.id}
                            tooltipOpen={false}
                            buttonClassName="booking-status-icon-button mobile"
                            readOnly
                            wrapperClassName="mobile"
                        />
                    </div>
                    <div className="mobile-booking-info-container">
                        <div className="d-flex customer-name">
                            {props.booking.customer && <div> {props.booking.customer.displayFullName()}</div>}
                            {props.booking.customer && props.booking.customer.customerComments && (
                                <div className="customer-details-comments-icon ml-auto">
                                    <CustomerCommentsIcon />
                                </div>
                            )}
                            {!props.booking.customer && <FormattedMessage id="Bookings.WalkIn" />}
                        </div>

                        <div className="mobile-booking-info">
                            <TextWithIcon
                                className="mobile-booking-card-icon"
                                text={formattedTime}
                                icon={<ClockIcon />}
                            />
                            <TextWithIcon
                                className="mobile-booking-card-icon"
                                text={props.booking.partySize}
                                icon={<PersonIcon />}
                            />
                            <TextWithIcon
                                className="mobile-booking-card-icon"
                                text={getTableLabels()}
                                icon={<FullySeatedIcon />}
                            />
                        </div>
                    </div>
                </div>
                {props.booking.orderAtTableStatus && (
                    <div className="additional-info-row">
                        <div className="order-section">
                            <Tag value={getOrderAtTableStatusText()} color={getOrderAtTableStatusColour()} />
                        </div>
                    </div>
                )}
                {props.booking.bookingComments && (
                    <div className="additional-info-row">{props.booking.bookingComments}</div>
                )}
                {props.bookingReasons.length > 0 && (
                    <div className="additional-info-row">
                        <div className="booking-reason-container">{getBookingReasons()}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

MobileBookingCard.propTypes = {
    booking: PropTypes.object.isRequired,
    bookingReasons: PropTypes.array,
    tableLabels: PropTypes.array,
};

export default MobileBookingCard;
