import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button } from 'reactstrap';
import { ReactComponent as UpArrowIcon } from '../../content/icons/up-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../content/icons/down-arrow.svg';
import { ReactComponent as DragIcon } from '../../content/icons/Icon-Drag-Handle.svg';

function Section(props) {
    const isCollapsible = props.isCollapsible !== null ? props.isCollapsible : true;
    const [isOpen, setisOpen] = useState(
        !isCollapsible ? true : props.closedByDefault !== null ? !props.closedByDefault : true
    );

    const toggle = () => {
        setisOpen(!isOpen);
    };

    function getArrowIcon() {
        return isOpen ? <UpArrowIcon /> : <DownArrowIcon />;
    }

    function getSectionTitle() {
        if (typeof props.sectionTitle === 'string') {
            return <h6 className="p-3 m-0 text-uppercase">{props.sectionTitle}</h6>;
        } else {
            return props.sectionTitle;
        }
    }

    return (
        <div className={props.sectionClassName ? `section ${props.sectionClassName}` : 'section'}>
            <div className={props.isTransparent ? 'transparent form-panel-header' : 'form-panel-header'}>
                {props.isGrabbable && (
                    <div className="grabbable-icon" {...props.dragHandleProps}>
                        <DragIcon />
                    </div>
                )}
                <div className="section-title">{getSectionTitle()}</div>
                {props.headerChildren}
                {!props.isLoading && props.isCollapsible && !props.isButtonDisabled && (
                    <Button
                        className={
                            props.includeBorderOnChevron
                                ? 'customisable-panel-button toggle-button'
                                : 'section-chevron toggle-button'
                        }
                        onClick={toggle}
                    >
                        {getArrowIcon()}
                    </Button>
                )}
            </div>
            <Collapse isOpen={isOpen}>{props.children}</Collapse>
        </div>
    );
}

Section.propTypes = {
    children: PropTypes.node.isRequired,
    headerChildren: PropTypes.node,
    sectionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closedByDefault: PropTypes.bool,
    isCollapsible: PropTypes.bool,
    isButtonDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    includeBorderOnChevron: PropTypes.bool,
    isTransparent: PropTypes.bool,
    sectionClassName: PropTypes.string,
    dragHandleProps: PropTypes.object,
    isGrabbable: PropTypes.bool,
};

export default Section;
