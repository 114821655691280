import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import DiaryContext from '../../contexts/DiaryContext';
import propTypeExtensions from '../../helpers/PropTypeExtensions';

function CurrencyDiv(props) {
    const diaryContext = useContext(DiaryContext);
    return (
        <Fragment>
            {!props.currencySymbolOnly && (
                <div
                    className="d-inline-block"
                    dangerouslySetInnerHTML={{
                        __html: `<div class=${props.className ? props.className : ''}>${diaryContext.currencySymbol}${
                            props.price
                        }</div>`,
                    }}
                ></div>
            )}
            {props.currencySymbolOnly && (
                <div
                    className="currency-symbol-only"
                    dangerouslySetInnerHTML={{ __html: `<span>${diaryContext.currencySymbol}</span>` }}
                ></div>
            )}
        </Fragment>
    );
}

CurrencyDiv.propTypes = {
    price: propTypeExtensions.requiredIf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        (props) => !props.currencySymbolOnly
    ),
    className: PropTypes.string,
};

export default CurrencyDiv;
