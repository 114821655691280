import React from 'react';
import DatePicker from './DatePicker';
import MomentHelper from '../../helpers/MomentHelper';
import PropTypes from 'prop-types';
import Moment from 'moment';
import useScreenSize from '../../hooks/useScreenSize';
import { ReactComponent as CalendarIcon } from '../../content/icons/Icon-Calendar.svg';

function DatePickerWrapper(props) {
    const { isMobileView } = useScreenSize();

    function incrementDate() {
        const newDateString = props.date.add(1, 'days').format('LL');
        props.setDate(MomentHelper.dateWithFormat(newDateString, 'LL'));
    }

    function decrementDate() {
        const newDateString = props.date.subtract(1, 'days').format('LL');
        props.setDate(MomentHelper.dateWithFormat(newDateString, 'LL'));
    }

    function goToToday() {
        props.setDate(MomentHelper.newInstance());
    }

    if (props.showCalendarIcon) {
        // Wrapped in label so that calendar icon triggers click event for datepicker
        return (
            <label className="date-picker-wrapper has-icon">
                <DatePicker
                    onDateChange={props.setDate}
                    incrementDate={incrementDate}
                    decrementDate={decrementDate}
                    goToToday={props.includeTodayButton ? goToToday : null}
                    date={props.date}
                    skeletonWidth={175}
                    allowTodayAndFutureDatesOnly={props.allowTodayAndFutureDatesOnly}
                    disabled={props.disabled}
                    excludeArrows={props.excludeArrows}
                    hideDayFromDate={props.hideDayFromDate}
                    isMobile={isMobileView}
                />
                <div className="calendar-icon-container">
                    <CalendarIcon />
                </div>
            </label>
        );
    }

    return (
        <div className="date-picker-wrapper">
            <DatePicker
                onDateChange={props.setDate}
                incrementDate={incrementDate}
                decrementDate={decrementDate}
                goToToday={props.includeTodayButton ? goToToday : null}
                date={props.date}
                skeletonWidth={175}
                allowTodayAndFutureDatesOnly={props.allowTodayAndFutureDatesOnly}
                disabled={props.disabled}
                excludeArrows={props.excludeArrows}
                hideDayFromDate={props.hideDayFromDate}
                isMobile={isMobileView}
            />
        </div>
    );
}

DatePickerWrapper.propTypes = {
    date: PropTypes.instanceOf(Moment),
    setDate: PropTypes.func.isRequired,
    includeTodayButton: PropTypes.bool,
    allowTodayAndFutureDatesOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    excludeArrows: PropTypes.bool,
    hideDayFromDate: PropTypes.bool,
    showCalendarIcon: PropTypes.bool,
};

export default DatePickerWrapper;
