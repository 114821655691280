import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import ValidationMessage from '../common/ValidationMessage';
import Dropdown from './Dropdown';
import { Controller } from 'react-hook-form';
import propTypeExtensions from '../../helpers/PropTypeExtensions';

function ValidationDropdown(props) {
    const multiSelect = props.multiSelect;
    const currentValue = props.selectedValue;
    const currentValues = props.selectedValues;
    const name = props.name;
    const setValue = props.formValidation.setValue;

    useEffect(() => {
        const newValue = multiSelect ? currentValues : currentValue;
        setValue(name, newValue);
    }, [setValue, name, currentValue, currentValues, multiSelect]);

    function onChange(value) {
        props.onChange(value);
        const newValue = multiSelect ? currentValues.concat([value]) : value;
        setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
    }

    function onClear() {
        props.onClearSelectedValues();
        setValue(name, [], { shouldValidate: true, shouldDirty: true });
    }

    function onRemove(value) {
        props.onMultiSelectRemoved(value);
        const newValue = currentValues.filter((v) => v !== value);
        setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
    }

    return (
        <Controller
            control={props.formValidation.control}
            name={props.name}
            render={() => (
                <Fragment>
                    <Dropdown
                        isSearchable={props.isSearchable}
                        onValueChange={onChange}
                        defaultValue={props.defaultValue}
                        options={props.options}
                        selectedValue={props.selectedValue}
                        selectedValues={props.selectedValues}
                        title={props.title}
                        multiSelect={props.multiSelect}
                        hideRemoveItemIcon={props.hideRemoveItemIcon}
                        onMultiSelectRemoved={onRemove}
                        onClearSelectedValues={onClear}
                        errors={props.formValidation.errors}
                        name={props.name}
                        ariaLabel={props.title}
                        ariaRequired
                    />
                    {props.formValidation.errors[props.name] && (
                        <ValidationMessage message={props.formValidation.errors[props.name].message} />
                    )}
                </Fragment>
            )}
            rules={props.validationRules}
            defaultValue={multiSelect ? currentValues : currentValue}
        />
    );
}

ValidationDropdown.propTypes = {
    formValidation: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    validationRules: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.any,
    options: PropTypes.array.isRequired,
    title: PropTypes.string,
    multiSelect: PropTypes.bool,
    isSearchable: PropTypes.bool,
    selectedValue: PropTypes.any,
    selectedValues: PropTypes.array,
    hideRemoveItemIcon: PropTypes.bool,
    onMultiSelectRemoved: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.multiSelect),
    onClearSelectedValues: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.multiSelect),
};

export default ValidationDropdown;
