const CardProviderStatus = {
    Idle: 'Idle',
    Sync: 'Sync',
    Active: 'Active',
    NotFound: 'NotFound',
    Inactive: 'Inactive',
    Failed: 'Failed',
};

export default CardProviderStatus;
