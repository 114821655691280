import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DiaryContext from '../../contexts/DiaryContext';
import StripeService from '../../services/StripeService';
import { Button, Tooltip } from 'reactstrap';
import VoucherPreviewCard from './VoucherPreviewCard';
import VouchersConnectPanel from './VouchersConnectPanel';
import VouchersConfirmationPanel from './VouchersConfirmationPanel';
import { ReactComponent as LeftArrow } from '../../content/icons/arrow-left.svg';
import { ReactComponent as RightArrow } from '../../content/icons/arrow-right.svg';
import useTrackPage from '../../hooks/useTrackPage';
import { ReactComponent as EditIcon } from '../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../content/icons/Icon-Delete.svg';
import { ReactComponent as AlertIcon } from '../../content/icons/Icon-Alert.svg';
import SettingsHelper from '../../helpers/SettingsHelper';
import DeletionModal from '../common/DeletionModal';
import { useNavigate } from 'react-router-dom';

function VouchersReviewPage(props) {
    const { code, state, addSuccessBar, isConnectedToStripe, setIsConnectedToStripe } = props;
    const intl = useIntl();
    const [isUpdatingStripeConnectStatus, setIsUpdatingStripeConnectStatus] = useState(false);
    const [stripeConnectUrl, setStripeConnectUrl] = useState(null);
    const [isFetchingStripeConnectUrl, setIsFetchingStripeConnectUrl] = useState(false);
    const diaryContext = useContext(DiaryContext);
    const [voucherCardIndex, setVoucherCardIndex] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
    const [voucherToDelete, setVoucherToDelete] = useState(null);
    const navigate = useNavigate();

    const toggleToolTip = () => setTooltipOpen(!tooltipOpen);

    useTrackPage('VouchersReview page');

    useEffect(() => {
        if (stripeConnectUrl === null && !isFetchingStripeConnectUrl) {
            setIsFetchingStripeConnectUrl(true);
            let redirectUrl = `${window.location.origin}/Vouchers`;
            StripeService.getConnectWithStripeUrl(diaryContext.deploymentId, diaryContext.restaurantId, redirectUrl)
                .then((url) => {
                    setStripeConnectUrl(url);
                })
                .finally(() => {
                    setIsFetchingStripeConnectUrl(false);
                });
        }
    }, [stripeConnectUrl, diaryContext.deploymentId, diaryContext.restaurantId, isFetchingStripeConnectUrl]);

    useEffect(() => {
        if (isConnectedToStripe !== null && !isConnectedToStripe && !isUpdatingStripeConnectStatus && code && state) {
            navigate('/Vouchers', { replace: true });
            setIsUpdatingStripeConnectStatus(true);
            StripeService.setUpStripeConnectForProvider(diaryContext.deploymentId, diaryContext.restaurantId, code)
                .then(() => {
                    setIsConnectedToStripe(true);
                    addSuccessBar(
                        intl.formatMessage({
                            id: 'Settings.ConnectedToStripe',
                        })
                    );
                })
                .finally(() => {
                    setIsUpdatingStripeConnectStatus(false);
                });
        }
    }, [
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        code,
        state,
        addSuccessBar,
        isConnectedToStripe,
        intl,
        isUpdatingStripeConnectStatus,
        navigate,
        setIsConnectedToStripe,
    ]);

    function updateVoucher(voucherId) {
        props.setVoucherBeingUpdated(voucherId);
    }

    function deleteVoucher() {
        props.deleteVoucher(voucherToDelete.id, removeVoucherCallback);
    }

    function decreaseVoucherDisplayIndex() {
        if (voucherCardIndex > 0) {
            setVoucherCardIndex(voucherCardIndex - 1);
        }
    }

    function getMobileDisplay() {
        let voucher = props.vouchers[voucherCardIndex];

        if (voucher) {
            return (
                <VoucherPreviewCard
                    key={voucher.uniqueIdentifier}
                    voucher={voucher}
                    updateVoucher={updateVoucher}
                    deleteVoucher={deleteVoucher}
                />
            );
        }
    }

    function getButtonDisplay() {
        let voucher = props.vouchers[voucherCardIndex];

        if (voucher) {
            return (
                <Fragment>
                    <div className="action-buttons">
                        <Button onClick={() => updateVoucher(voucher.id)}>{<EditIcon />}</Button>
                        <Button id={'deleteVoucher' + voucher.id} onClick={() => deleteVoucher(voucher.id)}>
                            <DeleteIcon />
                        </Button>
                    </div>
                    <div className="button-navigator">
                        <Button
                            disabled={voucherCardIndex === 0}
                            onClick={() => setVoucherCardIndex(voucherCardIndex - 1)}
                        >
                            <LeftArrow />
                        </Button>
                        <div className="button-navigation-label">{`${voucherCardIndex + 1}/${
                            props.vouchers.length
                        }`}</div>
                        <Button
                            disabled={voucherCardIndex === props.vouchers.length - 1}
                            onClick={() => setVoucherCardIndex(voucherCardIndex + 1)}
                        >
                            <RightArrow />
                        </Button>
                    </div>
                </Fragment>
            );
        }
    }

    function openVouchersPreviewSite() {
        window.open(SettingsHelper.getVouchersPortalPreviewUrl(diaryContext.micrositeName), '_blank');
    }

    function getPreviewButton() {
        if (isConnectedToStripe) {
            return (
                <Button className="btn-outline-primary" onClick={() => openVouchersPreviewSite()}>
                    <FormattedMessage id="Settings.PreviewLiveVouchersButtonText" />
                </Button>
            );
        }

        return (
            <span id="preview-button">
                <Button
                    className="btn-outline-primary preview-button-disabled"
                    disabled
                    onClick={() => openVouchersPreviewSite()}
                >
                    <div className={props.isMobileView ? 'button-content mob' : 'button-content'}>
                        <div className="preview-label">
                            <FormattedMessage id="Settings.PreviewLiveVouchersButtonText" />
                        </div>
                        <AlertIcon />
                    </div>
                </Button>
                <Tooltip
                    innerClassName="tooltip-style"
                    placement="bottom"
                    isOpen={tooltipOpen}
                    target="preview-button"
                    toggle={toggleToolTip}
                >
                    <FormattedMessage id="Vouchers.StripeConnectPreviewVouchersTooltipText" />
                </Tooltip>
            </span>
        );
    }

    function removeVoucherCallback() {
        setIsDeletionModalOpen(false);
        if (props.isMobileView) {
            decreaseVoucherDisplayIndex();
        }
    }

    function openDeletionModal(voucher) {
        setVoucherToDelete(voucher);
        setIsDeletionModalOpen(true);
    }

    function getDeletionModalTitle() {
        return intl.formatMessage(
            {
                id: 'Vouchers.VoucherDeletionConfirmation',
            },
            { voucherTitle: `${voucherToDelete ? voucherToDelete.title : ''}` }
        );
    }

    return (
        <div className={props.isMobileView ? 'vouchers-review-container mob' : 'vouchers-review-container'}>
            <div className="column vouchers">
                <div className="voucher-entry-list ">
                    {!props.isMobileView && (
                        <div className="font-weight-bold mb-2">
                            <FormattedMessage
                                id="Settings.RestaurantsVouchers"
                                values={{
                                    restaurantName: diaryContext.restaurantName,
                                }}
                            />
                        </div>
                    )}
                    <div className="add-preview-button-wrapper">
                        <Button className="btn-dashed" color="default" onClick={() => props.toggleAddNewVoucher()}>
                            <FormattedMessage id="Vouchers.AddVoucher" />
                        </Button>
                        {getPreviewButton()}
                    </div>

                    {props.isMobileView && props.vouchers.length > 0 && <div>{getMobileDisplay()}</div>}

                    {!props.isMobileView && (
                        <div className={props.isMobileView ? 'vouchers-scroll mob' : 'vouchers-scroll'}>
                            {props.vouchers.map((voucher) => {
                                return (
                                    <VoucherPreviewCard
                                        key={voucher.uniqueIdentifier}
                                        voucher={voucher}
                                        updateVoucher={updateVoucher}
                                        openDeletionModal={openDeletionModal}
                                        displayActionButtons={!props.isMobileView}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>

                {props.isMobileView && props.vouchers.length > 0 && (
                    <div className="vouchers-mobile-navigation">{getButtonDisplay()}</div>
                )}
            </div>
            <div className={props.isMobileView ? 'column stripe mob' : 'column stripe'}>
                {!props.isConnectedToStripe ? (
                    <VouchersConnectPanel
                        isUpdatingStripeConnectStatus={isUpdatingStripeConnectStatus}
                        stripeConnectUrl={stripeConnectUrl}
                    />
                ) : (
                    <VouchersConfirmationPanel
                        isUpdatingStripeConnectStatus={isUpdatingStripeConnectStatus}
                        addErrorBar={props.addErrorBar}
                        toggleAddon={props.toggleAddon}
                        vouchers={props.vouchers}
                    />
                )}
            </div>
            <DeletionModal
                isModalOpen={isDeletionModalOpen}
                setIsModalOpen={setIsDeletionModalOpen}
                deleteItem={deleteVoucher}
                title={getDeletionModalTitle()}
            />
        </div>
    );
}

VouchersReviewPage.propTypes = {
    vouchers: PropTypes.array.isRequired,
    toggleAddNewVoucher: PropTypes.func.isRequired,
    deleteVoucher: PropTypes.func.isRequired,
    setVoucherBeingUpdated: PropTypes.func.isRequired,
    setIsConnectedToStripe: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    isConnectedToStripe: PropTypes.bool,
};

export default VouchersReviewPage;
