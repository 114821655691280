import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableHeadingOption from '../common/TableHeadingOption';
import TableHeader from '../common/TableHeader';
import voucherSoldTableColumns from '../../enums/voucherSoldTableColumns';
import { useIntl, FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';
import { ReactComponent as CopyIcon } from '../../content/icons/Icon-Copy.svg';
import CurrencyDiv from '../common/CurrencyDiv';
import useScreenSize from '../../hooks/useScreenSize';
import { ReactComponent as ConfirmIcon } from '../../content/icons/confirm.svg';

function VoucherGenerateModalSoldTable(props) {
    const intl = useIntl();
    const { isMobileView } = useScreenSize();
    const [showBanner, setShowBanner] = useState(true);
    const [totalVouchersSold, setTotalVouchersSold] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setShowBanner(false);
        }, 4000);
    }, []);

    useEffect(() => {
        if (props.soldVouchers.length > 0) {
            let totalVouchers = 0;
            props.soldVouchers.forEach((soldVoucher) => {
                soldVoucher.VouchersSold.forEach((vs) => {
                    totalVouchers = totalVouchers + vs.Quantity;
                });
            });
            setTotalVouchersSold(totalVouchers);
        }
    }, [props.soldVouchers]);

    function getColumnHeadings() {
        return getHeadingOptions().map((headingOption, index) => {
            return (
                <TableHeader
                    index={index}
                    sortable={false}
                    key={headingOption.heading}
                    heading={headingOption.heading}
                />
            );
        });
    }

    function copyToClipboard(voucherRef) {
        let range = document.createRange();
        range.selectNode(document.getElementById('voucher-ref-' + voucherRef));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();

        props.addSuccessBar(
            intl.formatMessage({
                id: 'Settings.CopiedToClipboard',
            })
        );
    }

    function getHeadingOptions() {
        if (isMobileView) {
            return [];
        }

        return [
            new TableHeadingOption(
                false,
                intl.formatMessage({ id: 'Common.Voucher' }),
                voucherSoldTableColumns.VoucherName
            ),
            new TableHeadingOption(
                false,
                intl.formatMessage({ id: 'Vouchers.RecipientName' }),
                voucherSoldTableColumns.RecipientName
            ),
            new TableHeadingOption(
                false,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnVoucherRef' }),
                voucherSoldTableColumns.RecipientEmail
            ),
        ];
    }

    function getVoucherEntries() {
        return props.soldVouchers.map((voucher, voucherIndex) => {
            return (
                <Fragment key={voucherIndex}>
                    {voucher.VouchersSold.map((vs, vsIndex) => {
                        return (
                            <Fragment key={vsIndex}>
                                {vs.VoucherReferences.map((voucherRef) => {
                                    if (isMobileView) {
                                        return (
                                            <tr key={voucherRef}>
                                                <td>
                                                    <div>
                                                        <div className="bold-text text-larger">
                                                            <CurrencyDiv price={vs.UnitCost} /> {vs.Name}
                                                        </div>
                                                        <div className="bold-text mt-2 upper-case">
                                                            <FormattedMessage id="Vouchers.Recipient" />:
                                                        </div>
                                                        <div className="mt-1">
                                                            {vs.RecipientDetails.Email
                                                                ? `${vs.RecipientDetails.FullName} (${vs.RecipientDetails.Email})`
                                                                : vs.RecipientDetails.FullName}
                                                        </div>
                                                        <div className="bold-text mt-2 upper-case">
                                                            <FormattedMessage id="Vouchers.VoucherCode" />:
                                                        </div>
                                                        <div
                                                            className="generated-voucher-ref clickable mt-1 d-flex"
                                                            onClick={() => copyToClipboard(voucherRef)}
                                                        >
                                                            <div
                                                                id={'voucher-ref-' + voucherRef}
                                                                className="btn-dark flexbox blue-pill mr-1"
                                                            >
                                                                {voucherRef}
                                                            </div>
                                                            <CopyIcon />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return (
                                        <tr className="generated-voucher-ref-row" key={voucherRef}>
                                            <td>
                                                <CurrencyDiv price={vs.UnitCost} /> {vs.Name}
                                            </td>
                                            <td>
                                                {vs.RecipientDetails.Email
                                                    ? `${vs.RecipientDetails.FullName} (${vs.RecipientDetails.Email})`
                                                    : vs.RecipientDetails.FullName}
                                            </td>
                                            <td>
                                                <div
                                                    className="generated-voucher-ref clickable"
                                                    onClick={() => copyToClipboard(voucherRef)}
                                                >
                                                    <div
                                                        id={'voucher-ref-' + voucherRef}
                                                        className="btn-dark flexbox blue-pill mr-1"
                                                    >
                                                        {voucherRef}
                                                    </div>
                                                    <CopyIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </Fragment>
                        );
                    })}
                </Fragment>
            );
        });
    }

    return (
        <Fragment>
            <div>
                <div
                    className={
                        showBanner
                            ? 'generated-voucher-success-banner d-flex'
                            : 'generated-voucher-success-banner hidden'
                    }
                >
                    <ConfirmIcon />
                    {totalVouchersSold === 1 ? (
                        <FormattedMessage id="Vouchers.SingleVoucherGeneratedSuccessfullyMessage" />
                    ) : (
                        <FormattedMessage
                            id="Vouchers.MultipleVouchersGeneratedSuccessfullyMessage"
                            values={{
                                voucherNumber: totalVouchersSold,
                            }}
                        />
                    )}
                </div>
                <Table striped className="sold-voucher-table">
                    <thead>
                        <tr>{getColumnHeadings()}</tr>
                    </thead>
                    <tbody>{getVoucherEntries()}</tbody>
                </Table>
            </div>
        </Fragment>
    );
}

VoucherGenerateModalSoldTable.propTypes = {
    soldVouchers: PropTypes.array.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
};

export default VoucherGenerateModalSoldTable;
