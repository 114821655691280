import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ReactComponent as UpArrowIcon } from '../../content/icons/up-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../content/icons/down-arrow.svg';
import { FormattedMessage } from 'react-intl';
import bookingStatus from '../../enums/bookingStatus';

function RunningOrderTableBody(props) {
    const [showBodyElements, toggleShowBodyElements] = useState(true);
    const toggle = () => toggleShowBodyElements(!showBodyElements);

    function getArrowIcon() {
        return showBodyElements ? (
            <UpArrowIcon data-testid="arrow-up-icon" />
        ) : (
            <DownArrowIcon data-testid="arrow-down-icon" />
        );
    }

    function getTotalCovers(bookings) {
        let covers = 0;
        bookings.forEach((entry) => {
            if (entry.props.booking.status !== bookingStatus.cancelled) {
                covers += entry.props.booking.partySize;
            }
        });

        return covers;
    }

    function getTotalBookings(bookings) {
        return bookings.filter((b) => b.props.booking.status !== bookingStatus.cancelled).length;
    }

    function getBookingsAndCoversLabel() {
        const totalBookings = getTotalBookings(props.tableItems);
        const totalCovers = getTotalCovers(props.tableItems);
        let labelId;
        if (totalBookings !== 1) {
            labelId = 'Bookings.BookingsAndCoversLabel';
        } else {
            if (totalCovers !== 1) {
                labelId = 'Bookings.BookingAndCoversLabel';
            } else {
                labelId = 'Bookings.BookingAndCoverLabel';
            }
        }
        return <FormattedMessage id={labelId} values={{ totalCovers: totalCovers, totalBookings: totalBookings }} />;
    }

    function getVisitTimeIntervals() {
        let times = [];

        props.tableItems.forEach((b) => {
            const time = b.props.booking.visitDateTime.format('HH:mm');
            if (!times.includes(time)) {
                times.push(time);
            }
        });

        return times;
    }

    function getBookingsForVisitTime(visitTime) {
        const bookings = props.tableItems.filter((entry) => {
            return entry.props.booking.visitDateTime.format('HH:mm') === visitTime;
        });

        return bookings;
    }

    function getMaxPerIntervalLabel(visitTime) {
        const bookings = getBookingsForVisitTime(visitTime);

        let labelId;
        let amount;
        let maxPerInterval;
        if (props.service.maxCovers) {
            amount = getTotalCovers(bookings);
            labelId = 'Bookings.MaxPerIntervalCoversLabel';
            maxPerInterval = props.service.maxCovers;
        } else {
            amount = getTotalBookings(bookings);
            labelId = 'Bookings.MaxPerIntervalBookingsLabel';
            maxPerInterval = props.service.maxBookings;
        }

        return (
            <div className={isOverBooked(amount) ? 'overbooked' : ''}>
                <FormattedMessage id={labelId} values={{ amount: amount, maxPerInterval: maxPerInterval }} />
            </div>
        );
    }

    function isOverBooked(amount) {
        return props.service.maxCovers ? amount > props.service.maxCovers : amount > props.service.maxBookings;
    }

    function getBookingsForServiceIntervals(time) {
        return (
            <Fragment key={props.service.id + ' ' + time}>
                <tr className="max-per-interval-row">
                    <td className="row-header" colSpan="100%">
                        <div className="max-per-interval-data">
                            <div className="time-label">{time}</div>
                            {getMaxPerIntervalLabel(time)}
                        </div>
                    </td>
                </tr>

                {getBookingsForVisitTime(time)}
            </Fragment>
        );
    }

    return (
        <Fragment>
            <tbody>
                <tr className="table-section-header-row row-disabled" onClick={toggle}>
                    {/*setting colSpan to something silly so it always takes up the full width*/}
                    <td className="table-section-header" colSpan="100">
                        <div className="service-section">
                            <div className="service-header-name text-uppercase">{props.service.name}</div>

                            <div>{getBookingsAndCoversLabel()}</div>
                        </div>
                        <Button className="section-chevron" data-testid="show-hide-body-arrow-icon">
                            {getArrowIcon()}
                        </Button>
                    </td>
                </tr>
            </tbody>
            <tbody data-testid="table-items-body" className={showBodyElements ? '' : 'hidden'}>
                {getVisitTimeIntervals().map((time) => {
                    return getBookingsForServiceIntervals(time);
                })}
            </tbody>
        </Fragment>
    );
}

RunningOrderTableBody.propTypes = {
    service: PropTypes.object.isRequired,
    tableItems: PropTypes.array.isRequired,
};

export default RunningOrderTableBody;
