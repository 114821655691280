import { useEffect, useContext, useState } from 'react';
import DiaryContext from '../contexts/DiaryContext';
import MomentHelper from '../helpers/MomentHelper';

function useFormattedTime(dateTime) {
    const diaryContext = useContext(DiaryContext);
    const [formattedTime, setFormattedTime] = useState('');

    useEffect(() => {
        function setTimeFormat() {
            if (diaryContext.timeFormat === '24h') {
                setFormattedTime(MomentHelper.formatted24HourTimeFormat(dateTime));
            } else {
                setFormattedTime(MomentHelper.formatted12HourTimeFormat(dateTime));
            }
        }

        setTimeFormat();
    }, [dateTime, diaryContext.timeFormat]);

    return formattedTime;
}

export default useFormattedTime;
