import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Label, FormGroup, Form, Input, Button } from 'reactstrap';
import Section from '../common/Section';
import ValidationInput from '../common/ValidationInput';
import useValidation from '../../hooks/useValidation';
import SettingsService from '../../services/SettingsService';
import RestaurantService from '../../services/RestaurantService';
import DiaryContext from '../../contexts/DiaryContext';
import RestaurantDetails from '../../domainObjects/RestaurantSettings/RestaurantDetails';
import PhoneNumberInput from '../common/PhoneNumberInput';
import ValidationSummary from '../common/ValidationSummary';
import ValidationDropdown from '../common/ValidationDropdown';
import useScreenSize from '../../hooks/useScreenSize';
import ToolTipIcon from '../common/ToolTipIcon';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import WarningLabel from '../common/WarningLabel';
import ConfirmActionButton from '../common/ConfirmActionButton';
import MapContainer from '../common/MapContainer';
import validator from 'validator';

function OnboardingDetailsTab(props) {
    const intl = useIntl();
    const [isSaving, setIsSaving] = useState(false);
    const [venueName, setVenueName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [townOrCity, setTownOrCity] = useState('');
    const [postcode, setPostcode] = useState('');
    const [country, setCountry] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [timeZones, setTimeZones] = useState([]);
    const [isUpdatingGeoLocation, setIsIsUpdatingGeoLocation] = useState(false);
    const [geoLocationTooltipOpen, setGeoLocationTooltipOpen] = useState(false);
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [mapUrl, setMapUrl] = useState('');
    const [initialLongitude, setInitialLongitude] = useState(0);
    const [initialLatitude, setInitialLatitude] = useState(0);
    const [initialMapUrl, setInitialMapUrl] = useState('');
    const [hasLatLong, setHasLatLong] = useState(false);

    const displayClass = props.isActive ? '' : 'hidden';
    const diaryContext = useContext(DiaryContext);
    const { isMobileView, isTabletView } = useScreenSize();
    const [timeZone, setTimeZone] = useState(diaryContext.timeZone);

    const addErrorBar = props.addErrorBar;

    useEffect(() => {
        setVenueName(props.venueName);
    }, [props.venueName]);

    useEffect(() => {
        setAddressLine1(props.addressLine1);
    }, [props.addressLine1]);

    useEffect(() => {
        setAddressLine2(props.addressLine2);
    }, [props.addressLine2]);

    useEffect(() => {
        setTownOrCity(props.townOrCity);
    }, [props.townOrCity]);

    useEffect(() => {
        setPostcode(props.postcode);
    }, [props.postcode]);

    useEffect(() => {
        setCountry(props.country);
    }, [props.country]);

    useEffect(() => {
        setCountryCode(props.countryCode);
    }, [props.countryCode]);

    useEffect(() => {
        setPhoneNumber(props.phoneNumber);
    }, [props.phoneNumber]);

    useEffect(() => {
        setEmail(props.email);
    }, [props.email]);

    useEffect(() => {
        if (props.longitude) {
            setLongitude(props.longitude);
        }
    }, [props.longitude]);

    useEffect(() => {
        if (props.latitude) {
            setLatitude(props.latitude);
        }
    }, [props.latitude]);

    useEffect(() => {
        setMapUrl(props.mapUrl);
    }, [props.mapUrl]);

    useEffect(() => {
        RestaurantService.getSystemTimeZones(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((timeZones) => {
                setTimeZones(timeZones);
            })
            .catch(() => {
                addErrorBar();
            });
    }, [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        if (latitude !== '' && longitude !== '') {
            setHasLatLong(true);
        } else {
            setHasLatLong(false);
        }
    }, [latitude, longitude]);

    const formValidation = useValidation();

    function getDTO() {
        const restaurantSetting = new RestaurantDetails();
        return restaurantSetting.getDTO(
            venueName,
            addressLine1,
            addressLine2,
            townOrCity,
            postcode,
            country,
            props.micrositeName,
            longitude,
            latitude,
            phoneNumber,
            props.siteUrl,
            email,
            countryCode,
            props.isoCurrencySymbol,
            props.websiteUrl,
            timeZone
        );
    }

    function getUserPropertiesForTracking() {
        return {
            venueName: venueName,
            venueAddress: addressLine1,
            venueCountry: country,
            venueLongitude: longitude,
            venueLatitude: latitude,
            venueEmail: email,
        };
    }

    function trackOnboardingDetailsAndMoveTab() {
        AnalyticsHelper.trackClickWithProperties('Web SetUp Details', getDTO());
        AnalyticsHelper.identifyUserIdWithProperties(
            diaryContext.restaurantId,
            getUserPropertiesForTracking(),
            {},
            props.changeToNextTab
        );
    }

    function updateDetails() {
        setIsSaving(true);
        SettingsService.UpdateAddressAndContactDetails(diaryContext.deploymentId, diaryContext.restaurantId, getDTO())
            .then((response) => {
                diaryContext.updateMicrositeName(response.MicrositeName);
                props.addSuccessBar(intl.formatMessage({ id: 'Settings.SuccessfullySavedVenueDetailsMessage' }));
                trackOnboardingDetailsAndMoveTab();
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsSaving(false);
            });
    }

    function toggleGeoLocation() {
        const isToolTipOpen = !geoLocationTooltipOpen;
        setGeoLocationTooltipOpen(isToolTipOpen);
        if (isToolTipOpen) {
            setInitialLongitude(longitude);
            setInitialLatitude(latitude);
            setInitialMapUrl(mapUrl);
            updateGeoLocationData();
        } else {
            setLongitude(initialLongitude);
            setLatitude(initialLatitude);
            setMapUrl(initialMapUrl);
        }
    }

    function updateGeoLocationData() {
        setIsIsUpdatingGeoLocation(true);
        SettingsService.getGeoLocationData(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            getAddressDto(),
            townOrCity,
            country,
            postcode
        )
            .then((response) => {
                setLatitude(response.Latitude);
                setLongitude(response.Longitude);
                setMapUrl(response.MapUrl);
                setIsIsUpdatingGeoLocation(false);
            })
            .catch(() => {
                props.addErrorBar();
                setIsIsUpdatingGeoLocation(false);
            });
    }

    function getAddressDto() {
        let address = addressLine1;
        if (addressLine2) {
            address = `${address}, ${addressLine2}`;
        }
        return address;
    }

    function getCountryCodeOptions() {
        return props.countryCodes.map(
            (countryCode) => new Option(`${countryCode.Name}: +${countryCode.PhoneCode.toString()}`, countryCode.Id)
        );
    }

    function getCountryCodeId() {
        if (props.isLoading) {
            return 0;
        }
        let id =
            countryCode && props.countryCodes
                ? props.countryCodes.find((cc) => cc.PhoneCode === parseInt(countryCode)).Id
                : 0;
        return id.toString();
    }

    function onCountryCodeChange(value) {
        value = props.countryCodes.find((cc) => cc.Id === parseInt(value)).PhoneCode;
        setCountryCode(value);
    }

    function getTimezoneOptions() {
        return timeZones.map((t) => new Option(t.Name, t.Value));
    }

    function getGeoLocationDisabled() {
        if (isUpdatingGeoLocation || postcode === '') {
            return true;
        }
        return false;
    }

    function onDragEnd(event) {
        setLatitude(event.latLng.lat);
        setLongitude(event.latLng.lng);
    }

    function checkValidEmail(email) {
        if (email && !validator.isEmail(email)) {
            return intl.formatMessage({ id: 'Customer.EmailValidation' });
        }
    }

    function getPageBody() {
        if (isTabletView) {
            return getPageContent();
        }

        return <Section sectionTitle={intl.formatMessage({ id: 'Settings.MyDetails' })}>{getPageContent()}</Section>;
    }

    function getPageContent() {
        return (
            <div className="collapsible-panel-body">
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        formValidation.submit(updateDetails);
                    }}
                >
                    <div className="font-italic pb-2">
                        <FormattedMessage id="Settings.RequiredInformation" />
                    </div>

                    <FormGroup className="details-tab">
                        <div className="content-container border-top-0">
                            <h6>
                                <FormattedMessage id="Settings.RestaurantDetails" />
                            </h6>

                            <Label for="venueName" id="venueName">
                                <FormattedMessage id="Settings.VenueName" />
                            </Label>
                            <ValidationInput
                                testId="venueName"
                                type="text"
                                name="venueName"
                                value={venueName}
                                onChange={(e) => setVenueName(e)}
                                innerRef={formValidation.register({
                                    required: intl.formatMessage({ id: 'Settings.VenueNameRequired' }),
                                })}
                                errors={formValidation.errors}
                                maxLength="50"
                                ariaRequired
                                ariaLabelledBy="venueName"
                            />
                            <Label for="venueEmail" id="venueEmail">
                                <FormattedMessage id="Settings.VenueEmailAddress" />
                            </Label>
                            <ToolTipIcon
                                innerClassName={'tooltip-inner field-inner-tooltip'}
                                id={'venue-email-tooltip'}
                            >
                                <p>
                                    <FormattedMessage id="Settings.VenueEmailTooltipText" />
                                </p>
                            </ToolTipIcon>
                            <ValidationInput
                                type="text"
                                name="venueEmail"
                                value={email}
                                onChange={(e) => setEmail(e)}
                                innerRef={formValidation.register({
                                    required: intl.formatMessage({ id: 'Settings.VenueEmailRequired' }),
                                    validate: (value) => checkValidEmail(value),
                                })}
                                errors={formValidation.errors}
                                maxLength="100"
                                ariaRequired
                                ariaLabelledBy="venueEmail"
                            />
                            <PhoneNumberInput
                                label={intl.formatMessage({ id: 'Settings.VenuePhoneNumber' })}
                                id="venuePhoneNumber"
                                optionGroups={getCountryCodeOptions()}
                                onChange={setPhoneNumber}
                                selectedValue={getCountryCodeId(countryCode)}
                                inputValue={phoneNumber}
                                onCountryCodeChange={(e) => onCountryCodeChange(e)}
                                name="phoneNumber"
                                errors={formValidation.errors}
                                innerRef={formValidation.register({
                                    required: intl.formatMessage({ id: 'Settings.VenuePhoneNumberRequired' }),
                                })}
                                includeValidation
                                inputClassName={!isMobileView ? 'phone-validation-input' : ''}
                            />
                            <ValidationDropdown
                                formValidation={formValidation}
                                name="timeZone"
                                validationRules={{
                                    validate: (value) => {
                                        if (!value || !value.length)
                                            return intl.formatMessage({ id: 'Settings.TimeZoneRequired' });
                                    },
                                }}
                                isSearchable
                                onChange={setTimeZone}
                                defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                                options={getTimezoneOptions()}
                                selectedValue={timeZone}
                                title={intl.formatMessage({ id: 'Settings.TimeZone' })}
                            />
                        </div>

                        <div className="content-container mt-3">
                            <h6>
                                <FormattedMessage id="Settings.RestaurantAddress" />
                            </h6>
                            <Label for="addressLine1" id="addressLine1">
                                <FormattedMessage id="Settings.AddressLineOne" />
                            </Label>
                            <ValidationInput
                                testId="addressLine1"
                                type="text"
                                name="addressLine1"
                                value={addressLine1}
                                onChange={(e) => setAddressLine1(e)}
                                innerRef={formValidation.register({
                                    required: intl.formatMessage({ id: 'Settings.AddressLineOneRequired' }),
                                })}
                                errors={formValidation.errors}
                                maxLength="50"
                                ariaRequired
                                ariaLabelledBy="addressLine1"
                            />
                            <Label for="addressLine2" id="addressLine2">
                                <FormattedMessage id="Settings.AddressLineTwo" />
                            </Label>
                            <Input
                                type="text"
                                name="addressLine2"
                                value={addressLine2}
                                onChange={(e) => setAddressLine2(e.target.value)}
                                maxLength="50"
                                ariaLabelledBy={'addressLine2'}
                            />
                            <Label for="townOrCity" id="townOrCity">
                                <FormattedMessage id="Settings.TownOrCity" />
                            </Label>
                            <ValidationInput
                                testId="townOrCity"
                                type="text"
                                name="townOrCity"
                                value={townOrCity}
                                onChange={(e) => setTownOrCity(e)}
                                innerRef={formValidation.register({
                                    required: intl.formatMessage({ id: 'Settings.TownOrCityRequired' }),
                                })}
                                errors={formValidation.errors}
                                maxLength="50"
                                ariaRequired
                                ariaLabelledBy="townOrCity"
                            />
                            <Label for="postcode" id="postcode">
                                <FormattedMessage id="Settings.Postcode" />
                            </Label>
                            <ValidationInput
                                testId="postcode"
                                type="text"
                                name="postcode"
                                value={postcode}
                                onChange={(e) => setPostcode(e)}
                                innerRef={formValidation.register({
                                    required: intl.formatMessage({ id: 'Settings.PostcodeRequired' }),
                                })}
                                errors={formValidation.errors}
                                maxLength="20"
                                ariaRequired
                                ariaLabelledBy="postcode"
                            />

                            <ConfirmActionButton
                                id="geoLocation"
                                buttonColor="primary"
                                buttonClassName="mt-3"
                                tooltipPlacement="top"
                                tooltipOpen={geoLocationTooltipOpen}
                                toggle={toggleGeoLocation}
                                disabled={getGeoLocationDisabled()}
                                outline
                                includeCloseIcon
                                label={intl.formatMessage({ id: 'Settings.UpdateGeolocation' })}
                            >
                                <p>
                                    <FormattedMessage id="Settings.GeoLocationTooltipTitle" />
                                </p>
                                <WarningLabel
                                    label={intl.formatMessage({ id: 'Settings.GeoLocationTooltipWarningLabel' })}
                                />
                                <hr className="full-width-line"></hr>
                                <div className="text-right">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                            setGeoLocationTooltipOpen(false);
                                        }}
                                    >
                                        <FormattedMessage id="Common.Confirm" />
                                    </Button>
                                </div>
                            </ConfirmActionButton>
                            {hasLatLong && (
                                <MapContainer latitude={latitude} longitude={longitude} onDragEnd={onDragEnd} />
                            )}
                        </div>
                    </FormGroup>
                    <div className="panel-footer">
                        <input
                            disabled={isSaving || formValidation.submitDisabled || !hasLatLong}
                            type="submit"
                            value={intl.formatMessage({
                                id: 'Common.SaveAndContinue',
                            })}
                            className="btn btn-primary"
                        />
                        <ValidationSummary
                            errorCount={formValidation.errorCount}
                            submitDisabled={formValidation.submitDisabled}
                        />
                    </div>
                </Form>
            </div>
        );
    }

    return <div className={displayClass}>{getPageBody()}</div>;
}

OnboardingDetailsTab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    changeToNextTab: PropTypes.func.isRequired,
    venueName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    townOrCity: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    micrositeName: PropTypes.string,
    phoneNumber: PropTypes.string,
    siteUrl: PropTypes.string,
    email: PropTypes.string,
    countryCode: PropTypes.string,
    isoCurrencySymbol: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default OnboardingDetailsTab;
