import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class CountryService {
    static getCountryCodes() {
        return ApiHelper.get(`${SettingsHelper.getApiUrl()}/api/Tablet/v1/Country/CountryCodes`).then(
            (response) => response.data
        );
    }
}

export default CountryService;
