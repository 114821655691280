import ApiHelper from '../helpers/ApiHelper';

class ChargeBeeService {
    static getPlanInformation(request) {
        return ApiHelper.postJSON(`/api/ChargeBee/GetPlanInformation`, JSON.stringify(request)).then(
            (response) => response.data
        );
    }
    static getAvailablePlans(request) {
        return ApiHelper.postJSON(`/api/ChargeBee/GetAvailablePlans`, JSON.stringify(request)).then(
            (response) => response.data
        );
    }
    static checkout(request) {
        return ApiHelper.postJSON('/api/ChargeBee/checkoutSubscription', JSON.stringify(request)).then(
            (response) => response.data
        );
    }
}

export default ChargeBeeService;
