import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import TableHeader from './TableHeader';
import Paginator from './Paginator';
import TableHeadingOption from './TableHeadingOption';
import LoadingSpinner from './LoadingSpinner';
import { ReactComponent as NoBookingsIcon } from '../../content/images/rd-lite-no-bookings.svg';
import { FormattedMessage } from 'react-intl';

function PagedTable(props) {
    function getColumnHeadings() {
        return props.headings.map((headingOption, index) => {
            if (headingOption.sortable && headingOption.sortable === true) {
                return (
                    <TableHeader
                        index={index}
                        sortable={true}
                        key={headingOption.heading}
                        heading={headingOption.heading}
                        sortOrder={headingOption.sortOrder}
                        onSorting={props.onSorting}
                        currentSortOrder={props.sortOrder}
                        sortAscending={props.sortAscending}
                    />
                );
            } else {
                return (
                    <TableHeader
                        index={index}
                        sortable={false}
                        key={headingOption.heading}
                        heading={headingOption.heading}
                    />
                );
            }
        });
    }

    function getNumberOfPages() {
        return Math.ceil(props.totalItemCount / props.pageSize);
    }

    function getClassNames() {
        return props.classNames;
    }

    function getBody() {
        if (props.showSpinner) {
            return <LoadingSpinner bottomPadding />;
        }
        return (
            <Fragment>
                {props.tableItems.length > 0 ? (
                    <Fragment>
                        <div className="report-table">
                            <Table striped className={getClassNames()}>
                                <thead>
                                    <tr>{getColumnHeadings()}</tr>
                                </thead>
                                <tbody>{props.tableItems}</tbody>
                            </Table>
                            <Paginator
                                numberOfPages={getNumberOfPages()}
                                onSelectPage={props.onSelectPage}
                                selectedPage={props.selectedPage}
                                onPageSizeChange={props.onPageSizeChange}
                                pageSize={props.pageSize}
                                totalItemCount={props.totalItemCount}
                                showPageSizeSelector={props.showPageSizeSelector}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="report-empty-state">
                            <NoBookingsIcon />
                            <div>
                                <FormattedMessage
                                    id={!props.emptyTableText ? 'Common.NoResults' : props.emptyTableText}
                                />
                            </div>
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }

    return <Fragment>{getBody()}</Fragment>;
}

PagedTable.propTypes = {
    onSorting: PropTypes.func.isRequired,
    onSelectPage: PropTypes.func.isRequired,
    sortOrder: PropTypes.number.isRequired,
    sortAscending: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    selectedPage: PropTypes.number.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    headings: PropTypes.arrayOf(PropTypes.instanceOf(TableHeadingOption)).isRequired,
    tableItems: PropTypes.array.isRequired,
    totalItemCount: PropTypes.number.isRequired,
    showSpinner: PropTypes.bool,
    showPageSizeSelector: PropTypes.bool,
    classNames: PropTypes.array,
    emptyTableText: PropTypes.string,
};

export default PagedTable;
