import MomentHelper from './MomentHelper';
import localStorageItem from '../enums/localStorageItem';

class SessionHelper {
    static getTokenInfo() {
        const value = localStorage.getItem(localStorageItem.tokenInfo);
        if (value != null) {
            const tokenInfo = JSON.parse(value);
            const tokenExpiry = MomentHelper.newInstanceUtc(tokenInfo.tokenExpiryUtc);
            if (tokenExpiry.isBefore(MomentHelper.newInstanceUtc())) {
                localStorage.removeItem(localStorageItem.tokenInfo);
                return null;
            } else {
                return tokenInfo;
            }
        }
        return null;
    }

    static setProviderInfo(deploymentId, providerId) {
        localStorage.setItem(localStorageItem.deploymentId, deploymentId);
        localStorage.setItem(localStorageItem.providerId, providerId);
    }

    static getProviderInfo() {
        const providerId = localStorage.getItem(localStorageItem.providerId);
        const deploymentId = localStorage.getItem(localStorageItem.deploymentId);
        if (providerId !== null && deploymentId !== null) {
            return { providerId, deploymentId };
        } else {
            return null;
        }
    }
}

export default SessionHelper;
