import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class ImageService {
    static uploadRestaurantImages(deploymentId, restaurantId, data, setUploadProgress) {
        return ApiHelper.postImage(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Images/UploadAdditionalImages`,
            data,
            setUploadProgress
        ).then((response) => response.data);
    }

    static getImagesForProvider(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Images/GetMicrositeImagesForProvider`
        ).then((response) => response.data);
    }

    static deleteImages(deploymentId, restaurantId, imageIds) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Images/Delete`,
            imageIds
        ).then((response) => response.data);
    }

    static setImageAsMainImage(deploymentId, restaurantId, imageId) {
        return ApiHelper.put(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Images/SetAsMainImage?imageId=${imageId}`
        ).then((response) => response.data);
    }

    static setImagesEnabled(deploymentId, restaurantId, imageIds) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Images/Enable`,
            imageIds
        ).then((response) => response.data);
    }

    static setImagesDisabled(deploymentId, restaurantId, imageIds) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Images/Disable`,
            imageIds
        ).then((response) => response.data);
    }
}

export default ImageService;
