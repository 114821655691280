import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ServiceEditPanel from './ServiceEditPanel';
import ServiceEntry from './ServiceEntry';
import { useIntl } from 'react-intl';
import ServiceUpdateModal from './ServiceUpdateModal';
import ServiceUpdateModalType from '../../../enums/serviceUpdateModalType';

function ServiceEntryWrapper(props) {
    const intl = useIntl();
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [serviceToBeUpdated, setServiceToBeUpdated] = useState(null);
    const [updateType, setUpdateType] = useState(null);

    const toggleEditing = () => {
        setIsEditing(!isEditing);
    };

    const finishedUpdating = () => {
        setIsModalOpen(false);
        setIsEditing(false);
    };

    function openModalWithUpdatedService(service) {
        setServiceToBeUpdated(service);
        setIsModalOpen(true);
    }

    function setUpdateTypeExternal(updateType) {
        setUpdateType(updateType);
    }

    function updateServiceWithModal(failureCallback) {
        switch (updateType) {
            case ServiceUpdateModalType.update:
                props.updateService(serviceToBeUpdated, finishedUpdating, failureCallback);
                break;
            case ServiceUpdateModalType.delete:
                props.deleteService(serviceToBeUpdated.id, finishedUpdating, failureCallback);
                break;
            case ServiceUpdateModalType.disable:
                props.updateServiceEnabledService(serviceToBeUpdated.id, finishedUpdating, failureCallback);
                break;
            default:
                break;
        }
    }

    function updateService(serviceToBeUpdated) {
        props.updateService(serviceToBeUpdated, finishedUpdating);
    }

    function getDeletionModalTitle() {
        return intl.formatMessage(
            {
                id: 'Settings.DeleteModalGenericTitle',
            },
            { itemTitle: `${serviceToBeUpdated ? serviceToBeUpdated.name : ''}` }
        );
    }

    return (
        <Fragment>
            <div>
                {isEditing && (
                    <ServiceEditPanel
                        key={props.service.id}
                        service={props.service}
                        toggleEditing={toggleEditing}
                        doesServiceTimeOverLap={props.doesServiceTimeOverLap}
                        openModalWithUpdatedService={openModalWithUpdatedService}
                        isEditing={isEditing}
                        isInitialSetup={props.isInitialSetup}
                        updateService={updateService}
                        setUpdateType={setUpdateTypeExternal}
                    />
                )}

                {!isEditing && (
                    <ServiceEntry
                        key={props.service.id}
                        service={props.service}
                        toggleEditing={toggleEditing}
                        deleteService={props.deleteService}
                        updateServiceEnabledStatus={props.updateServiceEnabledStatus}
                        addSuccessBar={props.addSuccessBar}
                        addErrorBar={props.addErrorBar}
                        isInitialSetup={props.isInitialSetup}
                        setUpdateType={setUpdateTypeExternal}
                        openModalWithUpdatedService={openModalWithUpdatedService}
                        updateServiceEnabledService={props.updateServiceEnabledService}
                        awaitingIsEnabledRequest={props.awaitingIsEnabledRequest}
                    />
                )}

                {isModalOpen && (
                    <ServiceUpdateModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        updateService={updateServiceWithModal}
                        updateType={updateType}
                        deletionModalTitle={getDeletionModalTitle()}
                    />
                )}
            </div>
        </Fragment>
    );
}

ServiceEntryWrapper.propTypes = {
    service: PropTypes.object.isRequired,
    doesServiceTimeOverLap: PropTypes.func,
    updateService: PropTypes.func.isRequired,
    deleteService: PropTypes.func.isRequired,
    updateServiceEnabledService: PropTypes.func.isRequired,
    updateServiceEnabledStatus: PropTypes.func.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    isInitialSetup: PropTypes.bool,
    awaitingIsEnabledRequest: PropTypes.bool.isRequired,
};

export default ServiceEntryWrapper;
