import React, { useState, useContext, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Label, Button, ModalFooter } from 'reactstrap';
import NumericInputField from '../common/NumericInputField';
import DiaryContext from '../../contexts/DiaryContext';
import VoucherService from '../../services/VoucherService';
import PropTypes from 'prop-types';
import RDLModal from '../common/RDLModal';
import ValidationInput from '../common/ValidationInput';
import ValidationDropdown from '../common/ValidationDropdown';
import useScreenSize from '../../hooks/useScreenSize';
import useValidation from '../../hooks/useValidation';
import FooterPreviewModal from '../common/FooterPreviewModal';
import VoucherGenerateModalReviewTable from './VoucherGenerateModalReviewTable';
import VoucherGenerateModalSoldTable from './VoucherGenerateModalSoldTable';
import CurrencyDiv from '../common/CurrencyDiv';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';

function VouchersGenerateModal(props) {
    const intl = useIntl();
    const [vouchersToGenerate, setVouchersToGenerate] = useState([
        {
            id: props.defaultVoucherId ? props.defaultVoucherId : null,
            quantity: 1,
            receipientName: '',
            receipientEmail: '',
        },
    ]);
    const [isReviewingVouchers, setIsReviewingVouchers] = useState(false);
    const [soldVouchers, setSoldVouchers] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const diaryContext = useContext(DiaryContext);
    const { isMobileView } = useScreenSize();

    const addErrorBar = props.addErrorBar;
    const formValidation = useValidation();

    function getGenerateVouchersDTO(voucher) {
        return {
            BillingDetails: {
                FirstName: '',
                Surname: voucher.receipientName,
            },
            VouchersOrdered: [
                {
                    VoucherId: voucher.id,
                    Quantity: voucher.quantity,
                    RecipientDetails: {
                        FullName: voucher.receipientName,
                        Email: voucher.receipientEmail,
                    },
                    SendNotificationToRecipient: voucher.receipientEmail ? true : false,
                },
            ],
        };
    }

    function createVoucherOrder(voucher) {
        const dto = getGenerateVouchersDTO(voucher);
        return VoucherService.createInternalVoucherOrder(diaryContext.deploymentId, diaryContext.restaurantId, dto);
    }

    async function generateVouchers() {
        setIsProcessing(true);
        const promises = vouchersToGenerate.map((v) => createVoucherOrder(v));
        try {
            const newSoldVouchers = await Promise.all(promises);
            setSoldVouchers(newSoldVouchers);
            props.refreshVoucherData();
        } catch (e) {
            addErrorBar();
        } finally {
            setIsProcessing(false);
        }
    }

    function getGenerateCodesAnalyticsProperties() {
        let properties = {};
        vouchersToGenerate.forEach((voucher, index) => {
            const voucherObject = props.vouchers.find((v) => v.id === voucher.id);
            properties['Voucher' + (index + 1)] = {
                voucherName: `${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)}${
                    voucherObject.cost
                } ${voucherObject.title}`,
                receipientName: voucher.receipientName,
                subTotal: voucherObject.cost * voucher.quantity,
            };
        });
        return properties;
    }

    function getContinueAnalyticsProperties() {
        let properties = {};
        vouchersToGenerate.forEach((voucher, index) => {
            const voucherObject = props.vouchers.find((v) => v.id === voucher.id);
            properties['Voucher' + (index + 1)] = {
                voucherName: `${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)}${
                    voucherObject.cost
                } ${voucherObject.title}`,
                receipientName: voucher.receipientName,
                voucherQuantity: voucher.quantity,
            };
        });
        return properties;
    }

    function proceedToNextAction() {
        if (!isReviewingVouchers) {
            setIsReviewingVouchers(true);
            AnalyticsHelper.trackClickWithProperties('Web VoucherGenerate Continue', getContinueAnalyticsProperties());
        } else {
            generateVouchers();
            AnalyticsHelper.trackClickWithProperties(
                'Web Vouchers GenerateCode',
                getGenerateCodesAnalyticsProperties()
            );
        }
    }

    function addVoucherToGenerate() {
        let newVouchersToGenerate = [...vouchersToGenerate];
        newVouchersToGenerate.push({ id: null, quantity: 1, receipientName: '', receipientEmail: '' });
        setVouchersToGenerate(newVouchersToGenerate);
    }

    function updateVoucherProperty(index, propertyName, propertyValue) {
        let newVouchersToGenerate = [...vouchersToGenerate];
        newVouchersToGenerate[index][propertyName] = propertyValue;
        setVouchersToGenerate(newVouchersToGenerate);
    }

    function getModalHeader() {
        let modalHeaderId = 'Vouchers.GenerateVouchersCodes';
        if (soldVouchers.length > 0) {
            modalHeaderId = 'Vouchers.VoucherCodes';
        } else {
            modalHeaderId = !isReviewingVouchers ? 'Vouchers.GenerateVouchersCodes' : 'Vouchers.ReviewAndConfirm';
        }

        return (
            <div className="text-uppercase">
                <FormattedMessage id={modalHeaderId} />
            </div>
        );
    }

    function getVoucherOptions() {
        return props.vouchers.map((v) => {
            return {
                text: `${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)}${v.cost} ${v.title}`,
                value: v.id,
            };
        });
    }

    function getVoucherRows() {
        return vouchersToGenerate.map((v, index) => {
            const showLabel = isMobileView || index === 0;
            return (
                <div
                    key={index}
                    className={
                        index === 0
                            ? `d-flex generate-vouchers-row ${isMobileView ? 'mob' : ''}`
                            : `d-flex generate-vouchers-row mt-2 ${isMobileView ? 'mob' : ''}`
                    }
                >
                    <div className="generate-vouchers-column">
                        <ValidationDropdown
                            title={
                                showLabel
                                    ? intl.formatMessage({
                                          id: 'Common.Voucher',
                                      })
                                    : null
                            }
                            formValidation={formValidation}
                            name={`voucher-${index}`}
                            validationRules={{
                                required: intl.formatMessage(
                                    { id: 'Common.GenericRequiredValidationMessage' },
                                    { fieldName: intl.formatMessage({ id: 'Common.Voucher' }) }
                                ),
                            }}
                            onChange={(value) => {
                                updateVoucherProperty(index, 'id', value);
                            }}
                            defaultValue={intl.formatMessage({
                                id: 'Vouchers.SelectVoucher',
                            })}
                            options={getVoucherOptions()}
                            selectedValue={v.id}
                        />
                    </div>
                    <div className="generate-vouchers-column">
                        {showLabel && (
                            <Label for="recipientName">
                                <FormattedMessage id="Vouchers.RecipientName" />
                            </Label>
                        )}
                        <ValidationInput
                            type="text"
                            name={`recipientName-${index}`}
                            value={v.receipientName}
                            onChange={(e) => {
                                updateVoucherProperty(index, 'receipientName', e);
                            }}
                            placeholder={'Name'}
                            innerRef={formValidation.register({
                                required: intl.formatMessage(
                                    { id: 'Common.GenericRequiredValidationMessage' },
                                    { fieldName: intl.formatMessage({ id: 'Vouchers.RecipientName' }) }
                                ),
                            })}
                            errors={formValidation.errors}
                        />
                    </div>
                    <div className="generate-vouchers-column">
                        {showLabel && (
                            <Label for="sendTo">
                                <FormattedMessage id="Vouchers.SendToOptional" />
                            </Label>
                        )}
                        <ValidationInput
                            type="email"
                            name={`email-${index}`}
                            value={v.receipientEmail}
                            onChange={(e) => {
                                updateVoucherProperty(index, 'receipientEmail', e);
                            }}
                            placeholder={intl.formatMessage({
                                id: 'Common.EnterEmailAddress',
                            })}
                            innerRef={formValidation.register({
                                pattern: {
                                    value: /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/gi, // eslint-disable-line no-control-regex
                                    message: intl.formatMessage({
                                        id: 'AccountSubmission.ProvideAValidEmailAddress',
                                    }),
                                },
                            })}
                            errors={formValidation.errors}
                        />
                    </div>
                    <div className={showLabel ? 'numeric-input-wrapper' : ''}>
                        <NumericInputField
                            value={v.quantity}
                            min={1}
                            onChangeHandler={(value) => updateVoucherProperty(index, 'quantity', value)}
                        />
                    </div>
                </div>
            );
        });
    }

    function getModalBody() {
        if (soldVouchers.length > 0) {
            return <VoucherGenerateModalSoldTable soldVouchers={soldVouchers} addSuccessBar={props.addSuccessBar} />;
        }

        if (!isReviewingVouchers) {
            return (
                <div className={isMobileView ? 'rdl-modal mob' : 'rdl-modal'}>
                    <div className="content">
                        {getVoucherRows()}
                        <Button className="btn-dashed m-0 w-100 mt-4" color="default" onClick={addVoucherToGenerate}>
                            <FormattedMessage id="Vouchers.AddVoucher" />
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <VoucherGenerateModalReviewTable vouchersToGenerate={vouchersToGenerate} vouchers={props.vouchers} />
            );
        }
    }

    function getNextActionButtonText() {
        if (!isReviewingVouchers) {
            return intl.formatMessage({ id: 'Common.Continue' });
        }
        return isProcessing
            ? intl.formatMessage({ id: 'Vouchers.GeneratingCodes' })
            : intl.formatMessage({ id: 'Vouchers.GenerateCodes' });
    }

    function getVoucherObject(voucherId) {
        return props.vouchers.find((v) => v.id === voucherId);
    }

    function getCountAndTotal() {
        let itemCount = 0;
        let totalCost = 0;
        vouchersToGenerate.forEach((voucher) => {
            if (voucher.id) {
                const voucherObject = getVoucherObject(voucher.id);
                itemCount = itemCount + voucher.quantity;
                totalCost = totalCost + voucher.quantity * voucherObject.cost;
            }
        });
        return { itemCount, totalCost };
    }

    function getModalFooter() {
        if (soldVouchers.length > 0) {
            return null;
        }
        const { itemCount, totalCost } = getCountAndTotal();
        return (
            <ModalFooter className={isMobileView ? 'mob' : ''}>
                {isReviewingVouchers && (
                    <div>
                        <span className="voucher-footer-items">{itemCount} Items</span>
                        <span className="voucher-footer-total bold-text">
                            Total: <CurrencyDiv price={totalCost} />
                        </span>
                    </div>
                )}
                <div className={isMobileView ? '' : 'ml-auto'}>
                    <Button
                        className="mr-2"
                        outline
                        color="primary"
                        onClick={() => {
                            AnalyticsHelper.trackClick(
                                !isReviewingVouchers ? 'Web VoucherGenerate Cancel' : 'Web VoucherGenerate Back'
                            );
                            !isReviewingVouchers ? props.setIsModalOpen(false) : setIsReviewingVouchers(false);
                        }}
                        disabled={isProcessing}
                    >
                        <FormattedMessage id={!isReviewingVouchers ? 'Common.Cancel' : 'Common.Back'} />
                    </Button>

                    <Button
                        color="primary"
                        onClick={() => formValidation.submit(proceedToNextAction)}
                        disabled={isProcessing || formValidation.submitDisabled}
                    >
                        {getNextActionButtonText()}
                    </Button>
                </div>
            </ModalFooter>
        );
    }

    return (
        <Fragment>
            {!isMobileView && (
                <RDLModal
                    isModalOpen={props.isModalOpen}
                    setIsModalOpen={props.setIsModalOpen}
                    title={getModalHeader()}
                    body={getModalBody()}
                    modalClass={isMobileView ? '' : 'content-class'}
                    modalHeaderClass="modal-header"
                    action={proceedToNextAction}
                    customFooter={getModalFooter()}
                    hideFooter={soldVouchers.length > 0}
                />
            )}

            {isMobileView && (
                <FooterPreviewModal
                    title={getModalHeader()}
                    body={getModalBody()}
                    isModalOpen={props.isModalOpen}
                    setIsModalOpen={props.setIsModalOpen}
                    modalClassName="vouchers-promotion-modal"
                    customFooter={getModalFooter()}
                />
            )}
        </Fragment>
    );
}

VouchersGenerateModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    refreshVoucherData: PropTypes.func.isRequired,
    vouchers: PropTypes.array,
    defaultVoucherId: PropTypes.number,
};

export default VouchersGenerateModal;
