import React from 'react';
import PropTypes from 'prop-types';
import RDLModal from '../common/RDLModal';
import { useIntl } from 'react-intl';
import { ReactComponent as AlertIcon } from '../../content/icons/Icon-Alert.svg';
import TextWithIcon from '../common/TextWithIcon';

function DeletionModal(props) {
    const intl = useIntl();

    function getModalBody() {
        return (
            <div>
                {props.bodyText && (
                    <div className="warning-message-modal-body">
                        <TextWithIcon text={props.bodyText} icon={<AlertIcon />} />
                    </div>
                )}
            </div>
        );
    }

    return (
        <RDLModal
            isModalOpen={props.isModalOpen}
            setIsModalOpen={props.setIsModalOpen}
            action={props.deleteItem}
            title={props.title}
            body={getModalBody()}
            actionButtonText={intl.formatMessage({ id: 'Common.Delete' })}
            dismissActionButtonText={intl.formatMessage({ id: 'Common.Close' })}
            actionButtonPendingText={intl.formatMessage({ id: 'Common.Deleting' })}
        />
    );
}

DeletionModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    bodyText: PropTypes.string,
};

export default DeletionModal;
