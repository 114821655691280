import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

function ListBuilder(props) {
    return (
        <div className="form-panel-body">
            {props.content}

            {!props.isAddingNewItem && (
                <Button
                    className="btn-dashed m-0"
                    color="default"
                    onClick={() => props.toggleAddNewItem()}
                    data-testid={props.buttonTestId}
                >
                    {props.addNewItemButtonText}
                </Button>
            )}
        </div>
    );
}

ListBuilder.propTypes = {
    isAddingNewItem: PropTypes.bool.isRequired,
    toggleAddNewItem: PropTypes.func.isRequired,
    addNewItemButtonText: PropTypes.string,
    content: PropTypes.object.isRequired,
    buttonTestId: PropTypes.string,
};

export default ListBuilder;
