import React from 'react';
import PropTypes from 'prop-types';

function IconBulletPoint(props) {
    return (
        <div className="d-flex bullet-point">
            <div className="mr-2">
                <div className={props.className}>{props.icon}</div>
            </div>
            {props.text}
        </div>
    );
}

IconBulletPoint.propTypes = {
    icon: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default IconBulletPoint;
