import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TypeaheadWrapper from '../common/TypeaheadWrapper';
import CustomerService from '../../services/CustomerService';
import { useIntl } from 'react-intl';

function CustomerTypeahead(props) {
    const { deploymentId, restaurantId } = props;
    const intl = useIntl();

    function getAlternateDisplay(email, phoneNumber, mobileNumber) {
        if (email && phoneNumber && mobileNumber) {
            return email + '/\n' + phoneNumber + '/' + mobileNumber;
        }
        if (!email && (phoneNumber || mobileNumber)) {
            return phoneNumber ? phoneNumber : mobileNumber;
        }
        return email;
    }

    function addNewCustomer(searchTerm) {
        props.addNewFunction(false, searchTerm);
    }

    const processResponse = useCallback((response) => {
        if (response) {
            const results = response.Data.map((item) => ({
                id: item.Id,
                display: item.FirstName + ' ' + item.Surname,
                alternateDisplay: getAlternateDisplay(item.Email, item.PhoneNumber, item.MobileNumber),
            }));
            return results;
        } else {
            return [];
        }
    }, []);

    const makeRequest = useCallback(
        (searchTerm) => {
            return CustomerService.searchCustomers(deploymentId, restaurantId, searchTerm);
        },
        [deploymentId, restaurantId]
    );

    return (
        <TypeaheadWrapper
            typeaheadItemSelected={props.typeaheadItemSelected}
            requestFunctionAsync={makeRequest}
            processResultsFunction={processResponse}
            allowAddNew
            addNewFunction={addNewCustomer}
            addNewTitle={intl.formatMessage({ id: 'Bookings.NewCustomer' })}
            registerValidation={props.registerValidation}
            errors={props.errors}
            includeValidation={props.includeValidation}
            placeholder={intl.formatMessage({ id: 'Customer.CustomerTypeaheadPlaceholder' })}
            validationMessage={intl.formatMessage({ id: 'Customer.PleaseAttachCustomer' })}
            validationName={'customer'}
        />
    );
}

CustomerTypeahead.propTypes = {
    deploymentId: PropTypes.number.isRequired,
    restaurantId: PropTypes.number.isRequired,
    typeaheadItemSelected: PropTypes.func.isRequired,
    addNewFunction: PropTypes.func,
};

export default CustomerTypeahead;
