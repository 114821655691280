import React from 'react';

const AlertContext = React.createContext({
    alerts: [],
    setAlerts: () => {},
    refreshBookings: false,
    setRefreshBookings: () => {},
    updateAlertsAndSetRefreshBookings: () => {},
    currentDate: null,
    setCurrentDate: () => {},
    alertBookingOverlayId: null,
    setAlertBookingOverlayId: () => {},
});

export default AlertContext;
