import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';

function ToolTipIcon(props) {
    return (
        (props.children && (
            <span className={props.className}>
                <InfoIcon id={props.id} className="tooltip-svg-icon" />
                <UncontrolledTooltip
                    placement="top"
                    target={props.id}
                    autohide={false}
                    className="tooltip-box"
                    innerClassName={props.innerClassName ? props.innerClassName : ''}
                >
                    {props.children}
                </UncontrolledTooltip>
            </span>
        )) ||
        null
    );
}

ToolTipIcon.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
};

export default ToolTipIcon;
