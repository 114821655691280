import React, { useContext, Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useScreenSize from '../../../hooks/useScreenSize';
import DiaryContext from '../../../contexts/DiaryContext';
import SidePanelContentLayout from '../../common/SidePanelContentLayout';
import Section from '../../common/Section';
import TextWithIcon from '../../common/TextWithIcon';
import { ReactComponent as GreenTick } from '../../../content/icons/Tick-green.svg';
import { ReactComponent as GreenTickOutline } from '../../../content/icons/Tick-green-outline.svg';
import { ReactComponent as IconInfo } from '../../../content/icons/Icon-Info.svg';
import loyaltyStatus from '../../../enums/loyaltyStatus';
import ToolTipIcon from '../../common/ToolTipIcon';
import SettingsHelper from '../../../helpers/SettingsHelper';
import useTrackPage from '../../../hooks/useTrackPage';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import SessionContext from '../../../contexts/SessionContext';
import infoBarType from '../../../enums/infoBarType';
import SubscriptionStatus from './SubscriptionStatus';
import LoyaltyService from '../../../services/LoyaltyService';
import LoadingSpinner from '../../common/LoadingSpinner';

function LoyaltyDashboard(props) {
    const intl = useIntl();
    const navigate = useNavigate();
    const { isMobileView } = useScreenSize();
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const [infoBars, setInfoBars] = useState([]);
    const [successDisplayed, setSuccessDisplayed] = useState(false);
    const [visaStatus, setVisaStatus] = useState('');
    const [mastercardStatus, setMastercardStatus] = useState('');
    const [amexStatus, setAmexStatus] = useState('');
    const [toggleSubscriptionToolTip, setToggleSubscriptionToolTip] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalBookings, setTotalBookings] = useState(0);
    const [totalTransactions, setTotalTransactions] = useState(0.0);

    const supportEmail = SettingsHelper.getDishCultSupportEmail();
    let _ID = 0;

    function decodeCurrencySymbol(text) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }

    const toggleSubscriptionToolTipOpen = () => {
        setToggleSubscriptionToolTip(!toggleSubscriptionToolTip);
    };

    useEffect(() => {
        const fetchData = async () => {
            const providerRewardsDetails = await LoyaltyService.getProviderRewardsDetails(
                diaryContext.deploymentId,
                diaryContext.restaurantId
            );
            if (providerRewardsDetails) {
                setVisaStatus(providerRewardsDetails.data.VisaStatus);
                setMastercardStatus(providerRewardsDetails.data.MastercardStatus);
                setAmexStatus(providerRewardsDetails.data.AmexStatus);
            }

            const weeklyFigures = await LoyaltyService.getWeeklyFigures(
                diaryContext.deploymentId,
                diaryContext.restaurantId
            );
            if (weeklyFigures) {
                setTotalBookings(weeklyFigures.data.TotalBookings);
                const totalTransactionsResult = weeklyFigures.data.TotalTransactions;
                setTotalTransactions(parseFloat(totalTransactionsResult).toFixed(2));
            }
        };
        if (!isInitialized) {
            setIsLoading(true);
            fetchData().finally(() => {
                setIsLoading(false);
                setIsInitialized(true);
            });
        }
    }, [
        totalBookings,
        totalTransactions,
        isInitialized,
        isLoading,
        diaryContext.deploymentId,
        diaryContext.restaurantId,
    ]);

    function navigateToLoyaltyReport() {
        AnalyticsHelper.trackClickWithProperties('Web Promote DCRewards', {
            providerId: diaryContext.restaurantId,
            userName: sessionContext.userName,
            userId: sessionContext.userId,
        });
        navigate('/Settings/Loyalty/Report');
    }

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    function getBodyTitle() {
        return (
            <div className="dashboard-body-title">
                {diaryContext.loyaltyStatus === loyaltyStatus.active ? (
                    <TextWithIcon
                        icon={<GreenTick />}
                        text={intl.formatMessage({ id: 'Settings.LoyaltySubscriptionActiveLabel' })}
                        textClass="dashboard-body-title-text"
                        iconClass="green-tick"
                    />
                ) : (
                    <TextWithIcon
                        icon={<GreenTickOutline />}
                        text={intl.formatMessage({ id: 'Settings.LoyaltySubscriptionPendingLabel' })}
                        textClass="dashboard-body-title-text"
                        iconClass="green-tick-outline"
                    />
                )}
            </div>
        );
    }

    function getPageContent() {
        return (
            <div className={'loyalty-dashboard-page-body' + (isMobileView ? ' mobile' : '')}>
                <Section sectionTitle={getBodyTitle()}>
                    <div className={'loyalty-dashboard-content-body' + (isMobileView ? ' mobile' : '')}>
                        {diaryContext.loyaltyStatus === loyaltyStatus.syncing && (
                            <div className="loyalty-subscription-pending-notice">
                                <IconInfo />
                                <p className="loyalty-subscription-pending-text">
                                    {intl.formatMessage({ id: 'Settings.SubscriptionIsBeingReviewedText' })}
                                </p>
                            </div>
                        )}
                        {diaryContext.loyaltyStatus === loyaltyStatus.active && (
                            <SubscriptionStatus
                                visaStatus={visaStatus}
                                mastercardStatus={mastercardStatus}
                                amexStatus={amexStatus}
                                isLoading={isLoading}
                                toggleSubscriptionToolTip={toggleSubscriptionToolTip}
                                toggleSubscriptionToolTipOpen={toggleSubscriptionToolTipOpen}
                            />
                            //<div className="rewards-activation-page">{<DCRewardsSection />}</div>
                        )}
                        <div className="loyalty-dashboard-body-text">
                            <p className="about-dc-rewards-title">
                                {intl.formatMessage({ id: 'Settings.AboutDishCultRewards' })}
                            </p>
                            <p>{intl.formatMessage({ id: 'Settings.AboutDishCultRewardsText' })}</p>
                            <div>
                                {intl.formatMessage({ id: 'Settings.IfYouWishToUnsubscribeText' })}{' '}
                                <a href={`mailto:${supportEmail}`}>
                                    {intl.formatMessage({ id: 'Settings.ContactOurSupportTeamText' })}
                                </a>
                            </div>
                        </div>
                    </div>
                </Section>
            </div>
        );
    }

    function getSidePanelContent() {
        return (
            <Fragment>
                <div className="content">
                    <div className="loyalty-dashboard-sidepanel">
                        <div className="sidepanel-title">
                            <p className="sidepanel-title-text">
                                {intl.formatMessage({ id: 'Settings.WeeklyAtAGlance' })}
                            </p>
                            <ToolTipIcon
                                id={'weekly-bookings-tooltip'}
                                className="weekly-bookings-tooltip"
                                innerClassName="weekly-bookings-inner-tooltip"
                            >
                                <p>{intl.formatMessage({ id: 'Settings.WeeklyBookingsAndTransactionsTooltip' })}</p>
                            </ToolTipIcon>
                        </div>
                        <div className="weekly-stats-wrapper">
                            <div className="weekly-stats">
                                <div class-name="weekly-stats-header">
                                    {intl.formatMessage({ id: 'Settings.TotalBookings' })}
                                </div>
                                <div className="weekly-stats-value">{totalBookings}</div>
                            </div>
                            <div className="weekly-stats">
                                <div class-name="weekly-stats-header">
                                    {intl.formatMessage({ id: 'Settings.TotalTransactions' })}
                                </div>
                                <div className="weekly-stats-value">
                                    {decodeCurrencySymbol(diaryContext.currencySymbol) + totalTransactions}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content loyalty-dashboard-report-link" onClick={navigateToLoyaltyReport}>
                    <div className="loyalty-dashboard-view-report-text">
                        {intl.formatMessage({ id: 'Settings.ViewReport' })}
                    </div>
                </div>
                {!isMobileView && <div className="content loyalty-dashboard-sideboard-filler"></div>}
            </Fragment>
        );
    }

    if (!diaryContext.isLoyaltyEnabled) {
        navigate('/404');
    }

    useTrackPage('DCRewards Home Page');
    if (!successDisplayed && props.subscribedToLoyalty) {
        addSuccessBar(intl.formatMessage({ id: 'Settings.ThanksForSubscribingToDishCultRewards' }));
        setSuccessDisplayed(true);
        props.setSubscribedToLoyalty(false);
    }

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <SidePanelContentLayout
            pageTitle={intl.formatMessage({ id: 'Settings.DishCultRewards' })}
            pageContent={getPageContent()}
            sidePanelContent={getSidePanelContent()}
            includeInfoBar
            infoBars={infoBars}
            setInfoBars={setInfoBars}
        />
    );
}

export default LoyaltyDashboard;
