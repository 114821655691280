import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class EposService {
    static findMenuItems(deploymentId, providerId, query) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/EposSync/v1/MenuItems/${deploymentId}/${providerId}/Find?query=${query}`
        ).then((response) => response.data);
    }

    static getMenuItem(deploymentId, providerId, eposItemId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/EposSync/v1/MenuItems/${deploymentId}/${providerId}/MenuItem/${eposItemId}`
        ).then((response) => response.data);
    }

    static syncMenuItems(deploymentId, providerId) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/EposSync/v1/MenuItems/${deploymentId}/${providerId}/Sync`
        ).then((response) => response.data);
    }
}

export default EposService;
