import React, { useState, useContext, useEffect, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { InputGroup, InputGroupAddon, InputGroupText, Input, Button } from 'reactstrap';
import { ReactComponent as DCIcon } from '../../content/images/dishcult-logo.svg';
import { ReactComponent as ShareIcon } from '../../content/icons/voucher-share-url.svg';
import { ReactComponent as CopyIcon } from '../../content/icons/Icon-Copy.svg';
import TextWithIcon from '../common/TextWithIcon';
import SuccessIconWrapper from '../common/SuccessIconWrapper';
import DiaryContext from '../../contexts/DiaryContext';
import SettingsHelper from '../../helpers/SettingsHelper';
import ToggleSwitch from '../common/ToggleSwitch';
import VoucherService from '../../services/VoucherService';
import PropTypes from 'prop-types';
import RDLModal from '../common/RDLModal';
import useScreenSize from '../../hooks/useScreenSize';
import useTrackPage from '../../hooks/useTrackPage';
import FooterPreviewModal from '../common/FooterPreviewModal';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';

function VouchersPromoteModal(props) {
    const intl = useIntl();
    useTrackPage('Web Vouchers - Promote Page');
    const [isAwaitingEnablingRequest, setIsAwaitingEnablingRequest] = useState(false);
    const [sellOnPortalStatus, setSellOnPortalStatus] = useState(false);

    const diaryContext = useContext(DiaryContext);
    const { isMobileView } = useScreenSize();

    const addErrorBar = props.addErrorBar;

    useEffect(() => {
        VoucherService.getVoucherSalesSetting(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((response) => {
                setSellOnPortalStatus(response.IsEnabled);
            })
            .catch(() => {
                addErrorBar();
            });
    }, [diaryContext.deploymentId, diaryContext.restaurantId, addErrorBar]);

    function getIcon() {
        return <SuccessIconWrapper isCompleted={true} />;
    }

    function getModalHeader() {
        if (props.initialSetupComplete) {
            return (
                <div className="d-flex align-items-center">
                    {isMobileView && (
                        <div className="text-uppercase">
                            <FormattedMessage id="Common.GoLive" />
                        </div>
                    )}
                    <div
                        className={
                            isMobileView ? 'd-flex rd-progress-bar ml-auto' : 'd-flex rd-progress-bar m-0  w-100'
                        }
                    >
                        <div>
                            <TextWithIcon
                                text={isMobileView ? '' : intl.formatMessage({ id: 'Common.Create' })}
                                icon={getIcon()}
                                textClass={'font-weight-bold'}
                            />
                        </div>
                        <div className={isMobileView ? 'horizontal-splitter modal-mob' : 'horizontal-splitter'}></div>
                        <div>
                            <TextWithIcon
                                text={isMobileView ? '' : intl.formatMessage({ id: 'Common.Review' })}
                                icon={getIcon()}
                                textClass={'font-weight-bold'}
                            />
                        </div>
                        <div className={isMobileView ? 'horizontal-splitter modal-mob' : 'horizontal-splitter'}></div>
                        <div>
                            <TextWithIcon
                                text={isMobileView ? '' : intl.formatMessage({ id: 'Common.GoLive' })}
                                icon={getIcon()}
                                textClass={'font-weight-bold'}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="text-uppercase">
                    <FormattedMessage id="Vouchers.PromoteVouchers" />
                </div>
            );
        }
    }

    function getCopyUrlInput() {
        return (
            <InputGroup className="input-group-with-addon copy-input-addon">
                <Input
                    type="input"
                    value={SettingsHelper.getVouchersPortalMicrositeUrl(diaryContext.micrositeName)}
                    disabled={true}
                    id="vouchers-url-input"
                />
                <InputGroupAddon addonType="append" onClick={() => copyToClipboard()}>
                    <InputGroupText>
                        <CopyIcon />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        );
    }

    function copyToClipboard() {
        let range = document.createRange();
        range.selectNode(document.getElementById('vouchers-url-input'));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();

        props.addSuccessBar(
            intl.formatMessage({
                id: 'Settings.CopiedToClipboard',
            })
        );
    }

    function updateSellOnPortalVoucherStatus(enabled) {
        setSellOnPortalStatus(enabled);
        setIsAwaitingEnablingRequest(true);
        VoucherService.setVoucherSalesSetting(diaryContext.deploymentId, diaryContext.restaurantId, enabled)
            .then(() => {
                props.addSuccessBar(
                    intl.formatMessage(
                        enabled
                            ? {
                                  id: 'Vouchers.VoucherDishcultEnabled',
                              }
                            : {
                                  id: 'Vouchers.VoucherDishcultDisabled',
                              }
                    )
                );
            })
            .catch(() => {
                setSellOnPortalStatus(!enabled);
                addErrorBar();
            })
            .finally(() => {
                AnalyticsHelper.trackClick('Web VoucherDishcult Toggle');
                setIsAwaitingEnablingRequest(false);
            });
    }

    function getModalBody() {
        return (
            <div className={isMobileView ? 'rdl-modal mob' : 'rdl-modal'}>
                <div className={isMobileView ? 'vouchers-promote-content mob' : 'vouchers-promote-content'}>
                    {props.initialSetupComplete && (
                        <div className="setup-complete-heading mb-5 ">
                            <h4>
                                <FormattedMessage id="Common.Congratulations" />
                            </h4>
                            <h4>
                                <FormattedMessage id="Common.SetupLiveText" />
                            </h4>
                        </div>
                    )}

                    <div className={'row row-content'}>
                        <div className={isMobileView ? 'col-sm-1 mr-2 share-voucher-col' : 'col-sm-1 mr-2'}>
                            <ShareIcon className="icon" />
                        </div>

                        <div className={'col'}>
                            <h6 className="text-uppercase">
                                <FormattedMessage id="Vouchers.ShareVoucherUrl" />
                            </h6>

                            {!isMobileView && (
                                <p>
                                    <FormattedMessage id="Vouchers.ShareVoucherUrlDescription" />
                                </p>
                            )}

                            <div className={isMobileView ? 'url-input' : ''}>{getCopyUrlInput()}</div>
                        </div>
                    </div>

                    <div className={isMobileView ? 'row row-content mob' : 'row row-content'}>
                        <div className={isMobileView ? 'col col-content-mob' : 'col-sm-1 mr-2'}>
                            <DCIcon className="icon" />
                        </div>

                        <div className={isMobileView ? 'col-sm-1' : 'col-9'}>
                            <h6 className="text-uppercase">
                                <FormattedMessage id="Vouchers.SellVouchersDC" />
                            </h6>

                            <FormattedMessage id="Vouchers.EnableVouchersDCText" />
                        </div>

                        {!isMobileView && (
                            <div className="col-sm-1 mb-4">
                                <ToggleSwitch
                                    id="dc-switch"
                                    disabled={isAwaitingEnablingRequest}
                                    isChecked={sellOnPortalStatus}
                                    onChange={updateSellOnPortalVoucherStatus}
                                    optionLabels={[
                                        intl.formatMessage({
                                            id: 'Common.On',
                                        }),
                                        intl.formatMessage({
                                            id: 'Common.Off',
                                        }),
                                    ]}
                                />
                            </div>
                        )}
                    </div>

                    {isMobileView && (
                        <div className="row row-content">
                            <div className="col toggle-switch-col">
                                <ToggleSwitch
                                    id="dc-switch"
                                    disabled={isAwaitingEnablingRequest}
                                    isChecked={sellOnPortalStatus}
                                    onChange={updateSellOnPortalVoucherStatus}
                                    optionLabels={[
                                        intl.formatMessage({
                                            id: 'Common.On',
                                        }),
                                        intl.formatMessage({
                                            id: 'Common.Off',
                                        }),
                                    ]}
                                />
                            </div>
                        </div>
                    )}

                    <div className="row manage-vouchers-row">
                        <Button
                            className={isMobileView ? 'manage-vouchers-button mob' : 'manage-vouchers-button'}
                            onClick={
                                props.initialSetupComplete
                                    ? props.dismissModalAfterWizardSetup
                                    : () => props.setIsModalOpen(false)
                            }
                        >
                            <FormattedMessage id="Vouchers.ManageVouchersButtonText" />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {!isMobileView && (
                <RDLModal
                    isModalOpen={props.isModalOpen}
                    setIsModalOpen={props.setIsModalOpen}
                    title={getModalHeader()}
                    body={getModalBody()}
                    nonDismissible={props.initialSetupComplete}
                    hideFooter
                    modalClass={isMobileView ? '' : 'content-class'}
                    modalHeaderClass="modal-header"
                />
            )}

            {isMobileView && (
                <FooterPreviewModal
                    title={getModalHeader()}
                    body={getModalBody()}
                    isModalOpen={props.isModalOpen}
                    setIsModalOpen={props.setIsModalOpen}
                    modalClassName="vouchers-promotion-modal"
                    hideCloseButton
                />
            )}
        </Fragment>
    );
}

VouchersPromoteModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    initialSetupComplete: PropTypes.bool,
};

export default VouchersPromoteModal;
