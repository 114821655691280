import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Page extends Component {
    getClasses() {
        const classes = ['scrollable-panel', 'expanding-panel'];
        if (this.props.showSidePanel) {
            classes.push('retract');
        } else {
            classes.push('extend');
        }
        if (this.props.isMobileView) {
            classes.push('mobile');
        }
        return classes.join(' ');
    }

    render() {
        return <div className={this.getClasses()}>{this.props.page}</div>;
    }
}

Page.propTypes = {
    page: PropTypes.object.isRequired,
    showSidePanel: PropTypes.bool.isRequired,
};

export default Page;
