import React, { useState, useEffect, Fragment, useCallback } from 'react';
import DeploymentService from '../../services/DeploymentService';
import RestaurantService from '../../services/RestaurantService';
import SessionHelper from '../../helpers/SessionHelper';
import Dropdown from '../../components/common/Dropdown';
import TypeaheadWrapper from '../../components/common/TypeaheadWrapper';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

function AdminProviderLoginScreen(props) {
    const [deployments, setDeployments] = useState(null);
    const [selectedDeployment, setSelectedDeployment] = useState(null);

    useEffect(() => {
        DeploymentService.getAllDeployments().then((response) => {
            setDeployments(response);
        });
    }, []);

    function getDeploymentOptions() {
        if (deployments) {
            return deployments.map((d) => ({ text: d.Name, value: d.Id }));
        } else {
            return [];
        }
    }

    function onDeploymentChanged(deploymentId) {
        setSelectedDeployment(deploymentId);
    }

    function loginAsProvider(providerId) {
        SessionHelper.setProviderInfo(selectedDeployment, providerId);
        return props.onLoginToProvider(selectedDeployment, providerId, true);
    }

    const makeRequest = useCallback(
        (searchTerm) => {
            return RestaurantService.GetAllProvidersForDeployment(selectedDeployment, searchTerm);
        },
        [selectedDeployment]
    );

    const processResponse = useCallback((response) => {
        if (response) {
            return response.map((provider) => ({
                id: provider.RemoteId,
                display: provider.RemoteId + ' - ' + provider.Name,
            }));
        } else {
            return [];
        }
    }, []);

    return (
        <Fragment>
            <Container className="admin-provider-selection">
                <Dropdown
                    title={'Deployment'}
                    onValueChange={onDeploymentChanged}
                    defaultValue={'Please select a deployment'}
                    options={getDeploymentOptions()}
                    selectedValue={selectedDeployment}
                    hideRemoveItemIcon={true}
                />

                <div className="mt-2 mb-1">Provider</div>
                <TypeaheadWrapper
                    typeaheadItemSelected={loginAsProvider}
                    requestFunctionAsync={makeRequest}
                    processResultsFunction={processResponse}
                    placeholder={'Search by provider name or remote id'}
                    disabled={!selectedDeployment}
                />
            </Container>
        </Fragment>
    );
}

AdminProviderLoginScreen.propTypes = {
    onLoginToProvider: PropTypes.func.isRequired,
};

export default AdminProviderLoginScreen;
