const constants = {
    loginSiteAuthCookie: 'authToken',
    resdiarySupport: 'http://resdiarylitesupport.resdiary.com/support/home',
    resdiarySupportForums: 'http://resdiarylitesupport.resdiary.com/en/support/discussions/forums/4000002134',
    freshDeskAccountNumber: 'resdiary_account_number_35758',
    freshdeskUrl:
        'https://resdiary.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=no&helpdesk_ticket[requester]=',
};

export default constants;
