import React, { useState, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as ConfirmIcon } from '../../../content/icons/confirm.svg';
import SettingsHelper from '../../../helpers/SettingsHelper';
import DiaryContext from '../../../contexts/DiaryContext';
import useScreenSize from '../../../hooks/useScreenSize';
import BreadcrumbPage from '../../common/BreadcrumbPage';

function BookButtonPage(props) {
    const intl = useIntl();
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const diaryContext = useContext(DiaryContext);
    const { isMobileView } = useScreenSize();

    function getBreadcrumbOptions() {
        return [new BreadCrumbOption(intl.formatMessage({ id: 'Settings.Title' }), '/Settings')];
    }

    function getButtonCode() {
        return `<a title='Book with ResDiary' href='${SettingsHelper.getRestaurantMicrosite(
            diaryContext.micrositeName
        )}?utm_source=Referral&utm_medium=Book-Button&utm_campaign=Book%20Button#booking' target='_blank' > \n<img src='https://resdiary.blob.core.windows.net/uploads/uk/6564/images/12931/img57177.png' width='186' style='border: 0;' alt='Book with ResDiary'> \n</a>`;
    }

    function copyToClipboard() {
        let range = document.createRange();
        range.selectNode(document.getElementById('button-code'));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        setCopiedToClipboard(true);
    }

    function getContainerClasses() {
        const contentClass = 'book-button-creator-content';
        return isMobileView ? `${contentClass} mobile` : contentClass;
    }

    return (
        <BreadcrumbPage
            pageTitle={intl.formatMessage({ id: 'Settings.BookButtonTitleText' })}
            breadcrumbOptions={getBreadcrumbOptions()}
        >
            <FormattedMessage id="Settings.BookButtonTitleText" tagName="h2" />
            <div className={getContainerClasses()}>
                <Row>
                    <Col md="8">
                        <div className="button-description">
                            <div>
                                <FormattedMessage id="Settings.BookButtonDescriptionLine1" />
                            </div>
                            <div>
                                <FormattedMessage id="Settings.BookButtonDescriptionLine2" />
                            </div>
                        </div>
                        <div className="button-code">
                            <FormattedMessage id="Settings.BookButtonCodeCopy" />
                            <div className="code-box" id="button-code" onClick={() => copyToClipboard()}>
                                <div className="code">{getButtonCode()}</div>
                                {copiedToClipboard && (
                                    <div className="d-flex success-banner">
                                        <ConfirmIcon />
                                        <div>
                                            <FormattedMessage id="Settings.CopiedToClipboard" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="button-preview-container">
                            <img
                                src="https://resdiary.blob.core.windows.net/uploads/uk/6564/images/12931/img57177.png"
                                alt={intl.formatMessage({ id: 'Settings.BookWithResdiary' })}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </BreadcrumbPage>
    );
}

export default BookButtonPage;
