import React, { Fragment, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DiaryContext from '../../contexts/DiaryContext';
import orderAtTableStatus from '../../enums/orderAtTableStatus';
import CurrencyDiv from '../common/CurrencyDiv';

function BookingSummaryAdditionalInfo(props) {
    const diaryContext = useContext(DiaryContext);

    function getBookingReasons() {
        const reasons = [];
        props.bookingReasons.forEach((bookingReason) => {
            reasons.push(
                <div className="booking-reason-item overlay-reason" key={bookingReason.id}>
                    {bookingReason.name}
                </div>
            );
        });
        return reasons;
    }

    if (props) {
        return (
            <Fragment>
                <div>
                    {props.overlayContent.booking.bookingComments && (
                        <div
                            className={
                                (diaryContext.hasSquareEnabled &&
                                    props.overlayContent.booking.orderAtTableStatus !== orderAtTableStatus.Completed &&
                                    !props.overlayContent.booking.hasUnprocessedPayments) ||
                                props.overlayContent.booking.orderNotes
                                    ? 'info-block'
                                    : 'info-block top'
                            }
                        >
                            <h6>
                                <FormattedMessage id="Bookings.BookingCommentsLabel" />
                            </h6>
                            {props.overlayContent.booking.bookingComments}
                        </div>
                    )}
                    {props.overlayContent.booking.customer &&
                        props.overlayContent.booking.customer.customerComments && (
                            <div
                                className={
                                    (diaryContext.hasSquareEnabled &&
                                        props.overlayContent.booking.orderAtTableStatus !==
                                            orderAtTableStatus.Completed &&
                                        !props.overlayContent.booking.hasUnprocessedPayments) ||
                                    props.overlayContent.booking.bookingComments ||
                                    props.overlayContent.booking.orderNotes
                                        ? 'info-block'
                                        : 'info-block top'
                                }
                            >
                                <h6>
                                    <FormattedMessage id="Bookings.CustomersNotes" />
                                </h6>
                                {props.overlayContent.booking.customer.customerComments}
                            </div>
                        )}
                    {props.bookingReasons[0] && (
                        <div
                            className={
                                (diaryContext.hasSquareEnabled &&
                                    props.overlayContent.booking.orderAtTableStatus !== orderAtTableStatus.Completed &&
                                    !props.overlayContent.booking.hasUnprocessedPayments) ||
                                (props.overlayContent.booking.customer &&
                                    props.overlayContent.booking.customer.customerComments) ||
                                props.overlayContent.booking.bookingComments ||
                                props.overlayContent.booking.orderNotes
                                    ? 'info-block'
                                    : 'info-block top'
                            }
                        >
                            <h6>
                                <FormattedMessage id="Bookings.ReasonsForBooking" />
                            </h6>
                            {<div className="booking-reason-container">{getBookingReasons()}</div>}
                        </div>
                    )}
                    {props.overlayContent.booking.voucherName &&
                        props.overlayContent.booking.voucherReference &&
                        props.overlayContent.booking.voucherCost && (
                            <div
                                className={
                                    (diaryContext.hasSquareEnabled &&
                                        props.overlayContent.booking.orderAtTableStatus !==
                                            orderAtTableStatus.Completed &&
                                        !props.overlayContent.booking.hasUnprocessedPayments) ||
                                    (props.overlayContent.booking.customer &&
                                        props.overlayContent.booking.customer.customerComments) ||
                                    props.overlayContent.booking.bookingComments ||
                                    props.overlayContent.booking.orderNotes ||
                                    props.bookingReasons[0]
                                        ? 'info-block voucher-information'
                                        : 'info-block top voucher-information'
                                }
                            >
                                <h6>
                                    <FormattedMessage id="Common.Voucher" />
                                </h6>
                                <Fragment>
                                    <div className="blue-pill">{props.overlayContent.booking.voucherReference}</div>
                                    <div className="voucher-name">
                                        <CurrencyDiv price={props.overlayContent.booking.voucherCost} />{' '}
                                        {props.overlayContent.booking.voucherName}
                                    </div>
                                </Fragment>
                            </div>
                        )}
                </div>
            </Fragment>
        );
    }
}
BookingSummaryAdditionalInfo.propTypes = {
    overlayContent: PropTypes.object.isRequired,
    bookingReasons: PropTypes.array.isRequired,
};

export default BookingSummaryAdditionalInfo;
