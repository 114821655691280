import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import LocalisedApp from './LocalisedApp';
import { unregister } from './registerServiceWorker';
import './styles/styles.scss';
import 'react-circular-progressbar/dist/styles.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <LocalisedApp />
    </BrowserRouter>,
    rootElement
);

unregister();
