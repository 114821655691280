const runningOrderColumns = {
    visitTime: 0,
    customerDetails: 1,
    covers: 2,
    tables: 3,
    bookingComments: 4,
    reference: 5,
    bookingReason: 6,
    dietary: 7,
    promotion: 8,
    orderStatus: 9,
    voucherReference: 10,
    customerIsLoyaltyMember: 11,
    dishcultRewardsRedemption: 12,
};

export default runningOrderColumns;
