import requestType from '../enums/requestType';

function requestReducer(state, action) {
    switch (action.type) {
        case requestType.fetched:
            return {
                ...state,
                isFetching: false,
                responseData: action.payload,
                requestType: action.type,
            };
        case requestType.updateUrlAndFetch:
            return {
                ...state,
                isFetching: true,
                url: action.payload,
                defaultUrl: action.payload,
                requestType: action.type,
            };
        case requestType.fetchManually:
            return {
                ...state,
                isFetching: true,
                url: action.payload,
                defaultUrl: state.defaultUrl,
                requestType: action.type,
            };
        case requestType.error:
            return {
                ...state,
                isFetching: false,
                error: action.payload,
                requestType: action.type,
            };
        case requestType.poll:
            return {
                ...state,
                requestId: state.requestId + 1,
                isFetching: true,
                requestType: action.type,
            };
        default:
            return state;
    }
}
export default requestReducer;
