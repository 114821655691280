import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class CloseOutsService {
    static getCloseOuts(deploymentId, restaurantId, date) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/CloseOuts/${date}/V2`
        ).then((response) => response.data);
    }

    static createCloseOuts(deploymentId, restaurantId, createRequest) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/CloseOut`,
            JSON.stringify(createRequest)
        ).then((response) => response.data);
    }

    static updateCloseOutsDescription(deploymentId, restaurantId, updateRequest) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/UpdateCloseOutsDescription`,
            JSON.stringify(updateRequest)
        ).then((response) => response.data);
    }

    static deleteCloseOuts(deploymentId, restaurantId, deleteRequest) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/DeleteCloseOuts`,
            JSON.stringify(deleteRequest)
        );
    }
}

export default CloseOutsService;
