import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Label, FormGroup, Form, Button, Input } from 'reactstrap';
import Section from '../../common/Section';
import ValidationInput from '../../common/ValidationInput';
import WarningLabel from '../../common/WarningLabel';
import ConfirmActionButton from '../../common/ConfirmActionButton';
import MapContainer from '../../common/MapContainer';
import useValidation from '../../../hooks/useValidation';
import SettingsService from '../../../services/SettingsService';
import DiaryContext from '../../../contexts/DiaryContext';
import RestaurantDetails from '../../../domainObjects/RestaurantSettings/RestaurantDetails';
import PhoneNumberInput from '../../common/PhoneNumberInput';
import ValidationSummary from '../../common/ValidationSummary';
import ValidationDropdown from '../../common/ValidationDropdown';
import ToolTipIcon from '../../common/ToolTipIcon';
import useScreenSize from '../../../hooks/useScreenSize';
import validator from 'validator';
import RestaurantService from '../../../services/RestaurantService';

function MyDetailsTab(props) {
    const intl = useIntl();
    const [isSaving, setIsSaving] = useState(false);
    const [isUpdatingGeoLocation, setIsIsUpdatingGeoLocation] = useState(false);
    const [geoLocationTooltipOpen, setGeoLocationTooltipOpen] = useState(false);
    const [venueName, setVenueName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [townOrCity, setTownOrCity] = useState('');
    const [postcode, setPostcode] = useState('');
    const [country, setCountry] = useState('');
    const [longitude, setLongitude] = useState(0);
    const [latitude, setLatitude] = useState(0);
    const [initialLongitude, setInitialLongitude] = useState(0);
    const [initialLatitude, setInitialLatitude] = useState(0);
    const [countryCode, setCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [timeZones, setTimeZones] = useState([]);
    const displayClass = props.isActive ? '' : 'hidden';
    const diaryContext = useContext(DiaryContext);
    const [timeZone, setTimeZone] = useState(diaryContext.timeZone);

    const { isMobileView } = useScreenSize();

    useEffect(() => {
        RestaurantService.getSystemTimeZones(diaryContext.deploymentId, diaryContext.restaurantId).then((timeZones) => {
            setTimeZones(timeZones);
        });
    }, [diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        setVenueName(props.venueName);
    }, [props.venueName]);

    useEffect(() => {
        setAddressLine1(props.addressLine1);
    }, [props.addressLine1]);

    useEffect(() => {
        setAddressLine2(props.addressLine2);
    }, [props.addressLine2]);

    useEffect(() => {
        setTownOrCity(props.townOrCity);
    }, [props.townOrCity]);

    useEffect(() => {
        setPostcode(props.postcode);
    }, [props.postcode]);

    useEffect(() => {
        setCountry(props.country);
    }, [props.country]);

    useEffect(() => {
        if (props.longitude) {
            setLongitude(props.longitude);
        }
    }, [props.longitude]);

    useEffect(() => {
        if (props.latitude) {
            setLatitude(props.latitude);
        }
    }, [props.latitude]);

    useEffect(() => {
        setCountryCode(props.countryCode);
    }, [props.countryCode]);

    useEffect(() => {
        setPhoneNumber(props.phoneNumber);
    }, [props.phoneNumber]);

    useEffect(() => {
        setEmail(props.email);
    }, [props.email]);

    useEffect(() => {
        setWebsiteUrl(props.websiteUrl);
    }, [props.websiteUrl]);

    const { register, errors, submitDisabled, submit, errorCount, control, setValue } = useValidation();

    function toggleGeoLocation() {
        const isToolTipOpen = !geoLocationTooltipOpen;
        setGeoLocationTooltipOpen(isToolTipOpen);
        if (isToolTipOpen) {
            setInitialLongitude(longitude);
            setInitialLatitude(latitude);
            updateGeoLocationData();
        } else {
            setLongitude(initialLongitude);
            setLatitude(initialLatitude);
        }
    }

    function getDTO() {
        const restaurantSetting = new RestaurantDetails();
        return restaurantSetting.getDTO(
            venueName,
            addressLine1,
            addressLine2,
            townOrCity,
            postcode,
            country,
            props.micrositeName,
            longitude,
            latitude,
            phoneNumber,
            props.siteUrl,
            email,
            countryCode,
            props.isoCurrencySymbol,
            websiteUrl,
            timeZone
        );
    }

    function updateDetails() {
        setIsSaving(true);
        SettingsService.UpdateAddressAndContactDetails(diaryContext.deploymentId, diaryContext.restaurantId, getDTO())
            .then((response) => {
                diaryContext.updateMicrositeName(response.MicrositeName);
                diaryContext.updateTimeZone(response.TimeZone);
                props.addSuccessBar(intl.formatMessage({ id: 'Settings.SuccessfullySavedVenueDetailsMessage' }));
                setIsSaving(false);
            })
            .catch(() => {
                props.addErrorBar();
                setIsSaving(false);
            });
    }

    function getAddressDto() {
        let address = addressLine1;
        if (addressLine2) {
            address = `${address}, ${addressLine2}`;
        }
        return address;
    }

    function updateGeoLocationData() {
        setIsIsUpdatingGeoLocation(true);
        SettingsService.getGeoLocationData(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            getAddressDto(),
            townOrCity,
            country,
            postcode
        )
            .then((response) => {
                setLatitude(response.Latitude);
                setLongitude(response.Longitude);
                setIsIsUpdatingGeoLocation(false);
            })
            .catch(() => {
                props.addErrorBar();
                setIsIsUpdatingGeoLocation(false);
            });
    }

    function getCountryCodeOptions() {
        return props.countryCodes.map(
            (countryCode) => new Option(`${countryCode.Name}: +${countryCode.PhoneCode.toString()}`, countryCode.Id)
        );
    }

    function getCountryCodeId() {
        let id = countryCode ? props.countryCodes.find((cc) => cc.PhoneCode === parseInt(countryCode)).Id : 0;
        return id.toString();
    }

    function onCountryCodeChange(value) {
        value = props.countryCodes.find((cc) => cc.Id === parseInt(value)).PhoneCode;
        setCountryCode(value);
    }

    function hasContentUpdated() {
        return (
            props.venueName !== venueName ||
            props.addressLine1 !== addressLine1 ||
            props.addressLine2 !== addressLine2 ||
            props.townOrCity !== townOrCity ||
            props.postcode !== postcode ||
            props.country !== country ||
            props.countryCode !== countryCode ||
            props.phoneNumber !== phoneNumber ||
            props.email !== email ||
            props.websiteUrl !== websiteUrl ||
            props.latitude !== latitude ||
            props.longitude !== longitude ||
            diaryContext.timeZone !== timeZone
        );
    }

    function onDragEnd(event) {
        setLatitude(event.latLng.lat);
        setLongitude(event.latLng.lng);
    }

    function checkValidEmail(email) {
        if (email && !validator.isEmail(email)) {
            return intl.formatMessage({ id: 'Customer.EmailValidation' });
        }
    }

    function checkValidUrl(url) {
        if (url && !validator.isURL(url)) {
            return intl.formatMessage({ id: 'Settings.UrlValidationMessage' });
        }
    }

    function getTimezoneOptions() {
        return timeZones.map((t) => new Option(t.Name, t.Value));
    }

    return (
        <div className={displayClass}>
            <Section sectionTitle={intl.formatMessage({ id: 'Settings.MyDetails' })}>
                <div className="collapsible-panel-body">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            submit(updateDetails);
                        }}
                    >
                        <div className="font-italic pb-2">
                            <FormattedMessage id="Settings.RequiredInformation" />
                        </div>
                        <FormGroup className="details-tab">
                            <Label for="venueName">
                                <FormattedMessage id="Settings.VenueName" />
                            </Label>
                            <ValidationInput
                                testId="venueName"
                                type="text"
                                name="venueName"
                                value={venueName}
                                onChange={(e) => setVenueName(e)}
                                innerRef={register({
                                    required: intl.formatMessage({ id: 'Settings.VenueNameRequired' }),
                                })}
                                errors={errors}
                                maxLength="50"
                            />
                            <Label for="addressLine1">
                                <FormattedMessage id="Settings.AddressLineOne" />
                            </Label>
                            <ValidationInput
                                testId="addressLine1"
                                type="text"
                                name="addressLine1"
                                value={addressLine1}
                                onChange={(e) => setAddressLine1(e)}
                                innerRef={register({
                                    required: intl.formatMessage({ id: 'Settings.AddressLineOneRequired' }),
                                })}
                                errors={errors}
                                maxLength="50"
                            />
                            <Label for="addressLine2">
                                <FormattedMessage id="Settings.AddressLineTwo" />
                            </Label>
                            <Input
                                type="text"
                                name="addressLine2"
                                value={addressLine2}
                                onChange={(e) => setAddressLine2(e.target.value)}
                                maxLength="50"
                            />
                            <Label for="townOrCity">
                                <FormattedMessage id="Settings.TownOrCity" />
                            </Label>
                            <ValidationInput
                                testId="townOrCity"
                                type="text"
                                name="townOrCity"
                                value={townOrCity}
                                onChange={(e) => setTownOrCity(e)}
                                innerRef={register({
                                    required: intl.formatMessage({ id: 'Settings.TownOrCityRequired' }),
                                })}
                                errors={errors}
                                maxLength="50"
                            />
                            <Label for="postcode">
                                <FormattedMessage id="Settings.Postcode" />
                            </Label>
                            <ValidationInput
                                testId="postcode"
                                type="text"
                                name="postcode"
                                value={postcode}
                                onChange={(e) => setPostcode(e)}
                                innerRef={register({
                                    required: intl.formatMessage({ id: 'Settings.PostcodeRequired' }),
                                })}
                                errors={errors}
                                maxLength="20"
                            />
                            <Label for="country">
                                <FormattedMessage id="Settings.Country" />
                            </Label>
                            <ValidationInput
                                testId="country"
                                type="text"
                                name="country"
                                value={country}
                                onChange={(e) => setCountry(e)}
                                innerRef={register({
                                    required: intl.formatMessage({ id: 'Settings.CountryRequired' }),
                                })}
                                errors={errors}
                                maxLength="50"
                            />
                            <ConfirmActionButton
                                id="geoLocation"
                                buttonColor="primary"
                                buttonClassName="mt-3"
                                tooltipPlacement="top"
                                tooltipOpen={geoLocationTooltipOpen}
                                toggle={toggleGeoLocation}
                                disabled={isUpdatingGeoLocation}
                                outline
                                includeCloseIcon
                                label={intl.formatMessage({ id: 'Settings.UpdateGeolocation' })}
                            >
                                <p>
                                    <FormattedMessage id="Settings.GeoLocationTooltipTitle" />
                                </p>
                                <WarningLabel
                                    label={intl.formatMessage({ id: 'Settings.GeoLocationTooltipWarningLabel' })}
                                />
                                <hr className="full-width-line"></hr>
                                <div className="text-right">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                            setGeoLocationTooltipOpen(false);
                                        }}
                                    >
                                        <FormattedMessage id="Common.Confirm" />
                                    </Button>
                                </div>
                            </ConfirmActionButton>
                            <MapContainer latitude={latitude} longitude={longitude} onDragEnd={onDragEnd} />
                            <div className="content-container">
                                <h6>
                                    <FormattedMessage id="Settings.RestaurantDetails" />
                                </h6>
                                <PhoneNumberInput
                                    label={intl.formatMessage({ id: 'Settings.VenuePhoneNumber' })}
                                    id="venuePhoneNumber"
                                    optionGroups={getCountryCodeOptions()}
                                    onChange={setPhoneNumber}
                                    selectedValue={getCountryCodeId(countryCode)}
                                    inputValue={phoneNumber}
                                    onCountryCodeChange={(e) => onCountryCodeChange(e)}
                                    name="phoneNumber"
                                    errors={errors}
                                    innerRef={register({
                                        required: intl.formatMessage({ id: 'Settings.VenuePhoneNumberRequired' }),
                                    })}
                                    includeValidation
                                    inputClassName={!isMobileView ? 'phone-validation-input' : ''}
                                />
                                <ValidationDropdown
                                    name="timeZone"
                                    isSearchable
                                    formValidation={{ errors: errors, control: control, setValue: setValue }}
                                    validationRules={{
                                        validate: (value) => {
                                            if (!value || !value.length)
                                                return intl.formatMessage({ id: 'Settings.TimeZoneRequired' });
                                        },
                                    }}
                                    onChange={setTimeZone}
                                    options={getTimezoneOptions()}
                                    selectedValue={timeZone}
                                    title={intl.formatMessage({ id: 'Settings.TimeZone' })}
                                />
                                <Label>
                                    <FormattedMessage id="Settings.VenueEmailAddress" />
                                </Label>
                                <ToolTipIcon
                                    innerClassName={'tooltip-inner field-inner-tooltip'}
                                    id={'venue-email-tooltip'}
                                >
                                    <p>
                                        <FormattedMessage id="Settings.VenueEmailTooltipText" />
                                    </p>
                                </ToolTipIcon>
                                <ValidationInput
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e)}
                                    innerRef={register({
                                        required: intl.formatMessage({ id: 'Settings.VenueEmailRequired' }),
                                        validate: (value) => checkValidEmail(value),
                                    })}
                                    errors={errors}
                                    maxLength="100"
                                />
                                <Label>
                                    <FormattedMessage id="Settings.WebsiteUrl" />
                                </Label>
                                <ValidationInput
                                    type="text"
                                    name="websiteUrl"
                                    value={websiteUrl}
                                    onChange={(e) => setWebsiteUrl(e)}
                                    innerRef={register({ validate: (value) => checkValidUrl(value) })}
                                    errors={errors}
                                    maxLength="100"
                                />
                            </div>
                        </FormGroup>
                        <div className="panel-footer">
                            <input
                                disabled={isSaving || submitDisabled || !hasContentUpdated()}
                                type="submit"
                                value={
                                    isSaving
                                        ? intl.formatMessage({
                                              id: 'Common.Updating',
                                          })
                                        : intl.formatMessage({
                                              id: 'Common.Update',
                                          })
                                }
                                className="btn btn-primary"
                            />
                            <ValidationSummary errorCount={errorCount} submitDisabled={submitDisabled} />
                        </div>
                    </Form>
                </div>
            </Section>
        </div>
    );
}

MyDetailsTab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    venueName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    townOrCity: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    micrositeName: PropTypes.string,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    phoneNumber: PropTypes.string,
    siteUrl: PropTypes.string,
    email: PropTypes.string,
    countryCode: PropTypes.string,
    isoCurrencySymbol: PropTypes.string,
};

export default MyDetailsTab;
