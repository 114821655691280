import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useIntl } from 'react-intl';
import WizardPage from '../common/WizardPage';
import ProgressBar from './ProgressBar';
import accountSubmissionTab from '../../enums/Tabs/accountSubmissionTab';
import MyListingTab from '../settings/venue/MyListingTab';
import SettingsService from '../../services/SettingsService';
import DiaryContext from '../../contexts/DiaryContext';
import RestaurantDetails from '../../domainObjects/RestaurantSettings/RestaurantDetails';
import GeneralDetails from '../../domainObjects/RestaurantSettings/GeneralDetails';
import SearchDetails from '../../domainObjects/RestaurantSettings/SearchDetails';
import infoBarType from '../../enums/infoBarType';
import CountryService from '../../services/CountryService';
import ServicesTab from './ServicesTab';
import OnboardingDetailsTab from './OnboardingDetailsTab';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';

let _ID = 0;
function VenueWizardPage() {
    const intl = useIntl();

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const diaryContext = useContext(DiaryContext);
    const [restaurantDetails, setRestaurantDetails] = useState(null);
    const [isLoadingRestaurantDetails, setIsLoadingRestaurantDetails] = useState(true);
    const [allCuisines, setAllCuisines] = useState([]);
    const [allCurrencies, setAllCurrencies] = useState([]);
    const [infoBars, setInfoBars] = useState([]);
    const [isCountryCodesLoading, setIsCountryCodesLoading] = useState(true);
    const [countryCodes, setCountryCodes] = useState([]);
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [currentTabKey, setCurrentTabKey] = useState('');
    const [progressIndex, setProgressIndex] = useState(0); // eslint-disable-line
    const [allDetails, setAllDetails] = useState(null);

    useEffect(() => {
        SettingsService.getRestaurantSettings(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((data) => {
                setRestaurantDetails(new RestaurantDetails(data.NameAddressDetails));
                setWebsiteUrl(data.RestaurantInformation.WebsiteUrl);
                setAllCuisines(data.Cuisines);
                setAllCurrencies(data.Currencies);
                setAllDetails({
                    ...new RestaurantDetails(data.NameAddressDetails),
                    ...new GeneralDetails(data.RestaurantInformation),
                    ...new SearchDetails(data.SearchDetails),
                    isReserveWithGoogleEnabled: data.IsReserveWithGoogleEnabled,
                    hideOnPortal: data.HideOnPortal,
                });
                AnalyticsHelper.identifyUserId(diaryContext.restaurantId);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsLoadingRestaurantDetails(false);
            });
    }, [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId]);

    useEffect(() => {
        setIsCountryCodesLoading(true);
        CountryService.getCountryCodes()
            .then((countryCodes) => {
                setCountryCodes(countryCodes);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsCountryCodesLoading(false);
            });
    }, [addErrorBar]);

    useEffect(() => {
        if (!isLoadingRestaurantDetails) {
            if (allDetails.email && allDetails.venueDescription) {
                setCurrentTabKey(accountSubmissionTab.services);
                setProgressIndex(2);
            } else if (allDetails.email) {
                setCurrentTabKey(accountSubmissionTab.myListing);
                setProgressIndex(1);
            } else {
                setCurrentTabKey(accountSubmissionTab.myDetails);
                setProgressIndex(0);
            }
        }
    }, [allDetails, isLoadingRestaurantDetails]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentTabKey]);

    useEffect(() => {
        switch (currentTabKey) {
            case accountSubmissionTab.myDetails:
                AnalyticsHelper.trackPage('Account Details');
                break;
            case accountSubmissionTab.myListing:
                AnalyticsHelper.trackPage('Account Listing');
                break;
            case accountSubmissionTab.services:
                AnalyticsHelper.trackPage('Account Service');
                break;
            default:
        }
    }, [currentTabKey]);

    const tabLabels = [
        { key: accountSubmissionTab.myDetails, name: intl.formatMessage({ id: 'Settings.MyDetails' }) },
        { key: accountSubmissionTab.myListing, name: intl.formatMessage({ id: 'Settings.MyListing' }) },
        { key: accountSubmissionTab.services, name: intl.formatMessage({ id: 'Common.Services' }) },
    ];

    const tabs = [
        <OnboardingDetailsTab
            key={accountSubmissionTab.myDetails}
            isActive={currentTabKey === accountSubmissionTab.myDetails}
            addSuccessBar={addSuccessBar}
            addErrorBar={addErrorBar}
            {...restaurantDetails}
            countryCodes={countryCodes}
            websiteUrl={websiteUrl}
            changeToNextTab={changeToNextTab}
            isLoading={isLoading()}
        />,
        <MyListingTab
            key={accountSubmissionTab.myListing}
            isActive={currentTabKey === accountSubmissionTab.myListing}
            addSuccessBar={addSuccessBar}
            addErrorBar={addErrorBar}
            allCuisines={allCuisines}
            allCurrencies={allCurrencies}
            allDetails={allDetails}
            backToPreviousTab={backToPreviousTab}
            changeToNextTab={changeToNextTab}
            setAllDetails={setAllDetails}
            isOnboarding
        />,
        <ServicesTab
            key={accountSubmissionTab.services}
            isActive={currentTabKey === accountSubmissionTab.services}
            addSuccessBar={addSuccessBar}
            addErrorBar={addErrorBar}
            backToPreviousTab={backToPreviousTab}
        />,
    ];

    function onTabChanged(tabKey) {
        const newIndex = tabLabels.findIndex((label) => label.key === tabKey);
        if (tabKey !== currentTabKey && progressIndex >= newIndex) {
            setCurrentTabKey(tabKey);
        }
    }

    function backToPreviousTab() {
        const currentIndex = tabLabels.findIndex((label) => label.key === currentTabKey);
        const nextTabIndex = currentIndex - 1;
        const nextTab = tabLabels[nextTabIndex].key;
        onTabChanged(nextTab);
    }

    //Use this after clicking save & continue on the child content pages
    function changeToNextTab() {
        const currentIndex = tabLabels.findIndex((label) => label.key === currentTabKey);
        const nextTabIndex = currentIndex + 1;
        if (tabLabels.length - 1 > currentIndex && currentIndex >= progressIndex) {
            setCurrentTabKey(tabLabels[nextTabIndex].key);
            setProgressIndex(nextTabIndex);
        } else {
            setCurrentTabKey(tabLabels[nextTabIndex].key);
        }
    }

    function isLoading() {
        return isLoadingRestaurantDetails || isCountryCodesLoading;
    }

    return (
        <WizardPage
            pageTitle={intl.formatMessage({ id: 'Common.GetStartedMessage' })}
            tabLabels={tabLabels}
            currentTabKey={currentTabKey}
            tabs={tabs}
            onTabChanged={onTabChanged}
            isLoading={isLoading()}
            infoBars={infoBars}
            setInfoBars={setInfoBars}
            progressIndex={progressIndex}
            progressBar={<ProgressBar createAccountComplete subscriptionComplete accountSetupComplete={false} />}
        />
    );
}

export default VenueWizardPage;
