import React, { Fragment, useState, useContext } from 'react';
import Service from '../../../domainObjects/Service';
import ServiceEntryWrapper from './ServiceEntryWrapper';
import { useIntl } from 'react-intl';
import ListBuilder from '../../common/ListBuilder';
import ServiceEditPanel from './ServiceEditPanel';
import PropTypes from 'prop-types';
import DiaryContext from '../../../contexts/DiaryContext';
import ServiceSettingsService from '../../../services/ServiceSettingsService';
import MomentHelper from '../../../helpers/MomentHelper';

function ServicesListBuilder(props) {
    const intl = useIntl();
    const [isAddingNewService, setIsAddingNewService] = useState(false);
    const [awaitingIsEnabledRequest, setAwaitingIsEnabledRequest] = useState(false);

    const diaryContext = useContext(DiaryContext);

    const toggleAddNewService = () => {
        setIsAddingNewService(!isAddingNewService);
    };

    function doesServiceTimeOverLap(startTime, endTime, id, isEndTimeDropdown) {
        //Adjust the timespan for services which run beyond midnight
        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'days');
        }

        return props.services.some((service) => {
            if (service.id !== id) {
                const serviceStartTime = MomentHelper.dateWithFormat(service.timeFrom, 'HH:mm:ss');
                const serviceEndTime = MomentHelper.dateWithFormat(service.timeUntil, 'HH:mm:ss');

                //Adjust the timespan for services which run beyond midnight
                if (serviceEndTime.isBefore(serviceStartTime)) {
                    serviceEndTime.add(1, 'days');
                }

                if (endTime.diff(serviceStartTime, 'hours') > 24) {
                    return true;
                }

                if (serviceStartTime.isAfter(startTime) && serviceEndTime.isBefore(endTime)) {
                    return true;
                }

                return isEndTimeDropdown
                    ? MomentHelper.isDateWithinRangeExclusive(endTime, serviceStartTime, serviceEndTime)
                    : MomentHelper.isDateWithinRange(startTime, serviceStartTime, serviceEndTime);
            }
            return false;
        });
    }

    function getDto(service) {
        return {
            Id: service.id,
            Name: service.name,
            Description: service.description,
            TimeFrom: service.timeFrom,
            TimeUntil: service.timeUntil,
            LastBookingTime: service.lastBookingTime,
            TimeSlotInterval: service.timeSlotInterval,
            MaxBookings: service.maxBookings,
            MaxCovers: service.maxCovers,
            SegmentId: props.segmentId,
        };
    }

    function updateService(service, callback, failureCallback) {
        ServiceSettingsService.updateService(diaryContext.deploymentId, diaryContext.restaurantId, getDto(service))
            .then((result) => {
                if (service.id > 0) {
                    let servicesCopy = [...props.services];
                    let index = props.services.findIndex((t) => t.id === result.Id);
                    servicesCopy[index] = new Service(result);
                    props.setServices(servicesCopy);
                } else {
                    props.setServices([...props.services, new Service(result)]);
                    toggleAddNewService();
                }

                props.addSuccessBar(intl.formatMessage({ id: 'Common.ItemUpdatedInfoBarSuccessMessage' }));

                if (callback) {
                    callback();
                }
            })
            .catch(() => {
                props.addErrorBar();
                if (failureCallback) {
                    failureCallback();
                }
            });
    }

    function deleteService(serviceId, callback, failureCallback) {
        ServiceSettingsService.DeleteService(diaryContext.deploymentId, diaryContext.restaurantId, serviceId)
            .then(() => {
                props.setServices(props.services.filter((service) => service.id !== serviceId));
                props.addSuccessBar(
                    intl.formatMessage({
                        id: 'Common.ItemRemovedInfoBarSuccessMessage',
                    })
                );

                if (callback) {
                    callback();
                }
            })
            .catch(() => {
                props.addErrorBar();
                if (failureCallback) {
                    failureCallback();
                }
            });
    }

    function updateServiceEnabledService(serviceId, callback, failureCallback) {
        setAwaitingIsEnabledRequest(true);
        const servicesCopy = [...props.services];
        const index = props.services.findIndex((menu) => menu.id === serviceId);
        let serviceName = '';
        let isEnabled = true;
        if (index >= 0) {
            isEnabled = !servicesCopy[index].enabled;
            serviceName = servicesCopy[index].name;

            ServiceSettingsService.UpdateServiceEnabledStatus(
                diaryContext.deploymentId,
                diaryContext.restaurantId,
                serviceId,
                isEnabled
            )
                .then(() => {
                    updateServiceEnabledStatus(serviceId, isEnabled);
                    props.addSuccessBar(
                        intl.formatMessage(
                            {
                                id: isEnabled ? 'Settings.SuccessServiceEnabling' : 'Settings.SuccessServiceDisabling',
                            },
                            { serviceName: `'${serviceName}'` }
                        )
                    );
                    if (callback) {
                        callback();
                    }
                })
                .catch(() => {
                    props.addErrorBar(
                        intl.formatMessage(
                            {
                                id: isEnabled ? 'Settings.ErrorServiceEnabling' : 'Settings.ErrorServiceDisabling',
                            },
                            { serviceName: `'${serviceName}'` }
                        )
                    );
                    if (failureCallback) {
                        failureCallback();
                    }
                })
                .finally(() => {
                    setAwaitingIsEnabledRequest(false);
                });
        }
    }

    function updateServiceEnabledStatus(serviceId, isEnabled) {
        const servicesCopy = [...props.services];
        const index = props.services.findIndex((menu) => menu.id === serviceId);
        if (index >= 0) {
            servicesCopy[index].enabled = isEnabled;
        }
        props.setServices(servicesCopy);
    }

    function getPageContent() {
        return (
            <Fragment>
                {props.services.map((service) => {
                    return (
                        <ServiceEntryWrapper
                            key={service.id}
                            service={service}
                            doesServiceTimeOverLap={doesServiceTimeOverLap}
                            updateService={updateService}
                            deleteService={deleteService}
                            updateServiceEnabledStatus={updateServiceEnabledStatus}
                            addSuccessBar={props.addSuccessBar}
                            addErrorBar={props.addErrorBar}
                            isInitialSetup={props.isInitialSetup}
                            updateServiceEnabledService={updateServiceEnabledService}
                            awaitingIsEnabledRequest={awaitingIsEnabledRequest}
                        />
                    );
                })}

                {isAddingNewService && (
                    <ServiceEditPanel
                        service={new Service({ MaxCovers: 10 })}
                        doesServiceTimeOverLap={doesServiceTimeOverLap}
                        toggleAddNewService={toggleAddNewService}
                        updateService={updateService}
                        isInitialSetup={props.isInitialSetup}
                    />
                )}
            </Fragment>
        );
    }

    return (
        <ListBuilder
            isAddingNewItem={isAddingNewService}
            toggleAddNewItem={toggleAddNewService}
            addNewItemButtonText={intl.formatMessage({ id: 'Settings.AddNewServiceButtonText' })}
            content={getPageContent()}
        />
    );
}

ServicesListBuilder.propTypes = {
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired,
    segmentId: PropTypes.number.isRequired,
    setServices: PropTypes.func.isRequired,
    isInitialSetup: PropTypes.bool,
};

export default ServicesListBuilder;
