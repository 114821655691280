import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button, FormGroup } from 'reactstrap';
import CurrencyDiv from '../../common/CurrencyDiv';
import ConfirmActionButton from '../../common/ConfirmActionButton';
import EditMenuItemSection from './EditMenuItemSection';
import useScreenSize from '../../../hooks/useScreenSize';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import { ReactComponent as UpArrowIcon } from '../../../content/icons/up-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../../content/icons/down-arrow.svg';
import { ReactComponent as DragIcon } from '../../../content/icons/Icon-Drag-Handle.svg';

function MenuItem(props) {
    const intl = useIntl();
    const [isOpen, toggleIsOpen] = useReducer(toggle, false);
    const [isEditing, toggleIsEditing] = useReducer(toggle, false);
    const [isDeleting, toggleIsDeleting] = useReducer(toggle, false);
    const [tooltipOpen, toggleTooltip] = useReducer(toggle, false);
    const { isMobileView } = useScreenSize();

    function toggle(value) {
        return !value;
    }

    function getArrowIcon() {
        return isOpen ? <UpArrowIcon /> : <DownArrowIcon />;
    }

    function getSummary() {
        return props.menuItem.optionCategories.map((modifier) => {
            return (
                <div key={modifier.uniqueIdentifier} className="d-flex item-summary">
                    <div className="menu-items-summary-header">
                        <div className="bold-text text-uppercase">
                            <FormattedMessage id="Settings.Modifier" />
                        </div>
                        <div className="panel-display-item">{modifier.name}</div>
                    </div>
                    <div className="menu-items-summary-header">
                        <div className="bold-text text-uppercase">
                            <FormattedMessage id="Common.Description" />
                        </div>
                        <div className="panel-display-item">{modifier.dinerQuestion}</div>
                    </div>
                    <div className="menu-items-summary-header">
                        <div className="bold-text text-uppercase">
                            <FormattedMessage id="Settings.Item" />
                        </div>
                        {modifier.options.map((si) => {
                            return (
                                <div key={si.uniqueIdentifier} className="panel-display-item">
                                    {si.name}
                                </div>
                            );
                        })}
                    </div>
                    <div className="menu-items-summary-header">
                        <div className="bold-text text-uppercase">
                            <FormattedMessage id="Settings.Price" />
                        </div>
                        {modifier.options.map((si) => {
                            return (
                                <CurrencyDiv
                                    key={si.uniqueIdentifier}
                                    className="panel-display-item"
                                    price={si.price}
                                />
                            );
                        })}
                    </div>
                </div>
            );
        });
    }

    if (isEditing) {
        return (
            <EditMenuItemSection
                editingSection
                menuItem={props.menuItem}
                menuItemEdited={props.menuItemEdited}
                toggleIsOpen={toggleIsEditing}
                addErrorBar={props.addErrorBar}
            />
        );
    }

    return (
        <div className="menu-item">
            <div className={isOpen ? 'menu-item-row section-open' : 'menu-item-row'}>
                <div className="mr-auto menu-item-description">
                    <div className="grabbable-icon" {...props.dragHandleProps}>
                        <DragIcon />
                    </div>
                    <div>
                        <div className="bold-text">{props.menuItem.name}</div>
                        <div>{props.menuItem.description}</div>
                    </div>
                </div>
                {!isMobileView && (
                    <div className="row-section price-row-section-column">
                        <div>
                            <CurrencyDiv price={props.menuItem.price} />
                        </div>
                    </div>
                )}
                {!isMobileView && (
                    <div className="row-section modifier-row-section-column">
                        <div>
                            <FormattedMessage
                                id={
                                    props.menuItem.optionCategories.length === 1
                                        ? 'Settings.MenuItemTotalModifier'
                                        : 'Settings.MenuItemTotalModifiers'
                                }
                                values={{ totalItems: props.menuItem.optionCategories.length }}
                            />
                        </div>
                    </div>
                )}
                <div
                    className="row-section clickable"
                    onClick={() => {
                        toggleIsEditing();
                    }}
                >
                    <EditIcon />
                </div>
                <div className="row-section clickable delete">
                    <ConfirmActionButton
                        id={'deleteRowButton' + props.menuItem.uniqueIdentifier}
                        className="booking-status-icon-button"
                        icon={<DeleteIcon />}
                        tooltipOpen={tooltipOpen}
                        includeCloseIcon
                        toggle={toggleTooltip}
                        tooltipPlacement="top"
                    >
                        <FormattedMessage id="Common.DeletionConfirmation" />
                        <Button
                            className="btn-outline-primary cancel-btn"
                            onClick={() => {
                                toggleIsDeleting();
                                props.menuItemDeleted(props.menuItem);
                            }}
                        >
                            {isDeleting
                                ? intl.formatMessage({ id: 'Common.Deleting' })
                                : intl.formatMessage({ id: 'Common.Delete' })}
                        </Button>
                    </ConfirmActionButton>
                </div>
                <div className="row-section chevron">
                    <Button
                        className="section-chevron"
                        onClick={toggleIsOpen}
                        disabled={props.menuItem.optionCategories.length === 0}
                    >
                        {getArrowIcon()}
                    </Button>
                </div>
            </div>
            <Collapse className="inline-collapse-section" isOpen={isOpen}>
                <FormGroup className="panel-form-group">{getSummary()}</FormGroup>
            </Collapse>
        </div>
    );
}

MenuItem.propTypes = {
    menuItem: PropTypes.object.isRequired,
    dragHandleProps: PropTypes.object.isRequired,
    menuItemDeleted: PropTypes.func.isRequired,
    menuItemEdited: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
};

export default MenuItem;
