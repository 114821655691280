class ProgressFlagHelper {
    static toJson(progressFlags) {
        return {
            UploadedMenu: progressFlags.uploadedMenu,
            AddedAdditionalImage: progressFlags.addedAdditionalImage,
            AddedAdvancedListing: progressFlags.addedAdvancedListing,
            DismissedCompletionModal: progressFlags.dismissedCompletionModal,
            WizardComplete: progressFlags.wizardComplete,
        };
    }
}

export default ProgressFlagHelper;
