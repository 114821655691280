import { v4 as uuidv4 } from 'uuid';
import ModifierOption from './ModifierOption';

class Modifier {
    constructor(menuItem) {
        this.id = null;
        this.eposId = menuItem && menuItem.Id ? menuItem.Id : null;
        this.name = menuItem && menuItem.Name ? menuItem.Name : '';
        this.dinerQuestion = menuItem && menuItem.DinerQuestion ? menuItem.DinerQuestion : '';
        this.allowMultipleChoices = menuItem && menuItem.AllowMultipleChoices ? menuItem.AllowMultipleChoices : false;
        this.isRequired = menuItem && menuItem.IsRequired ? menuItem.IsRequired : false;
        this.numberOfMultipleChoices =
            menuItem && menuItem.NumberOfMultipleChoices ? menuItem.NumberOfMultipleChoices : '';
        this.options = menuItem ? this.getOptions(menuItem) : [];
        this.uniqueIdentifier = uuidv4();
    }

    populateFromDiaryDto(diaryModifierDto) {
        this.id = diaryModifierDto.Id;
        this.eposId = diaryModifierDto.EposId;
        this.name = diaryModifierDto.Name;
        this.dinerQuestion = diaryModifierDto.DinerQuestion;
        this.displayOrder = diaryModifierDto.DisplayOrder;
        this.allowMultipleChoices = diaryModifierDto.AllowMultipleChoices;
        this.isRequired = diaryModifierDto.IsRequired;
        this.options = diaryModifierDto.Options
            ? diaryModifierDto.Options.map((o) => {
                  const option = new ModifierOption();
                  option.populateFromDiaryDto(o);
                  return option;
              })
            : [];
    }

    getOptions(menuItem) {
        if (menuItem.Options) {
            return menuItem.Options.map((o) => new ModifierOption(o));
        }
        if (menuItem.options) {
            return menuItem.options;
        }
        return [];
    }
}

export default Modifier;
