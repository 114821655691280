import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Form } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import useValidation from '../../../hooks/useValidation';
import ValidationInput from '../../common/ValidationInput';
import ValidationSummary from '../../common/ValidationSummary';
import ConfirmActionButton from '../../common/ConfirmActionButton';
import TextWithIcon from '../../common/TextWithIcon';
import { ReactComponent as AlertIcon } from '../../../content/icons/Icon-Alert.svg';
import TableLabel from '../../../domainObjects/TableLabel';
import TableLabelHelper from '../../../helpers/TableLabelHelper';
import useScreenSize from '../../../hooks/useScreenSize';

function TableLabelCreationPanel(props) {
    const intl = useIntl();
    const { register, errors, submitDisabled, submit, errorCount } = useValidation();
    const [isToolTipOpen, setToolTip] = useState(false);
    const [tableLabelBeingEdited, setTableLabelBeingEdited] = useState(getTableLabel());
    const { isMobileView } = useScreenSize();

    function getTableLabel() {
        return new TableLabel(TableLabelHelper.toJson(props.tableLabel));
    }

    function onValueChange(key, value) {
        setTableLabelBeingEdited({ ...tableLabelBeingEdited, [key]: value });
    }

    function checkDuplicateName(value) {
        if (props.existingTableLabels.find((tl) => tl.name === value && tl.id !== props.tableLabel.id) !== undefined) {
            return intl.formatMessage({ id: 'Settings.TableLabelDuplicateNameValidation' });
        }
    }

    function hasTableLabelUpdated() {
        return JSON.stringify(tableLabelBeingEdited) !== JSON.stringify(props.tableLabel);
    }

    function updateTableLabelEntry() {
        props.updateTableLabelEntry(tableLabelBeingEdited);
    }

    function saveTableLabel(addAnother) {
        props.saveTableLabel(addAnother, tableLabelBeingEdited);
    }

    function getUpdateButtonContent() {
        return (
            <FormGroup className="mb-0">
                <Fragment>
                    <p className="mb-0">
                        <FormattedMessage id="Settings.UpdateTableConfirmationText" />
                    </p>
                    <div className="validation-message">
                        <div className="popover-warning-message">
                            <TextWithIcon
                                icon={<AlertIcon />}
                                text={intl.formatMessage({ id: 'Settings.UpdateTableWarningMessage' })}
                            />
                        </div>
                    </div>
                    <div className="text-right mt-2">
                        <Button
                            className="mr-2"
                            color="outline-primary"
                            type="submit"
                            onClick={() => setToolTip(false)}
                        >
                            <FormattedMessage id="Common.Cancel" />
                        </Button>
                        <Button
                            color="primary"
                            disabled={submitDisabled}
                            type="submit"
                            onClick={() => submit(() => updateTableLabelEntry())}
                        >
                            <FormattedMessage id="Common.Update" />
                        </Button>
                    </div>
                </Fragment>
            </FormGroup>
        );
    }

    function getSubmitButton() {
        let content;
        if (props.isEditing) {
            content = (
                <ConfirmActionButton
                    id={'updateRowButton' + props.tableLabel.uniqueIdentifier}
                    buttonColor="primary"
                    tooltipPlacement="top"
                    buttonClassName="ml-2"
                    tooltipOpen={isToolTipOpen}
                    disabled={submitDisabled || !hasTableLabelUpdated()}
                    toggle={() => setToolTip(!isToolTipOpen)}
                    label={intl.formatMessage({ id: 'Common.Update' })}
                    includeCloseIcon
                >
                    {getUpdateButtonContent()}
                </ConfirmActionButton>
            );
        } else if (!isMobileView) {
            content = (
                <Button
                    tabIndex="1"
                    color="primary"
                    onClick={() => submit(() => saveTableLabel())}
                    data-testid="save-table-label"
                    disabled={submitDisabled || !hasTableLabelUpdated()}
                >
                    <FormattedMessage id="Common.Save" />
                </Button>
            );
        } else {
            content = (
                <Button
                    tabIndex="1"
                    color="primary"
                    onClick={() => submit(() => saveTableLabel())}
                    data-testid="save-table-label"
                    className="button-mob-save"
                    disabled={submitDisabled || !hasTableLabelUpdated()}
                >
                    <FormattedMessage id="Common.Save" />
                </Button>
            );
        }

        return content;
    }

    return (
        <div className={props.isEditing ? 'grey-closable-panel editing' : 'grey-closable-panel'}>
            <Button
                className="close"
                onClick={() => (props.isEditing ? props.toggleEditing() : props.toggleAddNewTable())}
            >
                <span aria-hidden="true">&times;</span>
            </Button>
            <h6 className="heading text-uppercase bold" data-testid="table-creation-panel-header">
                {props.isEditing ? (
                    <FormattedMessage id="Settings.EditTable" />
                ) : (
                    <FormattedMessage id="Settings.AddTable" />
                )}
            </h6>

            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="form-entry-group d-flex">
                    <FormGroup className="col-md-5">
                        <FormattedMessage id="Common.Table" />
                        <ValidationInput
                            value={tableLabelBeingEdited.name}
                            onChange={(e) => onValueChange('name', e)}
                            testId="table-label-name-input"
                            innerRef={register({
                                required: intl.formatMessage({ id: 'Settings.TableLabelsTableRequired' }),
                                validate: (value) => checkDuplicateName(value),
                            })}
                            name="name"
                            autoFocus
                            errors={errors}
                        />
                    </FormGroup>

                    <FormGroup className="col-md-5">
                        <FormattedMessage id="Common.Covers" />
                        <ValidationInput
                            value={tableLabelBeingEdited.covers}
                            onChange={(e) => onValueChange('covers', parseInt(e))}
                            testId="table-label-covers-input"
                            innerRef={register({
                                min: {
                                    value: 1,
                                    message: intl.formatMessage({ id: 'Settings.TableLablesCoversRequired' }),
                                },
                                required: intl.formatMessage({ id: 'Settings.TableLablesCoversRequired' }),
                            })}
                            name="covers"
                            errors={errors}
                            type="number"
                        />
                    </FormGroup>
                </div>

                <FormGroup className="grey-closable-panel-footer">
                    <div className="button-wrapper">
                        {getSubmitButton()}

                        {!props.isEditing && !isMobileView && (
                            <Button
                                tabIndex="0"
                                color="primary"
                                disabled={submitDisabled || !hasTableLabelUpdated()}
                                onClick={() => submit(() => saveTableLabel(true))}
                            >
                                <FormattedMessage id="Common.SaveAndAddAnother" />
                            </Button>
                        )}
                        {!props.isEditing && isMobileView && (
                            <Button
                                tabIndex="0"
                                color="primary"
                                disabled={submitDisabled || !hasTableLabelUpdated()}
                                className="button-mob-save-add"
                                onClick={() => submit(() => saveTableLabel(true))}
                            >
                                <FormattedMessage id="Common.SaveAndAddAnother" />
                            </Button>
                        )}
                        <ValidationSummary errorCount={errorCount} submitDisabled={submitDisabled} />
                    </div>
                </FormGroup>
            </Form>
        </div>
    );
}

TableLabelCreationPanel.propTypes = {
    tableLabel: PropTypes.object.isRequired,
    saveTableLabel: PropTypes.func,
    isEditing: PropTypes.bool,
    toggleEditing: PropTypes.func,
    toggleAddNewTable: PropTypes.func,
    updateTableLabelEntry: PropTypes.func,
    existingTableLabels: PropTypes.array.isRequired,
};

export default TableLabelCreationPanel;
