import React, { useState, useEffect, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import InfoBars from '../common/InfoBars';
import LoadingSpinner from '../common/LoadingSpinner';
import useAccountSubmissionPollRequest from '../../hooks/useAccountSubmissionPollRequest';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../../contexts/SessionContext';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import useTrackPage from '../../hooks/useTrackPage';
import SettingsHelper from '../../helpers/SettingsHelper';

function SuccessPage() {
    const intl = useIntl();
    const navigate = useNavigate();
    const sessionContext = useContext(SessionContext);
    const params = new URL(document.location).searchParams;
    const paymentParameter = params.get('payment');
    const { accountSubmission, pollRequestTimedOut } = useAccountSubmissionPollRequest(
        encodeURIComponent(sessionContext.userName),
        paymentParameter ? paymentParameter : false
    );
    const [infoBars, setInfoBars] = useState([]);
    const [userIdentified, setUserIdentified] = useState(false);
    useTrackPage('Subscription');
    trackUser();

    useEffect(() => {
        if (accountSubmission) {
            window.location = `/`;
        }
    }, [accountSubmission, navigate]);

    function trackUser() {
        if (SettingsHelper.getSegmentAnonymousId()) {
            AnalyticsHelper.setAnonymousId(SettingsHelper.getSegmentAnonymousId());
        }

        if (!userIdentified) {
            AnalyticsHelper.identifyUserWithProperties(getAnalyticsProperties());
            setUserIdentified(true);
        }
    }

    function getAnalyticsProperties() {
        let currentUTC = new Date();
        currentUTC = currentUTC.toISOString();
        return { registrationDate: currentUTC };
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            {!pollRequestTimedOut && (
                <LoadingSpinner loadingText={intl.formatMessage({ id: 'Settings.CreatingAccount' })} />
            )}
            {pollRequestTimedOut && (
                <div className="diary-deploy-timeout-message">
                    <h2>
                        <FormattedMessage id="AccountSubmission.DiaryDeployTimeoutHeading" />
                    </h2>
                    <FormattedMessage id="AccountSubmission.DiaryDeployTimeoutMessage" />
                </div>
            )}
        </Fragment>
    );
}

SuccessPage.propTypes = {
    sessionId: PropTypes.string,
    accessToken: PropTypes.string,
};

export default SuccessPage;
