import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import TableLabel from '../../../domainObjects/TableLabel';
import TabelLabelsListBuilder from './TabelLabelsListBuilder';
import TableLabelsService from '../../../services/TableLabelService';
import DiaryContext from '../../../contexts/DiaryContext';
import infoBarType from '../../../enums/infoBarType';
import InfoBars from '../../common/InfoBars';
import { FormattedMessage, useIntl } from 'react-intl';
import BreadCrumbDisplay from '../../common/BreadCrumbDisplay';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import LoadingSpinner from '../../common/LoadingSpinner';

let _ID = 0;

function TableLabelsConfigPanel(props) {
    const intl = useIntl();
    const [tableLabels, updateTableLabels] = useState([]);
    const [infoBars, setInfoBars] = useState([]);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const diaryContext = useContext(DiaryContext);

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    useEffect(() => {
        TableLabelsService.getAllTableLabels(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((response) => {
                const data = response.TableLabels.map((label) => new TableLabel(label));
                updateTableLabels(data);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsFetchingData(false);
            });
    }, [addErrorBar, diaryContext.deploymentId, diaryContext.restaurantId]);

    function getBreadcrumbOptions() {
        return [
            new BreadCrumbOption(
                intl.formatMessage({
                    id: 'Settings.Title',
                }),
                '/Settings'
            ),
        ];
    }

    function getPageContent() {
        if (isFetchingData) {
            return <LoadingSpinner />;
        } else {
            return (
                <Fragment>
                    <div className="row">
                        <div className="col-lg-12 page-header">
                            <div className="d-flex">
                                <h2 className="flex-fill" data-testid="tables-page-header">
                                    <FormattedMessage id="Common.Tables" />
                                </h2>
                            </div>
                        </div>
                    </div>

                    <TabelLabelsListBuilder
                        tableLabels={tableLabels}
                        updateTableLabels={updateTableLabels}
                        addSuccessBar={addSuccessBar}
                        addErrorBar={addErrorBar}
                    />
                </Fragment>
            );
        }
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <BreadCrumbDisplay
                breadcrumbs={getBreadcrumbOptions()}
                activePageTitle={intl.formatMessage({
                    id: 'Settings.TablesTitleText',
                })}
            />

            {getPageContent()}
        </Fragment>
    );
}

export default TableLabelsConfigPanel;
