class UserAccount {
    constructor(account) {
        this.firstName = account && account.FirstName ? account.FirstName : '';
        this.lastName = account && account.LastName ? account.LastName : '';
        this.emailAddress = account && account.EmailAddress ? account.EmailAddress : '';
        this.countryId = account && account.CountryId ? account.CountryId : null;
        this.password = account && account.Password ? account.Password : '';
        this.countryName = account && account.CountryName ? account.CountryName : '';
        this.countryAlpha2Code = account && account.countryAlpha2Code ? account.countryAlpha2Code : '';
    }
}

export default UserAccount;
