import React, { useCallback, useContext, useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import TypeaheadWrapper from '../../common/TypeaheadWrapper';
import DiaryContext from '../../../contexts/DiaryContext';
import CurrencyDiv from '../../common/CurrencyDiv';
import AddMenuItemModifierSection from './AddMenuItemModifierSection';
import MenuItem from '../../../domainObjects/MenuItem';
import Modifier from '../../../domainObjects/Modifier';
import MenuItemModifier from './MenuItemModifier';
import EposService from '../../../services/EposService';

function EditMenuItemSection(props) {
    const intl = useIntl();
    const [searchResults, setSearchResults] = useState([]);
    const [selectedItem, setSelectedItem] = useState(props.menuItem ? props.menuItem : null);
    const diaryContext = useContext(DiaryContext);
    const [menuItemOptions, setMenuItemOptions] = useState([]);
    const [hasRetrievedModifiers, toggleHasRetrievedModifiers] = useReducer(toggle, false);

    function toggle(value) {
        return !value;
    }

    const selectedItemEposId = selectedItem ? selectedItem.eposId : null;

    useEffect(() => {
        if (selectedItemEposId && props.editingSection && !hasRetrievedModifiers) {
            EposService.getMenuItem(diaryContext.deploymentId, diaryContext.restaurantId, selectedItemEposId).then(
                (item) => {
                    toggleHasRetrievedModifiers();
                    const options = item.OptionCategories.map((cat) => new Modifier(cat));
                    setMenuItemOptions(options);
                }
            );
        }
    }, [
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        selectedItemEposId,
        props.editingSection,
        hasRetrievedModifiers,
    ]);

    const processResponse = useCallback(
        (response) => {
            const menuItems = response.MenuItems;
            if (menuItems) {
                setSearchResults(menuItems);
                const results = menuItems.map((item) => ({
                    id: item.Id,
                    display: `${item.Name} ${diaryContext.currencySymbol}${item.Price}`,
                    alternateDisplay: item.Description,
                }));
                return results;
            } else {
                return [];
            }
        },
        [diaryContext.currencySymbol]
    );

    const makeRequest = useCallback(
        (searchTerm) => {
            return EposService.findMenuItems(diaryContext.deploymentId, diaryContext.restaurantId, searchTerm);
        },
        [diaryContext.deploymentId, diaryContext.restaurantId]
    );

    function getButtonText() {
        if (props.editingSection) {
            return <FormattedMessage id="Common.Update" />;
        }
        return <FormattedMessage id="Common.Add" />;
    }

    function handleTypeaheadItemSelected(itemId) {
        const item = searchResults.find((item) => item.Id === itemId);
        setSelectedItem(new MenuItem(item));
        const options = item.OptionCategories.map((cat) => new Modifier(cat));
        setMenuItemOptions(options);
    }

    function handleSubmit() {
        if (props.editingSection) {
            props.menuItemEdited(selectedItem);
        } else {
            props.menuItemAdded(selectedItem);
        }
        props.toggleIsOpen();
    }

    function menuItemModifierAdded(modifier) {
        const newMenuItem = { ...selectedItem };
        newMenuItem.optionCategories.push(modifier);
        props.menuItemEdited(newMenuItem);
    }

    function menuItemModifierEdited(modifier) {
        const newMenuItem = { ...selectedItem };
        const index = newMenuItem.optionCategories.findIndex((o) => o.uniqueIdentifier === modifier.uniqueIdentifier);
        newMenuItem.optionCategories[index] = modifier;
        props.menuItemEdited(newMenuItem);
    }

    function menuItemModifierDeleted(modifier) {
        const newMenuItem = { ...selectedItem };
        const index = newMenuItem.optionCategories.findIndex((o) => o.uniqueIdentifier === modifier.uniqueIdentifier);
        if (index >= 0) {
            newMenuItem.optionCategories.splice(index, 1);
            props.menuItemEdited(newMenuItem);
        }
    }

    function getMenuItemModifierElements() {
        return selectedItem.optionCategories.map((m) => {
            return (
                <MenuItemModifier
                    key={m.uniqueIdentifier}
                    modifier={m}
                    modifierDeleted={menuItemModifierDeleted}
                    menuItemModifierEdited={menuItemModifierEdited}
                    modifiers={menuItemOptions}
                />
            );
        });
    }

    return (
        <div
            className={
                props.editingSection ? 'grey-closable-panel menu-section editing' : 'grey-closable-panel menu-section'
            }
        >
            <Button
                className="close"
                onClick={() => {
                    props.toggleIsOpen();
                }}
            >
                <span aria-hidden="true">&times;</span>
            </Button>
            <h6 className="heading text-uppercase bold">
                {props.editingSection ? (
                    <FormattedMessage id="Settings.EditItem" />
                ) : (
                    <FormattedMessage id="Settings.AddItem" />
                )}
            </h6>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <FormGroup className="panel-form-group">
                    {selectedItem && (
                        <div className="d-flex menu-items-summary">
                            <div className="menu-items-summary-header">
                                <div className="bold-text text-uppercase">
                                    <FormattedMessage id="Settings.ItemName" />
                                </div>
                                <div className="panel-display-item">{selectedItem.name}</div>
                            </div>
                            <div className="menu-items-summary-header">
                                <div className="bold-text text-uppercase">
                                    <FormattedMessage id="Common.Description" />
                                </div>
                                <div className="panel-display-item">{selectedItem.description}</div>
                            </div>
                            <div className="menu-items-summary-header">
                                <div className="bold-text text-uppercase">
                                    <FormattedMessage id="Settings.Price" />
                                </div>
                                <CurrencyDiv price={selectedItem.price} className="panel-display-item" />
                            </div>
                        </div>
                    )}
                    {selectedItem && selectedItem.optionCategories.length > 0 && (
                        <div className="mt-4 modifiers">
                            <div className="bold-text text-uppercase mb-2">
                                <FormattedMessage id="Settings.Modifiers" />
                            </div>
                            {getMenuItemModifierElements()}
                        </div>
                    )}
                    {selectedItem &&
                        menuItemOptions.length > 0 &&
                        selectedItem.optionCategories.length !== menuItemOptions.length && (
                            <AddMenuItemModifierSection
                                menuItemModifierAdded={menuItemModifierAdded}
                                menuItemModifierEdited={menuItemModifierEdited}
                                modifiers={menuItemOptions}
                            />
                        )}
                    {!selectedItem && (
                        <TypeaheadWrapper
                            typeaheadItemSelected={(itemId) => {
                                handleTypeaheadItemSelected(itemId);
                            }}
                            requestFunctionAsync={makeRequest}
                            processResultsFunction={processResponse}
                            placeholder={intl.formatMessage({ id: 'Settings.SearchItem' })}
                            useDangerouslySetHTML={true}
                            autoFocus
                            addErrorBar={props.addErrorBar}
                        />
                    )}
                </FormGroup>

                <FormGroup className="grey-closable-panel-footer">
                    <div className="button-wrapper">
                        <Button
                            color="primary"
                            disabled={!selectedItem}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {getButtonText()}
                        </Button>
                    </div>
                </FormGroup>
            </Form>
        </div>
    );
}

EditMenuItemSection.propTypes = {
    toggleIsOpen: PropTypes.func,
    menuItemAdded: PropTypes.func,
    menuItemEdited: PropTypes.func,
    editingSection: PropTypes.bool,
    addErrorBar: PropTypes.func.isRequired,
};

export default EditMenuItemSection;
