import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as WarningIcon } from '../../content/icons/Icon-Alert.svg';
import ExpandableSection from '../common/ExpandableSection';
import { useIntl, FormattedMessage } from 'react-intl';

function ValidationSummaryContainer(props) {
    const intl = useIntl();

    function getValidationTitle() {
        return props.validationErrors.map((v) => {
            return (
                <div key={v.title} className="d-flex warning-message">
                    <WarningIcon />
                    {v.title}
                </div>
            );
        });
    }

    function getValidationDescription() {
        return props.validationErrors.map((v) => {
            return (
                v.description && (
                    <div key={v.description} className="description">
                        <FormattedMessage id="Common.Description" />: {v.description}
                    </div>
                )
            );
        });
    }

    if (props.validationErrors && props.validationErrors.length > 0) {
        return (
            <div className="validation-warning">
                {getValidationTitle()}
                <ExpandableSection
                    previewHeight={0}
                    expandedText={intl.formatMessage({ id: 'Common.Hide' })}
                    hiddenText={intl.formatMessage({ id: 'Common.More' })}
                >
                    {getValidationDescription()}
                </ExpandableSection>
            </div>
        );
    } else {
        return null;
    }
}

ValidationSummaryContainer.propTypes = {
    validationErrors: PropTypes.array.isRequired,
};

export default ValidationSummaryContainer;
