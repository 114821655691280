import LoginPage from './login/LoginPage';

function RequireAuth({ children, auth, versionNumber, setLoading }) {
    return auth.authed === true ? (
        children
    ) : (
        <LoginPage versionNumber={versionNumber} login={auth.login} setLoading={setLoading} />
    );
}

export default RequireAuth;
