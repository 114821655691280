import React from 'react';
import StripeCheckoutForm from './StripeCheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function StripeCheckoutFormElementsWrapper(props) {
    const stripePromise = loadStripe(props.stripePublishableKey);
    return (
        <Elements stripe={stripePromise}>
            <StripeCheckoutForm
                clientSecret={props.clientSecret}
                campaignCode={props.campaignCode}
                alpha2Code={props.alpha2Code}
                countries={props.countries}
                productInformation={props.productInformation}
            />
        </Elements>
    );
}

export default StripeCheckoutFormElementsWrapper;
