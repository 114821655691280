import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { Container, Form } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import infoBarType from '../../enums/infoBarType';
import InfoBars from '../../components/common/InfoBars';
import { FormGroup, FormText } from 'reactstrap';
import { ReactComponent as ResIcon } from '../../content/images/rd-lite-logo.svg';
import { ReactComponent as ConfirmTick } from '../../content/icons/confirm.svg';
import ValidationInput from '../common/ValidationInput';
import useValidation from '../../hooks/useValidation';
import AccountService from '../../services/AccountService';
import LoadingSpinner from '../common/LoadingSpinner';
import { Link } from 'react-router-dom';
import useScreenSize from '../../hooks/useScreenSize';
import useQueryParam from '../../hooks/useQueryParam';

let _ID = 0;

function ResetPasswordPage(props) {
    const intl = useIntl();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [infoBars, setInfoBars] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [checkingToken, setCheckingToken] = useState(true);
    const [tokenIsInvalid, setTokenIsInvalid] = useState(false);
    const [token, setToken] = useState('');
    const addErrorBar = useCallback(
        (message) => {
            setIsSubmitting(false);
            setInfoBars([...infoBars, { id: _ID++, type: infoBarType.error, message: message }]);
        },
        [infoBars]
    );

    const { isMobileView } = useScreenSize();
    const { register, errors, submitDisabled, submit } = useValidation();
    const tokenQueryParam = useQueryParam('token');

    useEffect(() => {
        setToken(tokenQueryParam);
        AccountService.checkPasswordResetToken(tokenQueryParam)
            .then((result) => {
                if (result.data === true) {
                    setCheckingToken(false);
                } else {
                    setCheckingToken(false);
                    setTokenIsInvalid(true);
                }
            })
            .catch(() => {
                addErrorBar();
            });
    }, [addErrorBar, tokenQueryParam]);

    function submitResetPassword() {
        setIsSubmitting(true);
        AccountService.resetPassword(token, password)
            .then((result) => {
                if (result.data.isSuccess === true) {
                    setSubmitted(true);
                } else {
                    addErrorBar(result.data.errorMessage);
                }
            })
            .catch(() => {
                addErrorBar();
            });
    }

    function getResetFormContent() {
        if (checkingToken) return <LoadingSpinner noMargin />;
        else if (tokenIsInvalid) {
            return (
                <Fragment>
                    <FormGroup>
                        <FormattedMessage id="Login.ResetPasswordRequestInvalid" />{' '}
                        <Link className="text-lowercase" to="/ForgotPassword">
                            <FormattedMessage id="Common.ClickHere" />
                        </Link>
                    </FormGroup>
                    <FormGroup>
                        <Link className="login-button btn btn-outline-primary" to="/">
                            <FormattedMessage id="Login.BackToLogin" />
                        </Link>
                    </FormGroup>
                </Fragment>
            );
        } else if (!submitted) {
            return (
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        submit(submitResetPassword);
                    }}
                >
                    <FormGroup>
                        <FormText>
                            <FormattedMessage id="Login.ResetPasswordMessage" />{' '}
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <ValidationInput
                            testId="reset-password-form-password"
                            type="password"
                            name="password"
                            placeholder={intl.formatMessage({ id: 'Login.Password' })}
                            value={password}
                            onChange={(e) => setPassword(e)}
                            innerRef={register({
                                required: intl.formatMessage({ id: 'Login.PasswordRequired' }),
                            })}
                            errors={errors}
                            autoFocus
                        />
                    </FormGroup>
                    <FormGroup>
                        <ValidationInput
                            testId="reset-password-form-confirm-password"
                            type="password"
                            name="confirm-password"
                            placeholder={intl.formatMessage({ id: 'Login.ConfirmPassword' })}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e)}
                            innerRef={register({
                                validate: {
                                    passwordMatches: (value) =>
                                        value === password
                                            ? true
                                            : intl.formatMessage({ id: 'Login.ConfirmPasswordMatch' }),
                                },
                            })}
                            errors={errors}
                        />
                    </FormGroup>
                    <FormGroup>
                        <input
                            disabled={isSubmitting || submitDisabled}
                            type="submit"
                            value={
                                isSubmitting
                                    ? intl.formatMessage({
                                          id: 'Common.Submitting',
                                      })
                                    : intl.formatMessage({
                                          id: 'Common.Submit',
                                      })
                            }
                            className="login-button btn btn-primary"
                            data-testid="submit-button"
                        />
                    </FormGroup>
                </Form>
            );
        } else {
            return (
                <Fragment>
                    <ConfirmTick className="success-icon" />
                    <FormGroup>
                        <span data-testid="success-message">
                            <FormattedMessage id="Login.ResetPasswordSuccessMessage" />
                        </span>
                    </FormGroup>
                    <FormGroup>
                        <a className="login-button btn btn-outline-primary" href="/">
                            <FormattedMessage id="Login.BackToLogin" />
                        </a>
                    </FormGroup>
                </Fragment>
            );
        }
    }

    return (
        <Container className="login-page">
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <div className="flex-container-center">
                <div className="login-image">
                    <ResIcon />
                </div>
                <div className={isMobileView ? 'login-form-container mobile' : 'login-form-container'}>
                    {getResetFormContent()}
                </div>
            </div>
        </Container>
    );
}

export default ResetPasswordPage;
