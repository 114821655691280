import React, { Fragment, useCallback, useEffect, useState, useContext } from 'react';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import BreadCrumbDisplay from '../../common/BreadCrumbDisplay';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Badge } from 'reactstrap';
import SquareService from '../../../services/SquareService';
import LoadingSpinner from '../../common/LoadingSpinner';
import Dropdown from '../../common/Dropdown';
import DiaryContext from '../../../contexts/DiaryContext';
import SettingsHelper from '../../../helpers/SettingsHelper';
import infoBarType from '../../../enums/infoBarType';
import InfoBars from '../../common/InfoBars';
import DisconnectSquareModal from './DisconnectSquareModal';
import { v4 as uuidv4 } from 'uuid';
import CookiesHelper from '../../../helpers/CookiesHelper';
import squareIntegrationStatus from '../../../enums/squareIntegrationStatus';
import { useNavigate } from 'react-router-dom';

let _ID = 0;

function SquareSettingsPage(props) {
    const intl = useIntl();
    const csrfTokenCookieName = 'Auth_State';
    const diaryContext = useContext(DiaryContext);
    const squareUrl = SettingsHelper.getSquareUrl();
    const squareApplicationId = SettingsHelper.getSquareApplicationId();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const authorisationCodeParam = params.get('code');
    const errorParam = params.get('error');
    const errorDescriptionParam = params.get('error_description');
    const authStateParam = params.get('state');
    //state
    const [loading, setLoading] = useState(true);
    const [accountName, setAccountName] = useState(false);
    const [accountStatus, setAccountStatus] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [infoBars, setInfoBars] = useState([]);
    const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
    const [integrationStatus, setIntegrationStatus] = useState(null);
    const [fetchingAccount, setFetchingAccount] = useState(false);
    const [squareLocations, setSquareLocations] = useState([]);
    const [selectedSquareLocationName, setSelectedSquareLocationName] = useState('');
    const [isLoadingLocations, setIsLoadingLocations] = useState(false);
    const [isSettingLocation, setIsSettingLocation] = useState(false);
    const navigate = useNavigate();

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const hasValidSquareAccount = useCallback(() => {
        return accountName && accountStatus && integrationStatus === squareIntegrationStatus.valid;
    }, [accountName, integrationStatus, accountStatus]);

    function getBreadcrumbOptions() {
        return [new BreadCrumbOption(intl.formatMessage({ id: 'Settings.Title' }), '/Settings')];
    }

    function getCsrfTokenFromCookie() {
        return CookiesHelper.getCookie(csrfTokenCookieName);
    }

    function setCsrfTokenCookie() {
        const csrfToken = uuidv4();
        CookiesHelper.setCookie(csrfTokenCookieName, csrfToken, { maxAge: 120, sameSite: true, secure: true });
        return csrfToken;
    }

    useEffect(() => {
        if (loading && !fetchingAccount && !(authorisationCodeParam || errorParam || errorDescriptionParam)) {
            setFetchingAccount(true);
            SquareService.getSquareAccount(diaryContext.deploymentId, diaryContext.restaurantId)
                .then((data) => {
                    if (data) {
                        if (data.IntegrationStatus === squareIntegrationStatus.valid) {
                            setAccountName(data.AccountName);
                            setAccountStatus(data.AccountStatus);
                        }
                        setIntegrationStatus(data.IntegrationStatus);
                        setSelectedSquareLocationName(data.LocationName);
                    }
                    setLoading(false);
                    setFetchingAccount(false);
                })
                .catch(() => addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' })));
        }
    }, [
        loading,
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        authorisationCodeParam,
        errorParam,
        errorDescriptionParam,
        addErrorBar,
        intl,
        integrationStatus,
        fetchingAccount,
    ]);

    useEffect(() => {
        if (authorisationCodeParam && !connecting) {
            const csrfToken = getCsrfTokenFromCookie();
            if (authStateParam && authStateParam === csrfToken) {
                setConnecting(true);
                SquareService.connectSquareAccount(
                    diaryContext.deploymentId,
                    diaryContext.restaurantId,
                    authorisationCodeParam
                )
                    .then(() => {
                        diaryContext.toggleHasSquareEnabled();
                        addSuccessBar(intl.formatMessage({ id: 'Settings.SquareConnectSuccessMessage' }));
                    })
                    .catch(() => {
                        addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
                    })
                    .finally(() => {
                        navigate('/Settings/Square');
                    });
            } else {
                //most likely the user has taken too long to redirect back,
                //the state cookie is only valid for a short period
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
                navigate('/Settings/Square');
            }
        } else if (errorParam && errorDescriptionParam) {
            if (errorDescriptionParam === 'user_denied') {
                addErrorBar(intl.formatMessage({ id: 'Settings.SquareConnectUserDeniedErrorMessage' }));
            } else {
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
            }
            navigate('/Settings/Square');
        }
    }, [
        authorisationCodeParam,
        errorParam,
        errorDescriptionParam,
        diaryContext,
        connecting,
        addErrorBar,
        navigate,
        intl,
        authStateParam,
    ]);

    useEffect(() => {
        if (hasValidSquareAccount() && !isLoadingLocations) {
            setIsLoadingLocations(true);
            SquareService.getSquareLocations(diaryContext.deploymentId, diaryContext.restaurantId)
                .then((locations) => {
                    setSquareLocations(
                        locations.map((location) => {
                            return { text: location.Name, value: location.Id, currencyId: location.Currency };
                        })
                    );
                })
                .catch(() => {
                    addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
                });
        }
    }, [
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        intl,
        addErrorBar,
        hasValidSquareAccount,
        squareLocations,
        isLoadingLocations,
    ]);

    function connectSquareAccount() {
        const csrfToken = setCsrfTokenCookie();
        window.location =
            `${squareUrl}/oauth2/authorize?` +
            `client_id=${squareApplicationId}` +
            `&state=${csrfToken}` +
            `&session=${squareApplicationId.startsWith('sandbox')}` +
            `&scope=` +
            `PAYMENTS_WRITE+` +
            `PAYMENTS_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+` +
            `ORDERS_READ+ORDERS_WRITE+` +
            `ITEMS_READ+` +
            'MERCHANT_PROFILE_READ+' +
            'CUSTOMERS_READ+' +
            'CUSTOMERS_WRITE';
    }

    function disconnectSquareAccount(callback) {
        SquareService.disconnectSquareAccount(diaryContext.deploymentId, diaryContext.restaurantId)
            .then(() => {
                setAccountName(null);
                setAccountStatus(null);
                diaryContext.toggleHasSquareEnabled();
                addSuccessBar(intl.formatMessage({ id: 'Settings.SquareDisconnectSuccessMessage' }));
            })
            .catch(() => {
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
            })
            .finally(() => {
                if (callback) {
                    callback();
                }
            });
    }

    function getInegrationStatusDisplayName() {
        switch (integrationStatus) {
            case squareIntegrationStatus.valid:
                return intl.formatMessage({ id: 'Settings.SquareIntegrationStatusValid' });
            case squareIntegrationStatus.revoked:
                return intl.formatMessage({ id: 'Settings.SquareIntegrationStatusRevoked' });
            case squareIntegrationStatus.expired:
                return intl.formatMessage({ id: 'Settings.SquareIntegrationStatusExpired' });
            case squareIntegrationStatus.unauthorised:
                return intl.formatMessage({ id: 'Settings.SquareIntegrationStatusUnauthorised' });
            default:
                return;
        }
    }

    function setSquareLocation(locationId) {
        const location = squareLocations.find((l) => l.value === locationId);
        if (diaryContext.currencyId !== location.currencyId) {
            addErrorBar(intl.formatMessage({ id: 'Settings.SettingCurrencyValidation' }));
        } else {
            setIsSettingLocation(true);
            SquareService.setSquareLocation(diaryContext.deploymentId, diaryContext.restaurantId, locationId)
                .then(() => {
                    setSelectedSquareLocationName(location.text);
                })
                .catch(() => {
                    addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
                })
                .finally(() => {
                    setIsSettingLocation(false);
                });
        }
    }

    function getBody() {
        if (hasValidSquareAccount()) {
            return (
                <Fragment>
                    <p>
                        <FormattedMessage id="Settings.SquareConnectPrompt" />
                    </p>
                    <div className="content-with-header">
                        <div className="header">
                            <FormattedMessage id="Common.Square" />
                            <Badge color="info">{accountStatus}</Badge>
                        </div>
                        <div className="body">
                            <div>
                                <FormattedMessage id="Settings.SquareAccountName" />
                                &#58; {accountName}
                            </div>
                            {!selectedSquareLocationName && (
                                <Dropdown
                                    inputGroupClassName="location-dropdown"
                                    onValueChange={setSquareLocation}
                                    options={squareLocations}
                                    selectedValue={props.selectedFilter}
                                    hideRemoveItemIcon={true}
                                    defaultValue={intl.formatMessage({ id: 'Settings.PleaseSelectLocation' })}
                                />
                            )}
                            {selectedSquareLocationName && (
                                <div>
                                    <FormattedMessage
                                        id="Settings.SquareLocation"
                                        values={{ squareLocation: selectedSquareLocationName }}
                                    />
                                </div>
                            )}
                            <Button
                                className="settings-button"
                                color="primary"
                                disabled={isSettingLocation}
                                onClick={() => setDisconnectModalOpen(true)}
                            >
                                {!isSettingLocation ? (
                                    <FormattedMessage id="Common.Disconnect" />
                                ) : (
                                    <FormattedMessage id="Settings.SettingLocation" />
                                )}
                            </Button>
                        </div>
                    </div>
                    {disconnectModalOpen && (
                        <DisconnectSquareModal
                            isModalOpen={disconnectModalOpen}
                            setIsModalOpen={setDisconnectModalOpen}
                            disconnectSquare={disconnectSquareAccount}
                        />
                    )}
                </Fragment>
            );
        } else if (integrationStatus && integrationStatus !== squareIntegrationStatus.valid) {
            return (
                <Fragment>
                    <p>
                        <FormattedMessage id="Settings.SquareConnectPrompt" />
                    </p>
                    <div className="content-with-header">
                        <div className="header">
                            <FormattedMessage id="Common.Square" />
                            <Badge color="warning">{getInegrationStatusDisplayName()}</Badge>
                        </div>
                        <div className="body">
                            <p>
                                <FormattedMessage id="Settings.SquareReconnectAccountPrompt" />
                            </p>
                            <Button className="settings-button" color="primary" onClick={connectSquareAccount}>
                                <FormattedMessage id="Settings.SquareReconnectButtonLabel" />
                            </Button>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <p>
                        <FormattedMessage id="Settings.SquareConnectPrompt" />
                    </p>
                    <div className="content">
                        <div className="body">
                            <h6>
                                <FormattedMessage id="Settings.SquareConnectTitle" />
                            </h6>
                            <Button className="settings-button" color="primary" onClick={connectSquareAccount}>
                                <FormattedMessage id="Settings.SquareConnectButtonLabel" />
                            </Button>
                            <div>
                                <p>
                                    <FormattedMessage id="Settings.SquareTermsParagraph1" />{' '}
                                    <a href="https://squareup.com" target="_blank" rel="noopener noreferrer">
                                        <FormattedMessage id="Common.Square" />
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <div>
                <BreadCrumbDisplay
                    breadcrumbs={getBreadcrumbOptions()}
                    activePageTitle={intl.formatMessage({
                        id: 'Settings.ManageSquareTitleText',
                    })}
                />
                <h2>
                    <FormattedMessage id="Common.Square" />
                </h2>
                {loading && <LoadingSpinner></LoadingSpinner>}
                {!loading && (
                    <Fragment>
                        <div className="bg-white integration-container">{getBody()}</div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}

export default SquareSettingsPage;
