import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { ReactComponent as SearchIcon } from '../../content/icons/search.svg';
import { FormattedMessage } from 'react-intl';
import propTypeExtensions from '../../helpers/PropTypeExtensions';
import ValidationInput from './ValidationInput';

function Typeahead({
    results,
    setSelectedResult,
    searchTerm,
    setSearchTerm,
    isSearching,
    addNew,
    addNewFunction,
    addNewTitle,
    registerValidation,
    errors,
    includeValidation,
    placeholder,
    validationMessage,
    validationName,
    disabled,
    useDangerouslySetHTML,
    autoFocus,
}) {
    const [displayResults, setDisplayResults] = useState(false);

    const typeaheadInput = useRef();

    const handleSearchTermChange = (searchTerm) => {
        setSearchTerm(searchTerm);
        if (searchTerm.length >= 3) {
            setDisplayResults(true);
        } else {
            setDisplayResults(false);
        }
    };

    const handleResultSelected = (id, event) => {
        event.stopPropagation();
        setDisplayResults(false);
        setSelectedResult(id);
    };

    let getResults = () => {
        if (results.length > 0) {
            return results.map((result, index) => {
                return (
                    <li
                        onMouseDown={(event) => handleResultSelected(result.id, event)}
                        key={result.id}
                        data-testid={`typeahead-list-option-${index}`}
                    >
                        {!useDangerouslySetHTML && (
                            <div data-testid={`typeahead-list-option-${index}-display-value`}>{result.display}</div>
                        )}
                        {useDangerouslySetHTML && (
                            <div
                                data-testid={`typeahead-list-option-${index}-display-value`}
                                dangerouslySetInnerHTML={{ __html: `<div>${result.display}</div>` }}
                            ></div>
                        )}
                        <div data-testid={`typeahead-list-option-${index}-alt-display-value`}>
                            {result.alternateDisplay}
                        </div>
                    </li>
                );
            });
        }
        return (
            <div data-testid="typeahead-list-no-results" className="no-results">
                <FormattedMessage id={isSearching ? 'Common.Searching' : 'Common.NoResults'} />
            </div>
        );
    };

    const isInvalid = includeValidation ? errors[validationName] : false;
    const isLoading = false;
    return (
        <div>
            <InputGroup
                className={
                    isInvalid
                        ? `input-group-with-addon is-invalid search-dropdown-addon`
                        : `input-group-with-addon search-dropdown-addon`
                }
            >
                {!isLoading && (
                    <InputGroupAddon addonType="prepend" onClick={() => typeaheadInput.current.focus()}>
                        <InputGroupText>
                            <SearchIcon />
                        </InputGroupText>
                    </InputGroupAddon>
                )}
                {includeValidation && (
                    <ValidationInput
                        placeholder={placeholder}
                        type="search"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        onBlur={() => {
                            setDisplayResults(false);
                        }}
                        onFocus={() => {
                            if (searchTerm.length >= 3) {
                                setDisplayResults(true);
                            }
                        }}
                        innerRef={registerValidation({
                            required: validationMessage,
                        })}
                        name={validationName}
                        errors={errors}
                        testId="typeahead-input"
                        autoFocus={autoFocus}
                    />
                )}
                {!includeValidation && (
                    <Input
                        placeholder={placeholder}
                        type="search"
                        value={searchTerm}
                        onChange={(e) => handleSearchTermChange(e.target.value)}
                        onBlur={() => {
                            setDisplayResults(false);
                        }}
                        onFocus={() => {
                            if (searchTerm.length >= 3) {
                                setDisplayResults(true);
                            }
                        }}
                        innerRef={typeaheadInput}
                        data-testid="typeahead-input"
                        disabled={disabled}
                        autoFocus={autoFocus}
                    />
                )}
                {displayResults && (
                    <ul data-testid="typeahead-list" className="search-dropdown-results">
                        {addNew && (
                            <li className="add-new" onMouseDown={() => addNewFunction(searchTerm)}>
                                {addNewTitle}
                            </li>
                        )}
                        {getResults()}
                    </ul>
                )}
            </InputGroup>
        </div>
    );
}

Typeahead.propTypes = {
    setSelectedResult: PropTypes.func.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    results: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            display: PropTypes.string.isRequired,
            alternateDisplay: PropTypes.string,
        })
    ).isRequired,
    isSearching: PropTypes.bool.isRequired,
    searchTerm: PropTypes.string,
    addNew: PropTypes.bool,
    addNewFunction: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.addNew),
    addNewTitle: propTypeExtensions.requiredIf(PropTypes.string, (props) => props.addNew),
    disabled: PropTypes.bool,
    useDangerouslySetHTML: PropTypes.bool,
    autoFocus: PropTypes.bool,
};

export default Typeahead;
