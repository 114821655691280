import { v4 as uuidv4 } from 'uuid';
import MenuItem from './MenuItem';

class MenuSectionCategory {
    constructor(category) {
        this.id = category && category.Id ? category.Id : null;
        this.name = category && category.Name ? category.Name : '';
        this.menuItems = category && category.MenuItems ? category.MenuItems.map((item) => new MenuItem(item)) : [];
        this.displayOrder = category && category.DisplayOrder ? category.DisplayOrder : 0;
        this.uniqueIdentifier = uuidv4();
    }

    populateFromDiaryDto(diarySectionDto) {
        this.id = diarySectionDto.Id;
        this.name = diarySectionDto.Name;
        this.displayOrder = diarySectionDto.DisplayOrder;
        this.menuItems = diarySectionDto.MenuItems
            ? diarySectionDto.MenuItems.map((item) => {
                  const menuItem = new MenuItem();
                  menuItem.populateFromDiaryDto(item);
                  return menuItem;
              })
            : [];
    }
}

export default MenuSectionCategory;
