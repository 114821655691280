import React, { useState, useContext, Fragment } from 'react';
import DropdownExamples from './DropdownExamples';
import DatePickerExamples from './DatePickerExamples';
import TableHeadersExamples from './TableHeadersExamples';
import TypeaheadExamples from './TypeaheadExamples';
import SessionContext from '../../contexts/SessionContext';
import { Button } from 'reactstrap';

function Components(props) {
    const [showBrokenJSX, setshowBrokenJSX] = useState(false);
    const sessionContext = useContext(SessionContext);

    return (
        <Fragment>
            <div>
                <h1 data-testid="components-page-header">
                    Hi {sessionContext.displayName}, here's some component examples
                </h1>
            </div>
            <DropdownExamples />
            <DatePickerExamples />
            <TableHeadersExamples />
            <TypeaheadExamples />
            <Button
                onClick={() => {
                    setshowBrokenJSX(true);
                }}
                color="primary"
            >
                Click me for error
            </Button>
            {showBrokenJSX && (
                <BROKENJSX // eslint-disable-line react/jsx-no-undef
                />
            )}
        </Fragment>
    );
}

export default Components;
