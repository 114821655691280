import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import ValidationMessage from '../common/ValidationMessage';
import MenuOverlayExpander from './MenuOverlayExpander';

function MenuOverlayContent(props) {
    const intl = useIntl();

    function getMenuExpander() {
        return (
            <div>
                <MenuOverlayExpander
                    overlayContent={props.overlayContent}
                    menus={props.menus}
                    sendMenuNotification={props.sendMenuNotification}
                    openMenuChangeModal={props.openMenuChangeModal}
                />
            </div>
        );
    }

    function getSendMenuButton() {
        return (
            <div className="menu-overlay-wrapper">
                <div className="info-block top">
                    <h6>
                        <FormattedMessage id="Bookings.SendAMenu" />
                    </h6>
                    {props.menus.length === 0 && (
                        <ValidationMessage
                            message={intl.formatMessage({ id: 'Bookings.SendNoMenuWarningMessage' })}
                            isBlock
                        />
                    )}

                    {props.menus.length > 0 &&
                        (!props.overlayContent.booking.customer || !props.overlayContent.booking.customer.email) && (
                            <ValidationMessage
                                message={intl.formatMessage({ id: 'Bookings.SendMenuButtonWarningMessage' })}
                                isBlock
                            />
                        )}
                    {props.menus.length > 0 && (
                        <Fragment>
                            <div className="menu-overlay-description">
                                <FormattedMessage id="Bookings.SendAMenuDescription" />
                            </div>
                            <div className="bold-text menu-tip">
                                <FormattedMessage id="Bookings.SendAMenuTip" />
                            </div>
                            {getMenuExpander()}
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }

    return <Fragment>{getSendMenuButton()}</Fragment>;
}

MenuOverlayContent.propTypes = {
    overlayContent: PropTypes.object,
    menus: PropTypes.array,
    sendMenuNotification: PropTypes.func,
    openMenuChangeModal: PropTypes.func,
};

export default MenuOverlayContent;
