import MomentHelper from './MomentHelper';

class MenuDetailsHelper {
    static toJson(menu) {
        return {
            Id: menu.id,
            Name: menu.name,
            FileName: menu.fileName,
            MenuUrl: menu.menuUrl,
            Type: menu.type,
            IsEnabled: menu.isEnabled,
            CultureCode: menu.cultureCode,
            DisplayOnlineFrom: menu.displayOnlineFrom
                ? MomentHelper.toServerDateTimeFormat(menu.displayOnlineFrom)
                : null,
            DisplayOnlineUntil: menu.displayOnlineUntil
                ? MomentHelper.toServerDateTimeFormat(menu.displayOnlineUntil)
                : null,
            UploadedDate: menu.uploadedDate,
            UniqueIdentifier: menu.uniqueIdentifier,
        };
    }
}

export default MenuDetailsHelper;
