import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import ConfirmActionButton from '../common/ConfirmActionButton';
import SelectableText from '../common/SelectableText';
import TextWithIcon from '../common/TextWithIcon';
import { ReactComponent as FullySeatedIcon } from '../../content/icons/Icon-FullySeated.svg';
import { ReactComponent as CancelIcon } from '../../content/icons/Icon-Cancel.svg';
import { ReactComponent as ClockIcon } from '../../content/icons/Icon-Clock.svg';
import { ReactComponent as CloseIcon } from '../../content/icons/Icon-CloseOut.svg';
import { ReactComponent as SuccessIcon } from '../../content/icons/Icon-Success.svg';
import { ReactComponent as UnallocatedBookingIcon } from '../../content/icons/Icon-Unallocated.svg';
import Option from '../common/Option';
import confirmedStatus from '../../enums/confirmedStatus';
import arrivalStatus from '../../enums/arrivalStatus';
import bookingStatus from '../../enums/bookingStatus';
import selectableTextColour from '../../enums/selectableTextColour';
import MomentHelper from '../../helpers/MomentHelper';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import useOutsideClick from '../../hooks/useOutsideClick';
import propTypeExtensions from '../../helpers/PropTypeExtensions';

function BookingStatusControl(props) {
    const [isLate, setIsLate] = useState(false);
    const intl = useIntl();

    const bookingStatusControlRef = useRef(null);

    useEffect(() => {
        setIsLate(MomentHelper.isDateBeforeNow(props.booking.visitDateTime));
    }, [props.booking]);

    function updateArrivalStatus(event, status) {
        event.stopPropagation();
        props.updateArrivalStatus(status);
    }

    function closeBooking(event) {
        event.stopPropagation();
        props.closeBooking();
    }

    function cancelBooking(event) {
        event.stopPropagation();
        props.openCancellationModalWithBookingId();
    }

    function rebook(event) {
        event.stopPropagation();
        return props.rebookBooking();
    }

    function getButtons() {
        if (props.booking.status === bookingStatus.cancelled) {
            return (
                <ButtonGroup vertical className="booking-status-button-group">
                    <Button className="btn-outline-primary" onClick={(e) => rebook(e)}>
                        {intl.formatMessage({ id: 'Bookings.Rebook' })}
                    </Button>
                </ButtonGroup>
            );
        } else if (props.booking.isFutureBooking) {
            return (
                <ButtonGroup vertical className="booking-status-button-group">
                    <Button className="btn-outline-primary cancel-btn" onClick={(e) => cancelBooking(e)}>
                        {intl.formatMessage({ id: 'Bookings.CancelBooking' })}
                    </Button>
                </ButtonGroup>
            );
        } else if (props.booking.isUnallocated) {
            return (
                <ButtonGroup vertical className="booking-status-button-group">
                    <Button className="btn-outline-primary" onClick={(e) => rebook(e)}>
                        {intl.formatMessage({ id: 'Bookings.EditBookingLowercase' })}
                    </Button>
                </ButtonGroup>
            );
        } else {
            return (
                <Fragment>
                    <ButtonGroup vertical className="booking-status-button-group">
                        <Button
                            className={getBookingStatusButtonStyle(arrivalStatus.notArrived)}
                            onClick={(e) => updateArrivalStatus(e, arrivalStatus.notArrived)}
                        >
                            <TextWithIcon
                                text={
                                    isLate
                                        ? intl.formatMessage({ id: 'Bookings.Late' })
                                        : intl.formatMessage({ id: 'Bookings.Expected' })
                                }
                                icon={<ClockIcon />}
                                iconClass={isLate ? 'late' : 'expected'}
                            />
                        </Button>
                        <Button
                            className={getBookingStatusButtonStyle(arrivalStatus.fullySeated)}
                            onClick={(e) => updateArrivalStatus(e, arrivalStatus.fullySeated)}
                        >
                            <TextWithIcon
                                text={intl.formatMessage({ id: 'Bookings.FullySeated' })}
                                icon={<FullySeatedIcon />}
                                iconClass="fully-seated"
                            />
                        </Button>
                        <Button
                            className={getBookingStatusButtonStyle(bookingStatus.closed)}
                            onClick={(e) => closeBooking(e)}
                        >
                            <TextWithIcon
                                text={intl.formatMessage({ id: 'Bookings.Closed' })}
                                icon={<CloseIcon />}
                                iconClass="closed"
                            />
                        </Button>
                    </ButtonGroup>
                </Fragment>
            );
        }
    }

    function getBookingStatusButtonStyle(status) {
        const classes = ['btn-light'];
        if (props.booking.status === status || props.booking.arrivalStatus === status) {
            if (
                !(status === arrivalStatus.fullySeated && props.booking.status !== bookingStatus.confirmed) &&
                !(status === arrivalStatus.notArrived && props.booking.status === bookingStatus.closed)
            ) {
                classes.push('active-item');
            }
        }
        return classes.join(' ');
    }

    function getConfirmedStatus() {
        return props.booking.isReConfirmed ? confirmedStatus.bookingConfirmed : confirmedStatus.bookingMade;
    }

    function getBookingConfirmationOptions() {
        return [
            new Option(
                intl.formatMessage({ id: 'Bookings.BookingConfirmed' }),
                confirmedStatus.bookingConfirmed,
                selectableTextColour.rdGreen
            ),
            new Option(
                intl.formatMessage({ id: 'Bookings.BookingMade' }),
                confirmedStatus.bookingMade,
                selectableTextColour.blue
            ),
        ];
    }

    function getArrivalStatus() {
        if (props.booking.status === bookingStatus.cancelled) {
            return intl.formatMessage({ id: 'Bookings.Cancelled' });
        } else if (props.booking.status === bookingStatus.closed) {
            return intl.formatMessage({ id: 'Bookings.Closed' });
        } else if (props.booking.arrivalStatus === arrivalStatus.fullySeated) {
            return intl.formatMessage({ id: 'Bookings.FullySeated' });
        } else if (props.booking.isUnallocated) {
            return intl.formatMessage({ id: 'Bookings.Unallocated' });
        } else {
            return isLate
                ? intl.formatMessage({ id: 'Bookings.Late' })
                : intl.formatMessage({ id: 'Bookings.Expected' });
        }
    }

    function getIcon() {
        if (props.booking.status === bookingStatus.cancelled) {
            return (
                <div>
                    <CancelIcon />
                </div>
            );
        } else if (props.booking.isUnallocated) {
            return (
                <div className="booking-unallocated-icon">
                    <UnallocatedBookingIcon />
                </div>
            );
        } else if (props.booking.status === bookingStatus.closed) {
            return (
                <div>
                    <CloseIcon />
                </div>
            );
        } else if (props.booking.arrivalStatus === arrivalStatus.fullySeated) {
            return (
                <div className={'booking-icon-fully-seated'}>
                    <FullySeatedIcon />
                </div>
            );
        } else if (props.booking.arrivalStatus === arrivalStatus.notArrived) {
            return (
                <div className={getNotArrivedIconStyle()}>
                    <ClockIcon />
                </div>
            );
        }
    }

    function getNotArrivedIconStyle() {
        return MomentHelper.isDateBeforeNow(props.booking.visitDateTime) ? 'booking-icon-late' : 'booking-icon-default';
    }

    function toggleBookingStatusTooltip(e) {
        e.stopPropagation();
        return props.toggleBookingStatusTooltip();
    }

    function clickIsInPopoverContainer(element) {
        if (element.classList && element.classList.contains('popover-content-wrapper')) return true;
        return element.parentNode && clickIsInPopoverContainer(element.parentNode, 'popover-content-wrapper');
    }

    function closeBookingStatusControl(e) {
        if (props.tooltipOpen && !clickIsInPopoverContainer(e.target)) {
            props.closeBookingStatusControl();
        }
    }

    function getDisabledButtonText() {
        return props.booking.status === bookingStatus.cancelled
            ? intl.formatMessage({ id: 'Bookings.BookingCancelled' })
            : intl.formatMessage({ id: 'Bookings.BookingUnallocated' });
    }

    useOutsideClick([bookingStatusControlRef], closeBookingStatusControl);

    return (
        <Fragment>
            <div
                className={
                    props.wrapperClassName
                        ? 'booking-status-control-wrapper ' + props.wrapperClassName
                        : 'booking-status-control-wrapper'
                }
                ref={bookingStatusControlRef}
            >
                <ConfirmActionButton
                    id={props.bookingStatusButtonId}
                    className="booking-status-icon-button"
                    label={props.includeStatusTextOnButton ? getArrivalStatus() : null}
                    icon={getIcon()}
                    tooltipPlacement={props.tooltipPlacement}
                    tooltipOpen={props.tooltipOpen}
                    toggle={props.toggleBookingStatusTooltip}
                    buttonClassName={props.buttonClassName}
                    isMultiSelectorStatusControl
                    disabled={props.readOnly}
                >
                    <SelectableText
                        selectedValue={getConfirmedStatus()}
                        options={getBookingConfirmationOptions()}
                        onChange={() => props.toggleReconfirmedStatus()}
                        disabled={props.booking.status === bookingStatus.cancelled || props.booking.isUnallocated}
                        disabledText={getDisabledButtonText()}
                    />
                    <div className="booking-status-button-container" data-testid="booking-button-container">
                        {getButtons()}
                    </div>

                    {!props.booking.isFutureBooking && props.booking.status !== bookingStatus.cancelled && (
                        <Button className="btn-outline-primary cancel-btn" onClick={(e) => cancelBooking(e)}>
                            {intl.formatMessage({ id: 'Bookings.CancelBooking' })}
                        </Button>
                    )}
                </ConfirmActionButton>
                {props.booking.isReConfirmed && (
                    <div
                        className={props.includeStatusTextOnButton ? 'success-icon-circle' : 'success-icon-circle'}
                        data-testid="booking-is-reConfirmed-icon"
                        onClick={(e) => toggleBookingStatusTooltip(e)}
                    >
                        <SuccessIcon />
                    </div>
                )}
            </div>
        </Fragment>
    );
}

BookingStatusControl.propTypes = {
    booking: PropTypes.object.isRequired,
    bookingStatusButtonId: PropTypes.string.isRequired,
    updateArrivalStatus: propTypeExtensions.requiredIf(PropTypes.func, (props) => !props.readOnly),
    closeBooking: propTypeExtensions.requiredIf(PropTypes.func, (props) => !props.readOnly),
    openCancellationModalWithBookingId: propTypeExtensions.requiredIf(PropTypes.func, (props) => !props.readOnly),
    toggleReconfirmedStatus: propTypeExtensions.requiredIf(PropTypes.func, (props) => !props.readOnly),
    tooltipPlacement: PropTypes.string,
    tooltipOpen: PropTypes.bool,
    toggleBookingStatusTooltip: PropTypes.func,
    includeStatusTextOnButton: PropTypes.bool,
    buttonClassName: PropTypes.string,
    rebookBooking: PropTypes.func,
    closeBookingStatusControl: propTypeExtensions.requiredIf(PropTypes.func, (props) => !props.readOnly),
    readOnly: PropTypes.bool,
};

export default BookingStatusControl;
