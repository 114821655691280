import React, { Fragment, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import Section from '../../common/Section';
import AddMenuItemSection from './AddMenuItemSection';
import MenuItem from './MenuItem';
import EditCategorySection from './EditCategorySection';
import { Button } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import ConfirmActionButton from '../../common/ConfirmActionButton';
import { FormattedMessage, useIntl } from 'react-intl';

function CategorySection(props) {
    const intl = useIntl();
    const [isDeleting, toggleIsDeleting] = useReducer(toggle, false);
    const [isEditing, toggleIsEditing] = useReducer(toggle, false);
    const [tooltipOpen, toggleTooltip] = useReducer(toggle, false);
    const [editedName, setEditedName] = useState(props.category.name);

    function toggle(value) {
        return !value;
    }

    function categoryNameEdited() {
        const updatedCategory = { ...props.category };
        updatedCategory.name = editedName;
        props.editCategory(updatedCategory);
        toggleIsEditing();
    }

    function menuItemAdded(menuItem) {
        const updatedCategory = { ...props.category };
        menuItem.displayOrder = updatedCategory.menuItems.length;
        updatedCategory.menuItems.push(menuItem);
        props.editCategory(updatedCategory);
    }

    function menuItemEdited(menuItem) {
        const updatedCategory = { ...props.category };
        const index = updatedCategory.menuItems.findIndex((mi) => mi.uniqueIdentifier === menuItem.uniqueIdentifier);
        updatedCategory.menuItems[index] = menuItem;
        props.editCategory(updatedCategory);
    }

    function menuItemDeleted(menuItem) {
        const updatedCategory = { ...props.category };
        const index = updatedCategory.menuItems.findIndex((mi) => mi.uniqueIdentifier === menuItem.uniqueIdentifier);
        if (index >= 0) {
            updatedCategory.menuItems.splice(index, 1);
            props.editCategory(updatedCategory);
        }
    }

    function getMenuItemElements() {
        const sortedMenuItems = props.category.menuItems.sort((a, b) => a.displayOrder - b.displayOrder);
        return sortedMenuItems.map((item, index) => {
            return (
                <Draggable key={item.uniqueIdentifier} draggableId={item.uniqueIdentifier} index={index}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <MenuItem
                                key={item.uniqueIdentifier}
                                menuItem={item}
                                dragHandleProps={provided.dragHandleProps}
                                menuItemEdited={menuItemEdited}
                                menuItemDeleted={menuItemDeleted}
                                addErrorBar={props.addErrorBar}
                            />
                        </div>
                    )}
                </Draggable>
            );
        });
    }

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const updatedCategory = { ...props.category };

        const items = reorder(updatedCategory.menuItems, result.source.index, result.destination.index);

        items.forEach((c, index) => {
            c.displayOrder = index;
        });

        updatedCategory.menuItems = items;
        props.editCategory(updatedCategory);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function getHeaderChildren() {
        return (
            <Fragment>
                <div className="row-section borderless clickable" onClick={toggleIsEditing}>
                    <EditIcon />
                </div>
                <div className="row-section borderless clickable delete">
                    <ConfirmActionButton
                        id={'deleteRowButton' + props.category.uniqueIdentifier}
                        className="booking-status-icon-button"
                        icon={<DeleteIcon />}
                        tooltipOpen={tooltipOpen}
                        includeCloseIcon
                        toggle={toggleTooltip}
                        tooltipPlacement="top"
                    >
                        <FormattedMessage id="Common.DeletionConfirmation" />
                        <Button
                            className="btn-outline-primary cancel-btn"
                            onClick={() => {
                                toggleIsDeleting();
                                props.deleteCategory(props.category.uniqueIdentifier);
                            }}
                        >
                            {isDeleting
                                ? intl.formatMessage({ id: 'Common.Deleting' })
                                : intl.formatMessage({ id: 'Common.Delete' })}
                        </Button>
                    </ConfirmActionButton>
                </div>
            </Fragment>
        );
    }

    if (isEditing) {
        return (
            <div className="collapsible-panel-body transparent">
                <EditCategorySection
                    toggleIsOpen={toggleIsEditing}
                    saveCategory={categoryNameEdited}
                    title={editedName}
                    setTitle={setEditedName}
                    editingSection
                />
            </div>
        );
    }

    return (
        <Section
            sectionClassName="category-section"
            sectionTitle={props.category.name}
            isCollapsible
            isGrabbable
            dragHandleProps={props.dragHandleProps}
            headerChildren={getHeaderChildren()}
        >
            <div className="collapsible-panel-body menu-section">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                <div className="menu-item-container">{getMenuItemElements()}</div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <AddMenuItemSection
                    menuItemAdded={menuItemAdded}
                    menuItemEdited={menuItemEdited}
                    addErrorBar={props.addErrorBar}
                />
            </div>
        </Section>
    );
}

CategorySection.propTypes = {
    editCategory: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
    dragHandleProps: PropTypes.object.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
};

export default CategorySection;
