import React, { useState, Fragment, useCallback, useContext, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import logoAndImagesSettingsTab from '../../../enums/Tabs/logoAndImagesSettingsTab';
import RestaurantImagesTab from './RestaurantImagesTab';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import infoBarType from '../../../enums/infoBarType';
import ProgressFlagsContext from '../../../contexts/ProviderProgressFlagsContext';
import SettingsTabbedContentPage from '../SettingsTabbedContentPage';
import DiaryContext from '../../../contexts/DiaryContext';
import SessionContext from '../../../contexts/SessionContext';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';

let _ID = 0;
function ImagesSettingsPage(props) {
    const intl = useIntl();
    const [infoBars, setInfoBars] = useState([]);
    const progressFlagsContext = useContext(ProgressFlagsContext);
    const sessionContext = useContext(SessionContext);
    const diaryContext = useContext(DiaryContext);
    const [analyticsProperties] = useState(getAnalyticsProperties);
    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    const [currentTabKey, setCurrentTabKey] = useState(logoAndImagesSettingsTab.restaurantImages);

    const tabLabels = [
        {
            key: logoAndImagesSettingsTab.restaurantImages,
            name: intl.formatMessage({ id: 'Settings.RestaurantImages' }),
            showDot: !progressFlagsContext.addedAdditionalImage,
        },
    ];

    const tabs = [
        <RestaurantImagesTab
            key={logoAndImagesSettingsTab.restaurantImages}
            isActive={currentTabKey === logoAndImagesSettingsTab.restaurantImages}
            addErrorBar={addErrorBar}
            addSuccessBar={addSuccessBar}
        />,
    ];

    useEffect(() => {
        if (currentTabKey) {
            switch (currentTabKey) {
                case logoAndImagesSettingsTab.restaurantImages:
                    AnalyticsHelper.trackPage('Restaurant Images');
                    AnalyticsHelper.trackClickWithProperties('Web Activation RestImages', analyticsProperties);
                    break;

                default:
                    break;
            }
        }
    }, [currentTabKey, analyticsProperties]);

    function getAnalyticsProperties() {
        return {
            completedListing: progressFlagsContext.addedAdvancedListing,
            completedMenus: progressFlagsContext.uploadedMenu,
            completedImages: progressFlagsContext.addedAdditionalImage,
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
        };
    }

    function getSidePanelContent() {
        return (
            <Fragment>
                <div className="bold-text">
                    <FormattedMessage id="Settings.RestaurantImageRequirementsTitle" />
                </div>
                <ul className="image-requirement-list">
                    <li>
                        <FormattedMessage id="Settings.RestaurantImageMaxFileSizeRequirement" />
                    </li>
                    <li>
                        <FormattedMessage id="Settings.RestaurantImageMinSizeRequirement" />
                    </li>
                    <li>
                        <FormattedMessage id="Settings.RestaurantImageFileTypeRequirement" />
                    </li>
                </ul>
            </Fragment>
        );
    }

    function onTabChanged(tabKey) {
        if (tabKey !== currentTabKey) {
            setCurrentTabKey(tabKey);
        }
    }

    function getBreadcrumbOptions() {
        return [new BreadCrumbOption(intl.formatMessage({ id: 'Settings.Title' }), '/Settings')];
    }

    return (
        <SettingsTabbedContentPage
            pageTitle={intl.formatMessage({ id: 'Settings.Images' })}
            tabLabels={tabLabels}
            currentTabKey={currentTabKey}
            tabs={tabs}
            sidePanelAdditionalContent={getSidePanelContent()}
            onTabChanged={onTabChanged}
            includeBreadcrumb
            breadcrumbOptions={getBreadcrumbOptions()}
            breadcrumbActivePageTitle={intl.formatMessage({ id: 'Settings.Images' })}
            infoBars={infoBars}
            setInfoBars={setInfoBars}
        />
    );
}

export default ImagesSettingsPage;
