import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SidePanel extends Component {
    getClasses() {
        const classes = ['scrollable-panel', 'sliding-panel'];
        if (this.props.showSidePanel) {
            classes.push('show');
        } else {
            classes.push('hide');
        }
        if (this.props.isMobileView) {
            classes.push('mobile');
        }
        return classes.join(' ');
    }

    render() {
        return <div className={this.getClasses()}>{this.props.sidePanel}</div>;
    }
}

SidePanel.propTypes = {
    sidePanel: PropTypes.object.isRequired,
    showSidePanel: PropTypes.bool.isRequired,
};

export default SidePanel;
