import React, { useState, Fragment, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { InputGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import RDLModal from '../common/RDLModal';
import useScreenSize from '../../hooks/useScreenSize';
import DiaryContext from '../../contexts/DiaryContext';
import VoucherService from '../../services/VoucherService';

function VouchersResendModal(props) {
    const intl = useIntl();
    const { isMobileView } = useScreenSize();
    const [emailAddress, setEmailAddress] = useState('');
    const diaryContext = useContext(DiaryContext);

    function getModalHeader() {
        return (
            <div className="text-uppercase">
                <FormattedMessage id="Vouchers.VoucherResendTitle" />
            </div>
        );
    }

    function getEmailInput() {
        return (
            <InputGroup className="input-group-with-addon copy-input-addon">
                <Input
                    type="input"
                    id="vouchers-email-address"
                    value={emailAddress}
                    onChange={(e) => {
                        setEmailAddress(e.target.value);
                    }}
                />
            </InputGroup>
        );
    }

    function resendVoucher(callback) {
        VoucherService.resendVoucher(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            props.voucherOrderId,
            emailAddress
        )
            .then(() => {
                props.addSuccessBar(
                    intl.formatMessage({
                        id: 'Vouchers.VoucherSent',
                    })
                );
                props.toggleResendModal(false);
            })
            .catch(() => {
                props.addErrorBar();
            })
            .finally(() => {
                callback();
            });
    }

    function getModalBody() {
        return (
            <div className={isMobileView ? 'vouchers-promote-modal mob' : 'vouchers-promote-modal'}>
                <div className={isMobileView ? 'vouchers-promote-content mob' : 'vouchers-promote-content'}>
                    <div className={'row row-content'}>
                        <div className={'col'}>
                            <h6 className="text-uppercase">
                                <FormattedMessage id="Vouchers.SendTo" />
                            </h6>
                            <div className={isMobileView ? 'url-input' : null}>{getEmailInput()}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <RDLModal
                isModalOpen={props.isModalOpen}
                setIsModalOpen={props.toggleResendModal}
                title={getModalHeader()}
                body={getModalBody()}
                modalClass={isMobileView ? null : 'content-class small'}
                modalHeaderClass="modal-header"
                action={resendVoucher}
                actionButtonText={intl.formatMessage({ id: 'Vouchers.SendVoucher' })}
                actionButtonPendingText={intl.formatMessage({ id: 'Vouchers.Sending' })}
            />
        </Fragment>
    );
}

VouchersResendModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    toggleResendModal: PropTypes.func.isRequired,
    voucherOrderId: PropTypes.number.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
};

export default VouchersResendModal;
