import React, { useState, useEffect, useContext, useCallback, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Label, Form, FormGroup, Input, Button } from 'reactstrap';
import MerchantIdSection from './MerchantIdSection';
import VenueSignUpInfo from '../../../domainObjects/VenueSignUpInfo';
import MerchantId from '../../../domainObjects/MerchantId';
import useValidation from '../../../hooks/useValidation';
import CountryService from '../../../services/CountryService';
import AddressSection from './AddressSection';
import ContactDetailsSection from './ContactDetailsSection';
import DiaryContext from '../../../contexts/DiaryContext';
import SessionContext from '../../../contexts/SessionContext';
import ValidationInput from '../../common/ValidationInput';
import { ReactComponent as LeftArrow } from '../../../content/icons/chevron-left.svg';
import { useNavigate } from 'react-router-dom';
import SettingsService from '../../../services/SettingsService';
import RDLModal from '../../common/RDLModal';
import useScreenSize from '../../../hooks/useScreenSize';
import useTrackPage from '../../../hooks/useTrackPage';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import merchantTypes from '../../../enums/merchantTypes';
import loyaltyStatus from '../../../enums/loyaltyStatus';
import infoBarType from '../../../enums/infoBarType';
import InfoBars from '../../common/InfoBars';

function LoyaltyForm(props) {
    const [signUpInfo, setSignUpInfo] = useState(new VenueSignUpInfo());
    const [countryCodes, setCountryCodes] = useState([]);
    const [displayDeleteMerchantIdModal, setDisplayDeleteMerchantIdModal] = useState(false);
    const [merchantIdToDelete, setMerchantIdToDelete] = useState(0);
    const [initialised, setInitialised] = useState(false);
    const [termsAndConditionsCheckboxChecked, setTermsAndConditionsCheckboxChecked] = useState(false);
    const [permissionCheckboxChecked, setPermissionCheckboxChecked] = useState(false);
    const { isMobileView } = useScreenSize();
    const [infoBars, setInfoBars] = useState([]);

    const intl = useIntl();
    const formValidation = useValidation();
    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const navigate = useNavigate();
    let _ID = 0;

    const merchantTypeOptions = [
        new Option(intl.formatMessage({ id: 'Settings.Visa' }), merchantTypes.visa),
        new Option(intl.formatMessage({ id: 'Settings.Mastercard' }), merchantTypes.mastercard),
        new Option(intl.formatMessage({ id: 'Settings.AmericanExpress' }), merchantTypes.americanExpress),
    ];

    const commonTrackingProperties = {
        providerId: diaryContext.restaurantId,
        userName: sessionContext.userName,
        userId: sessionContext.userId,
    };

    useEffect(() => {
        if (!initialised) {
            setSignUpInfo({
                ...signUpInfo,
                venueName: diaryContext.restaurantName,
                providerId: diaryContext.restaurantId,
            });

            CountryService.getCountryCodes().then((result) => {
                setCountryCodes(result);
            });
            setInitialised(true);
        }
    }, [signUpInfo, initialised, diaryContext.restaurantName, diaryContext.restaurantId]);

    const addErrorBar = useCallback(
        (message) => {
            setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
        },
        [_ID]
    );

    function addNewMerchantId() {
        AnalyticsHelper.trackClickWithProperties('Web dcRewards addMerchantId', commonTrackingProperties);

        const setupInfoCopy = { ...signUpInfo };
        setupInfoCopy.merchantIds.push(new MerchantId());
        setSignUpInfo(setupInfoCopy);
    }

    function updateMerchantIdType(merchantId, type) {
        const signUpInfoCopy = { ...signUpInfo };
        const merchantIdToUpdate = signUpInfoCopy.merchantIds.find(
            (_) => _.uniqueIdentifier === merchantId.uniqueIdentifier
        );
        merchantIdToUpdate.merchantType = type;

        setSignUpInfo(signUpInfoCopy);
    }

    function updateMerchantIdValue(merchantId, value) {
        const signUpInfoCopy = { ...signUpInfo };
        const merchantIdToUpdate = signUpInfoCopy.merchantIds.find(
            (_) => _.uniqueIdentifier === merchantId.uniqueIdentifier
        );
        merchantIdToUpdate.merchantIds = [value];
        setSignUpInfo(signUpInfoCopy);
    }

    function deleteMerchantId(merchantId) {
        const signUpInfoCopy = { ...signUpInfo };
        const indexOfMerchantIdToDelete = signUpInfoCopy.merchantIds.findIndex(
            (_) => _.uniqueIdentifier === merchantId.uniqueIdentifier
        );
        signUpInfoCopy.merchantIds.splice(indexOfMerchantIdToDelete, 1);
        setSignUpInfo(signUpInfoCopy);
    }

    function setAddressInformation(fieldName, value) {
        const signUpInfoCopy = { ...signUpInfo };
        signUpInfoCopy.address[fieldName] = value;
        setSignUpInfo(signUpInfoCopy);
    }

    function setContactDetailsSection(fieldName, value) {
        const signUpInfoCopy = { ...signUpInfo };
        signUpInfoCopy.contactDetails[fieldName] = value;
        setSignUpInfo(signUpInfoCopy);
    }

    function isSubmitButtonDisabled() {
        const isDisabled = !(
            formValidation &&
            formValidation.errorCount === 0 &&
            termsAndConditionsCheckboxChecked &&
            permissionCheckboxChecked &&
            signUpInfo &&
            signUpInfo.merchantIds.length > 0
        );
        return isDisabled;
    }

    const concatArrays = (arrays) => {
        let concattedArray = arrays[0];
        for (let i = 0; i < arrays.length - 1; i++) {
            concattedArray = concattedArray.concat(arrays[i + 1]);
        }
        return concattedArray;
    };

    const reduceMerchantIds = (merchantIds) => {
        let newMerchantIdObjects = [];
        merchantTypeOptions.forEach((merchantTypeOption) => {
            const filteredMerchantIds = merchantIds.filter((x) => x.merchantType === merchantTypeOption.value);
            const midArrays = filteredMerchantIds.map((x) => {
                return x.merchantIds;
            });
            if (midArrays[0]) {
                const concacttedMidArrays = concatArrays(midArrays);

                newMerchantIdObjects.push({
                    type: merchantTypeOption.text,
                    merchantIds: concacttedMidArrays,
                });
            }
        });
        return newMerchantIdObjects;
    };

    function submitLoyaltyForm() {
        const merchantIds = signUpInfo.merchantIds.map((merchantId) => {
            const option = merchantTypeOptions.find((op) => {
                return op.value === merchantId.merchantType;
            });
            if (option && merchantId.merchantIds.length > 0) {
                return { merchantType: option.text, merchantID: merchantId.merchantIds[0] };
            }
            return null;
        });

        const reducedMerchantIds = reduceMerchantIds(signUpInfo.merchantIds);
        const trackingDetails = {
            venueName: signUpInfo.venueName,
            tradingName: signUpInfo.tradingName,
            merchantIds: merchantIds,
            dcrAddress1: signUpInfo.address.addressLine1,
            dcrCity: signUpInfo.address.city,
            dcrCountry: signUpInfo.address.country,
            providerId: diaryContext.providerId,
            userId: sessionContext.userId,
            userName: sessionContext.userName,
        };

        AnalyticsHelper.trackClickWithProperties('Web dcRewards Subscribe', trackingDetails);

        SettingsService.subscribeToLoyalty(diaryContext.deploymentId, diaryContext.restaurantId, {
            ...signUpInfo,
            userId: sessionContext.userId,
            merchantIds: reducedMerchantIds,
        })
            .then(() => {
                props.updateLoyaltyStatus(loyaltyStatus.syncing);
                props.setSubscribedToLoyalty(true);
                navigate('/Settings/Loyalty');
            })
            .catch(() => {
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
            });
    }

    function navigateBack() {
        AnalyticsHelper.trackClickWithProperties('Web dcRewards Back', commonTrackingProperties);
        navigate('/settings');
    }

    function closeRemoveMerchantIdModal() {
        AnalyticsHelper.trackClickWithProperties('Web removeMerchantId Cancel', commonTrackingProperties);
        setDisplayDeleteMerchantIdModal(false);
    }

    if (!diaryContext.isLoyaltyEnabled) {
        navigate('/404');
    }

    useTrackPage('DCRewards Subscription Page');

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <Form
                className="loyalty-form-page"
                onSubmit={(e) => {
                    e.preventDefault();
                    formValidation.submit(submitLoyaltyForm);
                }}
            >
                <div className="loyalty-form-header">
                    <h3 className="header">{intl.formatMessage({ id: 'Settings.LoyaltyFormTitle' })}</h3>
                    <p className="sub-header">{intl.formatMessage({ id: 'Settings.LoyaltyFormSubtitle' })}</p>
                </div>
                <div className="loyalty-form-body-container">
                    <div className="loyalty-form-body">
                        <div className="small-body loyalty-form">
                            <Label>
                                <i>{intl.formatMessage({ id: 'Settings.LoyaltyFormRequiredNote' })}</i>
                            </Label>
                            <FormGroup className="loyalty-form-group venue">
                                <FormGroup>
                                    <Label for="venueName">{intl.formatMessage({ id: 'Settings.VenueName' })}</Label>
                                    <Input
                                        id="venueName"
                                        placeholder={intl.formatMessage({ id: 'Settings.VenueName' })}
                                        type="text"
                                        disabled
                                        value={signUpInfo.venueName}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tradingName" id="tradingNameLabel">
                                        {intl.formatMessage({ id: 'Settings.TradingName' })}
                                    </Label>
                                    <ValidationInput
                                        id="tradingName"
                                        name="tradingName"
                                        placeholder={intl.formatMessage({ id: 'Settings.TradingName' })}
                                        type="text"
                                        value={signUpInfo.tradingName}
                                        onChange={(value) => {
                                            setSignUpInfo({ ...signUpInfo, tradingName: value });
                                        }}
                                        innerRef={formValidation.register({
                                            required: intl.formatMessage(
                                                { id: 'Settings.LoyaltyFormIsRequired' },
                                                { fieldName: intl.formatMessage({ id: 'Settings.TradingName' }) }
                                            ),
                                        })}
                                        errors={formValidation.errors}
                                        ariaLabelledBy="tradingNameLabel"
                                        ariaRequired
                                    />
                                </FormGroup>
                            </FormGroup>
                            <FormGroup className="loyalty-form-merchant-group">
                                <MerchantIdSection
                                    merchantIds={signUpInfo.merchantIds}
                                    addNewMerchantId={addNewMerchantId}
                                    updateMerchantIdType={updateMerchantIdType}
                                    updateMerchantIdValue={updateMerchantIdValue}
                                    deleteMerchantId={(merchantId) => {
                                        AnalyticsHelper.trackClickWithProperties(
                                            'Web dcRewards removeMerchantId',
                                            commonTrackingProperties
                                        );
                                        setMerchantIdToDelete(merchantId);
                                        setDisplayDeleteMerchantIdModal(true);
                                    }}
                                    formValidation={formValidation}
                                    merchantTypeOptions={merchantTypeOptions}
                                />
                            </FormGroup>
                            <FormGroup className="address">
                                <AddressSection
                                    address={signUpInfo.address}
                                    setAddressInformation={setAddressInformation}
                                    countryCodes={countryCodes}
                                    formValidation={formValidation}
                                />
                            </FormGroup>
                            <FormGroup className="contact-details">
                                <ContactDetailsSection
                                    contactDetails={signUpInfo.contactDetails}
                                    setContactDetailsSection={setContactDetailsSection}
                                    countryCodes={countryCodes}
                                    formValidation={formValidation}
                                    termsAndConditionsCheckboxChecked={termsAndConditionsCheckboxChecked}
                                    setTermsAndConditionsCheckboxChecked={setTermsAndConditionsCheckboxChecked}
                                    permissionCheckboxChecked={permissionCheckboxChecked}
                                    setPermissionCheckboxChecked={setPermissionCheckboxChecked}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <hr />
                    <FormGroup className={`loyalty-form-group form-controls ${isMobileView ? 'mobile' : null}`}>
                        <Button onClick={navigateBack} className="back-button" color="primary" outline>
                            <div className="d-flex align-items-center">
                                <LeftArrow /> {intl.formatMessage({ id: 'Common.Back' })}
                            </div>
                        </Button>

                        <Button
                            type="submit"
                            className="subscribe-button"
                            color="primary"
                            disabled={formValidation.submitDisabled || isSubmitButtonDisabled()}
                        >
                            {intl.formatMessage({ id: 'Settings.Subscribe' })}
                        </Button>
                    </FormGroup>
                </div>
                <RDLModal
                    isModalOpen={displayDeleteMerchantIdModal}
                    setIsModalOpen={closeRemoveMerchantIdModal}
                    title={intl.formatMessage({ id: 'Settings.LoyaltyMerchantIdDeleteConfirmation' })}
                    actionButtonText={intl.formatMessage({ id: 'Common.Confirm' })}
                    body=""
                    action={(func) => {
                        AnalyticsHelper.trackClickWithProperties(
                            'Web removeMerchantId Confirm',
                            commonTrackingProperties
                        );
                        deleteMerchantId(merchantIdToDelete);
                        setMerchantIdToDelete(0);
                        setDisplayDeleteMerchantIdModal(false);
                        func();
                    }}
                    backdrop
                    dismissActionButtonText={intl.formatMessage({ id: 'Common.Cancel' })}
                    wrapClassName={'delete-merchant-id-modal-wrapper'}
                />
            </Form>
        </Fragment>
    );
}

export default LoyaltyForm;
