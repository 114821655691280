import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import BookingFilterEntry from './BookingFilterEntry';

function FilterPanel(props) {
    const intl = useIntl();

    return (
        <Fragment>
            <div className={props.isMobileView ? 'active-panel-container mobile' : 'active-panel-container'}>
                <h6 className="text-uppercase">{intl.formatMessage({ id: 'Bookings.BookingSettings' })}</h6>
                <BookingFilterEntry
                    selectedFilter={props.selectedFilter}
                    onSelectedBookingFilterChange={props.onSelectedBookingFilterChange}
                />
            </div>
        </Fragment>
    );
}

FilterPanel.propTypes = {
    onSelectedBookingFilterChange: PropTypes.func.isRequired,
    selectedFilter: PropTypes.number,
};

export default FilterPanel;
