import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, CustomInput, Progress } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as PlusIcon } from '../../../content/icons/Icon-Plus.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import { ReactComponent as SuccessIcon } from '../../../content/icons/Icon-Success.svg';
import useScreenSize from '../../../hooks/useScreenSize';
import propTypeExtensions from '../../../helpers/PropTypeExtensions';

function ProviderImageCard(props) {
    const intl = useIntl();

    const { isMobileView } = useScreenSize();

    function isDisabled() {
        return props.isDeleting || props.image.isMainImage || !props.image.id;
    }

    function getCardStyle() {
        let cssClasses = 'provider-image-card';
        if (props.image.isSelected) cssClasses = `${cssClasses} selected`;
        if (isMobileView) cssClasses = `${cssClasses} mob`;
        if (!props.image.id) cssClasses = `${cssClasses} loading`;
        return cssClasses;
    }

    return (
        <div className={getCardStyle()}>
            <div className="provider-image-content">
                <div className="image-box">
                    <img src={props.image.url} alt="" />
                </div>

                <Fragment>
                    <div className="provider-image-information">
                        <div className="image-text-size bold-text file-title pb-2 mr-4">{`${props.image.name}`}</div>
                        <div className="uploaded-text pb-2">
                            <FormattedMessage
                                id="Settings.UploadedText"
                                values={{ uploadedDate: props.image.uploadedDate }}
                            />
                        </div>
                        {!props.image.id && (
                            <div>
                                <div className="mb-2">
                                    <FormattedMessage
                                        id="Settings.UploadedPercentage"
                                        values={{ loadingPercentage: props.image.loadingPercentage }}
                                    />
                                </div>
                                <Progress animated value={props.image.loadingPercentage} />
                            </div>
                        )}
                        {!(props.isOnboarding || !props.image.id) && (
                            <Button
                                className={
                                    props.image.isMainImage
                                        ? 'btn btn-primary main-image main-image-btn'
                                        : 'btn-outline-primary main-image-btn'
                                }
                                color="primary"
                                onClick={() => props.setAsMainImage(props.image)}
                                disabled={!props.image.id}
                            >
                                {props.image.isMainImage ? <SuccessIcon /> : <PlusIcon />}
                                <FormattedMessage id="Settings.SetAsMainImage" />
                            </Button>
                        )}
                    </div>
                    {!props.isOnboarding && (
                        <CustomInput
                            id={`selected-checkbox-${props.image.id}`}
                            type="checkbox"
                            onChange={() => props.toggleImageSelected(props.image)}
                            checked={props.image.isSelected}
                            disabled={!props.image.id}
                        />
                    )}
                </Fragment>
            </div>
            <div className="provider-image-action-bar d-flex">
                <div className="row-section">
                    {!props.isOnboarding && (
                        <Fragment>
                            <CustomInput
                                id={`active-radio-${props.image.uniqueIdentifier}`}
                                checked={props.image.isActive}
                                onChange={() => props.toggleImageActiveStatus(props.image, true)}
                                inline
                                type="radio"
                                name={`active-radio-${props.image.uniqueIdentifier}`}
                                label={intl.formatMessage({
                                    id: 'Settings.Active',
                                })}
                                disabled={isDisabled()}
                            />
                            <CustomInput
                                id={`disabled-radio-${props.image.uniqueIdentifier}`}
                                checked={!props.image.isActive}
                                onChange={() => props.toggleImageActiveStatus(props.image, false)}
                                inline
                                type="radio"
                                name={`disabled-radio-${props.image.uniqueIdentifier}`}
                                label={intl.formatMessage({
                                    id: 'Settings.Inactive',
                                })}
                                disabled={isDisabled()}
                            />
                        </Fragment>
                    )}
                    <Button
                        className="delete-button"
                        disabled={props.isOnboarding && props.image.id ? false : isDisabled()}
                        onClick={() => props.deleteImage(props.image)}
                    >
                        <DeleteIcon />
                        <FormattedMessage id="Common.Delete" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

ProviderImageCard.propTypes = {
    image: PropTypes.object.isRequired,
    isOnboarding: PropTypes.bool,
    toggleImageActiveStatus: propTypeExtensions.requiredIf(PropTypes.func, (props) => !props.isOnboarding),
    toggleImageSelected: propTypeExtensions.requiredIf(PropTypes.func, (props) => !props.isOnboarding),
    setAsMainImage: propTypeExtensions.requiredIf(PropTypes.func, (props) => !props.isOnboarding),
    deleteImage: PropTypes.func.isRequired,
    progressPercentage: PropTypes.number,
    isDeleting: PropTypes.bool,
};

export default ProviderImageCard;
