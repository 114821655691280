import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import TableHeadingOption from '../../common/TableHeadingOption';
import VoucherReportTable from './VoucherReportTable';
import PurchasedVoucher from '../../../domainObjects/PurchasedVoucher';
import voucherTableColumns from '../../../enums/voucherTableColumns';
import { ReactComponent as ChevronLeft } from '../../../content/icons/chevron-left.svg';
import { Link } from 'react-router-dom';
import VoucherService from '../../../services/VoucherService';
import DiaryContext from '../../../contexts/DiaryContext';
import VoucherReportHeader from './VoucherReportHeader';
import Voucher from '../../../domainObjects/Voucher';
import InfoBars from '../../common/InfoBars';
import infoBarType from '../../../enums/infoBarType';
import exportedReportStatus from '../../../enums/exportedReportStatus';
import reportType from '../../../enums/reportType';
import useTrackPage from '../../../hooks/useTrackPage';
import VoucherResendModal from '../VouchersResendModal';
import OverlayDialogue from '../../common/OverlayDialogue';
import { ReactComponent as WarningIcon } from '../../../content/icons/Icon-Alert.svg';
import SessionContext from '../../../contexts/SessionContext';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import voucherReportStatus from '../../../enums/voucherReportStatus';
import useQueryParam from '../../../hooks/useQueryParam';
import { useNavigate } from 'react-router-dom';

let _ID = 0;

function VoucherReportPage() {
    const intl = useIntl();
    const defaultPageSize = 20;
    const [infoBars, setInfoBars] = useState([]);
    useTrackPage('VoucherSales Report page');
    const [vouchers, setVouchers] = useState([]);
    const [availableVouchers, setAvailableVouchers] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [resendVoucherOrderId, setResendVoucherOrderId] = useState(null);
    const [isResendVoucherModalOpen, setIsResendVoucherModalOpen] = useState(false);

    //use these once the set functions are implemented
    const [pageSize, setPageSize] = useState(defaultPageSize);
    // const [orderDirection, setOrderDirect] = useState('ASC');
    // const [orderBy, setOrderBy] = useState('VoucherReference');
    const [searchReference, setSearchReference] = useState('');
    const [voucherStatusFilter, setVoucherStatusFilter] = useState(3);
    const [voucherFilterId, setVoucherFilterId] = useState(['0']);

    //delete these once the above are implemented
    const orderDirection = 'ASC';
    const orderBy = 0;

    const [isLoading, setIsLoading] = useState(true);
    const [hasQueryParamsBeenSet, setHasQueryParamsBeenSet] = useState(false);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [totalItemSoldCount, setTotalSold] = useState(0);
    const [totalItemRedeemedCount, setTotalRedeemed] = useState(0);
    const [totalItemNotRedeemedCount, setTotalNotRedeemed] = useState(0);
    const [totalItemExpiredCount, setTotalExpired] = useState(0);

    const [isExporting, setIsExporting] = useState(false);
    const [displayDialogue, setDisplayDialogue] = useState(false);
    const [exportingStatusMessage, setExportingStatusMessage] = useState('');

    const navigate = useNavigate();
    const voucherStatus = useQueryParam('voucherStatus');
    const voucherId = useQueryParam('voucherId');

    const diaryContext = useContext(DiaryContext);
    const sessionContext = useContext(SessionContext);
    const allColumns = getAllColumns();

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const addSuccessBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    }, []);

    const defaultColumns = () => {
        return [
            voucherTableColumns.VoucherReference,
            voucherTableColumns.Price,
            voucherTableColumns.VoucherName,
            voucherTableColumns.CustomerName,
            voucherTableColumns.EmailAddress,
            voucherTableColumns.Phone,
            voucherTableColumns.Type,
            voucherTableColumns.Purchased,
            voucherTableColumns.Validity,
            voucherTableColumns.Redemption,
            voucherTableColumns.BookingReference,
            voucherTableColumns.Status,
        ];
    };

    const getAnalyticsProperties = useCallback(() => {
        return {
            id_prov: diaryContext.restaurantId,
            user_fullname: sessionContext.displayName,
            user_email: sessionContext.userName,
        };
    }, [diaryContext.restaurantId, sessionContext.displayName, sessionContext.userName]);

    useEffect(() => {
        if (voucherId !== null && voucherId !== undefined) {
            setVoucherFilterId([voucherId]);
        }
        if (voucherStatus !== null && voucherStatus !== undefined) {
            setVoucherStatusFilter(parseInt(voucherStatus));
        }
        setHasQueryParamsBeenSet(true);

        navigate('/Vouchers/Report', { replace: true });
    }, [voucherId, voucherStatus, navigate]);

    useEffect(() => {
        VoucherService.getVouchers(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((response) => {
                const data = response.Vouchers.map((voucher) => new Voucher(voucher));
                setAvailableVouchers(data);
            })
            .catch(() => {
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [diaryContext.deploymentId, diaryContext.restaurantId, addErrorBar, intl]);

    useEffect(() => {
        if (!hasQueryParamsBeenSet) return;
        const filters = [];
        if (searchReference) {
            filters.push({ Type: 'VoucherReference', JsonData: searchReference.trim() });
        }
        if (voucherFilterId[0] !== '0') {
            filters.push({ Type: 'Voucher', JsonData: JSON.stringify(voucherFilterId) });
        }
        filters.push({ Type: 'Status', JsonData: voucherStatusFilter });
        filters.push({ Type: 'Provider', JsonData: JSON.stringify([diaryContext.restaurantId]) });

        const requestData = {
            PageSize: pageSize,
            OrderBy: orderBy,
            OrderDirection: orderDirection,
            Page: selectedPage,
            Filters: filters,
        };

        setIsLoading(true);
        VoucherService.getVoucherReport(diaryContext.deploymentId, diaryContext.restaurantId, requestData)
            .then((data) => {
                if ((data.Status = 'Success')) {
                    setTotalItemCount(data.TotalResults);
                    setTotalSold(data.TotalSold);
                    setTotalRedeemed(data.TotalRedeemed);
                    setTotalNotRedeemed(data.TotalNotRedeemed);
                    setTotalExpired(data.TotalExpired);
                    const vouchers = data.Entries.map((voucher) => new PurchasedVoucher(voucher));
                    setVouchers(vouchers);
                } else {
                    //add error bar with data.StatusMessage
                }
            })
            .then(() => {
                if (searchReference) {
                    let analyticsProperties = getAnalyticsProperties();
                    analyticsProperties.searchTerm = searchReference;
                    AnalyticsHelper.trackClickWithProperties('Web VoucherCode Search', analyticsProperties);
                }
            })
            .catch(() => {
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [
        searchReference,
        voucherFilterId,
        voucherStatusFilter,
        pageSize,
        orderBy,
        orderDirection,
        diaryContext.deploymentId,
        diaryContext.restaurantId,
        selectedPage,
        hasQueryParamsBeenSet,
        addErrorBar,
        intl,
        getAnalyticsProperties,
    ]);

    function onVoucherNameChange(voucherId) {
        let analyticsProperties = getAnalyticsProperties();
        let voucherName;
        if (voucherId > 0) {
            voucherName = availableVouchers.find((v) => v.id === parseInt(voucherId)).title;
        } else {
            voucherName = 'All Vouchers';
        }
        analyticsProperties.voucherName = voucherName;
        AnalyticsHelper.trackClickWithProperties('Web VoucherName Search', analyticsProperties);
        setVoucherFilterId([voucherId]);
    }

    function onVoucherStatusChange(status) {
        let analyticsProperties = getAnalyticsProperties();
        switch (status) {
            case voucherReportStatus.NotRedeemed:
                analyticsProperties.voucherType = 'notRedeemed';
                break;
            case voucherReportStatus.Redeemed:
                analyticsProperties.voucherType = 'redeemed';
                break;
            case voucherReportStatus.Expired:
                analyticsProperties.voucherType = 'expired';
                break;
            default:
                analyticsProperties.voucherType = 'sold';
        }
        AnalyticsHelper.trackClickWithProperties('VoucherSales Report page', analyticsProperties);
        setVoucherStatusFilter(status);
    }

    function onSelectPage(pageNumber, isEllipsis) {
        if (pageNumber < 1 || pageNumber > getNumberOfPages() || isEllipsis) {
            return;
        }
        setSelectedPage(pageNumber);
    }

    function onPageSizeChange(pageSize) {
        setPageSize(pageSize);
        setSelectedPage(1);
    }

    function getNumberOfPages() {
        return Math.ceil(totalItemCount / pageSize);
    }

    function getAllColumns() {
        return [
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnVoucherRef' }),
                voucherTableColumns.VoucherReference
            ),
            new TableHeadingOption(true, intl.formatMessage({ id: 'Settings.Price' }), voucherTableColumns.Price),
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnVoucherName' }),
                voucherTableColumns.VoucherName
            ),
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnCustomerName' }),
                voucherTableColumns.CustomerName
            ),
            new TableHeadingOption(true, intl.formatMessage({ id: 'Login.Email' }), voucherTableColumns.EmailAddress),
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnPhone' }),
                voucherTableColumns.Phone
            ),
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnType' }),
                voucherTableColumns.Type
            ),
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnPurchased' }),
                voucherTableColumns.Purchased
            ),
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnValidity' }),
                voucherTableColumns.Validity
            ),
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnRedemption' }),
                voucherTableColumns.Redemption
            ),
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnBookingRef' }),
                voucherTableColumns.BookingReference
            ),
            new TableHeadingOption(
                true,
                intl.formatMessage({ id: 'Vouchers.VoucherReportColumnStatus' }),
                voucherTableColumns.Status
            ),
        ];
    }

    function toggleResendModal(isOpen, voucherOrderId) {
        setIsResendVoucherModalOpen(isOpen);
        setResendVoucherOrderId(voucherOrderId);
    }

    function exportCsv() {
        let analyticsProperties = getAnalyticsProperties();
        AnalyticsHelper.trackClickWithProperties('Web VoucherSales Export', analyticsProperties);

        const filters = [];

        if (voucherFilterId[0] !== '0') {
            filters.push({ Type: 'Voucher', JsonData: JSON.stringify(voucherFilterId) });
        }

        if (searchReference) {
            filters.push({ Type: 'VoucherReference', JsonData: searchReference.trim() });
        }

        filters.push({ Type: 'VoucherStatus', JsonData: voucherStatusFilter });
        filters.push({ Type: 'Provider', JsonData: JSON.stringify([diaryContext.restaurantId]) });

        const requestData = {
            ReportType: reportType.VoucherSalesDetails,
            Filters: filters,
            Columns: defaultColumns(),
        };

        setDisplayDialogue(true);
        setIsExporting(true);
        setExportingStatusMessage(intl.formatMessage({ id: 'Common.ExportingReport' }));
        VoucherService.addReportExport(diaryContext.deploymentId, diaryContext.restaurantId, requestData)
            .then((exportRequestId) => {
                checkExportStatus(exportRequestId);
            })
            .catch(() => {
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
            });
    }

    function checkExportStatus(exportRequestId) {
        VoucherService.getExportedReport(diaryContext.deploymentId, diaryContext.restaurantId, exportRequestId).then(
            (exportedReport) => {
                if (exportedReport.Status === exportedReportStatus.completed) {
                    getExportedCsv(exportRequestId, exportedReport);
                } else if (
                    exportedReport.Status === exportedReportStatus.queued ||
                    exportedReport.Status === exportedReportStatus.inProgress
                ) {
                    setTimeout(() => checkExportStatus(exportRequestId), 5000);
                } else {
                    setExportingStatusMessage(intl.formatMessage({ id: 'Common.ExportFailed' }));
                    setIsExporting(false);
                }
            }
        );
    }

    function getExportedCsv(exportRequestId, exportedReport) {
        VoucherService.getExportedCsv(diaryContext.deploymentId, diaryContext.restaurantId, exportRequestId)
            .then((result) => {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', exportedReport.FileName);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(() => {
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
            })
            .finally(() => {
                setIsExporting(false);
                setDisplayDialogue(false);
            });
    }

    function getPageBody() {
        return (
            <Fragment>
                <div className="voucher-report-page">
                    {displayDialogue && (
                        <OverlayDialogue
                            showLoading={isExporting}
                            message={exportingStatusMessage}
                            toggleDisplayDialogue
                            setDialogueOpen={setDisplayDialogue}
                            icon={<WarningIcon />}
                            iconClassName="warning-icon"
                        />
                    )}
                    <h6>
                        <FormattedMessage id="Vouchers.VoucherSales" />
                    </h6>
                    <VoucherReportHeader
                        onVoucherStatusChange={onVoucherStatusChange}
                        voucherStatusFilter={voucherStatusFilter}
                        availableVouchers={availableVouchers}
                        selectedVoucherFilterId={voucherFilterId[0]}
                        onVoucherTitleChange={onVoucherNameChange}
                        onSearchReference={(e) => setSearchReference(e)}
                        vouchersTotalSold={totalItemSoldCount}
                        vouchersTotalRedeemed={totalItemRedeemedCount}
                        vouchersTotalNotRedeemed={totalItemNotRedeemedCount}
                        vouchersTotalExpired={totalItemExpiredCount}
                        exportCsv={exportCsv}
                        isExporting={isExporting}
                    />
                    <VoucherReportTable
                        vouchers={vouchers}
                        onSorting={() => {}} //todo
                        sortOrder={0} //todo
                        sortAscending={orderDirection && orderDirection === 'ASC' ? true : false}
                        onSelectPage={onSelectPage}
                        selectedPage={selectedPage}
                        onPageSizeChange={onPageSizeChange}
                        pageSize={pageSize}
                        totalItemCount={totalItemCount}
                        isLoading={isLoading}
                        columnTypes={allColumns}
                        allColumns={allColumns}
                        setIsResendVoucherModalOpen={toggleResendModal}
                    />
                    {isResendVoucherModalOpen && (
                        <VoucherResendModal
                            addErrorBar={addErrorBar}
                            addSuccessBar={addSuccessBar}
                            isModalOpen={isResendVoucherModalOpen}
                            toggleResendModal={toggleResendModal}
                            voucherOrderId={resendVoucherOrderId}
                        />
                    )}
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <h2 className="page-header">
                <FormattedMessage id="Common.Vouchers" />
            </h2>
            <div className="page-back-box">
                <Link to="/Vouchers">
                    <ChevronLeft /> <FormattedMessage id="Vouchers.VoucherReportBack" />
                </Link>
            </div>
            <div>{getPageBody()}</div>
        </Fragment>
    );
}

export default VoucherReportPage;
