import React from 'react';

function OptionPill(props) {
    return (
        <div
            className={props.className}
            color="secondary"
            onClick={() => props.onClick(props.value)}
            disabled={props.disabled}
        >
            {props.displayValue ? props.displayValue : props.value}
        </div>
    );
}

export default OptionPill;
