import React from 'react';
import PropTypes from 'prop-types';

function ToggleSwitch(props) {
    return (
        <div className={'toggle-switch'}>
            <input
                type="checkbox"
                name={props.id}
                className="toggle-switch-checkbox"
                id={props.id}
                checked={props.isChecked}
                onChange={(e) => props.onChange(e.target.checked)}
                disabled={props.disabled}
            />

            <label className="toggle-switch-label" htmlFor={props.id}>
                <span
                    className={props.disabled ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'}
                    data-yes={props.optionLabels[0]}
                    data-no={props.optionLabels[1]}
                />
                <span
                    className={props.disabled ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'}
                />
            </label>
        </div>
    );
}

ToggleSwitch.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export default ToggleSwitch;
