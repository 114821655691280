class RestaurantDetails {
    constructor(dto) {
        this.venueName = dto && dto.Name ? dto.Name : '';
        this.addressLine1 = dto && dto.StreetLine1 ? dto.StreetLine1 : '';
        this.addressLine2 = dto && dto.StreetLine2 ? dto.StreetLine2 : '';
        this.townOrCity = dto && dto.Town ? dto.Town : '';
        this.postcode = dto && dto.Postcode ? dto.Postcode : '';
        this.country = dto && dto.Country ? dto.Country : '';
        this.micrositeName = dto && dto.MicrositeName ? dto.MicrositeName : '';
        this.longitude = dto && dto.Longitude ? dto.Longitude : '';
        this.latitude = dto && dto.Latitude ? dto.Latitude : '';
        this.phoneNumber = dto && dto.PhoneNumber ? dto.PhoneNumber : '';
        this.mapUrl = dto && dto.MapUrl ? dto.MapUrl : '';
        this.siteUrl = dto && dto.SiteUrl ? dto.SiteUrl : '';
        this.email = dto && dto.Email ? dto.Email : '';
        this.countryCode = dto && dto.CountryCode ? dto.CountryCode : '';
        this.isoCurrencySymbol = dto && dto.IsoCurrencySymbol ? dto.IsoCurrencySymbol : '';
    }

    getDTO(
        venueName,
        addressLine1,
        addressLine2,
        townOrCity,
        postcode,
        country,
        micrositeName,
        longitude,
        latitude,
        phoneNumber,
        siteUrl,
        email,
        countryCode,
        isoCurrencySymbol,
        websiteUrl,
        timeZone
    ) {
        return {
            Name: venueName,
            StreetLine1: addressLine1,
            StreetLine2: addressLine2,
            Town: townOrCity,
            Postcode: postcode,
            Country: country,
            MicrositeName: micrositeName,
            Longitude: longitude,
            Latitude: latitude,
            PhoneNumber: phoneNumber,
            SiteUrl: siteUrl,
            Email: email,
            CountryCode: countryCode,
            IsoCurrencySymbol: isoCurrencySymbol,
            WebsiteUrl: websiteUrl,
            TimeZone: timeZone,
        };
    }
}

export default RestaurantDetails;
