class SearchDetails {
    constructor(dto) {
        this.cuisineTypes = dto && dto.CuisineIds ? dto.CuisineIds : [];
        this.pricePoint = dto && dto.PricePoint ? dto.PricePoint : '';
        this.dressCode = dto && dto.DressCodeIds ? dto.DressCodeIds : [];
        this.facilities = dto && dto.FacilityIds ? dto.FacilityIds : [];
    }

    getDTO(cuisineTypes, pricePoint, dressCode, facilities) {
        return {
            CuisineIds: cuisineTypes,
            PricePoint: pricePoint,
            DressCodeIds: dressCode,
            FacilityIds: facilities,
        };
    }
}

export default SearchDetails;
