import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CircularProgressbar } from 'react-circular-progressbar';
import GradientSVG from './GradientSVG';

function ListingStrength(props) {
    return (
        <Fragment>
            <GradientSVG endColor={'#00b7f0'} startColor={'#054169'} idCSS={'gradientCSS'} rotation={45} />
            <div className="d-flex flex-column">
                {props.includeHeading && (
                    <h6 className="text-uppercase">
                        <FormattedMessage id="AccountSubmission.ListingStrength" />:
                    </h6>
                )}
                <div className={props.centralProgressBar ? 'text-center font-weight-bold' : 'font-weight-bold'}>
                    <CircularProgressbar value={props.percentage} text={`${props.percentage}%`} counterClockwise />
                </div>
            </div>
        </Fragment>
    );
}

ListingStrength.propTypes = {
    percentage: PropTypes.number.isRequired,
    includeHeading: PropTypes.bool,
};

export default ListingStrength;
