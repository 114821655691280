import { useContext } from 'react';
import ProgressFlagContext from '../contexts/ProviderProgressFlagsContext';

function useOnboardingProgress() {
    const progressFlagContext = useContext(ProgressFlagContext);
    if (
        progressFlagContext.uploadedMenu &&
        progressFlagContext.addedAdditionalImage &&
        progressFlagContext.addedAdvancedListing
    ) {
        return 100;
    } else if (
        (!progressFlagContext.uploadedMenu &&
            progressFlagContext.addedAdditionalImage &&
            !progressFlagContext.addedAdvancedListing) ||
        (progressFlagContext.uploadedMenu &&
            !progressFlagContext.addedAdditionalImage &&
            progressFlagContext.addedAdvancedListing)
    ) {
        return 80;
    } else if (
        (progressFlagContext.uploadedMenu &&
            progressFlagContext.addedAdditionalImage &&
            !progressFlagContext.addedAdvancedListing) ||
        (!progressFlagContext.uploadedMenu &&
            progressFlagContext.addedAdditionalImage &&
            progressFlagContext.addedAdvancedListing)
    ) {
        return 90;
    } else if (
        (progressFlagContext.uploadedMenu &&
            !progressFlagContext.addedAdditionalImage &&
            !progressFlagContext.addedAdvancedListing) ||
        (!progressFlagContext.uploadedMenu &&
            !progressFlagContext.addedAdditionalImage &&
            progressFlagContext.addedAdvancedListing)
    ) {
        return 70;
    } else {
        return 60;
    }
}
export default useOnboardingProgress;
