import React, { Fragment } from 'react';
import FilterPanel from '../common/FilterPanel';
import { ReactComponent as MinimiseIcon } from '../../content/icons/minimise.svg';
import { Button } from 'reactstrap';

function BookingFilterOverlayContent(props) {
    return (
        <Fragment>
            <Button
                data-testid="sliding-panel-toggle"
                className={'sliding-panel-toggle mobile'}
                onClick={() => props.closeOverlay()}
            >
                <MinimiseIcon data-testid="minimise-icon" />
            </Button>
            <FilterPanel
                selectedFilter={props.selectedFilter}
                onSelectedBookingFilterChange={props.onSelectedBookingFilterChange}
                isMobileView
            />
        </Fragment>
    );
}

BookingFilterOverlayContent.propTypes = {};

export default BookingFilterOverlayContent;
