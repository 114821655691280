import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class MagicSearchService {
    static search(deploymentId, restaurantId, date, searchText) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/MagicSearch?date=${date}&searchText=${searchText}`
        );
    }
}

export default MagicSearchService;
