import React, { Fragment, useState, useCallback, useEffect, useContext } from 'react';
import infoBarType from '../../../enums/infoBarType';
import InfoBars from '../../common/InfoBars';
import { useIntl, FormattedMessage } from 'react-intl';
import BreadCrumbDisplay from '../../common/BreadCrumbDisplay';
import BreadCrumbOption from '../../../domainObjects/BreadCrumbOption';
import LoadingSpinner from '../../common/LoadingSpinner';
import ServicesListBuilder from './ServicesListBuilder';
import DiaryContext from '../../../contexts/DiaryContext';
import ServiceSettingsService from '../../../services/ServiceSettingsService';
import Service from '../../../domainObjects/Service';
import MomentHelper from '../../../helpers/MomentHelper';

let _ID = 0;

function ServicesPage() {
    const intl = useIntl();
    const [infoBars, setInfoBars] = useState([]);
    const [services, setServices] = useState([]);
    const [isLoadingServices, setIsLoadingServices] = useState(true);

    const diaryContext = useContext(DiaryContext);

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    function getBreadcrumbOptions() {
        return [
            new BreadCrumbOption(
                intl.formatMessage({
                    id: 'Settings.Title',
                }),
                '/Settings'
            ),
        ];
    }

    const retrieveNewSegmentId = useCallback(() => {
        let segmentId;
        diaryContext.segments.forEach((segment) => {
            segment.ValidityPeriods.forEach((p) => {
                if (MomentHelper.isDateWithinRange(MomentHelper.newInstance(), p.StartDate, p.EndDate)) {
                    segmentId = segment.Id;
                    return;
                }
            });
        });

        return segmentId;
    }, [diaryContext.segments]);

    const [segmentId] = useState(retrieveNewSegmentId());

    useEffect(() => {
        ServiceSettingsService.getServices(diaryContext.deploymentId, diaryContext.restaurantId, segmentId)
            .then((services) => {
                const servicesData = services.map((service) => new Service(service));
                const sortedServices = servicesData.sort(
                    (a, b) =>
                        MomentHelper.createDateTimeWithTime(a.timeFrom) -
                        MomentHelper.createDateTimeWithTime(b.timeFrom)
                );
                setServices(sortedServices);
            })
            .catch(() => {
                addErrorBar();
            })
            .finally(() => {
                setIsLoadingServices(false);
            });
    }, [diaryContext.deploymentId, diaryContext.restaurantId, segmentId, addErrorBar]);

    function getPageContent() {
        if (isLoadingServices) {
            return <LoadingSpinner />;
        } else {
            return (
                <Fragment>
                    <div className="row">
                        <div className="col-lg-12 page-header">
                            <div className="d-flex">
                                <h2 className="flex-fill">
                                    <FormattedMessage id="Common.Services" />
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="services-container">
                        <ServicesListBuilder
                            addSuccessBar={addSuccessBar}
                            addErrorBar={addErrorBar}
                            services={services}
                            segmentId={segmentId}
                            setServices={setServices}
                        />
                    </div>
                </Fragment>
            );
        }
    }

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <BreadCrumbDisplay
                breadcrumbs={getBreadcrumbOptions()}
                activePageTitle={intl.formatMessage({
                    id: 'Common.Services',
                })}
            />

            {getPageContent()}
        </Fragment>
    );
}

export default ServicesPage;
