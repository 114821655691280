import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BreadCrumbDisplay from './BreadCrumbDisplay';
import propTypeExtensions from '../../helpers/PropTypeExtensions';
import LoadingSpinner from './LoadingSpinner';
import InfoBars from './InfoBars';
import useScreenSize from '../../hooks/useScreenSize';
import ColouredDot from '../booking/ColouredDot';

function TabbedContentPage(props) {
    const { isTabletView } = useScreenSize();

    function getTabLabels() {
        const labels = [];
        props.tabLabels.forEach((label) => {
            let tabStyles = 'tab text-uppercase bold-text';
            if (props.currentTabKey === label.key) {
                tabStyles += ' selected';
            }
            const labelElement = (
                <div onClick={() => props.onTabChanged(label.key)} key={label.key} className={tabStyles}>
                    {label.name} {label.showDot && <ColouredDot colour={'#f29151'} />}
                </div>
            );
            labels.push(labelElement);
        });
        return labels;
    }

    function getPageContent() {
        if (props.isLoading) {
            return <LoadingSpinner />;
        } else {
            if (isTabletView) {
                return (
                    <div className="flex-container-inline mobile mt-3">
                        <div className="pb-3">
                            <div className="horizontal-tabs">{getTabLabels()}</div>
                            {props.sidePanelAdditionalContent && (
                                <div className="additional-text">{props.sidePanelAdditionalContent}</div>
                            )}
                        </div>
                        <div className={`tabbed-content ${props.tabbedContentClass}`}>{props.tabs}</div>
                    </div>
                );
            } else {
                return (
                    <div className="flex-container-inline mt-3 row">
                        <div className="col-md-3 verticle-tabs">
                            {getTabLabels()}
                            {props.sidePanelAdditionalContent && (
                                <div className="tab additional-text">{props.sidePanelAdditionalContent}</div>
                            )}
                        </div>
                        <div className={`tabbed-content col-md-9 ${props.tabbedContentClass}`}>{props.tabs}</div>
                    </div>
                );
            }
        }
    }

    return (
        <Fragment>
            <InfoBars infoBars={props.infoBars} setInfoBars={props.setInfoBars} />
            <div>
                {props.includeBreadcrumb && (
                    <BreadCrumbDisplay
                        breadcrumbs={props.breadcrumbOptions}
                        activePageTitle={props.breadcrumbActivePageTitle}
                    />
                )}
                <h2 className="page-header">{props.pageTitle}</h2>
            </div>
            {getPageContent()}
        </Fragment>
    );
}

TabbedContentPage.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    tabLabels: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
        })
    ).isRequired,
    currentTabKey: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.element).isRequired,
    sidePanelAdditionalContent: PropTypes.element,
    onTabChanged: PropTypes.func.isRequired,
    includeBreadcrumb: PropTypes.bool,
    breadcrumbOptions: propTypeExtensions.requiredIf(PropTypes.array, (props) => props.includeBreadcrumb),
    breadcrumbActivePageTitle: propTypeExtensions.requiredIf(PropTypes.string, (props) => props.includeBreadcrumb),

    setInfoBars: PropTypes.func,
    infoBars: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
            message: PropTypes.string,
        })
    ),
    isLoading: PropTypes.bool,
    tabbedContentClass: PropTypes.string,
};

export default TabbedContentPage;
