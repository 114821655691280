import overlayType from '../enums/overlayType';

class NewBookingOverlayContent {
    constructor(booking) {
        this.booking = booking ? booking : null;
        this.overlayType = overlayType.newBooking;
    }
}

export default NewBookingOverlayContent;
