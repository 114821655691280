import React, { useState, Fragment, useContext } from 'react';
import { Button, CustomInput } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as EditIcon } from '../../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../content/icons/Icon-Delete.svg';
import { ReactComponent as PreviewIcon } from '../../../../content/icons/Icon-Preview.svg';
import ConfirmActionButton from '../../../common/ConfirmActionButton';
import SettingsService from '../../../../services/SettingsService';
import DiaryContext from '../../../../contexts/DiaryContext';
import SettingsHelper from '../../../../helpers/SettingsHelper';
import MomentHelper from '../../../../helpers/MomentHelper';
import useScreenSize from '../../../../hooks/useScreenSize';

function MenuListingEntryPanel(props) {
    const intl = useIntl();
    const [awaitingIsEnabledRequest, setAwaitingIsEnabledRequest] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { isTabletView, isMobileView } = useScreenSize();

    const diaryContext = useContext(DiaryContext);

    function toggleTooltip() {
        setTooltipOpen(!tooltipOpen);
    }

    function getValidityPeriod() {
        if (props.menu.displayOnlineFrom && props.menu.displayOnlineUntil) {
            const dateFormat = MomentHelper.getDateFormatWithoutDay();

            const dateFrom = props.menu.displayOnlineFrom.format(dateFormat);
            const dateUntil = props.menu.displayOnlineUntil.format(dateFormat);
            return `${dateFrom} - ${dateUntil}`;
        } else {
            return intl.formatMessage({ id: 'Settings.Always' });
        }
    }

    function getUrl() {
        return SettingsHelper.getRestaurantMenuUrl(diaryContext.micrositeName) + props.menu.menuUrl;
    }

    function getButtons() {
        return (
            <Fragment>
                <div
                    className={
                        isMobileView || isTabletView ? 'row-element mob validity-period' : 'row-element validity-period'
                    }
                >
                    {getValidityPeriod()}
                </div>
                <a target="_blank" href={getUrl()} rel="noopener noreferrer">
                    <div
                        className={isMobileView || isTabletView ? 'row-element clickable mob' : 'row-element clickable'}
                    >
                        <PreviewIcon />
                    </div>
                </a>
                <div
                    className={isMobileView || isTabletView ? 'row-element clickable mob' : 'row-element clickable'}
                    onClick={() => props.toggleEditing()}
                >
                    <EditIcon />
                </div>
                <div
                    className={
                        isMobileView || isTabletView
                            ? 'row-element clickable delete mob'
                            : 'row-element clickable delete'
                    }
                >
                    <ConfirmActionButton
                        id={'deleteRowButton' + props.menu.id.toString()}
                        tooltipPlacement="top"
                        className="booking-status-icon-button"
                        icon={<DeleteIcon />}
                        tooltipOpen={tooltipOpen}
                        includeCloseIcon
                        toggle={toggleTooltip}
                    >
                        <FormattedMessage id="Common.DeletionConfirmation" />
                        <Button
                            className="btn-outline-primary cancel-btn"
                            onClick={() => props.deleteMenuDetail(props.menu.id)}
                        >
                            {intl.formatMessage({ id: 'Common.Delete' })}
                        </Button>
                    </ConfirmActionButton>
                </div>
            </Fragment>
        );
    }

    function getList() {
        return (
            <Fragment>
                <div
                    className={
                        isMobileView || isTabletView
                            ? 'row-element mob custom-controls-mobile'
                            : 'row-element custom-controls'
                    }
                >
                    <CustomInput
                        checked={props.menu.isEnabled}
                        onChange={() => updateIsMenuEnabled(props.menu.id, true)}
                        inline
                        type="radio"
                        id={'active ' + props.menu.id}
                        name={'customRadio' + props.menu.id}
                        label={intl.formatMessage({
                            id: 'Settings.Active',
                        })}
                        disabled={awaitingIsEnabledRequest}
                    />
                    <CustomInput
                        checked={!props.menu.isEnabled}
                        onChange={() => updateIsMenuEnabled(props.menu.id, false)}
                        inline
                        type="radio"
                        id={'disabled ' + props.menu.id}
                        name={'customRadio' + props.menu.id}
                        label={intl.formatMessage({
                            id: 'Settings.Inactive',
                        })}
                        disabled={awaitingIsEnabledRequest}
                    />
                </div>
                {(isMobileView || isTabletView) && <div className="mobile-menu-listing-row">{getButtons()}</div>}
                {!isMobileView && !isTabletView && <Fragment>{getButtons()}</Fragment>}
            </Fragment>
        );
    }

    function updateIsMenuEnabled(menuId, isEnabled) {
        setAwaitingIsEnabledRequest(true);
        SettingsService.UpdateMenuEnabledStatus(diaryContext.deploymentId, diaryContext.restaurantId, menuId, isEnabled)
            .then(() => {
                props.updateMenuEnabledStatus(menuId, isEnabled);
                props.addSuccessBar(
                    intl.formatMessage(
                        {
                            id: isEnabled ? 'Settings.SuccessMenuEnabling' : 'Settings.SuccessMenuDisabling',
                        },
                        { menuName: `'${props.menu.name}'` }
                    )
                );
            })
            .catch(() => {
                props.addErrorBar(
                    intl.formatMessage(
                        {
                            id: isEnabled ? 'Settings.ErrorMenuEnabling' : 'Settings.ErrorMenuDisabling',
                        },
                        { menuName: `'${props.menu.name}'` }
                    )
                );
            })
            .finally(() => {
                setAwaitingIsEnabledRequest(false);
            });
    }

    function getUploadedDate() {
        const dateFormat = MomentHelper.getDateFormatWithoutDay();
        return (
            <FormattedMessage
                id={'Settings.FileUploadedDate'}
                values={{ uploadDate: props.menu.uploadedDate.format(dateFormat) }}
            />
        );
    }

    return (
        <div key={props.menu.id} className="menu-item">
            <div className={isMobileView || isTabletView ? 'menu-listing-entry mob' : 'menu-listing-entry'}>
                <div className="mr-auto menu-description">
                    <div>
                        <div className="bold-text pb-1">{props.menu.name}</div>
                        <div>{getUploadedDate()}</div>
                    </div>
                </div>
                <Fragment>{getList()}</Fragment>
            </div>
        </div>
    );
}
export default MenuListingEntryPanel;
