import { v4 as uuidv4 } from 'uuid';
import Modifier from './Modifier';

class MenuItem {
    constructor(menuItem) {
        this.id = null;
        this.eposId = menuItem && menuItem.Id ? menuItem.Id : null;
        this.name = menuItem && menuItem.Name ? menuItem.Name : '';
        this.description = menuItem && menuItem.Description ? menuItem.Description : '';
        this.price = menuItem && menuItem.Price ? menuItem.Price : '';
        this.optionCategories =
            menuItem && menuItem.OptionCategories ? menuItem.OptionCategories.map((cat) => new Modifier(cat)) : [];
        this.displayOrder = menuItem && menuItem.DisplayOrder ? menuItem.DisplayOrder : 0;
        this.uniqueIdentifier = uuidv4();
    }

    populateFromDiaryDto(diaryMenuItemDto) {
        this.id = diaryMenuItemDto.Id;
        this.eposId = diaryMenuItemDto.EposId;
        this.name = diaryMenuItemDto.Name;
        this.description = diaryMenuItemDto.Description;
        this.displayOrder = diaryMenuItemDto.DisplayOrder;
        this.price = diaryMenuItemDto.Price;
        this.optionCategories = diaryMenuItemDto.OptionCategories
            ? diaryMenuItemDto.OptionCategories.map((m) => {
                  const modifier = new Modifier();
                  modifier.populateFromDiaryDto(m);
                  return modifier;
              })
            : [];
    }
}

export default MenuItem;
