import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import MenuListingCreationPanel from './MenuListingCreationPanel';
import MenuListingEntryPanel from './MenuListingEntryPanel';

function MenuListingEntryWrapper(props) {
    const [isEditing, setIsEditing] = useState(false);

    const toggleEditing = () => {
        setIsEditing(!isEditing);
    };

    function saveMenu(menu, file) {
        props.saveMenuDetails(menu, file, toggleEditing);
    }

    return (
        <Fragment>
            <div>
                {!isEditing && (
                    <MenuListingEntryPanel
                        key={props.menu.uniqueIdentifier}
                        menu={props.menu}
                        toggleEditing={toggleEditing}
                        deleteMenuDetail={props.deleteMenuDetail}
                        addSuccessBar={props.addSuccessBar}
                        addErrorBar={props.addErrorBar}
                        updateMenuEnabledStatus={props.updateMenuEnabledStatus}
                    />
                )}

                {isEditing && (
                    <MenuListingCreationPanel
                        key={props.menu.uniqueIdentifier}
                        menu={props.menu}
                        isEditing={isEditing}
                        toggleEditing={toggleEditing}
                        menuTypes={props.menuTypes}
                        cultureCodes={props.cultureCodes}
                        saveMenuDetails={saveMenu}
                        setFileToBeUploaded={props.setFileToBeUploaded}
                        existingMenus={props.existingMenus}
                        isSaving={props.isSaving}
                    />
                )}
            </div>
        </Fragment>
    );
}

MenuListingEntryWrapper.propTypes = {
    menu: PropTypes.object.isRequired,
    saveMenuDetails: PropTypes.func.isRequired,
    menuTypes: PropTypes.array.isRequired,
    cultureCodes: PropTypes.array.isRequired,
    existingMenus: PropTypes.array,
    isSaving: PropTypes.bool,
};

export default MenuListingEntryWrapper;
