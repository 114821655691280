import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowUp } from '../../content/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../content/icons/arrow-down.svg';
import propTypeExtensions from '../../helpers/PropTypeExtensions';
import { UncontrolledTooltip } from 'reactstrap';

class TableHeader extends React.Component {
    render() {
        let toolTipText;
        if (this.props.toolTipText) {
            toolTipText = (
                <UncontrolledTooltip
                    className={this.props.toolTipClasses ? this.props.toolTipClasses.join(' ') : ''}
                    placement="top"
                    target={
                        this.props.heading.replace(
                            /[^a-zA-Z\d]/gi,
                            '_'
                        ) /*https://www.w3.org/TR/CSS21/syndata.html#value-def-identifier*/
                    }
                >
                    {this.props.toolTipText}
                </UncontrolledTooltip>
            );
        } else {
            toolTipText = '';
        }

        if (this.props.sortable) {
            let directionMarker;
            if (this.props.sortOrder === this.props.currentSortOrder) {
                directionMarker = this.props.sortAscending ? <div>{<ArrowUp />}</div> : <div>{<ArrowDown />}</div>;
            }
            return (
                <th
                    className="sortable"
                    onClick={() => this.props.onSorting(this.props.sortOrder)}
                    id={this.props.heading.replace(/[^a-zA-Z\d]/gi, '_')}
                >
                    {this.props.heading}
                    {directionMarker}
                    {toolTipText}
                </th>
            );
        } else {
            return (
                <th id={this.props.heading.replace(/[^a-zA-Z\d]/gi, '_')}>
                    {this.props.heading}
                    {toolTipText}
                </th>
            );
        }
    }
}

TableHeader.propTypes = {
    heading: PropTypes.string.isRequired,
    sortable: PropTypes.bool.isRequired,
    sortOrder: propTypeExtensions.requiredIf(PropTypes.number, (props) => props.sortable),
    onSorting: propTypeExtensions.requiredIf(PropTypes.func, (props) => props.sortable),
    currentSortOrder: PropTypes.number,
    sortAscending: PropTypes.bool,
    index: PropTypes.number,
    toolTipText: PropTypes.string,
    toolTipClasses: PropTypes.array,
};

export default TableHeader;
