import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UpArrowIcon } from '../../content/icons/up-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../content/icons/down-arrow.svg';

function ExpandableSection(props) {
    const [shouldExpand, setShouldExpand] = useState(true);
    const [hover, setHover] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const expandableBodyChildContent = useRef();

    useEffect(() => {
        if (expandableBodyChildContent.current) {
            setShouldExpand(false);
        }
        const contentBodyRect = expandableBodyChildContent.current.getBoundingClientRect();
        setShouldExpand(contentBodyRect.height > props.previewHeight);
    }, [props.previewHeight]);

    return (
        <Fragment>
            <div
                className={
                    hover && shouldExpand
                        ? 'expandable-section-wrapper hovered-expandable-section'
                        : 'expandable-section-wrapper'
                }
                style={{ maxHeight: expanded ? null : props.previewHeight }}
                onMouseLeave={() => setHover(!hover)}
                onMouseEnter={() => setHover(!hover)}
                onClick={() => setExpanded(!expanded)}
            >
                <div className="expandable-section-body" ref={expandableBodyChildContent}>
                    {props.children}
                </div>
            </div>
            {shouldExpand && (
                <div className="expandable-section-toggle d-flex" onClick={() => setExpanded(!expanded)}>
                    {' '}
                    <div className="ml-auto">{expanded ? props.expandedText : props.hiddenText}</div>
                    {expanded ? <UpArrowIcon /> : <DownArrowIcon />}
                </div>
            )}
        </Fragment>
    );
}

ExpandableSection.propTypes = {
    previewHeight: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    expandedText: PropTypes.string.isRequired,
    hiddenText: PropTypes.string.isRequired,
};

export default ExpandableSection;
