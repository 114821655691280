import React from 'react';
import { Input, Button } from 'reactstrap';

const NumericInputField = (props) => {
    function decrementItem() {
        let value = props.value - 1;
        if (value < props.min) return;

        props.onChangeHandler(value, props.itemId);
    }

    function incrementItem() {
        let value = props.value + 1;
        if (value > props.max) return;

        props.onChangeHandler(value, props.itemId);
    }

    return (
        <div className="input-group rd-input-numeric-container">
            <div className="input-group-prepend">
                <Button disabled={props.value === props.min} className="rd-numeric-button btn" onClick={decrementItem}>
                    -
                </Button>
            </div>

            <Input type={'number'} value={props.value} onChange={props.onChangeHandler} readOnly={props.readOnly} />

            <div className="input-group-append">
                <Button className="rd-numeric-button btn" onClick={incrementItem}>
                    +
                </Button>
            </div>
        </div>
    );
};

export default NumericInputField;
