import ApiHelper from '../helpers/ApiHelper';

class ErrorService {
    static reportErrorFromReact(message, errorStack, componentStack) {
        return ApiHelper.postJSON('/api/Logging/JavascriptError/', {
            Message: JSON.stringify(message),
            ErrorStack: JSON.stringify(errorStack),
            ComponentStack: JSON.stringify(componentStack),
        });
    }

    static reportErrorFromRequest(message) {
        if (message.config && message.config.data) {
            let data = JSON.parse(message.config.data);
            if (data.password) {
                data.password = '*******';
            }
            message.config.data = data;
        }
        return ApiHelper.postJSON('/api/Logging/RequestError/', {
            Message: JSON.stringify(message),
        });
    }
}

export default ErrorService;
