class GeneralDetails {
    constructor(dto) {
        this.venueDescription = dto && dto.GeneralDescription ? dto.GeneralDescription : '';
        this.openingInformation = dto && dto.OpeningInformation ? dto.OpeningInformation : '';
        this.websiteUrl = dto && dto.WebsiteUrl ? dto.WebsiteUrl : '';
    }

    getDTO(venueDescription, openingInformation, websiteUrl) {
        return {
            GeneralDescription: venueDescription,
            OpeningInformation: openingInformation,
            WebsiteUrl: websiteUrl,
        };
    }
}

export default GeneralDetails;
