import React from 'react';
import PropTypes from 'prop-types';
import RDLModal from '../../common/RDLModal';
import { useIntl } from 'react-intl';

function SyncEposDataModal(props) {
    const intl = useIntl();

    return (
        <RDLModal
            isModalOpen={props.isModalOpen}
            setIsModalOpen={props.setIsModalOpen}
            action={props.syncMenuItems}
            title={intl.formatMessage({ id: 'Settings.SyncMenuItemsModalTitle' })}
            body={intl.formatMessage({ id: 'Settings.SyncMenuItemsModalBodyText' })}
            actionButtonText={intl.formatMessage({ id: 'Settings.Sync' })}
            actionButtonPendingText={intl.formatMessage({ id: 'Settings.Syncing' })}
        />
    );
}

SyncEposDataModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    syncMenuItems: PropTypes.func.isRequired,
};

export default SyncEposDataModal;
