import React from 'react';
import PropTypes from 'prop-types';
import ColouredDot from '../booking/ColouredDot';
import CustomerHelper from '../../helpers/CustomerHelper';
import moment from 'moment-timezone';
import MomentHelper from '../../helpers/MomentHelper';
import { ReactComponent as CustomerCommentsIcon } from '../../content/icons/Icon-Customer-Comment.svg';
import { FormattedMessage } from 'react-intl';

function AlertPanel(props) {
    return (
        <div
            className={`booking-alert ${props.rowColour}`}
            onClick={() => props.setAlertBookingOverlayId(props.booking.id)}
        >
            <div className="booking-alert-content d-flex">
                <div className="Booking-alert-column status-icon">{props.icon}</div>
                <div className="Booking-alert-column">
                    <div className="mr-auto">
                        <b>{props.title}</b>
                    </div>
                    <span className="mt-auto">{props.description}</span>
                    <div className="mt-auto">
                        <ColouredDot colour={props.booking.channelColour} />
                        <span className="ml-1">{CustomerHelper.displayFullName(props.booking.customer)}</span>
                    </div>
                </div>
                <div className="Booking-alert-column">
                    <div>
                        <b>
                            <FormattedMessage
                                id="Common.AlertTime"
                                values={{
                                    alertTime: moment
                                        .duration(MomentHelper.newInstance().diff(props.booking.bookingDateTime))
                                        .humanize(),
                                }}
                            />
                        </b>
                        <div className="booking-alert-customer-comment">
                            {props.booking.customer && props.booking.customer.customerComments && (
                                <CustomerCommentsIcon />
                            )}
                        </div>
                    </div>
                    <span className="mt-auto">{props.booking.partySize}</span>
                </div>
            </div>
        </div>
    );
}

AlertPanel.propTypes = {
    rowColour: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    booking: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    setAlertBookingOverlayId: PropTypes.func.isRequired,
};

export default AlertPanel;
