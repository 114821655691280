import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import { FormattedMessage } from 'react-intl';
import useScreenSize from '../../../hooks/useScreenSize';

function TableLabelEntry(props) {
    const { isMobileView } = useScreenSize();

    return (
        <div
            className={isMobileView ? 'created-entry-mob' : 'created-entry'}
            role="group"
            key={props.tableLabel.id}
            aria-label="First group"
        >
            <div className={isMobileView ? 'table-info-container-mob' : 'col-md-5 table-info-container'}>
                <div>
                    <FormattedMessage id="Common.Table" />
                </div>
                <div>{props.tableLabel.name}</div>
                {isMobileView && (
                    <div className="side-panel-mob button-top">
                        <Button data-testid="edit-icon-table-label" onClick={() => props.toggleEditing()}>
                            {' '}
                            {<EditIcon />}{' '}
                        </Button>
                    </div>
                )}
            </div>
            <div className={isMobileView ? 'table-info-container-mob' : 'col-md-6 table-info-container'}>
                <div>
                    <FormattedMessage id="Common.Covers" />
                </div>
                <div>{props.tableLabel.covers}</div>
                {isMobileView && (
                    <div className="side-panel-mob">
                        <Button
                            id={'deleteRowButton' + props.tableLabel.uniqueIdentifier}
                            onClick={() => props.openDeletionModal(props.tableLabel)}
                            data-testid="delete-icon-table-label"
                        >
                            <DeleteIcon />
                        </Button>
                    </div>
                )}
            </div>

            {!isMobileView && (
                <div className="side-panel">
                    <Button data-testid="edit-icon-table-label" onClick={() => props.toggleEditing()}>
                        {' '}
                        {<EditIcon />}{' '}
                    </Button>
                    <Button
                        id={'deleteRowButton' + props.tableLabel.uniqueIdentifier}
                        onClick={() => props.openDeletionModal(props.tableLabel)}
                        data-testid="delete-icon-table-label"
                    >
                        <DeleteIcon />
                    </Button>
                </div>
            )}
        </div>
    );
}

TableLabelEntry.propTypes = {
    tableLabel: PropTypes.object.isRequired,
    openDeletionModal: PropTypes.func,
    toggleEditing: PropTypes.func,
};

export default TableLabelEntry;
