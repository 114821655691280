import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DiaryContext from '../../contexts/DiaryContext';
import stripeSubscriptionStatus from '../../enums/stripeSubscriptionStatus';
import TextWithIcon from '../common/TextWithIcon';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';
import StripeService from '../../services/StripeService';
import { useIntl, FormattedMessage } from 'react-intl';
import RDLModal from '../common/RDLModal';

function UpdatePaymentModal(props) {
    const intl = useIntl();
    const diaryContext = useContext(DiaryContext);

    function getModalTitle() {
        if (diaryContext.subscriptionStatus === stripeSubscriptionStatus.pastDue) {
            return intl.formatMessage({ id: 'AccountSubmission.UpdatePaymentWarningModalTitle' });
        } else {
            return intl.formatMessage({ id: 'AccountSubmission.UpdatePaymentModalTitle' });
        }
    }

    function getModalBodyText() {
        if (diaryContext.subscriptionStatus === stripeSubscriptionStatus.pastDue) {
            return <FormattedMessage id="AccountSubmission.UpdatePaymentWarningModalDescription" />;
        } else {
            return <FormattedMessage id="AccountSubmission.UpdatePaymentModalDescription" />;
        }
    }

    function getModalBody() {
        return (
            <div>
                <div className="warning-info-icon-container">
                    <TextWithIcon text={getIconText()} icon={<InfoIcon />} />
                </div>
                {getModalBodyText()}
            </div>
        );
    }

    function getIconText() {
        if (diaryContext.subscriptionStatus === stripeSubscriptionStatus.pastDue) {
            return intl.formatMessage({ id: 'AccountSubmission.UpdatePaymentWarningModalInfoText' });
        } else {
            return intl.formatMessage({ id: 'AccountSubmission.UpdatePaymentModalInfoText' });
        }
    }

    function createPortalSession(callback) {
        StripeService.createPortalSession(diaryContext.deploymentId, diaryContext.restaurantId).then((response) => {
            props.dismissModal();
            window.location = response;

            if (callback) {
                callback();
            }
        });
    }

    return (
        <RDLModal
            isModalOpen={props.isModalOpen}
            setIsModalOpen={props.dismissModal}
            action={createPortalSession}
            title={getModalTitle()}
            body={getModalBody()}
            actionButtonText={intl.formatMessage({ id: 'AccountSubmission.UpdateBillingDetails' })}
            hideCloseButton={diaryContext.subscriptionStatus === stripeSubscriptionStatus.pastDue}
            nonDismissible={diaryContext.subscriptionStatus === stripeSubscriptionStatus.disabled}
        />
    );
}

UpdatePaymentModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    dismissModal: PropTypes.func.isRequired,
};

export default UpdatePaymentModal;
