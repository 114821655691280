import React, { Fragment, useState, useEffect } from 'react';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneNumberInput from '../common/PhoneNumberInput';
import Option from '../common/Option';
import ValidationInput from '../common/ValidationInput';
import Customer from '../../domainObjects/Customer';
import useValidation from '../../hooks/useValidation';
import customerFields from '../../enums/customerFields';
import validator from 'validator';
import PropTypes from 'prop-types';

function CustomerOverlayContent(props) {
    const intl = useIntl();
    const [customer, setCustomer] = useState(
        new Customer({
            MobilePhoneCode: props.countryCode,
            PhoneCode: props.countryCode,
            Surname: props.customerSearchTerm,
        })
    );
    const [originalCustomer, setOriginalCustomer] = useState(null);

    const { register, errors, submitDisabled, submit } = useValidation();

    useEffect(() => {
        if (props.isEditingCustomer) {
            const customerCopy = { ...props.customer };
            customerCopy.mobilePhoneCode = customerCopy.mobilePhoneCode
                ? customerCopy.mobilePhoneCode
                : props.countryCode;
            customerCopy.phoneCode = customerCopy.phoneCode ? customerCopy.phoneCode : props.countryCode;
            setCustomer(customerCopy);
            setOriginalCustomer(customerCopy);
        }
    }, [props.countryCode, props.customer, props.isEditingCustomer]);

    function getCountryCodeOptions() {
        return props.countryCodes.map(
            (countryCode) => new Option(`${countryCode.Name}: +${countryCode.PhoneCode.toString()}`, countryCode.Id)
        );
    }

    function onCustomerChange(field, value) {
        const customerCopy = { ...customer };
        if (field === customerFields.mobilePhoneCode || field === customerFields.phoneCode) {
            value = props.countryCodes.find((cc) => cc.Id === value).PhoneCode;
        }
        customerCopy[field] = value;
        setCustomer(customerCopy);
    }

    function getButtonText() {
        if (props.isEditingCustomer) {
            return props.isMakingRequest ? 'Common.Updating' : 'Common.Update';
        } else {
            return props.isMakingRequest ? 'Common.Adding' : 'Common.Add';
        }
    }

    function getCountryCodeId(countryCode) {
        return props.countryCodes.find((cc) => cc.PhoneCode === parseInt(countryCode)).Id;
    }

    function checkValidEmail(email) {
        if (email && !validator.isEmail(email)) {
            return intl.formatMessage({ id: 'Customer.EmailValidation' });
        }
    }

    function isUpdateButtonDisabled() {
        if (props.isEditingCustomer) {
            return JSON.stringify(originalCustomer) === JSON.stringify(customer);
        }
    }

    return (
        <Fragment>
            <div className="scrollable-panel customer-panel" data-testid="customer-overlay-body">
                <FormGroup>
                    <Label for="customerName">
                        <FormattedMessage id="Customer.Name" />
                    </Label>
                    <ValidationInput
                        name="customerName"
                        type="text"
                        value={customer.surname}
                        onChange={(e) => onCustomerChange(customerFields.surname, e)}
                        innerRef={register({ required: intl.formatMessage({ id: 'Customer.NameRequired' }) })}
                        errors={errors}
                        testId={'customer-name-input'}
                    />
                </FormGroup>
                <FormGroup>
                    <PhoneNumberInput
                        label={intl.formatMessage({ id: 'Customer.MobileNumber' })}
                        id="customerMobile"
                        optionGroups={getCountryCodeOptions()}
                        onChange={(e) => onCustomerChange(customerFields.mobileNumber, e)}
                        selectedValue={getCountryCodeId(customer.mobilePhoneCode)}
                        inputValue={customer.mobileNumber}
                        onCountryCodeChange={(e) => onCustomerChange(customerFields.mobilePhoneCode, e)}
                    />
                </FormGroup>
                <FormGroup>
                    <PhoneNumberInput
                        label={intl.formatMessage({ id: 'Customer.TelephoneNumber' })}
                        id="customerTelephone"
                        optionGroups={getCountryCodeOptions()}
                        selectedValue={getCountryCodeId(customer.phoneCode)}
                        onChange={(e) => onCustomerChange(customerFields.phoneNumber, e)}
                        inputValue={customer.phoneNumber}
                        onCountryCodeChange={(e) => onCustomerChange(customerFields.phoneCode, e)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="customerEmail">
                        <FormattedMessage id="Customer.Email" />
                    </Label>
                    <ValidationInput
                        name="customerEmail"
                        type="text"
                        value={customer.email}
                        onChange={(e) => onCustomerChange(customerFields.email, e)}
                        innerRef={register({ validate: (value) => checkValidEmail(value) })}
                        errors={errors}
                        testId={'customer-email-input'}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="customerComments">
                        <FormattedMessage id="Customer.CustomerComments" />
                    </Label>
                    <Input
                        name="customerComments"
                        className="customer-comments"
                        type="textarea"
                        rows="6"
                        maxLength="2000"
                        value={customer.customerComments}
                        onChange={(e) => onCustomerChange(customerFields.customerComments, e.target.value)}
                    />
                    <div>{customer.customerComments.length} / 2000</div>
                </FormGroup>
            </div>
            <div className="overlay-footer">
                <div className="overlay-footer-content float-right">
                    <Button
                        color="outline-primary"
                        className="mr-2"
                        onClick={() => props.toggleShowCustomerOverlay(false)}
                    >
                        <FormattedMessage id="Common.Cancel" />
                    </Button>
                    <Button
                        data-testid="customer-action-button"
                        color="primary"
                        onClick={() =>
                            props.isEditingCustomer
                                ? submit(() => props.updateCustomer(customer, props.bookingId, props.callback))
                                : submit(() => props.addNewCustomer(customer, props.bookingId, props.callback))
                        }
                        disabled={submitDisabled || isUpdateButtonDisabled()}
                    >
                        <FormattedMessage id={getButtonText()} />
                    </Button>
                </div>
            </div>
        </Fragment>
    );
}

CustomerOverlayContent.propTypes = {
    countryCode: PropTypes.number.isRequired,
    customer: PropTypes.object,
    countryCodes: PropTypes.array.isRequired,
    toggleShowCustomerOverlay: PropTypes.func.isRequired,
    bookingId: PropTypes.number,
    isEditingCustomer: PropTypes.bool,
    isMakingRequest: PropTypes.bool,
    updateCustomer: PropTypes.func,
    addNewCustomer: PropTypes.func,
    callback: PropTypes.func,
    customerSearchTerm: PropTypes.string,
};

export default CustomerOverlayContent;
