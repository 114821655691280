class CustomerHelper {
    static displayFullName(customer) {
        if (customer) {
            return customer.fullname ? customer.fullname : `${customer.firstname} ${customer.surname}`;
        }
        return '';
    }

    static toJson(customer) {
        return {
            Id: customer.id,
            Firstname: customer.firstname,
            Surname: customer.surname,
            Fullname: customer.fullname,
            PhoneCode: customer.phoneCode,
            PhoneNumber: customer.phoneNumber,
            MobilePhoneCode: customer.mobilePhoneCode,
            MobileNumber: customer.mobileNumber,
            Company: customer.company,
            Email: customer.email,
            IsEnabled: customer.isEnabled,
            IsVip: customer.isVip,
            Comments: customer.customerComments,
        };
    }
}

export default CustomerHelper;
