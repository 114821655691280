import React, { useState } from 'react';
import MerchantStatus from './MerchantStatus';
import { ReactComponent as VisaIcon } from '../../../content/icons/visa.svg';
import { ReactComponent as MastercardIcon } from '../../../content/icons/mastercard.svg';
import { ReactComponent as AmexIcon } from '../../../content/icons/amex.svg';
import merchantTypes from '../../../enums/merchantTypes';
import AddMIDModal from './AddMIDModal';
import { useIntl } from 'react-intl';
import ToolTipIcon from '../../common/ToolTipIcon';

const SubscriptionStatus = ({
    isLoading,
    visaStatus,
    mastercardStatus,
    amexStatus,
    subscriptionToolTipOpen,
    toggleSubscriptionToolTipOpen,
}) => {
    const [isAddMIDModalOpen, setIsAddMIDModalOpen] = useState(false);
    const [selectedMerchantType, setSelectedMerchantType] = useState(merchantTypes.visa);
    const intl = useIntl();
    if (!isLoading) {
        return (
            <>
                <div className="subscription-status-information">
                    <div className="content">
                        <span>{intl.formatMessage({ id: 'Settings.DcrSubscriptionStatusTitle' })}</span>
                        <ToolTipIcon
                            id={'subscription-status-tooltip'}
                            className="subscription-status-tooltip"
                            innerClassName="subscription-status-inner-tooltip"
                        >
                            <p>{intl.formatMessage({ id: 'Settings.DcrSubscriptionStatusTooltip' })}</p>
                        </ToolTipIcon>
                    </div>
                    <div className="d-flex">
                        <MerchantStatus
                            MerchantIcon={<VisaIcon />}
                            merchantStatus={visaStatus}
                            merchant={merchantTypes.visa}
                            setSelectedMerchantType={setSelectedMerchantType}
                            setIsAddMIDModalOpen={setIsAddMIDModalOpen}
                        />
                        <MerchantStatus
                            MerchantIcon={<MastercardIcon />}
                            merchantStatus={mastercardStatus}
                            merchant={merchantTypes.mastercard}
                            setSelectedMerchantType={setSelectedMerchantType}
                            setIsAddMIDModalOpen={setIsAddMIDModalOpen}
                        />
                        <MerchantStatus
                            MerchantIcon={<AmexIcon />}
                            merchantStatus={amexStatus}
                            merchant={merchantTypes.americanExpress}
                            setSelectedMerchantType={setSelectedMerchantType}
                            setIsAddMIDModalOpen={setIsAddMIDModalOpen}
                        />
                    </div>
                </div>
                <AddMIDModal
                    isModalOpen={isAddMIDModalOpen}
                    setIsModalOpen={setIsAddMIDModalOpen}
                    selectedMerchantType={selectedMerchantType}
                    setSelectedMerchantType={setSelectedMerchantType}
                />
            </>
        );
    }
};

export default SubscriptionStatus;
