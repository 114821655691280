class MenuManagementMenu {
    constructor(menu) {
        this.id = menu && menu.Id ? menu.Id : null;
        this.name = menu && menu.Name ? menu.Name : '';
        this.menuCategoryId = menu && menu.MenuCategoryId ? menu.MenuCategoryId : null;
        this.isDisabled = menu && menu.IsDisabled ? menu.IsDisabled : false;
        this.latestMenuVersionId = menu && menu.LatestMenuVersionId ? menu.LatestMenuVersionId : null;
    }
}

export default MenuManagementMenu;
