import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import MomentHelper from '../../helpers/MomentHelper';
import TextWithIcon from '../common/TextWithIcon';
import { ReactComponent as ClockIcon } from '../../content/icons/Icon-Clock.svg';
import { ReactComponent as CalendarIcon } from '../../content/icons/Icon-Calendar.svg';
import { ReactComponent as FullySeatedIcon } from '../../content/icons/Icon-FullySeated.svg';
import { ReactComponent as PersonIcon } from '../../content/icons/person.svg';
import { ReactComponent as MenuIcon } from '../../content/icons/menu.svg';
import { ReactComponent as DcRewardsRedemption } from '../../content/icons/dishcult-rewards-redemption.svg';
import BookingStatusControl from '../booking/BookingStatusControl';
import ColouredDot from '../booking/ColouredDot';
import BookingStatus from '../../domainObjects/BookingStatus';
import PropTypes from 'prop-types';
import useFormattedTime from '../../hooks/useFormattedTime';
import useScreenSize from '../../hooks/useScreenSize';
import orderAtTableStatus from '../../enums/orderAtTableStatus';
import { FormattedMessage } from 'react-intl';
import CurrencyDiv from '../common/CurrencyDiv';

function BookingSummaryOverlay(props) {
    const formattedTime = useFormattedTime(props.overlayContent.booking.visitDateTime);
    const { isTabletView, isMobileView } = useScreenSize();

    function getTableLabels() {
        let tables = '';
        if (props.tableLabels && props.tableLabels.length > 0) {
            const tableNames = props.tableLabels.map((label) => label.name);
            tables = tableNames.join(', ');
        } else {
            return '-';
        }
        return tables;
    }

    function updateArrivalStatus(newArrivalStatus) {
        return props.updateArrivalStatus(
            props.overlayContent.booking,
            newArrivalStatus,
            props.closeBookingStatusControl
        );
    }

    function closeBooking() {
        return props.closeBooking(props.overlayContent.booking, props.closeBookingStatusControl);
    }

    function toggleReconfirmedStatus() {
        return props.toggleReconfirmedStatus(props.overlayContent.booking, props.closeBookingStatusControl);
    }

    function openCancellationModalWithBookingId() {
        return props.openCancellationModalWithBookingId(props.overlayContent.booking, props.closeBookingStatusControl);
    }

    function rebookBooking() {
        return props.rebookBooking(props.overlayContent.booking, props.closeBookingStatusControl);
    }

    function getVoucherValue() {
        // TODO: Update once vouchers include specific values
        return <CurrencyDiv price={'10.00'} />;
    }

    if (props) {
        return (
            <Fragment>
                <div className="overlay-panel">
                    <div className="booking-summary-row">
                        <Row>
                            <Col>
                                <BookingStatusControl
                                    booking={new BookingStatus(props.overlayContent.booking)}
                                    bookingStatusButtonId={
                                        'bookingStatusOverlayButton_' + props.overlayContent.booking.id
                                    }
                                    tooltipPlacement={isTabletView || isMobileView ? 'bottom-start' : 'left-start'}
                                    tooltipOpen={props.bookingStatusControlOpen}
                                    toggleBookingStatusTooltip={props.toggleBookingStatusTooltip}
                                    includeStatusTextOnButton={true}
                                    buttonClassName="booking-status-icon-button"
                                    updateArrivalStatus={updateArrivalStatus}
                                    closeBooking={closeBooking}
                                    openCancellationModalWithBookingId={openCancellationModalWithBookingId}
                                    toggleReconfirmedStatus={toggleReconfirmedStatus}
                                    rebookBooking={rebookBooking}
                                    closeBookingStatusControl={props.closeBookingStatusControl}
                                />
                            </Col>
                            <Col>
                                <div>#{props.overlayContent.booking.bookingReference}</div>
                                <div className="mt-2">
                                    <ColouredDot colour={props.overlayContent.booking.channelColour} />
                                    {props.overlayContent.booking.channelName}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="booking-summary-row">
                        <Row>
                            <Col>
                                <TextWithIcon
                                    icon={
                                        <div>
                                            <CalendarIcon />
                                        </div>
                                    }
                                    text={MomentHelper.formattedDate(props.overlayContent.booking.visitDateTime)}
                                />
                            </Col>
                            <Col>
                                <TextWithIcon
                                    icon={
                                        <div>
                                            <ClockIcon />
                                        </div>
                                    }
                                    text={formattedTime}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="booking-summary-row divider-line">
                        <Row>
                            <Col>
                                <TextWithIcon
                                    icon={
                                        <div>
                                            <PersonIcon />
                                        </div>
                                    }
                                    text={props.overlayContent.booking.partySize.toString()}
                                />
                            </Col>
                            <Col>
                                <TextWithIcon
                                    icon={
                                        <div>
                                            <FullySeatedIcon />
                                        </div>
                                    }
                                    text={getTableLabels()}
                                />
                            </Col>
                        </Row>
                    </div>
                    {props.overlayContent.booking.preorderMenuName &&
                        (props.overlayContent.booking.hasUnprocessedPayments ||
                            props.overlayContent.booking.orderAtTableStatus === orderAtTableStatus.Completed) && (
                            <div className="booking-summary-row divider-line">
                                <TextWithIcon
                                    icon={
                                        <div>
                                            <MenuIcon />
                                        </div>
                                    }
                                    text={props.overlayContent.booking.preorderMenuName}
                                    iconClass={'extra-padding'}
                                    textClass={'extra-padding'}
                                />
                            </div>
                        )}
                    {props.overlayContent.booking.dishcultRewardsVoucherCode != null && (
                        <div className="booking-summary-row divider-line">
                            <div class="d-flex text-with-icon">
                                <div class="icon">
                                    <DcRewardsRedemption />
                                </div>
                                <div class="text">
                                    <FormattedMessage id="Bookings.DishcultRewardsRedemption" />
                                    <br />
                                    {getVoucherValue()}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

BookingSummaryOverlay.propTypes = {
    overlayContent: PropTypes.object.isRequired,
    updateArrivalStatus: PropTypes.func,
    closeBooking: PropTypes.func,
    openCancellationModalWithBookingId: PropTypes.func,
    toggleReconfirmedStatus: PropTypes.func,
    toggleBookingStatusTooltip: PropTypes.func,
    bookingStatusControlOpen: PropTypes.bool,
    rebookBooking: PropTypes.func,
    closeBookingStatusControl: PropTypes.func,
    tableLabels: PropTypes.array,
};

export default BookingSummaryOverlay;
