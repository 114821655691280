import React, { useState, useContext } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import Option from '../../../components/common/Option';
import Dropdown from '../../../components/common/Dropdown';
import DiaryContext from '../../../contexts/DiaryContext';
import LoyaltyService from '../../../services/LoyaltyService';
import PropTypes from 'prop-types';
import validator from 'validator';
import ValidationMessage from '../../common/ValidationMessage';
import merchantTypes from '../../../enums/merchantTypes';
import { useIntl } from 'react-intl';
import MerchantId from '../../../domainObjects/MerchantId';

const AddMIDModal = ({ isModalOpen, setIsModalOpen, selectedMerchantType, setSelectedMerchantType }) => {
    const [merchantId, setMerchantId] = useState('');
    const [validationActivated, setValidationActivated] = useState(false);
    const intl = useIntl();
    const diaryContext = useContext(DiaryContext);

    const addMID = () => {
        if (validationActivated && merchantId)
            LoyaltyService.addMIDToLocation(
                diaryContext.deploymentId,
                diaryContext.restaurantId,
                new MerchantId({
                    MerchantType: selectedMerchantType,
                    MerchantId: merchantId,
                })
            ).then(() => {
                setIsModalOpen(false);
                setSelectedMerchantType(null);
                setMerchantId('');
            });
    };

    const merchantTypeOptions = [
        new Option(intl.formatMessage({ id: 'Settings.Visa' }), merchantTypes.visa),
        new Option(intl.formatMessage({ id: 'Settings.Mastercard' }), merchantTypes.mastercard),
        new Option(intl.formatMessage({ id: 'Settings.AmericanExpress' }), merchantTypes.americanExpress),
    ];

    const isEmpty = (value) => {
        return validator.isEmpty(value, { ignore_whitespace: true });
    };

    return (
        <Modal isOpen={isModalOpen} toggle={setIsModalOpen} centered>
            <ModalBody>
                <div className="merchant-id-container">
                    <div className="d-flex merchant-id">
                        <div className="column">
                            <Dropdown
                                title={intl.formatMessage({ id: 'Settings.MerchantType' })}
                                onValueChange={(val) => {
                                    setSelectedMerchantType(val);
                                }}
                                selectedValue={selectedMerchantType}
                                options={merchantTypeOptions}
                            />
                        </div>
                        <div className="column">
                            <Label>{intl.formatMessage({ id: 'Settings.MerchantID' })}</Label>
                            <Input
                                type="text"
                                value={merchantId}
                                onChange={(e) => {
                                    setMerchantId(e.target.value);
                                }}
                                invalid={isEmpty(merchantId) && validationActivated}
                                className="validation-input-feedback"
                                onBlur={() => {
                                    setValidationActivated(true);
                                }}
                            />
                            {isEmpty(merchantId) && validationActivated && (
                                <ValidationMessage
                                    message={intl.formatMessage(
                                        { id: 'Settings.EnterAValidField' },
                                        { field: intl.formatMessage({ id: 'Settings.MerchantID' }) }
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={() => {
                        setValidationActivated(false);
                        setIsModalOpen(false);
                    }}
                >
                    {intl.formatMessage({ id: 'Common.Back' })}
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        setValidationActivated(true);
                        addMID();
                    }}
                    disabled={!merchantId && validationActivated}
                >
                    {intl.formatMessage({ id: 'Common.Submit' })}
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

AddMIDModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    selectedMerchantType: PropTypes.number,
    setSelectedMerchantType: PropTypes.func,
};

export default AddMIDModal;
