import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowLeft } from '../../content/icons/arrow-left.svg';
import { Button } from 'reactstrap';

function Overlay(props) {
    function getOverlayClassName() {
        const classes = ['overlay'];
        if (props.isMobileView) {
            classes.push('mobile');
        }
        if (!props.overlayOpen) {
            classes.push('hide');
        }
        return classes.join(' ');
    }

    return (
        <div data-testid="overlay" className={getOverlayClassName()}>
            {!props.isFilterOverlay && (
                <div className="overlay-header d-flex">
                    {props.showCloseButton && (
                        <Button
                            data-testid="overlay-toggle-button"
                            className="toggle-button"
                            onClick={() => props.overlayBackButtonAction()}
                        >
                            {<ArrowLeft />}
                        </Button>
                    )}
                    <div className={props.showCloseButton ? 'overlay-title' : 'overlay-title no-toggle-button'}>
                        {props.overlayTitle}
                    </div>
                    {props.headerChildren}
                </div>
            )}
            <div className={props.isFilterOverlay ? 'overlay-content filter-overlay' : 'overlay-content'}>
                {props.overlayContent}
            </div>
        </div>
    );
}

Overlay.propTypes = {
    overlayOpen: PropTypes.bool.isRequired,
    overlayBackButtonAction: PropTypes.func.isRequired,
    overlayTitle: PropTypes.string.isRequired,
    overlayContent: PropTypes.node,
    headerChildren: PropTypes.node,
    showCloseButton: PropTypes.bool,
    isMobileView: PropTypes.bool,
};

export default Overlay;
