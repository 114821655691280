import React, { useState, createContext } from 'react';
import localStorageItem from '../enums/localStorageItem';
import AnalyticsHelper from '../helpers/AnalyticsHelper';
import AccountService from '../services/AccountService';
import axios from '../Interceptor';
import { useNavigate } from 'react-router-dom';
import SessionHelper from '../helpers/SessionHelper';

const authContext = createContext();

export function useAuth(resetAppState) {
    const [authed, setAuthed] = useState(false);
    const navigate = useNavigate();
    return {
        authed,
        login(tokenInfo) {
            return new Promise((res) => {
                localStorage.setItem(localStorageItem.tokenInfo, JSON.stringify(tokenInfo));
                axios.defaults.headers.common['Authorization'] = `Bearer ${tokenInfo.token}`;
                setAuthed(true);
                res();
            });
        },
        logout() {
            return new Promise((res) => {
                AnalyticsHelper.resetUser();
                const tokenInfo = SessionHelper.getTokenInfo();
                if (tokenInfo) {
                    AccountService.deleteAPIToken(tokenInfo.token);
                    localStorage.removeItem(localStorageItem.tokenInfo);
                    localStorage.removeItem(localStorageItem.providerId);
                    localStorage.removeItem(localStorageItem.deploymentId);
                }
                setAuthed(false);
                resetAppState();
                res();
            });
        },
        returnToProviderSelection() {
            return new Promise((res) => {
                localStorage.removeItem(localStorageItem.providerId);
                localStorage.removeItem(localStorageItem.deploymentId);
                navigate('/');
                res();
            });
        },
    };
}

export function AuthProvider({ children }) {
    const auth = useAuth();

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer({ children }) {
    return (
        <authContext.Consumer>
            {(context) => {
                return children(context);
            }}
        </authContext.Consumer>
    );
}
