import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class BookingService {
    static getBookingsForDate(deploymentId, restaurantId, date) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/BookingsV2/${date}`
        ).then((response) => response.data);
    }

    static getBookingReasons(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/BookingReasons`
        ).then((response) => response.data);
    }

    static getBooking(deploymentId, restaurantId, bookingId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Booking/${bookingId}`
        ).then((response) => response.data);
    }

    static createBooking(deploymentId, restaurantId, bookingDto) {
        return ApiHelper.postJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/BookingV2`,
            JSON.stringify(bookingDto)
        ).then((response) => response.data);
    }

    static setArrivalStatus(deploymentId, restaurantId, bookingId, arrivalStatus) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Booking/${bookingId}/ArrivalStatus?newArrivalStatus=${arrivalStatus}`
        ).then((response) => response.data.UpdatedArrivalStatus);
    }

    static closeBooking(deploymentId, restaurantId, bookingId) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Booking/${bookingId}/MealStatus?newStatus=Closed`
        ).then((response) => response.data.UpdatedMealStatus);
    }

    static reconfirmBooking(deploymentId, restaurantId, bookingId, isReConfirmed) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Booking/${bookingId}/SetIsReConfirmed?isReConfirmed=${isReConfirmed}`
        ).then((response) => response.data);
    }

    static cancelBooking(deploymentId, restaurantId, bookingId) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Booking/${bookingId}/Cancel`
        ).then((response) => response.data);
    }

    static updateBooking(deploymentId, restaurantId, booking) {
        return ApiHelper.putJSON(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/BookingEx`,
            { Booking: booking }
        ).then((response) => response.data);
    }

    static sendMenuNotification(deploymentId, restaurantId, bookingId, menuId) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/PreOrder/${bookingId}/SendNotification/${menuId}`
        ).then((response) => response.data);
    }

    static getAllMenus(deploymentId, restaurantId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/Menu/GetAll`
        ).then((response) => response.data);
    }

    static getBookingPreorder(deploymentId, restaurantId, bookingId) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${deploymentId}/${restaurantId}/PreOrder/${bookingId}`
        ).then((response) => response.data);
    }
}

export default BookingService;
