import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ValidationSummary from './ValidationSummary';

function CloseablePanel(props) {
    function getSaveButtonText() {
        if (props.isSaving) {
            return <FormattedMessage id="Common.Saving" />;
        }

        if (props.saveButtonText) {
            return props.saveButtonText;
        }

        return <FormattedMessage id="Common.Save" />;
    }

    return (
        <div className={props.isEditing ? 'grey-closable-panel editing' : 'grey-closable-panel'}>
            <Button
                className="close"
                onClick={() => (props.isEditing ? props.toggleEditing() : props.toggleAddNewItem())}
            >
                <span aria-hidden="true">&times;</span>
            </Button>
            <h6 className="heading text-uppercase bold">
                {props.isEditing ? props.editItemText : props.addNewItemText}
            </h6>
            {props.formBody}
            <FormGroup className="grey-closable-panel-footer">
                {props.footerAdditionalContent}
                <div className="button-wrapper">
                    <Button
                        color="primary"
                        type="submit"
                        onClick={() => props.submit(() => props.saveItem())}
                        disabled={props.buttonDisabled}
                    >
                        {getSaveButtonText()}
                    </Button>
                    <ValidationSummary errorCount={props.errorCount} submitDisabled={props.submitDisabled} />
                </div>
            </FormGroup>
        </div>
    );
}

CloseablePanel.propTypes = {
    formBody: PropTypes.object,
    isEditing: PropTypes.bool,
    saveItem: PropTypes.func,
    toggleEditing: PropTypes.func,
    toggleAddNewItem: PropTypes.func,
    addNewItemText: PropTypes.string,
    editItemText: PropTypes.string,
    buttonDisabled: PropTypes.bool,
    submit: PropTypes.func,
    submitDisabled: PropTypes.bool,
    errorCount: PropTypes.number,
    isSaving: PropTypes.bool,
    footerAdditionalContent: PropTypes.node,
    saveButtonText: PropTypes.string,
};

export default CloseablePanel;
