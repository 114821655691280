import React from 'react';
import Dropdown from './Dropdown';
import PropTypes from 'prop-types';
import bookingFilterType from '../../enums/bookingFilterType';
import { useIntl } from 'react-intl';

function BookingFilterEntry(props) {
    const intl = useIntl();

    function getSelectedFilterName() {
        switch (props.selectedFilter) {
            case bookingFilterType.allBookings:
                return intl.formatMessage({
                    id: 'Bookings.AllBookings',
                });
            case bookingFilterType.activeBookings:
                return intl.formatMessage({
                    id: 'Bookings.ActiveBookings',
                });
            case bookingFilterType.cancelledBookings:
                return intl.formatMessage({
                    id: 'Bookings.CancelledBookings',
                });
            default:
                return intl.formatMessage({
                    id: 'Bookings.AllBookings',
                });
        }
    }

    return (
        <div className="active-panel">
            <Dropdown
                title={intl.formatMessage({
                    id: 'Common.Show',
                })}
                onValueChange={props.onSelectedBookingFilterChange}
                defaultValue={getSelectedFilterName()}
                options={[
                    {
                        text: intl.formatMessage({
                            id: 'Bookings.AllBookings',
                        }),
                        value: bookingFilterType.allBookings,
                    },
                    {
                        text: intl.formatMessage({
                            id: 'Bookings.ActiveBookings',
                        }),
                        value: bookingFilterType.activeBookings,
                    },
                    {
                        text: intl.formatMessage({
                            id: 'Bookings.CancelledBookings',
                        }),
                        value: bookingFilterType.cancelledBookings,
                    },
                ]}
                selectedValue={props.selectedFilter}
                hideRemoveItemIcon={true}
            />
        </div>
    );
}

BookingFilterEntry.propTypes = {
    onSelectedBookingFilterChange: PropTypes.func.isRequired,
    selectedFilter: PropTypes.number,
};

export default BookingFilterEntry;
