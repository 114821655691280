import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Collapse } from 'reactstrap';
import ListingStrength from '../common/ListingStrength';
import SuccessIconWrapper from '../common/SuccessIconWrapper';
import { ReactComponent as UpArrowIcon } from '../../content/icons/up-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../content/icons/down-arrow.svg';
import ProviderProgressFlagsContext from '../../contexts/ProviderProgressFlagsContext';
import useScreenSize from '../../hooks/useScreenSize';
import useOnboardingProgress from '../../hooks/useOnboardingProgress';
import { useNavigate } from 'react-router-dom';

function ImproveListingsFooter(props) {
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const providerProgressFlagsContext = useContext(ProviderProgressFlagsContext);
    const { isMobileView } = useScreenSize();
    const onboardingProgress = useOnboardingProgress();
    const navigate = useNavigate();

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    function getArrowIcon() {
        return isOpen ? <UpArrowIcon /> : <DownArrowIcon />;
    }

    function getFooterClassNames() {
        const classes = ['footer-heading'];
        if (isOpen) {
            classes.push('mb-3');
        }
        if (isMobileView) {
            classes.push('mob');
        }
        return classes.join(' ');
    }

    function getFooterHeading() {
        if (isMobileView) {
            return (
                <h5>
                    <FormattedMessage id="AccountSubmission.IncreaseBookingRateBy200Percent" />
                </h5>
            );
        }
        return (
            <h4>
                <FormattedMessage id="AccountSubmission.IncreaseBookingRateHeaderText" />
            </h4>
        );
    }

    function linkToListingsPage(url) {
        navigate(url);
    }

    return (
        <div className="improve-listings-footer">
            <div className={getFooterClassNames()}>
                {getFooterHeading()}

                <Button color="primary" onClick={toggle} className={isMobileView ? 'toggle-button-mob' : ''}>
                    <div>
                        {intl.formatMessage({
                            id: 'AccountSubmission.FindOutMore',
                        })}
                        {getArrowIcon()}
                    </div>
                </Button>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="d-flex">
                    {!isMobileView && (
                        <div className="progress-container">
                            <ListingStrength percentage={onboardingProgress} includeHeading />
                        </div>
                    )}
                    <div className={isMobileView ? 'w-100' : 'd-flex flex-column ml-5'}>
                        {!isMobileView && (
                            <div className="mb-2 bold-text">
                                <FormattedMessage id="AccountSubmission.AchieveListingStrengthText" />
                            </div>
                        )}
                        <div className={isMobileView ? 'd-flex flex-column' : 'd-flex'}>
                            {!providerProgressFlagsContext.addedAdditionalImage && (
                                <div
                                    className={
                                        isMobileView ? 'listing-container footer mob' : 'listing-container footer'
                                    }
                                    onClick={() => linkToListingsPage('/Settings/Images')}
                                >
                                    <div className="d-flex">
                                        <SuccessIconWrapper isCompleted={false} notStarted />
                                        <div>
                                            <h6 className="listing-title">
                                                <FormattedMessage id="AccountSubmission.UploadRestaurantImagesTitle" />{' '}
                                            </h6>
                                            {!isMobileView && (
                                                <FormattedMessage id="AccountSubmission.UploadRestaurantImagesDescription" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!providerProgressFlagsContext.uploadedMenu && (
                                <div
                                    className={
                                        isMobileView ? 'listing-container footer mob' : 'listing-container footer'
                                    }
                                    onClick={() => linkToListingsPage('/Settings/Venue?listing=MyListingMenus')}
                                >
                                    <div className="d-flex">
                                        <SuccessIconWrapper isCompleted={false} notStarted />
                                        <div>
                                            <h6 className="listing-title">
                                                <FormattedMessage id="AccountSubmission.UploadMenuTitle" />{' '}
                                            </h6>
                                            {!isMobileView && (
                                                <FormattedMessage id="AccountSubmission.UploadMenuDescription" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!providerProgressFlagsContext.addedAdvancedListing && (
                                <div
                                    className={
                                        isMobileView ? 'listing-container footer mob' : 'listing-container footer'
                                    }
                                    onClick={() => linkToListingsPage('/Settings/Venue?listing=MyListing')}
                                >
                                    <div className="d-flex">
                                        <SuccessIconWrapper isCompleted={false} notStarted />
                                        <div>
                                            <h6 className="listing-title">
                                                <FormattedMessage id="AccountSubmission.AdvanceListingTitle" />{' '}
                                            </h6>
                                            {!isMobileView && (
                                                <FormattedMessage id="AccountSubmission.AdvanceListingDescription" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
}

export default ImproveListingsFooter;
