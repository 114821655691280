import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form } from 'reactstrap';
import Section from '../../common/Section';
import MenuListingsListBuilder from './menuListings/MenuListingsListBuilder';
import useScreenSize from '../../../hooks/useScreenSize';

function MyListingMenusTab(props) {
    const intl = useIntl();
    const displayClass = props.isActive ? '' : 'hidden';
    const { isMobileView } = useScreenSize();

    return (
        <div className={displayClass}>
            <Section sectionTitle={intl.formatMessage({ id: 'Settings.MyListingMenus' })}>
                <div className={isMobileView ? 'collapsible-panel-body mob' : 'collapsible-panel-body'}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <MenuListingsListBuilder
                            menus={props.providerMenus}
                            cultureCodes={props.cultureCodes}
                            menuTypes={props.menuTypes}
                            addSuccessBar={props.addSuccessBar}
                            addErrorBar={props.addErrorBar}
                        />
                    </Form>
                </div>
            </Section>
        </div>
    );
}

MyListingMenusTab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    providerMenus: PropTypes.array.isRequired,
    menuTypes: PropTypes.array.isRequired,
    cultureCodes: PropTypes.array.isRequired,
};

export default MyListingMenusTab;
