import React, { Fragment } from 'react';
import { FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import Option from '../../common/Option';
import { useIntl } from 'react-intl';
import ValidationInput from '../../common/ValidationInput';
import ValidationDropdown from '../../common/ValidationDropdown';

function AddressSection(props) {
    const intl = useIntl();

    function getCountryOptions() {
        return props.countryCodes ? props.countryCodes.map((c) => new Option(c.Name, c.Id)) : [];
    }

    return (
        <Fragment>
            <p className="form-group-title">{intl.formatMessage({ id: 'Settings.Address' })}</p>
            <FormGroup className="loyalty-form-group address">
                <FormGroup>
                    <Label id="addressLineOneLabel" for="addressLineOne">
                        {intl.formatMessage({ id: 'Settings.AddressLineOne' })}
                    </Label>
                    <ValidationInput
                        id="addressLineOne"
                        type="text"
                        name="addressLine1"
                        value={props.address.addressLine1}
                        onChange={(value) => props.setAddressInformation('addressLine1', value)}
                        placeholder={intl.formatMessage({ id: 'Settings.AddressLineOne' })}
                        innerRef={props.formValidation.register({
                            required: intl.formatMessage(
                                { id: 'Settings.LoyaltyFormIsRequired' },
                                { fieldName: intl.formatMessage({ id: 'Settings.Address' }) }
                            ),
                            validate: (value) => {
                                if (value && value.length > 100) {
                                    return intl.formatMessage({ id: 'Common.ValueTooLongError' }, { maxLength: '100' });
                                }
                                if (value && value.length < 2) {
                                    return intl.formatMessage({ id: 'Common.ValueTooShortError' }, { minLength: '2' });
                                }
                            },
                        })}
                        errors={props.formValidation.errors}
                        ariaLabelledBy="addressLineOneLabel"
                        ariaRequired
                    />
                </FormGroup>
                <FormGroup>
                    <Label id="cityLabel" for="city">
                        {intl.formatMessage({ id: 'Settings.City' })}
                    </Label>
                    <ValidationInput
                        id="city"
                        type="text"
                        name="city"
                        value={props.address.city}
                        placeholder={intl.formatMessage({ id: 'Settings.City' })}
                        onChange={(value) => props.setAddressInformation('city', value)}
                        innerRef={props.formValidation.register({
                            required: intl.formatMessage(
                                { id: 'Settings.LoyaltyFormIsRequired' },
                                { fieldName: intl.formatMessage({ id: 'Settings.City' }) }
                            ),
                            validate: (value) => {
                                if (value && value.length > 100) {
                                    return intl.formatMessage({ id: 'Common.ValueTooLongError' }, { maxLength: '100' });
                                }
                                if (value && value.length < 2) {
                                    return intl.formatMessage({ id: 'Common.ValueTooShortError' }, { minLength: '2' });
                                }
                            },
                        })}
                        errors={props.formValidation.errors}
                        ariaLabelledBy="cityLabel"
                        ariaRequired
                    />
                </FormGroup>
                <FormGroup>
                    <Label id="postcodeLabel" for="postCode">
                        {intl.formatMessage({ id: 'Settings.Postcode' })}
                    </Label>
                    <ValidationInput
                        id="postCode"
                        type="text"
                        name="postcode"
                        value={props.address.postcode}
                        placeholder={intl.formatMessage({ id: 'Settings.Postcode' })}
                        onChange={(value) => props.setAddressInformation('postcode', value)}
                        innerRef={props.formValidation.register({
                            required: intl.formatMessage(
                                { id: 'Settings.LoyaltyFormIsRequired' },
                                { fieldName: intl.formatMessage({ id: 'Settings.Postcode' }) }
                            ),
                            validate: (value) => {
                                if (value && value.length > 20) {
                                    return intl.formatMessage({ id: 'Common.ValueTooLongError' }, { maxLength: '20' });
                                }
                                if (value && value.length < 2) {
                                    return intl.formatMessage({ id: 'Common.ValueTooShortError' }, { minLength: '2' });
                                }
                            },
                        })}
                        errors={props.formValidation.errors}
                        ariaLabelledBy="postcodeLabel"
                        ariaRequired
                    />
                </FormGroup>
                <FormGroup>
                    <ValidationDropdown
                        title={intl.formatMessage({ id: 'Settings.Country' })}
                        onChange={(countryId) => {
                            props.setAddressInformation(
                                'country',
                                props.countryCodes.find((c) => c.Id === countryId).Name
                            );
                        }}
                        selectedValue={
                            props.countryCodes.find((c) => c.Name === props.address.country) != null
                                ? props.countryCodes.find((c) => c.Name === props.address.country).Id
                                : 0
                        }
                        options={getCountryOptions()}
                        formValidation={props.formValidation}
                        name="country"
                        validationRules={{
                            required: intl.formatMessage(
                                { id: 'Settings.LoyaltyFormIsRequired' },
                                { fieldName: intl.formatMessage({ id: 'Settings.Country' }) }
                            ),
                        }}
                        hideRemoveItemIcon
                        defaultValue={intl.formatMessage({ id: 'Settings.SelectOne' })}
                        isSearchable
                        ariaRequired
                    />
                </FormGroup>
            </FormGroup>
        </Fragment>
    );
}

AddressSection.propTypes = {
    address: PropTypes.object.isRequired,
    setAddressInformation: PropTypes.func.isRequired,
    countryCodes: PropTypes.array,
    formValidation: PropTypes.object.isRequired,
};

export default AddressSection;
