import React from 'react';
import PropTypes from 'prop-types';
import CardProviderStatus from '../../../enums/cardProviderStatus';
import { useIntl } from 'react-intl';

const MerchantStatus = ({ MerchantIcon, merchantStatus, merchant, setSelectedMerchantType, setIsAddMIDModalOpen }) => {
    const intl = useIntl();
    const renderSubscriptionStatus = (status) => {
        switch (status) {
            case CardProviderStatus.Idle:
                return `${intl.formatMessage({ id: 'Settings.DcrSubscriptionStatusIdle' })}`;
            case CardProviderStatus.Sync:
                return `${intl.formatMessage({ id: 'Settings.DcrSubscriptionStatusSync' })}`;
            case CardProviderStatus.Active:
                return `${intl.formatMessage({ id: 'Settings.DcrSubscriptionStatusActive' })}`;
            case CardProviderStatus.Inactive:
                return `${intl.formatMessage({ id: 'Settings.DcrSubscriptionStatusInactive' })}`;
            case CardProviderStatus.NotFound:
                return `${intl.formatMessage({ id: 'Settings.DcrSubscriptionStatusNotFound' })}`;
            case CardProviderStatus.Failed:
                return `${intl.formatMessage({ id: 'Settings.DcrSubscriptionStatusFailed' })}`;
            default:
                return `${intl.formatMessage({ id: 'Settings.DcrSubscriptionStatusNotFound' })}`;
        }
    };

    const getMerchantStatusStyles = (status) => {
        let statusButtonClass = 'merchant-status';
        switch (status) {
            case CardProviderStatus.Idle:
            case CardProviderStatus.NotFound:
            case CardProviderStatus.Inactive:
            default:
                return statusButtonClass + ' idle';
            case CardProviderStatus.Sync:
                return statusButtonClass + ' sync';
            case CardProviderStatus.Active:
                return statusButtonClass + ' active';
            case CardProviderStatus.Failed:
                return statusButtonClass + ' failed';
        }
    };
    return (
        <div className="flex-fill merchant-status-container">
            {MerchantIcon}
            <div className={getMerchantStatusStyles(merchantStatus)}>{renderSubscriptionStatus(merchantStatus)}</div>
            {(merchantStatus === CardProviderStatus.Active || merchantStatus === CardProviderStatus.NotFound) && (
                <div
                    className="add-mid"
                    onClick={() => {
                        setSelectedMerchantType(merchant);
                        setIsAddMIDModalOpen(true);
                    }}
                >
                    {intl.formatMessage({ id: 'Settings.AddMid' })}
                </div>
            )}
        </div>
    );
};

MerchantStatus.propTypes = {
    MerchantIcon: PropTypes.object.isRequired,
    merchantStatus: PropTypes.string.isRequired,
    merchant: PropTypes.number.isRequired,
    setSelectedMerchantType: PropTypes.func.isRequired,
    setIsAddMIDModalOpen: PropTypes.func.isRequired,
};

export default MerchantStatus;
