import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ResIcon } from '../../content/images/rd-lite-logo.svg';
import useScreenSize from '../../hooks/useScreenSize';

function ProgressPage(props) {
    const { isMobileView } = useScreenSize();
    return (
        <Fragment>
            <div className="text-center pt-2">
                <ResIcon className="brand-svg" />
                <h2 className={isMobileView ? 'page-header get-started-title' : 'page-header'}>{props.pageTitle}</h2>
                {props.progressBar}
            </div>
            {props.children}
        </Fragment>
    );
}

ProgressPage.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    progressBar: PropTypes.element.isRequired,
    children: PropTypes.node.isRequired,
};

export default ProgressPage;
