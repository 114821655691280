import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Label, FormGroup, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

function EditCategorySection(props) {
    function getButtonText() {
        if (props.editingSection) {
            return <FormattedMessage id="Common.Update" />;
        }
        return <FormattedMessage id="Common.Add" />;
    }

    return (
        <div
            className={
                props.editingSection ? 'grey-closable-panel menu-section editing' : 'grey-closable-panel menu-section'
            }
        >
            <Button
                className="close"
                onClick={() => {
                    props.toggleIsOpen();
                }}
            >
                <span aria-hidden="true">&times;</span>
            </Button>
            <h6 className="heading text-uppercase bold">
                {props.editingSection ? (
                    <FormattedMessage id="Settings.EditCategory" />
                ) : (
                    <FormattedMessage id="Settings.AddCategory" />
                )}
            </h6>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (props.title) {
                        props.saveCategory();
                    }
                }}
            >
                <FormGroup className="panel-form-group">
                    <Label for="categoryTitle">
                        <FormattedMessage id="Common.Title" />
                    </Label>
                    <Input
                        name="categoryTitle"
                        type="text"
                        value={props.title}
                        onChange={(event) => {
                            props.setTitle(event.target.value);
                        }}
                        autoFocus
                    />
                    <div className="pt-1">
                        <FormattedMessage id="Settings.CategoryExampleText" />
                    </div>
                </FormGroup>

                <FormGroup className="grey-closable-panel-footer">
                    <div className="button-wrapper">
                        <Button
                            color="primary"
                            onClick={() => {
                                props.saveCategory();
                            }}
                            disabled={!props.title}
                        >
                            {getButtonText()}
                        </Button>
                    </div>
                </FormGroup>
            </Form>
        </div>
    );
}

EditCategorySection.propTypes = {
    id: PropTypes.number,
    toggleIsOpen: PropTypes.func,
    saveCategory: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    setTitle: PropTypes.func.isRequired,
};

export default EditCategorySection;
