import React, { useState, useContext, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Label, Form, Input, FormGroup, CustomInput, Button } from 'reactstrap';
import ValidationInput from '../common/ValidationInput';
import ValidationSummary from '../common/ValidationSummary';
import ButtonToolbarInput from '../common/ButtonToolbarInput';
import ValidationWrapper from '../common/ValidationWrapper';
import DiaryContext from '../../contexts/DiaryContext';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import useScreenSize from '../../hooks/useScreenSize';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import useTrackPage from '../../hooks/useTrackPage';
import VoucherPreviewCard from './VoucherPreviewCard';

function VoucherCreationPanel(props) {
    const intl = useIntl();
    const { setVoucherBeingUpdated } = props;
    const { formValidation } = props;
    const { reset } = formValidation;
    const [voucherMaxQuantity, setVoucherMaxQuantity] = useState(
        props.voucher.maximumQuantity !== 1000 ? props.voucher.maximumQuantity : 1
    );

    const [maxPurchaseNoLimitSelected, setMaxPurchaseNoLimitSelected] = useState(
        !props.isEditing || props.voucher.maximumQuantity === 1000
    );

    const [minValidityNoLimitSelected, setMinValidityNoLimitSelected] = useState(
        !props.isEditing || props.voucher.monthsValid === 0
    );
    const defaultMaxQuantity = 1000;

    const diaryContext = useContext(DiaryContext);
    const { isMobileView } = useScreenSize();

    useTrackPage(getTrackingPageTitle());

    const onMaxQuantityChange = useCallback(
        (value) => {
            setVoucherBeingUpdated((state) => ({ ...state, maximumQuantity: value }));
        },
        [setVoucherBeingUpdated]
    );

    useEffect(() => {
        if (maxPurchaseNoLimitSelected) {
            onMaxQuantityChange(defaultMaxQuantity);
        } else {
            onMaxQuantityChange(voucherMaxQuantity);
        }
    }, [maxPurchaseNoLimitSelected, voucherMaxQuantity, onMaxQuantityChange]);

    function getTrackingPageTitle() {
        if (props.isInitialSetup) {
            return 'VouchersOnboard Creation';
        }
        if (props.isEditing) {
            return 'Web Vouchers - Edit';
        }
        return 'Web Vouchers - Create';
    }

    function onPriceChange(e) {
        let value = null;
        let currencyExpression = new RegExp(
            /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)|^(?!.\.\.)[0-9]*(\.[0-9]{0,2})?$/
        );
        if (e !== 'undefined' && e !== null && isNaN(value) === false) {
            let regExResult = currencyExpression.test(e);
            if (regExResult) {
                value = e;
            }
            if (value == null) {
                value = '';
            } else {
                switch (value.includes('.')) {
                    case true:
                        props.onValueChange('cost', parseFloat(value).toFixed(2));
                        break;
                    default:
                        props.onValueChange('cost', parseInt(value));
                        break;
                }
            }
        }
    }

    function priceButtonBar() {
        if (!props.isInitialSetup && props.isEditing) {
            return <div className="voucher-cost-tag">{props.voucher.cost}</div>;
        }

        return (
            <ButtonToolbarInput
                options={[25, 50, 100, 150, 200]}
                onChange={onPriceChange}
                selectedValue={props.voucher.cost}
                validation={formValidation.register({
                    required: intl.formatMessage({ id: 'Vouchers.VoucherPriceValidationRequired' }),
                    min: {
                        value: 1,
                        message: intl.formatMessage({ id: 'Vouchers.VoucherPriceValidationRequired' }),
                    },
                })}
                hasInput
                checkboxLabel={
                    intl.formatMessage({ id: 'Settings.CustomPrice' }) +
                    ` (${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)})`
                }
                errors={formValidation.errors}
                name="cost"
                isMobileView={isMobileView}
            />
        );
    }

    function getAnalyticsProperties() {
        return {
            voucherName: props.voucher.title,
            value: props.voucher.cost,
            description: props.voucher.description,
            validity: props.voucher.monthsValid,
            MaxPurchase: props.voucher.maximumQuantity,
        };
    }

    function trackVoucherOnboardingCreation() {
        if (props.isInitialSetup) {
            AnalyticsHelper.trackClickWithProperties('Web VouchersOnboard Creation Continue', getAnalyticsProperties());
        }
    }

    function getOnboardingHeaderTitle() {
        return props.isEditing ? (
            <FormattedMessage id="Vouchers.EditYourVoucher" />
        ) : (
            <FormattedMessage id="Vouchers.CreateYourVoucher" />
        );
    }

    function checkValidValidity() {
        if (minValidityNoLimitSelected) {
            return;
        }
        const validity = props.voucher.monthsValid;
        if (validity === null) {
            return intl.formatMessage({ id: 'Vouchers.VoucherValidityValidationRequired' });
        }

        if (validity === 0 || (props.minValidityPeriod && validity < props.minValidityPeriod)) {
            return intl.formatMessage(
                { id: 'Vouchers.VoucherValidityMustBeGreaterThanMinimumValidity' },
                { minimumValidity: props.minValidityPeriod - 1 }
            );
        }
    }

    function getFormClass() {
        let className = 'vouchers-container';

        if (isMobileView && props.isInitialSetup) className += ' mob';

        if (!props.isInitialSetup) {
            className += ' row';
        }

        return className;
    }

    function onMinValidityChanged(e) {
        let value = null;
        if (e !== null && e !== 'undefined' && e !== '') {
            value = parseInt(e);
        }
        reset({ monthsValid: e });
        props.onValueChange('monthsValid', value);
    }

    function deselectValidityNoExpiry() {
        if (minValidityNoLimitSelected) {
            setMinValidityNoLimitSelected(false);
            onMinValidityChanged(props.minValidityPeriod ? props.minValidityPeriod : 1);
        }
    }

    function deselectMaxPurchaseNoLimit() {
        reset({ maximumQuantity: voucherMaxQuantity });
        setMaxPurchaseNoLimitSelected(false);
    }

    return (
        <Form
            className={getFormClass()}
            onSubmit={(e) => {
                e.preventDefault();
                trackVoucherOnboardingCreation();
                formValidation.submit(props.saveVoucher);
            }}
        >
            <FormGroup
                className={props.isInitialSetup && !isMobileView ? 'wizard-form-content' : 'form-content col-lg mr-5'}
            >
                {props.isInitialSetup && <h6 className="text-uppercase">{getOnboardingHeaderTitle()}</h6>}
                <Label>
                    <FormattedMessage id="Settings.Price" />
                    {` (${CurrencyHelper.getCurrencySignWithSymbol(diaryContext.currencySymbol)})`}
                </Label>

                {priceButtonBar()}

                <Label>
                    <FormattedMessage id="Vouchers.VoucherTitle" />
                </Label>
                <ValidationInput
                    type="text"
                    name="title"
                    value={props.voucher.title}
                    onChange={(e) => props.onValueChange('title', e)}
                    innerRef={formValidation.register({
                        required: intl.formatMessage({ id: 'Vouchers.VoucherTitleRequired' }),
                    })}
                    errors={formValidation.errors}
                    maxLength="75"
                />
                <ValidationWrapper errors={formValidation.errors} errorNames={['monthsValid', 'maximumQuantity']}>
                    <div className={isMobileView ? '' : 'd-flex'}>
                        <div>
                            <Label>
                                <FormattedMessage id="Vouchers.VoucherValidity" />
                            </Label>

                            <div className="d-flex">
                                <CustomInput
                                    checked={minValidityNoLimitSelected}
                                    onChange={() => {
                                        setMinValidityNoLimitSelected(true);
                                        onMinValidityChanged(0);
                                    }}
                                    type="radio"
                                    id={'voucher-no-min-validity'}
                                    name={'customRadioValidityInput'}
                                    label={intl.formatMessage({ id: 'Vouchers.NoExpiry' })}
                                />
                                <CustomInput
                                    checked={!minValidityNoLimitSelected}
                                    onChange={deselectValidityNoExpiry}
                                    type="radio"
                                    id={'voucher-custom-min-validity'}
                                    name={'customRadioValidityInput'}
                                />
                                <div className={isMobileView ? 'd-flex' : 'd-flex mr-5'}>
                                    <div
                                        className="max-per-purchase-validation-input"
                                        onClick={deselectValidityNoExpiry}
                                    >
                                        <ValidationInput
                                            type="number"
                                            name="monthsValid"
                                            value={
                                                props.voucher.monthsValid
                                                    ? props.voucher.monthsValid
                                                    : props.minValidityPeriod
                                            }
                                            onChange={(e) => onMinValidityChanged(e)}
                                            innerRef={formValidation.register({
                                                validate: (value) => checkValidValidity(value),
                                            })}
                                            errors={formValidation.errors}
                                            className="max-purchase-input"
                                            disabled={props.minValidityPeriod === null || minValidityNoLimitSelected}
                                            hideValidationMessage
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <Label>
                                <FormattedMessage id="Settings.MaxPurchase" />
                            </Label>

                            <div className="d-flex">
                                <CustomInput
                                    checked={maxPurchaseNoLimitSelected}
                                    onChange={() => {
                                        reset({ maximumQuantity: voucherMaxQuantity });
                                        setMaxPurchaseNoLimitSelected(true);
                                    }}
                                    type="radio"
                                    id={'voucher-no-max-purchase'}
                                    name={'customRadioInput'}
                                    label={intl.formatMessage({ id: 'Settings.NoLimit' })}
                                />
                                <CustomInput
                                    checked={!maxPurchaseNoLimitSelected}
                                    onChange={deselectMaxPurchaseNoLimit}
                                    type="radio"
                                    id={'voucher-custom-max-purchase'}
                                    name={'customRadioInput'}
                                />
                                <div className="d-flex">
                                    <div
                                        className="max-per-purchase-validation-input"
                                        onClick={deselectMaxPurchaseNoLimit}
                                    >
                                        <ValidationInput
                                            type="number"
                                            name="maximumQuantity"
                                            value={voucherMaxQuantity}
                                            onChange={(e) => setVoucherMaxQuantity(parseInt(e))}
                                            innerRef={formValidation.register({
                                                required: intl.formatMessage({
                                                    id: 'Vouchers.VoucherMaxPurchaseValidationRequired',
                                                }),
                                                min: {
                                                    value: 1,
                                                    message: intl.formatMessage({
                                                        id: 'Vouchers.VoucherMaxPurchaseValidationRequired',
                                                    }),
                                                },
                                            })}
                                            errors={formValidation.errors}
                                            className="max-purchase-input"
                                            disabled={maxPurchaseNoLimitSelected}
                                            hideValidationMessage
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ValidationWrapper>
                <Label>
                    <FormattedMessage id="Settings.DescriptionOptional" />
                </Label>
                <Input
                    type="textarea"
                    name="description"
                    value={props.voucher.description}
                    onChange={(e) => props.onValueChange('description', e.target.value)}
                    maxLength="255"
                    className={isMobileView ? 'textarea-sm' : 'textarea-lg'}
                />
                <Label>
                    <FormattedMessage id="Settings.TermsAndConditions" />
                </Label>
                <ValidationInput
                    type="textarea"
                    name="termsAndConditions"
                    value={props.voucher.termsAndConditions}
                    onChange={(e) => props.onValueChange('termsAndConditions', e)}
                    errors={formValidation.errors}
                    maxLength="800"
                    className={isMobileView ? 'textarea-sm' : 'textarea-lg'}
                    displayCharacterCount
                />
            </FormGroup>
            {!props.isInitialSetup && !isMobileView && (
                <div className="voucher-preview col">
                    <Label>
                        <FormattedMessage id="Vouchers.VoucherPreview" />
                    </Label>
                    <VoucherPreviewCard key={props.voucher.uniqueIdentifier} voucher={props.voucher} />
                </div>
            )}
            {props.isInitialSetup && (
                <div className="vouchers-footer-panel">
                    <div className={isMobileView ? 'panel-footer button-wrapper mob' : 'panel-footer button-wrapper'}>
                        <Button className="btn-outline-primary" onClick={() => props.toggleAddNewVoucher()}>
                            <FormattedMessage id="Common.Cancel" />
                        </Button>
                        <input
                            disabled={props.isSaving || formValidation.submitDisabled || !props.hasVoucherUpdated()}
                            type="submit"
                            value={
                                props.isSaving
                                    ? intl.formatMessage({
                                          id: 'Common.Saving',
                                      })
                                    : intl.formatMessage({
                                          id: 'Common.SaveAndContinue',
                                      })
                            }
                            className="btn btn-primary"
                        />
                    </div>
                    <ValidationSummary
                        errorCount={formValidation.errorCount}
                        submitDisabled={formValidation.submitDisabled}
                    />
                </div>
            )}
        </Form>
    );
}

VoucherCreationPanel.propTypes = {
    voucher: PropTypes.object.isRequired,
    saveVoucher: PropTypes.func,
    formValidation: PropTypes.object.isRequired,
    onValueChange: PropTypes.func.isRequired,
    isInitialSetup: PropTypes.bool,
    isEditing: PropTypes.bool,
    isSaving: PropTypes.bool,
    minValidityPeriod: PropTypes.number,
    hasVoucherUpdated: PropTypes.func,
};

export default VoucherCreationPanel;
