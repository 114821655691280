import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import 'resdiary-datepicker/initialize';
import 'resdiary-datepicker/lib/css/_datepicker.css';
import { isInclusivelyBeforeDay, isInclusivelyAfterDay, SingleDatePicker } from 'resdiary-datepicker';
import MomentHelper from '../../helpers/MomentHelper';
import moment from 'moment-timezone';
import Dropdown from './Dropdown';
import Option from './Option';
import { Button } from 'reactstrap';
import { ReactComponent as ChevronLeft } from '../../content/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../content/icons/chevron-right.svg';
import { FormattedMessage } from 'react-intl';

class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: props.autoFocus,
        };
        this.onFocusChange = this.onFocusChange.bind(this);
    }

    onFocusChange({ focused }) {
        this.setState({ focused });
    }

    getDateRestrictions(date) {
        if (this.props.allowTodayAndPastDatesOnly) {
            return !isInclusivelyBeforeDay(date, MomentHelper.newInstance());
        }

        if (this.props.allowTodayAndFutureDatesOnly) {
            return !isInclusivelyAfterDay(date, MomentHelper.newInstance());
        }
        return null;
    }

    returnMonths() {
        return moment.monthsShort().map((label, value) => {
            return new Option(label, value);
        });
    }

    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
        <div className="month-and-year-selector">
            <div className="month-selector">
                <Dropdown
                    options={this.returnMonths()}
                    onValueChange={(selectedMonth) => onMonthSelect(month, selectedMonth)}
                    selectedValue={month.month()}
                    isLoading={this.props.isLoading}
                    skeletonWidth={130}
                    flip={false}
                    hideRemoveItemIcon
                />
            </div>
            <div className="year-selector">
                <Dropdown
                    options={this.returnYears()}
                    onValueChange={(selectedYear) => onYearSelect(month, selectedYear)}
                    selectedValue={month.year()}
                    isLoading={this.props.isLoading}
                    skeletonWidth={130}
                    defaultValue={month.year().toString()}
                    flip={false}
                    hideRemoveItemIcon
                />
            </div>
        </div>
    );

    returnYears = () => {
        const defaultStartYearModifier = this.props.dropdownStartYearModifier
            ? this.props.dropdownStartYearModifier
            : 10;
        const defaultEndYearModifier = this.props.dropdownEndYearModifier ? this.props.dropdownEndYearModifier : 10;
        const startYear = this.props.allowTodayAndFutureDatesOnly
            ? moment().year()
            : moment().year() - defaultStartYearModifier;
        const endYear = this.props.allowTodayAndPastDatesOnly
            ? moment().year()
            : moment().year() + defaultEndYearModifier;
        const years = [];
        for (let i = startYear; i <= endYear; i++) {
            years.push(new Option(i, i));
        }
        const currentSelectedYear = this.props.date.year();
        if (!years.some((e) => parseInt(e.value) === currentSelectedYear)) {
            const option = new Option(currentSelectedYear, currentSelectedYear);
            parseInt(years[0].value) > currentSelectedYear ? years.unshift(option) : years.push(option);
        }
        return years;
    };

    onDateChange(date) {
        this.setState({
            date: date,
        });
    }

    getContent() {
        if (this.props.excludeArrows) {
            return (
                <SingleDatePicker
                    id={this.props.id}
                    date={this.props.date}
                    focused={this.state.focused}
                    disabled={this.props.disabled}
                    onDateChange={(date) => {
                        this.props.onDateChange(date);
                    }}
                    onFocusChange={this.onFocusChange}
                    numberOfMonths={1}
                    firstDayOfWeek={MomentHelper.getFirstDayOfWeekForLocale()}
                    isOutsideRange={(date) => this.getDateRestrictions(date)}
                    displayFormat={
                        this.props.hideDayFromDate
                            ? MomentHelper.getDateFormatWithoutDay()
                            : MomentHelper.getDateFormat()
                    }
                    small
                    hideKeyboardShortcutsPanel
                    openDirection={this.props.openDirection}
                    renderMonthElement={this.renderMonthElement}
                    readOnly={true}
                    withPortal={this.props.isMobile}
                />
            );
        } else {
            return (
                <Fragment>
                    <Button
                        disabled={this.props.disabled}
                        className="btn-light decrement-day"
                        onClick={() => this.props.decrementDate()}
                    >
                        <div>{<ChevronLeft />}</div>
                    </Button>
                    <SingleDatePicker
                        id={this.props.id}
                        date={this.props.date}
                        focused={this.state.focused}
                        disabled={this.props.disabled}
                        onDateChange={(date) => {
                            this.props.onDateChange(date);
                        }}
                        onFocusChange={this.onFocusChange}
                        numberOfMonths={1}
                        firstDayOfWeek={MomentHelper.getFirstDayOfWeekForLocale()}
                        isOutsideRange={(date) => this.getDateRestrictions(date)}
                        displayFormat={
                            this.props.hideDayFromDate
                                ? MomentHelper.getDateFormatWithoutDay()
                                : MomentHelper.getDateFormat()
                        }
                        small
                        hideKeyboardShortcutsPanel
                        openDirection={this.props.openDirection}
                        renderMonthElement={this.renderMonthElement}
                        readOnly={true}
                        withPortal={this.props.isMobile}
                    />
                    <Button
                        disabled={this.props.disabled}
                        className="btn-light increment-day"
                        onClick={() => this.props.incrementDate()}
                    >
                        <div>{<ChevronRight />}</div>
                    </Button>
                    {this.props.goToToday && (
                        <Button
                            disabled={this.props.disabled}
                            className="btn-outline-primary now-btn"
                            onClick={() => this.props.goToToday()}
                        >
                            <FormattedMessage id="Common.Now" />
                        </Button>
                    )}
                </Fragment>
            );
        }
    }

    render() {
        return <Fragment>{this.getContent()}</Fragment>;
    }
}

DatePicker.propTypes = {
    autoFocus: PropTypes.bool,
    date: PropTypes.object,
    onDateChange: PropTypes.func.isRequired,
    decrementDate: PropTypes.func.isRequired,
    incrementDate: PropTypes.func.isRequired,
    goToToday: PropTypes.func,
    allowTodayAndPastDatesOnly: PropTypes.bool,
    allowTodayAndFutureDatesOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    skeletonWidth: PropTypes.number,
    id: PropTypes.string,
    hideDayFromDate: PropTypes.bool,
    openDirection: PropTypes.string,
    dropdownStartYearModifier: PropTypes.number,
    dropdownEndYearModifier: PropTypes.number,
    validation: PropTypes.object,
    validationRuleField: PropTypes.string,
    excludeArrows: PropTypes.bool,
    isMobile: PropTypes.bool,
};

export default DatePicker;
