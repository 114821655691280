import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormGroup, Form, Input } from 'reactstrap';
import Section from '../../common/Section';
import SettingsService from '../../../services/SettingsService';
import DiaryContext from '../../../contexts/DiaryContext';
import ValidationMessage from '../../common/ValidationMessage';

function CustomerDataRetentionTab(props) {
    const intl = useIntl();
    const [isSaving, setIsSaving] = useState(false);
    const [retentionPeriodMonths, setRetentionPeriodMonths] = useState('');
    const displayClass = props.isActive ? '' : 'hidden';
    const diaryContext = useContext(DiaryContext);
    const addErrorBar = props.addErrorBar;
    const [validationMessage, setValidationMessage] = useState('');

    useEffect(() => {
        SettingsService.getCustomerDataRetentionPeriod(diaryContext.deploymentId, diaryContext.restaurantId)
            .then((data) => {
                setRetentionPeriodMonths(data.CustomerRetentionPeriod ?? '');
            })
            .catch(() => {
                addErrorBar();
            });
    }, [diaryContext.deploymentId, diaryContext.restaurantId, addErrorBar]);

    function updateRetentionPeriod() {
        setIsSaving(true);
        SettingsService.setCustomerDataRetentionPeriod(
            diaryContext.deploymentId,
            diaryContext.restaurantId,
            parseInt(retentionPeriodMonths)
        )
            .then(() => {
                props.addSuccessBar(intl.formatMessage({ id: 'Common.SuccessfullySaved' }));
            })
            .catch(() => {
                props.addErrorBar();
            })
            .finally(() => {
                setIsSaving(false);
            });
    }

    function validateRetentionPeriod(v) {
        setRetentionPeriodMonths(v);
        if (v.length && !(parseInt(v) > 0))
            setValidationMessage(intl.formatMessage({ id: 'Settings.CustomerDataRetentionValidationMessage' }));
        else setValidationMessage('');
    }

    function submit() {
        if (!validationMessage) updateRetentionPeriod();
    }

    return (
        <div className={`${displayClass} customer-data-retention`}>
            <Section sectionTitle={intl.formatMessage({ id: 'Settings.CustomerDataRetention' })}>
                <div className="collapsible-panel-body">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            submit(updateRetentionPeriod);
                        }}
                    >
                        <FormGroup>
                            <div>
                                <div className="form-group">
                                    <FormattedMessage id="Settings.CustomerDataRetentionDescription" />
                                </div>
                                <div className="form-group bold-text">
                                    <FormattedMessage id="Settings.CustomerDataRetentionDescriptionNote" />
                                </div>
                                <div className="form-group">
                                    <span>
                                        <FormattedMessage id="Settings.CustomerDataRetentionLabelPart1" />
                                    </span>
                                    <Input
                                        className={'form-control d-inline ml-1 mr-1'}
                                        type="number"
                                        name="dataRetention"
                                        value={retentionPeriodMonths}
                                        onChange={(v) => validateRetentionPeriod(v.target.value)}
                                    />
                                    <span>
                                        <FormattedMessage id="Settings.CustomerDataRetentionLabelPart2" />
                                    </span>
                                    {validationMessage && <ValidationMessage message={validationMessage} />}
                                </div>
                            </div>
                        </FormGroup>
                        <div className="panel-footer">
                            <input
                                disabled={isSaving || validationMessage}
                                type="submit"
                                value={
                                    isSaving
                                        ? intl.formatMessage({
                                              id: 'Common.Updating',
                                          })
                                        : intl.formatMessage({
                                              id: 'Common.Update',
                                          })
                                }
                                className="btn btn-primary"
                            />
                        </div>
                    </Form>
                </div>
            </Section>
        </div>
    );
}

CustomerDataRetentionTab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
};

export default CustomerDataRetentionTab;
