import React, { Fragment, useEffect, useState, useContext, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as ChevronLeft } from '../../../../content/icons/chevron-left.svg';
import LoyaltyReportTableBody from './LoyaltyReportTableBody';
import LoyaltyReportTableHeader from './LoyaltyReportTableHeader';
import LoyaltyReportTransaction from '../../../../domainObjects/LoyaltyReportTransaction';
import useScreenSize from '../../../../hooks/useScreenSize';
import LoyaltyReportFilterSidebar from './LoyaltyReportFilterSidebar';
import loyaltyReportDateRange from '../../../../enums/loyaltyReportDateRange';
import loyaltyReportRewardStatus from '../../../../enums/loyaltyReportRewardStatus';
import loyaltyReportPageLength from '../../../../enums/loyaltyReportPageLength';
import DiaryContext from '../../../../contexts/DiaryContext';
import LoyaltyService from '../../../../services/LoyaltyService';
import LoadingSpinner from '../../../common/LoadingSpinner';
import InfoBars from '../../../common/InfoBars';
import infoBarType from '../../../../enums/infoBarType';

function LoyaltyReportPage(props) {
    const allTimeStartDate = moment('2022-10-01');

    const intl = useIntl();
    const [searchTerm, setSearchTerm] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchResults] = useState([]);
    const [initialised, setInitialised] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [dateRangeOption, setDateRangeOption] = useState(loyaltyReportDateRange.allTime);
    const [rewardStatusOption, setRewardStatusOption] = useState(loyaltyReportRewardStatus.all);
    const [pageLengthOption, setPageLengthOption] = useState(loyaltyReportPageLength.twenty);
    const { isMobileView } = useScreenSize();
    const diaryContext = useContext(DiaryContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(null);
    const [startDate, setStartDate] = useState(allTimeStartDate);
    const [endDate, setEndDate] = useState(moment());
    const [pageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [initialFilters, setInitalFilters] = useState({});
    const [infoBars, setInfoBars] = useState([]);

    let _ID = 0;
    const addErrorBar = useCallback(
        (message) => {
            setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
        },
        [_ID]
    );

    if (!diaryContext.isLoyaltyEnabled) {
        navigate('/404');
    }
    const getPage = function () {
        setIsLoading(true);
        setInitialised(true);
        let request = getRequest();

        let selectedFilters = JSON.parse(JSON.stringify(request));
        delete selectedFilters.pageNumber;

        const newFilters = JSON.stringify(selectedFilters);

        if (newFilters !== initialFilters) {
            request.pageCount = null;
        }

        LoyaltyService.getProviderTransactions(diaryContext.deploymentId, diaryContext.restaurantId, request)
            .then((response) => {
                setPageCount(response.data.PageCount);
                const reportTransactions = response.data.ProviderTransactions.map(
                    (t) => new LoyaltyReportTransaction(t)
                );
                setTableData(reportTransactions);

                delete request.pageNumber;
                request.pageCount = response.data.PageCount;
                setInitalFilters(JSON.stringify(request));

                setIsLoading(false);
            })
            .catch(() => {
                addErrorBar(intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' }));
                setIsLoading(false);
            });
    };

    const getFormattedMomentDate = function (momentDate) {
        return momentDate.format('YYYY-MM-DD HH:mm:ss').toString();
    };

    useEffect(() => {
        if (!initialised) {
            getPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialised]);

    useEffect(() => {
        const currentDate = moment();
        switch (dateRangeOption) {
            case loyaltyReportDateRange.thisWeek:
                setStartDate(moment().startOf('week'));
                setEndDate(currentDate.endOf('day'));
                break;
            case loyaltyReportDateRange.thisMonth:
                setStartDate(moment().startOf('month'));
                setEndDate(currentDate.endOf('day'));
                break;
            case loyaltyReportDateRange.customRange:
                break;
            case loyaltyReportDateRange.allTime:
            default:
                setStartDate(allTimeStartDate);
                setEndDate(currentDate.endOf('day'));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRangeOption]);

    useEffect(() => {
        switch (pageLengthOption) {
            case loyaltyReportPageLength.fifty:
                setPageSize(50);
                break;
            case loyaltyReportPageLength.twoHundred:
                setPageSize(200);
                break;
            case loyaltyReportPageLength.fiveHundred:
                setPageSize(500);
                break;
            case loyaltyReportPageLength.oneThousand:
                setPageSize(1000);
                break;
            case loyaltyReportPageLength.twoThousand:
                setPageSize(2000);
                break;
            case loyaltyReportPageLength.twenty:
            default:
                setPageSize(20);
                break;
        }
    }, [pageLengthOption]);

    useEffect(() => {
        getPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const getRequest = function () {
        return {
            startDate: getFormattedMomentDate(startDate),
            endDate: getFormattedMomentDate(endDate),
            pageSize: pageSize,
            pageNumber: currentPage,
            pageCount: pageCount,
        };
    };

    return (
        <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            <div className={`loyalty-report-page ${isMobileView ? 'mobile' : ''}`}>
                <div className="loyalty-report-page-header">
                    <h1 className="header">{intl.formatMessage({ id: 'Settings.LoyaltyReportPageHeader' })}</h1>
                    <div className="page-back-box">
                        <Link to="/Settings/Loyalty">
                            <ChevronLeft /> <FormattedMessage id="Settings.LoyaltyReportBack" />
                        </Link>
                    </div>
                </div>
                <div className="loyalty-report-page-body">
                    <LoyaltyReportTableHeader
                        searchTerm={searchTerm}
                        searchResults={searchResults}
                        setSearchTerm={setSearchTerm}
                        tableData={tableData}
                        setIsFilterOpen={setIsFilterOpen}
                        isLoading={isLoading}
                    />
                    {isLoading && <LoadingSpinner noMargin />}
                    {!isLoading && (
                        <LoyaltyReportTableBody
                            tableData={tableData}
                            searchResults={searchResults}
                            searchTerm={searchTerm}
                            pageCount={pageCount}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                        />
                    )}
                </div>
            </div>
            {isFilterOpen && (
                <div className="loyalty-report-page-filter-sidebar">
                    <LoyaltyReportFilterSidebar
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        dateRangeOption={dateRangeOption}
                        setDateRangeOption={setDateRangeOption}
                        rewardStatusOption={rewardStatusOption}
                        setRewardStatusOption={setRewardStatusOption}
                        dateRangeFrom={startDate}
                        setDateRangeFrom={setStartDate}
                        dateRangeTo={endDate}
                        setDateRangeTo={setEndDate}
                        pageLengthOption={pageLengthOption}
                        setPageLengthOption={setPageLengthOption}
                        applyFilters={getPage}
                    />
                </div>
            )}
        </Fragment>
    );
}

export default LoyaltyReportPage;
