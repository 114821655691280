import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Label, FormGroup, Form, Input } from 'reactstrap';
import Section from '../../common/Section';
import useValidation from '../../../hooks/useValidation';
import SettingsService from '../../../services/SettingsService';
import TermsPrivacyDto from '../../../domainObjects/RestaurantSettings/TermsPrivacy';
import DiaryContext from '../../../contexts/DiaryContext';

function TermsPrivacyTab(props) {
    const intl = useIntl();
    const [isSaving, setIsSaving] = useState(false);
    const [terms, setTerms] = useState('');
    const [privacy, setPrivacy] = useState('');
    const displayClass = props.isActive ? '' : 'hidden';
    const diaryContext = useContext(DiaryContext);

    const { errors, submitDisabled, submit } = useValidation();

    useEffect(() => {
        setTerms(props.termsConditions);
    }, [props.termsConditions]);

    useEffect(() => {
        setPrivacy(props.privacyPolicy);
    }, [props.privacyPolicy]);

    function hasContentUpdated() {
        return props.termsConditions !== terms || props.privacyPolicy !== privacy;
    }

    function getDto() {
        let termsPrivacyDto = new TermsPrivacyDto();
        return termsPrivacyDto.getDto(terms, privacy);
    }

    function updateDetails() {
        setIsSaving(true);

        SettingsService.setTermsAndPrivacy(diaryContext.deploymentId, diaryContext.restaurantId, getDto())
            .then(() => {
                props.addSuccessBar(intl.formatMessage({ id: 'Settings.SuccessfullySavedVenueDetailsMessage' }));
                setIsSaving(false);
            })
            .catch(() => {
                props.addErrorBar();
                setIsSaving(false);
            });
    }

    return (
        <div className={displayClass}>
            <Section sectionTitle={intl.formatMessage({ id: 'Settings.TermsAndPrivacy' })}>
                <div className="collapsible-panel-body">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            submit(updateDetails);
                        }}
                    >
                        <FormGroup>
                            <div className="single-page">
                                <Label for="termsAndConditions">
                                    <FormattedMessage id="Settings.TermsAndConditions" />
                                </Label>
                                <Input
                                    type="textarea"
                                    name="termsAndConditions"
                                    value={terms}
                                    onChange={(e) => setTerms(e.target.value)}
                                    errors={errors}
                                    maxLength="4000"
                                    autoFocus
                                    className="textarea-lg"
                                />
                                <Label for="privacyPolicy">
                                    <FormattedMessage id="Settings.PrivacyPolicy" />
                                </Label>
                                <Input
                                    type="textarea"
                                    name="privacyPolicy"
                                    value={privacy}
                                    onChange={(e) => setPrivacy(e.target.value)}
                                    errors={errors}
                                    maxLength="4000"
                                    autoFocus
                                    className="textarea-lg"
                                />
                            </div>
                        </FormGroup>
                        <div className="panel-footer">
                            <input
                                disabled={isSaving || submitDisabled || !hasContentUpdated()}
                                type="submit"
                                value={
                                    isSaving
                                        ? intl.formatMessage({
                                              id: 'Common.Updating',
                                          })
                                        : intl.formatMessage({
                                              id: 'Common.Update',
                                          })
                                }
                                className="btn btn-primary"
                            />
                        </div>
                    </Form>
                </div>
            </Section>
        </div>
    );
}

TermsPrivacyTab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    termsConditions: PropTypes.string,
    privacyPolicy: PropTypes.string,
};

export default TermsPrivacyTab;
